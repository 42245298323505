import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../../../store/selectors/userAssignedBoards';
import { getServerTime, BasicLazyParams, FileTypes, warningDailogInit } from '../../../../../utile';
import {
    getBoardsData,
    getBranchesLatest
} from '../../../../../store/actions';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import _ from 'lodash';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import Authorizer, { PERMISSIONS } from '../../../../session/authorizer';
import { baseUrlAdmin } from '../../../../../store/apiConstants';
import Service from '../../../../../services';

class AssignStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUserNameSearch: false,
            username: '',
            boardId: null,
            classId: null,
            groupId: null,
            sectionIds: [],
            boards: [],
            classes: [],
            groups: [],
            sections: [],
            branchLevels: {},
            lazyParams: BasicLazyParams,
            users: [],
            selectedUser: [],
            globalSearch: ''
        }

        this.service = new Service()
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }
        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


        // let url = `${baseUrlAdmin}/`

        // this.service.get(url, true).then(res => {
        //     if (res?.status && res?.res?.status) {
        //         this.setState({
        //             isLoading: false,
        //             users: res?.res?.data
        //         })
        //     }else{
        //         this.setState({
        //             isLoading : false
        //         })
        //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        //     }
        // }).catch(err => {
        //     this.setState({
        //         isLoading: false
        //     })
        //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        // })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });



                this.setState({
                    boards: boards
                });
            }
        }


        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }


    onChangeBoard = (boardId) => {

        this.setState({
            classes: [], classId: null,
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null
        });

    };

    onChangeClass = (classId) => {

        this.setState({
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                sections: _groups,
                classId,
                groupId: _groups[0].value
            });
        }
    };

    handleUserNameSearchChange = (event) => {
        this.setState({
            isUserNameSearch: event.target.checked,
        });
    };

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }

    handleClearSearch = () => {


        let sectionsData = {};
        if (this.props.branchData && this.props.branchData.length) {
            sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
        }

        this.setState(
            {
                boardId: null,
                classId: null,
                groupId: null,
                branchId: null,
                courseId: [],
                sectionIds: [],
                username: '',
                startDate: getServerTime().setDate(getServerTime().getDate() - 5),
                endDate: getServerTime(),
                classes: [],
                sectionsData

            }
        );
    };



    onSearchClick = () => {

        const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionIds, startDate, endDate } = this.state;

        if (isUserNameSearch) {
            if (username == '') {
                this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                return;
            }
            if (username.length < 5) {
                this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                return;
            }
        } else {
            if (!boardId) {
                this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                return;
            } else if (boardId && (!classId || !groupId)) {
                this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                return;
            }
            // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
            //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
            //     this.props.snackBarOpen('Kindly select Course and Section.');
            //     return;
            // }

        }

        this.filterUsersData({
            boardId,
            classId,
            groupId,
            sectionId: sectionIds.length > 0 ? sectionIds : null,
            username,
            isUserNameSearch,
            // startDate,
            // endDate,
            // isDateSearch,
            // fromDate: new Date(fromDate).getTime(),
            // toDate: new Date(toDate).getTime(),

        });

    }

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }

    filterUsersData = (filterData) => {
        this.setState({
            isLoading: true,
            users: []
        })

        let { lazyParams, globalSearch } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers/admission?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords


                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }



    onSelectUser = (e) => {
        this.setState({ selectedUser: e.value })
    }
    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    isSelected = (rowData, selectedItems, uniqField) => {
        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {

        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts
        })

    }

    onGlobalSearch = (e) => {

        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
            }
        }, () => {

            //  if (e.target.value.length > 2) {
            this.startGlobalFilter()
            //  }

        });
    }


    assignStudents = (rowData) => {

        if (!rowData.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select atleast one user', life: 3000 });
            return
        } else {


            let admissions = [];

            this.state.selectedUser.map(e => {
                let obj = {}
                obj._id = e._id
                obj.admissionRId = e.admissionRId

                admissions.push({
                    _id: e._id,
                    admissionRId: e.admissionRId
                })
            })

            this.setState({
                isLoading: true
            });


            const url = `${baseUrlAdmin}/transport-route/student/add`;
            this.service.post(url, {
                admissions,
                transportStop: this.props.transportStop,
                transportRoute: this.props.transportRoute
            }, true).then((data) => {

                if (data && data.res && data.res.status) {

                    this.setState({
                        isLoading: false,
                        selectedUser: [],
                    }, () => this.props.onCloseAddStudent(true));
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unkown error', life: 3000 });
                }

            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });
        }
    }



    render() {

        const { isUserNameSearch, username } = this.state

        const header = (
            <div className="flex justify-content-between bg-white ">
                <div className="flex">
                    <h4 >Students List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4 h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearch: '' }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">Sort & Filter</h4>
                                {/* <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <Dialog
                    visible={this.props.showAddStudent}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={true}
                    style={{ maxWidth: '80%', minWidth: '80%' }}
                    footer={this.footer}
                    header={`${this.props.editStudentDetails ? 'Update' : ''} Student Details`} headerClassName='text-center'
                    onHide={() => this.props.onCloseAddStudent()}
                >
                    <div className="mt-2">
                        <div className='my-2'>
                            <Checkbox inputId="usearch" value="true"
                                onChange={this.handleUserNameSearchChange}
                                checked={isUserNameSearch}
                            ></Checkbox>
                            <label htmlFor="usearch" className="p-checkbox-label ml-2">Search User By Username</label>
                        </div>
                        <>
                            <div className='ma-mt20'>
                                {
                                    !isUserNameSearch && <>
                                        <div className='my-3'>
                                            <Dropdown value={this.state.boardId}
                                                className='w-2 ma-w200'
                                                options={this.state.boards}
                                                onChange={(e) => this.onChangeBoard(e.value)}
                                                placeholder="--Select Board--" />
                                            <span className='ma-required-mark '>*</span>
                                            <Dropdown value={this.state.classId}
                                                options={this.state.classes}
                                                className='w-2 ml-4 ma-w200'
                                                onChange={(e) => this.onChangeClass(e.value)} placeholder="--Select Class--" />
                                            <span className='ma-required-mark '>*</span>

                                            {
                                                this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                                    return (!(this.props.branchLevels.length == index + 1) ? <Dropdown
                                                        className='w-2 ml-4 ma-mr20 ma-mt10 ma-w200'
                                                        value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                        // className='ma-w100p'
                                                        //    disabled={this.state.hideUpdateButton}
                                                        optionLabel={"name"}
                                                        optionValue={"key"}
                                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                                        onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                                        placeholder={`--Select ${level.levelName}--`} /> : <MultiSelect
                                                        className='w-2 ml-4 ma-mr20 ma-mt10 ma-w200'
                                                        value={this.state.sectionIds ? this.state.sectionIds : []}
                                                        // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                        onChange={(e) => this.onMultiChange(e.value)}
                                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                                        optionLabel={"name"}
                                                        optionValue={"key"}
                                                        placeholder={`--Select ${level.levelName}--`}

                                                    // onChange={(e) => setCities(e.value)} 
                                                    />
                                                    )
                                                }) : null
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    isUserNameSearch && <>
                                        <InputText value={username} onChange={(e) => {
                                            this.setState({
                                                username: e.target.value
                                            })
                                        }} className='p-inputtext-style1 w-3 my-2' />
                                    </>
                                }



                            </div>
                            <div className='my-3'>
                                <Button label="Search" className='p-button-raised' onClick={this.onSearchClick} />
                                <Button label="Clear" className='ml-2 ma-m-lr10 p-button-outlined' onClick={this.handleClearSearch} />
                            </div>

                            <div className="card datatable-crud-demo ma-mt20">
                                <DataTable ref={(el) => this.dt = el}
                                    lazy
                                    value={this.state.users}
                                    dataKey="_id"
                                    paginator
                                    onPage={this.onPage}
                                    // onSort={this.onSort}
                                    first={this.state.lazyParams.first}
                                    last={this.state.totalRecords}
                                    rows={this.state.lazyParams.rows}
                                    totalRecords={this.state.totalRecords}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter2}
                                    //selection={this.state.selectedUser}
                                    //onSelectionChange={this.onSelectUser}
                                    paginatorLeft={() => {
                                        return <>
                                            <p className='avail-q'> Selected Users:  <span>{this.state.selectedUser && this.state.selectedUser.length}</span> </p>
                                        </>
                                    }}
                                    selectionMode="checkbox"

                                    header={header}>
                                    <Column headerStyle={{ width: '4em' }} field='_id'
                                        header={<Checkbox inputId={'item.id'}
                                            checked={this.isSelectedAllItems(this.state.users, this.state.selectedUser, '_id')}
                                            onChange={(e) => { this.selectOrdeselect('list', this.state.selectedUser, this.state.users, e.checked, 'selectedUser', '_id') }}
                                        ></Checkbox>}
                                        body={(rowData) => {
                                            return <Checkbox inputId={'item.id'}
                                                checked={this.isSelected(rowData, this.state.selectedUser, '_id')}
                                                onChange={(e) => { this.selectOrdeselect('single', this.state.selectedUser, rowData, e.checked, 'selectedUser', '_id') }}
                                            ></Checkbox>
                                        }}>
                                    </Column>
                                    <Column field="given_name" header="Name" />
                                    <Column field="phone_number" header="Phone" />
                                    <Column field="email" header="Email ID" />
                                    <Column field="custom:board" header="Board" />
                                    <Column field="custom:grade" header="Grade" />
                                    {/* <Column header="Actions" body={(rowData) => {
                                    return (<>
                                        <Button label="Assign Assessment" onClick={() => this.assignSubjects(false, [rowData.sectionId])} />
                                    </>
                                    );
                                }} ></Column> */}
                                </DataTable>

                            </div>
                            {/* <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_SUBJECTS} > */}
                            <Button label="Assign Students" className='ma-mt20 p-button-raised' onClick={() => this.assignStudents(this.state.selectedUser)} />
                        </>

                    </div>

                </Dialog>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(AssignStudent);
