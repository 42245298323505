import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { connect } from 'react-redux';
import InputTextB from './../customComponents/inputTextB';
import { Menu } from 'primereact/menu';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { WarningIcon } from '../svgIcons';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { TreeTable } from 'primereact/treetable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import './styles.scss';
import {
    getCurriculum,
    getActiveOrganizationData,
    selectBoard,
    selectClass,
    selectGroup,
    clearSelectedOrganizationData,
    updateSubject,
    getSubject,
    dispatchClearCurriculumData,
    startExportToOffline,
    getImageUrl,
    getBranchesLatest,
    getBoardsData,
    saveBranchesLatest
} from './../../store/actions';
import _ from 'lodash';
import LoadingComponent from '../loadingComponent';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { FIELDS_INFO } from '../../constants';
import { warningDailogInit } from '../../utile';
import { I18Next } from './../../internationalization';
import withRouter from '../lib/withRouter';
const uuidv4 = require('uuid/v4');
const items = [
    { label: 'Organization' },
    { label: 'Branch Master', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class BranchMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dynamicNodes: [],
            totalLevels: 1,
            // totalLevelsInfo: [{
            //     levelId: uuidv4(),
            //     levelName: '',
            // }],
            totalLevelsInfo: [],
            insertAt: '',

            nodes: [
            ],


            mainNode: {
                children: []
            },


            levelName: '',
            levelNameErr: '',
            isShowLevelAddDialog: false,

            isShowSectionInfo: false,
            selectedSectionInfo: null,
            warningDialog: warningDailogInit,

            // _id: '61a0da571ec18aa5f18ad81a'
        }
        this.service = new Service();
    }

    componentDidMount() {

        this.props.getBranchesLatest(true);

        this.formNodes();

    }



    // componentDidUpdate() {



    //     localStorage.setItem('branchs', JSON.stringify({ nodes: this.state.nodes, totalLevelsInfo: this.state.totalLevelsInfo }));
    // }

    componentDidUpdate(prevProps) {
        if (prevProps.branchLevels !== this.props.branchLevels) {
            this.setState({
                totalLevelsInfo: this.props.branchLevels ? this.props.branchLevels : [],
                nodes: this.props.branchData ? this.props.branchData : []
            }, this.formNodes)
        }

        if (prevProps.updateError !== this.props.updateError && this.props.updateError) {
            this.toast.show({ severity: 'error', summary: 'Unable to update', detail: this.props.updateErrorMessage, life: 3000 });
        }
    }


    formNodes = () => {

        // let { nodes, totalLevelsInfo } = JSON.parse(localStorage.getItem('branchs'));

        let { nodes, totalLevelsInfo } = this.state;


        let nodesTemp = _.cloneDeep(nodes);
        let mainNodes = nodesTemp.filter(n => n.level == 1).map(n => n.key);
        mainNodes.children = mainNodes;
        let finalNodes = this.formDynamicNodes(mainNodes, nodesTemp)
        this.setState({
            dynamicNodes: finalNodes,
            totalLevelsInfo,
            nodes
        })
    }



    formDynamicNodes = (mainNodes, nodes) => {
        function recurse(node) {
            if (node.children) {
                node.children = node.children.map(childNode => {
                    childNode = nodes.find((n) => n.key == childNode);
                    childNode = recurse(childNode);
                    return childNode
                });
            }
            return node;
        }
        mainNodes = recurse(mainNodes);

        return mainNodes.children;
    }


    getSectionIdsofNodes = (mainNodes, nodes) => {
        let lastNodesKeys = [];
        let childNodeKeys = [];
        function recurse(node) {
            childNodeKeys.push(node.key);

            if (node.children) {
                node.children = node.children.map(childNode => {
                    childNode = nodes.find((n) => n.key == childNode);
                    childNode = recurse(childNode);
                    return childNode
                });
            }

            if (node.children == undefined) {
                lastNodesKeys.push(node.key);
            }

            return node;
        }
        mainNodes = recurse(mainNodes);

        return { lastNodesKeys, childNodeKeys };
    }


    addLevel = (position) => {
        this.setState({

            isShowLevelAddDialog: true,
            levelAddPosition: position ? position : null,
            levelName: ''
        });
    }

    onAddLevel = async () => {
        if (this.state.levelName.trim() != '') {
            if (!await this.checkIsTimeTablesCreated()) {
                if (this.state.levelAddPosition) {
                    this.addLevelAtPosition(this.state.levelAddPosition, this.state.levelName)
                } else {
                    this.addLevelAtTop(this.state.levelName);
                }
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Level can not be added once time tables created. Please delete all timetables before adding levels', life: 3000 });
            }


        } else {
            this.setState({
                levelNameErr: 'Please enter level name'
            })
        }
    }

    addLevelAtTop = (levelName) => {
        let { nodes, totalLevels, totalLevelsInfo } = this.state;
        let nodesTemp = _.cloneDeep(nodes);
        let previousTopNodeIds = [];
        nodesTemp.forEach((n) => {
            if (n.level == 1) {
                previousTopNodeIds.push(n.key);
            }
        });


        for (let ij = totalLevelsInfo.length; ij > 0; ij--) {
            nodesTemp.forEach((n) => {

                if (n.level == ij) {
                    n.level = ij + 1;

                }
            });
        }

        previousTopNodeIds.forEach((id, index) => {
            let dummyNode = {
                key: uuidv4(),
                name: levelName + ' ' + (index + 1),
                isActive: true,
                level: 1,
                children: [id]
            };
            nodesTemp.push(dummyNode)
        })


        let mainNodeIds = nodesTemp.filter(n => n.level == 1).map(n => n.key);
        let mainNodes = {
            children: mainNodeIds
        }
        let temp = _.cloneDeep(nodesTemp);
        let main = _.cloneDeep(mainNodes);

        let finalNodes = this.formDynamicNodes(main, temp)
        this.setState({
            dynamicNodes: finalNodes,
            nodes: nodesTemp,
            totalLevelsInfo: [{
                levelId: uuidv4(),
                levelName: levelName
            }, ...totalLevelsInfo],
            isShowLevelAddDialog: false,
            levelName: '',
            levelNameErr: ''
        }, () => {
            this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
        })
    }

    addLevelAtPosition = (position, levelName) => {
        let { nodes, totalLevels, totalLevelsInfo } = this.state;
        let nodesTemp = _.cloneDeep(nodes);
        let previousTopNodeIds = [];
        nodesTemp.forEach((n) => {
            if (n.level == position + 1) {
                previousTopNodeIds.push(n.key);
            }
        });

        for (let ij = totalLevelsInfo.length; ij > position; ij--) {
            nodesTemp.forEach((n) => {

                if (n.level == ij) {
                    n.level = ij + 1;

                }
            });
        }
        let previousNodes = nodesTemp.filter(n => n.level == position);
        previousTopNodeIds.forEach((id, index) => {
            let dummyNode = {
                key: uuidv4(),
                name: levelName + ' ' + (index + 1),
                isActive: true,
                level: position + 1,
                children: [id]
            };
            nodesTemp.push(dummyNode);
            previousNodes.forEach(i => {
                if (i.children) {
                    if (i.children.includes(id)) {
                        i.children.splice(i.children.indexOf(id), 1, dummyNode.key)
                    }
                }
                nodesTemp = nodesTemp.map((ki) => {
                    if (ki.key == i.key) {
                        ki.children = i.children;
                    }
                    return ki;
                });
            });
        });

        let mainNodeIds = nodesTemp.filter(n => n.level == 1).map(n => n.key);
        let mainNodes = {
            children: mainNodeIds
        }
        let temp = _.cloneDeep(nodesTemp);
        let main = _.cloneDeep(mainNodes);

        let finalNodes = this.formDynamicNodes(main, temp);



        totalLevelsInfo.splice(position, 0, {
            levelId: uuidv4(),
            levelName: levelName
        });


        this.setState({
            dynamicNodes: finalNodes,
            nodes: nodesTemp,
            totalLevelsInfo,
            isShowLevelAddDialog: false,
            levelName: '',
            levelNameErr: ''
        }, () => {
            this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
        })
    }


    deleteLevelAtPosition = async (position) => {

        if (!await this.checkIsTimeTablesCreated()) {


            let { nodes, totalLevelsInfo } = this.state;
            let nodesTemp = _.cloneDeep(nodes);
            let selectedNotesAtPosition = nodesTemp.filter(n => n.level == position);
            selectedNotesAtPosition.forEach(nod => {
                nodesTemp.map(i => {
                    if (i.children) {
                        if (i.children.includes(nod.key)) {
                            if (nod.children) {
                                i.children.splice(i.children.indexOf(nod.key), 1, ...nod.children)
                            } else {
                                i.children.splice(i.children.indexOf(nod.key), 1)
                            }

                        }
                    }
                    return i;
                });
            });

            nodesTemp = nodesTemp.filter(n => n.level != position);
            for (let ij = position + 1; ij <= totalLevelsInfo.length; ij++) {
                nodesTemp.forEach((n) => {
                    if (n.level == ij) {
                        n.level -= 1;
                    }
                });
            }

            let mainNodeIds = nodesTemp.filter(n => n.level == 1).map(n => n.key);
            let mainNodes = {
                children: mainNodeIds
            }
            let temp = _.cloneDeep(nodesTemp);
            let main = _.cloneDeep(mainNodes);


            totalLevelsInfo.splice(position - 1, 1)

            let finalNodes = this.formDynamicNodes(main, temp)
            this.setState({
                dynamicNodes: finalNodes,
                nodes: nodesTemp,
                totalLevelsInfo
            }, () => {
                this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
            })

        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Level can not be deleted once time tables created. Please delete all timetables before deleting levels', life: 3000 });
        }
    }


    markLevelForTimeTable = (position) => {



        this.setState({
            isLoading: true,
        });

        let url = `${baseUrlAdmin}/timetable/all?limit=10&page=1`;
        this.service.post(url, {}, true).then(res => {

            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.timeTables) {
                    if (res.res.data && res.res.data.timeTables && res.res.data.timeTables.length) {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'There are time tables created with level. please delete all the time tables before change', life: 10000 });
                    } else {
                        this.setState({
                            warningDialog: {
                                visible: true,
                                headerMsg: 'Are you Sure?',
                                message: `Do you want mark the selected level for time table, once the time table created, it can't be changed until all the timetables are deleted.`
                            },
                            selectedLevelForMark: position,
                            isLoading: false

                        });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                }

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);
        })
    }

    onConfirmMark = () => {
        let { nodes, totalLevelsInfo, selectedLevelForMark } = this.state;
        let position = selectedLevelForMark;
        let nodesTemp = _.cloneDeep(nodes);

        totalLevelsInfo = totalLevelsInfo.map((level, index) => {
            if (index == selectedLevelForMark - 1) {
                level.isMarked = true
            } else {
                level.isMarked = false;
            }
            return level;
        })



        this.setState({
            warningDialog: warningDailogInit,
            nodes: nodesTemp,
            totalLevelsInfo
        }, () => {
            this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
        })
    }


    addNewRow = (rowData) => {
        let { nodes, totalLevelsInfo } = this.state;
        let nodesTemp = _.cloneDeep(nodes);


        let childId;
        for (let ij = totalLevelsInfo.length; ij >= rowData.level; ij--) {
            let dummyNode = {
                key: uuidv4(),
                name: totalLevelsInfo[ij - 1].levelName + ' Sample' + ij,
                isActive: true,
                level: ij,
            };
            if (ij < totalLevelsInfo.length && childId) {
                dummyNode = {
                    ...dummyNode,
                    children: [childId]
                }
            }
            nodesTemp.push(dummyNode);
            childId = dummyNode.key;
        }

        nodesTemp = nodesTemp.map((node) => {
            if (node.children) {
                if (node.children.includes(rowData.key)) {
                    node.children.push(childId)
                }
            }
            return node;
        });

        let mainNodeIds = nodesTemp.filter(n => n.level == 1).map(n => n.key);
        let mainNodes = {
            children: mainNodeIds
        }
        let temp = _.cloneDeep(nodesTemp);
        let main = _.cloneDeep(mainNodes);

        let finalNodes = this.formDynamicNodes(main, temp)
        this.setState({
            dynamicNodes: finalNodes,
            nodes: nodesTemp
        }, () => {
            this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
        });
    }

    checkIsSectionAssigned = async (sectionIds) => {


        return await new Promise((response, rej) => {

            this.setState({
                isLoading: true
            });
            const url = `${baseUrlAdmin}/branch/issectionsAssigned`;
            this.service.post(url, { sectionIds }, true).then(res => {
                if (res && res.status) {
                    if (res.res.data) {

                        this.setState({
                            isLoading: false
                        })

                        response(true)
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        response(false)
                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                    response(true)
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                })
                console.log(e);
                response(true)

            })



        });


    }

    deleteRowAtPosition = async (rowData) => {



        // if (rowData.level == this.state.totalLevelsInfo.length) {
        //     let { data, err } = await this.checkIsSectionAssigned(rowData.key)
        //     if (data == true) {
        //         this.toast.show({ severity: 'error', summary: 'Warning', detail: `It can't be deleted as it is assigned to something.`, life: 3000 });
        //         return;

        //     }
        // }

        let { nodes } = this.state;
        let nodesTemp = _.cloneDeep(nodes);



        let mainNodes1 = nodes.find(a => a.key == rowData.key);


        let temp1 = _.cloneDeep(nodesTemp);
        let main1 = _.cloneDeep(mainNodes1);

        let { lastNodesKeys, childNodeKeys } = this.getSectionIdsofNodes(main1, temp1)




        let data = await this.checkIsSectionAssigned(lastNodesKeys)
        if (data == true) {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: `It can't be deleted as it is assigned to something.`, life: 3000 });
            return;

        }



        // return;

        let canNotDelete = true;

        if (rowData.level != 1) {


            nodesTemp = nodesTemp.map((node) => {
                if (node.children) {
                    if (node.children.includes(rowData.key)) {
                        if (node.children.length > 1) {
                            canNotDelete = false;
                            node.children.splice(node.children.indexOf(rowData.key), 1)
                        }
                    }

                }
                return node;
            });
        } else {
            let isLevels1 = nodesTemp.filter(n => n.level == 1);
            if (isLevels1.length > 1) {
                canNotDelete = false;
            }

        }



        if (canNotDelete) {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'It can not be deleted, due to limitations', life: 3000 });
            return;
        }

        childNodeKeys.forEach(no => {
            nodesTemp = nodesTemp.filter((n => n.key != no));
        });


        let mainNodeIds = nodesTemp.filter(n => n.level == 1).map(n => n.key);
        let mainNodes = {
            children: mainNodeIds
        }
        let temp = _.cloneDeep(nodesTemp);
        let main = _.cloneDeep(mainNodes);

        let finalNodes = this.formDynamicNodes(main, temp)
        // this.setState({
        //     dynamicNodes: finalNodes,
        //     nodes: nodesTemp
        // });
        this.setState({
            dynamicNodes: finalNodes,
            nodes: nodesTemp
        }, () => {
            this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
        });
    }


    onEditorValueChange = (options, value) => {
        let { nodes } = this.state;
        let nodesTemp = _.cloneDeep(nodes);
        nodesTemp.forEach(n => {
            if (n.key == options.node.key) {
                n.name = value
            }
        });

        let mainNodeIds = nodesTemp.filter(n => n.level == 1).map(n => n.key);
        let mainNodes = {
            children: mainNodeIds
        }

        let main = _.cloneDeep(mainNodes);
        let temp = _.cloneDeep(nodesTemp);
        let finalNodes = this.formDynamicNodes(main, temp)
        this.setState({
            dynamicNodes: finalNodes,
            nodes: nodesTemp,
        })
    }

    onStatusChange = (key, value) => {
        let { nodes } = this.state;
        let nodesTemp = _.cloneDeep(nodes);
        nodesTemp.forEach(n => {
            if (n.key == key) {
                n.isActive = value
            }
        });

        let mainNodeIds = nodesTemp.filter(n => n.level == 1).map(n => n.key);
        let mainNodes = {
            children: mainNodeIds
        }

        let main = _.cloneDeep(mainNodes);
        let temp = _.cloneDeep(nodesTemp);
        let finalNodes = this.formDynamicNodes(main, temp)
        this.setState({
            dynamicNodes: finalNodes,
            nodes: nodesTemp,
        }, () => {
            this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
        })
    }

    onLevelNameChange = (levelId, value) => {
        let levels = this.state.totalLevelsInfo;
        levels = levels.map((l) => {
            if (l.levelId == levelId) {
                l.levelName = value;
            }
            return l;
        });
        this.setState({
            totalLevelsInfo: levels
        });
    }

    checkIsTimeTablesCreated = async () => {

        return await new Promise((response, rej) => {

            this.setState({
                isLoading: true
            });
            let url = `${baseUrlAdmin}/timetable/all?limit=10&page=1`;
            this.service.post(url, {}, true).then(res => {

                if (res && res.status && res.res.status) {
                    if (res.res.data && res.res.data.timeTables) {
                        if (res.res.data && res.res.data.timeTables && res.res.data.timeTables.length) {
                            this.setState({
                                isLoading: false
                            })
                            response(true)
                        } else {
                            this.setState({
                                isLoading: false
                            });
                            response(false)
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Some error occured.', life: 3000 });
                        response(true)
                    }

                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Some error occured.', life: 3000 });
                    response(true)
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                })
                console.log(e);
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Some error occured.', life: 3000 });
                response(true)

            })

        });



    }

    updateBranches = (levels, data) => {
        this.props.saveBranchesLatest({ _id: this.props._id, levels, data })

    };

    updateOnBlur = () => {
        this.updateBranches(this.state.totalLevelsInfo, this.state.nodes);
    }

    updateBranchesOnClick = () => {
        this.props.saveBranchesLatest({
            _id: this.props._id,
            levels: this.state.totalLevelsInfo,
            data: this.state.nodes
        });
    };


    viewCurriculums = (rowData) => {


        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/branch/section/${rowData.key}`;
        this.service.get(url, true).then(res => {
            if (res && res.res && res.res.status) {
                if (res.res.data) {

                    this.setState({
                        selectedSectionInfo: res.res.data,
                        isShowSectionInfo: true,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.errMessage, life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Error', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
            console.log(e);

        })


    }







    rightToolbarTemplate = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>{I18Next('branchMaster', langType).COMMON_KEY}</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    setAsDefault = (classId) => {
        let { selectedSectionInfo } = this.state;
        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/branch/setdefault/section/${selectedSectionInfo.sectionId}/class/${classId}`;
        this.service.get(url, true).then(res => {
            if (res && res.status) {
                if (res.res.data) {
                    this.setState({
                        selectedSectionInfo: res.res.data,
                        isLoading: false
                    })

                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Successfully updated.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.errMessage, life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Error', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
            console.log(e);

        })




    }


    render() {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage

        return (
            <div >
                <div className="">
                    <div className="">
                        {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                    </div>
                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>
                <div className="ask-a-doubt branch-master">
                    <div className="grid" >
                        <div className="col-4 ">
                            <div className='level-create'>
                                <div className=''>
                                    <h3 className='l-title'>{I18Next('levelsCreation', langType).COMMON_KEY} </h3>
                                    <div className='  '>
                                        <Authorizer permId={PERMISSIONS.BRANCH_MASTER_LEVEL_ADD} >
                                            <Button icon="pi pi-plus" className='p-button-rounded ma-button-icon shadow-6' onClick={() => this.addLevel(null)} />
                                        </Authorizer>
                                    </div>

                                    {
                                        this.state.totalLevelsInfo.map((l, index) => {
                                            return <div key={l.levelId} >
                                                <div className="grid m-2 gap-2 ">
                                                    <span className='level-lable mt-1'>{I18Next('level', langType).COMMON_KEY} {index + 1}</span>
                                                    <div  style={{ overflow: 'hidden' }}>
                                                        {
                                                            // <Authorizer permId={PERMISSIONS.BRANCH_MASTER_LEVEL_EDIT} >
                                                            <InputText type="text"
                                                                className='w-full'
                                                                value={l.levelName}
                                                                onChange={(e) => this.onLevelNameChange(l.levelId, e.target.value)}
                                                                onBlur={this.updateOnBlur}
                                                            />
                                                            // </Authorizer>
                                                        }
                                                    </div>
                                                    <div>
                                                        <Authorizer permId={PERMISSIONS.BRANCH_MASTER_LEVEL_DELETE} >
                                                            {
                                                                this.state.totalLevelsInfo.length != index + 1 ? <Button icon="pi pi-trash" className='p-button-rounded ma-button-icon p-button-danger ml-2 shadow-6' onClick={() => { this.deleteLevelAtPosition(index + 1) }} /> : null
                                                            }
                                                        </Authorizer>
                                                        <Authorizer permId={PERMISSIONS.BRANCH_MASTER_LEVEL_DELETE} >
                                                            {
                                                                l.isMarked ? <Button icon="pi pi-flag" className="p-button-rounded ma-button-icon p-button-success shadow-6 ml-2" /> : <Button tooltip='Mark Level For TimeTable' tooltipOptions={{ position: 'bottom' }} onClick={(event) => this.markLevelForTimeTable(index + 1)} icon="pi pi-flag" className="p-button-rounded ma-button-icon p-button-text ml-2 shadow-6" aria-label="More" />
                                                            }
                                                            {/* {
                                                            this.state.totalLevelsInfo.length != index + 1 ? <Button tooltip='Mark Level For TimeTable' tooltipOptions={{ position: 'bottom' }} onClick={(event) => this.markLevelForTimeTable(index + 1)} icon="pi pi-eject" className="p-button-rounded ma-button-icon p-button-text  ma-ml10" aria-label="More" /> : null
                                                        } */}
                                                        </Authorizer>
                                                    </div>
                                                </div>
                                                <Authorizer permId={PERMISSIONS.BRANCH_MASTER_LEVEL_ADD} >
                                                    {this.state.totalLevelsInfo.length != index + 1 && <div className=' '>
                                                        <Button icon="pi pi-plus" className='p-button-rounded ma-button-icon shadow-6' onClick={() => { this.addLevel(index + 1) }} />

                                                    </div>
                                                    }
                                                </Authorizer>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-8 p-md-2">
                            <div className="">
                                <TreeTable
                                    tableStyle={{ width: `${1000 + (((this.state.totalLevelsInfo && this.state.totalLevelsInfo.length) ? this.state.totalLevelsInfo.length : 0) * 20)}px` }}
                                    className='branch-tree'
                                    value={this.state.dynamicNodes} showGridlines >
                                    <Column field="name" header={I18Next('name', langType).COMMON_KEY}
                                        style={{ width: `${300 + (((this.state.totalLevelsInfo && this.state.totalLevelsInfo.length) ? this.state.totalLevelsInfo.length : 0) * 20)}px` }}
                                        body={(options) => {
                                            return <>
                                                {options.name}
                                            </>

                                        }}
                                        editor={(options) => {
                                            return (
                                                <InputText type="text"
                                                    value={options.node[options.field]}
                                                    onChange={(e) => this.onEditorValueChange(options, e.target.value)}
                                                    onBlur={this.updateOnBlur}
                                                />
                                            )

                                        }}
                                        expander
                                    //style={{ height: '3.5em' }}


                                    ></Column>
                                    <Column field="level" header={I18Next('level', langType).COMMON_KEY}
                                        body={(options) => {
                                            return <>
                                                {options.level}
                                            </>

                                        }}
                                        style={{ height: '3.5em' }}></Column>
                                    <Column field="key" header={I18Next('key', langType).COMMON_KEY}
                                        body={(options) => {
                                            return <>
                                                {(options.level == this.state.totalLevelsInfo.length) ? options.key : ''}
                                            </>

                                        }}
                                        style={{ height: '3.5em' }}></Column>
                                    {/* <Column field="isActive" header="Status" body={(options) => {
                                        return <>
                                 
                                            <div className="ma-status">
                                                <span className="ma-status-text">Active </span>  <InputSwitch
                                                    onChange={(e) => { this.onStatusChange(options.key, e.value) }}
                                                    checked={options.isActive}
                                                />
                                            </div>
                                        </>
                                    }} style={{ height: '3.5em' }}></Column> */}
                                    <Column body={(rowData) => {
                                        return (<>
                                            <Authorizer permId={PERMISSIONS.BRANCH_MASTER_DATA_ADD} >
                                                <Button className='p-button-rounded ma-button-icon p-button-success shadow-6' icon="pi pi-plus" onClick={() => this.addNewRow(rowData)} />
                                            </Authorizer>
                                            <Authorizer permId={PERMISSIONS.BRANCH_MASTER_DATA_DELETE} >
                                                <Button className='p-button-rounded ma-button-icon p-button-danger shadow-6 ml-2' icon="pi pi-trash" onClick={() => this.deleteRowAtPosition(rowData)} />
                                            </Authorizer>
                                            {(rowData.level == this.state.totalLevelsInfo.length) ? <Button onClick={(event) => this[rowData.key].toggle(event)} icon="pi pi-ellipsis-v" className="p-button-rounded shadow-6 ma-button-icon p-button-text ml-2" aria-label="More" /> : null}

                                            <Menu model={[
                                                {
                                                    label: (I18Next('viewCurriculums', langType).COMMON_KEY), icon: 'pi pi-fw pi-book',
                                                    command: () => { this.viewCurriculums(rowData) }
                                                }
                                            ]}
                                                className="content-more"
                                                popup ref={el => this[rowData.key] = el} />
                                        </>
                                        );
                                    }} header={I18Next('actions', langType).COMMON_KEY}></Column>
                                </TreeTable>
                            </div>
                        </div>
                    </div>


                    {/* <Toolbar className="ma-toolbard" right={() => {
                        return <><Button label='Update' className='ma-mr15' onClick={this.updateBranchesOnClick} />
                        </>
                    }}></Toolbar> */}
                </div>
                {
                    this.props.isLoading || this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />


                <Dialog

                    header={I18Next('levelAdd', langType).COMMON_KEY}
                    blockScroll={true}
                    draggable={false}
                    footer={() => {
                        return (<>
                            <Button label={I18Next('cancel', langType).COMMON_KEY} className='p-button-outlined' onClick={() => {
                                this.setState({
                                    isShowLevelAddDialog: false,

                                })
                            }} />
                            <Button label={I18Next('add', langType).COMMON_KEY} className='ma-m-lr10' onClick={this.onAddLevel} />

                        </>)
                    }}
                    closeOnEscape={true}
                    dismissableMask={true}
                    visible={this.state.isShowLevelAddDialog}
                    closable={false}
                    onHide={() => { this.setState({ isShowLevelAddDialog: false }) }}>
                    <div className="p-grid subject-add-dialog">
                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label'>{I18Next('levelName', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputTextB info={FIELDS_INFO.LEVEL_NAME} id="levelName">
                                <InputText value={this.state.levelName} onChange={(e) => {
                                    this.setState({
                                        levelName: e.target.value,
                                        levelNameErr: e.target.value == '' ? 'Level Name is required' : ''
                                    })
                                }} className='p-inputtext-style1 ' />
                            </InputTextB>
                            <p className="p-error">{this.state.levelNameErr}</p>
                        </div>
                    </div>

                </Dialog >
                <Dialog
                    //visible={this.state.isShowCreate}
                    style={{ width: '50%' }}
                    header={I18Next('curriculumInfo', langType).COMMON_KEY}
                    headerClassName="text-center"
                    modal
                    // footer={this.createRoleDialogFooter}
                    draggable={false}
                    // footer={this.footer}
                    blockScroll={true}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={this.state.isShowSectionInfo}
                    closable={true}
                    onHide={() => {
                        this.setState({
                            isShowSectionInfo: false
                        })
                    }}>
                    <div>
                        {
                            this.state.selectedSectionInfo && this.state.selectedSectionInfo.curriculums && this.state.selectedSectionInfo.curriculums.length ? this.state.selectedSectionInfo.curriculums.map(c => {
                                return <div className='grid'>
                                    <div className='col-4 p-md-4'>
                                        {c.boardName}
                                    </div>
                                    <div className='col-4 p-md-4'>
                                        {c.className}
                                    </div>
                                    <div className='col-4 p-md-4'>
                                        {
                                            c.isDefault ? <><Button label={I18Next('default', langType).COMMON_KEY} disabled className='' /></> : <>
                                                <Button label={I18Next('setAsDefault', langType).COMMON_KEY} className='p-button-outlined' onClick={() => {
                                                    this.setAsDefault(c.classId)
                                                }} />
                                            </>
                                        }
                                    </div>
                                </div>
                            })
                                : <p className='ma-alert-txt'>No Curriculums available in this section</p>}
                    </div>
                </Dialog>
                <Dialog
                    header={<></>}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    blockScroll={true}
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, selectedLevelForMark: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmMark} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, selectedLevelForMark: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
            </div >
        )
    }
}





const mapStateToProps = (state) => ({
    isLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id,
    updateError: state.branchDataLatest.error,
    updateErrorMessage: state.branchDataLatest.errorMessage,
    defaultLanguage: state.languageReducer.language,
});


export default connect(mapStateToProps, {
    saveBranchesLatest,
    getBranchesLatest
})((withRouter(BranchMaster)));




