import React, { Component } from 'react'
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';
import Authentication from './../../session';


class ResourceUsageReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,


        }



        this.service = new Service();


        // const userAnalyticsBySubjectT = userAnalyticsBySubject.map(item => {
        //     if (item.mp4 > 0 || item.pdf > 0 || item.zip > 0 || item.quiz > 0) {
        //         isGraphDataAvailable = true;
        //     }
        //     return { Videos: item.mp4, Pdf: item.pdf, Ppt: item.zip, Quiz: item.quiz, quizCount: item.quizCount, subjectId: item.subjectId, subjectName: item.subjectName };
        // });
    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${baseUrlAdmin}/analytics/resourceUsageReport`;
        this.service.get(url, true).then(res => {


            if (res && res.status) {
                this.setState({
                    ...res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {

        this.getReport();

    }
    render() {
        const { defaultLanguage } = this.props;
        const { graphData, stats, inlineLoading } = this.state;
        const langType = defaultLanguage
     

        return (
            <div className='ma-relative'>
                <div className='resource-usage-report' style={{background:"#d6edff"}}>
                    <h3 className="resource-title ma-mt10">ResourceUsageReport</h3>
                    <div className='p-grid flex-start col-12'>
                        <div className='col-10   '>
                            {/* <div className='resource-usage-graph'> */}
                                {/* {
                                    graphData && graphData.length ? <ResponsiveBar
                                        data={graphData}
                                        keys={['Last Week', 'Current Week']}
                                        indexBy="key"
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        padding={0.5}
                                        innerPadding={4}
                                        groupMode="grouped"
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        colors={['#3632CE', '#FF761A']}
                                        borderRadius={3}
                                        borderColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            //   legend: 'subjectName',
                                            legendPosition: 'left',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Time in Hours(HH)',
                                            // legend: 'food',
                                            legendPosition: 'middle',
                                            legendOffset: -40
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        enableGridY={false}

                                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                        legends={[
                                            {
                                                dataFrom: 'keys',
                                                anchor: 'bottom',
                                                direction: 'row',
                                                justify: false,
                                                translateX: 0,
                                                translateY: 50,
                                                itemsSpacing: 2,
                                                itemWidth: 100,
                                                itemHeight: 20,
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 0.85,
                                                symbolSize: 18,
                                                symbolShape: 'circle',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                        enableLabel={false}
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                    /> : <></>
                                } */}
                                {graphData && graphData.length && 
                                <div className='trending-table-rup '>
                                <table className='trending-table '>
                                    <tr className=''>
                                        <th></th>
                                        <th className='t-head-v'>videos</th>
                                        <th className='t-head-pdf'>pdf</th>
                                        <th className='t-head-ppt'>ppt</th>
                                        <th className='t-head-q'>quiz</th>
                                    </tr>
                                    <tr>
                                        <td className='color' style={{fontSize:13}}>Current week (hours)</td>
                                        <td className='ma-ml10'>{graphData[0]?.["Current Week"]}</td>
                                        <td>{graphData[1]?.["Current Week"]}</td>
                                        <td>{graphData[2]?.["Current Week"]}</td>
                                        <td>{graphData[3]?.["Current Week"]}</td>
                                    </tr>
                                    <tr>
                                        <td className='color2' style={{fontSize:13}}>Previous week (hours)</td>
                                        <td>{graphData[0]?.["Last Week"]}</td>
                                        <td>{graphData[1]?.["Last Week"]}</td>
                                        <td>{graphData[2]?.["Last Week"]}</td>
                                        <td>{graphData[3]?.["Last Week"]}</td>
                                    </tr>
                                </table>
                                </div>
                                 } 
                        </div>
                        <div className='col-2 '>
                           

                            {
                                graphData && graphData.length ?
                                 <div className='usage-trend '>
                                    <p className='trend-title'>usageTrend</p>
                                    <img src='./new-assets/Path_3270.png' style={{ height: '20px', marginRight: '20px' }} />
                                    <p className='trend-mark'>+ {stats}%</p>
                                </div> : <></>
                            }
                      

                        </div>

                    </div>

                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(ResourceUsageReport));
