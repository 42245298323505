
import React, { Component } from 'react'
import { baseUrlAdmin } from '../../../store/apiConstants';
import moment from 'moment';
import Service from '../../services';
import { connect } from 'react-redux';
import Authentication from './../../session';


class Notifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            "pieData": null,
            "stats": null,
            page: 0,
            // {
            //     "total": 79,
            //     "Progress": "40.51",
            //     "Closed": "31.65",
            //     "Initiated": "27.85"
            // }
            notifications: [],
            stopLoading: false
        };

        this.myRef = React.createRef();
        this.service = new Service();

    }



    getNotifications = () => {
        if (!this.state.stopLoading && !this.state.inlineLoading) {
            this.setState({
                inlineLoading: true
            });
            let { page } = this.state;

            const url = `${baseUrlAdmin}/notification-manager/dashboard?limit=10&page=${page + 1}&sort=-createdAt`;
            this.service.get(url, true).then(res => {
                
                if (res && res.res && res.res.status) {

                    this.setState((prevState) => {
                        return {
                            notifications: prevState.notifications.concat(res.res.data),
                            stopLoading: res.res.data == 0 ? true : false,
                            totalRecords: res.res.totalRecords,
                            inlineLoading: false
                        };
                    });
                } else {
                    this.setState({
                        inlineLoading: false
                    });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    inlineLoading: false
                });

            })
        }
    }

    onScroll = () => {
        const current = this.myRef.current;
        let total = current.scrollHeight - current.clientHeight;
        if (((current.scrollTop / total) * 100) > 80) {
            this.setState((prevState) => {
                return {
                    page: prevState.page + 1
                };
            }, () => {
                this.getNotifications();
            });

        }

    }



    componentDidMount() {
        if (this.props.discussionUser) {
        this.getNotifications();
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.discussionUser != newProps.discussionUser) {
            this.getNotifications();
        }
    }




    render() {
        const { defaultLanguage } = this.props;
        const { notifications, inlineLoading } = this.state;
        const langType = defaultLanguage
   
        return (
            <div className='ma-relative'>
                <div className='notifications-dashboard'>
                    <p className='n-d-title'>News&Notifications</p>

                    <div className='notifi-w  ' ref={this.myRef}
                        onScroll={this.onScroll}>
                        <div>
                            {
                                notifications.map(n => <div className='notify-item'>
                                    <p className='notify-item-title'>{n.title}</p>
                                    <p className='notify-type'>Type: alert <span>{moment(n.createdAt).fromNow()}</span></p>
                                    {/* <p className='notify-item-title'>{n.description}</p> */}
                                </div>)
                            }
                        </div>
                    </div>

                </div>
                {/* {
                    inlineLoading && <InLoadingComponent />
                } */}
            </div>

        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
});

export default connect(mapStatesToProps, {})(Authentication(Notifications));
