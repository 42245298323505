import React, { Component } from 'react'
import { ExamTypes } from '../constants'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { getServerTime } from '../../../utile';
const getYears = () => {
    let currentYear = getServerTime().getFullYear()
    let data = [];
    for (let i = 0; i < 50; i++) {
        data.push({ label: currentYear - i, value: currentYear - i })
    }
    
    return data;
}
export default class PreviousExam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exam: props.data && props.data.exam ? props.data.exam : '',
            year: props.data && props.data.year ? props.data.year : '',
            isAssigned: props.data ? true : false,
            errorMsg: '',
            years: getYears()
        }

    }



    onChange = (field, data) => {
        this.setState({
            [field]: data
        },
            () => {
                this.props.onSave(
                    {
                        id: this.props.id,
                        data: {

                        },
                        assigned: false
                    });
            });

    };


    assignCurriculum = () => {
        const { exam, year } = this.state;

        if (exam != '' && year != '') {
            this.setState({
                isAssigned: true
            }, () => {
                this.props.onSave(
                    {
                        id: this.props.id,
                        data: {
                            exam,
                            year
                        },
                        assigned: true
                    });
            })
        } else {
            this.setState({
                errorMsg: 'Exam and year is required'
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 3000);
        }



    }


    onEditClick = () => {
        if (this.props.previousExam.every((s) => s.assigned === true)) {
            this.setState({
                isAssigned: false,
            }, () => {
                this.props.onSave(
                    {
                        id: this.props.id,
                        assigned: false
                    });
            });
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please add or remove which is in edit state.', life: 3000 });
        }

    }





    render() {
        return (
            <div className='grid mt-1 ml-1' >
                <div className='col-12 p-md-12'>
                    <div className='grid ma-w100p ma-no-pm' >
                            <Dropdown value={this.state.exam}
                                disabled={this.state.isAssigned}
                                className='w-3 ma-w200 ma-mr10'
                                options={ExamTypes}
                                optionLabel="label"
                                optionValue="value"
                                onChange={(e) => this.onChange('exam', e.value)}
                                placeholder="Select Exam" />

                            <Dropdown value={this.state.year}
                                disabled={this.state.isAssigned}
                                className='w-3 ml-3 ma-w200 ma-mr10'
                                options={this.state.years}
                                optionLabel="label"
                                optionValue="value"
                                onChange={(e) => this.onChange('year', e.value)}
                                placeholder="Select Year" />
                            {this.state.isAssigned ? <Button
                                label={`Edit`} className='ml-3'
                                onClick={this.onEditClick}
                            />
                                : <Button
                                    label={`Save`} className='ml-3'
                                    onClick={this.assignCurriculum}
                                />
                            }
                            <span className="previous-remove ma-pointer ml-3" onClick={() => { this.props.removePreviousExamRow(this.props.id) }}>
                                <i className="pi pi-times"></i>
                            </span>
                    </div>

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    <p className='ma-error-msg'>{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}
