import { Dropdown } from 'primereact/dropdown'
import React, { Component } from 'react'
import { CodeLinkIcon, MailIcon } from '../svgIcons'
import { InputText } from 'primereact/inputtext'
import { BasicLazyParams } from '../../utile'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { RadioButton } from 'primereact/radiobutton'
import ClassetStudentSearch from '../../classetComponents/classetStudentSearch'
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD'
import ClassetDropdown from '../../classetComponents/classetDropDown'
import Service from '../../services'
import { baseUrlAdmin } from '../../store/apiConstants'
import ClassetInputText from '../../classetComponents/classetInputText'
import { Toast } from 'primereact/toast'
import LoadingComponent from '../loadingComponent'
import { debounce } from 'lodash';
const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "Enrollment Number", value: "enrollmentNumber" }
]
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]


const feeConcessionTypesOptions = [
    { value: 'merit', label: 'Merit-based' },
    { value: 'sports', label: 'Sports' },
    { value: 'financial', label: 'Financial Aid' },
    { value: 'alumni', label: 'Alumni' },
    { value: 'staff', label: 'Staff Child' }
];

const feeData = [
    {
        refundableFeeType: "Tuition Fee",
        feeCategory: "Tuition Fee",
        paidFee: 0,
        refundAmt: 0,
        totalDue: 0
    },
    {
        refundableFeeType: "Library Fee",
        feeCategory: "Library Fee",
        paidFee: 1000,
        refundAmt: 300,
        totalDue: 1000 - 300
    },
    {
        refundableFeeType: "Lab Fee",
        feeCategory: "Lab Fee",
        paidFee: 2000,
        refundAmt: 0,
        totalDue: 0
    },
    {
        refundableFeeType: "Sports Fee",
        feeCategory: "Sports Fee",
        paidFee: 0,
        refundAmt: 0,
        totalDue: 2000
    },
    {
        refundableFeeType: "Transport Fee",
        feeCategory: "Transport Fee",
        paidFee: 3000,
        refundAmt: 1000,
        totalDue: 3000 - 1000
    },
    {
        refundableFeeType: "Exam Fee",
        feeCategory: "Exam Fee",
        paidFee: 2500,
        refundAmt: 800,
        totalDue: 2500 - 800
    },
    {
        refundableFeeType: "Hostel Fee",
        feeCategory: "Hostel Fee",
        paidFee: 4500,
        refundAmt: 1500,
        totalDue: 4500 - 1500
    },
    {
        refundableFeeType: "Tuition Fee",
        feeCategory: "Tuition Fee",
        paidFee: 6000,
        refundAmt: 2500,
        totalDue: 6000 - 2500
    },
    {
        refundableFeeType: "Library Fee",
        feeCategory: "Library Fee",
        paidFee: 1200,
        refundAmt: 400,
        totalDue: 1200 - 400
    },
    {
        refundableFeeType: "Lab Fee",
        feeCategory: "Lab Fee",
        paidFee: 3500,
        refundAmt: 1000,
        totalDue: 3500 - 1000
    }
];




export class FeeConcessionOrRefund extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lazyParams: BasicLazyParams,
            searchType: 1,
            searchInput: "",
            studentsData: [],
            openConcessionDialog: false,
            raiseData: [],
            RefundfeeData: feeData,
            studentId: '',
            searchLevel: 1,
            feeConcessionTypesOptions: feeConcessionTypesOptions,
            searchType: searchTypeOptions[0].value,
        };
        this.debouncedApiCall = debounce(this.getStudentData, 500)
        this.service = new Service();
    }


    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData,
            studentId: ""
        }, () => { this.getStudents(); this.getStudentData() })
    }


    getStudentData = () => {

        this.setState({ isLoading: true })
        let academicYear = localStorage.getItem('userAcademicYear')
        let { lazyParams, dropdownsData, searchType, searchValue, studentId } = this.state;
        const { branchId, boardId, classId, sectionId } = dropdownsData
        let url = `${baseUrlAdmin}/concession/get-students?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`

        let username = searchType === 'studentName' ? searchValue : ""
        let enrollmentNo = searchType === 'enrollmentNumber' ? searchValue : ""
        let status = searchType === 'status' ? searchValue : ""

        let payload = {
            branchId,
            boardId,
            classId,
            sectionId,
            studentId,
            academicYear,
            username,
            enrollmentNo,
            status
        }
        if ((branchId && boardId && classId && sectionId) || studentId || username || enrollmentNo || status) {
            this.service.post(url, payload, true).then(data => {

                if (data?.status) {
                    this.setState({
                        studentsData: data?.res?.data,
                        isLoading: false
                    })

                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                }

            }).catch(error => {
                this.setState({ isLoading: false })
                this.toast.show({
                    severity: 'error',
                    summary: 'Network error',
                    detail: 'An error occurred while processing your request.',
                    life: 3000
                });

            })
        } else {
            this.setState({ isLoading: false });
        }
    }

    getStudents = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        // let { lazyParams } = this.state;
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/fine/student`
        let payload = {
            "branchId": this.state.dropdownsData?.branchId,
            "boardId": this.state.dropdownsData?.boardId,
            "classId": this.state.dropdownsData?.classId,
            "sectionId": this.state.dropdownsData?.sectionId,
            "academicYear": academicYear
        }
        if (payload?.sectionId) {
            this.service.post(url, payload, true).then(data => {
                // console.log(data, "datastudent")
                const studentsData = data?.res?.data.map(each => {
                    return {
                        label: each?.name,
                        value: each?.user_id
                    }
                })

                if (data?.status) {
                    this.setState({
                        students: studentsData,
                        isLoading: false
                    })

                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                }

            }).catch(error => {
                this.setState({ isLoading: false })

            })
        } else {
            this.setState({ isLoading: false });
        }
    }

    getRaiseConcessionsData = () => {
        this.setState({ isLoading: true })
        let academicYear = localStorage.getItem('userAcademicYear')
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/concession//get-feetype-terms/${this.state?.studentInfo?.user_id}`

        this.service.get(url, true).then(data => {
            const total = data?.res?.data.reduce((accumulator, each) => {
                if (each.concession !== undefined) {
                    accumulator += Number(each.concession);
                }
                return accumulator;
            }, 0);

            if (data?.status) {
                this.setState({
                    raiseData: data?.res?.data,
                    isLoading: false,
                    totalRecords: data?.res?.data?.length,
                    totalConcessionRaised: total
                })

            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }

        }).catch(error => {
            this.setState({ isLoading: false })

        })
    }

    onHandleConcession = (data) => {
        console.log(data, "studentInfo")
        this.setState({
            openConcessionDialog: true,
            studentInfo: data
        }, () => { this.getRaiseConcessionsData() })
    }
    onHandleConcessionClose = () => {
        this.setState({
            openConcessionDialog: false,
            raiseData: [],
            studentInfo: {}
        })
    }
    onHandleRefund = (data) => {
        this.setState({
            openeRefundDialog: true,
            studentInfo: data
        })
    }
    onHandleRefundClose = (data) => {
        this.setState({
            openeRefundDialog: false,
        })
    }

    handleSetSearchValue = (value) => {
        this.setState({
            searchValue: value,
            dropdownsData: {
                boardId: '',
                branchId: "",
                classId: '',
                sectionId: '',
            }
        }, () => {
            this.debouncedApiCall()

        })
    }
    handleSearchLevel = (value) => {

        this.setState({ searchLevel: value, studentId: "", searchValue: "", studentsData: [] })
    }

    onStudentChange = (e) => {
        this.setState({ studentId: e.value }, () => {
            this.getStudentData()
        })

    }


    handleTermWisePercentageValue = (e, rowData) => {

        let concessionAmt = Number(e.target.value)
        if (rowData.amountPaid === 0) {
            if (rowData?.dueAmount >= concessionAmt) {
                const updatedData = this.state.raiseData.map(item => {
                    if (item._id === rowData._id) {
                        return { ...item, currentConAmt: concessionAmt };
                    }
                    return item;
                });
                this.setState({ raiseData: updatedData });
            }

        }
    };


    handleConcessionType = (e, rowData) => {

        const updatedData = this.state.raiseData.map(item => {
            if (item._id === rowData._id) {
                return { ...item, concessionType: e.target.value };
            }
            return item;
        });
        this.setState({ raiseData: updatedData });
    }


    handleSetSearchTypeValue = (value) => {

        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "studentName") {
                this.setState({ isUserNameSearch: true, })
            } else {
                this.setState({ isUserEnrollSearch: true, })
            }

        })
    }
    onHandleRaiseConcession = () => {
        let payload = []
        console.log(this.state.raiseData, "raiseData")
        this.state?.raiseData?.filter((el) => {
            if (el?.currentConAmt || el?.concessionType) {
                payload.push(el)
            }
        })
        let flag = payload?.every((each) => {
            if (each.currentConAmt !== undefined && each.currentConAmt !== "" && each.concessionType !== undefined && each.concessionType !== "") {
                return true
            } else {
                return false
            }
        })

        let url = `${baseUrlAdmin}/concession/raise-concessions`;
        if (payload?.length && flag) {
            this.service.post(url, payload, true).then(data => {
                if (data.status && data.res.status) {
                    this.setState({
                        openConcessionDialog: false,
                        raiseData: [],

                    })
                    this.toast?.show({ severity: 'info', summary: 'Success', detail: data.res.message, life: 3000 });
                    this.getStudentData()
                } else {
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: data.errMessage, life: 3000 });

                }
            });
        } else {
            this.toast?.show({ severity: 'error', summary: 'Please Enter All the required Concession Type and TermWise Value Fields', detail: 'Validation failed' });

        }


    }


    render() {
        console.log(this.state, "madhu")
        return (
            <div className="ma-main">

                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">

                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>

                    <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            <BoardToSectionDD selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                        </div>
                    </div>

                    <div className="col-12 ml-5">
                        <label className='label'>Student<span className='ma-required'>*</span></label>
                        <div className='mt-2'>
                            <ClassetDropdown
                                icon={<MailIcon />}
                                id='student'
                                className=""
                                height="48px"
                                width="327px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                disabled={this.state?.searchLevel === 2}
                                //     onChange={(e) => {this.setState({studentId:e.value}) 
                                //     this.getTermBasedData()

                                // }}
                                onChange={this.onStudentChange}
                                placeholder={(
                                    <div>
                                        <span className=''>Select Student</span>
                                    </div>
                                )}
                            />

                        </div>
                        {/* {formValidations && !formValidations.fields['paymentMode'].isValid && <p className="p-error">{formValidations.fields['paymentMode'].errorMsg}</p>} */}
                    </div>

                </div>

                <DataTable ref={(el) => this.dt = el}
                    value={this.state.studentsData}
                    dataKey="id"
                    paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts} 
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >

                    <Column filterField="studentName" field="user_info.given_name" sortable bodyClassName='text-center' header="Student Name" alignHeader={"center"} />
                    <Column filterField="enrollmentNo" field="enrollmentNo" sortable bodyClassName='text-center' header="Enrollment No" alignHeader={"center"} />
                    <Column filterField="grade" sortable bodyClassName='text-center' header="Grade-Section" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                        return (

                            <p>{rowData ? `${rowData?.user_info?.["custom:grade"]} - ${rowData?.user_info?.["sectionName"]}` : null}</p>
                        )
                    }} />
                    < Column filterField="board" field="user_info.custom:board" sortable bodyClassName='text-center' header="Board" alignHeader={"center"} />
                    <Column bodyClassName='text-center' header="Past Concession" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                        return (
                            <div className='custom-fee-pay '>
                                <Button className='text-center' style={{ backgroundColor: '#076EFF', border: '1px solid #076EFF' }} onClick={() => this.onHandleConcession(rowData)}>
                                    <p className='payButton pl-2' style={{ color: rowData.totalDue !== 0 ? "#ffffff" : "#C6CED2" }}>Raise</p>
                                </Button>
                            </div>



                        )
                    }} />
                    <Column sortable bodyClassName='text-center' header="Refund" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                        return (
                            <div className='custom-fee-pay text-center'>
                                <Button className='text-center' style={{ backgroundColor: '#076EFF', border: '1px solid #076EFF' }} onClick={() => this.onHandleRefund(rowData)}>
                                    <p className='payButton pl-2' style={{ color: rowData.totalDue !== 0 ? "#ffffff" : "#C6CED2" }}>Refund</p>
                                </Button>
                            </div>



                        )
                    }} />

                </DataTable>


                <Dialog
                    isopen={this.state.openConcessionDialog}
                    visible={this.state.openConcessionDialog}
                    className="w-11"
                    draggable={false}
                    onHide={this.onHandleConcessionClose}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2 mb-4'>
                            <h3 className="formhead text-center ">Raise Concession</h3>

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center  align-items-center mb-4'>
                            {/* <Button className='formSubmitButtonn p-4 ' onClick={this.onHandleRaiseConcession}>
                                <p className='formSubmitButtonnText'>Raise Concession</p>
                            </Button>
                            <Button className='formCancelButtonn p-4'
                                onClick={this.onHandleConcessionClose} >
                                <p className='formCancelButtonnText'>Cancel</p>
                            </Button> */}
                        </div>
                    )}

                >

                    <>
                        <div className='grid m-2'>
                            <div className="col-4 " >
                                <label className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Student Name</label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='studentName'
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        value={this.state.studentInfo?.user_info?.given_name}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-4 " >
                                <label className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Enrollment No</label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='enrollmentNo'
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        height="48px"
                                        width="368px"
                                        value={this.state.studentInfo?.user_info?.enrollmentNo}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-4" >
                                <label className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Grade</label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='grade'
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        height="48px"
                                        width="368px"
                                        value={this.state.studentInfo?.user_info?.['custom:grade']}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-4" >
                                <label className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Section</label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='section'
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        height="48px"
                                        width="368px"
                                        value={this.state.studentInfo?.user_info?.sectionName}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-4" >
                                <label className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Board</label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='board'
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        height="48px"
                                        width="368px"
                                        value={this.state.studentInfo?.user_info?.['custom:board']}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-4 mt-4" >
                                <p className='ma-label-s2 font-serif concession-amt'>Total Concession Raised: <span className='underline' style={{ color: "green" }}>{this.state.totalConcessionRaised}</span></p>
                            </div>

                        </div>

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state?.raiseData}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        >

                            <Column filterField="feeType" field="feeType" sortable bodyClassName='text-center' header="Fee Type" alignHeader={"center"} />
                            <Column filterField="feeTermName" sortable bodyClassName='text-center' header="Fee Term Name" alignHeader={"center"} body={(rowData, index) => {
                                return (
                                    <p>{`Term ${rowData?.term}`}</p>
                                )
                            }} />
                            <Column filterField="termWiseActualValue" field="termWiseActualValue" sortable bodyClassName='text-center' header="Term wise Actual Fee" alignHeader={"center"} />
                            <Column filterField="concession" field="concession" sortable bodyClassName='text-center' header="Concession" alignHeader={"center"} />
                            <Column filterField="amountPaid" field="amountPaid" sortable bodyClassName='text-center' header="Paid Fee" alignHeader={"center"} />
                            <Column bodyClassName='text-center' header="Concession Type" alignHeader={"center"} body={(rowData, index) => {
                                return (
                                    <>
                                        <ClassetDropdown
                                            id='paymentType'
                                            className=""
                                            height="48px"
                                            width="216px"
                                            options={this.state.feeConcessionTypesOptions}
                                            value={rowData?.concessionType || ""}
                                            onChange={(e) => this.handleConcessionType(e, rowData)}
                                            placeholder="Select Concession Type"
                                            disabled={rowData?.amountPaid === 0 ? rowData?.termWiseActualValue === 0 ? true : false :  true}

                                        />
                                    </>
                                )
                            }} />
                            <Column bodyClassName='text-center' header="Term wise Percentage/Value" alignHeader={"center"} body={(rowData, index) => {

                                return (
                                    <>
                                        <ClassetInputText
                                            className="text-xl"
                                            height="48px"
                                            width="216px"
                                            placeholder={!(rowData?.currentConAmt) ? 'Enter Value' : ''}
                                            value={rowData?.amountPaid === 0 ? rowData?.currentConAmt || '' : rowData?.currentConAmt || 0}
                                            onChange={(e) => this.handleTermWisePercentageValue(e, rowData)}
                                            keyfilter="int"
                                            disabled={rowData?.amountPaid === 0 ? rowData?.termWiseActualValue === 0 ? true : false :  true}
                                        />
                                    </>
                                )
                            }} />
                            <Column filterField="concessionValue" field="termWisePercentageOrValue" sortable bodyClassName='text-center' header="Concession Value" alignHeader={"center"} body={(rowData, index) => {
                                return (<p>{rowData?.currentConAmt || 0}</p>
                                )
                            }} />
                            <Column filterField="totalDue" field="" sortable bodyClassName='text-center' header="Total Due" alignHeader={"center"} body={(rowData, index) => {
                                // console.log(Number(rowData?.dueAmount) - Number(rowData?.currentConAmt))
                                let dueAmount = Number(rowData?.dueAmount) || 0
                                let conAmt = Number(rowData?.currentConAmt) || 0
                                let finalDue = Number(dueAmount - conAmt).toFixed(2) // (Calculating dueAmount from backend substracting ->   termFee - pastConcession - amountPaid  )
                                return (
                                    <p>{finalDue}</p>
                                )
                            }} />

                        </DataTable>
                        <div className='flex justify-content-center  align-items-center mt-4'>
                            <Button label='Raise Concession' className='submitButtonn mr-4 button text-lg ' onClick={this.onHandleRaiseConcession} />
                            <Button className='cancelButtonn '
                                onClick={this.onHandleConcessionClose} >
                                <p className='cancelButtonnText'>Cancel</p>
                            </Button>
                        </div>

                    </>
                </Dialog>


                <Dialog
                    isopen={this.state.openeRefundDialog}
                    visible={this.state.openeRefundDialog}
                    className="w-11"
                    draggable={false}
                    onHide={this.onHandleRefundClose}
                    closable={false}
                    header={() => {
                        return (<div className='mt-2 mb-4'>
                            <h3 className="formhead text-center ">Refund Fee</h3>

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center  align-items-center mb-4'>
                            <Button className='formSubmitButton p-4 ' >
                                <p className='formSubmitButtonText'>Refund Fee</p>
                            </Button>
                            <Button className='formCancelButton p-4'
                                onClick={this.onHandleRefundClose} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}

                >

                    <>
                        <div className='grid m-2'>
                            <div className="col-4 " >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Student Name</p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11" style={{ backgroundColor: '#E6E8EA', border: '1px solid #E6E8EA' }}>
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText placeholder="Student Name" className='w-full h-3rem' value={this.state.studentInfo?.studentName} disabled={true} />
                                    </span>

                                </div>
                            </div>
                            <div className="col-4 " >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Enrollment No</p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11" style={{ backgroundColor: '#E6E8EA', border: '1px solid #E6E8EA' }}>
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText className='w-full h-3rem border-noround ' value={this.state.studentInfo?.enrollmentNo} disabled={true}
                                            placeholder="Enrollment No" />
                                    </span>

                                </div>
                            </div>

                            <div className="col-4" >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Grade</p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11" style={{ backgroundColor: '#E6E8EA' }}>
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText className='w-full h-3rem border-noround ' value={this.state.studentInfo?.grade}
                                            placeholder="Grade" disabled={true}
                                        />
                                    </span>

                                </div>
                            </div>
                            <div className="col-4" >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Section</p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11" style={{ backgroundColor: '#E6E8EA', border: '1px solid #E6E8EA' }}>
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText className='w-full h-3rem border-noround ' value={this.state.studentInfo?.section}
                                            placeholder="Section" disabled={true}
                                        />
                                    </span>

                                </div>
                            </div>
                            <div className="col-4" >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Board</p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11" style={{ backgroundColor: '#E6E8EA', border: '1px solid #E6E8EA' }}>
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText className='w-full h-3rem border-noround ' value={this.state.studentInfo?.board}
                                            placeholder="Board" disabled={true}
                                        />
                                    </span>

                                </div>
                            </div>

                            <div className="col-4  mt-4" >
                                <p className='ma-label-s2 font-serif concession-amt'>Total Refund Raised: <span className='underline' style={{ color: "green" }}>Rs.1,25,236</span></p>
                            </div>
                            <div className="col-4  " >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Transaction ID<span className='ma-required'>*</span></p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText placeholder="Transaction ID" className='w-full h-3rem' />
                                    </span>

                                </div>
                            </div>
                            <div className="col-4" >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Destination Bank<span className='ma-required'>*</span></p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText placeholder="Destination Bank" className='w-full h-3rem' />
                                    </span>

                                </div>
                            </div>
                            <div className="col-4  " >
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Bank Account No./Cheque No/Manual Reciept No.<span className='ma-required'>*</span></p>
                                <div className="flex justify-content-start  align-items-center mt-1 pl-2 input-field" >
                                    <span className="p-input-icon-left w-11">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <InputText placeholder="Bank Account No./Cheque No/Manual Reciept No." className='w-full h-3rem' />
                                    </span>

                                </div>
                            </div>

                        </div>

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.RefundfeeData}
                            dataKey="id"
                            paginator
                            lazy
                            responsiveLayout="scroll"
                            className="dataTableValuesText"
                            showGridlines
                            selectionMode={'checkbox'}
                            columnResizeMode="expand"
                            resizableColumns
                            // selection={this.state.selectedProducts} 
                            // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        >

                            <Column filterField="refundableFeeType" field="refundableFeeType" sortable bodyClassName='text-center' header="Refundable Fee Type" alignHeader={"center"} />
                            <Column filterField="feeCategory" field="feeCategory" sortable bodyClassName='text-center' header="Fee Category" alignHeader={"center"} />
                            <Column filterField="paidFee" field="paidFee" sortable bodyClassName='text-center' header="Paid Fee" alignHeader={"center"} />
                            <Column bodyClassName='text-center' header="Refundable Amount" alignHeader={"center"} body={(rowData, index) => {
                                return (
                                    <>
                                        <span className="p-input-icon-right">
                                            <i className="pi pi-times p-button-rounded p-button-secondary" aria-label="Cancel" />
                                            <InputText step={{ width: "151px", height: "24px" }} />
                                        </span>
                                    </>
                                )
                            }} />
                            <Column filterField="refundAmt" field="refundAmt" bodyClassName='text-center' header="Refund Amount" alignHeader={"center"} />
                            <Column filterField="totalDue" field="totalDue" sortable bodyClassName='text-center' header="Total Due" alignHeader={"center"} />

                        </DataTable>

                    </>
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        )
    }
}

export default FeeConcessionOrRefund