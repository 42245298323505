import React, { Component } from 'react'
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import LoadingComponent from '../loadingComponent';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import SvgViewer from './../customComponents/svgViewer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import Service from '../services';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import moment from 'moment';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import OrgConfiguration from './orgConfiguration';
import withRouter from '../lib/withRouter';
class OrganizationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            globalFilter: null,
            loading: false,
            totalRecords: 0,
            customers: null,
            selectAll: false,
            selectedCustomers: null,
            selectedRepresentative: null,
            columns: [{ columnName: 'User App', isVisible: false, id: 'userApp' },
            { columnName: 'Admin App', isVisible: false, id: 'adminApp' },
            { columnName: 'Org Code', isVisible: false, id: 'orgCode' },
            { columnName: 'Org Id', isVisible: false, id: '_id' }
            ]

        }
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    removeDisable = (classNames) => {
        return classNames.replace('p-disabled', '')

    }




    onNextClick = (e, onClick) => {
        console.log(e);

        this.props.getNextRecords();

        // let productsTemp = records.map((r) => {
        //     r.code = 'sha' + index;
        //     index++;
        //     return r;
        // })
        // this.setState({ products: productsTemp }, () => {
        //     onClick(e);
        // })
    }

    onEditConfigClick = (orgData) => {
        
        this.setState({
            editOrgData: orgData,
            isShowConfigChange: true


        })
    }


    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Organization List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.props.globalSearch} className="ml-4  h-2rem w-15rem" onChange={this.props.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.ORG_SEARCH}>
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} >
                            <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4>View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className='mt-2'
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (<>

            {/* {this.props.isShowConfigChange ? <OrgConfiguration  onClose={() => { this.setState({ isShowConfigChange: false }) }} orgId={this.props.editOrganization._id} /> : <> */}
            <div className="card datatable-crud-demo mt-4">
                <DataTable ref={(el) => this.dt = el} className='p-datatable-gridlines'
                    responsiveLayout="scroll"
                    dataKey="_id"
                    value={this.props.organizations}
                    filters={this.props.lazyParams.filters}
                    // loading={this.props.isLoading}
                    // paginatorLeft={() => {
                    //     return <>
                    //         <p className='avail-q'> Available Organizations:  <span>{this.props.totalRecords}</span> </p>
                    //     </>
                    // }}
                    filterDisplay="row"
                    paginator
                    lazy
                    onPage={this.props.onPage}
                    onSort={this.props.onSort}
                    onFilter={this.props.onFilter}
                    first={this.props.lazyParams.first}
                    last={this.props.totalRecords}
                    rows={this.props.lazyParams.rows}
                    sortField={this.props.lazyParams.sortField}
                    sortOrder={this.props.lazyParams.sortOrder}
                    totalRecords={this.props.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    //paginatorTemplate={paginatorTemplate}
                    //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    // globalFilter={this.state.globalFilter}
                    header={header}>

                    <Column sortable filter filterMatchMode='contains' showFilterMenu ={false} filterField='name' filterPlaceholder='Search by name' field="name" header="Organization Name" />
                    <Column sortable filter filterMatchMode='contains' showFilterMenu ={false} field="title" filterPlaceholder='Search by title' header="Organization Title" />
                    {
                        this.isColumnVisible('userApp') && <Column sortable filter  showFilterMenu ={false} field="subDomain" header="User App"
                            body={(rowData) => {
                                return (<>
                                    <a className="ma-url ma-pointer" href={`https://${rowData.subDomain}.macademy.in`} target="_blank">{rowData.subDomain + '.macademy.in'}</a>
                                </>)
                            }}
                        />}
                    {
                        this.isColumnVisible('adminApp') && <Column sortable filter showFilterMenu ={false} field="subDomain" header="Admin App"
                            body={(rowData) => {
                                return (<>
                                    <a className="ma-url ma-pointer"
                                        //  href="https://b2bsas-prod-admin.web.app"
                                        href={`https://${rowData.subDomain}-admin.macademy.in`}
                                        target="_blank">{rowData.subDomain + '-admin.macademy.in'}</a>
                                </>)
                            }}
                        />
                    }
                    {
                        this.isColumnVisible('orgCode') && <Column sortable filter showFilterMenu ={false} field="orgCode" header="Org Code" />

                    }
                    {
                        this.isColumnVisible('_id') && <Column sortable filter showFilterMenu ={false} field="_id" header="Org Id" />

                    }

                    <Column sortable field="tenureEndDate" header="End Date"
                        body={(rowData) => {
                            return (<>
                                <span>{moment(rowData.tenureEndDate).format('DD-MM-YYYY')}</span>
                            </>)
                        }}
                    />
                    <Column sortable field="isActive" header="Status" body={(rowData) => {
                        return (
                            <Authorizer permId={PERMISSIONS.CHANGE_ORG_STATUS} >
                                <div className="ma-status">
                                    <span className="ma-status-text"  >{rowData.isActive ? 'Active' : 'InActive'} </span>  <InputSwitch disabled onChange={(e) => { this.props.onStatusChange(e, rowData) }} checked={rowData.isActive} />
                                </div>
                            </Authorizer>
                        )
                    }} />
                    <Column header="Config" body={(rowData) => {
                        return (<>
                            <Authorizer permId={PERMISSIONS.EDIT_ORGANIZATION} >
                                <span data-pr-tooltip="Configuration" data-pr-position="bottom" className={`edit${rowData._id}`} onClick={() => { this.props.onEditConfigClick(rowData) }}>
                                    {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                    <i className="pi pi-cog" width="20" height="20"></i>
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} />
                            </Authorizer>
                        </>
                        );
                    }} ></Column>
                    <Column header="Edit" body={(rowData) => {
                        return (<>
                            <Authorizer permId={PERMISSIONS.EDIT_ORGANIZATION} >
                                <span data-pr-tooltip="Edit Organization" data-pr-position="bottom" className={`edit${rowData._id}`} onClick={() => { this.props.onEditOrgClick(rowData) }}>
                                    {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                    <i className="pi pi-file-edit"></i> 
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} />
                            </Authorizer>
                        </>
                        );
                    }} ></Column>
                    {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                </DataTable>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />


            </div>
            {/* </>} */}
        </>
        )
    }
}



const mapStateToProps = (state) => ({

});



export default connect(mapStateToProps, {

})((withRouter(OrganizationList)));

//Authentication