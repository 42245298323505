import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ClassetVideoIcon } from '../../svgIcons';
import './styles.scss'
import { CircularProgressbar, buildStyles, } from 'react-circular-progressbar';
import ChangingProgressProvider from './ChangingProgressProvider';

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const percentage = 50;

const chapters = [
    {
        id: '1',
        chapterName: 'Chapter 1 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '2',
        chapterName: 'Chapter 2 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '3',
        chapterName: 'Chapter 3 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '4',
        chapterName: 'Chapter 4',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '5',
        chapterName: 'Chapter 5',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '6',
        chapterName: 'Chapter 6 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '7',
        chapterName: 'Chapter 7',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '8',
        chapterName: 'Chapter 8',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '9',
        chapterName: 'Chapter 9 ',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '10',
        chapterName: 'Chapter 10',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '11',
        chapterName: 'Chapter 11',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },
    {
        id: '12',
        chapterName: 'Chapter 12',
        topics: [
            { topicName: 'Topic 1' },
            { topicName: 'Topic 2' },
            { topicName: 'Topic 3' },
            { topicName: 'Topic 4' },
            { topicName: 'Topic 5' },
            { topicName: 'Topic 6' },

        ]
    },

]


const TabElements = [
    {
        tabName: 'Video', value: 'mp4'
    }, {
        tabName: 'Interactions', value: 'zip'
    }, {
        tabName: 'Ebooks', value: 'ebook'
    }, {
        tabName: 'PDF', value: 'pdf'
    }, {
        tabName: 'Quiz', value: 'quiz'
    },
]

const contentResources = [
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    }, {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    }, {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    }, {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
    {
        title: 'Title of the Resource',
        medium: 'English',
        time: '15 mins'
    },
]
class DigitalLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {

            selectedChapter: '',
            selectedTab: 'mp4'

        };
    }

    componentDidMount() {

    }

    OpenTopicsForChapter = (chapterId) => {
        if (this.state.selectedChapter == chapterId) {
            this.setState({
                selectedChapter: ''
            })
        } else {
            this.setState({
                selectedChapter: chapterId
            })
        }


    }


    render() {

        return (
            <div className=' px-5'>

                <div className='grid  flex justify-content-between'>
                    <div className="col-3 ">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Board<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                <Dropdown
                                    className='h-3rem w-full border-round-md pt-2 border-none text-lg pl-3'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder="Select Board" />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 ">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Grade<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                <Dropdown
                                    className='h-3rem w-full border-round-md pt-2 border-none text-lg pl-3'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder="Select Grade" />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 ">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Subject<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                <Dropdown
                                    className='h-3rem w-full border-round-md pt-2 border-none text-lg pl-3'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder="Select Subject" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className='grid mt-3 ml-1'>

                    <div className='col-2 card mr-5'>

                        <p className='text-sm	mb-3' style={{ color: '#6C757D' }}>Grade Name</p>

                        <p className='font-bold	mb-3' style={{ color: '#495057', }}> <span className='pi pi-home font text-base		'>  </span>  Subject Name</p>
                        <div style={{ backgroundColor: '#DEE2E6', height: 0.5, width: '100%', padding: 0 }}></div>

                        <div className='mt-3 ma-scroll-vr-smoke' style={{ height: 700 }}>
                            {
                                chapters.map((chapter, index) => {
                                    return (
                                        <div className='mb-3 flex justify-content-between cursor-pointer' onClick={() => this.OpenTopicsForChapter(chapter.id)}>
                                            <p className='' style={{ color: '#495057' }}>{chapter.chapterName}</p>
                                            {
                                                this.state.selectedChapter == chapter.id ?
                                                    <span className='pi pi-chevron-up mr-3' style={{ alignSelf: 'center', color: '#495057' }}></span> :
                                                    <span className='pi pi-chevron-down mr-3' style={{ alignSelf: 'center', color: '#495057' }}></span>
                                            }
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='col-9 card'>
                        <div className='m-3 flex flex-row justify-content-between'>
                            <div className=''>
                                <p className='font-bold text-3xl mb-2' style={{ color: 'black' }}>Chapter Name : Chapter 1</p>
                                <p className='font-medium text-xl	' style={{ color: 'black' }}>Topic Name : Topic 1</p>
                            </div>
                            <div style={{ width: '80px', height: '80px', }}>
                                {/* <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                    styles={{
                                        root: {},
                                        path: {
                                            stroke: `rgba(62, 152, 199, ${percentage / 100})`,
                                            strokeLinecap: 'butt',
                                            transition: 'stroke-dashoffset 0.5s ease 0s',
                                            transform: 'rotate(0.25turn)',
                                            transformOrigin: 'center center',
                                        },
                                        trail: {
                                            stroke: '#d6d6d6',
                                            strokeLinecap: 'butt',
                                            transform: 'rotate(0.25turn)',
                                            transformOrigin: 'center center',
                                        },
                                        text: {
                                            fill: 'black',
                                            fontSize: '20px',
                                            fontWeight: 'bolder',
                                            textAlign:'center'
                                        },
                                        background: {
                                            fill: '#076EFF',
                                        },
                                    }}
                                /> */}
                                {/* <ChangingProgressProvider values={[66]}>
                                    {(percentage) => (
                                        <CircularProgressbar
                                            value={percentage}
                                            text={`${percentage}%`}
                                            styles={buildStyles({
                                                pathTransitionDuration: 0.15,
                                                textColor: "red",
                                                pathColor: "turquoise",
                                                trailColor: "gold",
                                            })}
                                        />
                                    )}
                                </ChangingProgressProvider> */}
                                <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                    styles={
                                        {
                                            ...buildStyles({
                                                pathColor: "#076EFF",
                                                trailColor: "lightgrey",
                                                textColor: 'black',
                                            }), text: {
                                                fill: 'black',
                                                fontSize: '20px',
                                                fontWeight: 'bolder',
                                                paddingRight:'20px'
                                            },
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div className='shadow-1 px-2' style={{ backgroundColor: '#F4F5F6', borderRadius: 10, width: '100%', height: 55, display: 'flex', alignItems: 'center', overflowX: 'auto', }}>
                            {TabElements.map((tab, index) => (
                                <div key={index} className={` cursor-pointer ${this.state.selectedTab === tab.value ? 'shadow-1' : ''}`} style={{ backgroundColor: this.state.selectedTab === tab.value ? 'white' : '#F4F5F6', borderRadius: 5, minWidth: 100, marginRight: 5, overflow: 'auto', whiteSpace: 'nowrap' }} onClick={() => this.setState({ selectedTab: tab.value })}>
                                    <p className='px-8 py-3 inter500semibold' style={{ margin: 0, }}>{tab.tabName}</p>
                                </div>
                            ))}
                        </div>


                        <div className='pt-3 ma-scroll-vr-smoke' style={{ height: '70%' }}>

                            {
                                contentResources.map((res, index) => {
                                    return (
                                        <div className='mt-2' >
                                            <div className='flex justify-content-between px-4'>

                                                <div className='flex pb-2'>
                                                    <div className='border-3 mr-4' style={{ height: 30, width: 30, borderColor: '#076EFF', borderRadius: 25, justifyContent: 'center', alignItems: 'center', display: 'flex', alignSelf: "center" }}>
                                                        <p className='font-bold' style={{ color: '#076EFF', fontSize: 20, }}>{index + 1}</p>
                                                    </div>
                                                    <div>
                                                        <p className='text-xl mb-2 text-black-alpha-90' style={{ fontWeight: 'bolder', }}>{res.title}</p>
                                                        <p className='interRegular14'>Medium : {res.medium}</p>
                                                        <p className='interRegular14'>Run Time : {res.time}</p>
                                                    </div>
                                                </div>
                                                <div style={{ alignSelf: 'center' }}>
                                                    <ClassetVideoIcon />
                                                </div>
                                            </div>
                                            <div style={{ backgroundColor: '#DEE2E6', height: 0.25, width: '100%', padding: 0 }}></div>
                                        </div>
                                    )
                                })
                            }

                        </div>




                    </div>


                </div>



            </div>

        );
    }
}

export default DigitalLibrary;
