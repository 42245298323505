import { Tooltip } from 'primereact/tooltip';
import './styles.scss';
import { InputText } from 'primereact/inputtext';

function EMailText(props) {

    return (
            <div style={{ display: 'flex' ,border : "#cbc8c8 1px solid"}} className='custom-input2'><>
                <input type="text" disabled={props.otpVerified} value={props.value} className='input-text w-full' onChange={props.onChange} />
                {!props.otpVerified && <span className='right ma-pointer action-text' onClick={props.onActionClick}>{props.rightText}</span>}</>
            </div>
    );
}



export default EMailText;