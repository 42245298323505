import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog'
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import Authentication from './../session';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { BasicLazyParams, FileTypes, warningDailogInit } from '../../utile';
import {
    getBoardsData,
    getBranchesLatest
} from './../../store/actions';
import { Toast } from 'primereact/toast';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import LoadingComponent from '../loadingComponent';
import withRouter from '../lib/withRouter';
class AssignPlanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            filterData: null,
            users: [],
            subjects: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedSection: [],
            isSectionAssign: null,
            selectedUser: [],
            plannerToAssign: _.cloneDeep(this.props.plannerToAssign)

        }
        this.service = new Service();
    }


    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }


        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });



                this.setState({
                    boards: boards
                });
            }
        }


        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }



    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: [],
            selectedSection: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }


    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        //console.log(items)
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }



    // onChangeGroup = (groupId) => {
    //     this.setState(
    //         {
    //             groupId,
    //         },
    //         () => {
    //             this.getCurriculumData();
    //         }
    //     );
    // };
    // grades selection --- end


    getActiveSubjects = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subjects/active`;
            this.service.get(url, false).then((data) => {
                if (data && data.res && data.res.subjects) {
                    let subjects = [];
                    if (data.res.subjects.length) {
                        subjects = data.res.subjects.map((s) => {
                            s.isSelected = false;
                            return s
                        });
                    } else {

                        this.toast.show({ severity: 'error', summary: 'No Subjcts', detail: 'No active subjects in this board to assign', life: 3000 });
                    }
                    this.setState({
                        subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            // this.snackBarOpen('select board, class and group id');
        }
    };

    //subjects --- start

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
            });

        return allUsers;
    }

    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        })

        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;
        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords


                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }


    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }
    onGlobalSearch = (e) => {

        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
            }
        }, () => {

            //  if (e.target.value.length > 2) {
            this.startGlobalFilter()
            //  }

        });
    }

    startGlobalFilter = () => {

        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionId, startDate, endDate } = filterData;

            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }

            }



            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }


    onSelectSection = (e) => {
        this.setState({ selectedSection: e.value })
    }


    assignSubjects = (toSection, rowData) => {

        const { plannerToAssign } = this.state;



        let selectedSubjects = plannerToAssign.subjects.filter((s) => s.isSelected).map(s => s.subjectId);

        if (selectedSubjects.length > 0) {
            let {
                boardId,
                classId,
                groupId,
                _id,
                ...rest
            } = plannerToAssign
            let data;
            if (toSection) {
                if (!rowData.length) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select atleast one section', life: 3000 });
                    return
                }
                data = {
                    curriculum: {
                        boardId, classId, groupId,
                        yearlyPlanner: { plannerId: _id, assignedSubjects: selectedSubjects }
                    },
                    assignIds: rowData,
                    assignTo: 'section'
                }
            } else {
                if (!rowData) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select atleast one user', life: 3000 });
                    return
                }
                data = {
                    curriculum: {
                        boardId, classId, groupId,
                        yearlyPlanner: { plannerId: _id, assignedSubjects: selectedSubjects }
                    },
                    assignIds: rowData,
                    assignTo: 'user'
                }
            }

            this.setState({
                isLoading: true
            });

            const url = `${baseUrlAdmin}/yearlyplanner/assignplanner`;
            this.service.post(url, data, true).then((data) => {

                if (data && data.res && data.res.success) {
                    this.setState({
                        isLoading: false,
                        sectionIds: [],
                        selectedUser: []
                    }, this.props.onAssignSuccess);


                    //this.toast.show({ severity: 'success', summary: 'Assigned', detail: 'Content assigned successfully.', life: 3000 });

                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unkown error', life: 3000 });
                }

            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });

        } else {

            this.toast.show({ severity: 'error', summary: 'Subjects Required', detail: 'Please select atleast one subject to assign', life: 3000 });

        }
    }


    onSelectUser = (e) => {
        this.setState({ selectedUser: e.value })
    }
    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    isSelected = (rowData, selectedItems, uniqField) => {
        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {

        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts
        })





    }

    handleSelect = (subject) => {
        let subjects = this.state.plannerToAssign.subjects.map((s) => {
            if (s.subjectId == subject.subjectId) {
                s.isSelected = s.isSelected ? false : true;
            }
            return s;
        });
        this.setState((prevState) => {
            return {
                plannerToAssign: { ...prevState.plannerToAssign, subjects },
            };
        });
    }

    render() {
        const { plannerToAssign } = this.state;
        //  console.log('plannerToAssign', this.props.plannerToAssign)
        const header = (
            <div className="flex justify-content-between bg-white ">
                <div className="ma-tbl-left">
                    <h2 >{this.props.branchLevels && this.props.branchLevels.length && this.props.branchLevels[this.props.branchLevels.length - 1].levelName} List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
   
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6 list-none">
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} > */}
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}


                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} > */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        {/* </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        const header2 = (
            <div className="flex justify-content-between bg-white ">
                <div className="ma-tbl-left">
                    <h2 >Users List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch}
                    />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6 list-none">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearch: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}


                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportUsersCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <h4 className="sort-title">Sort & Filter</h4>
                                {/* <ul>
                                    {this.state.columns.map((column) => {
                                        return <li>{column.columnName}</li>
                                    })}
                                </ul> */}
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (<><Dialog
            //visible={this.state.isShowCreate}
            style={{ width: '90%', height: '90vh' }}
            //  header={this.props.isAdd ? "Create Role" : "Edit Role"}
            modal
            blockScroll={true}
            //footer={this.createRoleDialogFooter}
            draggable={false}
            // footer={this.footer}
            closeOnEscape={false}

            dismissableMask={false}
            className='add-a-resource-dialog'
            visible={true}
            closable={true}
            header="Assign Planner"
            headerClassName='text-center'
            onHide={this.props.onCancel}>
            <div className='assign-quiz' >

                <div className='p-grid ma-no-pm'>
                    <div className='p-col-12 p-md-12  exam-preview'>
                        <div className=' ma-m20'>
                            <div>
                                <p className=''>Planner Name</p>
                                <p className='exam-name'>{plannerToAssign.title}</p>
                            </div>


                            <div className='ma-clearFix'></div>
                            {plannerToAssign.subjects && plannerToAssign.subjects.length ? <>
                                <p className='my-2'>Select Subjects</p>


                                {plannerToAssign.subjects.map((subject) => {
                                    return <>
                                        <Checkbox
                                            checked={subject.isSelected}
                                            inputId={subject.subjectId} onChange={() => this.handleSelect(subject)}></Checkbox>
                                        <label htmlFor={subject.subjectId} className="p-checkbox-label ma-mr25 mx-2">{subject.subjectName}</label>
                                    </>
                                })}

                            </> : null}

                            {/* <div className='p-grid exam-info'>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Number Of Questions</p>

                                        <p className='e-value'>{examToAssign.noOfQuestions || examToAssign.totalNoOfQuestions}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Total Time</p>
                                        <p className='e-value'>{examToAssign.totalTime} Min</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Total Marks</p>
                                        <p className='e-value'>{examToAssign.totalMarks}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Question Types</p>
                                        <p className='e-value'>{(examToAssign.questionType && examToAssign.questionType.length) ? examToAssign.questionType.join(', ') : 'All'}</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>




                <div className='my-3'>
                    {/* <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_TO_SECTION} > */}
                    <Button label="Assign to Sections" onClick={() => {
                        this.setState({
                            isSectionAssign: true
                        })
                    }} className={`${this.state.isSectionAssign === true ? '' : 'p-button-outlined'}`} />
                    {/* </Authorizer>
                    <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_TO_USERS} > */}
                    {/* <Button label="Assign to Users" onClick={() => {
                        this.setState({
                            isSectionAssign: false
                        })
                    }} className={`${this.state.isSectionAssign === false ? '' : 'p-button-outlined'} ma-ml15`} /> */}
                    {/* </Authorizer> */}
                </div>
                {
                    this.state.isSectionAssign == true ? <>
                        <div className='ma-mt20'>
                            {/* <Dropdown value={this.state.classId}
                            options={this.state.classes}
                            className='ma-w200'
                            //onChange={(e) => this.onChangeClass(e.value)} 
                            placeholder="Select Section"
                        /> */}

                        </div>
                        <div>


                            {
                                this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                    return (!(this.props.branchLevels.length == index + 1) ? <Dropdown
                                        className={`ma-mr20 ma-mt10 ma-w200`}
                                        value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                        // className='ma-w100p'
                                        //    disabled={this.state.hideUpdateButton}
                                        optionLabel={"name"}
                                        optionValue={"key"}
                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                        onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                        placeholder={`--Select ${level.levelName}--`} /> : null
                                        //      <MultiSelect
                                        //     className='ma-m-lr10 ma-w200'
                                        //     value={this.state.sectionIds ? this.state.sectionIds : []}
                                        //     // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                        //     onChange={(e) => this.onMultiChange(e.value)}
                                        //     options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                        //     optionLabel={"name"}
                                        //     optionValue={"key"}
                                        //     placeholder={`--Select ${level.levelName}--`}

                                        // // onChange={(e) => setCities(e.value)} 
                                        // />
                                    )
                                }) : null
                            }

                            <div className="card datatable-crud-demo ma-mt10 ">
                                <DataTable ref={(el) => this.dtSections = el}
                                    //  lazy
                                    //  value={this.state.roles}
                                    // value={this.props.branches}
                                    value={(this.state.sectionsData && this.state.sectionsData['level' + (this.props.branchLevels.length)]) ? this.state.sectionsData['level' + (this.props.branchLevels.length)] : []}
                                    // selection={this.state.selectedProducts}
                                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                    dataKey="key"
                                    paginator
                                    rows={10}
                                    responsiveLayout="scroll"
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                 
                                    selection={this.state.selectedSection}
                                    onSelectionChange={this.onSelectSection}
                                    selectionMode="checkbox"
                                    header={header}>
                                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                                    <Column sortable field="name" header={this.props.branchLevels && this.props.branchLevels.length && this.props.branchLevels[this.props.branchLevels.length - 1].levelName} />
                                    {/* <Column sortable body={(rowData) => {
                                    return (
                                        <div className="ma-status">
                                            <span className="ma-status-text">Active </span>  <InputSwitch
                                                onChange={(e) => { this.changeStatus(e, rowData) }}
                                                checked={rowData.status == 'Active'} />
                                        </div>
                                    )
                                }} field="status" header="Role Status" /> */}
                                    {/* <Column field="chapterLongDescription" header="Chapter Long Description" /> */}
                                    {/* <Column field="isChapterActive" header="Status" body={this.statusTemplate} /> */}
                                    {/* <Column body={(rowData) => {
                                        return (<>
                                            <Button label="Assign Exam" onClick={() => this.assignSubjects(true, [rowData.key])} />
                                        </>
                                        )
                                    }}
                                        header="Actions"></Column> */}
                                    {/* <Column body={this.showChaptersTemplate}></Column> */}
                                </DataTable>
                            </div>
                            {/* <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_SUBJECTS} > */}
                            <Button label="Assign Planner" className='ma-mt20' onClick={() => this.assignSubjects(true, this.state.selectedSection.map((i) => i.key))} />
                            {/* </Authorizer> */}
                        </div>
                    </> : <></>
                }

            </div>
        </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            {
                this.state.isLoading && <LoadingComponent />
            }
        </>)
    }
}






const mapStateToProps = (state) => ({
    loading: state.curriculumData && state.curriculumData.loading,
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    subjectData:
        state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.subjects &&
        state.curriculumData.response.Item.subjects,
    offlineExportStatus: state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.offlineExportStatus &&
        state.curriculumData.response.Item.offlineExportStatus,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    updateSubjectResponse: state.updateSubject && state.updateSubject.response && state.updateSubject.response,
    subjectsUpdateError: state.updateSubject && state.updateSubject.error && state.updateSubject.error,
    updateSubjectLoading: state.updateSubject && state.updateSubject.loading && state.updateSubject.loading,
    getAllImagesUrlInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    // getCurriculum,
    // getActiveOrganizationData,
    // selectBoard,
    // dispatchClearCurriculumData,
    // selectClass,
    // selectGroup,
    // updateSubject,
    // getSubject,
    // clearSelectedOrganizationData,
    // startExportToOffline,
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(AssignPlanner)));