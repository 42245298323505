
import React, { Component } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import './styles.scss';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import moment from 'moment';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
// import { getUserAnalyticsDataByFilter } from "../../store/actions";
import LoadingComponent from './../../loadingComponent';
import { Toast } from 'primereact/toast';
import { getDateString, getDatesBetweenDates, getServerTime } from '../../../utile';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import userInfo from '../../userManagement/userInfo';
import withRouter from '../../lib/withRouter';



let getVideoType = (type) => {
    switch (type) {
        case 'Videos':
            return 'mp4'
        case 'Pdf':
            return 'pdf'
        case 'Ppt':
            return 'zip'
        case 'Quiz':
            return 'quiz'
        default:
            return '';
    }
}



const contentTypes = [
    { value: 'all', label: 'All' },
    { value: 'Videos', label: 'Videos' },
    { value: 'Pdf', label: 'PDF' },
    { value: 'Ppt', label: 'Ppt' },
    { value: 'Quiz', label: 'Quiz' }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;



class IndividualUsageAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRanges: [getDateString(getServerTime())],
            currentSelection: 1,
            userAnalyticsBySubject: [],
            pieData: [],
            startDate: getServerTime(),
            endDate: getServerTime(),
            selectedContentTypes: ['all'],
            graphData: [],
            isGraphDataAvailable: false,

            // ...oldState
            //   selectedContentTypes:[]
        }
        this.service = new Service();
    }


    setDate = (days, currentSelection) => {
        let toDay = getDateString(getServerTime())

        let dateRanges = [];
        if (days == 1) {
            dateRanges = [...dateRanges, toDay];
        } else if (days > 1) {
            let startD = 0 - days;
            let toDay = getDateString(getServerTime());
            let startDate = new Date(moment(toDay).add(startD, 'days').hour(0).minute(0).second(0).millisecond(0));
            let endDate = new Date(moment(toDay).hour(1).minute(0).second(0).millisecond(0));
            dateRanges = getDatesBetweenDates(startDate, endDate);
        }

        this.setState({
            dateRanges,
            currentSelection,
            selectedContentTypes: ['all']

        }, this.getUsageAnalytics(dateRanges));
    }

    generateReport = ({ subjects, userAnalyticsByContent, userAnalyticsBySubject }) => {

        let pieData = [];
        if (Object.keys(userAnalyticsByContent).length) {
            Object.keys(userAnalyticsByContent).forEach((content) => {
                pieData = [...pieData, { id: this.getContentType(content), value: userAnalyticsByContent[content], label: this.getContentType(content) }]
            });
        }


        let isGraphDataAvailable = false;
        const userAnalyticsBySubjectT = userAnalyticsBySubject.map(item => {
            if (item.mp4 > 0 || item.pdf > 0 || item.zip > 0 || item.quiz > 0) {
                isGraphDataAvailable = true;
            }
            return { Videos: item.mp4, Pdf: item.pdf, Ppt: item.zip, Quiz: item.quiz, quizCount: item.quizCount, subjectId: item.subjectId, subjectName: item.subjectName };
        });
        this.setState({
            userAnalyticsBySubject: userAnalyticsBySubject,
            graphData: userAnalyticsBySubjectT,
            pieData,
            subjects: subjects,
            isGraphDataAvailable
        });

    }

    getStartAndEndDate = (dateRanges) => {
        let arr = [dateRanges[0], dateRanges[dateRanges.length - 1]];
        return arr;

    }
    getUsageAnalytics = (dateRanges) => {

        let { boardId,
            classId,
            groupId,
            user_id
        } = this.props.userInfo;


        let filterData = {
            boardId,
            classId,
            groupId,
            userId: user_id,
            dateRange: dateRanges ? this.getStartAndEndDate(dateRanges) : this.getStartAndEndDate(this.state.dateRanges)
        }
        // this.props.getUserAnalyticsDataByFilter(filterData);

        this.setState({
            isLoading: true,
            selectedContentTypes: ['all']
        });


        let url = `${baseUrlAdmin}/analytics/getuseranalyticsbyfilter`;
        this.service.post(url, filterData, true).then(res => {

            if (res && res.res) {

                this.setState({
                    isLoading: false,
                }, () => {

                    this.generateReport(res.res);
                })


            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            // console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    getContentType = (key) => {
        switch (key) {
            case 'mp4':
                return 'Videos';
            case 'zip':
                return 'Ppt';
            case 'pdf':
                return 'Pdf';
            case 'quiz':
                return 'Quiz';
            case 'all':
                return 'All';
            default:
                return key;

        }
    }

    getSelectedText = (keys) => {
        let txt = '';
        keys.forEach((key, index) => {
            txt += ` ${this.getContentType(key)}`

            if (index + 1 != keys.length) {
                txt += ','
            }
        });

        return txt;
    }

    handleSelect = (dateRange) => {

        let dateRanges = getDatesBetweenDates(dateRange[0], dateRange[1]);

        this.setState({
            startDate: dateRange[0],
            endDate: dateRange[1],
            dateRanges: dateRanges,
            currentSelection: 5,
            dateRange,

        });
    }

    onDateClick = () => {
        let dateRanges = getDatesBetweenDates(this.state.startDate, this.state.endDate);
        this.setState({
            currentSelection: 5,
            dateRanges
        });
    }


    componentDidMount() {
        if (this.props.userInfo) {
            this.getUsageAnalytics();
        }
    }

    onContentTypeChange = value => {

        let selectedContentTypes = value;
        let userAnalyticsBySubject = this.state.userAnalyticsBySubject;
        let filteredR = [];
        userAnalyticsBySubject.forEach((item) => {
            let obj = { subjectName: item.subjectName, }
            selectedContentTypes.forEach((s) => {
                obj[s] = item[getVideoType(s)];
            });

            if (selectedContentTypes.includes('all')) {

                obj = {
                    Videos: item.mp4,
                    Pdf: item.pdf,
                    Ppt: item.zip,
                    Quiz: item.quiz,
                    quizCount: item.quizCount,
                    subjectId: item.subjectId,
                    subjectName: item.subjectName,

                }
            }
            filteredR = [...filteredR, obj];
        });

        this.setState({
            selectedContentTypes: value,
            graphData: filteredR
        });
    }


    render() {

        // console.log('indi-state', this.state)
        // console.log('indi-props', this.props)
        const { board, groups, branch, courses, sections, startDate, endDate, dateRange } = this.state;
        const selectionRange = {
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
        }
        const { classes } = this.props;
        return (
            <>
                <Dialog
                    //visible={this.state.isShowCreate}
                    className='graph-view'
                    style={{ width: '90%', height: '90vh' }}
                    header={`${this.props.userInfo.given_name} usage analytics`}
                    modal

                    //footer={this.createRoleDialogFooter}
                    draggable={false}
                    // footer={this.footer}
                    blockScroll={true}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    onHide={this.props.onHide}>
                    <div className="grid usage-analytics">


                        <div className='col-12 p-md-12'>

                            <div className="grid filters-c bg-blue-50">
                                <div className='col-2 p-md-2' style={{ paddingBottom: 0, cursor: 'pointer' }}>
                                    <button onClick={() => { this.setDate(1, 1) }} className={`filter-btn ${this.state.currentSelection == 1 ? 'active-fiter-btn' : ''} || ${this.state.currentSelection == 2 ? 'filter-btn-select' : ''}`}>Today</button>
                                </div>
                                <div className='col-2 p-md-2' style={{ paddingBottom: 0, cursor: 'pointer' }}>
                                    <button onClick={() => { this.setDate(2, 2) }} className={`filter-btn ${this.state.currentSelection == 2 ? 'active-fiter-btn' : ''} || ${this.state.currentSelection == 3 ? 'filter-btn-select' : ''}`}>Last 3 days</button>
                                </div>
                                <div className='col-2 p-md-2' style={{ paddingBottom: 0, cursor: 'pointer' }}>
                                    <button onClick={() => { this.setDate(6, 3) }} className={`filter-btn ${this.state.currentSelection == 3 ? 'active-fiter-btn' : ''} || ${this.state.currentSelection == 4 ? 'filter-btn-select' : ''}`}>Last 7 days</button>
                                </div>
                                <div className='col-2 p-md-2' style={{ paddingBottom: 0, cursor: 'pointer' }}>
                                    <button onClick={() => { this.setDate(29, 4) }} className={`filter-btn ${this.state.currentSelection == 4 ? 'active-fiter-btn' : ''} || ${this.state.currentSelection == 5 ? 'filter-btn-select' : ''}`}>Last 30 days</button>
                                </div>
                                <div className='col-3 p-md-3' style={{ paddingBottom: 0, cursor: 'pointer' }}>
                                    {/* <div onClick={() => { this.onDateClick() }} className={`date-tooltip ${this.state.currentSelection == 5 ? 'active-fiter-btn' : ''}`}>
                                        <span className="date-range "> <span className="date-range-t">{getDateString(this.state.startDate)} to {getDateString(this.state.endDate)}</span></span>
                                        <div className="date-tooltiptext">
                                            <Calendar showIcon={true}
                                                placeholder="Select Dates"
                                                selectionMode="range"
                                                readOnlyInput
                                                value={selectionRange}
                                                disabled={this.state.filterFor != 'all'}
                                                onChange={(e) => this.handleSelect(e.value)}
                                            ></Calendar>

                                        </div>
                                        <img src='./images/Mask Group 27.png' className="calender-icon" />
                                    </div> */}
                                    <Calendar showIcon={true}
                                    className='w-full'
                                        placeholder="Select Dates"
                                        selectionMode="range"
                                        readOnlyInput
                                        value={dateRange}
                                        onChange={(e) => this.handleSelect(e.value)}
                                    ></Calendar>

                                </div>
                                <div className='col-1 p-md-1' style={{ paddingBottom: 0 }}>
                                    <Button label="Go"  onClick={() => { this.getUsageAnalytics() }} icon="pi pi-arrow-right" iconPos="right" />
                                </div>
                            </div>
                            <div className="sp-clearFix"></div>
                            <div className="grid graph-c">
                                <div className='col-8 p-md-8' >
                                    <div className="paper-s1 time-spent-c">
                                        <span className="time-spent-label">Time spent on resources</span>
                                        <div className="time-spent-filter">
                                            <MultiSelect value={this.state.selectedContentTypes}
                                                className='ma-w100p'
                                                multiple
                                                options={contentTypes}

                                                onChange={(e) => this.onContentTypeChange(e.value)}
                                                placeholder="Select Content" />

                                        </div>
                                        <div className="sp-clearFix"></div>


                                        <div className="bar-graphs">
                                            {this.state.isGraphDataAvailable ? <ResponsiveBar
                                                data={this.state.graphData}
                                                keys={['Videos', 'Pdf', 'Ppt', 'Quiz']}
                                                indexBy="subjectName"
                                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                padding={0.1}
                                                innerPadding={4}
                                                groupMode="grouped"
                                                valueScale={{ type: 'linear' }}
                                                indexScale={{ type: 'band', round: true }}
                                                colors={['var(--analyitcs-color1)', 'var(--analyitcs-color2)', 'var(--analyitcs-color3)', 'var(--analyitcs-color4)']}
                                                borderRadius={3}
                                                borderColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
                                                axisTop={null}
                                                axisRight={null}
                                                axisBottom={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    //   legend: 'subjectName',
                                                    legendPosition: 'left',
                                                    legendOffset: 32
                                                }}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: 'Time in Hours(H:M)',
                                                    // legend: 'food',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40
                                                }}
                                                labelSkipWidth={12}
                                                labelSkipHeight={12}
                                                labelTextColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
                                                legends={[
                                                    {
                                                        dataFrom: 'keys',
                                                        anchor: 'bottom',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: 0,
                                                        translateY: 50,
                                                        itemsSpacing: 2,
                                                        itemWidth: 100,
                                                        itemHeight: 20,
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 0.85,
                                                        symbolSize: 18,
                                                        symbolShape: 'circle',
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemOpacity: 1
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}

                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                            /> : <><p className="no-data">No data to display</p> </>}

                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 p-md-4' >
                                    <div className="paper-s1 resource-usage-c">
                                        <p>Resource usage <span>(overall)</span></p>
                                        <div className="bar-graphs">
                                            {this.state.isGraphDataAvailable ?
                                                <ResponsivePie
                                                    data={this.state.pieData}
                                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                    innerRadius={0}
                                                    padAngle={0}
                                                    cornerRadius={0}
                                                    colors={['var(--analyitcs-color1)', 'var(--analyitcs-color2)', 'var(--analyitcs-color3)', 'var(--analyitcs-color4)']}
                                                    borderWidth={0}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                    radialLabelsSkipAngle={10}
                                                    radialLabelsTextColor="#333333"
                                                    radialLabelsLinkColor={{ from: 'color' }}
                                                    sliceLabelsSkipAngle={10}
                                                    sliceLabelsTextColor="#333333"
                                                    // isInteractive={false}
                                                    enableSliceLabels={false}
                                                    // enableRadialLabels={false}
                                                    enableArcLinkLabels={false}
                                                    tooltip={(dt) => {
                                                        return <h3>{dt.datum.value + " %"}</h3>
                                                    }}
                                                    legends={[
                                                        {
                                                            anchor: 'bottom',
                                                            direction: 'row',
                                                            justify: false,
                                                            translateX: 0,
                                                            translateY: 56,
                                                            itemsSpacing: 0,
                                                            itemWidth: 80,
                                                            itemHeight: 18,
                                                            itemTextColor: '#999',
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 1,
                                                            symbolSize: 18,
                                                            symbolShape: 'circle',
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemTextColor: '#000'
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                />
                                                : <><p className="no-data">No data to display</p> </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        // userInfo: state.userData && state.userData.data && state.userData.data.user_info,
        // userAnalyticsByContent: state.userAnalyticsData && state.userAnalyticsData.userAnalyticsByContent,
        // userAnalyticsBySubject: state.userAnalyticsData && state.userAnalyticsData.userAnalyticsBySubject,
        // subjects: state.userAnalyticsData && state.userAnalyticsData.subjects
    }
}


// export default (Authentication(UsageAnalytics));
//getUserAnalyticsDataByFilter
export default connect(mapStateToProps, {})(withRouter((IndividualUsageAnalytics)));