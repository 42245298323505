import { Dropdown } from 'primereact/dropdown';
import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import uuidv4 from 'uuid/v4';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
// import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import {

    getBoardsData,
    getBranchesLatest
} from './../../store/actions';
import { getFormFields, isFormValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, isFieldValid, isValidUrl } from '../../utile/formHelper';
import './styles.scss';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import LoadingComponent from '../loadingComponent';
import formFields from './virtualClassFields.json';
import Resources from './resources';
import { getServerTime } from '../../utile';
import withRouter from '../lib/withRouter';


const transFormEditUserData = (editData) => {
    const editClassData = _.cloneDeep(editData);
    if (editClassData && Object.keys(editClassData).length) {
        let resources = [];
        if (editClassData.resources && editClassData.resources.length) {
            resources = editClassData.resources.map((i, index) => {
                return {
                    id: index + 1,
                    assigned: true,
                    data: i
                };
            })
        }
        editClassData.resources = resources;
    }
    return editClassData;
}

class CreateClass extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, transFormEditUserData(this.props.editClassData) || { startTime: moment().add(10, 'minutes') });
        this.state = {
            virtualClass: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            topicId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            isShowDetails: false,
            curriculumInfo: {},
            brnaches: [],
            courses: [],
            sections: [],
            branchId: null,
            courseId: null,
            sectionId: null,
            isShowCreateSuccess: false,
            teachers: [],
            sectionIds: this.props.editClassData ? this.props.editClassData.sectionIds : [],
            filteredSections: [],
            teacherAssignedSubjects: this.props.teacherAssignedSubjects || [],
            teacherAssignedSections: this.props.teacherAssignedSections || []

        }
        this.service = new Service();
    }



    // grades selection --- start
    onChangeBoard = (boardId) => {


        const _classes = [];
        const selectedBoard = this.state.boards && this.state.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        let virtualClass = {
            ...this.state.virtualClass,
            boardId,
            boardName: selectedBoard.boardName,
            classId: null,
            groupId: null,
            subjectId: null,
            chapters: []
        };

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'boardId'), this.state.formValidations, virtualClass);

        this.setState((prevState) => {
            return {
                groups: [],
                classId: null, groupId: null,
                subjects: [], subjectId: null,
                chapters: [], chapterId: null,
                topics: [], topicId: null,
                classes: _classes,
                boardId,
                virtualClass,
                formValidations
            };
        });

    };

    onChangeClass = (classId) => {
        this.setState((prevState) => {
            return {
                groups: [], groupId: null,
                subjects: [], subjectId: null,
                chapters: [], chapterId: null,
                topics: [], topicId: null,


            };
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.state.boards && this.state.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            const selectedGroup =
                selectedClass &&
                selectedClass.groups &&
                selectedClass.groups.length &&
                selectedClass.groups[0];


            let virtualClass = {
                ...this.state.virtualClass,
                classId,
                className: selectedClass.className,
                groupId: selectedGroup.groupId,
                groupName: selectedGroup.groupName,
                subjectId: null,
                chapters: [],
                strength: 0
            }


            let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'classId'), this.state.formValidations, virtualClass);
            formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'groupId'), this.state.formValidations, virtualClass);

            this.setState((prevState) => {
                return {
                    groups: _groups,
                    classId,
                    virtualClass,
                    formValidations,
                    groupId: selectedGroup.groupId,
                    subjects: [], subjectId: null,
                    chapters: [], chapterId: null,
                    topics: [], topicId: null

                };
            },
                () => {
                    this.getCurriculumData();
                    this.getAttendeesCount(this.state.virtualClass)
                });
        }
    };

    // grades selection --- end


    //subjects --- start
    getSelectedCurriculum = (subjects) => {
        let { subjectId, chapters, topicId } = this.props.editClassData;
        let selectedSubject = subjects && subjects.length && subjects.find((subject) => subject.subjectId == subjectId);
        let chapterId = selectedSubject && selectedSubject.chapters && selectedSubject.chapters.filter(c => chapters.find(ch => ch.chapterId == c.chapterId)).map(c => c.chapterId);
        return {
            //   subjects,
            chapters: selectedSubject.chapters,
            subjectId,
            chapterId,
            subjectName: selectedSubject.subjectName,
        };
    }

    filterTeacherAssignedSubjects = (subjects) => {
        let aSubjects = this.state.teacherAssignedSubjects.map(s => s.subjectId);
        return subjects.filter(s => aSubjects.includes(s.subjectId));
    }

    filterTeacherAssignedSections = (assignedSections) => {
        let filtered = [];


        assignedSections.forEach(a => {

            let secData = this.props.branchData.find((b) => b.key == a.sectionId);
            filtered.push(secData);
            let key = secData.key;
            let temp;
            if (secData.level > 1) {
                do {
                    temp = this.props.branchData.find((n) => n.children && n.children.includes(key));
                    filtered.push(temp);

                    key = temp.key;
                } while (temp.level != 1);
            }
        })

        filtered = Array.from(new Set(filtered.map(JSON.stringify))).map(JSON.parse)

        let sectionsData = {
            level1: filtered.filter((a) => a.level == 1)
        }


        return { sectionsData, filteredSections: filtered };
    }


    getCurriculumData = (isEdit) => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {

            this.setState({
                isLoading: true
            })

            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.Item) {
                    let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}
                    this.setState({
                        subjects: this.filterTeacherAssignedSubjects(data.res.Item.subjects),
                        curriculumInfo: data.res.Item,
                        isLoading: false,

                        ...selected
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });





            //  getCurriculum(boardId, classId, groupId);
        } else {
            // this.snackBarOpen('select board, class and group id');
        }
    };

    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);


        let virtualClass = {
            ...this.state.virtualClass,
            subjectId,
            subjectName: selectedSubject[0].subjectName,
            chapters: []
        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'subjectId'), this.state.formValidations, virtualClass);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a, b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                virtualClass,
                formValidations
            };
        });


    };

    // onChangeChapter = (chapterId) => {
    //     let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
    //     this.setState(
    //         {
    //             topics: selectedChapter[0].topics,
    //             chapterId,
    //             topicId: null
    //         });
    // };

    onChangeMultiChapter = (chapterIds) => {
        let topics = [];
        let topicId = [];


        let chapters = [];
        this.state.chapters.map((chapter) => {
            chapterIds.map((chapterId) => {
                if (chapterId == chapter.chapterId) {
                    topics.push({
                        label: chapter.chapterName,
                        items: chapter.topics.map((item) => { return { value: item.topicId, label: item.topicName } }),
                    })
                    chapters.push({ chapterId: chapter.chapterId, chapterName: chapter.chapterName })
                    return;
                }
            });
        });

        if (this.state.chapterId && this.state.topicId) {
            if (chapterIds.length > this.state.chapterId.length) {
                topicId = this.state.topicId
            } else {
                let removedChapters = [];
                this.state.chapterId.forEach((cId) => {
                    if (!chapterIds.includes(cId)) {
                        removedChapters.push(cId);
                    }
                });

                let topicsInRemovedChapters = [];
                removedChapters.forEach(rmvdCId => {
                    this.state.chapters.forEach((c) => {
                        if (rmvdCId == c.chapterId) {
                            topicsInRemovedChapters = topicsInRemovedChapters.concat(c.topics.map(c => { return c.topicId }));
                        }
                    });
                });

                this.state.topicId.forEach((tId) => {
                    if (!topicsInRemovedChapters.includes(tId)) {
                        topicId.push(tId);
                    }
                })
            }
        }



        let virtualClass = {
            ...this.state.virtualClass,
            chapters: chapters
        }


        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'chapters'), this.state.formValidations, virtualClass);



        this.setState((prevState) => {
            return {
                topics: topics,
                chapterId: chapterIds,
                topicId: topicId,
                virtualClass
            };
        });
    };

    onChangeTopic = (topicId) => {
        this.setState(
            {
                topicId
            });
    };




    getAttendeesCount = ({ sectionIds, classId }) => {

        if (!sectionIds) {
            sectionIds = []
        }

        if (sectionIds && classId) {
            this.setState({
                isLoading: true,
            });
            const url = `${baseUrlAdmin}/user/section/userscount`;
            this.service.post(url, { sectionIds, classId }, true).then(res => {

                if (res && res.res && res.res.status) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            virtualClass: {
                                ...prevState.virtualClass,
                                strength: res.res.data
                            }
                        };
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })
        }
    }


    groupedItemTemplate(option) {
        return (
            <div className="p-d-flex p-ai-center country-item">
                {/* <img alt={option.label} src="showcase/demo/images/flag_placeholder.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${option.code.toLowerCase()}`} />*/}
                <div>{option.label}</div>
            </div>
        );
    }
    //subjects --- end


    // branches --- start

    // onChangeMultiBranch = (branchIds) => {
    //     
    //     let courses = [];
    //     let courseId = [];
    //     this.props.branchesInfo.branches.map((branch) => {
    //         branchIds.map((branchId) => {
    //             if (branchId == branch.branchId) {
    //                 courses.push({
    //                     label: branch.branchName,
    //                     branchId: branch.branchId,
    //                     items: branch.courses.map((item) => { return { value: item.courseId, label: item.courseName, sections: item.sections } }),
    //                 })
    //                 return;
    //             }
    //         });
    //     });

    //     if (this.state.branchId && this.state.courseId) {
    //         if (branchIds.length > this.state.branchId.length) {
    //             courseId = this.state.courseId
    //         } else {
    //             let removedBranches = [];
    //             this.state.branchId.forEach((cId) => {
    //                 if (!branchIds.includes(cId)) {
    //                     removedBranches.push(cId);
    //                 }
    //             });

    //             let coursesInRemovedBranches = [];
    //             removedBranches.forEach(rmvdCId => {
    //                 this.props.branchesInfo.branches.forEach((c) => {
    //                     if (rmvdCId == c.branchId) {
    //                         coursesInRemovedBranches = coursesInRemovedBranches.concat(c.courses.map(c => { return c.topicId }));
    //                     }
    //                 });
    //             });

    //             this.state.courseId.forEach((tId) => {
    //                 if (!coursesInRemovedBranches.includes(tId)) {
    //                     courseId.push(tId);
    //                 }
    //             })
    //         }
    //     }

    //     this.setState(
    //         {
    //             courses: courses,
    //             branchId: branchIds,
    //             courseId: courseId
    //         });
    // };

    // onChangeMultiBranch = (branchIds) => {

    //     let courses = [];
    //     let courseId = [];

    //     let isRemoved = false
    //     this.props.branchesInfo.branches.map((branch) => {
    //         branchIds.map((branchId) => {
    //             if (branchId == branch.branchId) {
    //                 courses.push({
    //                     label: branch.branchName,
    //                     branchId: branch.branchId,
    //                     items: branch.courses.map((item) => { return { value: item.courseId, label: item.courseName, sections: item.sections } }),
    //                 })
    //                 return;
    //             }
    //         });
    //     });

    //     if (this.state.branchId && this.state.courseId) {
    //         if (branchIds.length > this.state.branchId.length) {
    //             courseId = this.state.courseId
    //         } else {
    //             isRemoved = true;
    //             let removedBranches = [];
    //             this.state.branchId.forEach((cId) => {
    //                 if (!branchIds.includes(cId)) {
    //                     removedBranches.push(cId);
    //                 }
    //             });

    //             let coursesInRemovedBranches = [];
    //             removedBranches.forEach(rmvdCId => {
    //                 this.props.branchesInfo.branches.forEach((c) => {
    //                     if (rmvdCId == c.branchId) {
    //                         coursesInRemovedBranches = coursesInRemovedBranches.concat(c.courses.map(c => { return c.courseId }));
    //                     }
    //                 });
    //             });

    //             this.state.courseId.forEach((tId) => {
    //                 if (!coursesInRemovedBranches.includes(tId)) {
    //                     courseId.push(tId);
    //                 }
    //             })
    //         }
    //     }

    //     this.setState(
    //         {
    //             courses: courses,
    //             branchId: branchIds,
    //             courseId: courseId,
    //             sectionId: isRemoved ? null : this.state.sectionId
    //         });
    // };

    // onChangeMultiCourse = (courseIds) => {

    //     let sections = [];
    //     let sectionId = [];
    //     this.state.courses.map((course) => {
    //         course.items.map((item) => {
    //             courseIds.map((courseId) => {
    //                 if (courseId == item.value) {
    //                     sections.push({
    //                         label: item.label,
    //                         value: item.value,
    //                         items: item.sections.map((i) => { return { value: i.sectionId, label: i.sectionName } }),

    //                     })
    //                     return;
    //                 }
    //             });
    //         });

    //     });

    //     if (this.state.courseId && this.state.sectionId) {
    //         if (courseIds.length > this.state.sectionId.length) {
    //             sectionId = this.state.sectionId
    //         } else {
    //             let removedCourses = [];
    //             this.state.courseId.forEach((cId) => {
    //                 if (!courseIds.includes(cId)) {
    //                     removedCourses.push(cId);
    //                 }
    //             });

    //             let sectionsInRemovedCourses = [];
    //             removedCourses.forEach(rmvdCId => {
    //                 this.state.courses.forEach((c) => {
    //                     c.items.map((item) => {
    //                         if (rmvdCId == item.value) {
    //                             sectionsInRemovedCourses = sectionsInRemovedCourses.concat(item.sections.map(c => { return c.sectionId }));
    //                         }
    //                     });

    //                 });
    //             });

    //             this.state.sections.forEach((a) => {
    //                 a.items.forEach((tId) => {
    //                     if (!sectionsInRemovedCourses.includes(tId.value)) {
    //                         sectionId.push(tId.value);
    //                     }
    //                 });
    //             })
    //         }
    //     }

    //     this.setState(
    //         {
    //             sections: sections,
    //             courseId: courseIds,
    //             sectionId: sectionId
    //         });
    // };


    // onChangeSection = (sectionId) => {
    //     this.setState(
    //         {
    //             sectionId
    //         });
    // };


    getTeachers = () => {
        const url = `${baseUrlAdmin}/admin/getteachers`;
        this.service.get(url, true).then((data) => {

            if (data && data.res && data.res) {
                this.setState({
                    teachers: data.res,
                });
            } else {

            }
        });
    }

    filterBoardsAndSections = ({ assignedSubjects, assignedSections }) => {

        let aClasses = assignedSubjects.map(a => a.classId);
        let aBoards = assignedSubjects.map(a => a.boardId);
        let boards = [];
        let _data = {

        }

        if (this.props.boards && this.props.boards.length) {
            let filtered = this.props.boards.filter(board => {
                if (aBoards.includes(board.boardId)) {
                    if (board.classes && board.classes.length) {
                        board.classes = board.classes.filter((cls) => {
                            if (aClasses.includes(cls.classId)) {
                                return cls;
                            }
                        })
                    }
                    return board;
                }
            })
            _data = {
                ..._data,
                boards: filtered
            }
        }

        let sectionsData = this.filterTeacherAssignedSections(assignedSections);

        return { ..._data, ...sectionsData };
    }

    formGradesDataForEdit = (editClassData) => {


        if (this.props.boards && this.props.boards.length) {

            let aClasses = this.props.teacherAssignedSubjects.map(a => a.classId);
            let aBoards = this.props.teacherAssignedSubjects.map(a => a.boardId);

            let boards = this.props.boards.filter(board => {
                if (aBoards.includes(board.boardId)) {
                    if (board.classes && board.classes.length) {
                        board.classes = board.classes.filter((cls) => {
                            if (aClasses.includes(cls.classId)) {
                                return cls;
                            }
                        })
                    }
                    return board;
                }
            });




            const _classes = [];
            const _groups = [];
            const selectedBoard =
                boards &&
                boards &&
                boards.length > 0 &&
                boards.find((board) => board.boardId === editClassData.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ label: item.className, value: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editClassData.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editClassData.groupId)

                this.setState(
                    {
                        boards,
                        boardId: editClassData.boardId,
                        classId: editClassData.classId,
                        groupId: editClassData.groupId,
                        classes: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }

    // braches --- end 

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            // if (this.props.boards) {
            //     let boards = [];
            //     this.props.boards &&
            //         this.props.boards.map((item, index) => {
            //             boards.push({ value: item.boardId, label: item.boardName });
            //         });
            //     this.setState({
            //         boards: boards
            //     });
            // }


        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            if (this.props.editClassData && this.props.branchData) {

                let _filtered = this.filterTeacherAssignedSections(this.props.teacherAssignedSections)

                let finaldata = this.getSectionsData(_filtered.filteredSections, this.props.editClassData.sectionIds[0], this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata,
                    filteredSections: _filtered.filteredSections
                    //sectionIds:
                });

            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }
                // this.setState({
                //     sectionsData
                // });
            }
        }
        // if (!this.props.branchData) {
        //     this.props.getBranchesLatest();
        // } else {
        //     let sectionsData = {
        //         level1: this.props.branchData.filter((a) => a.level == 1)
        //     }
        //     this.setState({
        //         sectionsData
        //     });
        // }
        this.getTeachers();

        if (this.props.editClassData) {

            this.formGradesDataForEdit(this.props.editClassData);


        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            // if (this.props.boards) {
            //     let boards = [];
            //     this.props.boards &&
            //         this.props.boards.map((item, index) => {
            //             boards.push({ value: item.boardId, label: item.boardName });
            //         });



            //     this.setState({
            //         boards: boards
            //     });
            // }
        }


        if (prevProps.branchData !== this.props.branchData) {
            if (this.props.editClassData) {
                // let finaldata = this.getSectionsData(this.props.branchData, this.props.editClassData.sectionIds[0], this.props.branchLevels.length);
                // this.setState({
                //     sectionsData: finaldata
                // });

                let _filtered = this.filterTeacherAssignedSections(this.props.teacherAssignedSections)

                let finaldata = this.getSectionsData(_filtered.filteredSections, this.props.editClassData.sectionIds[0], this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata,
                    filteredSections: _filtered.filteredSections
                    //sectionIds:
                });
            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }
                // this.setState({
                //     sectionsData
                // });
            }

        }

    }


    filterBoardsAndSections = ({ assignedSubjects, assignedSections }) => {

        let aClasses = assignedSubjects.map(a => a.classId);
        let aBoards = assignedSubjects.map(a => a.boardId);
        let boards = [];
        let _data = {

        }

        if (this.props.boards && this.props.boards.length) {
            let filtered = this.props.boards.filter(board => {
                if (aBoards.includes(board.boardId)) {
                    if (board.classes && board.classes.length) {
                        board.classes = board.classes.filter((cls) => {
                            if (aClasses.includes(cls.classId)) {
                                return cls;
                            }
                        })
                    }
                    return board;
                }
            })
            _data = {
                ..._data,
                boards: filtered
            }
        }

        let sectionsData = this.filterTeacherAssignedSections(assignedSections);

        return { ..._data, ...sectionsData };
    }


    getSubjectsAndSections = (user_id) => {
        this.setState({
            isLoading: true
        })

        const url = `${baseUrlAdmin}/admin/getusersec/${user_id}`;
        this.service.get(url, true).then((data) => {

            if (data && data.res && data.res.status) {
                let _data = this.filterBoardsAndSections(data.res.data)

                this.setState({
                    isLoading: false,
                    teacherAssignedSubjects: data.res.data.assignedSubjects,
                    teacherAssignedSections: data.res.data.assignedSections,
                    ..._data
                });
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    };

    onTeacherSelect = (e) => {
        let selectedTeacher = this.state.teachers.filter((t) => t.user_id == e.value);
        // if (selectedTeacher && selectedTeacher.length) {


        let virtualClass = {
            ...this.state.virtualClass,
            teacherId: e.value,
            teacherName: selectedTeacher[0].userName,
            boardId: null,
            classId: null,
            groupId: null,
            sectionIds: []

        };

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'teacherId'), this.state.formValidations, virtualClass);

        this.setState({
            virtualClass,
            formValidations,
            sectionIds: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            boards: [],
            subjects: [],
            chpaters: [],
            classes: [],
            groups: [],
            chapterId: []
        }, () => {
            this.getSubjectsAndSections(e.value);
        });
        // }
    }

    isAdditionalValid = () => {

        let { virtualClass, formValidations } = this.state;
        let isValid = true;

        if ((virtualClass.resources && virtualClass.resources.length && !(virtualClass.resources.every(i => i.assigned)))) {
            formValidations.fields['resources'] = { ...formValidations.fields['resources'], isValid: false, errorMsg: `Please save or remove Previous Exam` }
            isValid = false;
        } else {
            formValidations.fields['resources'] = { ...formValidations.fields['resources'], isValid: true, errorMsg: `` }
        }

        if (virtualClass.meetingApp == 'custom' && !virtualClass.customJoinLink.length) {
            formValidations.fields['customJoinLink'] = { ...formValidations.fields['customJoinLink'], isValid: false, errorMsg: `Join Link is Required.` }
            isValid = false;
        } else if (virtualClass.meetingApp == 'custom' && virtualClass.customJoinLink.length && !isValidUrl(virtualClass.customJoinLink)) {
            formValidations.fields['customJoinLink'] = { ...formValidations.fields['customJoinLink'], isValid: false, errorMsg: `Invalid URL.` }
            isValid = false;
        } else {
            formValidations.fields['customJoinLink'] = { ...formValidations.fields['customJoinLink'], isValid: true, errorMsg: `` }
        }
        this.setState({
            formValidations: formValidations
        });
        return isValid;
    }

    showDetailsDialog = () => {


        const formStatus = isFormValid(formFields, this.formFields.formValidations, this.state.virtualClass);

        if (!formStatus.formValidations.isFormValid || !this.isAdditionalValid()) {
            this.setState({
                formValidations: formStatus.formValidations,
            });

        } else {
            this.setState({
                isShowDetails: true
            })
        }

    }

    hideDetailsDialog = () => {
        this.setState({
            isShowDetails: false
        })
    };

    onMeetingStartTimeChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

        let data = JSON.parse(JSON.stringify(formData));
        if (moment(value).isAfter(getServerTime())) {
            data[fieldName] = value;
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `meeting start date after current date and time.`
            }
        }

        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }


    onCreateClass = () => {
        let virtualClass = _.cloneDeep(this.state.virtualClass);


        let _resources = [];
        virtualClass.resources.map((ia) => {
            if (ia.assigned) {
                _resources.push(ia.data);
            }
        });
        virtualClass.resources = _resources;


        if (this.props.editClassData) {
            this.setState({
                isLoading: true
            });
            const url = `${baseUrlAdmin}/virtualclass/updateMeeting`;
            this.service.post(url, virtualClass, true).then(res => {
                if (res && res.res && res.res.status) {
                    this.setState({
                        isLoading: false,
                        isShowCreateSuccess: true,
                        isShowDetails: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })
        } else {





            this.setState({
                isLoading: true
            });
            const url = `${baseUrlAdmin}/virtualclass/createMeeting`;
            this.service.post(url, virtualClass, true).then(res => {
                if (res && res.res && res.res.status) {
                    this.setState({
                        isLoading: false,
                        isShowCreateSuccess: true,
                        isShowDetails: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })
        }



    }

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                let iii = data.find(a => a.key == c)
                if (iii) {
                    items.push(iii)
                }
            })
        }
        // console.log(items)
        return { items, selectedNode };

    }






    getSectionsData = (nodes, key, totalLevels) => {

        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)).filter(a => a != null && a != undefined),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }


    onChangeSection = (key, level) => {

        let { sectionsData, filteredSections } = this.state;

        let data = this.getSelectedLevelData(filteredSections, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState((prevState) => {
            return {
                sectionsData: newSectionData,
                sectionIds: [],
                virtualClass: {
                    ...prevState.virtualClass,
                    strength: 0,
                }
            };
        });

    };

    onMultiChange = (value) => {

        let virtualClass = {
            ...this.state.virtualClass,
            sectionIds: value
        };
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'sectionIds'), this.state.formValidations, virtualClass);

        this.setState((prevState) => {
            return {
                sectionIds: value,
                virtualClass,
                formValidations
            };
        }, () => {
            this.getAttendeesCount(this.state.virtualClass)
        });

    }

    onCreateSuccess = () => {
        this.props.onCreateSuccess()


    }

    addMore = () => {

        if (this.state.virtualClass.resources.every((s) => s.assigned === true)) {

            this.setState((prevState) => {
                return {
                    virtualClass: {
                        ...prevState.virtualClass,
                        resources: [...prevState.virtualClass.resources, { id: uuidv4(), assigned: false }],
                    }
                }
            }
            );
        } else {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Please save resource before adding another one', life: 3000 });

        }
    }

    onSave = (data) => {
        let resources = this.state.virtualClass.resources.map((item) => {
            if (item.id == data.id) {
                return data;
            } else {
                return item;
            }
        });

        this.setState((prevState) => {
            return {
                virtualClass: {
                    ...prevState.virtualClass,
                    resources: resources
                },
            }
        });
    }

    removeResourcesRow = (id) => {
        let formValidations = this.state.formValidations;

        formValidations.fields['resources'] = { ...formValidations.fields['resources'], isValid: true, errorMsg: `` }
        if (this.state.virtualClass.resources.length) {
            let resources = this.state.virtualClass.resources.filter(item => item.id != id);
            this.setState((prevState) => {
                return {
                    virtualClass: {
                        ...prevState.virtualClass,
                        resources
                    },
                    formValidations
                }
            });
        }
    }
    removeTempQuestion = (id) => {
        let resources = this.state.virtualClass.resources.filter(item => item.id != id);
        this.setState((prevState) => {
            return {
                virtualClass: {
                    ...prevState.virtualClass,
                    resources
                },
            }
        });
    }



    createDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button label="Cancel" className='p-button-outlined' onClick={this.hideDetailsDialog} />
            <Button label={`${this.props.editClassData ? 'Update' : 'Create'} Class`} className='ma-m-lr10' onClick={this.onCreateClass} />

        </div>)
    };

    successDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button label="Close" className='p-button-outlined' onClick={this.onCreateSuccess} />
            {/* <Button label={`Send Notification`} className='ma-m-lr10' onClick={() => { this.setState({ isShowCreateSuccess: false }) }} />*/}

        </div>)
    };


    render() {
        const { virtualClass, formValidations, sectionsData } = this.state;
        //   console.log(this.props, 'branchLevels')
        //   console.log('sectionsData', sectionsData)
        return (<>
            <div className='ma-main'>
                <div>
                    <h4 className=''>{this.props.editClassData ? 'Edit' : 'Create'} Class</h4>
                    <div className="grid w-100 m-lr16">
                        <div className="lg:col-2 md:col-3">
                            <p className='text-label2'>Teacher<span className='ma-required'>*</span></p>
                            <Dropdown value={virtualClass.teacherId}
                                options={this.state.teachers.map(item => { return { label: item.userName, value: item.user_id } })}
                                // className='ma-w200'
                                className='w-11rem'
                                onChange={(e) => this.onTeacherSelect(e)}
                                disabled={this.props.editClassData}
                                placeholder="Select Teacher" />
                            {formValidations && !formValidations.fields['teacherId'].isValid && <p className="p-error">{formValidations.fields['teacherId'].errorMsg}</p>}
                        </div>
                        <div className="lg:col-2 md:col-3">
                            <p className='text-label2 '>Date & Time<span className='ma-required'>*</span></p>
                            <Calendar
                                value={new Date(virtualClass.startTime)}
                                id='tenureend'
                                showIcon={true}
                                showTime={true}
                                hourFormat="12"
                                onChange={(e) => this.onMeetingStartTimeChange(e.value, 'startTime', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations')}
                                className='w-11rem'
                            ></Calendar>
                            {formValidations && !formValidations.fields['startTime'].isValid && <p className="p-error">{formValidations.fields['startTime'].errorMsg}</p>}
                        </div>
                        <div className="lg:col-2 md:col-3 ">
                            <p className='text-label2'>Duration (In Minutes)<span className='ma-required'>*</span></p>
                            <InputText className='w-11rem'
                                value={virtualClass.duration}
                                onChange={(e) => { onNumberChange(e.target.value, 'duration', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                            />
                            {formValidations && !formValidations.fields['duration'].isValid && <p className="p-error">{formValidations.fields['duration'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="grid ma-w100p m-lr16">
                        <div className=' md:col-4 '>
                            <p className='text-label2' >Class Title<span className='ma-required'>*</span></p>
                            <InputTextarea
                                value={virtualClass.title}
                                onChange={(e) => { onTextChange(e.target.value, 'title', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                                className='p-inputtext-style1 w-12' rows={4} />
                            {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                        </div>
                        <div className=' md:col-4'>
                            <p className='text-label2' >Description<span className='ma-required'>*</span></p>
                            <InputTextarea
                                value={virtualClass.description}
                                onChange={(e) => { onTextChange(e.target.value, 'description', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                                className='p-inputtext-style1  w-12' rows={4} />
                            {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                        </div>
                    </div>

                </div>

                <div className="card">
                    <div className="grid ma-w100p ma-mt10" >
                        <div className=" md:col-3 lg:col-2 m-r0">
                            <Dropdown value={this.state.boardId}
                                className='w-11'
                                options={this.state.boards}
                                optionLabel='boardName'
                                optionValue='boardId'
                                onChange={(e) => this.onChangeBoard(e.value)}
                                placeholder="Select Board" />
                            <span className='ma-required'>*</span>
                            {formValidations && !formValidations.fields['boardId'].isValid && <p className="p-error">{formValidations.fields['boardId'].errorMsg}</p>}
                        </div>
                        <div className="md:col-3 lg:col-2 m-r0">
                            <Dropdown value={this.state.classId}
                                options={this.state.classes}
                                className='w-11'
                                onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            <span className='ma-required'>*</span>
                            {formValidations && !formValidations.fields['classId'].isValid && <p className="p-error">{formValidations.fields['classId'].errorMsg}</p>}
                        </div>

                        <div className="md:col-3 lg:col-2 m-r0">
                            <Dropdown value={this.state.subjectId}
                                options={this.state.subjects} //.map((item) => { return { value: item.subjectId, label: item.subjectName } })}
                                optionLabel='subjectName'
                                optionValue='subjectId'
                                className='w-11'
                                onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                            <span className='ma-required'>*</span>
                            {formValidations && !formValidations.fields['subjectId'].isValid && <p className="p-error">{formValidations.fields['subjectId'].errorMsg}</p>}
                        </div>
                        <div className="md:col-3 lg:col-2  m-r0">
                            <MultiSelect value={this.state.chapterId}
                                options={this.state.chapters}
                                //options={this.state.chapters.map((item) => { return { value: item.chapterId, label: item.chapterName } })}
                                className='w-11'
                                optionLabel='chapterName'
                                optionValue='chapterId'
                                onChange={(e) => this.onChangeMultiChapter(e.value)} placeholder="Select Chapters" />
                            <span className='ma-required'>*</span>
                            {formValidations && !formValidations.fields['chapters'].isValid && <p className="p-error">{formValidations.fields['chapters'].errorMsg}</p>}
                        </div>

                    </div>
                    <div>
                        {
                            this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                return (!(this.props.branchLevels.length == index + 1) ? <><Dropdown
                                    className='w-12rem md:w-10rem'
                                    value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                    // className='ma-w100p'
                                    //    disabled={this.state.hideUpdateButton}
                                    optionLabel={"name"}
                                    optionValue={"key"}
                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                    onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                    placeholder={`--Select ${level.levelName}--`} />   <span className='ma-required mr-4 '>*</span>
                                </> : <><MultiSelect
                                    className='w-12rem md:w-10rem md:ml-1'
                                    value={this.state.sectionIds ? this.state.sectionIds : []}
                                    // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                    onChange={(e) => this.onMultiChange(e.value)}
                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                    optionLabel={"name"}
                                    optionValue={"key"}
                                    placeholder={`--Select ${level.levelName}--`}
                                />
                                    <span className='ma-required mr-2 '>*</span>
                                    {formValidations && !formValidations.fields['sectionIds'].isValid && <p className="p-error">{formValidations.fields['sectionIds'].errorMsg}</p>}
                                </>
                                )
                            }) : null
                        }

                    </div>



                    <div className='ma-clearFix'>

                    </div>
                    <div className="my-2">
                        <p className='text-label2'>Strength <span className='ma-required'>*</span></p>
                        <InputText className='w-12rem '
                            disabled={true}
                            value={virtualClass.strength}
                        //  onChange={(e) => { onTextChange(e.target.value, 'strength', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                        />

                        {formValidations && !formValidations.fields['strength'].isValid && <p className="p-error">{formValidations.fields['strength'].errorMsg}</p>}
                    </div>
                    <div className="my-3">
                        <div className="p-grid ma-radion-in">
                            <div style={{ width: '80%' }}>
                                <p className='text-label2'>Application <span className='ma-required'>*</span></p>
                                <div className='radio-btns'>
                                    <RadioButton className="radio-inline" value='zoom' inputId="zoom" name="userType"
                                        onChange={(e) => { onChoiceChange(e.value, 'meetingApp', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                                        disabled={this.props.editClassData}
                                        checked={virtualClass.meetingApp === 'zoom'}
                                    />
                                    <label className='ma-label-s1 ml-1' htmlFor="zoom">Zoom</label>
                                    {/* <RadioButton
                                    className="radio-inline ma-ml10" value='googlemeet' inputId="googlemeet" name="userType"
                                    onChange={(e) => { onChoiceChange(e.value, 'meetingApp', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                                    checked={virtualClass.meetingApp === 'googlemeet'}
                                />
                                <label className='ma-label-s1' htmlFor="googlemeet">Google Meet</label>*/}
                                    <RadioButton
                                        disabled={this.props.editClassData}
                                        className="radio-inline ml-2" value='teams' inputId="teams" name="userType"
                                        onChange={(e) => { onChoiceChange(e.value, 'meetingApp', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                                        checked={virtualClass.meetingApp === 'teams'}
                                    />
                                    <label className='ma-label-s1 ml-1' htmlFor="teams">Microsoft Teams</label>
                                    <RadioButton
                                        disabled={this.props.editClassData}
                                        className="radio-inline ml-2" value='custom' inputId="custom" name="userType"
                                        onChange={(e) => { onChoiceChange(e.value, 'meetingApp', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                                        checked={virtualClass.meetingApp === 'custom'}
                                    />
                                    <label className='ma-label-s1 ml-1' htmlFor="custom">Custom</label>
                                    {
                                        virtualClass.meetingApp === 'custom' && <div className='p-grid ml-2'>
                                            <div className='p-col-12 p-md-12 mt-4'>
                                                <InputText className='p-inputtext-style1'
                                                    //onTextChange={onTextChange}
                                                    value={virtualClass.customJoinLink}
                                                    style={{ width: '90%' }}
                                                    disabled={this.props.editClassData}
                                                    placeholder='Enter link to join meeting..'
                                                    onChange={(e) => { onTextChange(e.target.value, 'customJoinLink', this, formFields, virtualClass, formValidations, 'virtualClass', 'formValidations') }}
                                                />
                                                <span className='ma-required'> *</span>
                                                {formValidations && !formValidations.fields['customJoinLink'].isValid && <p className="p-error">{formValidations.fields['customJoinLink'].errorMsg}</p>}
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                        {formValidations && !formValidations.fields['meetingApp'].isValid && <p className="p-error">{formValidations.fields['meetingApp'].errorMsg}</p>}
                    </div>



                    <div className="">
                        <p className='text-label2'>Resources</p>
                        <div className='virtual-resources'>
                            {
                                virtualClass && virtualClass.resources && virtualClass.resources.map((temp) => {
                                    return <div key={temp.id} >
                                        <Resources data={temp.data}
                                            resources={virtualClass.resources}
                                            key={temp.id} id={temp.id}
                                            onSave={this.onSave}
                                            removeResourcesRow={this.removeResourcesRow} />
                                    </div>
                                })
                            }
                            <span onClick={this.addMore} className='ma-addmore ma-pointer'>
                                <i className="pi pi-plus-circle"></i>
                            </span>
                        </div>
                        <p className='upload-note'><span className='ma-required'> Note :</span> Paste the video resources using url links</p>
                        {formValidations && formValidations.fields && !formValidations.fields['resources'].isValid && <p className="p-error">{formValidations.fields['resources'].errorMsg}</p>}
                    </div>
                </div>


                <Toolbar className="ma-toolbar border-none" right={() => { return (<Button label={`Proceed`} className='' onClick={this.showDetailsDialog} />) }}></Toolbar>

                <Dialog
                    visible={this.state.isShowDetails}
                    style={{ width: '80%' }}
                    header={'Class Details'}
                    blockScroll={true}
                    modal
                    footer={this.createDialogFooter}
                    draggable={false}
                    // footer={this.footer}
                    closeOnEscape={true}
                    dismissableMask={false}
                    // visible={true}
                    closable={false}
                    onHide={this.hideDetailsDialog}>
                    <div className='class-detials'>
                        <div className='p-grid' >
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Board:</p>
                                <p className='d-value'>{virtualClass.boardName}</p>
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Class Title:</p>
                                <p className='d-value'>{virtualClass.title}</p>
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Teacher:</p>
                                <p className='d-value'>{virtualClass.teacherName}</p>
                            </div>
                        </div>
                        <div className='p-grid' >
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Class:</p>
                                <p className='d-value'>{virtualClass.className}</p>
                            </div>
                            {/* <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Branch:</p>
                                <p className='d-value'>Central Board Of Secondary Education</p>
                            </div>*/}
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Description:</p>
                                <p className='d-value'>{virtualClass.description}</p>
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Duration:</p>
                                <p className='d-value'>{virtualClass.duration} Min</p>
                            </div>
                        </div>
                        <div className='p-grid' >
                            {/* <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Group:</p>
                                <p className='d-value'>{virtualClass.groupName}</p>
                            </div>*/}
                            {/* <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Course:</p>
                                <p className='d-value'>Central Board Of Secondary Education</p>
                            </div>*/}
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Date & Time:</p>
                                <p className='d-value'>{moment(virtualClass.startTime).format('LLL')}</p>
                            </div>
                        </div>
                        <div className='p-grid' >
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Subject:</p>
                                <p className='d-value'>{virtualClass.subjectName}</p>
                            </div>
                            {/* <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Section:</p>
                                <p className='d-value'>Central Board Of Secondary Education</p>
                            </div>*/}
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Application:</p>
                                <p className='d-value'>{virtualClass.meetingApp.toUpperCase()}</p>
                            </div>
                        </div>
                        <div className='p-grid' >
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Chapters:</p>
                                <p className='d-value'>{virtualClass.chapters.map(a => a.chapterName).join(', ')}</p>
                            </div>
                            {/* <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Section:</p>
                                <p className='d-value'>Central Board Of Secondary Education</p>
                            </div>*/}
                            <div className='p-col-12 p-md-4'>
                                <p className='d-title'>Strength:</p>
                                <p className='d-value'>{virtualClass.strength}</p>
                            </div>
                        </div>

                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.isShowCreateSuccess}
                    // style={{ width: '80%' }}
                    blockScroll={true}
                    // modal
                    footer={this.successDialogFooter}
                    draggable={false}
                    // footer={this.footer}
                    closeOnEscape={false}
                    dismissableMask={false}
                    // visible={true}
                    closable={false}
                    onHide={() => {
                        this.setState({ isShowCreateSuccess: false }, () => {
                            this.props.onCreateSuccess()
                        })
                    }}>
                    <div className='success-dialog'>
                        <img src="./images/success-icon.PNG" className='img-center' alt="success" />
                        <p className='success-title'>Success!</p>
                        <p className='success-msg'>
                            {virtualClass.subjectName} class {this.props.editClassData ? 'updated' : 'created'} and scheduled at {moment(virtualClass.startTime).format('L')} @ {moment(virtualClass.startTime).format('LT')}
                        </p>
                    </div>
                </Dialog>
            </div>
            {
                (this.state.isLoading || this.props.loading || this.props.isBranchLoading) && <>
                    <LoadingComponent />
                </>
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.curriculumData && state.curriculumData.loading,
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(withRouter(CreateClass));
