import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import SvgViewer from '../../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import IndividualUsageAnalytics from '../../analytics/usageAnalytics/individualUsageAnalytics';
import { AnalyticsIcon2, ClassetEditIcon, KeyIcon, WarningIcon } from '../../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../../internationalization';
import withRouter from '../../lib/withRouter';
import CreateUser from './createUserManagement';
import '../styles.scss'

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const dummyData = [
    {
        role_name: "Name 1",
        description: "Admin",
        create_on: 25,
        contact_no: "1234567898",
        modified_on: "30/04/2020",
        total_users: 20
    },
    {
        role_name: "Name 2",
        description: "Admin",
        create_on: 25,
        contact_no: "1234567898",
        modified_on: "30/04/2020",
        total_users: 20
    },
    {
        role_name: "Name 3",
        description: "Admin",
        create_on: 25,
        contact_no: "1234567898",
        modified_on: "30/04/2020",
        total_users: 20
    },
]


class UserManagement extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            users: [],
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            //filterData: {},
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',

            lazyParams: BasicLazyParams,
            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],
            showingReports: false,
            series: [
                { name: 'Max marks', data: [44, 55, 57, 56, 61, 58] },
                { name: 'Sub.high score', data: [76, 85, 99, 98, 87, 105] },
                { name: 'Obtained marks', data: [35, 41, 36, 26, 45, 48] }
            ],
            options: {
                xaxis: {
                    categories: ['TELUGU', 'HINDI', 'ENGLISH', 'MATHS', 'SCIENCE', 'SOCIAL'],
                },
                yaxis: {
                    title: { text: 'Marks' }
                },
                chart: {
                    toolbar: {
                        show: false
                    }
                }
            },

        }

        this.service = new Service();
    }


    rightToolbarTemplate = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (
            <React.Fragment>
                {/* <h4 className='ma-tab-title'>User Management</h4> */}
                <h4 className='ma-tab-title'>{I18Next('roleManagement', langType).COMMON_KEY}</h4>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }


    componentDidMount() {
        this.getUsersApi();

    }


    getUsersApi = async () => {
        this.setState({ isLoading: true })
        let lazyParams = this.state.lazyParams;
        let globalSearch = this.state.globalSearch;
        let url = `${baseUrlAdmin}/admin/getAllUserAdmin?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        try {
            const res = await this.service.get(url, true);
            if (res.status) {
                this.setState({
                    users: res?.res?.userData?.data.map((r, index) => {
                        r.slNo = (lazyParams.rows * (lazyParams.page)) + (index + 1);
                        return r;
                    }),
                    isLoading: false,
                    totalRecords: res.res?.totalRecords
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    };


    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }

    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }

    // filterUsersData = () => {
    //     this.setState({
    //         isLoading: true,
    //         users: []
    //     });


    //     let { lazyParams, globalSearch, filterData } = this.state;
    //     const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

    //     this.service.post(url, filterData, true).then(res => {
    //         if (res && res.status && res.res.status) {
    //             let users1 = this.reFormUsersObj(res.res.Items);
    //             this.setState({
    //                 isLoading: false,
    //                 users: users1,
    //                 totalRecords: res.res.totalRecords
    //             });
    //         } else {
    //             this.setState({
    //                 isLoading: false
    //             });
    //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
    //         }
    //     }).catch(e => {
    //         this.setState({
    //             isLoading: false
    //         });
    //         console.log(e);
    //     })
    // }

    showUserInfo = (user) => {
        this.setState({
            selectedUser: user,
            isShowUserInfo: true
        })
    }



    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }

        });
    }

    startGlobalFilter = () => {

        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isUserNameSearch, username, boardId, classId, groupId } = filterData;

            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }

            }



            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }


    onPage = (event) => {

        console.log("CALLPAGE")
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsersApi);


    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsersApi);
    }



    onEditUserClick = (editUser) => {

        // this.props?.onEditUserData(editUser)
        this.props.onTabChange(3,editUser)
        // this.setState({
        //     editUser,
        //     isCreateUser: true
        // });
    }

    showUserAnalytics = (selectedUser) => {

        this.setState({
            selectedUser,
            isShowAnalytics: true
        });
    }


    changeStatus = (e, rowData) => {

        let data = { ...rowData }
        if (e.value === true) {
            data.status = 'Active'
        } else {
            data.status = 'InActive'
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this User status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            // editUserData: data,
            editUser: data
        });

    }


    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        const user = this.state.editUser;

        const url = `${baseUrlAdmin}/admin/updateAdminUser`;
        this.service.put(url, user, true).then((res) => {

            if (res && res.status && res.res.success) {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'success', summary: 'User status has been changed successfully', detail: "", life: 3000 });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }



    onCreateOrEditSuccess = () => {
        if (this.state?.editUser) {

            this.setState({ isCreateUser: false, editUser: false }, () => {
                this.filterUsersData();
            })


        } else {
            this.setState({ isCreateUser: false, editUser: false })
        }


    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id === c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }


    editTemplate = (rowData) => {
        return (<>
            {/* <div className="p-d-flex ma-mlr10 user-edit-t"> */}
            <div className="">
                <Button label="Reset Password" className='p-button-outlined' onClick={() => { this.resetPassword(rowData) }} />
            </div>
            {/* </div> */}
        </>
        );
    }

    resetPassword = (data) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want reset the password, it can't be reversed once change the pasword`
            },
            // editUserData: data,
            editUser: data
        });

    }

    onConfirmReset = () => {



        this.setState({
            warningDialog2: warningDailogInit,
            isLoading: true
        });

        const url = `${baseUrlAdmin}/user/resetPassword?_id=${this.state.editUser._id}`;
        this.service.put(url, {}, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    isShowResetSuccess: true,
                    newPassword: res.res.password,
                    editUser: null
                })
                //   this.toast.show({ severity: 'success', summary: 'Success', detail: 'Password reset and sent to mail successfully.', life: 3000 });
            } else {
                this.setState({
                    isLoading: false,
                    editUser: null
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false,
                editUser: null
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }
    copyToClipboard = (txt) => {
        navigator.clipboard.writeText(txt);
        this.toast.show({ severity: 'success', summary: 'Copied', detail: 'Password copied to clipboard', life: 3000 });
    }

    reports = (rowData) => {
        this.setState({
            showingReports: true,
            reportsData: rowData
        })
    }


    onCancel = () => {
        this.setState({
            isCreateUser: false,
            editUser: null
        })
    }


    onClickTm = () => {
        console.log("user-bulkupload")
        this.props.navigate('/user-bulkupload')
    }

    addUser = () => {
        this.props.onTabChange(3,{});
        this.setState({
            isCreateUser: true
        })
    }
    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    {/* <h4 className=''>User List</h4> */}
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.USER_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.USER_VIEWCOLUMNS} >
                            <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.USER_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        const { selectedUser, isShowUserInfo, isShowAnalytics, editUser } = this.state
        return (
            <>
                <div className="text-right mt-4 mb-2">

                    {/* <Authorizer permId={PERMISSIONS.USER_ADDNEWUSER} > */}
                    <Button icon={'pi pi-plus-circle text-lg mt-2 p-2'} style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={this.addUser}>
                        <p className=' text-xl ml-2 '>Add New User</p>
                    </Button>
                    {/* </Authorizer> */}
                </div>
                <div className="card datatable-crud-demo ma-m30">
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        value={this.state.users}
                        dataKey="id"
                        paginator
                        className="dataTableValuesText"
                        lazy
                        responsiveLayout="scroll"
                        showGridlines
                        onSort={this.onSort}
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={''}>

                        {/* <Column style={{ width: "6rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="S.No." className="datatable-cell" body={(rowData, { rowIndex }) => {
                                    return (
                                        <>
                                            <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) +( rowIndex + 1)}</p>
                                        </>
                                    )
                                }} /> */}
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="slNo" field="slNo" header="Sl No" />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="username" field="username" header=" Name of the User" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                            return (
                                <span>{rowData?.user_info?.userType}</span>
                            )
                        }} field="" header="Name of the Role" />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                            return (
                                <span>{rowData?.user_info?.phone_number}</span>
                            )
                        }} field="" header="Contact No" />


                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="modified_on" className='' field="modified_on" header="Last Login" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                            return (
                                <div className="">

                                    <InputSwitch
                                        className='mt-1'
                                        onChange={(e) => { this.changeStatus(e, rowData) }}
                                        checked={rowData?.user_info?.status == "Active" ? true : false} />

                                    {/* <Authorizer permId={PERMISSIONS.USER_CHANGE_STATUS} > */}
                                    {/* <InputSwitch value={rowData?.status === "Active" ? true : false} onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData?.status === "Active"} /> */}
                                    {/* </Authorizer> */}
                                </div>
                            )
                        }} field="gps" header="Status" />
                        <Column className=' ' header="Action" body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex">

                                    {/* <Authorizer permId={PERMISSIONS.USER_EDITUSER} > */}
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={`ma-ml10 ma-tbl-sort awl${rowIndex}`} onClick={() => { this.onEditUserClick(rowData) }}>
                                        <ClassetEditIcon height={32} width={32} />
                                    </span>
                                    <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                                    {/* </Authorizer> */}

                                </div>

                            </>
                            );
                        }} ></Column>

                        <Column className=' ' header="Resend Credintials" body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex">

                                    {/* <Authorizer permId={PERMISSIONS.USER_EDITUSER} > */}
                                    <span data-pr-tooltip="Resend Credintials" data-pr-position="" className={`ma-ml10 ma-tbl-lock`} >
                                        {/* <SvgViewer className='ma-icon-svg ma-pointer ml-1' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        <KeyIcon height={25} width={25} />

                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.ma-tbl-lock`} />
                                    {/* </Authorizer> */}

                                </div>

                            </>
                            );
                        }} ></Column>

                    </DataTable>
                </div>
                {/* {
                        isShowUserInfo && <UserInfo isShowUserInfo={isShowUserInfo} selectedUser={selectedUser} onHide={() => { this.setState({ isShowUserInfo: false }) }} />
                    } */}

                {
                    isShowAnalytics && <IndividualUsageAnalytics
                        userInfo={selectedUser}
                        onHide={() => { this.setState({ isShowAnalytics: false }) }} />
                }

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                <Dialog
                    header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={false}
                    visible={this.state.warningDialog.visible}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editUser: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editUser: null })}>
                    <div>
                        <span className="flex justify-content-center" >
                            <WarningIcon width={"30%"} height={"30%"} />
                        </span>
                    </div>
                    <p className='text-xl mt-2 text-900 font-bold flex justify-content-center'>{this.state.warningDialog.headerMsg}</p>
                    <p className='text-lg text-600 text-center font-bold white-space-normal'>{this.state.warningDialog.message}</p>
                </Dialog>


                <Dialog
                    header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={false}
                    visible={this.state.warningDialog2.visible}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit, editUser: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmReset} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog2: warningDailogInit, editUser: null })}>
                    <div>
                        <span className="flex justify-content-center" >
                            <WarningIcon width={"30%"} height={"30%"} />
                        </span>
                    </div>
                    <p className='text-xl mt-2 text-900 font-bold flex justify-content-center'>{this.state.warningDialog2.headerMsg}</p>
                    <p className='text-lg text-600 text-center font-bold white-space-normal'>{this.state.warningDialog2.message}</p>
                </Dialog>


                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowResetSuccess}
                    // style={{ width: '80%' }}

                    // modal
                    footer={() => {
                        return (<div className='ma-mt20'>
                            <Button label="Done" onClick={() => {
                                this.setState({
                                    isShowResetSuccess: false,
                                    newPassword: null
                                })

                            }} />
                            {/* <Button label={`Send Notification`} className='ma-m-lr10' onClick={() => { this.setState({ isShowCreateSuccess: false }) }} /> */}

                        </div>)
                    }}
                    draggable={false}
                    // footer={this.footer}
                    closeOnEscape={true}
                    dismissableMask={true}
                    // visible={true}
                    closable={false}
                    onHide={() => {
                        this.setState({
                            isShowResetSuccess: false,
                            newPassword: null
                        })
                    }}>
                    <div className='success-dialog'>
                        <img src="./images/success-icon.PNG" className='img-center' alt="success" />
                        <p className='success-title'>Success!</p>
                        <p className='success-msg'>
                            Student password is Reset, please click on below password to copy to clipboard.
                        </p>
                        <p className='success-msg'>
                            <span className='password-copy ma-pointer' onClick={() => { this.copyToClipboard(this.state.newPassword) }}>
                                {
                                    this.state.newPassword && this.state.newPassword
                                }
                            </span> </p>
                    </div>
                </Dialog>
                <Dialog
                    header={<>Report</>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={true}
                    visible={this.state.showingReports}
                    style={{ width: '50vw' }}
                    // footer={() => {
                    //     return (<div>
                    //         <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ showingReports }) }} />
                    //         <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmReset} />
                    //     </div>)
                    // }}
                    onHide={() => this.setState({ showingReports: false, reportsData: {} })}
                >
                    <div style={{ border: "1px black solid" }} className='ma-w100p'>
                        <div className='ma-p10' style={{ paddingBottom: "0px" }}>
                            <img width={'100%'} height={'80'} />
                        </div>
                        <hr />
                        <h3 className='ma-text-center'>STUDENT PROFILE</h3>
                        <hr />
                        <div className='p-grid'>
                            <div className='p-col-9 p-grid ma-ml20'>
                                <div className='p-col-4 ma-mt10' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Name of the Student </span>
                                </div>
                                <div className='p-col-8 ma-mt10' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: JATTI RITHWIKA</span>
                                </div>
                                <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Father's Name </span>
                                </div>
                                <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: J.RAJESHWAR</span>
                                </div>
                                <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Mother's Name </span>
                                </div>
                                <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: JHANSI</span>
                                </div>
                                <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Class </span>
                                </div>
                                <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: PP1</span>
                                </div>
                                <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Admin No. </span>
                                </div>
                                <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: 5331</span>
                                </div>
                            </div>
                            <div className='p-col-2'>
                                <img height={'130'} width={'120'} className='ma-mt5' />
                            </div>
                        </div>
                        <div className='ma-ml10 ma-mr10'>
                            <table border="1" width={"100%"} style={{ borderCollapse: "collapse" }}>
                                <thead>
                                    <tr style={{ fontWeight: "bold" }}>
                                        <td colspan="12" width={"100%"} className='ma-text-center' >PERIODIC TEST - I EXAMINATION REPORT</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th width={"20%"}>Subjects</th>
                                        <th width={"15%"}>Total marks</th>
                                        <th width={"15%"}>Obtained marks</th>
                                        <th width={"10%"}>Grade</th>
                                        <th width={"10%"}>Grade point</th>
                                        <th width={"25%"}>Remarks</th>
                                    </tr>
                                    <tr>
                                        <td height={'20px'}></td>
                                        <td height={'20px'}></td>
                                        <td height={'20px'}></td>
                                        <td height={'20px'}></td>
                                        <td height={'20px'}></td>
                                        <td height={'20px'}></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr style={{ fontWeight: "bold" }}>
                                        <td colspan="1" className='ma-text-center'>GRAND TOTAL</td>
                                        <td className='ma-text-center'>100</td>
                                        <td className='ma-text-center'>100</td>
                                        <td className='ma-text-center'>100</td>
                                        <td className='ma-text-center'>100</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <table border='1' className='ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr style={{ fontWeight: "bold" }}>
                                        <th width={"20%"}>Grade</th>
                                        <th >A+</th><th >A</th><th >B+</th><th >B</th><th >C</th><th >D</th><th >E</th>
                                    </tr>
                                    <tr className='ma-text-center'>
                                        <td width={"20%"}>Range (%)</td>
                                        <td >90-100</td><td >80-89</td><td >70-79</td><td >60-69</td><td >45-59</td>
                                        <td >44-33</td><td >BELOW 33</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table border='1' className='ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr className='ma-text-center'>
                                        <td width={"15%"}>Month</td>
                                        <td >APR</td><td >MAY</td><td >JUN</td><td >JUL</td><td >AUG</td><td >SEP</td>
                                        <td >OCT</td><td >NOV</td><td >DEC</td><td >JAN</td><td >FEB</td><td >MAR</td>
                                        <td>TOTAL</td><td>PER(%)</td>
                                    </tr>
                                    <tr className='ma-text-center'>
                                        <td width={"15%"}>Working Days</td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                    </tr>
                                    <tr className='ma-text-center'>
                                        <td width={"15%"}>Days Present</td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table border='1' className='ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                                <tr className='ma-text-center'>
                                    <td width={'50%'}>CLASS TEACHER REMARK</td>
                                    <td>PARENTS REMARK</td>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td height={'100px'}></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h3 className='ma-text-center ma-mt10'>Graphycal Analysis of "JATTI RITHWIKA" Marks</h3>
                        <div>
                            <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={250} />
                        </div>
                        <div className='ma-ml20 ma-mr20 ma-mb10' style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
                            <h3>Class Teacher's Sign</h3>
                            <h3>Bigmaster's Sign</h3>
                            <h3>Parent/Guardian's Sign</h3>
                        </div>
                    </div>
                </Dialog>

                {/* </Navbar> */}
            </>
        )
    }
}

export default connect(null, {

})(Authentication(withRouter(UserManagement)));