import { Component } from "@fullcalendar/core";
import { Button } from "primereact/button";
import Authorizer from "../../../session/authorizer";
import { Checkbox } from "primereact/checkbox";
import { AddIcon2, ClassetEditIcon, GreenCheck } from "../../../svgIcons";
import './styles.scss';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

const createTestTypes = [
    { id: 'One', title: 'Quiz 1', total: '12', checked: 'academicYearOne' },
    { id: 'Two', title: 'Assessment', total: '10', checked: 'academicYearTwo' },
    { id: 'Three', title: 'Ad Hoc', total: '16', checked: 'academicYearThree' },
    { id: 'Four', title: 'JEE Main', total: '11', checked: 'academicYearFour' },
    { id: 'Five', title: 'NEET', total: '12', checked: 'academicYearThree' },
    { id: 'six', title: 'CLAT', total: '12', checked: 'academicYearFour' },
    { id: 'seven', title: 'Academic Session 3', total: '11', checked: 'academicYearThree' },
    { id: 'eight', title: 'Academic Session 4', total: '12', checked: 'academicYearFour' },
];


class CreateTestType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCreateTestDialog: false,
          


    }
}



    onHandleTestDialog=()=>{
        this.setState({openCreateTestDialog:true})

    }
    onHideTestType=()=>{
        this.setState({
            openCreateTestDialog:false
        })
    }

    render() {
        return (
            <>
            <div className="admin-management">

            <div className="grid mb-6 mt-2">
                <div className='col-11 flex justify-content-end align-items-center'>
                  
                    <Button className="addButton" onClick={this.onHandleTestDialog}>
                    <AddIcon2 />
                        <p className=''>Add Test Type</p>
                    </Button>

                </div>
            </div>
            <div className="grid col-12">
                {createTestTypes?.map(each => {
                    return (
                        <Authorizer
                        // permId={PERMISSIONS.SHOW_ROLES_LIST}
                        >
                            <div className="mx-6 card cursor-pointer col-2 doubts-tab-w nowrap"
                                //  onClick={() => { this.onTabChange(0) }} 
                                style={ { height: '170px', width: '320px' } }
                            >
                                <div className="flex flex-column justify-content-center align-items-center nowrap">
                                    <div className="mt-1 w-full flex justify-content-end align-items-center">
                                        <Checkbox
                                            className='mt-1'
                                            onChange={(e) => { }}
                                            checked={each.switch} />
                                    </div>
                                    <div className="w-full flex flex-column justify-content-center mt-3 ml-3">
                                        <h3 className='title'>{each.title}</h3>
                                        <h3 className='total'>Total : {each.total} </h3>
                                    </div>
                                    <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center'>
                                        <ClassetEditIcon width={32} height={32}/>
                                    </div>
                                </div>
                            </div>
                        </Authorizer>
                    )
                })}
            </div>
        </div>
        <Dialog
                    isOpen={this.state.openCreateTestDialog}
                    visible={this.state.openCreateTestDialog}
                    draggable={false}
                    onHide={this.onHideCreateFeeCategoryDialog}
                    closable={false}
                    header={() => {
                        return (<div className='mt-2 mb-4 text-center'>
                            <GreenCheck />
                            <h3 className="formhead text-center">Add Test Type</h3>
                            {/* <h3 className="text-center formhead">Add Fee Category</h3> */}

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>

                            {/* <Button className='formSubmitButton'>
                                <p className='formSubmitButtonText'> Add  </p>
                            </Button> */}
                            <Button className='formSubmitButton p-4' >
                                <AddIcon2 />
                                <p className='formSubmitButtonText'>Accept </p>
                            </Button>
                            <Button className='formCancelButton'
                                onClick={this.onHideTestType}
                            >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}

                >
                    <div className="mb-5 ml-5 mr-5">

                        <div className="" >
                        <div className="field col-12">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Test Type Name<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                        //  value={term.academicTitle} 
                                        //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                        placeholder="Name of the Test Type"
                                    />
                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Description<span className="ma-required">*</span></label>
                            <div className="p-inputgroup">
                                <InputTextarea className='h-7rem border-300 shadow-2 w-full border-round-md pt-2 border-none text-lg pl-3' id="description"
                                    //  value={term.description} 
                                    //  onChange={(e) => { onTextChange(e.target.value, 'description', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                    placeholder="Description" rows={3} />
                                {/* <i className="pi pi-info-circle mt-4 font-bold text-lg ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['description'].isValid && <p className="p-error text-lg">{formValidations.fields['description'].errorMsg}</p>} */}
                        </div>
                           
                        
                        


                         


                        </div>
                       
                    </div>


                </Dialog>

        </>
        

        
        )

    }

}
export default CreateTestType;