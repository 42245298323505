import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import {
    VideoIcon, PptIcon,
    PdfIcon,
    QuizIcon,
    QuizCountIcon
} from '../../../svgIcons';


let TotalAnalyticsTypes = [
    { field: 'mp4', title: 'VIDEOS', icon: <VideoIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'pdf', title: 'PDF', icon: <PdfIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'zip', title: 'PPT', icon: <PptIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quiz', title: 'QUIZS', icon: <QuizIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quizCount', title: 'QUIZ ATTEMPTS', icon: <QuizCountIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Attempts' }

]



export default class OverallUsageAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: '',
            columns: [
                { columnName: 'Total Time on VIDEOS', isVisible: true, id: 'mp4' },
                { columnName: 'Total Time on PDF', isVisible: true, id: 'pdf' },
                { columnName: 'Total Time on PPT', isVisible: true, id: 'zip' },
                { columnName: 'Total Time on QUIZ', isVisible: true, id: 'quiz' },
                { columnName: 'Total Time on QUIZ COUNT', isVisible: true, id: 'quizCount' }]
        }
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }
    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }




    render() {


        const { totalCount, finalRecords, totalUsageAnalytics } = this.props.totalUserRecord;

        return (<>
            <div className='grid highlights-tabs'>
                {TotalAnalyticsTypes.map((a) => {
                    return <div className="sm:col-12 md:col-2 ">
                        <div className='paper-s1 highlights-tab border-primary'>
                            <p className='h-title'><span className='content-type'>{a.icon}</span> {a.title}</p>
                            <p ><span className='h-time-l'>{a.subTitle}:</span> <span className='h-time'>{totalUsageAnalytics[a.field]}</span></p>
                        </div>
                    </div>
                })}
            </div>
        </>)
    }
}
