import React, { Component } from 'react'
import CreateRole from './roles/createRole';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import './styles.scss'
import CreateUser from './users/createUser';
import ListTab from './ListTab';
import RolesList from './roles';
import UsersList from './users';
// import Navbar from './../navbar';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from './../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { TabMenu } from 'primereact/tabmenu';

const items = [
    { label: 'Management' },
    { label: 'Admin Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const tabs = [
    { label: 'Roles' },
    { label: 'Users' },
];


class AdminManagement extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        };
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Admin Management</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }


    componentDidUpdate(prevProps, prevState) {
        
    }

    render() {

        return (
            <div>

                <div className="">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                    </div>
                    <div className="-mt-6">
                        <Toolbar className="border-none" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>


                <div className="admin-management ">
                    <div className="grid h-3rem">
                        <div className="md:col-6 lg:col-4 xl:col-4">
                            <div className="grid doubts-tabs ">
                                <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} >
                                    <div className={`col-3 doubts-tab-w ${this.state.currentTab == 0 ? 'tab-active' : ''}`}>
                                        <p className={` tab-p ${this.state.currentTab == 0 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(0) }}>Roles</p>
                                    </div>
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.SHOW_USERS_LIST} >
                                    <div className={`col-3 doubts-tab-w ${this.state.currentTab == 1 ? 'tab-active' : ''}`}>
                                        <p className={` tab-p ${this.state.currentTab == 1 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(1) }}>Users</p>
                                    </div>
                                </Authorizer>
                                {/* <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                                    <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>List</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {
                        this.state.currentTab == 0 && <>
                            <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} >
                                <RolesList />
                            </Authorizer>
                            {/* <CreateRole /> */}
                        </>
                    }
                    {
                        this.state.currentTab == 1 && <>
                            <Authorizer permId={PERMISSIONS.SHOW_USERS_LIST} >
                                <UsersList />
                            </Authorizer>
                        </>
                    }
                    {/* {
                        this.state.currentTab == 2 && <>
                            <ListTab />
                        </>
                    } */}
                </div>

            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});





export default connect(mapStatesToProps, {})(Authentication(withRouter(AdminManagement)));