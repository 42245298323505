import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import Service from '../../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { BasicLazyParams, warningDailogInit } from '../../../../utile';
import SvgViewer from '../../../customComponents/svgViewer';
import { DeleteIcon, WarningIcon } from '../../../svgIcons';
import { Toast } from 'primereact/toast';
import AddStop from './addStop';
import Students from './students/students';

export default class Stops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            globalFilter: null,
            showSearch: false,
            columns: [
                { columnName: 'Distance From School', isVisible: false, id: 'distanceFromSchool' },
            ],
            warningDialog: warningDailogInit,
            warningDialogForDelete: warningDailogInit,
            showAddStop: false,
            deleteStopData: null,
            stopDetails: [],
            lazyParams: BasicLazyParams,
            globalSearch: '',
            routeId: '',
            totalRecords: 0
        }

        this.service = new Service()
    }


    componentDidMount() {
        this.getStopsData()
    }

    getStopsData = () => {
        this.setState({
            isLoading: true
        })
        let lazyParams = this.state.lazyParams;

        let routeId = this.props.routeId._id

        let url = `${baseUrlAdmin}/transport-route/stops/${routeId}?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`

        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    stopDetails: res?.res?.data?.stops,
                    totalRecords: res?.res?.data?.totalRecords
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });
        })
    }

    onClickAddStop = (rowData) => {
        this.setState({
            showAddStop: true,
            routeData: rowData
        })
    }

    onCloseAddStop = () => {
        this.setState({
            routeData: '',
            editStopDetails: '',
            showAddStop: false
        })
    }

    onSaveOrUpdate = (isUpdate) => {
        this.toast.show({ severity: 'success', summary: 'Saved', detail: `Stop ${isUpdate ? 'updated' : 'created'} successfuly`, life: 3000 });
        this.setState({
            editStopDetails: '',
            showAddStop: false
        }, this.getStopsData)
    }


    showEditRoleDialog = (editData) => {
        this.setState({
            editStopDetails: editData,
            showAddStop: true,
        });
    }


    handleDelete = async (rowData) => {
        console.log('r', rowData)
        this.setState({
            warningDialogForDelete: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want to delete the ${rowData.stopName} from the stops list.`
            },
            deleteStopData: rowData
        });
    }

    deleteStop = () => {
        let { deleteStopData } = this.state;
        const url = `${baseUrlAdmin}/transport-route/stops/${deleteStopData._id}`;
        this.setState({
            warningDialogForDelete: warningDailogInit,
            isLoading: true
        })
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, this.getStopsData)
                this.toast.show({ severity: 'success', summary: 'Stop Deleted', detail: res.res.message, life: 3000 });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    editTemplate = (rowData, { rowIndex }) => {
        return (<>
            <div className="p-d-flex user-edit-t">

                <span data-pr-tooltip="Edit" data-pr-position="bottom" className={` ar${rowIndex}`} onClick={() => this.showEditRoleDialog(rowData)} >
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="15" height="15" />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.ar${rowIndex}`} />

                <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`dlete${rowIndex} ml-3`}
                    onClick={() => { this.handleDelete(rowData) }}
                >
                    <DeleteIcon height={15} width={15} />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.dlete${rowIndex}`} />
            </div>
        </>
        );
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getRoutesData);
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getRoutesData);
    }

    showStudents = (rowData) => {
        this.setState({
            studentManagement: true,
            selectedStop: rowData
        }, () => {
            this.props.updateCurrentTab('students')
        })
    }

    onGoBack = () => {
        this.setState({
            studentManagement: false,
            selectedStop: null
        }, () => {
            this.getStopsData()
            this.props.updateCurrentTab('stops')
        })

    }
    render() {


        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Stops</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4 h-2rem w-15rem" value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <i data-pr-tooltip="View Columns"
                            onClick={(event) => this.menu.toggle(event)}
                            data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className="mt-2"
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <div className='ma-main'>

                    {this.state.studentManagement ? <Students
                        // routeId={this.props.routeId}
                        onGoBack={this.onGoBack}
                        onClickContinueFormStudent={this.onClickContinueFormStudent}
                        selectedStop={this.state.selectedStop}
                    /> :
                        <>
                            <div className='flex justify-content-between '>
                                <Button label="+ Add Stop" className='p-button-raised my-3 ma-m-lr10' onClick={this.onClickAddStop} />
                                <p className='mt-4 back-arrow' onClick={this.props.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                            </div>
                            <div className='card datatable-crud-demo ma-m30'>
                                <DataTable ref={(el) => this.dt = el}
                                    value={this.state.stopDetails || []}
                                    dataKey="id"
                                    paginator
                                    lazy
                                    onPage={this.onPage}
                                    rows={this.state.lazyParams.rows}
                                    first={this.state.lazyParams.first}
                                    last={this.state.totalRecords}
                                    totalRecords={this.state.totalRecords}
                                    responsiveLayout="scroll"
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                    header={header}
                                >
                                    <Column header="S.No." body={(rowData, index) => {
                                        return (
                                            <>
                                                <p>{(this.state.lazyParams.page * this.state.lazyParams.rows)  + index.rowIndex + 1}</p>
                                            </>
                                        )
                                    }} />
                                    <Column field="stopNumber" className='' header="Stop Number" />
                                    <Column sortable field="stopName" className='' header="Stop Name" />
                                    <Column field="location" header="Google Location Link" />
                                    <Column sortable field="pickUpTime" header="Pick up time" />
                                    <Column field="dropTime" header="Drop Time" />
                                    <Column sortable field="fee" header="Fee" />

                                    <Column sortable field="" header="No of Students"
                                        body={(rowData, rowIndex) => {
                                            return (<span className='p-ml-10 text-primary ma-pointer' onClick={() => { this.showStudents(rowData) }}>{rowData.admissions}</span>)
                                        }}
                                    />
                                    {
                                        this.isColumnVisible('distanceFromSchool') && <Column field="distanceFromSchool" header="Distance from school" />
                                    }
                                    <Column field="" header="Actions" className='' body={this.editTemplate} />
                                </DataTable>

                                {/* <div  className='continue-btn p-mt-3'>
                            <Button label='Continue' onClick={this.props.onClickContinue}/>
                        </div> */}
                            </div>

                            <Dialog
                                draggable={false}
                                blockScroll={true}
                                closeOnEscape={true}
                                className='ma-alert-box'
                                dismissableMask={true}
                                closable={false}
                                visible={this.state.warningDialogForDelete.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                                footer={() => {
                                    return (<div>
                                        <Button label="No" className='p-button-outlined ' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, deleteStopData: null }) }} />
                                        <Button label="Yes" className='p-button-raised ma-m-lr10' onClick={this.deleteStop} />
                                    </div>)
                                }}
                                onHide={() => this.setState({ warningDialogForDelete: warningDailogInit, deleteStopData: null })}>
                                <div>
                                    <span className="warning-icon flex justify-content-center" >
                                        <WarningIcon width={"30%"} height={"30%"} />
                                    </span>
                                </div>
                                <p className='text-xl mt-2 text-900 font-bold flex justify-content-center'>{this.state.warningDialogForDelete.headerMsg}</p>
                                <p className='text-lg text-600 text-center font-bold white-space-normal'>{this.state.warningDialogForDelete.message}</p>
                            </Dialog>

                            {this.state.showAddStop &&
                                <AddStop
                                    showAddStop={this.state.showAddStop}
                                    editStopDetails={this.state.editStopDetails}
                                    routeId={this.props.routeId}
                                    onCloseAddStop={this.onCloseAddStop}
                                    onSaveOrUpdate={this.onSaveOrUpdate}
                                />
                            }





                        </>
                    }

                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}
