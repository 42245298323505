import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";


import './styles.scss';
import { ClassetEditIcon, CodeLinkIcon, MailIcon, QuestionMarkIcon, RefreshIcon } from "../../../svgIcons";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import Authorizer from "../../../session/authorizer";
import { Tooltip } from "chart.js";
import { DataTable } from "primereact/datatable";
import SvgViewer from "../../../customComponents/svgViewer";
import { getFormFields, onDropDownChange, onTextChange } from "../../../../utile/formHelper";
import { Calendar } from 'primereact/calendar';
import ClassetInputText from "../../../../classetComponents/classetInputText";
import ClassetMultiSelect from "../../../../classetComponents/classetMultiSelect";
import ClassetDropdown from "../../../../classetComponents/classetDropDown";
import Service from "../../../../services";

const creatTestFields = require('./createTest.json');
const testFormat = [{ value: 'openTest', label: "Open Test" }, { value: 'closedTest', label: "Closed Test" }]

const testTemplates = [
    { value: 'neet2024', label: "NEET 2024" },
    { value: 'cbse', label: "CBSE" },
    { value: 'custom', label: "Custom" },
    { value: 'jEEMains2024', label: "JEE Mains 2024" },
    { value: 'jEEMains2023', label: "JEE Mains 2023" }


]
const testType = [{ label: 'Assignment', value: 'assignment' }, { label: 'Quiz', value: 'quiz' }, { label: 'Assessment', value: 'assessment' }, { label: 'Mock Test', value: 'mocktest' }]

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]
class CreateTest extends Component {

    constructor(props) {
        super(props);

        this.formFields = getFormFields(creatTestFields);
        this.state = {
            formValidations: this.formFields.formValidations,
            testData: this.formFields.data,
            selfSelection: false,
            autoSelection: true,
            onEdit: false,
            // onhide: false,
            testFormats: testFormat,
            testFormatType: null,
            startDate: null,
            testTemplates: testTemplates,
            selectedTestTemplate: null,
            addMore: true,
            generateQuestions: false


        }
        this.service = new Service();
    }

    question = [
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" }
    ]


    handleSelfSelection = () => {
        this.setState(prevState => ({
            onEdit: true,
            autoSelection: false,
            selfSelection: true,

        }))


    }
    handleGenerateQuestions = () => {
        this.setState(prevState => ({
            onEdit: true,
            generateQuestions: true,
            // autoSelection: false,

        }))


    }
    handleAutoPaperGenerate = () => {
        this.setState(prevState => ({
            onEdit: true,
            autoSelection: true,
            selfSelection: false,

        }))


    }
    handleOnEdit = () => {
        this.setState(prevState => ({
            selfSelection: false,
            autoSelection: true,
            onEdit: false



        }));


    }

    handleAddMore = () => {
        this.setState(prevState => ({
            onEdit: prevState.autoSelection,
            selfSelection: true,
            onhide: true,
            addMore: false
        }))

    }



    render() {
        const { formValidations, testData } = this.state
        return (
            <>
                <div className="p-6">
                    {!this.state.generateQuestions &&

                        <div className="createtest-card " >
                            <div className="grid">

                                <div className="col-4">
                                    <label className='label'>Title of the Test<span className='ma-required'>*</span></label>
                                    <div className='mt-2'  >
                                        <ClassetInputText
                                            id="title"
                                            icon={<CodeLinkIcon height={22} />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Name of the Test"
                                            value={testData.title}
                                            onChange={(e) => { onTextChange(e.target.value, 'title', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}

                                            disabled={this.state.onEdit}
                                        />

                                    </div>
                                    {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}

                                </div>

                                <div className="col-4">
                                    <label className='label'>Test Type<span className='ma-required'>*</span></label>
                                    <div className="mt-2" >
                                        <ClassetMultiSelect
                                            icon={<MailIcon />}
                                            id='testType'
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={testType}
                                            value={testData.testType}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'testType', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Type of Test</span>
                                                </div>
                                            )}
                                            disabled={this.state.onEdit}

                                        />
                                    </div>
                                    {formValidations && !formValidations.fields['testType'].isValid && <p className="p-error">{formValidations.fields['testType'].errorMsg}</p>}
                                </div>


                                <div className="col-4">
                                    <label className='label'>No. Of Questions<span className='ma-required'>*</span></label>
                                    <div className='mt-2' >
                                        <ClassetInputText
                                            id="noOfQuestions"
                                            icon={<CodeLinkIcon height={22} />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Select Questions"
                                            value={testData.noOfQuestions}

                                            onChange={(e) => { onTextChange(e.target.value, 'noOfQuestions', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            disabled={this.state.onEdit}
                                        // style={this.state.onhide ? { backgroundColor: '#C6CED2' } : {}}
                                        />

                                    </div>
                                    {formValidations && !formValidations.fields['noOfQuestions'].isValid && <p className="p-error">{formValidations.fields['noOfQuestions'].errorMsg}</p>}

                                </div>

                                {!this.state.onEdit &&

                                    <div className="col-4">
                                        <label className='label'>Total Time (in minutes)<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetInputText
                                                id="totalTime"
                                                icon={<CodeLinkIcon height={22} />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                placeholder="00"
                                                value={testData.totalTime}
                                                onChange={(e) => { onTextChange(e.target.value, 'totalTime', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['totalTime'].isValid && <p className="p-error">{formValidations.fields['totalTime'].errorMsg}</p>}
                                    </div>

                                }


                                {!this.state.onEdit &&
                                    <div className="col-4">
                                        <label className='label'>Total Marks<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetInputText
                                                id="totalMarks"
                                                icon={<CodeLinkIcon height={22} />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                placeholder="Total Marks"
                                                value={testData.totalMarks}
                                                onChange={(e) => { onTextChange(e.target.value, 'totalMarks', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['totalMarks'].isValid && <p className="p-error">{formValidations.fields['totalMarks'].errorMsg}</p>}
                                    </div>

                                }
                                {!this.state.onEdit &&
                                    <div className="col-4">
                                        <label className='label'>Test Format<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='testFormats'
                                                icon={<MailIcon />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                options={testFormat}
                                                value={testData.testFormats}
                                                onChange={(e) => {
                                                    onDropDownChange(e.target.value, 'testFormats', this, creatTestFields, testData, formValidations, 'testData', formValidations)



                                                }}
                                                placeholder={(
                                                    <div>
                                                        <span className=''>Test Format</span>
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['testFormats'].isValid && <p className="p-error">{formValidations.fields['testFormats'].errorMsg}</p>}
                                    </div>



                                }
                                {!this.state.onEdit && testData.testFormats === 'closedTest' &&
                                    <>

                                        <div className=" col-4">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Start Date & Time<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Calendar id="time24" className='h-3rem text-lg  border-round-md  border-none' placeholder="Start Date & Time" showTime showSeconds width='48px' />
                                            </div>
                                        </div>

                                        <div className=" col-4">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>End Date & Time<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>

                                                    <MailIcon />
                                                </div>
                                                <Calendar id="time24" className='h-3rem text-lg  border-round-md  border-none' placeholder="End Date & Time" showTime showSeconds />
                                            </div>
                                        </div>

                                    </>}
                                {!this.state.onEdit &&
                                    <div className="col-4">
                                        <label className='label'>Test Template<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='selectedTestTemplate'
                                                icon={<MailIcon />}
                                                className=""
                                                height="48px"
                                                width="368px"
                                                options={this.state.testTemplates}
                                                value={testData.selectedTestTemplate}
                                                onChange={(e) => { onDropDownChange(e.target.value, 'selectedTestTemplate', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}
                                                placeholder={(
                                                    <div>
                                                        <span className=''>Select Test Template</span>
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['selectedTestTemplate'].isValid && <p className="p-error">{formValidations.fields['selectedTestTemplate'].errorMsg}</p>}
                                    </div>

                                }
                            </div>
                            <div className="">
                                <div className="flex justify-content-center ">
                                    {(!this.state.onEdit || this.state.selfSelection) &&
                                        <Button className='formCancelButton'
                                            onClick={this.handleSelfSelection}
                                            // style={this.state.onhide ? { backgroundColor: '#076EFF', borderRadius: '12px', marginRight: '17px', borderWidth: 'none' } : {borderRadius: '12px'}}
                                            style={this.state.selfSelection ? { backgroundColor: '#076EFF', borderRadius: '12px', marginRight: '17px', borderWidth: 'none' } : { borderRadius: '12px' }}

                                        >
                                            <p className='formCancelButtonText' style={this.state.selfSelection ? { color: '#FFFFFF' } : {}}>Self selection</p>
                                        </Button>
                                    }
                                    {(!this.state.onEdit || this.state.autoSelection) &&
                                        <Button className="addButton ml-4 " onClick={this.handleAutoPaperGenerate}
                                            style={this.state.onEdit ? { marginRight: '10px' } : {}}>
                                            <p >Auto Paper Generate</p>
                                        </Button>
                                    }
                                    {this.state.onEdit &&
                                        <div onClick={this.handleOnEdit} className="cursor-pointer mt-2 ml-2" >
                                            <ClassetEditIcon width={32} height={32} color={'#000000'} onClick={this.onEdit} className="mt-2 " />
                                        </div>}
                                </div>
                            </div>

                        </div>}


                    {((testData.selectedTestTemplate && testData.testFormats) || this.state.generateQuestions) && <div className={`createtest-card `} >
                        <div className="grid">
                            {((testData.selectedTestTemplate && testData.testFormats) || this.state.generateQuestions) &&
                                <div className="col-4">
                                    <label className='label'>Board<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='paymentTerms'
                                            icon={<MailIcon />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            // value={feeTypeFormData.paymentTerms}
                                            // onChange={(e) => { onDropDownChange(e.target.value, 'paymentTerms', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations) }}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Select Board</span>
                                                </div>
                                            )}
                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['paymentTerms'].isValid && <p className="p-error">{formValidations.fields['paymentTerms'].errorMsg}</p>} */}
                                </div>}
                            {((testData.selectedTestTemplate && testData.testFormats) || this.state.generateQuestions) &&
                                <div className="col-4">
                                    <label className='label'>Grade<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='paymentTerms'
                                            icon={<MailIcon />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            // value={feeTypeFormData.paymentTerms}
                                            // onChange={(e) => { onDropDownChange(e.target.value, 'paymentTerms', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations) }}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Select Grade</span>
                                                </div>
                                            )}
                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['paymentTerms'].isValid && <p className="p-error">{formValidations.fields['paymentTerms'].errorMsg}</p>} */}
                                </div>}
                            {((testData.selectedTestTemplate && testData.testFormats) || this.state.generateQuestions) &&
                                <div className="col-4">
                                    <label className='label'>Subject<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='paymentTerms'
                                            icon={<MailIcon />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            // value={feeTypeFormData.paymentTerms}
                                            // onChange={(e) => { onDropDownChange(e.target.value, 'paymentTerms', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations) }}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Select Subject</span>
                                                </div>
                                            )}
                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['paymentTerms'].isValid && <p className="p-error">{formValidations.fields['paymentTerms'].errorMsg}</p>} */}
                                </div>}
                            {((testData.selectedTestTemplate && testData.testFormats) || this.state.generateQuestions) &&
                                <div className="col-4">
                                    <label className='label'>Chapter<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='paymentTerms'
                                            icon={<MailIcon />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            // value={feeTypeFormData.paymentTerms}
                                            // onChange={(e) => { onDropDownChange(e.target.value, 'paymentTerms', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations) }}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Select Chapter</span>
                                                </div>
                                            )}
                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['paymentTerms'].isValid && <p className="p-error">{formValidations.fields['paymentTerms'].errorMsg}</p>} */}
                                </div>}
                            {((testData.selectedTestTemplate && testData.testFormats) || this.state.generateQuestions) &&
                                <div className="col-4">
                                    <label className='label'>Topic<span className='ma-required'>*</span></label>
                                    <div className='mt-2'>
                                        <ClassetDropdown
                                            id='paymentTerms'
                                            icon={<MailIcon />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            // value={feeTypeFormData.paymentTerms}
                                            // onChange={(e) => { onDropDownChange(e.target.value, 'paymentTerms', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations) }}
                                            placeholder={(
                                                <div>
                                                    <span className=''>Select Topic</span>
                                                </div>
                                            )}
                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['paymentTerms'].isValid && <p className="p-error">{formValidations.fields['paymentTerms'].errorMsg}</p>} */}
                                </div>}

                            {(testData.selectedTestTemplate === 'custom' && testData.testFormats && !this.state.generateQuestions)  && <>

                                <div className="col-4">
                                    <label className='label'>Total Questions<span className='ma-required'>*</span></label>
                                    <div className='mt-2' >
                                        <ClassetInputText
                                            // id="noOfQuestions"
                                            icon={<CodeLinkIcon height={22} />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Select Questions"
                                        // value={testData.noOfQuestions}

                                        // onChange={(e) => { onTextChange(e.target.value, 'noOfQuestions', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}

                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['noOfQuestions'].isValid && <p className="p-error">{formValidations.fields['noOfQuestions'].errorMsg}</p>} */}

                                </div>

                                <div className="col-4">
                                    <label className='label'>Paper Levels<span className='ma-required'>*</span></label>
                                    <div className='mt-2' >
                                        <ClassetInputText
                                            // id="noOfQuestions"
                                            icon={<CodeLinkIcon height={22} />}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Select Questions"
                                        // value={testData.noOfQuestions}

                                        // onChange={(e) => { onTextChange(e.target.value, 'noOfQuestions', this, creatTestFields, testData, formValidations, 'testData', formValidations) }}

                                        />

                                    </div>
                                    {/* {formValidations && !formValidations.fields['noOfQuestions'].isValid && <p className="p-error">{formValidations.fields['noOfQuestions'].errorMsg}</p>} */}

                                </div>
                                
                                <div className="h-3rem mt-6 flex justify-content-center">
                                    <Button className="addButton ml-4 "
                                    //  onClick={this.handleAddMore}
                                    >
                                        <p >Add More</p>
                                    </Button>
                                </div>





                            </>
                            }


                            {this.state.generateQuestions &&
                                <div className="h-3rem mt-6 flex justify-content-center">
                                    <Button className="addButton ml-4 "
                                    // onClick={this.handleGenerateQuestions}
                                    >
                                        <p >Generate Questions</p>
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>}

                    {(testData.selectedTestTemplate === 'custom' && testData.testFormats && !this.state.generateQuestions && !this.state.onEdit) && <div className="mt-7 flex justify-content-center">
                        <Button className="addButton ml-4 " onClick={this.handleGenerateQuestions}
                        // style={this.state.onhide ? { marginRight: '10px' } : {}}
                        >
                            <p >Generate Questions</p>
                        </Button>
                    </div>}




                    <div>
                        <div className="">
                            {this.state.onEdit &&

                                <DataTable ref={(el) => this.dt = el}
                                    value={this.question}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                    responsiveLayout="scroll"
                                    selectionMode={'checkbox'}
                                >
                                    {(this.state.selfSelection && !this.state.generateQuestions || (this.state.generateQuestions)) &&
                                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}
                                    <Column field="question" header="Question" className="datatable-cell" sortable />
                                    <Column field="solutionandkey" header="Solution and Key" className="datatable-cell" sortable />
                                    <Column className=' ' header="" sortable body={(rowData, rowIndex ) => {
                                        return (<>
                                            <div className="flex flex-column">
                                                {this.state.autoSelection && !this.state.generateQuestions && <RefreshIcon onclick={this.handleRefresh} width={32} height={32} />}
                                                {(this.state.selfSelection && !this.state.generateQuestions || (this.state.generateQuestions)) && <ClassetEditIcon width={32} height={32} color={'#000000'} />}
                                                {(this.state.selfSelection && !this.state.generateQuestions || (this.state.generateQuestions)) && <QuestionMarkIcon width={32} height={32} className="mt-4" />}

                                            </div>
                                        </>
                                        );
                                    }} ></Column>

                                </DataTable>
                            }

                        </div >

                    </div>
                </div>

            </>
        )
    }

}
export default CreateTest