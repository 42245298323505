import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { cloneDeep } from 'lodash';

class BranchToSectionMultiDDPreFill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardIds: null,
            classIds: null,
            groupId: null,
            branchIds: null,
            sectionId: null,
            boards: [],
            classes: [],
            sections: [],
            curriculumInfo: {},
        };
        this.service = new Service()
    }

    componentDidMount() {

        this.getData()
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.branchIds == null &&
            this.props.boards && this.props.boards.length > 0 &&
            this.props.branchData && this.props.branchData.length > 0) {

            let branchdIds = this.props.branchData.map(e => e.key);
            let filteredBoards = cloneDeep(this.props.boards.filter(board => {
                return board.assignedBranches.some(branchId => branchdIds.includes(branchId));
            }));

            if (filteredBoards.length > 0) {
                

                filteredBoards.forEach((board) => {
                    board?.classes?.forEach((classs) => {
                        classs.className = board?.boardName + ' -- ' + classs.className
                        classs.sections.forEach((sec) => sec.sectionName = classs.className + ' -- ' + sec.sectionName)
                    })
                })



                let boradIds = filteredBoards?.map(e => e.boardId)



                let filterClasses = filteredBoards?.filter(board => board.boardId === boradIds[0])?.map(e => e.classes).flat();



                let filterClassIds = filterClasses?.map(e => e.classId)


                let filteredSections = filterClasses.filter(e => e.classId === filterClassIds[0])?.map(e => e.sections).flat();

                let sectionIds = filteredSections?.map(e => e.sectionId)

                let selectedsectionName = '';
                let selectedsectionId = '';


                if (filteredSections.length > 0) {
                    selectedsectionName = filteredSections[0].sectionName;
                    selectedsectionId = sectionIds[0];


                }
                let selectedbranchName = '';
                if (filterClasses.length > 0) {

                    selectedbranchName = filterClasses?.filter(item => item.classId === filterClassIds[0])?.map(e => e.branches).flat();


                }


                if (selectedsectionId !== this.state.sectionId) {

                    this.setState({
                        branches: filterClasses[0].branches,
                        branchIds: [branchdIds[0]],
                        boards: filteredBoards,
                        boardIds: [boradIds[0]],
                        classes: filterClasses,
                        classIds: [filterClassIds[0]],
                        selectedClassName: filterClasses[0].className,
                        selectedbranchName: selectedbranchName && selectedbranchName[0].branchName,
                        sections: filteredSections,
                        sectionId: selectedsectionId,
                        selectedsectionName: selectedsectionName,
                    }, () => {

                        this.setDropdownsData();
                    });
                }
            }
        }
    }

    getData = () => {
        this.props.getBranchesLatest();
        this.props.getBoardsData(true)
    }

    onChangeBranch = (branchIds) => {

        this.setState({
            boards: [], classes: [], sections: [],
        });

        let filteredBoards = cloneDeep(this.props.boards.filter(board => {
            return board.assignedBranches.some(branchId => branchIds.includes(branchId));
        }))

        filteredBoards.forEach((board) => {
            board?.classes?.forEach((classs) => {
                classs.className = board?.boardName + ' -- ' + classs.className
                classs.sections.forEach((sec) => sec.sectionName = classs.className + ' -- ' + sec.sectionName)
            })
        })

        this.setState({
            branchIds: branchIds,
            boards: filteredBoards,
            classes: [],
            boardIds: '',
            classIds: '',
            branches: [],
            sections: [],
            sectionId: '',
            selectedsectionName: '',
        });
    };

    onChangeBoard = (boardIds) => {
        this.setState({
            classes: [], sections: [],
        });

        let filteredClasses = [];

        this.state.boards.forEach(board => {
            if (boardIds.includes(board.boardId)) {
                filteredClasses.push(...board.classes);
            }
        });



        this.setState({
            classes: filteredClasses,
            boardIds,
            classIds: '',
            branches: [],
            sections: [],
            sectionId: '',
            selectedsectionName: '',

        }, () => this.setDropdownsData());
    };

    onChangeClass = (classIds) => {
        this.setState({
            sections: [],
        });
        let filterClasses1 = this.state.classes?.filter((classs) =>
            classIds?.includes(classs?.classId)
        )
        let lsecs = []
        filterClasses1?.map((fClass) => {
            let secc = fClass.sections?.filter((sec) => this.state.branchIds?.includes(sec?.branchId))
            lsecs = [...lsecs, ...secc]
        })

        this.setState({
            classIds: classIds,
            sections: lsecs,
            sectionId: '',
            selectedsectionName: ''
        }, () => this.setDropdownsData());
    };

    onChangeSection = (sectionId) => {
        let selectedsectionName = this.state.sections?.find((sectiond) => sectiond.sectionId == sectionId).sectionName;

        this.setState({
            sectionId: sectionId,
            selectedsectionName: selectedsectionName,
        }, () => this.setDropdownsData());
    };

    setDropdownsData = () => {
        let {
            boardIds,
            classIds,
            branchIds,
            sectionId,
        } = this.state
        this.props.setValues({
            boardIds, classIds, branchIds, sectionId,
        })
    }



    render() {
        return (
            <div>
                <div >
                    <div className='grid m-2'>
                        <div className=" col-3">
                            <ClassetMultiSelect required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props.branchData}
                                value={this.state.branchIds} onChange={(e) => this.onChangeBranch(e.value)} optionLabel="name"
                                optionValue="key" disabled={this.props.selectedValue === 2 ? true : false} />
                        </div>
                        <div className=" col-3">
                            <ClassetMultiSelect label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.state.boards} value={this.state.boardIds} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                optionValue="boardId" disabled={this.props.selectedValue === 2 ? true : false} />
                        </div>
                        <div className=" col-3">
                            <ClassetMultiSelect label={'Grade'} icon={<GradesIcon />} placeholder={'Select (Board - Grade)'} options={this.state?.classes}
                                value={this.state.classIds} onChange={(e) => this.onChangeClass(e.value)} optionLabel="className"
                                optionValue="classId" disabled={this.props.selectedValue === 2 ? true : false} />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown label={'Section'} icon={<GradesIcon />} placeholder={'Select (Board - Grade - Section)'} options={this.state?.sections}
                                value={this.state.sectionId} onChange={(e) => this.onChangeSection(e.value)} optionLabel="sectionName"
                                optionValue="sectionId" disabled={this.props.selectedValue === 2 ? true : false} />
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(BranchToSectionMultiDDPreFill);