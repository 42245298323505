import React, { Component } from 'react'
import { connect } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import Service from '../../services';
import Authentication from '../../session';
import withRouter from '../../lib/withRouter';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { DeleteIconClasset, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import ClassetCalendar from '../../../classetComponents/classetCalender';


const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const createApplicationFields = require('./applicationCreate.json');
const formFields = createApplicationFields
class ApplicationCreate extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createApplicationFields);
        this.state = {
            isLoading: false,
            feePlans: [],
            admissionType: [],
            gender: [
                {label: "Male", value: "MALE"},
                {label:"Female", value: "FEMALE"},
                {label: "Other", value: "OTHER"}
            ],
            pickupFee: 'average',
            preferences: false,
            refundable: false,
            refundErr: '',
            appliation: this.props?.editData?._id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            addDifferentialFeeAllow: false
        }
        this.service = new Service();
    }


    componentDidMount() {
        this.getAdmissionTypeDropdown()
        // this.getFeePlansData()
    }


  
    getAdmissionTypeDropdown = async () => {

        let url = `${baseUrlAdmin}/admission-types`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admision => ({
                    label: admision.categoryName,
                    value: admision._id
                }));

                this.setState({ admissionType: result });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };

    onSubmitApplication = async () => {
        // if (this.state.refundable === false) {
        //     this.setState({ refundErr: 'please select Refundable fee' })
        // }
        let bodyForApi = cloneDeep(this.state.appliation)
        const formStatus = isFormValid(createApplicationFields, this.formFields.formValidations, bodyForApi);

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        }
        // else {
        //     const payload = {
        //         "name": bodyForApi?.name,
        //         "admissionType": bodyForApi?.admissionType,
        //         "gstVatper": bodyForApi?.GSTPercentage,
        //         "isGstVat": this.state?.preferences,
        //         "avgPickDrp": this.state.pickupFee === 'average' ? true : false,
        //         "singleTriper": bodyForApi?.transportPercentage,
        //         "isRefundable": this.state.refundable

        //     }
        //     let url = `${baseUrlAdmin}/bus-fee-plans`
        //     try {
        //         const res = await this.service.post(url, payload, true);
        //         if (res?.res?.status && res.status) {
        //             this.toast.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
        //         } else {
        //             const error = 'Some error occurred';
        //             this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

        //         }
        //     } catch (e) {
        //         console.log(e);
        //         this.props.toastMessage.show({ severity: 'error', summary: e, detail: e.toString(), life: 3000 });
        //     }
        // }
    }

    deleteDifferentialFee = (index) => {
        const updatedFeeTypes = this.state.transportFeeTypes.filter((_, i) => i !== index);
        this.setState({ transportFeeTypes: updatedFeeTypes });
    };

    handleInputChange = (index, field, value) => {
        const feeType = [...this.state.transportFeeTypes];
        feeType[index][field] = value;
        this.setState({ feeType });
    };


    handleRadioButtonChange = (option) => {
        this.setState({ pickupFee: option });
    }


    // getFeePlansData = async () => {
    //     this.setState({ isLoading: true });
    //     let url = `${baseUrlAdmin}/bus-fee-plans/`;
    //     try {
    //         const res = await this.service.get(url, true);
    //         if (res?.res?.status && res.status) {
    //             const feePlan = res?.res?.data?.feePlans[0];
    //             if (feePlan) {
    //                 this.setState({
    //                     transport: {
    //                         ...this.state.transport,
    //                         name: feePlan.name,
    //                         admissionType: feePlan.admissionType,
    //                         GSTPercentage: feePlan.gstVatper,
    //                         transportPercentage: feePlan.singleTriper
    //                     },
    //                     preferences: feePlan.isGstVat,
    //                     refundable: feePlan.isRefundable,
    //                     pickupFee: feePlan.avgPickDrp ? 'average' : 'maximum',
    //                     isLoading: false
    //                 });
    //             }
    //             this.setState({ feePlans: res?.res?.data?.feePlans, isLoading: false });
    //         } else {
    //             this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
    //         }
    //     } catch (e) {
    //         this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
    //     }
    // };


    







    render() {
        const { appliation, formValidations } = this.state;
        return (
            <>
                <div className=' mt-5 ml-7 '>
                    <div className="grid  md:grid-cols-2 lg:grid-cols-3 mx-6 lg:gap-6 md:gap-4" >
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Application ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    value={appliation.firstName}
                                    onChange={(e) => onTextChange(e.target.value, 'firstName', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Application ID" />
                            </div>
                            {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error text-lg">{formValidations.fields['firstName'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Academic Session<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.academicSession}
                                    options={this.state.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'academicSession', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Select Academic Session'
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Admission Type<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.admissionType}
                                    options={this.state.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'admissionType', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Admission Types'
                                />
                            </div>
                            {formValidations && !formValidations.fields['admissionType'].isValid && <p className="p-error text-lg">{formValidations.fields['admissionType'].errorMsg}</p>}

                        </div>

                    </div>


                    <div className="grid mt-5  md:grid-cols-3 lg:grid-cols-3 mx-6 lg:gap-6 md:gap-4" >

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student First Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    value={appliation.firstName}
                                    onChange={(e) => onTextChange(e.target.value, 'firstName', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Student First Name" />
                            </div>
                            {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error text-lg">{formValidations.fields['firstName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student Last Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    value={appliation.lastName}
                                    onChange={(e) => onTextChange(e.target.value, 'lastName', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Student Last Name" />
                            </div>
                            {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error text-lg">{formValidations.fields['lastName'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Contact no<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={appliation.contactNo}
                                    onChange={(e) => onTextChange(e.target.value, 'contactNo', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Contact no" />
                            </div>
                            {formValidations && !formValidations.fields['contactNo'].isValid && <p className="p-error text-lg">{formValidations.fields['contactNo'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1  ">
                            <p className='transport-fee-form-label'>Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={appliation.emailId}
                                    onChange={(e) => onTextChange(e.target.value, 'emailId', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Email ID" />
                            </div>
                            {formValidations && !formValidations.fields['emailId'].isValid && <p className="p-error text-lg">{formValidations.fields['emailId'].errorMsg}</p>}

                        </div>



                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Gender<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.gender}
                                    options={this.state.gender}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'gender', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Select Gender'
                                />
                            </div>
                            {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error text-lg">{formValidations.fields['gender'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Date of Birth<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetCalendar
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' border-round-md add-vehicle-field-input text-xl w-full'
                                    calendarMode={'single'}
                                    // value={this.props?.editData?._id ? new Date(vehicle.purchasedOn) : ''}
                                    onChange={(e) => { onTextChange(e.target.value, 'dob', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder="DOB" />
                            </div>
                            {formValidations && !formValidations.fields['dob'].isValid && <p className="p-error text-lg">{formValidations.fields['dob'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Adhaar Card<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={appliation.adhaarNo}
                                    onChange={(e) => onTextChange(e.target.value, 'adhaarNo', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Adhaar Card No." />
                            </div>
                            {formValidations && !formValidations.fields['adhaarNo'].isValid && <p className="p-error text-lg">{formValidations.fields['adhaarNo'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Board<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.board}
                                    // options={this.state.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'board', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Select Board'
                                />
                            </div>
                            {formValidations && !formValidations.fields['board'].isValid && <p className="p-error text-lg">{formValidations.fields['board'].errorMsg}</p>}

                        </div>


                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Grade<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.grade}
                                    // options={this.state.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'grade', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Select Grade'
                                />
                            </div>
                            {formValidations && !formValidations.fields['grade'].isValid && <p className="p-error text-lg">{formValidations.fields['grade'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Course<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.course}
                                    // options={this.state.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'course', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Select Course'
                                />
                            </div>
                            {formValidations && !formValidations.fields['course'].isValid && <p className="p-error text-lg">{formValidations.fields['course'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Previous School<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={appliation.previousSchool}
                                    onChange={(e) => onTextChange(e.target.value, 'previousSchool', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Previous School" />
                            </div>
                            {formValidations && !formValidations.fields['previousSchool'].isValid && <p className="p-error text-lg">{formValidations.fields['previousSchool'].errorMsg}</p>}
                        </div>
                    

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Parent Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={appliation.parentName}
                                    onChange={(e) => onTextChange(e.target.value, 'parentName', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Parent Name" />
                            </div>
                            {formValidations && !formValidations.fields['parentName'].isValid && <p className="p-error text-lg">{formValidations.fields['parentName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1  ">
                            <p className='transport-fee-form-label'>Parent Type<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.parentType}
                                    // options={this.state.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'parentType', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Parent Type'
                                />
                            </div>
                            {formValidations && !formValidations.fields['parentType'].isValid && <p className="p-error text-lg">{formValidations.fields['parentType'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Parent Contact No.<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={appliation.parentContact}
                                    onChange={(e) => onTextChange(e.target.value, 'parentContact', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Contact No." />

                            </div>
                            {formValidations && !formValidations.fields['parentContact'].isValid && <p className="p-error text-lg">{formValidations.fields['parentContact'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Parent Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={appliation.parentEmailId}
                                    // options={this.state.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'parentEmailId', this, formFields, appliation, formValidations, 'appliation', 'formValidations') }}
                                    placeholder='Parent Email ID'
                                />
                            </div>
                            {formValidations && !formValidations.fields['parentEmailId'].isValid && <p className="p-error text-lg">{formValidations.fields['parentEmailId'].errorMsg}</p>}

                        </div>

                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mx-6 my-4 ">
                        <div className="" >
                            <p className="transport-fee-form-label">Occupation<span className="ma-required"></span></p>
                            <div className="flex">
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    value={appliation.occupation}
                                    onChange={(e) => onTextChange(e.target.value, 'occupation', this, formFields, appliation, formValidations, 'appliation', 'formValidations')}
                                    placeholder="Select Occupation" />
                            </div>
                        </div>
                    </div>


                    

                    <div className="text-center mt-8">
                        <Button icon={'pi pi-plus-circle text-lg '} label='Save Enquiry'  className='confirmDialogAcceptBtn  mr-5 w-14rem p-5' />
                        <Button icon={'pi pi-plus-circle text-lg '} label='Save and Proceed to Next Step' onClick={this.onSubmitApplication} className='confirmDialogAcceptBtn  mr-5 w-24rem p-5' />
                    </div>





                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

export default connect(null, {})(Authentication(withRouter(ApplicationCreate)));