import React, { Component } from 'react'
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsiveBar } from '@nivo/bar';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}


class BrachWiseUsageReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            inlineLoadingD: false,
            branchWiseUsageReport: [],
            dialogHeader: "",
            showDiolog: false,
            arrToDataTable: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }
        this.service = new Service();
    }

    componentDidMount() {
        this.getBranchReportData()
    }
    getBranchReportData = () => {
        this.setState({ isLoading: true })
        const url = `${baseUrlAdmin}/notification-manager/dashboard-sections`;
        this.service.get(url, true).then(res => {
            if (res && res.status) {
                this.setState({
                    branchWiseUsageReport: res?.res?.sectionData,
                    isLoading: false
                })
            } else {
                this.setState({ isLoading: false });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
        })
    }
    onClickMore = (h) => {
        this.setState({
            showDiolog: true,
            dialogHeader: h
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event });
    }
    render() {
        const { defaultLanguage } = this.props;
        const { inlineLoading, branchWiseUsageReport } = this.state;
        const langType = defaultLanguage


        return (
            <div className='ma-relative'>
                <div className='resource-usage-report' style={{ background: "#f8fce1" }}>
                    <h3 className="resource-title ma-mt10">BranchWiseUsageReport</h3>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-11'>
                            <div className='trending-table-rup' style={{ width: "100%" }}>
                                <table className='trending-table'>
                                    <tr>
                                        <th style={{ color: "#4682b4", font: "normal normal bold 14px/16px Roboto" }}>Number</th>
                                        <th style={{ color: "#40e0d0", font: "normal normal bold 14px/16px Roboto" }}>Branch</th>
                                        <th style={{ color: "#9acd32", font: "normal normal bold 14px/16px Roboto" }}>Usage</th>
                                    </tr>
                                    {branchWiseUsageReport && branchWiseUsageReport?.slice(0, 3).map((branch, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{branch.section}</td>
                                                    <td>{moment.utc(branch?.timeSpent * 1000).format('HH:mm:ss')}</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </table>
                                <div className='flex-reverse ma-mr100'>
                                    <span className='ma-mt10 ma-pointer' onClick={() => this.onClickMore("Brach Wise Usage Report")}
                                        style={{ color: "#004cff", font: "normal normal bold italic 13px Roboto" }}>More</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog className='ma-alert-box p-dialog-dash p-overflow-hidden'
                    header={<h4>BranchWiseUsageReport</h4>}
                    draggable={false}
                    closeOnEscape={true}
                    blockScroll={false}
                    dismissableMask={false}
                    closable={true}
                    visible={this.state.showDiolog} style={{ maxWidth: '40vw', minWidth: '40vw' }}
                    onHide={() => this.setState({ showDiolog: false })}
                >
                    <div className='card datatable-crud-demo'>
                        <DataTable stripedRows className='asdf'
                            ref={(el) => this.dt = el}
                            //  lazy
                            value={branchWiseUsageReport}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                            dataKey="id"
                            paginator
                            // lazy
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Reports"
                            globalFilter={this.state.globalFilter}
                            header={''}
                        >
                            <Column header='S No' field='rank' body={(rowData, index) => {
                                return (
                                    <p>{index.rowIndex + 1}</p>
                                )
                            }} />
                            <Column header='Branch' field='section' />
                            <Column header='TimeSpent' field='timeSpent' body={(rowData) => {
                                return (
                                    <p>{moment.utc(rowData.timeSpent * 1000).format('HH:mm:ss')}</p>
                                )
                            }} />
                        </DataTable>
                    </div>
                    {
                        this.state.inlineLoadingD && <InLoadingComponent />
                    }
                </Dialog>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(BrachWiseUsageReport));
