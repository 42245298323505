import React, { useState, useEffect, useRef, useMemo, Children } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation, useMatch } from 'react-router-dom';
import Authentication from './session'
import { connect } from 'react-redux';
import Notification from './components/notification';
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AssignFeeScreen from './components/financeManagement/asignFee';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import BulkUploadc from './components/contentMaster/bulkUpload';
import FormLayoutDemo from './components/FormLayoutDemo';
import InputDemo from './components/InputDemo';
import FloatLabelDemo from './components/FloatLabelDemo';
import ButtonDemo from './components/ButtonDemo';
import TableDemo from './components/TableDemo';
import ListDemo from './components/ListDemo';
import TreeDemo from './components/TreeDemo';
import PanelDemo from './components/PanelDemo';
import OverlayDemo from './components/OverlayDemo';
import MediaDemo from './components/MediaDemo';
import MenuDemo from './components/MenuDemo';
import MessagesDemo from './components/MessagesDemo';
import FileDemo from './components/FileDemo';
import ChartDemo from './components/ChartDemo';
import MiscDemo from './components/MiscDemo';
import Documentation from './components/Documentation';
import BlocksDemo from './components/BlocksDemo';
import IconsDemo from './utilities/IconsDemo';
import CrudDemo from './pages/CrudDemo';
import CalendarDemo from './pages/CalendarDemo';
import Invoice from './pages/Invoice';
import Help from './pages/Help';
import EmptyPage from './pages/EmptyPage';
import InvalidStateDemo from './components/InvalidStateDemo';
import TimelineDemo from './pages/TimelineDemo';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import './fonts/fonts.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import LoginPage from './components/login'
import withRouter from './components/lib/withRouter';
import Service from './components/services';
import Dashboard from './components/dashboard/index'
import Boards from './components/boardsMaster/index';
import Branch from './components/branchMaster/index';
import OrganizationMaster from './components/orgnizationMaster'
import ChequeOrDDStatus from './components/financeManagement/chequeStatus';
import CurriculumMaster from './components/curriculumMaster'
import AssignContent from './components/curriculumMaster/assignContent';
import CalendarMaster from './components/calendarMaster'
import EnquiryFrom from './components/enqiryForm';
import AcadamicYear from './components/admissionsOrganization/academicYears';
import AdmissionType from './components/admissionsOrganization/admissionType';
import ApplicationFee from './components/applicationFee';
import Course from './components/course';
import BusFeePlans from './components/finance/busFeePlans';
import Finance from './components/finance';
import FeeDaySheet from './components/organizationManage/feeDaySheet';
import FeeTypes from './components/organizationManage/feeTypes';
import FeePlan from './components/organizationManage/feePlan';
import VehicleManagement from './components/transport/vehicleManagement';
import TransportStaffManagement from './components/transport/transportStaffManagement';
import RouteManagement from './components/transport/routeManagement';
import TransportAttendance from './components/transport/attendance';
import TransportAttendanceReport from './components/transport/attendanceReport';
import BulkUploadYearlyPlanner from './components/calendarMaster/bulkUpload';
import TimeTable from './components/timeTable';
import QuestionBank from './components/questionBank';
import VirtualClass from './components/virtualClass';
import AssignQuestions from './components/assignQuestions';
import Examinations from './components/examination';
import DiscussionBoard from './components/discussionBoard';
import AskADoubt from './components/askADoubt';
import ContentMaster from './components/contentMaster';
import AdminManagement from './components/adminManagement';
import BulkUploadA from './components/adminManagement/bulkUpload';
import ApprovalManagement from './components/approvalManagement';
import ClassRoom from './components/classroom';
import Attendance from './components/attendance';
import BulkAttendance from './components/attendance/bulkAttendance';
import AttendaceReport from './components/attendance/attendaceReport';
import UsageAnalytics from './components/analytics/usageAnalytics/index';
import ExamAnalytics from './components/analytics/examAnalytics';
import TeacherAnalytics from './components/analytics/teacherAnalytics';
import BranchWiseAnalytics from './components/analytics/usageAnalytics/branchWiseAnalytics';
import UserManagement from './components/userManagement';
import TicketManagement from './components/ticketManagement';
import BulkUpload from './components/userManagement/bulkUpload';

import TransportIndex from './components/TransportManagement/index';
import AdminManagementIndex from './components/admissionNewManagement/index';
import SchoolSetupIndex from './components/schoolSetup/index';
import Acadamics from './components/academics';
import FinanceNewIndex from './components/financeNew/index'
import UnAssignFee from './components/financeManagement/unAssignFee';
import TimeTableNew from './components/TimeTableNew/timeTable';
import RequestSubstitution from './components/TimeTableNew/Request Substitution/requestSubstitutionTable'
import Enquiry from './components/Admissions/Enquiry/index'
import AddEnquiry from './components/Admissions/Enquiry/enquiryAdd'
import ApplicationCreate from './components/Admissions/Enquiry/application'



import RolesAndUsers from './components/Roles&Users/index';
import AcademicYears from './components/admissionsOrganization/academicYears';
import FeeSetUpIndex from './components/feeSetUp';
import StudentInfo from './components/studentManagement/studentInformation';
import StudentAttendance from './components/studentManagement/attendance';
import StudentTransfer from './components/studentManagement/studentTransfer';
import TransferPendingStudents from './components/studentManagement/transferPendingStudents';
import CollectFee from './components/financeManagement/collectFee';
import TeacherDairyFormat from './components/ClassetAcademicsModule/Teacher/teacherDairyFormat';
import AcademicCalendar from './components/ClassetAcademicsModule/AcademicCalendar';
import FeeConcessionOrRefund from './components/financeManagement/feeConcessionOrRefund';
import CreateTest from './components/ClassetAcademicsModule/Examination/createTest/index';
import CreateTestType from './components/ClassetAcademicsModule/Examination/createTestType/index';
import DigitalLibrary from './components/ClassetAcademicsModule/DigitalLibrary';
import CreateTemplate from './components/ClassetAcademicsModule/Examination/createTemplate/index';
import TestsandExam from './components/ClassetAcademicsModule/Examination/testsExam/index';
import NewQuestionBank from './components/ClassetAcademicsModule/Examination/questionBank/index';

import UsersBulkUpload from './components/studentManagement/bulkUpload';

import Axios from 'axios';
import { baseUrlAdmin } from './store/apiConstants';
import { icon } from '@fortawesome/fontawesome-svg-core';
const academicYearsOptions = [
    { finYear: '2022-2023', value: '2022-2023' },
    { finYear: '2021-2022', value: '2021-2022' },
    { finYear: '2020-2021', value: '2020-2021' }
]










let timer1;
const App = (props) => {

    //console.log(props)
    //console.log(loc.state.level, 'name')

    const [menuActive, setMenuActive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [academicYearOptions, setAcademicYearOptions] = useState([])
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [logoColor, setLogoColor] = useState('white');
    const [menuMode, setMenuMode] = useState('slim');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-white');
    const [componentTheme, setComponentTheme] = useState('blue');
    const [logoUrl, setLogoUrl] = useState('./macademy-logo2.jpg');
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [logged, setLogged] = useState(location.state?.level);
    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;
    const breadcrumb = [
        { path: '/', parent: 'Dashboard', label: 'Dashboard' },
        { path: '/organization', parent: 'Organization', label: 'Organization Master' },
        { path: '/boards', parent: 'Boards', label: 'Boards List' },
        { path: '/branch', parent: 'Branch Master', label: 'Branch Master' },
        { path: '/admission-create', parent: 'Admission', label: 'Admission Create' },
        { path: '/academic-years', parent: 'Admission', label: 'Academic Years' },
        { path: '/admission-types', parent: 'Admission', label: 'Admission Types' },
        { path: '/application-fees', parent: 'Admission', label: 'Application Fees' },
        { path: '/admission-courses', parent: 'Admission', label: 'Admission Courses' },
        { path: '/bus-fee-plans', parent: 'Finance', label: 'Bus Fee plans' },
        { path: '/fee-collect', parent: 'Finance', label: 'Fee Collect' },
        { path: '/fee-day-sheet', parent: 'Finance', label: 'Fee Day Sheet' },
        { path: '/fee-types', parent: 'Finance', label: 'Fee Types' },
        { path: '/fee-plans', parent: 'Finance', label: 'Fee Plans' },
        { path: '/vehicle-management', parent: 'Transport', label: 'Vehicle Management' },
        { path: '/transport-staff-management', parent: 'Transport', label: 'Transport Staff Management' },
        { path: '/route-management', parent: 'Transport', label: 'Route Management' },
        { path: '/transport-attendance', parent: 'Transport', label: 'Transport Attendence' },
        { path: '/transport-attendance-report', parent: 'Transport', label: 'Transport Attendence Report' },

        { path: '/curriculum', parent: 'CurriculumMaster', label: 'Curriculum Master' },
        { path: '/assign-content', parent: 'Assign Content', label: 'Assign Content' },
        { path: '/assign-content', parent: 'Assign Content', label: 'Assign Content' },
        { path: '/content', parent: "Contetn master", label: "Content Master" },
        { path: '/question-bank', parent: "Examination", label: "Question Bank" },
        { path: '/assign-question', parent: "Examination", label: "Assign Questions" },
        { path: '/examination', parent: "Examination", label: "Exams" },
        { path: '/discussion-board', parent: "Discussion Board", label: "Discussion Board" },
        { path: '/askadoubt', parent: "Ask a Doubt", label: "Ask a Doubt" },
        { path: '/rolesAndUsers', parent: "RolesAndUsers", label: "RolesAndUsers" },
        { path: '/transportManagement', parent: "Transport Management", label: "Transport Management" },
        { path: '/admin-manage', parent: 'Admin Management', label: 'Admin Management' },
        { path: '/admins-bulkupload', parent: 'Admin Management', label: 'Bulk Upload' },
        { path: '/finance', parent: "Settings", label: "Finance" },
        { path: '/UnAssignFee', parent: "Finance", label: "UnAssignFee" },

        { path: '/formlayout', parent: 'UI Kit', label: 'Form Layout' },
        { path: '/input', parent: 'UI Kit', label: 'Input' },
        { path: '/floatlabel', parent: 'UI Kit', label: 'Float Label' },
        { path: '/invalidstate', parent: 'UI Kit', label: 'Invalid State' },
        { path: '/button', parent: 'UI Kit', label: 'Button' },
        { path: '/table', parent: 'UI Kit', label: 'Table' },
        { path: '/list', parent: 'UI Kit', label: 'List' },
        { path: '/tree', parent: 'UI Kit', label: 'Tree' },
        { path: '/panel', parent: 'UI Kit', label: 'Panel' },
        { path: '/overlay', parent: 'UI Kit', label: 'Overlay' },
        { path: '/media', parent: 'UI Kit', label: 'Media' },
        { path: '/menu', parent: 'UI Kit', label: 'Menu' },
        { path: '/menu/seat', parent: 'UI Kit', label: 'Menu' },
        { path: '/menu/payment', parent: 'UI Kit', label: 'Menu' },
        { path: '/menu/confirmation', parent: 'UI Kit', label: 'Menu' },
        { path: '/messages', parent: 'UI Kit', label: 'Messages' },
        { path: '/file', parent: 'UI Kit', label: 'File' },
        { path: '/chart', parent: 'UI Kit', label: 'Charts' },
        { path: '/misc', parent: 'UI Kit', label: 'Misc' },
        { path: '/icons', parent: 'Utilities', label: 'Icons' },
        { path: '/blocks', parent: 'PrimeBlocks', label: 'Blocks' },
        { path: '/crud', parent: 'Utilities', label: 'Crud' },
        { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
        { path: '/timeline', parent: 'Pages', label: 'Timeline' },
        { path: '/invoice', parent: 'Pages', label: 'Invoice' },
        { path: '/help', parent: 'Pages', label: 'Help' },
        { path: '/empty', parent: 'Pages', label: 'Empty Page' },
        { path: '/documentation', parent: 'Pages', label: 'Documentation' },

        { path: '/', parent: 'PrimeBlocks', label: 'Dashboard' },
        { path: '/newusers', parent: 'PrimeBlocks', label: 'New users' },
        { path: '/users', parent: 'PrimeBlocks', label: 'Users' },
        { path: '/parents', parent: 'PrimeBlocks', label: 'Parents' },
        { path: '/leaves', parent: 'PrimeBlocks', label: 'Leaves' },
        { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
        { path: '/user-management', parent: 'PrimeBlocks', label: 'User ManageMent' },
        { path: '/user-bulkupload', parent: 'PrimeBlocks', label: 'Bulk Upload' },
        { path: '/ticket-management', parent: 'PrimeBlocks', label: 'Ticket Management' },
        { path: '/admissionManagement', parent: 'Home', label: 'Admission' },
        { path: '/schoolSetup', parent: 'Home', label: 'School Setup' },
        { path: '/notifications', parent: 'PrimeBlocks', label: 'Notification Management' },
        { path: '/feesetup', parent: "Home", label: "Finance" },
        { path: '/acadamics', parent: "Home", label: "Academics" },

        { path: '/studentInfo', parent: "Students", label: "Student Information" },
        { path: '/userBulkupload', parent: "Students", label: "Student BulkUpload" },

        { path: '/attendance', parent: "Students", label: "Attendance" },
        { path: '/studentTransfer', parent: "Students", label: "Student Transfer" },
        { path: '/transferPendingStudents', parent: "Students", label: "Transfer Pending Students" },

        { path: '/TeacherDairyTemplate', parent: "Academics", label: "Teacher Dairy Template" },
        { path: '/AcademicCalendar', parent: 'Academics', label: 'AcademicCalendar' },
        { path: '/TeacherDairy', parent: 'Academics', label: 'Teacher Dairy' },
        { path: '/DigitalLibrary', parent: 'Academics', label: 'Digital Library' },
        { path: '/CreateTest', parent: 'Examination', label: 'Create Test' },
        { path: '/QuestionBank', parent: 'Examination', label: 'Question Bank' },
        { path: '/CreateTestType', parent: 'Examination', label: 'Create Test Type' },
        { path: '/CreateTemplate', parent: 'Examination', label: 'Create Template' },
        { path: '/TestsandExams', parent: 'Examination', label: 'Tests and Exams' },

        { path: '/GradingSystem', parent: 'Prograss Reports', label: 'Grading System' },
        { path: '/PerformanceAnalytics', parent: 'Prograss Reports', label: 'Performance Analytics' },
        { path: '/GradeBookTemplate', parent: 'Prograss Reports', label: 'Grade Book Template' },
        { path: '/GradeBook', parent: 'Prograss Reports', label: 'Grade Book' },
        { path: '/ApprovalHierarchy', parent: 'Academics', label: 'Approval Hierarchy' },
        { path: '/Reports', parent: 'Academics', label: 'Reports' },
        { path: '/myTimeTable', parent: 'Timetable', label: 'My Timetable' },
        { path: '/requestSubstitution', parent: 'Timetable', label: 'Request Substitution' },
        { path: '/lagReport', parent: 'Timetable', label: 'Lag Report' },
        { path: '/timeTableReports', parent: 'Timetable', label: 'TimeTable Reports' },
        { path: '/enquiry', parent: 'Admissions', label: 'Enquiry' },
        { path: '/addEnquiry', parent: 'Enquiry', label: 'Add Enquiry' },
        { path: '/application', parent: 'Enquiry', label: 'Application' },
        {path:'/collectFee',parent:'Finance',label:'CollectFee'},
        {path:'/assignFee',parent:'Finance',label:'AssignFee'},
        {path:' /feeConcessionOrRefund',parent:'Finance',label:'Concession or Refund'}
      


    ];



    const menu = [
        {
            label: 'Settings', icon: 'pi pi-fw pi-cog',
            items: [
                { label: 'School Setup', icon: 'pi pi-id-card', to: '/schoolSetup' },
                { label: "Acadamics", icon: 'pi pi-check-square', to: "/acadamics" },
                { label: "Fee Setup", icon: 'pi pi-bookmark', to: "/feesetup" },
                { label: "Finance", icon: 'fa fa-money', to: "/finance" },
                { label: 'Admission', icon: 'pi pi-mobile', to: '/admissionManagement' },
                { label: 'Transport', icon: 'pi pi-table', to: '/transportManagement' },
                { label: 'Roles & Users', icon: 'pi pi-list', to: '/rolesAndUsers' },
                { separator: true },
            ]
        },
        { separator: true },
        {
            label: 'Admissions', icon: 'pi pi-building',
            items: [
                { label: 'Enquiry', icon: 'pi pi-id-card', to: '/enquiry' },
                { label: 'Application', icon: 'pi pi-check-square', to: '' },
                { label: 'Exam/Test', icon: 'pi pi-bookmark', to: '' },
                { label: 'Registration', icon: 'pi pi-exclamation-circle', to: '' },
                { label: 'Enrollment', icon: 'pi pi-mobile', to: '' },
                {
                    label: 'Reports', icon: 'pi pi-table', to: '',
                    items: [
                        {label:'# Enquiry Report', to: ''},
                        {label:'# Application Report', to: ''},
                        {label:'# Exam Report', to: ''},
                        {label:'# Registration Report', to: ''},
                        {label:'# Enrollment Report', to: ''},
                        {label:'# Admission Fee Report', to: ''},
                        {label:'# Demographic Report', to: ''},
                        {label:'# Grade Wise Report', to: ''},
                    ],
                },

            ]
        },
        { separator: true },
        {
            label: 'Students', icon: 'fa fa-graduation-cap',
            items: [
                { label: 'Home', icon: 'pi pi-home', to: '/' },

                { label: 'Student Information', icon: 'pi pi-id-card', to: '/studentInfo' },

                { label: 'Attendance', icon: 'pi pi-check-square', to: '/attendance' },
                { label: 'Student Transfer', icon: 'pi pi-exclamation-circle', to: '/studentTransfer' },
                { label: 'Transfer Pending Students', icon: 'pi pi-table', to: '/transferPendingStudents' },

                { separator: true },

            ]
        },
        { separator: true },
        {
            label: 'Finance', icon: 'fa fa-money',
            items: [{ label: 'Collect Fee/Fee Challan', icon: 'pi pi-building', to: '/collectFee' },
            { label: 'Assign Fee', icon: 'pi pi-info-circle', to: '/assignFee' },
            { label: 'Fee Unassign', icon: 'pi pi-bookmark', to: '/UnAssignFee' },
            { label: "Fee Concession/Refund", icon: 'pi pi-info-circle', to: '/feeConcessionOrRefund' },
            { label: "Cheque/DD Status", icon: 'pi pi-circle', to: '/chequeOrDDstatus' }

            ]
        },
        { separator: true },
        {
            label: 'Academics', icon: 'fa fa-thin fa-book',
            items: [

                { label: 'Academic Calender', icon: "pi pi-calendar pi-calendar-clock", to: "/AcademicCalendar" },
                { label: 'Teacher Dairy Template', icon: "pi pi-check-square", to: "/TeacherDairyTemplate" },
                { label: 'Teacher Dairy', icon: "pi pi-bookmark", to: "/TeacherDairy" },
                { label: 'Digital Library', icon: "pi pi-exclamation-circle", to: "/DigitalLibrary" },
                {
                    label: 'Examination', icon: "pi pi-mobile", items: [
                        { label: "# Create Test", to: "/CreateTest" },
                        { label: "# Question Bank", to: "/QuestionBank" },
                        { label: "# Create Test Type", to: "/CreateTestType" },
                        { label: "# Create Template ", to: "/CreateTemplate" },
                        { label: "# Tests & Exams", to: "/TestsandExams" },




                    ]
                },
                {
                    label: 'Progress Report', icon: "pi pi-table",
                    items: [{ label: "# Grading System ", to: "/GradingSystem " },
                    { label: "# Performance Analytics ", to: "/PerformanceAnalytics" },
                    { label: "# Grade Book Template", to: "/GradeBookTemplate" },
                    { label: "# Grade Book", to: "/GradeBook" },]
                },
                { label: 'Approval Hierarchy', icon: "pi pi-list", to: "/ApprovalHierarchy" },
                { label: 'Reports', icon: "pi pi-share-alt", to: "/Reports" },




            ]
        },
        { separator: true },
        // {
        //     label: 'admission', icon: 'fa fa-thin fa-book',
        //     items: [
        //         { label: 'admission', to: '/admissionManagement' },

        //     ]
        // },
        // { separator: true },
        {
            label: 'Timetable', icon: 'fa fa-clock-o',

            items: [
                { label: 'My Timetable', icon: 'pi pi-id-card', to: '/myTimeTable' },
                { label: 'Request Substitution', icon: 'pi pi-check-square', to: '/requestSubstitution' },
                { label: 'Lag Report', to: '/lagReport', icon: 'pi pi-bookmark' },
                { label: 'TimeTable Reports', icon: 'pi pi-exclamation-circle', to: 'timeTableReports' },

            ]
        },
        { separator: true },
        {
            label: 'Reports', icon: 'fa fa-bar-chart',
        },
        { separator: true },
        {
            label: 'Communicate', icon: 'pi pi-fw pi-comment',
        },
        { separator: true },

    ];

    let meta = breadcrumb.find((obj) => {
        return obj.path === location.pathname;
    });



    const handleAcadamicsYearsData = async () => {
        const year = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/academic-years/`;
        return await new Service().get(url, true)
            .then((res) => {
                if (res?.res?.status && res.status) {
                    setAcademicYearOptions(res?.res?.data);
                    // only of empty key in localstorage
                    setSelectedOption(res?.res?.data[0]._id)
                    if (year !== null && year !== '' && year != undefined) {
                        localStorage.setItem('userAcademicYear', year)
                    }
                    else {
                        localStorage.setItem('userAcademicYear', res?.res?.data[0]._id)
                    }


                } else {
                    setAcademicYearOptions([]);
                }
            })
            .catch((e) => {
                console.log(e);
                setAcademicYearOptions([]);
            });
    };



    useEffect(() => {
        handleAcadamicsYearsData()
    }, [])

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
        // if (location.state.level == 2) {
        //     setLogged(true)
        // }
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    // const admin = () => {
    //     setMenuActive1(true);
    //     props?.user?.user.username == 'superadmin' && menuActive1 == true ? { label: 'CREATE USER', icon: 'pi pi-fw pi-user-plus', to: '/create-user' } : null

    // };




    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl('theme-css', componentTheme, 2);
        setComponentTheme(componentTheme);

        const appLogoLink = document.getElementById('app-logo');
        const appLogoUrl = `macademy-logo2.jpg`;
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        const horizontalLogoUrl = `macademy-logo2.jpg`;

        if (appLogoLink) {
            appLogoLink.src = appLogoUrl;
        }
        if (horizontalLogoLink) {
            horizontalLogoLink.src = horizontalLogoUrl;
        }
        setLogoColor(logoColor);
    };




    const changeComponentTheme = (theme) => {
        setComponentTheme(theme);
        changeStyleSheetUrl('theme-css', theme, 3);
    };

    const changeColorScheme = (e) => {
        setColorScheme(e.value);

        const scheme = e.value;
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);

        changeLogo(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById('app-logo');
        const mobileLogoLink = document.getElementById('logo-mobile');
        const invoiceLogoLink = document.getElementById('invoice-logo');
        const footerLogoLink = document.getElementById('footer-logo');
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        setLogoUrl(`macademy-logo2.jpg`);

        if (appLogoLink) {
            appLogoLink.src = `macademy-logo.jpg`;
        }

        if (horizontalLogoLink) {
            horizontalLogoLink.src = `macademy-logo.jpg`;
        }

        if (mobileLogoLink) {
            mobileLogoLink.src = logoUrl;
        }

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            footerLogoLink.src = logoUrl;
        }
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                const _linkElement = document.getElementById(id);
                _linkElement && _linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
        } else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === 'static') {
            setStaticMenuDesktopInactive(false);
        }
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive((prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive((prevSearchActive) => !prevSearchActive);
        searchClick = true;
    };


    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive((prevRightMenuActive) => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };

    const onChangeDropDownOption = (value) => {
        localStorage.setItem('userAcademicYear', value)
        setSelectedOption(value)
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 1091;
    };

    const containerClassName = classNames(
        'layout-wrapper',
        {
            'layout-overlay': menuMode === 'overlay',
            'layout-static': menuMode === 'static',
            'layout-slim': menuMode === 'slim',
            'layout-horizontal': menuMode === 'horizontal',
            'layout-sidebar-dim': colorScheme === 'dim',
            'layout-sidebar-dark': colorScheme === 'dark',
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        colorScheme === 'light' ? menuTheme : ''
    );

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>

            {/* <InitialComponent /> */}
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-content-wrapper">
                <AppTopbar
                    meta={breadcrumb}
                    topbarNotificationMenuActive={topbarNotificationMenuActive}
                    topbarUserMenuActive={topbarUserMenuActive}
                    onMenuButtonClick={onMenuButtonClick}
                    academicYearOptions={academicYearOptions}
                    selectedOption={selectedOption}
                    onChangeDropOption={onChangeDropDownOption}
                    onSearchClick={toggleSearch}
                    onTopbarNotification={onTopbarNotificationMenuButtonClick}
                    onTopbarUserMenu={onTopbarUserMenuButtonClick}
                    onRightMenuClick={onRightMenuButtonClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    menuMode={menuMode}
                    menu={menu}
                    menuActive={menuActive}
                    staticMenuMobileActive={staticMenuMobileActive}
                    onMenuClick={onMenuClick}
                    onMenuitemClick={onMenuitemClick}
                    onRootMenuitemClick={onRootMenuitemClick}
                ></AppTopbar>

                <div style={{ backgroundColor: "white" }} className="layout-content overflow-auto">
                    <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                        <AppBreadcrumb meta={breadcrumb} />
                    </div>
                    <Routes>
                        {/* <Route path="/" element={<Dashboard/>} /> */}
                        <Route path="/boards" element={<Boards />} />
                        {/* <Route path="/" element={<TableDemo />} /> */}
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/branch" element={<Branch />} />
                        <Route exact path='/organization' element={<OrganizationMaster />} />
                        <Route exact path="/admission-create" element={<EnquiryFrom />} />
                        <Route exact path="/academic-years" element={<AcadamicYear />} />
                        <Route exact path="/admission-types" element={<AdmissionType />} />
                        <Route exact path="/application-fees" element={<ApplicationFee />} />
                        <Route exact path="/admission-courses" element={<Course />} />
                        <Route exact path="/bus-fee-plans" element={<BusFeePlans />} />
                        <Route exact path="/fee-collect" element={<Finance />} />
                        <Route exact path="/fee-day-sheet" element={<FeeDaySheet />} />
                        <Route exact path="/fee-types" element={<FeeTypes />} />
                        <Route exact path="/fee-plans" element={<FeePlan />} />
                        <Route exact path="/vehicle-management" element={<VehicleManagement />} />
                        <Route exact path="/transport-staff-management" element={<TransportStaffManagement />} />
                        <Route exact path="/route-management" element={<RouteManagement />} />
                        <Route exact path="/transport-attendance" element={<TransportAttendance />} />
                        <Route exact path="/transport-attendance-report" element={<TransportAttendanceReport />} />
                        <Route path='/curriculum' element={<CurriculumMaster />} />
                        <Route path="/assign-content" element={<AssignContent />} />
                        <Route path="/yearlyplanner" element={<CalendarMaster />} />
                        <Route exact path='/curriculum' element={<CurriculumMaster />} />
                        <Route exact path="/assign-content" element={<AssignContent />} />
                        <Route exact path="/content" element={<ContentMaster />} />
                        <Route exact path="/content-bulkupload" element={<BulkUploadc />} />
                        <Route exact path="/yearlyplanner" element={<CalendarMaster />} />
                        <Route exact path="/yearlyplanner-bulk" element={<BulkUploadYearlyPlanner />} />
                        <Route exact path="/timetable" element={<TimeTable />} />
                        <Route exact path="/question-bank" element={<QuestionBank />} />
                        <Route exact path="/examination" element={<Examinations />} />
                        <Route exact path="/assign-question" element={<AssignQuestions />} />
                        <Route exact path='/virtualclass' element={<VirtualClass />} />
                        <Route exact path="/discussion-board" element={<DiscussionBoard />} />
                        <Route exact path="/askadoubt" element={<AskADoubt />} />
                        <Route exact path="/admin-manage" element={<AdminManagement />} />
                        <Route exact path="/admins-bulkupload" element={<BulkUploadA />} />
                        <Route exact path="/approval-management" element={<ApprovalManagement />} />
                        <Route exact path="/classroom" element={<ClassRoom />} />
                        <Route exact path="/give-attendance" element={<Attendance />} />
                        <Route exact path="/upload-attendance" element={<BulkAttendance />} />
                        <Route exact path="/attendance-report" element={<AttendaceReport />} />
                        <Route exact path="/usage-analytics" element={<UsageAnalytics />} />
                        <Route exact path="/exam-analytics" element={<ExamAnalytics />} />
                        <Route exact path="/teacher-analytics" element={<TeacherAnalytics />} />
                        <Route exact path="/branch-analytics" element={<BranchWiseAnalytics />} />
                        <Route exact path="/user-management" element={<UserManagement />} />
                        <Route exact path="/user-bulkupload" element={<BulkUpload />} />
                        <Route exact path="/ticket-management" element={<TicketManagement />} />
                        <Route exact path="/notifications" element={<Notification />} />
                        <Route exact path="/transportManagement" element={<TransportIndex />} />
                        <Route exact path="/admissionManagement" element={<AdminManagementIndex />} />
                        <Route exact path="/schoolSetup" element={<SchoolSetupIndex />} />
                        <Route exact path="/rolesAndUsers" element={<RolesAndUsers />} />
                        <Route exact path='/chequeOrDDstatus' element={<ChequeOrDDStatus />} />
                        <Route exact path="/feeSetUp" element={<FeeSetUpIndex />} />
                        <Route exact path="/acadamics" element={<Acadamics />} />
                        <Route exact path="/finance" element={<FinanceNewIndex />} />
                        <Route exact path='/UnAssignFee' element={<UnAssignFee />} />
                        <Route exact path='/myTimeTable' element={<TimeTableNew />} />
                        <Route exact path='/requestSubstitution' element={<RequestSubstitution />} />



                        <Route exact path="/studentInfo" element={<StudentInfo />} />
                        <Route exact path="/attendance" element={<StudentAttendance />} />
                        <Route exact path="/studentTransfer" element={<StudentTransfer />} />
                        <Route exact path="/transferPendingStudents" element={<TransferPendingStudents />} />
                        <Route exact path='/collectFee' element={<CollectFee />} />
                        <Route exact path='/assignFee' element={<AssignFeeScreen />} />

                        <Route exact path='/TeacherDairyTemplate' element={<TeacherDairyFormat />} />
                        <Route exact path='/AcademicCalendar' element={<AcademicCalendar />} />
                        <Route exact path='/DigitalLibrary' element={<DigitalLibrary />} />
                        <Route exact path='/feeConcessionOrRefund' element={<FeeConcessionOrRefund />} />
                        <Route exact path='/CreateTest' element={<CreateTest />} />
                        <Route exact path='/CreateTestType' element={<CreateTestType />} />
                        <Route exact path='/CreateTemplate' element={<CreateTemplate />} />
                        <Route exact path='/TestsandExams' element={<TestsandExam />} />
                        <Route exact path='/QuestionBank' element={<NewQuestionBank />} />
                        <Route exact path='/enquiry' element={<Enquiry/>} />
                        <Route exact path='/addEnquiry' element={<AddEnquiry/>} />
                        <Route exact path='/application' element={<ApplicationCreate/>} />

                        <Route exact path="/userBulkupload" element={<UsersBulkUpload />} />












                        {/* <Route path="/formlayout" element={<FormLayoutDemo />} /> */}
                        {/* <Route path="/formlayout" element={<FormLayoutDemo />} />
                        <Route path="/input" element={<InputDemo />} />
                        <Route path="/floatlabel" element={<FloatLabelDemo />} />
                        <Route path="/invalidstate" element={<InvalidStateDemo />} />
                        <Route path="/button" element={<ButtonDemo />} />
                        <Route path="/table" element={<TableDemo />} />
                        <Route path="/list" element={<ListDemo />} />
                        <Route path="/tree" element={<TreeDemo />} />
                        <Route path="/panel" element={<PanelDemo />} />
                        <Route path="/overlay" element={<OverlayDemo />} />
                        <Route path="/media" element={<MediaDemo />} />
                        <Route path="/menu/*" element={<MenuDemo />} />
                        <Route path="/messages" element={<MessagesDemo />} />
                        <Route path="/file" element={<FileDemo />} />
                        <Route path="/chart" element={<ChartDemo colorMode={colorScheme} location={location} />} />
                        <Route path="/misc" element={<MiscDemo />} />
                        <Route path="/icons" element={<IconsDemo />} />
                        <Route path="/crud" element={<CrudDemo />} />
                        <Route path="/blocks" element={<BlocksDemo />} />
                        <Route path="/calendar" element={<CalendarDemo />} />
                        <Route path="/timeline" element={<TimelineDemo />} />
                        <Route path="/invoice" element={<Invoice logoUrl={logoUrl} location={location} />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/empty" element={<EmptyPage />} />
                        <Route path="/documentation" element={<Documentation />} /> */}
                    </Routes>
                </div>

                <AppFooter />
            </div>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

            <AppConfig
                configActive={configActive}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                colorScheme={colorScheme}
                changeColorScheme={changeColorScheme}
                menuTheme={menuTheme}
                changeMenuTheme={changeMenuTheme}
                componentTheme={componentTheme}
                changeComponentTheme={changeComponentTheme}
                onConfigClick={onConfigClick}
                onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
            ></AppConfig>

            {/* <AppSearch searchActive={searchActive} onSearchClick={onSearchClick} onSearchHide={onSearchHide} /> */}
        </div>
    );
};






const mapStateToProps = (state) => ({
    user: state.user,
    authenticated: state?.user?.authenticated,
    isLoading: state?.orgData?.isLoading,
    isSuperOrg: state.orgData.isSuper,
});

export default connect(mapStateToProps, {})(Authentication(withRouter(App)));

