import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import Authentication from '../../session';
import { baseUrlAdmin, primaryColor } from '../../../store/apiConstants';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { AcademicSessionCalIcon, AddIconInButton, AssignFormIcon, CalenderCheckIcon, CalenderIcon, ClassetEditIcon, CodeLinkIcon, MailIcon, PlainCalenderIcon } from '../../svgIcons';
import { getFormFields } from '../../../utile/formHelper';
import { isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper'
import { Dropdown } from 'primereact/dropdown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { trimObj } from '../../../utile';
import { cloneDeep } from 'lodash';
import Service from '../../../services';
import uuidv4 from 'uuid/v4';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';

const createBoardFields = require('./boardFields.json');

class BoardsCreation extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createBoardFields);
        this.state = {
            isDialogOpenForCreateBoard: false,
            board: this.formFields.data,
            formValidations: this.formFields.formValidations,
            dateRange: null,
            academicYearDdValues: [],
        };
        this.service = new Service()

    }

    componentDidMount() {
        this.getBoardsAndBranches()
    }
    getBoardsAndBranches = () => {
        this.props.getBoardsData(true);
        this.props.getBranchesLatest(true);
        this.getBranchDropdownValues();
        this.getAcademicYers()
    }
    componentDidUpdate(prevProps, prevState) {

    }
    getAcademicYers = () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/academic-years/`;
        this.service.get(url, true).then(res => {


            if (res && res.res && res.res.data) {
                let dd = res.res.data.map(obj => ({ value: obj._id, label: obj.academicCode }))
                this.setState({
                    isLoading: false,
                    academicYearDdValues: res.res.data.map(obj => ({ value: obj._id, label: obj.academicCode })),
                });
            } else {
                this.setState({
                    isLoading: false,
                    academicYearDdValues: []
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            this.setState({
                isLoading: false,
                academicYearDdValues: []
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }
    getBranchDropdownValues = () => {
        if (this.props.branchData) {
            this.setState({ branchesDdValues: this.props?.branchData?.filter(obj => obj.level === 1).map(obj => ({ value: obj.key, label: obj.name.toUpperCase() })) })

        }
    }

    onEditBoard = (boardData) => {

        let data = cloneDeep(boardData)

        this.setState({ edit: true, isDialogOpenForCreateBoard: true, board: data, dateRange: [new Date(boardData.startDate), new Date(boardData.endDate)] })
    }

    onSubmitClick = (activate) => {
        const formStatus = isFormValid(createBoardFields, this.formFields.formValidations, trimObj(this.state.board));

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            let bodyForApi = cloneDeep(this.state.board)

            if (this.state.edit) {
                bodyForApi.startDate = this.state.dateRange[0];
                bodyForApi.endDate = this.state.dateRange[1];
                bodyForApi.boardActivity = activate;
                delete bodyForApi.date;
                bodyForApi.academicYear = bodyForApi.academicCode;
            } else {
                bodyForApi.boardId = uuidv4();
                bodyForApi.boardActivity = activate;
                bodyForApi.classes = [];
                bodyForApi.startDate = this.state.dateRange[0];
                bodyForApi.endDate = this.state.dateRange[1];
                delete bodyForApi.date;
                bodyForApi.academicYear = bodyForApi.academicCode;
            }

            this.setState({
                isLoading: true
            })
            let url = `${baseUrlAdmin}/organization/board`

            let etag = this.props?.boardsDataFor?.etag;
            let headers = {
                "If-Match": etag,
            }
            console.log(bodyForApi);

            this.service.post(url, bodyForApi, true, headers).then(res => {

                if (res?.status && res?.res?.status) {
                    this.state.edit ? this.toast.show({ severity: 'success', summary: 'Board Updated Successfully', detail: `${bodyForApi.boardName}`, life: 3000 }) :
                        this.toast.show({ severity: 'success', summary: 'Board Created Successfully', detail: `${bodyForApi.boardName}`, life: 3000 });
                    this.setState({
                        isLoading: false,
                        isDialogOpenForCreateBoard: false,
                        edit:false

                    }, () => { this.getBoardsAndBranches() })
                } else {
                    this.setState({
                        isLoading: false,
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(err => {
                console.log(err, 'err')
                this.setState({
                    isLoading: false,
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
            })


        }
    }

    changeStatus = (boardData, status) => {
        this.setState({ board: boardData, edit: true, dateRange: [new Date(boardData.startDate), new Date(boardData.endDate)] }, () => this.onSubmitClick(status));
    }
    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData
        })
    }

    render() {
        const { board, formValidations } = this.state
        return (
            <div>
                <div className="admin-management">
                    {/* <BranchToSectionDD setValues={this.setDropdownValues} /> */}

                    {/* <p>{this.props?.boardsDataFor?.etag}</p> */}
                    <div className="grid mb-6">
                        <div className='col-11 flex justify-content-end align-items-center'>
                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={() => { this.setState({ isDialogOpenForCreateBoard: true }) }}>
                                <p className='addButtonClass'>ADD</p>
                                <AddIconInButton />
                            </Button>
                        </div>
                    </div>
                    <div className='grid doubts-tabs'>
                        {
                            this?.props?.boards?.map((board, index) => {
                                return <div
                                    className="mx-6 card cursor-pointer col-3 doubts-tab-w"
                                    style={{ height: '170px', width: '320px' }}
                                >
                                    <div className="flex flex-column justify-content-center align-items-center ml-3">
                                        <div className="mt-1 w-full flex justify-content-end align-items-center">
                                            <InputSwitch
                                                onChange={(e) => { this.changeStatus(board, !board?.boardActivity) }}
                                                checked={board?.boardActivity} />
                                        </div>
                                        <div className="w-full flex  ml-3">
                                            <h3 className='Montserrat24'>{board?.boardCode?.toUpperCase()}</h3>
                                        </div>
                                        <div className="flex justify-content-center mr-5">
                                            <p className='poppins24' style={{ fontSize: '24px' }}>{board?.academicYear}</p>
                                        </div>
                                        <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center' onClick={() => this.onEditBoard(board)}>
                                            <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <Dialog
                        visible={this.state.isDialogOpenForCreateBoard}
                        draggable={false}
                        closeOnEscape={false}
                        // position={"right"}
                        className='ma-alert-box w-8'
                        style={{ width: "500px", height: "700px" }}
                        dismissableMask={false}
                        closable={true}
                        header={() => {
                            return (<div className='mt-2'>
                                <h4 className="formHeadingInter">Add Board</h4>
                            </div>)
                        }}
                        footer={() => (
                            <div className='flex justify-content-center mb-4'>
                                <Button className='formSubmitButton px-6' style={{ borderRadius: '10px' }} onClick={() => this.onSubmitClick(this.state.edit ? this.state.board?.boardActivity : false)}>
                                    <p className='formSubmitButtonText'> {this.state.edit ? 'Update' : 'Create'}</p>
                                </Button>
                                {!this.state.edit && <Button className='formSubmitButton px-8' style={{ borderRadius: '10px' }} onClick={() => this.onSubmitClick(true)} >
                                    <p className='formSubmitButtonText'>Create & Activate</p>
                                </Button>}
                                <Button className='formCancelButton px-6' style={{ borderRadius: '10px' }} onClick={() => this.setState({ board: {}, dateRange: null, isDialogOpenForCreateBoard: false , edit:false})} >
                                    <p className='formCancelButtonText'>Cancel</p>
                                </Button>
                            </div>
                        )}
                        onHide={() => this.setState({ board: {}, dateRange: null, isDialogOpenForCreateBoard: false,edit:false })}
                    >
                        <div className='flex flex-column justify-content-center mx-6'>
                            <div className='grid col-12 justify-content-center'>
                                <div className=" col-6">
                                    <ClassetInputText
                                        required={true}
                                        label={'Board Name'}
                                        icon={<MailIcon />}
                                        placeholder={'Board Name'}
                                        value={board.boardName}
                                        title="Name"
                                        tooltipInfo={<div>
                                            <p className='font-semibold text-sm'>Enter the Name of Board </p>
                                            <p className="-mt-1">Name will be displayed throughout</p>
                                        </div>}
                                        onChange={(e) => { onTextChange(e.target.value, 'boardName', this, createBoardFields, board, formValidations, 'board', 'formValidations') }} />

                                    {formValidations && !formValidations.fields['boardName'].isValid && <p className="p-error font-semibold">{formValidations.fields['boardName'].errorMsg}</p>}

                                </div>
                                <div className=" col-6">
                                    <ClassetInputText tooltipInfo={<div>
                                        <p className='font-semibold text-sm'>Enter the Code of Board</p>
                                        <p className="-mt-1">Code will be displayed throughout</p>
                                    </div>}
                                        title="Code"
                                        required={true}
                                        label={'Board Code'}
                                        icon={<CodeLinkIcon height={22} />}
                                        placeholder={'Code (Alpha Numeric)'}
                                        value={board.boardCode} onChange={(e) => { onTextChange(e.target.value, 'boardCode', this, createBoardFields, board, formValidations, 'board', 'formValidations') }} validation={formValidations.fields['boardCode'].isValid} />

                                    {formValidations && !formValidations.fields['boardCode'].isValid && <p className="p-error font-semibold">{formValidations.fields['boardCode'].errorMsg}</p>}
                                </div>
                                <div className=" col-6">
                                    <ClassetCalendar
                                        title="Date"
                                        tooltipInfo={
                                            <div>
                                                <p className='font-semibold text-sm'>Pick From and To Dates</p>
                                                <p className="-mt-1">Enter the start and end dates for the Board</p>
                                            </div>
                                        }
                                        calendarMode={'range'}
                                        required={true} label={'Date'}
                                        icon={<CalenderIcon height={20} />}
                                        value={this.state.dateRange}
                                        onChange={(e) => { this.setState({ dateRange: e.value }) }}
                                        placeholder={'Pick Date'} />
                                    {/* <p className="p-error font-semibold">{this.state.dateRange?.length ? '' : ' Pick Start & End Date '}</p> */}

                                </div>
                                <div className=" col-6">
                                    <ClassetDropdown
                                        title="Session"
                                        tooltipInfo={
                                            <div>
                                                <p className='font-semibold text-sm'>select Academic Session</p>
                                                <p className="-mt-1">This term will be created in the selected Academic Session</p>
                                            </div>
                                        }
                                        required={true}
                                        label={'Academic Session'}
                                        icon={<AcademicSessionCalIcon />}
                                        placeholder={'Select Academic Session'}
                                        options={this.state.academicYearDdValues}
                                        value={board.academicCode} onChange={(e) => { onDropDownChange(e.target.value, 'academicCode', this, createBoardFields, board, formValidations, 'board', 'formValidations') }} />

                                    {formValidations && !formValidations.fields['academicCode'].isValid && <p className="p-error font-semibold">{formValidations.fields['academicCode'].errorMsg}</p>}
                                </div>
                                <div className='col-6'>
                                    <ClassetMultiSelect icon={<AssignFormIcon />} label={'Assign branches'} required
                                        title="Branches"
                                        tooltipInfo={
                                            <div>
                                                <p className='font-semibold text-sm'>select Branches</p>
                                                {/* <p className="-mt-1">Enter the start and end dates for the Board</p> */}
                                            </div>
                                        }
                                        options={this.state.branchesDdValues}
                                        value={board.assignedBranches}
                                        placeholder='Select Branches'
                                        onChange={(e) => { onDropDownChange(e.target.value, 'assignedBranches', this, createBoardFields, board, formValidations, 'board', 'formValidations') }} />

                                    {formValidations && !formValidations.fields['assignedBranches'].isValid && <p className="p-error font-semibold">{formValidations.fields['assignedBranches'].errorMsg}</p>}
                                </div>
                                <div className='col-6'>
                                    <ClassetTextArea
                                        tooltipInfo={
                                            <div>
                                                <p className='font-semibold text-sm'>Description</p>
                                                <p className="-mt-1">Write a brief to understand this Boards for others</p>
                                            </div>
                                        }
                                        title='Description'
                                        label={'Description'}
                                        value={board.description}
                                        onChange={(e) => { onTextChange(e.target.value, 'description', this, createBoardFields, board, formValidations, 'board', 'formValidations') }} placeholder="Description" rows={3} />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: state.boardsData.boards,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    boardsDataFor: state.boardsData,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(BoardsCreation)));