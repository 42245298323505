
import * as ActionTypes from "../actionTypes";

export default function(state={boardsDropDownOptions:[],academicSessionsDropDownOptions:[]}, action){
    switch(action.type){
        case ActionTypes.REQUEST_BOARDS_DROPDOWN_DATA:
            return{
                ...state,
               boardsDropDownOptions:action.data
            }
        case ActionTypes.REQUEST_ACADEMIC_SESSIONS_DATA :
            return{
                ...state,
                academicSessionsDropDownOptions:action.data
            }    
            // case ActionTypes.REQUEST_CURRENT_UPDATE_ACADEMIC_YEARS :
            //     return{
            //         ...state,
            //         academicGlobalCurrentYear:action.year
            //     }    
        default:
            return state
    }
}