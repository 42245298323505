import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SvgViewer from '../../../customComponents/svgViewer';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import InputTextB from '../../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../../constants';
import { getFormFields, isFormValid, onTextChange } from '../../../../utile/formHelper';
import { BasicLazyParams, trimObj } from '../../../../utile';
import { InputTextarea } from 'primereact/inputtextarea';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { DeleteIcon } from '../../../svgIcons';
import AddTemplate from './addTemplate';
import { Toolbar } from 'primereact/toolbar';
import LoadingComponent from '../../../loadingComponent';

const formFields = [
    {
        "Type": "Text",
        "Label": "Name",
        "FieldName": "name",
        "Required": true,
        "MaxLength": 50
    },
    {
        "Type": "Text",
        "Label": "Content",
        "FieldName": "content",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Template Id",
        "FieldName": "templateId",
        "Required": true
    }

]

export default class SmsTemplates extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, {});
        this.state = {
            templates: [],
            template: this.formFields.data,
            formValidations: this.formFields.formValidations,
            globalFilter: "",
            isShowAdd: false,
            columns: [],
            lazyParams: BasicLazyParams,
            globalSearch: ''

        }
        this.service = new Service();
    }

    isShowEdit = (rowData) => {
        this.setState({
            isShowAdd: true,
            editTemplate: rowData

        })
    }

    getTemplates = () => {
        this.setState({
            isLoading: true,
            templates: [],
            totalRecords: 0
        });
        let { lazyParams, globalSearch } = this.state;
        let url = `${baseUrlAdmin}/smstemplate/${this.props.orgId}?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.post(url, lazyParams.filters, true).then(res => {
            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        templates: res.res.data.templates,
                        totalRecords: res.res.data.totalRecords,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
                isShowConfigChange: false,
            }
        }, this.getTemplates);
    }

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTemplates);
    }

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTemplates);
    }

    onFilter = (event) => {
        event['first'] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getTemplates);
    }

    handleDelete = (rowData) => {
        const url = `${baseUrlAdmin}/smstemplate/${rowData._id}`;
        this.setState({
            isLoading: true
        })
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.getTemplates();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }


    componentDidMount() {
        this.getTemplates();
    }

    editTemplate = (rowData, { rowIndex }) => {
        return (
            <>
                <Authorizer permId={PERMISSIONS.EDIT_BOARD} >
                    <span data-pr-tooltip="Edit Template" data-pr-position="bottom" className={`e1${rowIndex}`} onClick={() => { this.isShowEdit(rowData) }}>
                        <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                    </span>
                    <Tooltip className="table-li-tooltip" autoHide={false} target={`.e1${rowIndex}`} />
                </Authorizer>
                <Authorizer permId={PERMISSIONS.TIMETABLE_DELETE} >
                    <span data-pr-tooltip="Delete Template" data-pr-position="bottom" className={`dlete${rowIndex} ma-mlr10`}
                        onClick={() => { this.handleDelete(rowData) }}
                    >
                        <DeleteIcon height={20} width={20} />
                    </span>
                    <Tooltip className="table-li-tooltip" target={`.dlete${rowIndex}`} />
                </Authorizer>
            </>
        );
    }


    onCreateOrEditSuccess = () => {

        this.setState({
            isShowAdd: false,
            editTemplate: null

        }, this.getTemplates);


    }

    render() {

       // console.log(this.state, 'test')
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Templates List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem" onChange={this.onGlobalSearch} />}

                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.BOARDS_VIEWCOLUMNS} >
                            <i data-pr-tooltip="View Columns"
                                onClick={(event) => this.menu.toggle(event)}
                                data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className='mt-2'
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (
            <div className='mt-4'>
                <h4>Template Management</h4>

                <Authorizer permId={PERMISSIONS.ADD_BOARD} >
                    <div className='flex justify-content-end mb-3'>
                        {/* <Toolbar className="ma-toolbard"
                            right={() => {
                                return  */}
                                <Button label="+ Add Template" className='ma-m-lr10'
                                    onClick={() => {
                                        this.setState({
                                            isShowAdd: true,
                                            editTemplate: null
                                        })
                                    }} />
                            {/* }}
                        /> */}
                    </div>
                </Authorizer>
                {
                    this.state.isShowAdd && <>
                        <AddTemplate
                            editTemplate={this.state.editTemplate} hideAddTemplateDialog={() => {
                                this.setState({
                                    editTemplate: null,
                                    isShowAdd: false
                                })
                            }}
                            onCreateOrEditSuccess={this.onCreateOrEditSuccess}
                            orgId={this.props.orgId}
                        />
                    </>
                }
                <div className="card datatable-crud-demo ma-m30">
                    <DataTable ref={(el) => this.dt = el}
                        scrollable
                        responsiveLayout="scroll"
                        value={this.state.templates}
                        dataKey="_id"
                        lazy
                        onSort={this.onSort}
                        onPage={this.onPage}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        paginator
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} templates"
                        globalFilter={this.state.globalFilter}
                        header={header}

                    >

                        <Column field="name" header="Name" />
                        <Column field="content" header="Content" />
                        <Column field="templateId" header="Template Id" />
                        <Column header="Edit" body={this.editTemplate} ></Column>
                    </DataTable>

                </div>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        )
    }
}
