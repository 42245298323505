import { Tooltip } from 'primereact/tooltip';
import './styles.scss';

function InputText2(props) {
    return (
        <>
            <div style={{ display: 'flex' ,border : "#cbc8c8 1px solid"}} className='custom-input2'>
                <span className='left left-b'>{props.left}</span>
                <input id={props.id} type="text" className='input-text' disabled={props.otpVerified} value={props.value} onChange={props.onChange} />
                {!props.otpVerified && <span className='right ma-pointer action-text' onClick={props.onActionClick}>{props.actionText}</span>}

                {/* {!props.otpVerified ? <span className='right ma-pointer action-text' onClick={props.onActionClick}>{props.actionText}</span>:<span className='right ma-pointer action-text' onClick={props.onEditClick}>{props.EditActionText}</span>} */}
            </div>
        </>
    );
}

export default InputText2;