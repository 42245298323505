
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import InputTextB from './../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import Services from './service';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { WarningIcon } from '../svgIcons';

class AdditionalGrades extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            board: [],
            classes: [],
            boardId: this.props.baseAccountInfo.user_info['custom:boardId'],
            addGradeForm: { classId: null, className: null, groupId: null, groupName: null },
            anotherGrades: [],
            isShowAddGradeForm: false,
            groupsOfSeletedClass: [],
            baseAccountInfo: this.props.baseAccountInfo,
            dialogBox: { show: false, msg: '' },
            deleteGrade: {}
        };
    }



    populateBoardFormData() {

        this.setState({ classes: [], groups: [] });
        const _classes = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === this.state.boardId);
        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
        }
        this.setState(
            {
                classes: _classes,
            }
        );

    };

    updateBoardStateFromProps() {
        if (this.props.boards) {


            let boards = [];
            this.props.boards &&
                this.props.boards.map((item, index) => {
                    boards.push({ boardId: item.boardId, boardName: item.boardName });
                });

            const _classes = [];
            const selectedBoard =
                this.props.boards && this.props.boards.find((board) => board.boardId === this.state.boardId);

            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            this.setState({
                board: boards,
                classes: _classes
            });
        }
    };

    componentDidMount() {
        this.setState({ isLoading: true });
        const services = new Services();

        services.getUserGrades(this.state.baseAccountInfo.user_id).then((data) => {
            if (data.status && data.res.Items) {

                this.setState({
                    anotherGrades: data.res.Items
                },
                    this.props.hideUpdateButton(data.res.Items.length)
                );

            }
        });

        this.updateBoardStateFromProps();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            this.updateBoardStateFromProps();
        }
    }

    onChangeClass1 = (classId) => {
        this.setState({ groupsOfSeletedClass: [], addGradeForm: { groupId: null, groupName: null } });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        this.setState({
            user: { ...this.state.user, grade: selectedClass && selectedClass.className }
        });

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
        }


        const selectedGroup =
            selectedClass &&
            selectedClass.groups &&
            selectedClass.groups.length &&
            selectedClass.groups[0];

        this.setState((prevState) => ({
            groupsOfSeletedClass: _groups,
            addGradeForm: {
                ...prevState.addGradeForm,
                classId, className: selectedClass.className,
                groupId: selectedGroup.groupId,
                groupName: selectedGroup.groupName
            }
        }));
    };

    // onChangeGroup1 = (groupId) => {
    //     const selectedBoard =
    //         this.props.boards &&
    //         this.props.boards.find((board) => board.boardId === this.state.boardId);
    //     const selectedClass =
    //         selectedBoard &&
    //         selectedBoard.classes &&
    //         selectedBoard.classes.length &&
    //         selectedBoard.classes.find((_class) => _class.classId === this.state.addGradeForm.classId);
    //     const selectedGroup =
    //         selectedClass &&
    //         selectedClass.groups &&
    //         selectedClass.groups.length &&
    //         selectedClass.groups.find((_group) => _group.groupId === groupId);
    //     this.setState((prevState) => ({
    //         addGradeForm: { ...prevState.addGradeForm, groupId, groupName: selectedGroup.groupName }
    //     }));
    // };

    addNewGrade = () => {

        if (!this.state.addGradeForm.classId || !this.state.addGradeForm.groupId) {
            this.toast.show({ severity: 'error', summary: 'Select all', detail: 'Please select required fields.', life: 5000 });
        }
        else {
            const services = new Services();
            const userData = this.state.baseAccountInfo;
            const info = this.state.addGradeForm;
            const data = {
                user_id: userData.user_id,
                isBaseAccount: false,
                user_info: {
                    username: userData.user_info.username,
                    "phone_number": userData.user_info.phone_number,
                    "given_name": userData.user_info.given_name,
                    "custom:board": userData.user_info["custom:board"],
                    "custom:boardId": userData.user_info["custom:boardId"],
                    "custom:grade": info.className,
                    "custom:gradeId": info.classId,
                    "custom:groupId": info.groupId,
                    "custom:group": info.groupName,
                    "classId": info.classId,
                    "groupId": info.groupId,
                    "boardId": userData.user_info.boardId,
                    branchId: null,
                    courseId: null,
                    sectionId: userData.user_info.sectionId,
                    "profilePicStorageKey": userData.user_info.profilePicStorageKey,
                    "shippingAddress": userData.user_info.shippingAddress,
                    isBaseAccount: false,
                }
            }

            services.addNewGradeToUser(data).then((data) => {

                if (data.status) {
                    this.setState((prevState) => ({
                        addGradeForm: { classId: null, className: null, groupId: null, groupName: null },
                        anotherGrades: [...prevState.anotherGrades, data.res.data],
                        isShowAddGradeForm: false
                    }),
                        this.props.hideUpdateButton(true)
                    );
                    this.toast.show({ severity: 'success', summary: 'successfull', detail: 'Grade added successfully.', life: 5000 });
                }
            });
        }
    };


    onGradeAddCancel = () => {
        this.setState({
            isShowAddGradeForm: false
        });
    }
    getAddedGradeIds = (anotherGrades) => {
        let addedGrades = [];
        if (this.props.baseAccountInfo) {
            addedGrades.push(this.props.baseAccountInfo.classId);
        }

        if (anotherGrades && anotherGrades.length) {
            anotherGrades.forEach(grade => {
                addedGrades.push(grade.user_info.classId);
            })

        }

        return addedGrades;



    }
    showAddGradeForm = () => {

        let addedGrades = this.getAddedGradeIds(this.state.anotherGrades);


        let classes = this.state.classes.filter((c) => !(addedGrades.includes(c.classId)));


        this.setState({
            isShowAddGradeForm: true,
            classes
        });
    };



    showConfirmDialog = (accountId, userId) => {
        this.setState({
            deleteGrade: { accountId, userId },
            dialogBox: { show: true, msg: '' }
        });
    }

    deleteGrade = () => {
        this.setState((prevState) => ({
            dialogBox: { show: false, msg: '' }
        }));
        const services = new Services();
        services.deleteGrade(this.state.deleteGrade).then((data) => {
            if (data.status) {

                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ boardId: item.boardId, boardName: item.boardName });
                    });


                const _classes = [];
                const selectedBoard =
                    this.props.boards && this.props.boards.find((board) => board.boardId === this.state.boardId);

                if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                    selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
                }

                let anotherGrades = this.state.anotherGrades.filter((grade) => grade.account_id !== this.state.deleteGrade.accountId)
                let addedGrades = this.getAddedGradeIds(anotherGrades);
                let classes = _classes.filter((c) => !(addedGrades.includes(c.classId)));

                this.setState((prevState) => ({
                    anotherGrades: anotherGrades,
                    dialogBox: { show: false, msg: '' },
                    classes
                }),
                    this.props.hideUpdateButton(this.state.anotherGrades.length - 1)
                );
                this.toast.show({ severity: 'success', summary: 'successfull', detail: 'Grade deleted successfully.', life: 5000 });
            }
        });
    }

    render() {
        const { board, anotherGrades } = this.state;


        return (
            <div>
                <div>
                    {anotherGrades && anotherGrades.length ? <>
                        {anotherGrades.map((gradeItem) => {
                            return <>
                                <div className="p-grid ma-w100p" >
                                    <div className="p-col-12 p-md-3 ">
                                        <p className='ma-label-s2'>Board</p>
                                        <InputText value={gradeItem.user_info['custom:board']}
                                            disabled={true}
                                            className='p-inputtext-style1 ma-w100p ' />
                                    </div>
                                    <div className="p-col-12 p-md-3 ">
                                        <p className='ma-label-s2'>Class</p>
                                        <InputText value={gradeItem.user_info['custom:grade']}
                                            disabled={true}
                                            className='p-inputtext-style1 ma-w100p' />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        {/* <i className='pi pi-trash delete-grade-btn ma-icon' onClick={() => this.showConfirmDialog(gradeItem.account_id, gradeItem.user_id)} ></i> */}
                                        <Button label="Delete" className='delete-grade-btn' onClick={() => this.showConfirmDialog(gradeItem.account_id, gradeItem.user_id)} />
                                    </div>
                                </div>
                            </>

                        })}
                    </> : <><p className='no-grades'>No additional grades available to this user.</p></>}
                </div>
                <div>
                    {!this.state.isShowAddGradeForm ?
                        <Button className="mt-4" onClick={this.showAddGradeForm}>
                            Add Grade
                        </Button> :
                        <div>
                            <div className="grid col-12 gap-4" >
                                <div className="col-3 p-md-3">
                                    <p className='ma-label-s2'>Board<span className='ma-required'>*</span></p>
                                    <InputTextB info='Enter the name of the organization registered as per the ROC' id="co12">
                                        <Dropdown
                                            value={this.state.boardId}
                                            className='w-full'
                                            options={board.map(b => { return { label: b.boardName, value: b.boardId } })}
                                            disabled
                                            placeholder="--Select Board--" />
                                    </InputTextB>
                                </div>
                                <div className="col-3 p-md-3">
                                    <p className='ma-label-s2'>Class<span className='ma-required'>*</span></p>
                                    <InputTextB info='Enter the name of the organization registered as per the ROC' id="co12">
                                        <Dropdown
                                            value={this.state.addGradeForm.classId}
                                            className='w-full'
                                            options={this.state.classes.map(b => { return { label: b.className, value: b.classId } })}
                                            onChange={(e) => this.onChangeClass1(e.value)}
                                            placeholder="--Select Class--" />
                                    </InputTextB>
                                </div>
                                <div className="col-4 p-md-4 gap-3 flex ">
                                    <Button label="Add Grade" className='add-grade-btn' onClick={this.addNewGrade} />
                                    <Button label="Cancel" className='p-button-outlined ma-mlr10 add-grade-btn' onClick={this.onGradeAddCancel} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.dialogBox.show}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ dialogBox: { show: false, msg: ' ' } }) }} />
                            <Button label="Delete" className='ma-m-lr10' onClick={this.deleteGrade} />
                        </div>)
                    }}
                    onHide={() => this.setState({ dialogBox: { show: false, msg: '' } })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'> Are you sure, </p>
                    <p className='ma-alert-msg'>You want to delete Grade of user?</p>
                </Dialog>
            </div>

        );
    }
}

const mapStatesToProps = (state) => ({
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
});

export default connect(mapStatesToProps, {
})(AdditionalGrades);
