import React from "react";
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { Toast } from 'primereact/toast';
import Authentication from '../../session';
import { Button } from 'primereact/button';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { Dialog } from 'primereact/dialog';
import BehaviourAnalytics from './behaviorAnalytics';
import { ResponsiveLine } from '@nivo/line';
import "./styles.scss";
import { baseUrlAdmin } from "../../../store/apiConstants";
import Service from "../../../services";
import { getRegex } from "../../../utile";
import withRouter from "../../lib/withRouter";
const _ = require("lodash")

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    getContentAnchorEl: null
};

const data = [
    {
        "id": "Marks",
        "color": "hsl(59, 70%, 50%)",
        "data": [
            {
                "x": "plane",
                "y": 235
            },
            {
                "x": "helicopter",
                "y": 167
            },
            {
                "x": "boat",
                "y": 132
            },
            {
                "x": "train",
                "y": 220
            },
            {
                "x": "subway",
                "y": 145
            },
            {
                "x": "bus",
                "y": 33
            },
            {
                "x": "car",
                "y": 166
            },
            {
                "x": "moto",
                "y": 124
            },
            {
                "x": "bicycle",
                "y": 152
            },
            {
                "x": "horse",
                "y": 243
            },
            {
                "x": "skateboard",
                "y": 32
            },
            {
                "x": "others",
                "y": 300
            }
        ]
    }
];

let comparativeAnalysisDummy = [
    {
        "yaxis": "Yours",
        "Your Score": 0,
        "Average Score": 0,
        "Highest Score": 0
    },
    {
        "yaxis": 'Average',
        "Your Score": 0,
        "Average Score": 0,
        "Highest Score": 0
    },
    {
        "yaxis": "Highest",
        "Your Score": 0,
        "Average Score": 0,
        "Highest Score": 0
    }
];

class IndividualCompleteAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comparativeAnalysis: [],
            comparativeAnalysisSubjectWise: [],
            subjects: [],
            timeSlots: [],
            selectedTimeSlots: [],
            examReport: {},
            timeMagementData: [],
            timeManagementSubjectWiseData: [],
            breakDownAnalysis: {},
            timeSlots: [
               
            ],
            breakDownAnalysisPieData: [],
            examReport: {},
            currrentView: 0,
            lineChartData: [],
            reportByPreviousExams: null,
            lineChartCurrentView: 'marks',
            lineChartViews: [],

        }
        this.service = new Service();

    }


    getBreakDownAnalysisData = (examReport, selectedTimeSlots) => {
        let breakDownAnalysis = { attempted: 0, perfect: 0, overtime: 0, wasted: 0 };;

        selectedTimeSlots.forEach((slot) => {
            breakDownAnalysis.perfect += Number(examReport[`${slot}_PerfectTime`] ? examReport[`${slot}_PerfectTime`] : 0);
            breakDownAnalysis.overtime += Number(examReport[`${slot}_OverTime`] ? examReport[`${slot}_OverTime`] : 0);
            breakDownAnalysis.wasted += Number(examReport[`${slot}_Wasted`] ? examReport[`${slot}_Wasted`] : 0);
            breakDownAnalysis.attempted += (Number(examReport[`${slot}_PerfectTime`] ? examReport[`${slot}_PerfectTime`] : 0) + Number(examReport[`${slot}_OverTime`] ? examReport[`${slot}_OverTime`] : 0) + Number(examReport[`${slot}_Wasted`] ? examReport[`${slot}_Wasted`] : 0));
        });

        return breakDownAnalysis;
    }




    generateAnalyticsReports(examReport, subjects, timeSlots, reportByPreviousExams) {
        // window.scroll(0, 0);
        // if(!this.props.resultDetailsInfo) {
        //   this.props.history.push('/mock-assessments');
        // }

        let comparativeAnalysis = [];
        comparativeAnalysisDummy.forEach((r, index) => {
            let record = _.cloneDeep(r);

            if (index == 0) {
                record['Your Score'] = examReport.totalMarks
            }
            if (index == 1) {
                record['Average Score'] = examReport.averageMarks
            }

            if (index == 2) {
                record['Highest Score'] = examReport.highestMarks
            }
            comparativeAnalysis.push(record);
        });

        let comparativeAnalysisSubjectWise = [];

        subjects.forEach((subject) => {
            let subjectReport = [];
            comparativeAnalysisDummy.forEach((r, index) => {
                let record = _.cloneDeep(r);
                if (index == 0) {
                    record['Your Score'] = examReport[`${subject.replace(getRegex(' '), '_')}_marks`]
                }
                if (index == 1) {
                    record['Average Score'] = examReport[`${subject.replace(getRegex(' '), '_')}_averageMarks`]
                }
                if (index == 2) {
                    record['Highest Score'] = examReport[`${subject.replace(getRegex(' '), '_')}_highestMarks`]
                }

                subjectReport.push(record);
            });

            comparativeAnalysisSubjectWise.push({ subject, report: subjectReport });

        });

        let perfect = 0;
        let overtime = 0;
        let wasted = 0;

        let timeManagementSubjectWiseData = [];
        subjects.forEach((subject) => {
            perfect += Number(examReport[`${subject.replace(getRegex(' '), '_')}_correctPerfectTime`]) + Number(examReport[`${subject.replace(getRegex(' '), '_')}_wrongPerfectTime`]);
            overtime += Number(examReport[`${subject.replace(getRegex(' '), '_')}_correctOverTime`]) + Number(examReport[`${subject.replace(getRegex(' '), '_')}_wrongOverTime`]);
            wasted = examReport[`${subject.replace(getRegex(' '), '_')}_unAttemptedWasted`];


            let obj = [];
            obj.push(subject)
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_correctPerfectTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_wrongPerfectTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_correctOverTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_wrongOverTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_unAttemptedWasted`])
            obj.push(Number(examReport[`${subject.replace(getRegex(' '), '_')}_accuracy`]).toFixed(0));
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_classAccuracy`])

            timeManagementSubjectWiseData.push({ subject, values: obj })
        });





        let timeMagementData = [{ key: 'Perfect', value: perfect }, { key: 'OverTime', value: overtime, }, { key: 'Wasted', value: wasted }, { key: 'My Accuracy', value: Number(examReport.accuracy).toFixed(0) }, { key: 'Class Accuracy', value: examReport.classAccuracy }]

        
        let breakDownAnalysis = this.getBreakDownAnalysisData(examReport, [timeSlots[0]]);

        let breakDownAnalysisPieData = [
            {
                "id": "Wasted",
                "value": breakDownAnalysis.wasted
            },
            {
                "id": "Overtime",
                "value": breakDownAnalysis.overtime
            },
            {
                "id": "Perfect",
                "value": breakDownAnalysis.perfect
            }
        ]


        let reportForLineChart = reportByPreviousExams[this.state.lineChartCurrentView];
        let lineChartData = [{ name: this.state.lineChartCurrentView, data: reportForLineChart }];

        let lineChartViews = [];

        Object.keys(reportByPreviousExams).forEach((key => {
            lineChartViews = [...lineChartViews, { view: key, viewDisplayName: this.getViewDisplayNames(key) }];

        }))


        this.setState({
            comparativeAnalysis,
            comparativeAnalysisSubjectWise,
            timeMagementData,
            timeManagementSubjectWiseData,
            breakDownAnalysis,
            breakDownAnalysisPieData,
            examReport: examReport,
            subjects: subjects,
            timeSlots: timeSlots,
            reportByPreviousExams,
            lineChartData,
            lineChartViews,
            selectedTimeSlots :[timeSlots[0]]
        });
    }


    // componentDidUpdate(prevProps) {
    //     console.log(this.props);
    //     
    //     if (!this.props.examResult) {
    //         
    //         this.props.history.push('/dashboard');
    //     }
    //     if (prevProps.examResult !== this.props.examResult && this.props.examResult && this.props.examResult.length) {
    //         
    //         this.generateAnalyticsReports(this.props.examResult[0], this.props.subjects, this.props.timeSlots);
    //     }
    // }


    componentDidMount() {
        // //     this.generateAnalyticsReports(examReport1, subjects1, timeSlots1);
        // if (!this.props.examResult) {
        //     this.props.history.push('/dashboard');
        // }
        if (this.props.completeExamReport) {

            this.generateAnalyticsReports(this.props.completeExamReport.examResult[0], this.props.completeExamReport.subjects, this.props.completeExamReport.timeSlots, this.props.completeExamReport.reportByPreviousExams);
        }
    }


    componentWillUnmount() {
    }

    goBack = () => {

        if (this.state.currrentView == 0) {
            window.history.back();
        } else {

            this.setState({
                currrentView: 0
            });
        }

        //this.props.history.push('/mock-assessments');
    }

    getViewDisplayNames(view) {
        switch (view) {
            case 'rank':
                return ' My Rank vs Past Rank'
            case 'percentile':
                return "My Percentile vs Past Percentile";
            case 'marks':
                return "My Marks vs Past Marks";
            default:
                return `${view.charAt(0).toUpperCase() + view.slice(1)} Marks vs Past ${view.charAt(0).toUpperCase() + view.slice(1)} Marks`;
        }
    }

    onLineChartViewChange = (view) => {
        let reportForLineChart = this.state.reportByPreviousExams[view];
        let lineChartData = [{ name: view, data: reportForLineChart }];

        this.setState({
            lineChartData,
            lineChartCurrentView: view
        });
    }


    onSlotChange = (timeSlot) => {

        let selectedTimeSlots = this.state.selectedTimeSlots;
        if (!selectedTimeSlots.includes(timeSlot)) {
            selectedTimeSlots = [...selectedTimeSlots, timeSlot]
        }
        else if (selectedTimeSlots.indexOf(timeSlot) >= 0) {

            selectedTimeSlots.splice(selectedTimeSlots.indexOf(timeSlot), 1);

        }

        let breakDownAnalysis = this.getBreakDownAnalysisData(this.state.examReport, selectedTimeSlots);


        let breakDownAnalysisPieData = [
            {
                "id": "Wasted",
                "value": breakDownAnalysis.wasted
            },
            {
                "id": "Overtime",
                "value": breakDownAnalysis.overtime
            },
            {
                "id": "Perfect",
                "value": breakDownAnalysis.perfect
            }
        ]

        this.setState({
            selectedTimeSlots,
            breakDownAnalysis,
            breakDownAnalysisPieData
        });
    }

    render() {
        const { selectedTimeSlots, examReport } = this.state;
        // const {totalMarks, totalNoOfQuestions, totalUserMarks, userAnswered} = this.props.resultDetailsInfo && this.props.resultDetailsInfo[0] || {};
      //  console.log('linechart', this.state);
        return (
            <>
                <Dialog
                    className="individual-c-a"
                    header={`${examReport.phoneNumber} - ${examReport.userName}`}
                    blockScroll={true}
                    draggable={false}
                    // footer={this.footer}
                    closeOnEscape={true}
                    dismissableMask={true}
                    visible={true}
                    closable={true}
                    style={{ width: '70%', height: '96vh' }}
                    onHide={this.props.onHide}>


                    <div className="report-c" id='summary'>
                        <div className="">
                            <div style={{ width: '40px', float: 'left' }}>
                                {
                                    this.state.currrentView > 0 && <Button
                                        icon="pi pi-arrow-left"
                                        className="p-button-rounded "
                                        onClick={() => this.goBack()}>
                                    </Button>
                                }
                            </div>
                            <div style={{ marginLeft: '40px' }}>
                                {
                                    this.state.currrentView == 0 && <>
                                        <div className="p-grid report-title-c" >
                                            <div className="p-col-3">
                                                <div >
                                                    <p className="report-title">Test Report</p>
                                                </div>
                                            </div>
                                            <div className="p-col-3">
                                                <div >
                                                    <p className="report-exam-name"><i className="pi pi-calendar-minus" /> {this.state.examReport.examname}</p>
                                                </div>
                                            </div>
                                            <div className="p-col-3">
                                                <div >
                                                    <p className="report-exam-name"> {moment(Number(this.state.examReport.submittedAt)).format("DD-MM-YYYY h:mm A")}</p>
                                                </div>
                                            </div>
                                            <div className="p-col-3">
                                                <div>
                                                    <p className="report-exam-name"> Status: Submitted</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="p-grid">
                                            <div className="p-col-3" >
                                                <div className="report-stats">
                                                    <div className="p-grid">
                                                        <div className="p-col-3">
                                                            <div className="stats-icon stats-icon1">
                                                                <img className="stats-img1" src="./images/Group_1.png" />
                                                            </div>
                                                        </div>
                                                        <div className="p-col-9">
                                                            <p className="stat-count stat-count1">{this.state.examReport.rank}</p>
                                                            <p className="stat-title">Rank <span>{this.state.examReport.hikeRank}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-3" >
                                                <div className="report-stats">
                                                    <div className="p-grid">
                                                        <div className="p-col-3" >
                                                            <div className="stats-icon stats-icon2">
                                                                <img className="stats-img2" src="./images/ic_trending_down_24px.png" />
                                                            </div>
                                                        </div>
                                                        <div className="p-col-9">
                                                            <p className="stat-count stat-count2">{this.state.examReport.totalmarks}</p>
                                                            <p className="stat-title">Marks <span>{this.state.examReport.hikeMarks}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-3" >
                                                <div className="report-stats">
                                                    <div className="p-grid">
                                                        <div className="p-col-3" >
                                                            <div className="stats-icon stats-icon3">
                                                                <img className="stats-img3" src="./images/Group_777.png" />
                                                            </div>
                                                        </div>
                                                        <div className="p-col-9" >
                                                            <p className="stat-count stat-count3">{this.state.examReport.highestmarks}</p>
                                                            <p className="stat-title">Highest Marks</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-3" >
                                                <div className="report-stats">
                                                    <div className="p-grid">
                                                        <div className="p-col-3" >
                                                            <div className="stats-icon stats-icon4">
                                                                <img className="stats-img1" src="./images/Group_778.png" />
                                                            </div>
                                                        </div>
                                                        <div className="p-col-9" >
                                                            <p className="stat-count stat-count4">{this.state.examReport.averagemarks}</p>
                                                            <p className="stat-title">Average Marks</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="p-grid">
                                            <div className="p-col-7" >
                                                <table className="subjects-table">
                                                    <tr>
                                                        <th>Subject</th>
                                                        <th>Rank</th>
                                                        <th>Your Score</th>
                                                        <th>Average</th>
                                                        <th>Highest</th>
                                                    </tr>

                                                    {this.state.subjects.map((subject) => {
                                                        return <>
                                                            <tr>
                                                                <td>{subject}</td>
                                                                <td> {this.state.examReport[`${subject.replace(getRegex(' '), '_')}_rank`]}</td>
                                                                <td> {this.state.examReport[`${subject.replace(getRegex(' '), '_')}_marks`]}</td>
                                                                <td> {this.state.examReport[`${subject.replace(getRegex(' '), '_')}_averageMarks`]}</td>
                                                                <td> {this.state.examReport[`${subject.replace(getRegex(' '), '_')}_highestMarks`]}</td>
                                                            </tr>
                                                        </>
                                                    })}
                                                </table>
                                            </div>
                                            <div className="p-col-5" >
                                                <div>
                                                    <button
                                                        className="btn-r btn-r1 ma-pointer"
                                                        onClick={() => { this.setState({ currrentView: 1 }) }}
                                                    >
                                                        Comparative Analysis
                                                        <i className="pi pi-arrow-right arrow-icon"></i>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        className="btn-r btn-r2 ma-mt10 ma-pointer"
                                                        onClick={() => { this.setState({ currrentView: 2 }) }}
                                                    >
                                                        Time Management
                                                        <i className="pi pi-arrow-right arrow-icon"></i>
                                                    </button>
                                                </div>
                                                <button
                                                    className="btn-r btn-r3 ma-mt10 ma-pointer"
                                                    onClick={() => { this.setState({ currrentView: 3 }) }}
                                                >
                                                    Behavior Analysis
                                                    <i className="pi pi-arrow-right arrow-icon"></i>
                                                </button>

                                            </div>
                                        </div>
                                        {this.state.lineChartData && this.state.lineChartData.length &&

                                            <div className="p-grid">
                                                {/* {console.log('testing')} */}
                                                <div className="p-col-12" >

                                                    <div className="line-chart">
                                                        <div>
                                                            <Dropdown value={this.state.lineChartCurrentView}
                                                                className='ma-w200 ma-m10 ma-left'
                                                                style={{ border: 'none' }}
                                                                options={this.state.lineChartViews}
                                                                optionLabel='viewDisplayName'
                                                                optionValue='view'
                                                                onChange={(e) => this.onLineChartViewChange(e.value)}
                                                                placeholder="--Select--" />
                                                        </div>
                                                        <div className="ma-clearFix">

                                                        </div>
                                                        <div className="line-chart-c">
                                                            {/* <ResponsiveLine
                                                                data={this.state.lineChartData}
                                                                margin={{ top: 50, right: 50, bottom: 20, left: 60 }}
                                                                xScale={{ type: 'point' }}
                                                                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                                                yFormat=" >-.2f"
                                                                axisTop={null}
                                                                axisRight={null}

                                                                curve="linear"
                                                                enableArea={true}
                                                                pointSize={10}
                                                                pointColor={{ theme: 'background' }}
                                                                pointBorderWidth={2}
                                                                pointBorderColor={{ from: 'serieColor' }}
                                                                pointLabelYOffset={-12}
                                                                useMesh={true}
                                                            /> */}

                                                            <Chart
                                                                options={{
                                                                    colors: ['#008FFB', '#00E396', '#CED4DC'],
                                                                    chart: {
                                                                        type: 'area',
                                                                        // height: 350,
                                                                        zoom: {
                                                                            enabled: false
                                                                        }
                                                                    },
                                                                    dataLabels: {
                                                                        enabled: false
                                                                    },
                                                                    stroke: {
                                                                        curve: 'smooth',
                                                                        colors: ['#008FFB', '#00E396', '#CED4DC'],
                                                                    },

                                                                    // title: {
                                                                    //     text: 'Fundamental Analysis of Stocks',
                                                                    //     align: 'left'
                                                                    // },
                                                                    // subtitle: {
                                                                    //     text: 'Price Movements',
                                                                    //     align: 'left'
                                                                    // },
                                                                    // labels: series.monthDataSeries1.dates,
                                                                    xaxis: {
                                                                        title: {
                                                                            text: 'Exams',
                                                                            style: {
                                                                                fontWeight: 600
                                                                            }
                                                                        }
                                                                    },
                                                                    yaxis: {
                                                                        //  opposite: true,
                                                                        // title: {
                                                                        //     text: '',
                                                                        //     style: {
                                                                        //         fontWeight: 600
                                                                        //     }
                                                                        // }
                                                                    },
                                                                    legend: {
                                                                        horizontalAlign: 'left'
                                                                    }
                                                                }}
                                                                series={this.state.lineChartData}

                                                                type="area"
                                                                height={300}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                {/* Multiselect exam graph is pending -sp */}

                                {
                                    this.state.currrentView == 1 && <>
                                        <div style={{ height: '0px' }}>
                                            <svg>
                                                <defs>
                                                    <linearGradient id="yours" >
                                                        <stop offset="0%" stopColor="#98FFEE" />
                                                        <stop offset="100%" stopColor="#03E9C3" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <svg>
                                                <defs>
                                                    <linearGradient id="average" >
                                                        <stop offset="0%" stopColor="#85E5FF" />
                                                        <stop offset="100%" stopColor="#00BCF2" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <svg>
                                                <defs>
                                                    <linearGradient id="highest" >
                                                        <stop offset="0%" stopColor="#82A9FF" />
                                                        <stop offset="100%" stopColor="#004EF9" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div>
                                            <div className="comparative-analysis" >
                                                <p className='comparative-title'>Comparative Analysis (Total Marks)</p>
                                                <div className="comparativ-bar">
                                                    <ResponsiveBar
                                                        enableLabel={false}
                                                        data={this.state.comparativeAnalysis}
                                                        keys={['Your Score', 'Average Score', 'Highest Score']}
                                                        indexBy="yaxis"
                                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                        layout="horizontal"
                                                        valueScale={{ type: 'linear' }}
                                                        indexScale={{ type: 'band', round: true }}
                                                        // colors={{ scheme: 'paired' }}
                                                        //colors={['url(#someGradientId)','#03E9C3', '#00BCF2', '#004EF9']}
                                                        colors={['url(#yours)', 'url(#average)', 'url(#highest)']}
                                                        borderRadius={4}
                                                        padding={0.4}
                                                        axisTop={null}
                                                        maxValue={this.state.examReport.totalexammarks}
                                                        axisRight={null}
                                                        axisBottom={{
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: '',
                                                            legendPosition: 'middle',
                                                            legendOffset: 32
                                                        }}
                                                        axisLeft={{
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: '',
                                                            legendPosition: 'middle',
                                                            legendOffset: 32
                                                        }}
                                                        labelSkipWidth={12}
                                                        labelSkipHeight={12}
                                                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                        legends={[
                                                            {
                                                                dataFrom: 'keys',
                                                                anchor: 'bottom',
                                                                direction: 'row',
                                                                justify: false,
                                                                translateX: 12,
                                                                translateY: 50,
                                                                itemsSpacing: 10,
                                                                itemWidth: 100,
                                                                itemHeight: 20,
                                                                itemDirection: 'left-to-right',
                                                                itemOpacity: 0.85,
                                                                symbolSize: 20,
                                                                effects: [
                                                                    {
                                                                        on: 'hover',
                                                                        style: {
                                                                            itemOpacity: 1
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        animate={true}
                                                        motionStiffness={90}
                                                        motionDamping={15}
                                                        enableGridX={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            {this.state.comparativeAnalysisSubjectWise.length && this.state.comparativeAnalysisSubjectWise.map((r) => {
                                                return <div className="p-col-4" >
                                                    <div className="comparative-analysis" >
                                                        <p className='comparative-title'>{r.subject}</p>
                                                        <div className="comparativ-bar">
                                                            <ResponsiveBar
                                                                enableLabel={false}
                                                                data={r.report}
                                                                keys={['Your Score', 'Average Score', 'Highest Score']}
                                                                indexBy="yaxis"
                                                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                                padding={0.5}
                                                                maxValue={100}

                                                                layout="horizontal"
                                                                valueScale={{ type: 'linear' }}
                                                                indexScale={{ type: 'band', round: true }}
                                                                // colors={{ scheme: 'paired' }}
                                                                //colors={['#03E9C3', '#00BCF2', '#004EF9']}
                                                                colors={['url(#yours)', 'url(#average)', 'url(#highest)']}
                                                                borderRadius={4}
                                                                axisTop={null}
                                                                axisRight={null}
                                                                axisBottom={{
                                                                    tickSize: 5,
                                                                    tickPadding: 5,
                                                                    tickRotation: 0,
                                                                    legend: '',
                                                                    legendPosition: 'middle',
                                                                    legendOffset: 32
                                                                }}
                                                                axisLeft={{
                                                                    tickSize: 10,
                                                                    tickPadding: 0,
                                                                    tickRotation: 0,
                                                                    legend: '',
                                                                    legendPosition: 'middle',
                                                                    legendOffset: 40
                                                                }}
                                                                labelSkipWidth={12}
                                                                labelSkipHeight={12}
                                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                legends={[
                                                                    {
                                                                        dataFrom: 'keys',
                                                                        anchor: 'bottom',
                                                                        direction: 'row',
                                                                        justify: false,
                                                                        translateX: 35,
                                                                        translateY: 52,
                                                                        itemsSpacing: 6,
                                                                        itemWidth: 100,
                                                                        itemHeight: 20,
                                                                        itemDirection: 'left-to-right',
                                                                        itemOpacity: 0.85,
                                                                        symbolSize: 20,
                                                                        effects: [
                                                                            {
                                                                                on: 'hover',
                                                                                style: {
                                                                                    itemOpacity: 1
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                ]}
                                                                animate={true}
                                                                motionStiffness={90}
                                                                motionDamping={15}

                                                                //enableGridY={false}
                                                                enableGridX={true}
                                                            />
                                                        </ div>
                                                    </ div>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    this.state.currrentView == 2 && <>
                                        <div className="p-grid">
                                            <div className="p-col-12" >
                                                <div className="time-management-c" >
                                                    <div className="p-grid">

                                                        <div className="p-col-12" >
                                                            <div className="time-management">
                                                                <p className="time-m-title">TIME MANAGEMENT</p>
                                                                <div className="sp-clearFix"></div>

                                                                <div className="time-c">
                                                                    <table className="exam-tbl">
                                                                        <tr>
                                                                            <th colspan={8}>
                                                                                <div className="t-highlights">
                                                                                    <div className="p-grid ma-no-pm">
                                                                                        {this.state.timeMagementData.length && this.state.timeMagementData.map((r, index) => {
                                                                                            return <div className="sp-2 p-md-2-5">
                                                                                                <p className="time-m-stats"> {r.key}<span className={`count count-${index}`}> {r.value}{index > 2 ? '%' : ''} </span></p>
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th rowSpan="2">
                                                                                Subject
                                                                            </th>
                                                                            <th colSpan="2">
                                                                                Perfect
                                                                            </th>
                                                                            <th colSpan="2">
                                                                                Overtime
                                                                            </th>
                                                                            <th rowSpan="2">
                                                                                Wasted
                                                                            </th>
                                                                            <th rowSpan="2">
                                                                                My Accuracy
                                                                            </th>
                                                                            <th rowSpan="2">
                                                                                Class Accuracy
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>
                                                                                Correct
                                                                            </th>
                                                                            <th>
                                                                                Incorrect
                                                                            </th>
                                                                            <th>
                                                                                Correct
                                                                            </th>
                                                                            <th>
                                                                                Incorrect
                                                                            </th>
                                                                        </tr>

                                                                        {this.state.timeManagementSubjectWiseData.length && this.state.timeManagementSubjectWiseData.map((r) => {
                                                                            return <tr>
                                                                                {r.values.map((field, index) => <td className={`${index == 6 ? (Number(r.values[index + 1]) - Number(field) ? 'sp-success' : 'sp-danger') : ''}`}>{field}{index > 5 ? '%' : ''}</td>)}
                                                                            </tr>
                                                                        })}
                                                                    </table>
                                                                </div>

                                                                <div className="sp-clearFix"></div>
                                                                <div className="p-grid" >
                                                                    <div className="p-col-4" >
                                                                        <div className="breakdown-analysis">
                                                                            <p className="b-title">Breakdown Analysis <span className='b-times'></span></p>
                                                                            <div className="p-grid" >
                                                                                <div className="p-col-6" style={{ paddingBottom: 0, paddingRight: 20 }}>
                                                                                    <p className="b-c-title">Attempted  <span className="b-count attempted-c">{this.state.breakDownAnalysis.attempted} </span></p>
                                                                                </div>
                                                                                <div className="p-col-6" style={{ paddingBottom: 0, paddingRight: 20 }}>
                                                                                    <p className="b-c-title">Overtime  <span className="b-count overtime-c">{this.state.breakDownAnalysis.overtime} </span></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="p-grid" >
                                                                                <div className="p-col-6" style={{ paddingBottom: 0, paddingRight: 20 }}>
                                                                                    <p className="b-c-title">Perfect  <span className="b-count perfect-c">{this.state.breakDownAnalysis.perfect} </span></p>
                                                                                </div>
                                                                                <div className="p-col-6" style={{ paddingBottom: 0, paddingRight: 20 }}>
                                                                                    <p className="b-c-title">Wasted  <span className="b-count wasted-c">{this.state.breakDownAnalysis.wasted} </span></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-col-4" >
                                                                        <div style={{ height: '260px' }}>
                                                                            <ResponsivePie
                                                                                data={this.state.breakDownAnalysisPieData}
                                                                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                                                innerRadius={0.5}
                                                                                padAngle={0.7}
                                                                                cornerRadius={0}
                                                                                colors={['#2C67E8', '#2c67e8', '#6BF9E2']}
                                                                                borderWidth={1}
                                                                                enableRadialLabels={false}
                                                                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                                                radialLabelsSkipAngle={10}
                                                                                radialLabelsTextColor="#333333"
                                                                                radialLabelsLinkColor={{ from: 'color' }}
                                                                                sliceLabelsSkipAngle={10}
                                                                                sliceLabelsTextColor="#333333"
                                                                                enableSliceLabels={false}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-col-4" >
                                                                        <div className="time-slot-c">
                                                                            {this.state.timeSlots.map((timeSlot) => {
                                                                                return <div className="time-slot-w"> <p onClick={() => { this.onSlotChange(timeSlot) }} className={`time-slot ${selectedTimeSlots.includes(timeSlot) ? 't-b-active' : ''}`}> <span className={`t-btn ${selectedTimeSlots.includes(timeSlot) ? 't-active' : ''}`}> </span>  {`${timeSlot} mins`} </p> </div>
                                                                            })}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ div>
                                            </div>
                                        </div>

                                    </>
                                }
                                {
                                    this.state.currrentView == 3 && <BehaviourAnalytics userId={examReport.userId} examId={examReport.examId} />
                                }
                            </div>

                        </div>

                    </div >

                </Dialog >
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        );
    }
}

/////let dd = require('./dummy2.json')
// const mapStateToProps = state => {
//     return dd;
//     // return {
//     //     examResult: state.examAnalytics.examResult,
//     //     subjects: state.examAnalytics.subjects,
//     //     timeSlots: state.examAnalytics.timeSlots,
//     //     reportByPreviousExams: state.examAnalytics.reportByPreviousExams
//     // }
// }

export default withRouter(Authentication(IndividualCompleteAnalysis));

