import React, { Component } from 'react';
//import BootStrapHeader from './../home/bootStrapNavBar';
import './styles.scss'
import CreateDiscussion from './allDiscussions/createDiscussion';
import AllDiscussions from './allDiscussions';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import LeaderBoard from './leaderBoard';
import Notifications from './notifications';
import { connect } from 'react-redux';
import Authentication from '../session';
// import Header from '../header';
import PERM_CONSTANTS from './permissionConstants';
import { isUserAuthorized } from '../../utile/isAuthorized';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import withRouter from '../lib/withRouter';


const items = [
    { label: 'Academics' },
    { label: 'Discussion Board', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


class DiscussionBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 1
        }
    }


    changeTab = (tab) => {

        this.setState({
            currentTab: tab
        });
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className=''>Discussion Board</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }


    render() {

        return (
            <>
                    <div className="">
                        <div className="-mt-4">
                            <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className="grid ma-main branch-master" style={{ marginTop: '0px' }}>
                        <div className='col-12'>
                            <div >
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className="discussion-board">

                                            <div className="grid disc-tabs-header">
                                                <div className='col-3'>
                                                    <h4 className="m-3">Disusssion Boards</h4>
                                                </div>
                                                <div className='col-9 ma-no-pm'>
                                                    <ul className="discussion-tabs" >
                                                        {/* <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_GENERAL_FEED} > */}
                                                        <li onClick={() => { this.changeTab(1) }} className={`m-0 ma-pointer ${this.state.currentTab == 1 ? 'tab-active-a' : ''}`}> Feed</li>
                                                        {/* </Authorizer> */}
                                                        <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_GENERAL_QOD} >
                                                            <li onClick={() => { this.changeTab(2) }} className={`ma-pointer ${this.state.currentTab == 2 ? 'tab-active-a' : ''}`}>QOD</li>
                                                        </Authorizer>
                                                        <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_GENERAL_MY_DISCUSSION} >
                                                            <li onClick={() => { this.changeTab(3) }} className={`ma-pointer ${this.state.currentTab == 3 ? 'tab-active-a' : ''}`}>My Discussion</li>
                                                        </Authorizer>
                                                        <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_GENERAL_MY_ANSWERS} >
                                                            <li onClick={() => { this.changeTab(4) }} className={`ma-pointer ${this.state.currentTab == 4 ? 'tab-active-a' : ''}`}>My Answers</li>
                                                        </Authorizer>
                                                        <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_GENERAL_FLAGGED_DISCUSSIONS} >
                                                            <li onClick={() => { this.changeTab(7) }} className={`ma-pointer ${this.state.currentTab == 7 ? 'tab-active-a' : ''}`}>Flagged Discussions</li>
                                                        </Authorizer>
                                                        <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_GENERAL_PINNED_DISCUSSIONS} >
                                                            <li onClick={() => { this.changeTab(8) }} className={`ma-pointer ${this.state.currentTab == 8 ? 'tab-active-a' : ''}`}>Pinned Discussions</li>
                                                        </Authorizer>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="ma-clearFix"></div>
                                            <div className="grid posts-container mt-3">
                                                <div className="col-8 ma-no-pm ma-pr30">
                                                    <AllDiscussions
                                                        currentTab={this.state.currentTab}
                                                        isQuestionsOfTheDayTab={this.state.currentTab == 2 ? true : false}
                                                        myQuestions={this.state.currentTab == 3 ? true : false}
                                                        answeredAndCommentedPosts={this.state.currentTab == 4 ? true : false}
                                                        flagged={this.state.currentTab == 7 ? true : false}
                                                        pinned={this.state.currentTab == 8 ? true : false}

                                                    />

                                                    {/* {
                                            this.state.currentTab == 5 && <AllDiscussions currentTab={this.state.currentTab} favourites={true} />
                                        }
                                        {
                                            this.state.currentTab == 6 && <LeaderBoard currentTab={this.state.currentTab} />
                                        } */}

                                                </div>
                                                <div className='p-col-4 '>
                                                    <Notifications />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            {/* </Sidebar> */}

                        </div>
                    </div>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {

    }
}


export default connect(mapStateToProps, {})(Authentication(withRouter(DiscussionBoard)));