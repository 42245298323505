import React from 'react'
import Chart from 'react-apexcharts';
function scatterChart() {
    const options = {
        chart: {
          type: 'scatter',
        },
        xaxis: {
          categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4'],
        },
        colors: ['#bf86db'],
      };
    
      const series = [
        {
          name: 'Sample Data',
          data: [
            { x: 'Category 1', y: 65 },
            { x: 'Category 2', y: 59 },
            { x: 'Category 3', y: 80 },
            { x: 'Category 4', y: 81 },
          ],
        },
      ];

    const chartContainerStyle = {

    };
    return (
        <>

            <div style={chartContainerStyle}>
                <Chart options={options} series={series} type="scatter" />
            </div>
        </>
    )
}

export default scatterChart