import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';

class BranchToSectionDDPreFill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardId: null,
            classId: null,
            groupId: null,
            branchId: null,
            sectionId: null,
            boards: [],
            classes: [],
            curriculumInfo: {},
        };
        this.service = new Service()
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate() {
        
        if (this.state.branchId == null && this.props.boards && this.props.boards?.length > 0 && this.props.branchData && this.props.branchData.length >0) {
            let branchId = this.props.branchData?.find((branchd) => branchd.key ===this.props.branchData[0].key);
            const selectedBoard = this.props.boards.filter(board => board.assignedBranches.includes(branchId.key));
            
            if (selectedBoard?.length > 0) {

                const selectedClass = selectedBoard[0]?.classes[0];

                if (selectedClass?.branches?.length > 0) {
                    const selectedbranchName = selectedClass.branches.find((branchd) => branchd.branchId == branchId.key)?.branchName;

                    const sections = selectedClass.sections?.filter((section) => section.branchId == branchId.key) || [];

                    const selectedsectionName = sections.length > 0 ? sections.find((sectiond) => sectiond.sectionId == sections[0].sectionId)?.sectionName : '';

                    this.setState({
                        classes: selectedBoard[0]?.classes,
                        boards:selectedBoard,
                        boardId: selectedBoard[0]?.boardId,
                        selectedBoardName: selectedBoard[0].boardName,
                        classId: selectedClass?.classId,
                        selectedClass: selectedClass,
                        groupId: selectedClass.groupId,
                        branches: selectedClass.branches,
                        selectedClassName: selectedClass.className,
                        branchId: branchId.key,
                        selectedbranchName: selectedbranchName,
                        sections: sections,
                        sectionId: sections.length > 0 ? sections[0]?.sectionId : '',
                        selectedsectionName: selectedsectionName,
                    }, () => this.setDropdownsData());
                }
            }
        }
     


    }

    getData = () => {
        this.props.getBranchesLatest();
        this.props.getBoardsData(true)
    }

    onChangeBranch = (branchId) => {
        this.setState({
            boards: [], classes: [], sections: [],
        });
        let selectedbranchName = this.props.branchData?.find((branchd) => branchd.key == branchId).name;
        let filteredBoards = this.props.boards.filter(board => board.assignedBranches.includes(branchId));
        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            boards: filteredBoards,
            classes: [],
            boardId: '',
            selectedBoardName: '',
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: '',
            sections: [],
            sectionId: '',
            selectedsectionName: '',
        });
    };

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], sections: [],
        });
        const selectedBoard = this.state.boards && this.props.boards.find((board) => board.boardId === boardId);

        this.setState({
            classes: selectedBoard.classes,
            boardId,
            selectedBoardName: selectedBoard.boardName,
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: '',
            sections: [],
            sectionId: '',
            selectedsectionName: '',

        }, () => this.setDropdownsData());
    };

    onChangeClass = (classId) => {
        let selectedClass = this.state.classes?.find((classd) => classd.classId == classId)

        let filteredSections = selectedClass?.sections.filter((sec) => sec.branchId == this.state.branchId)

        this.setState({
            classId: classId,
            selectedClass: selectedClass,
            branches: selectedClass.branches,
            selectedClassName: selectedClass.className,
            sections: filteredSections,
            sectionId: '',
            selectedsectionName: '',
        }, () => this.setDropdownsData());
    };

    onChangeSection = (sectionId) => {
        let selectedsectionName = this.state.sections?.find((sectiond) => sectiond.sectionId == sectionId).sectionName;

        this.setState({
            sectionId: sectionId,
            selectedsectionName: selectedsectionName,
        }, () => this.setDropdownsData());
    };

    setDropdownsData = () => {
        let {
            boardId,
            classId,
            branchId,
            sectionId,
            selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        } = this.state
        let returnData = {
            boardId, classId, branchId, sectionId, selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        }
        this.props.setValues(returnData)
    }



    render() {
        return (
            <div>
                <div >
                    <div className='grid m-2'>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props.branchData}
                                value={this.state.branchId} onChange={(e) => this.onChangeBranch(e.value)} optionLabel="name"
                                optionValue="key" />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.state.boards} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                optionValue="boardId" />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Grade'} icon={<GradesIcon />} placeholder={'Select Grade'} options={this.state?.classes}
                                value={this.state.classId} onChange={(e) => this.onChangeClass(e.value)} optionLabel="className"
                                optionValue="classId" />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Section'} icon={<GradesIcon />} placeholder={'Select Section'} options={this.state?.sections}
                                value={this.state.sectionId} onChange={(e) => this.onChangeSection(e.value)} optionLabel="sectionName"
                                optionValue="sectionId" />
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    // boards: state.boardsData.boards,
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(BranchToSectionDDPreFill);