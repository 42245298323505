import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import React, { useState } from 'react'

const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "EnrollMent Number", value: "enrollmentNumber" }
]

const ClassetStudentSearch = ({ handleTypeValue, handleSearchValue, selectedValue }) => {
    const [searchType, setSearchType] = useState(searchTypeOptions[0].value);
    const [searchValue, setSearchValue] = useState("");
    const [focused, setFocused] = useState(false);


    const handleFocus = () => {
        if (selectedValue == 2) {
            setFocused(true);
            setSearchValue("")
        }

    };

    const handleBlur = () => {
        if (selectedValue == 2) {
            setFocused(false);
            setSearchValue("")
        }

    };

    return (
        <div className="flex">
            {/* First component with ClassetDropdown */}
            <div style={{ width: "660px", height: "48px" }}
                className={classNames('p-inputgroup', 'custom-input-group', `border-300 shadow-2 border-round-lg flex justify-content-center align-items-center`, { 'custom-input-focused': focused })}
                onClick={handleFocus}
            >
                <Dropdown
                    optionLabel='label'
                    style={{ width: "171px", height: "48px" }}
                    optionValue='value'
                    className='pr-2 text-xl pl-2 no-border border-none pt-2'
                    value={searchType}
                    onFocus={handleFocus}
                    disabled={selectedValue === 1 ? true : false}
                    onBlur={handleBlur}
                    options={searchTypeOptions}
                    onChange={(e) => { handleTypeValue(e.target.value); setSearchType(e.target.value) }}
                    placeholder={(
                        <div className="flex justify-content-start  align-items-center">
                            <span className='text-xl'> Select Type</span>
                        </div>
                    )}
                />
                <Divider layout="vertical" />

                <span className=" p-inputgroup-addon pl-3">
                    <i className="pi pi-search pt-2 px-3 text-2xl "></i>
                </span>
                <InputText
                    className='text-lg  no-border border-none'
                    style={{ width: "489px", height: "48px" }}
                    value={searchValue}
                    onFocus={handleFocus}
                    disabled={selectedValue === 1 ? true : false}
                    onBlur={handleBlur}
                    onChange={(e) => { handleSearchValue(e.target.value); setSearchValue(e.target.value); }}
                    placeholder="Search Student Name/Enrollment Number" />
            </div>
        </div>
    )
}

export default ClassetStudentSearch