import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import SvgViewer from '../../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import IndividualUsageAnalytics from '../../analytics/usageAnalytics/individualUsageAnalytics';
import { AccessIcon, AddIcon2, AnalyticsIcon2, ClassetEditIcon, WarningIcon } from '../../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../../internationalization';
import withRouter from '../../lib/withRouter';
import CreateRole from './createRole';
import '../styles.scss'
import { selectPermissions } from '../../../store/selectors/authorize';
import { getPermissionList, getSelectedModules } from '../../../store/actions';

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const dummyData = [
    {
        role_name: "Account",
        description: "Account",
        create_on: 25,
        create_on: "30/04/2020",
        modified_on: "30/04/2020",
        total_users: 20
    },
    {
        role_name: "Teacher",
        description: "Teacher",
        create_on: 25,
        create_on: "30/04/2020",
        modified_on: "30/04/2020",
        total_users: 20
    },
    {
        role_name: "Hr",
        description: "Hr",
        create_on: 25,
        create_on: "30/04/2020",
        modified_on: "30/04/2020",
        total_users: 20
    },
]


class RoleManagement extends Component {

    constructor(props) {

        super(props);

        console.log(this.props.isAdd, "Enter from", this.props)
        this.state = {

            isLoading: false,
            users: dummyData,
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            currentTab: this.props.currentTab,
            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],
            showingReports: false,
            series: [
                { name: 'Max marks', data: [44, 55, 57, 56, 61, 58] },
                { name: 'Sub.high score', data: [76, 85, 99, 98, 87, 105] },
                { name: 'Obtained marks', data: [35, 41, 36, 26, 45, 48] }
            ],
            options: {
                xaxis: {
                    categories: ['TELUGU', 'HINDI', 'ENGLISH', 'MATHS', 'SCIENCE', 'SOCIAL'],
                },
                yaxis: {
                    title: { text: 'Marks' }
                },
                chart: {
                    toolbar: {
                        show: false
                    }
                }
            },
            //selectedModule:defaultModule,
            isEdit: true,
            //matchedPermissionGroups : []


        }
        this.service = new Service();
    }


    rightToolbarTemplate = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (
            <React.Fragment>

                <h4 className='ma-tab-title'>{I18Next('roleManagement', langType).COMMON_KEY}</h4>

            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }



    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }

    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    showUserInfo = (user) => {
        this.setState({
            selectedUser: user,
            isShowUserInfo: true
        })
    }



    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getRoles);
    }

    startGlobalFilter = () => {

        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isUserNameSearch, username, boardId, classId, groupId } = filterData;

            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }

            }



            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }




    showUserAnalytics = (selectedUser) => {

        this.setState({
            selectedUser,
            isShowAnalytics: true
        });
    }


    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value === true) {
            data.status = 'Active'
        } else {
            data.status = 'InActive'
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this User status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            // editUserData: data,
            editUser: data
        });

    }


    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        let user = this.state.editUser;

        this.setState({
            isLoading: true
        });

        const url = `${baseUrlAdmin}/user/changestatus?_id=${user._id}&status=${user.status}`;
        this.service.put(url, {}, true).then((res) => {
            if (res && res.status && res.res.success) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.filterUsersData();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }



    onCreateOrEditSuccess = () => {
        if (this.state.editUser) {

            this.setState({ isCreateUser: false, editUser: false }, () => {
                this.filterUsersData();
            })


        } else {
            this.setState({ isCreateUser: false, editUser: false })
        }


    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id === c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }


    editTemplate = (rowData) => {
        return (<>
            {/* <div className="p-d-flex ma-mlr10 user-edit-t"> */}
            <div className="">
                <Button label="Reset Password" className='p-button-outlined' onClick={() => { this.resetPassword(rowData) }} />
            </div>
            {/* </div> */}
        </>
        );
    }

    resetPassword = (data) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want reset the password, it can't be reversed once change the pasword`
            },
            // editUserData: data,
            editUser: data
        });

    }

    onConfirmReset = () => {



        this.setState({
            warningDialog2: warningDailogInit,
            isLoading: true
        });

        const url = `${baseUrlAdmin}/user/resetPassword?_id=${this.state.editUser._id}`;
        this.service.put(url, {}, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    isShowResetSuccess: true,
                    newPassword: res.res.password,
                    editUser: null
                })
                //   this.toast.show({ severity: 'success', summary: 'Success', detail: 'Password reset and sent to mail successfully.', life: 3000 });
            } else {
                this.setState({
                    isLoading: false,
                    editUser: null
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false,
                editUser: null
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }
    copyToClipboard = (txt) => {
        navigator.clipboard.writeText(txt);
        this.toast.show({ severity: 'success', summary: 'Copied', detail: 'Password copied to clipboard', life: 3000 });
    }

    reports = (rowData) => {
        this.setState({
            showingReports: true,
            reportsData: rowData
        })
    }

    onClickTm = () => {
        console.log("user-bulkupload")
        this.props.navigate('/user-bulkupload')
    }

    addNewRole = () => {

        this.props.showEditRoleDialog(this.state.editUser, null, false)
        this.props.onTabChange(1)

    }

    renderFilteredPermissions(editUser) {
        console.log(editUser, "editUser", this.props.permissionsMasterList)
        let matchedPermissionGroups = []
        this.props.permissionsMasterList.length > 0 && this.props.permissionsMasterList.forEach(permissionGroup => {

            let permissionKeys = Object.keys(editUser && editUser?.permissions);


            if (permissionKeys.includes(permissionGroup.id)) {

                // let updatedPermissionGroup = {
                //     ...permissionGroup,

                //     // "pk": editUser.pk,
                //     // "sk": editUser.sk,
                //     // "status": editUser.status,
                //     // "description": editUser.description,
                //     // "roleName": editUser.roleName
                // };
                matchedPermissionGroups.push(permissionGroup);
            }

            console.log(matchedPermissionGroups, "matchedPermissionGroups")

        });
        return matchedPermissionGroups;
    }
    onEditUserClick = (editUser) => {
        console.log(editUser, "editUsereditUser")
        let matchedPermissionGroups = this.renderFilteredPermissions(editUser)
        this.props.showEditRoleDialog(editUser, matchedPermissionGroups, true)
        this.props.onTabChange(1)




    }
    componentDidMount() {
        this.props.showEditRoleDialog(this.state.editUser, null, false)
        this.props.getApiCall(false)


    }

    formatDate = (rowData) => {
        return moment(rowData["field"]).format('MM/DD/YYYY');
    };

    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>Role List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.USER_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.USER_VIEWCOLUMNS} >
                            <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.USER_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        return (
            <>

                <div className="text-right mt-4 mb-2">
                    <Button icon={'pi pi-plus-circle text-lg mt-2 p-2'} style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={this.addNewRole}>
                        <p className=' text-xl ml-2 '>Add New role</p>
                    </Button>
                </div>


                <div className="card datatable-crud-demo ">
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        className="datatable-cell"
                        columnResizeMode="expand"
                        value={this.props.rolesData}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        //showGridlines
                        onSort={this.props.onSort}
                        onPage={this.props.onPage}
                        first={this.props.lazyParams.first}
                        rows={this.props.lazyParams.rows}
                        totalRecords={this.props.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    >

                        <Column alignHeader={"center"} sortable bodyClassName='text-center' headerClassName="datatable-header" header="S.No" body={(rowData, index) => {
                            return (
                                <>
                                    <p>{(this.props.lazyParams.page * this.props.lazyParams.rows) + rowData.slNo}</p>
                                </>
                            )
                        }} />

                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' sortField='roleName' filterField="role_name" field="roleName" header=" Name of the Role" />
                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' filterField="description" field="description" header="Role Description" />
                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' filterField="create_on" field="createdAt" header="Created On" body={this.formatDate} />



                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' filterField="modified_on" field="updatedAt" header="Modified On" body={this.formatDate} />
                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' filterField="total_users" field="adminUserCount" header="Total Users" />
                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' body={(rowData) => {
                            return (
                                <div className="">
                                    {/* <span className="ma-status-text">{rowData.status || 'Active'} </span> */}
                                    {/* <Authorizer permId={PERMISSIONS.USER_CHANGE_STATUS} > */}
                                    <InputSwitch onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.status === 'Active' || rowData.status === undefined} />
                                    {/* </Authorizer> */}
                                </div>
                            )
                        }} field="status" header="Status" />
                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' header="Actions" body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="">
                                    {/* <Authorizer permId={PERMISSIONS.USER_EDITUSER} > */}
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.awl${rowIndex}`} />
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={`ma-ml10 awl${rowIndex}`} onClick={() => { this.onEditUserClick(rowData) }}>
                                        {/* <SvgViewer className='ma-icon-svg ma-pointer ml-1' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        <ClassetEditIcon height={25} width={25} />

                                    </span>
                                    {/* </Authorizer> */}

                                </div>
                            </>
                            );
                        }} ></Column>



                        <Column style={{ width: "15rem", height: "4rem" }} alignHeader={"center"} sortable headerClassName="datatable-header" bodyClassName='text-center' header="Access" body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="">
                                    {/* <Authorizer permId={PERMISSIONS.USER_EDITUSER} > */}
                                    <span data-pr-tooltip="Access" data-pr-position="" className={`ma-ml10 awl${rowIndex}`} >
                                        {/* <SvgViewer className='ma-icon-svg ma-pointer ml-1' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        <AccessIcon height={25} width={25} />

                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.uy${rowIndex}`} />
                                    {/* </Authorizer> */}

                                </div>
                            </>
                            );
                        }} ></Column>

                    </DataTable>
                </div>





                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}



const mapStatesToProps = (state) => ({


});

const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStatesToProps, mapDispatchToProps)(Authentication(withRouter(RoleManagement)));