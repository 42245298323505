import React, { Component } from 'react'
import { connect } from 'react-redux';

//import { withRouter } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import Service from './../../services';
// import InputTextB from './../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import Authentication from '../../session';
import InputTextB from '../../customComponents/inputTextB';
import withRouter from '../../lib/withRouter';
import { I18Next } from '../../../internationalization';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { CalenderCheckIcon, ClipIcon, MailIcon, PaperClipIcon } from '../../svgIcons';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { getBoardsData, selectBoard } from '../../../store/actions';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { trimObj } from '../../../utile';
import { cloneDeep } from 'lodash';
import { constrainPoint } from '@fullcalendar/core';
import { CodeStarNotifications } from 'aws-sdk';


// import withRouter from '../lib/withRouter';
// import { I18Next } from '../../internationalization';

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }
const createAdmissionEnrollement = require('./enquiryCreate.json');


class AdmissionEnquiry extends Component {

    constructor(props) {

        super(props);
        console.log(this.props,"this.props")
        this.state = {
            enrollAdmission: this.props?. enrolladmissionData,
            formValidations:  this.props?.formValidations,
            isLoading: false,
            dateRange: (this.props.enrolladmissionData?.fromDate && this.props.enrolladmissionData?.toDate) ? [new Date(this.props.enrolladmissionData?.fromDate), new Date(this.props.enrolladmissionData?.toDate)] : [],
            gradesDropDownOptions: [],
            boardsData: this.props.enrolladmissionData?._id ? this.props.enrolladmissionData?.boards : [],
            gradesData: this.props.enrolladmissionData?._id ? this.props.enrolladmissionData?.grades : [],
            enqformLoad: this.props?.enqformLoad,
            initialForm:this.props?.initialForm,

        }


        this.service = new Service();
    }


    componentDidMount() {

         if (this.props.enrolladmissionData?._id && this.props?.boards && this.props?.boards.length >0) {
            this.formGradesDataForEdit(this.state?.boardsData, this.state?.gradesData)
        }



        this.props.getData(this.state.enrollAdmission);
        
       

    }


    formGradesDataForEdit(boards, grades) {




if (this.props?.boards?.length===0) {
    this.setState({
        gradesDropDownOptions: [],
        gradesData: []})
}

        if (boards && boards.length > 0 && grades && grades.length > 0 && this.props?.boards && this.props?.boards.length >0) {

            const _classes = [];

            const selectedBoards = this.props.boards && this.props.boards.filter((board) => (boards?.includes(board.boardId)));
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards.map(selectedBoard => {
                    if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {


                        selectedBoard.classes.filter((classIds) => (grades?.includes(classIds.classId)));
                        selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                    }
                })


                console.log(_classes,"bordsDataclass")
                this.setState({
                    gradesDropDownOptions: _classes,
                    boardsData: boards,
                    gradesData: _classes
                        .filter(c => grades?.includes(c.value))  // Filter the classes to get only the matching ones
                        .map(c => c.value) 


                }, () => {

                    //console.log(this.state.gradesDropDownOptions, "bordsDataclass after setState");

                    const updatedEnrollAdmission = {
                        ...this.state.enrollAdmission,
                        boards: this.state.boardsData,
                        grades: this.state.gradesData
                    };





                    this.props.getData(updatedEnrollAdmission)

                }

                );
            }
        } else {
 
            const _classes = []
            const selectedBoards = this.props.boards;
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards.map(selectedBoard => {
                    if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                        selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                    }
                })


                this.setState({
                    boardsData: boards,
                    gradesDropDownOptions:_classes,
                    gradesData: []



                }, () => {

                    const updatedEnrollAdmission = {
                        ...this.state.enrollAdmission,
                        boards: this.state.boardsData,
                        grades: this.state.gradesData
                    };




                    this.props.getData(updatedEnrollAdmission)

                }

                );
            }
        }



    }
    


    componentDidUpdate(prevProps, prevState) {

       
        if (prevState.boardsData?.length !== this.state.boardsData?.length || prevState.gradesData?.length !== this.state.gradesData?.length ) {
           
            this.props.getFormLoad(false);
           
          
        } 
       
        if (prevProps.boards.length !== this.props.boards.length && this.props.enrolladmissionData?._id) {
            this.formGradesDataForEdit(this.state?.boardsData, this.state?.gradesData)
        }

    }




    onChangeBoard = (boardIds, e) => {

        if (boardIds && boardIds.length > 0) {
            const _classes = [];


            const selectedBoards = this.props.boards && this.props.boards.filter((board) => (boardIds.includes(board.boardId)));
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards.map(selectedBoard => {
                    if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                        selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                    }
                })



                this.setState({
                    boardsData: boardIds,
                    gradesDropDownOptions: _classes,
                    gradesData: _classes
                        .filter(c => this.state.gradesData?.includes(c.value))  // Filter the classes to get only the matching ones
                        .map(c => c.value)


                }, () => {

                  

                    const updatedEnrollAdmission = {
                        ...this.state.enrollAdmission,
                        boards: this.state.boardsData,
                        grades: this.state.gradesData
                    };

               
                    this.props.getData(updatedEnrollAdmission)

                }

                );

            }
        }



        else {

            const _classes = []
            const selectedBoards = this.props.boards;
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards.map(selectedBoard => {
                    if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                        selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                    }
                })


                this.setState({
                    boardsData: boardIds,
                    gradesDropDownOptions: _classes,

                    gradesData: []


                }, () => {
                    const updatedEnrollAdmission = {
                        ...this.state.enrollAdmission,
                        boards: this.state.boardsData,
                        grades: this.state.gradesData
                    };

                    //console.log(this.state.boardsData,"cahagagagagagg",this.formFields.formValidations)
                   // this.props.getFormLoad(!this.state.enqformLoad)
                  
                    this.props.getData(updatedEnrollAdmission)

                }

                );
            }

        }


    }


    onChangeClass = (classIds) => {


        this.setState({
            gradesData: classIds
        }, () => {

            const updatedEnrollAdmission = {
                ...this.state.enrollAdmission,
                boards: this.state.boardsData,
                grades: this.state.gradesData
            };


            this.props.getData(updatedEnrollAdmission)



        });


    };



    onDateChange = (classIds) => {


        this.setState({
            gradesData: classIds
        }, () => {

            const updatedEnrollAdmission = {
                ...this.state.enrollAdmission,
                boards: this.state.boardsData,
                grades: this.state.gradesData
            };

            this.props.getData(updatedEnrollAdmission)



        });


    };

    onTextChange = (e, field) => {
        console.log(e, "ee")
        let data = JSON.parse(JSON.stringify(this.state.enrollAdmission));
        let formValidations = this.state.formValidations;
        data[field] = e.checked;
        let fieldInfo = createAdmissionEnrollement.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            enrollAdmission: data,
            formValidations
        }, () => { this.props.getData(this.state.enrollAdmission); });
    }

    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.enrollAdmission));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = createAdmissionEnrollement.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            enrollAdmission: data,
            formValidations
        }, () => { this.props.getData(this.state.enrollAdmission, this.state.formValidations); });
    }


    render() {
        const { boardsData, gradesData, formValidations, dateRange, enrollAdmission,gradesDropDownOptions } = this.state
       

        

        return (

            <div className='grid ml-4'>
                <div className=" grid col-12 -ml-6" >
                    <div className=" xl:col-4 lg:col-6 md:col-12 flex justify-content-start align-content-center">
                        <div className='flex'>
                            <InputSwitch
                                className=''
                                onChange={(e) => this.onToggleChange(e, 'onlineAdmission')}

                                checked={enrollAdmission?.onlineAdmission} />

                            <div className='ml-3 -mt-2'>
                                <p className="toggleSwitchHead w-auto">Enable Online Admission</p>
                                <p className="toggleSwitchPara">Enabling this will allow access to application form for Parents</p>
                            </div>
                        </div>
                        <div className='-mt-1'>
                            <ClipIcon width={16} height={16} color={'black'} />
                        </div>

                    </div>
                    <div className="xl:col-4 lg:col-6 md:col-12 -mt-3 lg:flex justify-content-center align-content-center">
                        <div>
                            <p className="dateTextLabel">From & To Dates<span className='ma-required'>*</span></p>
                            <Calendar
                                value={dateRange}
                                onChange={(e) => {
                                    this.setState({ dateRange: e.value }, () => {


                                        const updatedEnrollAdmission = {
                                            ...this.state.enrollAdmission,
                                            fromDate: this.state?.dateRange[0],
                                            toDate: this.state?.dateRange[1]
                                        };
                                        this.props.getData(updatedEnrollAdmission)

                                    });
                                }}


                                // className='fieldHeight shadow-2 dateTextLabel'
                                selectionMode="range"
                                inputClassName="fieldHeight shadow-2 dateTextLabel pl-4 border-round-medium"
                                style={{ width: "343px", height: "48px" }}
                                placeholder='From & To Dates'
                                readOnlyInput
                                hideOnRangeSelection />
                            { this.state.dateRange?.length === 0 && (
                                <p className="p-error font-semibold">From & To Dates Required</p>
                            )}

                        </div>

                    </div>
                </div>
                <div style={{ width: "100%" }} className='grid mt-5 lg:col-12 md:col-12 xl:col-12 selectBoardsContainer flex pr-8 flex-column justify-content-start align-content-center mt-1'>
                    <div className='col-12 flex justify-content-start align-content-center ml-3'>
                        <p className='selectBoardsHeading w-auto'>Select Boards & Grades</p>
                    </div>


                    <div className='col-12 flex justify-content-start align-content-center'>
                        <div className='mx-8 lg:mx-5 md:mx-5 '>
                            <ClassetMultiSelect
                                value={boardsData ? this.props?.boards?.filter(item => boardsData?.includes(item.boardId)).map(item => item?.boardId) : []}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                options={this.props?.boards }
                                label="Boards"
                                optionLabel='boardName'
                                required={true}
                                optionValue='boardId'
                                display="chip"
                                className='dialogCardInFiel w-21rem text-lg shadow-2 pl-2'
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center mt-1">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <span className='text-xl ml-4 body-medium'>Select Boards</span>
                                    </div>
                                )} />



                            {formValidations && !formValidations.fields['boards'].isValid && <p className="p-error font-semibold">{formValidations.fields['boards'].errorMsg}</p>}

                        </div>
                        <div className='ml-3'>
                            <ClassetMultiSelect
                                value={gradesData ?  gradesDropDownOptions?.filter(item => gradesData?.includes(item.value)).map(item => item?.value): []}
                                onChange={(e) => this.onChangeClass(e.value)}
                                options={this.state?.gradesDropDownOptions}
                                label="Board Name-Grades"
                                optionLabel="label"
                                required={true}
                                className='dialogCardInFiel w-21rem text-lg shadow-2 pl-2'
                                display="chip"
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center mt-1">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <span className='text-xl ml-4 body-medium'>Select Grades</span>
                                    </div>
                                )}



                            />

                            {formValidations && !formValidations.fields['grades'].isValid && <p className="p-error font-semibold">{formValidations.fields['grades'].errorMsg}</p>}

                        </div>
                    </div>
                </div>


                <div className='-ml-2 mt-5'>
                    <h4 className='validationsHeading w-auto my-5'>Validations For Automation:</h4>
                    <div className='flex my-3'>
                        <Checkbox variant="filled" value={enrollAdmission?.ageCriteria} checked={enrollAdmission?.ageCriteria} onChange={(e) => this.onTextChange(e, 'ageCriteria')}
                        />
                        <p className='validationsText w-auto ml-3'>Age Criteria Required</p>
                    </div>
                    <div className='flex my-3'>
                        <Checkbox variant="filled" value={enrollAdmission?.moveIfAppFee}
                            checked={enrollAdmission?.moveIfAppFee} onChange={(e) => this.onTextChange(e, 'moveIfAppFee')}
                        />
                        <p className='validationsText w-auto ml-3'>Move student to subsequent steps only if application / registration fee is paid</p>
                    </div>
                    <div className='flex my-3'>
                        <Checkbox variant="filled" value={enrollAdmission?.moveIfDocsSub}
                            checked={enrollAdmission?.moveIfDocsSub} onChange={(e) => this.onTextChange(e, 'moveIfDocsSub')}
                        />
                        <p className='validationsText w-auto ml-3'>Move student to subsequent steps only if application / registration documents are submitted</p>
                    </div>
                    <div className='flex my-3'>
                        <Checkbox variant="filled" value={enrollAdmission?.moveIfEvalMarks}
                            checked={enrollAdmission?.moveIfEvalMarks} onChange={(e) => this.onTextChange(e, 'moveIfEvalMarks')}
                        />
                        <p className='validationsText w-auto ml-3'>Move student to Confirmation step only if evaluation marks are entered</p>
                    </div>
                    <div className='flex my-3'>
                        <Checkbox variant="filled" value={enrollAdmission?.moveIfEvalCriteria}
                            checked={enrollAdmission?.moveIfEvalCriteria}

                            onChange={(e) => this.onTextChange(e, 'moveIfEvalCriteria')}

                        />
                        <p className='validationsText w-auto ml-3'>Move student to Confirmation step only if student achieves evaluation criteria</p>
                    </div>
                    <div className='flex my-3'>
                        <Checkbox variant="filled" value={enrollAdmission?.confirmAddIfFeeCat}
                            checked={enrollAdmission?.confirmAddIfFeeCat}
                            onChange={(e) => this.onTextChange(e, 'confirmAddIfFeeCat')}

                        />
                        <p className='validationsText w-auto ml-3'>Confirm admission only if fee category is assigned</p>
                    </div>
                    <div className='flex my-3'>
                        <Checkbox variant="filled" value={enrollAdmission?.moveStudentIfCustomFields}
                            checked={enrollAdmission?.moveStudentIfCustomFields}

                            onChange={(e) => this.onTextChange(e, 'moveStudentIfCustomFields')}
                        />
                        <p className='validationsText w-auto ml-3'>Move student to subsequent steps only if custom fields are filled</p>
                    </div>



                </div>

            </div>

        )
    }
}


const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});

export default connect(mapStatesToProps, {
    getBoardsData
})(Authentication(withRouter(AdmissionEnquiry)));
