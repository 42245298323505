import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import InputTextB from './../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
import { Checkbox } from 'primereact/checkbox';

import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import uuidv4 from 'uuid/v4';
import './styles.scss'
import { trimObj } from '../../../utile';

const formFields = [
    {
        "Type": "Text",
        "Label": "Subject Name",
        "FieldName": "subjectName",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Subject Code",
        "FieldName": "subjectCode",
        "Required": true
    },
    {
        "Type": "Toggle",
        "Label": "Active Status",
        "FieldName": "isSubjectActive",
        "Required": false
    }

]

class CreateSubject extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editSubject || { subjectId: uuidv4() });
        this.state = {
            subject: this.formFields.data,
            formValidations: this.formFields.formValidations
        }
    }

    footer = () => {
        return (<>
            <Button label="Cancel" className='p-button-outlined' onClick={this.onCloseDialog} />
            <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />

        </>)
    }

    onSubmitClick = () => {
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.subject));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            this.props.addSubject(trimObj(this.state.subject));
            // this.setState({
            //     formValidations: formStatus.formValidations,
            // });
        }
    }

    onCloseDialog = () => {
        this.props.hideCreateSubjectDialog();
        // this.setState({
        //     showCreateDialog: false,
        //     subject: {
        //         subjectName: '',
        //         subjectCode: '',
        //         isSubjectActive: false
        //     }
        // }, () => { })
    }

    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.subject));
        let formValidations = this.state.formValidations;
        data[field] = e.target.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            subject: data,
            formValidations
        });
    }
    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.subject));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            subject: data,
            formValidations
        });
    }

    render() {
        const { subject, formValidations } = this.state;
        return (
            <Dialog header={`${this.props.isAdd ? 'Add' : 'Edit'} Subject`} blockScroll={true} draggable={false} footer={this.footer} closeOnEscape={true} dismissableMask={true} visible={true} closable={false} onHide={() => this.setState({ showCreateDialog: false })}
            headerClassName = "text-center"
            >
                <div className="grid col-12 ">
                    <div className="col-5">
                        <p className='text-label'>Subject Name <span className='ma-required'>* </span>:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.SUBJECT_NAME_1} id="subjectName">
                            <InputText value={subject.subjectName} onChange={(e) => { this.onTextChange(e, 'subjectName') }} className='p-inputtext-style1 ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['subjectName'].isValid && <p className="p-error">{formValidations.fields['subjectName'].errorMsg}</p>}
                    </div>
                </div>
                <div className="grid col-12">
                    <div className="col-5">
                        <p className='text-label' >Subject Code <span className='ma-required'>*</span>:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.SUBJECT_CODE} id="subjectCode">
                            <InputText value={subject.subjectCode} onChange={(e) => { this.onTextChange(e, 'subjectCode') }} className='p-inputtext-style1' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['subjectCode'].isValid && <p className="p-error">{formValidations.fields['subjectCode'].errorMsg}</p>}
                    </div>
                </div>
                <div className="grid col-12">
                    <div className="col-5">
                        <p className='text-label'>Active Status:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.SUBJECT_STATUS} id="isSubjectActive">
                            <InputSwitch checked={subject.isSubjectActive} onChange={(e) => { this.onToggleChange(e, 'isSubjectActive') }} />
                        </InputTextB>
                    </div>
                </div>
            </Dialog >
        )
    }
}




export default CreateSubject;