import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import InputTextB from './../customComponents/inputTextB';
import { FIELDS_INFO } from '../../constants';
import { contentSizeCheck, trimObj } from './../../utile';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import LoadingComponent from '../loadingComponent';
import { MELUHA_BOARD_ID, PDF_FILE_SIZE, PPT_FILE_SIZE, VIDEO_FILE_SIZE } from './../../store/actionTypes';
import { ContentOrigins, ContentSource, ContetOrigin, getFileTypes } from '../../utile';
import { getFormFields, onTextChange, onDropDownChange, isFormValid, getAllLanguageArrayList } from '../../utile/formHelper';
import {
    addContentToTopic,
    changeContentPrioriry,
    clearAssignContentData,
    clearDeleteContentData,
    clearGetContentData,
    clearSelecteAssignData,
    clearSelectedOrganizationData,
    deleteContent,
    dispatchClearCurriculumData,
    getActiveOrganizationData,
    getContent,
    getContentPreSignedUrl,
    getCurriculum,
    getSubject,
    getTopics,
    removeContentFromTopic,
    selectBoard,
    selectChapter,
    selectClass,
    selectContetDataForAssign,
    selectGroup,
    selectSubject,
    selectTopic,
    updateContent,
    updateSubject,
    uploadContent
} from './../../store/actions';

const thirdFormFields = [
    {
        "Type": "Text",
        "Label": "File Type",
        "FieldName": "contentFileType",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Origin",
        "FieldName": "contentOrigin",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Title",
        "FieldName": "contentName",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Target URL or Key",
        "FieldName": "contentFile",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Content Language",
        "FieldName": "contentLanguage",
        "Required": true
    },
]
class EditContent extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(thirdFormFields, this.props.editContent);
        this.state = {
            // editContent: this.props.editContent,
            editContent: this.formFields.data,
            formValidations: this.formFields.formValidations,
            uploadedFileName: this.props.editContent.contentFileName,
            isUploadPending: false
        }
    }


    onTextChange = (value) => {
        this.setState((prevState) => {
            return {
                editContent: {
                    ...prevState.editContent,
                    contentName: value
                }
            };
        });
    }

    onUpdate = () => {

        let { editContent, resourceTitle } = this.state;
        console.log("editContenteditContent", editContent)
        let isValid = true;
        let updateObj=trimObj(this.state.editContent);
        if (editContent.source == ContentSource.CONTENT_REPO || editContent.contentFileType == 'quiz') {

            if (editContent.contentName.trim() == '') {
                isValid = false;
                this.toast.show({ severity: 'error', summary: 'Invalid Title', detail: 'Please enter the Title.', life: 4000 });
            }
            // if (isValid) {
            //     let updateObj = trimObj(this.state.editContent);
            //     this.props.onUpdateContent(updateObj)
            // }

        }  if (editContent.source == ContentSource.UPLOAD || editContent.source == undefined) {
            if (editContent.contentOrigin && (parseInt(editContent.contentOrigin) == ContetOrigin.COLEARNERS || parseInt(editContent.contentOrigin) == ContetOrigin.ERUDEX)) {

                const formStatus = isFormValid(thirdFormFields, this.formFields.formValidations, trimObj(editContent));
                if (!formStatus.formValidations.isFormValid) {
                    this.setState({
                        formValidations: formStatus.formValidations
                    });
                    isValid = false;
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
                }
                // if (isValid) {
                //     let updateObj = trimObj(this.state.editContent);
                //     this.props.onUpdateContent(updateObj)
                // }
            }  if (editContent.source == ContentSource.UPLOAD && editContent.contentStorageKey) {
                const { file, uploadedFileName, fileExtension, fileType } = this.state;


                let obj = {};
                if (fileType) {
                    let type = fileType;
                    let resourceType = '';
                    type.toLowerCase();
                    if (type.includes('zip')) {
                        type = 'zip';
                        resourceType = 'ppt';
                    }
                    if (
                        type.includes('mp4') ||
                        type.includes('mpg') ||
                        type.includes('avi') ||
                        type.includes('wmv') ||
                        type.includes('mov') ||
                        type.includes('flv')
                    ) {
                        resourceType = 'video';
                        type = type.split('/')[1];
                    }
                    if (type.includes('pdf')) {
                        type = 'pdf';
                        resourceType = 'pdf';
                    }
                    obj = {
                        contentFileType: type,
                        contentStorageKey: this.props.contentPreSignedUrl.contentStorageKey,
                        contentResourceType: resourceType,
                        contentStorageBucketName: this.props.contentPreSignedUrl.contentStorageBucketName
                    }

                }

                let content = {
                    ...this.state.editContent,
                    ...obj,
                    // contentFileName: resourceTitle,
                    // source: 1
                }
                content = trimObj(content);
                updateObj = { ...content }
                // this.props.onUpdateContent({ ...content })

                // this.props.addContent({
                //     contentFileType: type,
                //     contentStorageKey: this.props.contentPreSignedUrl.contentStorageKey,
                //     contentId: uuidv4(),
                //     contentResourceType: resourceType,
                //     contentStorageBucketName: this.props.contentPreSignedUrl.contentStorageBucketName,
                //     contentFileName: name,
                //     contentName: this.state.resourceTitle,
                //     priority: this.state.uploadedFilePriority || '',
                //     source: 1
                // });

            }
        }
         if (editContent.contentLanguage.trim() == '') {
            isValid = false;
            this.toast.show({ severity: 'error', summary: 'Invalid Language', detail: 'Please enter the Language.', life: 4000 });
        }
        if (isValid) {
            this.props.onUpdateContent(updateObj)
        }
    }

    filterContent = (resourceType) => {
        return new Promise((res, rej) => {
            const filteredContent =
                Array.isArray(this.props.contentInfo) &&
                this.props.contentInfo.filter((content) => content.contentResourceType === resourceType);
            this.setState(
                {
                    filteredContent
                },
                () => res()
            );
        });
    };

    validateNameNPriorityOfContent = (key, compare) => {
        let isDuplicate;

        if (isDuplicate) {
            if (key === 'priority') {
                this.setState(
                    {
                        duplicatePriority: true
                    },
                    () => {
                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Change priority', life: 3000 });

                    }

                );
            }
            if (key === 'contentName') {
                this.setState(
                    {
                        duplicateName: true
                    },
                    () => this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'File name already exist', life: 3000 })
                );
            }
        } else {
            if (key === 'priority') {
                this.setState({
                    duplicatePriority: false
                });
            }
            if (key === 'contentName') {
                this.setState({
                    duplicateName: false
                });
            }
        }

        return isDuplicate;
    };

    uploadFile = () => {
        const {
            uploadedFileName,
            fileActualFileName,
            selectedFileType,
            fileExtension
        } = this.state;

        const userGivenFileName = uploadedFileName.slice(0, uploadedFileName.lastIndexOf('.'));

        let duplicatePriority = false;
        let duplicateName = false;

        if (this.props.contentInfo && this.props.contentInfo.length > 0) {
            this.props.contentInfo.forEach((x) => {
                if (
                    x.priority === this.state.uploadedFilePriority &&
                    selectedFileType.toLowerCase().includes(x.contentResourceType.toLowerCase())
                ) {
                    duplicatePriority = true;
                }
                if (
                    x.contentName.toLowerCase() === this.state.uploadedFileName.trim().toLowerCase() &&
                    selectedFileType.toLowerCase().includes(x.contentResourceType.toLowerCase())
                ) {
                    duplicateName = true;
                }
            });
        }
        // if (this.state.uploadedFilePriority == "") {
        //     this.snackBarOpen('Please enter priority.');
        // } else 
        if (duplicateName) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Name matches with another resource.. Kindly change it.', life: 3000 });
        } else if (duplicatePriority) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Priority matches with another resource.. Kindly change it.', life: 3000 });
        } else {
            if (userGivenFileName !== fileActualFileName && fileExtension !== '') {
                const selectedFile = new File([...this.state.file], userGivenFileName + '.' + fileExtension, {
                    type: selectedFileType
                });

                this.setState({ file: selectedFile }, () => {
                    this.props.getContentPreSignedUrl(userGivenFileName + '.' + fileExtension);
                });
            } else {
                this.props.getContentPreSignedUrl(userGivenFileName + '.' + fileExtension);
            }
        }
    };

    uploadFileNUpdateContent = () => {
        if (this.props.contentPreSignedUrl) {
            if (this.props.contentPreSignedUrl.presignedURL) {
                this.props.uploadContent(this.props.contentPreSignedUrl.presignedURL, this.state.file);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.contentPreSignedUrl !== this.props.contentPreSignedUrl) {
            if (this.props.contentPreSignedUrl) {
                this.uploadFileNUpdateContent();
            }
        }


        if ((prevProps.isUploading == true) && (prevProps.isUploading != this.props.isUploading)) {
            if (this.state.isUploadPending) {
                this.setState({
                    isUploadPending: false
                });
            }
        }
    }


    render() {
        //  console.log('editcontent', this.state.editContent)
        const { editContent, formValidations } = this.state;
        let contentLang = this?.props?.contentLanguagesList?.find((vl) => vl?.code === editContent?.contentLanguage)

        return <>
            <Dialog
                header={'Edit Content'}
                draggable={false}
                closeOnEscape={true}
                headerClassName = "text-center"
                className='ma-alert-box'
                dismissableMask={true}
                blockScroll={true}
                closable={false}
                visible={true}
                style={{ maxWidth: '22vw', minWidth: '80%' }}
                footer={() => {
                    return (<div>
                        <Button label="Cancel" className='p-button-outlined' onClick={this.props.onCancelEdit} />
                        <Button label="Update" disabled={this.state.isUploadPending} className='ma-m-lr10' onClick={this.onUpdate} />
                    </div>)
                }}
            // onHide={this.props.onCancelEdit}
            >
                <div className='edit-content'>
                    <div className="" >
                        <div className="">
                            <p className='ma-label-s2'>Source <span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.RESOURCE_SOURCE} id="ddaaw">
                                <InputText
                                    value={editContent.source == ContentSource.CONTENT_REPO ? 'Content Repository' : 'Upload'}
                                    disabled
                                    className='p-inputtext-style1  w-full' />
                            </InputTextB>
                        </div>

                        <div className=" mt-4">
                            <p className='ma-label-s2'>Title <span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.RESOURCE_TITLE} id="adsw">
                                <InputText
                                    value={editContent.contentName}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'contentName', this, thirdFormFields, editContent, formValidations, 'editContent', 'formValidations')
                                        //this.onTextChange(e.target.value)
                                    }}
                                    className='p-inputtext-style1  w-full' />
                            </InputTextB>
                            {formValidations && !formValidations.fields['contentName'].isValid && <p className="p-error">{formValidations.fields['contentName'].errorMsg}</p>}
                        </div>

                        <div className="mt-4">
                            <p className='ma-label-s2'>Select Content Language<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.RESOURCE_LANGUAGE} id="ddaFaw">
                                <Dropdown
                                    value={contentLang?.code}
                                    options={this?.props?.contentLanguagesList || []}
                                    optionLabel='name'
                                    optionValue='code'
                                    className=' w-full'
                                    onChange={(e) => {
                                        onTextChange(e.value, 'contentLanguage', this, thirdFormFields, editContent, formValidations, 'editContent', 'formValidations')
                                    }}
                                    placeholder="Select Content Language" />
                            </InputTextB>
                            {formValidations && !formValidations.fields['contentLanguage'].isValid && <p className="p-error">{formValidations.fields['contentLanguage'].errorMsg}</p>}

                        </div>

                        {editContent.source && (parseInt(editContent.source) == ContentSource.UPLOAD) && editContent.contentOrigin && (parseInt(editContent.contentOrigin) == ContetOrigin.COLEARNERS || parseInt(editContent.contentOrigin) == ContetOrigin.ERUDEX) && <>
                            <h3 className='ma-ml10 ma-mb0'>3rd Party Resources</h3>
                            <div className="">
                                <p className='ma-label-s2'>Resource Type<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.RESOURCE_TYPE} id="aaw">
                                    <Dropdown value={editContent.contentFileType}
                                        options={getFileTypes()}
                                        optionLabel='displayName'
                                        optionValue='value'
                                        className=' ma-w200'
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'contentFileType', this, thirdFormFields, editContent, formValidations, 'editContent', 'formValidations')
                                        }}
                                        placeholder="Select FileType" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['contentFileType'].isValid && <p className="p-error">{formValidations.fields['contentFileType'].errorMsg}</p>}
                            </div>
                            <div className=" ">
                                <p className='ma-label-s2'>Origin<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.RESOURCE_ORIGIN} id="ddsaw">
                                    <Dropdown value={parseInt(editContent.contentOrigin)}
                                        options={ContentOrigins}
                                        optionLabel='displayName'
                                        optionValue='value'
                                        className=' ma-w200'
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'contentOrigin', this, thirdFormFields, editContent, formValidations, 'editContent', 'formValidations')
                                        }}
                                        placeholder="Select Origin" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['contentOrigin'].isValid && <p className="p-error">{formValidations.fields['contentOrigin'].errorMsg}</p>}
                            </div>
                            <div className="col-12 ">
                                <p className='ma-label-s2'>Target URL or Key <span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.RESOURCE_URLKEY} id="ddaaw">
                                    <InputText
                                        onChange={(e) => {
                                            onTextChange(e.target.value, 'contentFile', this, thirdFormFields, editContent, formValidations, 'editContent', 'formValidations')
                                        }}
                                        value={editContent.contentFile}
                                        className='inputtext-style1  ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['contentFile'].isValid && <p className="p-error">{formValidations.fields['contentFile'].errorMsg}</p>}
                            </div>

                        </>}

                        {editContent.source && (parseInt(editContent.source) == ContentSource.UPLOAD) && <>
                            <div className="mt-4">
                                <p className='ma-label-s2'>File<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.RESOURCE_FILENAME} id="ddaFaw">
                                    <InputText
                                        value={this.state.uploadedFileName}
                                        disabled
                                        className='p-inputtext-style1  w-full' />
                                </InputTextB>
                            </div>
                            <div className="grid ml-1 mt-4">
                                <div className=''>
                                <label htmlFor="editfile" className='select-file'>Select File</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="editfile"
                                    hidden={true}
                                    accept={''}
                                    //onChange={this.handleChange}

                                    onChange={async (e) => {
                                        const file = e.target.files;
                                        const name = file[0] && file[0].name;
                                        if (!name) {
                                            //this.snackBarOpen('Please select file');
                                            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select file', life: 3000 });
                                            this.setState({
                                                uploadedFileName: '',
                                                uploadedFilePriority: ''
                                            });
                                            return;
                                        }
                                        const fileNameWithOutExtension = name && name.slice(0, name.lastIndexOf('.'));

                                        const selectedFileType = file[0] && file[0].type;
                                        let type = file[0] && file[0].type;
                                        const fileSize = file[0].size;
                                        const actualFileName = name.slice(0, name.lastIndexOf('.'));
                                        const extension = name.slice(name.lastIndexOf('.')).split('.')[1];
                                        let resourceType = '';
                                        type.toLowerCase();
                                        let isValidFile;

                                        if (type.includes('zip')) {
                                            type = 'zip';
                                            resourceType = 'ppt';
                                            await this.filterContent(resourceType);
                                            isValidFile = true;
                                            if (contentSizeCheck(fileSize, PPT_FILE_SIZE)) {
                                                this.toast.show({ severity: 'error', summary: 'Invalid size', detail: 'Size between 0-10mb', life: 3000 });
                                                this.setState({
                                                    uploadNameError: true,
                                                    uploadedFileName: ''
                                                });
                                                return;
                                            }
                                        }
                                        if (
                                            type.includes('mp4') ||
                                            type.includes('mpg') ||
                                            type.includes('avi') ||
                                            type.includes('wmv') ||
                                            type.includes('mov') ||
                                            type.includes('flv')
                                        ) {
                                            resourceType = 'video';
                                            await this.filterContent(resourceType);
                                            type = type.split('/')[1];
                                            isValidFile = true;
                                            if (contentSizeCheck(fileSize, VIDEO_FILE_SIZE)) {
                                                this.toast.show({ severity: 'error', summary: 'Invalid size', detail: 'Size between 0-200mb', life: 2000 });
                                                this.setState({
                                                    uploadNameError: true,
                                                    uploadedFileName: ''
                                                });
                                                return;
                                            }
                                        }
                                        if (type.includes('pdf')) {
                                            type = 'pdf';
                                            resourceType = 'pdf';
                                            await this.filterContent(resourceType);
                                            isValidFile = true;
                                            if (contentSizeCheck(fileSize, PDF_FILE_SIZE)) {
                                                this.toast.show({ severity: 'error', summary: 'Invalid size', detail: 'Size between 0-5mb', life: 2000 });
                                                this.setState({
                                                    uploadNameError: true,
                                                    uploadedFileName: ''
                                                });
                                                return;
                                            }
                                        }

                                        if (isValidFile) {
                                            this.validateNameNPriorityOfContent('contentName', actualFileName);

                                            this.setState(
                                                {
                                                    file: file,
                                                    fileLength: file[0].size,
                                                    fileActualFileName: actualFileName,
                                                    selectedFileType,
                                                    fileExtension: extension,
                                                    fileType: file[0] && file[0].type,
                                                    uploadedFilePriority: '',
                                                    uploadedFileName: fileNameWithOutExtension,
                                                    uploadNameError: false,
                                                    isUploadPending: true
                                                });
                                        } else {
                                            this.setState(
                                                {
                                                    file: '',
                                                    uploadedFileName: null,
                                                    uploadedFilePriority: ''
                                                },
                                                () => (document.getElementById('editfile').value = null)
                                            );
                                            this.toast.show({ severity: 'error', summary: 'Invalid File type', detail: 'Select A valid file', life: 3000 });
                                        }
                                    }}
                                //disabled={this.props.bulkUserUploadRequested}
                                />
                                </div>
                                <div className='-mt-1'>
                                <Button label="Upload" className='p-button-success mt-2.65rem ml-4 '
                                    onClick={() => {
                                        if (this.state.file && this.state.file.length) {
                                            this.uploadFile();
                                        } else {
                                            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please Select Pdf, Ppt, Video or Quiz', life: 3000 });
                                        }
                                    }
                                    }
                                    disabled={(this.state.resourceTitle == '' || !this.state.file) ? true : false}
                                    icon="pi pi-cloud-upload"
                                />
                                </div>
                            </div>

                            <p className='upload-note mt-1'><span>Note:</span> Supported video formats mp4, mpg, avi, wmv, mov, flv and files format in pdf, zip</p>

                        </>}






                    </div>
                </div>
            </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>;
    }
}






const mapStateToProps = (state) => ({
    loading: state.curriculumData && state.curriculumData.loading,
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    subjectData:
        state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.subjects &&
        state.curriculumData.response.Item.subjects,
    chapterData:
        state.curriculumData &&
        state.curriculumData.subjectInfo &&
        state.curriculumData.subjectInfo.length &&
        state.curriculumData.subjectInfo[0] &&
        state.curriculumData.subjectInfo[0].chapters,
    topicData:
        state.curriculumData &&
        state.curriculumData.topicsInfo &&
        state.curriculumData.topicsInfo.length &&
        state.curriculumData.topicsInfo[0] &&
        state.curriculumData.topicsInfo[0].topics,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    updateSubjectResponse: state.updateSubject && state.updateSubject.response && state.updateSubject.response,
    contentPreSignedUrl:
        state.contentPreSignedUrl && state.contentPreSignedUrl.response && state.contentPreSignedUrl.response,
    contentInfo:
        state.contentInfo &&
        state.contentInfo.response &&
        state.contentInfo.response.Item &&
        state.contentInfo.response.Item.contentDetails &&
        state.contentInfo.response.Item.contentDetails,
    contentKey:
        state.contentInfo &&
        state.contentInfo.response &&
        state.contentInfo.response.Item &&
        state.contentInfo.response.Item.contentKey &&
        state.contentInfo.response.Item.contentKey,
    contentGetSuccess: state.contentInfo && state.contentInfo.successStatus && state.contentInfo.successStatus,
    uploadContent: state.uploadContent && state.uploadContent,
    isUploading: state.uploadContent && state.uploadContent && state.uploadContent.isUploading,
    updateContentInfo: state.updateContent && state.updateContent.response && state.updateContent.response,
    updateContentLoading: state.updateContent && state.updateContent.loading && state.updateContent.loading,
    deleteContentInfo: state.deleteContent && state.deleteContent.response && state.deleteContent.response,
    deleteContentLoading: state.deleteContent && state.deleteContent.loading && state.deleteContent.loading,
    deleteContentError: state.deleteContent && state.deleteContent.error && state.deleteContent.error,
    selectedContentDataForAssign:
        state.assignContent &&
        state.assignContent.selectedContentDataForAssign &&
        state.assignContent.selectedContentDataForAssign,
    checkedContetForAssign:
        state.assignContent && state.assignContent.selectedContent && state.assignContent.selectedContent
});




export default connect(mapStateToProps, {
    getCurriculum,
    getActiveOrganizationData,
    selectBoard,
    selectClass,
    selectGroup,
    updateSubject,
    getSubject,
    selectTopic,
    selectChapter,
    selectSubject,
    getTopics,
    getContent,
    getContentPreSignedUrl,
    uploadContent,
    updateContent,
    deleteContent,
    clearDeleteContentData,
    clearGetContentData,
    selectContetDataForAssign,
    addContentToTopic,
    removeContentFromTopic,
    clearAssignContentData,
    changeContentPrioriry,
    clearSelectedOrganizationData,
    clearSelecteAssignData,
    dispatchClearCurriculumData
})(EditContent);