import React, { Component } from 'react'

import { Dialog } from 'primereact/dialog';
import { IndexToAlphabet, OptionToAlphabet } from '../../../utile';
// import ContentPlayers from '../../contentMaster/contentPlayers';
import MathJax from 'react-mathjax-preview';
import { connect } from 'react-redux';
import SingleQuestion from './singleQuestion';
import { QuestionTypeKeys, QuestionTypeKeysWithAQ } from '../constants';
import QuestionInTable from './questionInTable';
class PreviewQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: null
        }
    }


    callMathJax = () => {
        if (window && window.MathJax && window.MathJax.Hub) {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }

    }


    componentDidMount() {

        this.callMathJax();
    }
    componentDidUpdate() {


        //   console.log('ddddd')
    }


    questionRender = (question, index) => {
        return <>{this.fieldRender(question, 'Question', 'question', 'attachmentUrl', index)}
        </>
    }

    optionsRender = (question) => {
        return <><p className='q-label ma-mt30'>Options</p>
            {
                question.options && question.options.length && question.options.map((a, oIndex) => {
                    return <div className={`option-w ${oIndex % 2 == 0 ? 'sp-clearFix' : ''}`} key={a.id}>
                        <SingleQuestion pclassName="question" optionN={IndexToAlphabet(oIndex + 1)} mathJaxValue={a.value} attachment={a.imageUrl} />
                    </div>
                })
            }

            <div className='ma-clearFix'></div>
            <hr className='ma-m20' />
            <p className='q-label ma-mt30'>Key</p>
            {(question.questionType == "SCCT" || question.questionType == "PQS") && <p className='question'>{OptionToAlphabet(question.key)}</p>}
            {(question.questionType == "MCCT"  || question.questionType == "PQM") && <p className='question'>{question.key.map(k => { return OptionToAlphabet(k) }).join(', ')}</p>}
        </>
    }

    explainationAndHintRender = (question) => {
        return <>
            {this.fieldRender(question, 'Explanation', 'explanation', 'explainationAttachment')}
            <hr className='ma-m20' />
            {this.fieldRender(question, 'Hint', 'hint', 'hintAttachment')}
        </>
    }



    intKeyRender = (question) => {
        return <>
            <div>
                <p className='q-label ma-mt30'>Key</p>
                <p className='question'>{question.key}</p>
            </div>
        </>
    }



    fbAnswerRender = (question) => {
        return <div>
            <p className='q-label ma-mt30'>Answers</p>
            <p className='question'>{question.key}</p>
            <p className='q-label ma-mt30'>Alternate answer</p>
            <p className='question'>{question.alternateKey}</p>
        </div>
    }


    fieldRender = (question, label, filedName, attachmentName, index) => {
        return <><p className='q-label'>{label} {index ? index : ''}</p>
            <SingleQuestion pclassName="question " mathJaxValue={question[filedName]} attachment={question[attachmentName]} />
        </>

    }
    paraRender = (question) => {
        return <>
            {this.fieldRender(question, 'Paragraph', 'paragraph', 'paragraphUrl')}
        </>
    }

    paraQuestionsRender = (question) => {
        return <>
            <p className='q-label mt-mt20'>Questions</p>
            <div className='ma-p10 '>
                {question.questions && question.questions.length ? <>
                    {question.questions.map((temp, index) => <>
                        {this.questionRender(temp, index + 1)}
                        <hr className='ma-m20' />
                        {this.optionsRender(temp)}
                        <hr className='ma-m20' />
                        {this.explainationAndHintRender(temp)}</>)}
                </> : <></>}
            </div>
        </>
    }

    vsaqRender = (question) => {
        return <>
            {this.fieldRender(question, 'Question', 'question', 'attachmentUrl')}
            <hr className='ma-m20' />
            {this.fieldRender(question, 'Key', 'key', 'keyUrl')}
            <hr className='ma-m20' />
            {this.fieldRender(question, 'Scheme of Validation', 'schemeOfValidation', 'schemeOfValidationUrl')}
        </>
    }


    getQuestionRender = (question) => {
        switch (question.questionType) {
            case QuestionTypeKeys.SCCT:
            case QuestionTypeKeys.MCCT:
            case QuestionTypeKeys.PQS:
            case QuestionTypeKeys.PQM:
                return <>
                    {
                        question.paragraph && <>
                            {this.paraRender(question)}
                            <hr className='ma-m20' />
                        </>
                    }

                    {this.questionRender(question)}
                    <hr className='ma-m20' />
                    {this.optionsRender(question)}
                    <div className='ma-clearFix'></div>
                    <hr className='ma-m20' />
                    {this.explainationAndHintRender(question)}
                </>;
            case QuestionTypeKeys.INT:
                return <>
                    {this.questionRender(question)}
                    <hr className='ma-m20' />
                    {this.intKeyRender(question)}
                    <hr className='ma-m20' />
                    {this.explainationAndHintRender(question)}
                </>;
            case QuestionTypeKeys.NUM:
                return <>
                    {this.questionRender(question)}
                    <div className='ma-clearFix'></div>
                    <hr className='ma-m20' />
                    {this.intKeyRender(question)}
                    <hr className='ma-m20' />
                    {this.explainationAndHintRender(question)}
                </>;
            case QuestionTypeKeys.FB:
                return <>
                    {this.questionRender(question)}
                    <hr className='ma-m20' />
                    {this.fbAnswerRender(question)}
                    <hr className='ma-m20' />
                    {this.explainationAndHintRender(question)}
                </>;
            case QuestionTypeKeys.PSGS:
            case QuestionTypeKeys.PSGM:
                return <>
                    {this.paraRender(question)}
                    <hr className='ma-m20' />
                    {this.paraQuestionsRender(question)}
                </>;
            case QuestionTypeKeysWithAQ.VSAQ:
            case QuestionTypeKeysWithAQ.SAQ:
            case QuestionTypeKeysWithAQ.LAQ:
                return <>
                    {this.vsaqRender(question)}

                </>;
            default:
                return <>Question type not supported</>

        }


    }


    render() {

        const { question } = this.props;
        // console.log('test 1', question)
        return (<div>
            <Dialog
                blockScroll={true}
                //visible={this.state.isShowCreate}
                style={{ width: '96%', height: '96vh' }}
                header={'Question Preview'} headerClassName='text-center'
                modal
                keepInViewport={true}

                draggable={false}
                // footer={this.footer}
                closeOnEscape={true}
                dismissableMask={false}
                visible={true}
                closable={true}
                onHide={this.props.onHide}>
                <div className='preview-paper'>
                    <div className='paper-download-1'>

                        <div className='question-wrapper' >
                            {this.getQuestionRender(question)}
                            {/* <QuestionInTable question={question} /> */}

                            {/* <p className='question'><span className='qNo'>{question.qNo}.</span> {question.question}</p> */}
                            {/* <SingleQuestion pclassName="question" mathJaxValue={question.question} attachment={question.attachmentUrl} /> */}
                            {/* <MathJax className='question' math={question.question} /> */}
                            {/* {
                            question.attachmentUrl && question.attachmentUrl.length > 2 && <>
                                <img src={'https://classetimage.s3.ap-south-1.amazonaws.com/' + question.attachmentUrl} className='q-attach' />
                            </>
                        } */}


                        </div>
                    </div>
                </div>
            </Dialog>


            {/* {this.state.isShowPreview && <ContentPlayers onCloseDialog={() => {
                this.setState({
                    isShowPreview: false
                })
            }} contentInfo={this.state.previewContent} />} */}
        </div>)
    }
}



const mapStatesToProps = (state) => ({
    //discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
});

export default connect(mapStatesToProps, {})(PreviewQuestion);





