import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";

import './styles.scss';
import { SelectButton } from "primereact/selectbutton";
import { ImageIcon, MailIcon, ViewIcon } from "../../../svgIcons";
import { Dropdown } from "primereact/dropdown";

// const TeacherDairyfForatFields = require('./teacherDairyFormat.json');
const sampleOptions = [
    { value: 'SCCT', label: 'SCCT' },
    { value: 'MCCT', label: 'MCCT' },
    { value: 'integer', label: 'integer' },
    { value: 'Numerial', label: 'Numerical' }

]
const options = [
    { value: 1, label: 'Add Exam Template' },
    { value: 2, label: 'Exam Templates' }];

const tabHeadStyle = { backgroundColor: 'white', borderRadius: 13, width: '50%', display: "flex", justifyContent: 'center' }
class NewQuestionBank extends Component {

    constructor(props) {
        super(props);
        //  this.formFields = getFormFields(getFields(this.props.editRole), this.props.editRole ? processEditRole(this.props.editRole) : {});
        // this.formFields = getFormFields(TeacherDairyfForatFields);
        this.state = {

            //  formValidations: this.formFields.formValidations,


            teacher: [{ id: 1, title: '', description: '' }],
            selectedTab: '1'
        }

        // this.service = new Service();
    }

    addTeacher = () => {
        const newTeacher = { id: this.state.teacher.length + 1, title: '', description: '' };
        this.setState(prevState => ({
            teacher: [...prevState.teacher, newTeacher]
        }));
    }


    render() {
        const { formValidations } = this.state
        return (
            <>

                <div className="p-5">
                    {/* <SelectButton
                        className='button-group col-12'
                        value={this.state.tab}
                        options={options}
                        onChange={(e) => this.setState({ tab: e.value })}


                    /> */}
                    <div className='shadow-2  mx-3  flex  mb-3 justify-content-center p-1' style={{ backgroundColor: '#F4F5F6', borderRadius: 13, alignItems: 'center', borderWidth: 4, borderColor: '#E6E8EA' }}>
                        <div className={` cursor-pointer ${this.state.selectedTab === '1' ? 'shadow-2' : ''}`} style={this.state.selectedTab === '1' ? tabHeadStyle : { width: '50%', display: "flex", justifyContent: 'center' }} onClick={() => this.setState({ selectedTab: '1' })}>
                            <p className='px-8 py-3 inter500semibold ' >{'Add Exam Template'}</p>
                        </div>
                        <div className={` cursor-pointer ${this.state.selectedTab === '2' ? 'shadow-2' : ''}`} style={this.state.selectedTab === '2' ? tabHeadStyle : { width: '50%', display: "flex", justifyContent: 'center' }} onClick={() => this.setState({ selectedTab: '2' })}>
                            <p className='px-8 py-3 inter500semibold'>{'Exam Templates'}</p>
                        </div>
                    </div>

                    <div className="createtest-card flex ">
                        <div className="field col-4">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Question Type<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div className='flex w-10'>
                                    <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                        <Dropdown
                                            className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                            options={sampleOptions}
                                            // value={term.academicBoard}
                                            // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center mt-1">
                                                    <MailIcon width={20} height={16} color={'#667A85'} />
                                                    <span className='text-xl ml-4'>Select Question Type</span>
                                                </div>
                                            )} />
                                    </div>
                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                        </div>
                        <div className="mt-6 ml-7">

                            <Button className="addButton" >
                                <p >Add Question</p>
                            </Button>
                        </div>
                    </div>
                    <div className="createtest-card  ">
                        <div className="flex">
                            <div className="col-10 mt-2">
                                <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Question<span className="ma-required">*</span></label>
                                <div className="p-inputgroup">
                                    <InputTextarea className='h-7rem border-300 shadow-2 w-full border-round-md pt-2 border-none text-lg pl-3 w-11' id="description"
                                        //  value={term.description} 
                                        //  onChange={(e) => { onTextChange(e.target.value, 'description', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                        placeholder="Enter Question" rows={3} />

                                </div>

                            </div>
                            <div className="pt-7 flex flex-column ml-5">
                                <div className=""> <ViewIcon className="mt-5" /></div>

                                <div className="mt-5"> <ImageIcon className="" /></div>
                            </div>
                        </div>
                        <div className="col-3">
                            {/* <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Section<span className='ma-required'>*</span></p> */}
                            <div className='flex w-10'>
                                <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                    <Dropdown
                                        className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                        options={sampleOptions}
                                        // value={term.academicBoard}
                                        // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center mt-1">
                                                <MailIcon width={20} height={16} color={'#667A85'} />
                                                <span className='text-xl ml-4'>Total Options</span>
                                            </div>
                                        )} />
                                </div>
                            </div>
                        </div>

                    </div>





                </div>

            </>
        )
    }

}
export default NewQuestionBank