import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';

import SvgViewer from '../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import './styles.scss'
// import UserInfo from './userInfo';
// import CreateUser from './createUser';
import Authentication from '../session';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import IndividualUsageAnalytics from '../analytics/usageAnalytics/individualUsageAnalytics';
import { AnalyticsIcon2, BoardsIconInActive, CalenderCheckIcon, MailIcon, WarningIcon } from '../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../internationalization';
import withRouter from '../lib/withRouter';
import InputTextB from '../customComponents/inputTextB';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];

const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const data = [
    {
        grades: "1st",
        bornAfter: '2015-01-01',
        bornBefore: '2015-12-31'
    },
    {
        grades: "2nd",
        bornAfter: '2014-01-01',
        bornBefore: '2014-12-31'
    },
    {
        grades: "3rd",
        bornAfter: '2013-01-01',
        bornBefore: '2013-12-31'
    },
    {
        grades: "4th",
        bornAfter: '2012-01-01',
        bornBefore: '2012-12-31'
    },
    {
        grades: "5th",
        bornAfter: '2015-01-01',
        bornBefore: '2015-12-31'
    },
    {
        grades: "6th",
        bornAfter: '2014-01-01',
        bornBefore: '2014-12-31'
    },
    {
        grades: "7th",
        bornAfter: '2013-01-01',
        bornBefore: '2013-12-31'
    },
    {
        grades: "8th",
        bornAfter: '2012-01-01',
        bornBefore: '2012-12-31'
    }
];



const home = { icon: 'pi pi-home' }


class AgeCriteriaTable extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            users: data,
            isShowUserInfo: false,
            isShowAnalytics: false,
            ageCriteriaTableData: [],
            selectedUser: null,
            isCalenderDialogOpen: false,
            updateDateType: "",
            updatedToRow: {},
            isDatesChanged:false,
            branchId: "",
            boards: [],
            boardId: "",
            isCreateUser: false,
            //filterData: {},
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,


        }

        this.service = new Service();
    }


    rightToolbarTemplate = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (
            <React.Fragment>
                {/* <h4 className='ma-tab-title'>User Management</h4> */}
                <h4 className='ma-tab-title'>{I18Next('VehicleManagement', langType).COMMON_KEY}</h4>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }


    componentDidMount() {
        this.onChangeBranch(this.props.branchData[0]?.key)
    }

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevState.branchId !== this.state.branchId) {
            this.setState({ boardId: this.state.boards[0]?.boardId, boardId: "" }, () => this.onChangeBoard(this.state.boards[0]?.boardId))
        }
    }

    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }


    onSelectDateChangeForBornAfter = (rowData, rowIndex, type) => {
        this.setState({
            isCalenderDialogOpen: !this.state.isCalenderDialogOpen,
            updatedToRow: { ...rowData, currentIndex: rowIndex, datetype: type }
        });
    }


    onAssignDatesToParticularGrade = () => {
        const { updatedToRow, depositeDate } = this.state
        const updatedAgeCriteriaData = this.state.ageCriteriaTableData.map((each, index) => {
            if (index == updatedToRow?.currentIndex) {
                if (updatedToRow?.datetype == "Born Before") {
                    this.setState({isDatesChanged:true})
                    return { ...each, bornBefore: depositeDate }
                }
                else if (updatedToRow?.datetype == "Born After") {
                    this.setState({isDatesChanged:true})
                    return { ...each, bornAfter: depositeDate }
                }
            }
            else {
                return each;
            }
        })

        this.setState({ ageCriteriaTableData: updatedAgeCriteriaData, isCalenderDialogOpen: false })


    }

    getAgeCriteriaData = async (boardId) => {
        const { branchId } = this.state
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admissions/agecriteria?boardId=${boardId}&branchId=${branchId}`;
        try {
            const res = await this.service.get(url, true);
            console.log(res, "res345")
            if (res?.res?.status && res.status) {
                this.setState({ ageCriteriaTableData: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    };


    showUserInfo = (user) => {
        this.setState({
            selectedUser: user,
            isShowUserInfo: true
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.filterUsersData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }

        });
    }

    startGlobalFilter = () => {

        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isUserNameSearch, username, boardId, classId, groupId } = filterData;

            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }

            }



            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }


    onSaveAllAssigendDatesToGrades = async () => {
        const { branchId, boardId, ageCriteriaTableData } = this.state
        const payload = ageCriteriaTableData?.map(each => {
            return { boardId: boardId, branchId: branchId, gradeId: each.classId, bornAfter: each.bornAfter, bornBefore: each.bornBefore }
        })
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admissions/agecriteria`;
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                console.log(res?.res?.data, "res345")
                this.setState({ isLoading: false,isDatesChanged:false});
                this.getAgeCriteriaData(boardId)
                this.toast.show({ severity: 'success', summary: "Assigned dates have been successfully saved.", detail: "", life: 3000 });
            } else {
                console.log(res,"res34");
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    }



    onChangeBranch = (branchId) => {
        let selectedbranchName = this.props.branchData?.find((branchd) => branchd?.key == branchId)?.name;

        let filteredBoards = this.props.boards?.filter(board => board?.assignedBranches?.includes(branchId));


        this.setState({
            branchId: branchId,
            boardId: '',
            selectedbranchName: selectedbranchName,
            boards: filteredBoards
        });
    };
    onChangeBoard = (boardId) => {

        const selectedBoard = this.state.boards && this.props.boards?.find((board) => board.boardId === boardId);

        this.setState({
            classes: selectedBoard?.classes,
            boardId,
            selectedBoardName: selectedBoard?.boardName
        }, () => this.getAgeCriteriaData(boardId));
    };
    render() {
        const { selectedUser, isShowUserInfo, isShowAnalytics, editUser } = this.state
        return (
            <>
                {/* <div className="ask-a-doubt ma-main">
                            <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} />
                        </div> */}
                <div className='flex justify-content-between'>
                    <div className="grid flex ml-2 mt-3 md:col-6 lg:col-12 xl:col-12">
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props.branchData}
                                value={this.state.branchId} onChange={(e) => this.onChangeBranch(e.value)} optionLabel="name"
                                optionValue="key" />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.state.boards} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                optionValue="boardId" />
                        </div>
                        <div className="xl:-ml-3 col-5 mt-4 flex justify-content-end align-items-center">
                            {(this.state.boardId && this.state.branchId && this.state.isDatesChanged) &&
                                <Button
                                    label="Save"
                                    icon={<i className='pi pi-download text-xl ml-3 -mr-3'></i>}
                                    className='ageCriteriaSaveBtn'
                                    onClick={() => this.onSaveAllAssigendDatesToGrades()}
                                />}
                        </div>
                    </div>
                </div>
                <div className="  ">
                    {!this.state.isLoading && this.state.ageCriteriaTableData.length ?
                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            value={this.state.ageCriteriaTableData}
                            dataKey="id"
                            paginator
                            lazy
                            responsiveLayout="scroll"
                            // loading={this.state.isLoading}
                            className="dataTableValuesText"
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            // selection={this.state.selectedProducts} 
                            // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                            // onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.ageCriteriaTableData?.length}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.ageCriteriaTableData?.length}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        // header={header}
                        >
                            <Column style={{ width: "5rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} className=' ' header="Sl No" body={(rowData, { rowIndex }) => {
                                return (
                                    <span>{rowIndex + 1}</span>
                                )
                            }} ></Column>
                            <Column style={{ width: "10rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} filterField="className" field="className" header="Grades" />
                            <Column style={{ width: "18rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} className=' ' header="Born After" body={(rowData, { rowIndex }) => {
                                return (
                                    <div className='flex justify-content-center align-items-center cursor-pointer' onClick={() => { this.onSelectDateChangeForBornAfter(rowData, rowIndex, "Born After"); }}>
                                        <CalenderCheckIcon width={16} height={16} color={'black'} />
                                        <p className='ml-2'>{new Date(rowData.bornAfter).toLocaleDateString('en-GB') == "Invalid Date" ? '--/--/----'  : new Date(rowData.bornAfter).toLocaleDateString('en-GB')}</p>
                                    </div>
                                )
                            }} ></Column>
                            <Column style={{ width: "18rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} className=' ' header="Born Before" body={(rowData, { rowIndex }) => {
                                return (
                                    <div className='flex justify-content-center align-items-center cursor-pointer' onClick={() => { this.onSelectDateChangeForBornAfter(rowData, rowIndex, "Born Before"); }}>
                                        <CalenderCheckIcon width={16} height={16} color={'black'} />
                                        <p className='ml-2'>{new Date(rowData.bornBefore).toLocaleDateString('en-GB') == "Invalid Date" ? '--/--/----'  : new Date(rowData.bornBefore).toLocaleDateString('en-GB')}</p>
                                    </div>
                                )
                            }} ></Column>

                        </DataTable> : ""}
                </div>

                {
                    isShowAnalytics && <IndividualUsageAnalytics
                        userInfo={selectedUser}
                        onHide={() => { this.setState({ isShowAnalytics: false }) }} />
                }
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {/* </Navbar> */}
                <Dialog
                    blockScroll={true}
                    visible={this.state.isCalenderDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className='calenderBoxSize'
                    dismissableMask={false}
                    closable={false}
                    footer={() => (
                        <div className='flex justify-content-center mb-3'>
                            <Button label="Cancel" className='calenderCancelBtn mr-5 px-7' onClick={() => { this.setState({ isCalenderDialogOpen: false, depositeDate: "" }) }} />
                            <Button label='Apply' className='calenderApplyBtn   px-7' onClick={() => { this.onAssignDatesToParticularGrade(); }} />
                        </div>
                    )}
                    onHide={() => {
                        this.setState({
                            isCalenderDialogOpen: false,
                        })
                    }}>
                    <div className="flex flex-column justify-content-center border-300 border-bottom-1 mt-1">
                        <Calendar
                            className="pb-5"
                            value={this.state.depositeDate}
                            onChange={(e) => this.setState({ depositeDate: e.value })}
                            inline
                            showIcon={false}
                        />
                    </div>


                </Dialog>
            </>
        )
    }
}
const mapStatesToProps = (state) => ({
    // boards: state.boardsData.boards,
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(AgeCriteriaTable);
