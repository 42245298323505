import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon, MailIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData } from '../../store/actions';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';

class BoardToSectionDDCol extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardId: null,
            classId: null,
            groupId: null,
            branchId: null,
            classes: [],
            branches: [],
            curriculumInfo: {},
        };
        this.service = new Service()
    }

    componentDidMount() {
        this.props.getBoardsData(true);
    }



    componentDidUpdate() {

        if (this.state.boardId == null && this.props.boards?.length > 0) {
            const selectedBoard = this.props.boards.find((board) => board?.boardId === this.props?.boards[0]?.boardId);

            if (selectedBoard?.classes?.length > 0) {
                const selectedClass = selectedBoard.classes.find((classd) => classd.classId == selectedBoard.classes[0].classId);

                if (selectedClass?.branches?.length > 0) {
                    const selectedbranchName = selectedClass.branches.find((branchd) => branchd.branchId == selectedClass.branches[0].branchId)?.branchName;

                    const sections = selectedClass.sections?.filter((section) => section.branchId == selectedClass.branches[0].branchId) || [];

                    const selectedsectionName = sections.length > 0 ? sections.find((sectiond) => sectiond.sectionId == sections[0].sectionId)?.sectionName : '';

                    this.setState({
                        classes: selectedBoard.classes,
                        boardId: this.props.boards[0]?.boardId,
                        selectedBoardName: selectedBoard.boardName,
                        classId: selectedBoard.classes[0]?.classId,
                        selectedClass: selectedClass,
                        groupId: selectedClass.groupId,
                        branches: selectedClass.branches,
                        selectedClassName: selectedClass.className,
                        branchId: selectedClass.branches[0]?.branchId,
                        selectedbranchName: selectedbranchName,
                        sections: sections,
                        sectionId: sections.length > 0 ? sections[0]?.sectionId : '',
                        selectedsectionName: selectedsectionName,
                    }, () => this.setDropdownsData());
                }
            }
        }

    }


    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], classId: null, curriculumInfo: null, branches: [], sections: []
        });
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board?.boardId === boardId);

        this.setState({
            classes: selectedBoard?.classes,
            boardId,
            selectedBoardName: selectedBoard?.boardName,
            classId: '',
            selectedClass: {},
            groupId: '',
            branches: [],
            selectedClassName: ''
        });
    };

    onChangeClass = (classId) => {
        this.setState({
            branches: [], sections: []
        });
        let selectedClass = this.state?.classes?.find((classd) => classd.classId == classId)
        this.setState({
            classId: classId,
            selectedClass: selectedClass,
            groupId: selectedClass?.groupId,
            branches: selectedClass?.branches,
            selectedClassName: selectedClass?.className,
            branchId: '',
            selectedbranchName: '',
            sections: []
        });
    };

    onChangeBranch = (branchId) => {
        this.setState({
            sections: []
        });
        let selectedbranchName = this.state?.branches?.find((branchd) => branchd.branchId == branchId).branchName;
        let sections = this.state.selectedClass?.sections?.filter((section) => section.branchId == branchId)

        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            sections: sections,
            sectionId: '',
            selectedsectionName: '',
        });
    };

    onChangeSection = (sectionId) => {
        let selectedsectionName = this.state.sections?.find((sectiond) => sectiond.sectionId == sectionId).sectionName;

        this.setState({
            sectionId: sectionId,
            selectedsectionName: selectedsectionName,
        }, () => this.setDropdownsData());
    };

    setDropdownsData = () => {
        let {
            boardId,
            classId,
            branchId,
            sectionId,
            selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        } = this.state
        let returnData = {
            boardId, classId, branchId, sectionId, selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        }

        this.props.setValues(returnData)
    }



    render() {
        return (
            <div>
                <div >
                    <div className='grid m-2'>
                        <div className="col-12">                       
                        <div className="row-3 my-2">
                            <ClassetDropdown required={true} label={'Board'} icon={<MailIcon height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.props.boards} value={this.state?.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                optionValue="boardId" disabled={this.props.selectedValue===2 }/>
                        </div>
                        <div className="row-3 my-2">
                            <ClassetDropdown required={true} label={'Grade'} icon={<MailIcon />} placeholder={'Select Grade'} options={this.state?.classes}
                                value={this.state.classId} onChange={(e) => this.onChangeClass(e.value)} optionLabel="className"
                                optionValue="classId" disabled={this.props.selectedValue=== 2 }/>
                        </div>
                        <div className="row-3 my-2">
                            <ClassetDropdown required={true} label={'Branch'} icon={<MailIcon height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.state?.branches}
                                value={this.state.branchId} onChange={(e) => this.onChangeBranch(e.value)} optionLabel="branchName"
                                optionValue="branchId" disabled={this.props.selectedValue===2 }/>
                        </div>
                        <div className="row-3 my-2">
                            <ClassetDropdown required={true} label={'Section'} icon={<MailIcon />} placeholder={'Select Section'} options={this.state?.sections}
                                value={this.state.sectionId} onChange={(e) => this.onChangeSection(e.value)} optionLabel="sectionName"
                                optionValue="sectionId" disabled={this.props.selectedValue===2 }/>
                        </div>
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    // boards: state.boardsData.boards,
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(BoardToSectionDDCol);