import React, { Component } from "react"
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import cloneDeep from 'lodash/cloneDeep';
import Authentication from '../../../session';
import _ from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MultiSelect } from "primereact/multiselect";
import moment from 'moment';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
// import SearchBar from "./searchBar";
// import './styles.scss'
import {
    getBoardsData,
    getBranchesLatest
} from '../../../store/actions';
import { baseUrlAdmin } from "../../../store/apiConstants";
import withRouter from "../../lib/withRouter";
import { BasicLazyParams } from "../../../utile";
import Service from "../../../services";
import SearchBar from "./searchBar";


const branchData = [
    {
        branchName: "Branch A",
        branchHead: "John Doe",
        contactNumber: "123-456-7890",
        creationDate: "CBSE",
        totalStaff: 20,
        totalStudents: 300,
    },
    {
        branchName: "Branch B",
        branchHead: "Jane Smith",
        contactNumber: "987-654-3210",
        creationDate: "CBSE",
        totalStaff: 15,
        totalStudents: 250,
    },
    {
        branchName: "Branch C",
        branchHead: "Alice Johnson",
        contactNumber: "456-789-0123",
        creationDate: "CBSE",
        totalStaff: 25,
        totalStudents: 350,
    },
    {
        branchName: "Branch D",
        branchHead: "Michael Brown",
        contactNumber: "789-012-3456",
        creationDate: "CBSE",
        totalStaff: 18,
        totalStudents: 280,
    },
    {
        branchName: "Branch E",
        branchHead: "Emily Wilson",
        contactNumber: "234-567-8901",
        creationDate: "CBSE",
        totalStaff: 22,
        totalStudents: 320,
    },
    {
        branchName: "Branch F",
        branchHead: "David Lee",
        contactNumber: "890-123-4567",
        creationDate: "CBSE",
        totalStaff: 17,
        totalStudents: 270,
    },
    {
        branchName: "Branch G",
        branchHead: "Sophia Garcia",
        contactNumber: "345-678-9012",
        creationDate: "CBSE",
        totalStaff: 19,
        totalStudents: 290,
    },
    {
        branchName: "Branch H",
        branchHead: "James Rodriguez",
        contactNumber: "012-345-6789",
        creationDate: "CBSE",
        totalStaff: 23,
        totalStudents: 330,
    },
    {
        branchName: "Branch I",
        branchHead: "Olivia Martinez",
        contactNumber: "678-901-2345",
        creationDate: "CBSE",
        totalStaff: 16,
        totalStudents: 260,
    },
    {
        branchName: "Branch J",
        branchHead: "Ethan Taylor",
        contactNumber: "901-234-5678",
        creationDate: "CBSE",
        totalStaff: 21,
        totalStudents: 310,
    },
];
class FeeStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            users: [
            ],
            filterData: null,

            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],

            isUserNameSearch: false,
            username: '',
            boardId: null,
            classId: null,
            groupId: null,
            sectionIds: [],
            boards: [],
            classes: [],
            groups: [],
            sections: [],
            branchLevels: {},
            lazyParams: BasicLazyParams,
            users: [],
            selectedUser: [],
            globalSearch: '',
            totalRecords: 0,
        };
        this.service = new Service();

    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }



    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }
    componentDidMount() {

        this.filterUsersData();
    }



    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }
    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.filterUsersData);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }


    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>


            </div>
        );

        return (
            <div>


                <div className='ma-main  '>
                    <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} />
                    <div className="card datatable-crud-demo ma-m30  m-2">

                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            value={branchData}
                            dataKey="id"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            responsiveLayout="scroll"
                            showGridlines
                            selectionMode={'checkbox'}
                            columnResizeMode="expand"
                            resizableColumns
                            // selection={this.state.selectedProducts} 
                            // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        // header={header}
                        >

                            <Column style={{ width: "5rem", height: "6rem" }} alignHeader={"center"}
                                header={() => {
                                    return (

                                        <Checkbox
                                            // onChange={} 
                                            checked={false}>

                                        </Checkbox>

                                    )
                                }}
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <div className='flex justify-content-center'>
                                            <Checkbox
                                                // onChange={} 
                                                checked={false}>

                                            </Checkbox>
                                        </div>
                                    )
                                }} ></Column>

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branchName" field="branchName" header="Branch Name" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="creationDate" field="creationDate" header="Board" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStaff" field="totalStaff" header="Grade" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStudents" field="totalStudents" header="Total Students" />
                            {/* <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStudents" field="totalStudents" header="Contact Person" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branchHead" field="branchHead" header="Phone Number" /> */}


                            {/* <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Actions" body={(rowData, { rowIndex }) => {
                                return (
                                    <span className='text-xl'>...</span>
                                )
                            }} ></Column> */}


                        </DataTable>

                        <Toast ref={(el) => this.toast = el} position="bottom-right" />

                        {/* {this.state.isLoading && <LoadingComponent />} */}
                    </div >
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({

    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.isLoading,
    isUpdated: state.boardsData.isUpdated,
    boardsData: state.boardsData,
    updateError: state.boardsData.error,
    updateErrorMessage: state.boardsData.errorMessage,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});
export default connect(null, {

})(Authentication(withRouter(FeeStructure)));