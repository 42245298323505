import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
// import Navbar from './../navbar';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { TabMenu } from 'primereact/tabmenu';                 
import { primaryColor } from '../../store/apiConstants';
import AcademicSession from './academicSession';
import AcademicTerms from './termsScreen';
import BranchMaster from './branchMaster';
import "./styles.scss";
import { getAcademicSessionsDropDowns, getBoardsDropDowns, getBranchesLatest } from '../../store/actions';


const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const tabs = [
    { label: 'Vehicle Management' },
    { label: 'Route Management' },
    { label: 'Assign Management' },
    { label: 'Transport Fee ' },
];


class SchoolSetupIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        };
    }



    componentDidMount() {
        this.getBoardsAndBranches();
    }
    getBoardsAndBranches = () => {
        this.props.getBranchesLatest();
        this.props.getAcademicSessionsDropDowns();
        this.props.getBoardsDropDowns();
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Transport Management</h4>
            </React.Fragment>
        )
    }

    // leftToolbarTemplate() {
    //     return (
    //         <React.Fragment>
    //             <BreadCrumb model={items} home={home} />
    //         </React.Fragment>
    //     )
    // }


    componentDidUpdate(prevProps, prevState) {
        
    }

    render() {

        return (
            <div className='overflow-y'>

                <div className="">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                    </div>
                    {/* <div className="-mt-3">
                        <Toolbar className="border-none" 
                         left={this.leftToolbarTemplate} 
                        right={this.rightToolbarTemplate}></Toolbar>
                    </div> */}
                </div>


                <div className="admin-management">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid">
                                {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} > */}
                                    <div className="cursor-pointer col-4" style={this.state.currentTab === 0 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                        <p  className="doubts-tabs tabPara"  onClick={() => { this.onTabChange(0) }}>Academic Session</p>
                                    </div>
                                {/* </Authorizer> */}
                                {/* <Authorizer permId={PERMISSIONS.SHOW_USERS_LIST} > */}
                                    <div className=" cursor-pointer col-4" style={this.state.currentTab === 1 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                        <p  className=" doubts-tabs tabPara"  onClick={() => { this.onTabChange(1) }}>Term</p>
                                    </div>
                                {/* </Authorizer> */}
                                {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} > */}
                                    <div className=" cursor-pointer col-4" style={this.state.currentTab === 2 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                        <p className="doubts-tabs tabPara"  onClick={() => { this.onTabChange(2) }}>Branch Master</p>
                                    </div>
                                {/* </Authorizer> */}
                                {/* <div className={`p-col-4 p-md-4 ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                                    <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>List</p>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    {
                        this.state.currentTab == 0 && <>
                            {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} > */}
                                < AcademicSession />
                            {/* </Authorizer> */}

                        </>
                    }
                    {
                        this.state.currentTab == 1 && <>
                            {/* <Authorizer permId={PERMISSIONS.SHOW_USERS_LIST} > */}
                                <AcademicTerms />
                            {/* </Authorizer> */}
                        </>
                    }
                    {
                        this.state.currentTab == 2 && <>
                            {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} > */}
                                <BranchMaster />
                            {/* </Authorizer> */}

                        </>
                    }
                </div>

            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, {getAcademicSessionsDropDowns,getBranchesLatest,getBoardsDropDowns})(Authentication(withRouter(SchoolSetupIndex)));