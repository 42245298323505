import React, { Component } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { PdfIcon, PptIcon, QuizIcon, VideoIcon } from '../../../svgIcons';

import { TreeTable } from 'primereact/treetable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';



export default class BranchWiseAccordian extends Component {
  constructor(props) {

    super(props);



  }


  dataRender(item) {
    return <div className='grid' style={{ marginTop: '-20px' }}>
      <div className='md:col-2'>
        <p className='level-name'>{item.name}</p>
      </div>
      <div className='md:col-2'>
        <p className='total-timespent'><span className='total-time-label'>Total Time Spent:</span>{item.totalTime}</p>
      </div>
      <div className='md:col-2'>
        <p className=''><span><VideoIcon height={26} width={26} color={'var(--primary-color)'} /></span><span className='content-label'>VIDEOS</span><span className='content-value'>{item.mp4}</span></p>
      </div>
      <div className='md:col-2'>
        <p className=''><span><PdfIcon height={26} width={26} color={'var(--primary-color)'} /></span><span className='content-label'>PDF</span><span className='content-value'>{item.pdf}</span></p>
      </div>
      <div className='md:col-2'>
        <p className=''><span><PptIcon height={26} width={26} color={'var(--primary-color)'} /></span><span className='content-label'>PPT</span><span className='content-value'>{item.zip}</span></p>
      </div>
      <div className='md:col-2'>
        <p className=''><span><QuizIcon height={26} width={26} color={'var(--primary-color)'} /></span><span className='content-label'>QUIZ</span><span className='content-value'>{item.quiz}</span></p>
      </div>

    </div>

  }




  render() {

    const { branchWiseAnalytics } = this.props;

    return (<div className='branchwise-acc ma-mt20'>
      {branchWiseAnalytics && branchWiseAnalytics.length ? <div className="card">
        <TreeTable
          // tableStyle={{ width: `${1000 + ((10) * 20)}px` }}
          className='branch-tree'
          scrollable="true"
          value={branchWiseAnalytics} >
          <Column field="name" header="Branch Wise Analytics  "
            headerStyle={{ display: 'none' }}
           // style={{ width: `${300 + ((10) * 20)}px` }}
            body={(options) => {
              return <>
                {this.dataRender(options)}
              </>

            }}

            expander


          ></Column>

        </TreeTable>
      </div> : <></>}

    </div>
    )
  }
}
