import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import Authentication from './../session';
import Service from '../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Toolbar } from 'primereact/toolbar';
// import Navbar from './../navbar/index';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import {
    getBoardsData,
} from './../../store/actions';
import './styles.scss'
import LoadingComponent from '../loadingComponent';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import SearchBar from './searchBar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import moment from 'moment';
import withRouter from '../lib/withRouter';

const items = [
    { label: 'Attendance' },
    { label: 'Give Attendance', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


const AbsentReasons = [
    {
        label: 'Informed',
        value: 'Informed'
    },
    {
        label: 'UnInformed',
        value: 'UnInformed'
    },
]

class GiveAttendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            groups: [],
            curriculumInfo: {},
            isShowBoardCreate: false,
            editBoardData: null,
            isBoardAdd: true,
            isShowBoards: true,
            isShowClasses: false,
            editChapterData: null,
            isShowExportToOffline: false,
            globalSearch: '',
            attendance: []

        }
        this.service = new Service();
    }




    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
    }







    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Attendance</h4>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }




    getStudentsAttendance = () => {
        // return

        this.setState({
            isLoading: true,
            attendance: []
        })


        let { filterData } = this.state;
        const url = `${baseUrlForLongRunningTask}/attendance/get?limit=100000&page=1`;
        this.service.post(url, { ...filterData, date: moment(filterData.date).format('YYYY-MM-DD') }, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    attendance: res.res.data,
                    totalRecords: res.res.totalRecords
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    onSearchClick = (filterData) => {

        //   console.log(filterData);

        this.setState({
            filterData
        }, this.getStudentsAttendance)



    }


    handleCheckBoxChange = (rowIndex, type) => (event) => {
        let attendance = this.state.attendance
        attendance[rowIndex][type] = event.target.checked;

        this.setState({
            attendance
        });

    };

    handleReasonChange = (rowIndex, type) => (event) => {
        let attendance = this.state.attendance
        attendance[rowIndex][type] = event.target.value;

        this.setState({
            attendance
        });

    };


    timingsTemplate = (rowData, { rowIndex }) => {
        return <div className=' my-1'>
            <div>
                <Checkbox inputId={'a' + rowIndex}
                    checked={rowData.morning}
                    onChange={this.handleCheckBoxChange(rowIndex, 'morning')}
                ></Checkbox>
                <label htmlFor={'a' + rowIndex} className="p-checkbox-label ml-1 p-name">Morning</label>
            </div>
            <div className=' my-1'>
                <Checkbox inputId={'ab' + rowIndex}
                    checked={rowData.afternoon}
                    onChange={this.handleCheckBoxChange(rowIndex, 'afternoon')}
                ></Checkbox>
                <label htmlFor={'ab' + rowIndex} className="p-checkbox-label ml-1 p-name">Afternoon</label>
            </div>
            <div>
                <p className='ma-label-s2'>Type<span className='ma-required'>*</span></p>
                <Dropdown value={rowData.reason}
                    options={AbsentReasons}
                    className=' ma-w200'
                    onChange={this.handleReasonChange(rowIndex, 'reason')}
                    placeholder="Select Type" />
            </div>
        </div>

    }

    onClearClick = () => {
        this.setState({
            attendance: [],
            totalRecords: 0
        })

    }

    saveAttendance = () => {

        let { attendance } = this.state;

        if (attendance && attendance.length) {
            this.setState({
                isLoading: true
            })



            const url = `${baseUrlForLongRunningTask}/attendance/save`;
            this.service.post(url, attendance, true).then(res => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Successfully saved.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })

        } else {
            this.toast.show({ severity: 'error', summary: 'No Attendance', detail: 'No Attendance to save', life: 3000 });
        }
    }



    render() {
        return (
            < >
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {/* {(this.state.isShowClasses) && <>
                                <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                            </>
                            } */}
                    </div>
                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb border-none" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>
                <div className='ma-main'>
                    <SearchBar
                        onClearClick={this.onClearClick}
                        filterData={this.state.filterData}
                        filterUsersData={this.onSearchClick}
                        searchLabel="Get Students"
                    />
                    <div className="card datatable-crud-demo  fetch-q-tabled ma-mt30">
                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            scrollable
                            value={this.state.attendance}
                            showGridlines
                            // paginatorLeft={() => {
                            //     return <>
                            //         <p className='avail-q'> Available Boards:  <span>{this.props.boards && this.props.boards.length}</span> </p>
                            //     </>
                            // }}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}

                            className="ma-table-d"
                            tableClassName='ma-table-tbl'
                            dataKey="id"
                            paginator
                            rows={5}
                            responsiveLayout="scroll"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        //header={header}
                        >
                            <Column headerClassName='' field="slNo" header="S.No" body={(rowData, { rowIndex }) => {
                                return <>
                                    {rowIndex + 1}
                                </>
                            }} />
                            <Column headerClassName='difficulty' field="username" body={(rowData) => {
                                return (<span> {rowData.username ? rowData.username.substring(3) : ''}</span>

                                )
                            }} header="User Name (for Login)" />
                            <Column headerClassName='difficulty' field="given_name" header="Given Name" />
                            <Column headerClassName='difficulty' field="boardName" header="Board Name" />
                            <Column headerClassName='difficulty' field="className" header="Class Name" />

                            <Column headerClassName='col-w240px' header="Timings" body={this.timingsTemplate} ></Column>

                        </DataTable>



                    </div>

                    <Toolbar className="border-none mt-4"
                        right={() => <>
                            <Button label='Save Attendance' className=''
                                onClick={this.saveAttendance}
                            /></>}

                    ></Toolbar>
                </div>


                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.props.isLoading || this.state.isLoading) && <>
                        <LoadingComponent />
                    </>
                }

            </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(GiveAttendance)));

//Authentication