import React from 'react'
import Chart from 'react-apexcharts';

function barcharts1() {


    const options = {
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4','Category 5'],
        },
        colors: ['#e05ec4'],
    };

    const series = [
        {
            name: 'Sample Data',
            data: [65, 59, 80, 81, 40],
        },
    ];

    const chartContainerStyle = {
       
      };
    return (
        <>

            <div  style={{width:"280px",height:'300px',marginTop:"30px"}}>
                {/* <h2>Bar Chart </h2> */}
                <Chart options={options} series={series} type='bar'   />
            </div>
        </>
    )
}

export default barcharts1