import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import FileUploadLambda from '../fileUpload/fileUploadLambda';
import { InputTextarea } from 'primereact/inputtextarea';
import { Steps } from 'primereact/steps';
import CkEditorComponent from '../questionBank/common/ckEditor';
import React, { Component } from 'react'
import { getServerTime, trimObj, warningDailogInit } from '../../utile';
import Service from './../../services';
import { getFormFields, isFieldValid, isFormValid, onChoiceChange, onDropDownChange, onTextChange } from '../../utile/formHelper';
import InputTextB from '../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import { FIELDS_INFO } from '../../constants';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import AttachPreviewDelete from '../questionBank/common/attachPreviewDelete';
import {
    getImageUrl
} from './../../store/actions';
import { Button } from 'primereact/button';
import AssignNotification from './assignNotification';
import reactStringReplace from 'react-string-replace';
import { baseUrlAdmin } from '../../store/apiConstants';

const NOTIFICATION_MODES = {
    SMS: 'sms',
    ALERT: 'alert',
    EMAIL: 'email',
    WHATS_APP: 'whatsapp'
}

const NotificationTypes = [{ label: 'MockTest Report', value: 'MockTest Report' }, { label: 'Calendar', value: 'Calendar' }]
const ScheduleTypes = [{ label: 'Now', value: 'now' },
    // { label: 'Schedule', value: 'schedule' }
]




var formFields = require('./createNotification.json');



const items = [

    { label: 'Notification', className: 'start-1' },
    { label: 'Assign', className: 'end-1' },
];


class CreateNotification extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editNotification || {});

        this.state = {
            notification: this.formFields.data,
            formValidations: this.formFields.formValidations,
            warningDialog: warningDailogInit,
            totalRecords: 0,
            globalSearch: '',
            currentStep: 0,
            filterData: null,
            textMsgs: {},
            smsTemplates: [],
            whatsAppTemplates: [],
            isTemplatesRequested: false,
            smsTemplateContent: "",
            whatsAppTemplateBody: ""

        }


        this.service = new Service();

    }

    getTemplates = () => {
        this.setState({
            isLoading: true,
            smsTemplates: [],
        });
        let { lazyParams, globalSearch } = this.state;


        let url = `${baseUrlAdmin}/smstemplate/all`;
        this.service.get(url, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        smsTemplates: res.res.data,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    getWhatsAppTemplates = () => {
        this.setState({
            isLoading: true,
            whatsAppTemplates: [],
        });
        let { lazyParams, globalSearch } = this.state;


        let url = `${baseUrlAdmin}/whatsapptemplate/all`;
        this.service.get(url, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        whatsAppTemplates: res.res.data,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }


    componentDidMount() {
        this.getTemplates();
        this.getWhatsAppTemplates();

    }


    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    addAttachment = (fieldName, fileName, data) => {

        let notification = {
            ...this.state.notification,
            [fieldName]: data.imageStorageKey
        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, notification);


        this.setState((prevState) => {
            return {
                notification,
                formValidations
            }
        });
    }


    onScheduleChange = (fieldName, data) => {

        let notification = {
            ...this.state.notification,
            [fieldName]: data,
            scheduleTime: new Date().toISOString()
        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, notification);


        this.setState((prevState) => {
            return {
                notification,
                formValidations
            }
        });
    }

    onMessageTemplateChange = (fieldName, data) => {

        let smsTemplateContent = this.state.smsTemplates.find(a => a._id == data).content

        let notification = {
            ...this.state.notification,
            [fieldName]: data,

        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, notification);


        this.setState((prevState) => {
            return {
                notification,
                formValidations,
                smsTemplateContent
            }
        });
    }


    formatWhatsAppTemplateTxt = (str) => {
        let result;
        let i = 100;
        do {
            result = str.lastIndexOf(`{{${i}}}`);
            if (result >= 0) {
                str = str.slice(0, result) + '{#var#}' + str.slice(result + 5);
            }
            i--;
        } while (i > 0);
        return str;
    }

    onWhatsAppTemplateChange = (fieldName, data) => {

        let template = this.state.whatsAppTemplates.find(a => a._id == data);
        let whatsAppTemplateBody = template.body

        whatsAppTemplateBody = this.formatWhatsAppTemplateTxt(whatsAppTemplateBody)

        let notification = {
            ...this.state.notification,
            [fieldName]: data,
            whatsAppTemplateName: template.name

        }

        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, notification);


        this.setState((prevState) => {
            return {
                notification,
                formValidations,
                whatsAppTemplateBody
            }
        });
    }



    _afterFilesUploaded = (files) => {

    }

    _onAttachSuccess = (fieldName) => (fileName, data) => {


        this.props.getImageUrl(data.imageStorageKey);
        this.addAttachment(fieldName, fileName, data);

    }


    removeAttach = (fieldName) => {
        this.setState((prevState) => {
            return {
                questionInfo: {
                    ...prevState.questionInfo,
                    [fieldName]: ''
                }
            };
        });

    }

    updateFields = (requiredFields, notRequiredFields) => {
        let { notification } = this.state;

        formFields = formFields.map(field => {
            if (requiredFields.includes(field.FieldName)) {

                field.Required = true;
            }

            if (notRequiredFields.includes(field.FieldName)) {
                notification[field.FieldName] = '';

                field.Required = undefined;
            }

            return field;
        });
        this.formFields = getFormFields(formFields, this.state.org);

        this.setState({
            notification,
            formValidations: this.formFields.formValidations,
            textMsgs: {},
            smsTemplateContent: "",
            whatsAppTemplateBody: ""
        })
    }

    addStr(str, index, stringToAdd) {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    }

    getSMSString = (str, txtEnteredMsgs) => {

        // var str = 'Dear Parent, This is to inform you that your ward {#var#}., is absent for {#var#}. out of {#var#}. conducted on {#var#}.. {#var#}.';


        let textMsgs = {
            // "50": "1st",
            // "74": "2nd",
            // "90": "3rd",
            // "112": "4th",
            // "122": "5th"
        }

        // for (var i = 0; i < str.length; i++) {
        //     if (str[i] === "{#var#}") {
        //         textMsgs[i] = ''
        //     }
        // }

        var regex = /{#var#}/gi, result, indices = [];
        while ((result = regex.exec(str))) {
            //  indices.push(result.index);
            textMsgs[result.index] = ''
        }


        textMsgs = { ...textMsgs, ...txtEnteredMsgs }


        Object.keys(textMsgs).forEach(a => {
            let result = str.lastIndexOf('{#var#}')
            str = str.slice(0, result) + str.slice(result + 7);
            str = this.addStr(str, result, textMsgs[`${result}`])
        });

        // console.log(str)

        return str;

    }


    getParamsForWhatsApp = (str, txtEnteredMsgs) => {

        // var str = 'Dear Parent, This is to inform you that your ward {#var#}., is absent for {#var#}. out of {#var#}. conducted on {#var#}.. {#var#}.';


        let textMsgs = {
            // "50": "1st",
            // "74": "2nd",
            // "90": "3rd",
            // "112": "4th",
            // "122": "5th"
        }

        // for (var i = 0; i < str.length; i++) {
        //     if (str[i] === "{#var#}") {
        //         textMsgs[i] = ''
        //     }
        // }

        var regex = /{#var#}/gi, result, indices = [];
        while ((result = regex.exec(str))) {
            //  indices.push(result.index);
            textMsgs[result.index] = ''
        }


        textMsgs = { ...textMsgs, ...txtEnteredMsgs }

        let params = [];
        Object.keys(textMsgs).forEach(a => {
            params.push(textMsgs[a]);
        });

        // console.log(str)

        return params;

    }


    onSubmitClick = () => {

        const { currentStep, notification, smsTemplateContent, whatsAppTemplateBody, textMsgs } = this.state;

        let _notification = notification;

        if (_notification.mode == NOTIFICATION_MODES.SMS) {
            if (smsTemplateContent && smsTemplateContent.length) {
                _notification.message = this.getSMSString(smsTemplateContent, textMsgs);
            }
        }

        if (_notification.mode == NOTIFICATION_MODES.WHATS_APP) {
            if (whatsAppTemplateBody && whatsAppTemplateBody.length) {
                _notification.params = this.getParamsForWhatsApp(whatsAppTemplateBody, textMsgs);
            }
        }

        if (currentStep == 0) {
            const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(_notification));

            if (!formStatus.formValidations.isFormValid) {
                this.setState({
                    isSubmitClick: true,
                    formValidations: formStatus.formValidations,
                });
            } else {

                if (_notification.mode == NOTIFICATION_MODES.WHATS_APP) {
                    if (_notification.params && _notification.params.length) {
                        let isValid = true;
                        _notification.params.forEach(param => {
                            if (!(param || param.length)) {
                                isValid = false
                            }
                        });

                        if (!isValid) {
                            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please complete the message', life: 3000 });
                        }

                    }
                }

                this.setState({
                    currentStep: 1
                });
            }
        } else if (currentStep == 1) {



            let isValid = true;

            if (isValid) {
                alert('')
            }

        }

    }

    onBackClick = () => {
        if (this.state.currentStep == 0) {
            this.props.onCreateCancel();
        } else {
            this.setState((prevState) => {
                return { currentStep: prevState.currentStep - 1 }
            });
        }

    }


    onTxtMsgFieldChange = (position, value) => {
        this.setState((prevState) => {
            return {
                textMsgs: {
                    ...prevState.textMsgs,
                    [position]: value
                }
            }
        });
    }


    render() {
        const { currentStep, notification, formValidations, textMsgs } = this.state;


        //  console.log(this.props.orgData, 'abc')
        return (
            <div className='create-notification bg-white border-round'>
                <div className="ma-box-style2 create-org-w">
                    <span className='create-org-title'>{this.props.editOrganization ? 'Edit' : 'Create'} Notification</span>
                    <Steps
                        className='org-steps'
                        model={items} activeIndex={currentStep}
                        readOnly={false}
                    />

                    <div className='ma-clearFix'>

                    </div>
                    {currentStep == 0 && <>
                        <p className='craete-org-subtitle'>Notification Details</p>
                        <hr className="ma-hr-light" />
                        <div className="grid  col-12  " >
                            <div className="col-3 md-3">
                                <p className='ma-label-s2'>Name<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.NOTIFICATION_NAME} id="kexpT">
                                    <InputText value={notification.name}
                                        onChange={(e) => { onTextChange(e.target.value, 'name', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                        className='p-inputtext-style1 w-full' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                            </div>
                            <div className="col-3 p-md-3">
                                <p className='ma-label-s2'>Notification Type<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.NOTIFICATION_TYPE} id="lexpT">
                                    <Dropdown value={notification.type}
                                        className='w-full'
                                        options={NotificationTypes}
                                        onChange={(e) => {
                                            onDropDownChange(e.value, 'type', this, formFields, notification, formValidations, 'notification', 'formValidations')
                                        }}
                                        placeholder="Select Notification Type" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['type'].isValid && <p className="p-error">{formValidations.fields['type'].errorMsg}</p>}
                            </div>

                            <div className="col-3 p-md-3">
                                <p className='ma-label-s2'>Schedule<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.NOTIFICATION_SCHEDULE} id="lexpT">
                                    <Dropdown value={notification.schedule}
                                        className='w-full'
                                        options={ScheduleTypes}
                                        onChange={(e) => {
                                            this.onScheduleChange('schedule', e.value)
                                        }}
                                        placeholder="Select Schedule Type" />
                                </InputTextB>
                                {formValidations && !formValidations.fields['schedule'].isValid && <p className="p-error">{formValidations.fields['schedule'].errorMsg}</p>}
                            </div>
                            {
                                notification.schedule == 'schedule' && <>

                                    <div className="col-3 p-md-3">
                                        <p className='ma-label-s2'>Date and Time<span className='ma-required'>*</span></p>
                                        <InputTextB info={FIELDS_INFO.DATEANDTIME} id="lexpeT">
                                            <Calendar
                                                value={notification.scheduleTime ? new Date(notification.scheduleTime) : null}
                                                showIcon={true}
                                                placeholder='Start Date'
                                                id='fromDates'
                                                showTime
                                                minDate={getServerTime()}
                                                onChange={(e) => onTextChange(e.value.toISOString(), 'scheduleTime', this, formFields, notification, formValidations, 'notification', 'formValidations')}
                                                className='w-full'
                                            ></Calendar>
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['scheduleTime'].isValid && <p className="p-error">{formValidations.fields['scheduleTime'].errorMsg}</p>}
                                    </div>
                                </>
                            }
                        </div>
                        <div className="grid ma-w100p " >
                            <div className="col-12 p-md-3 ml-3">
                                <p className='ma-label-s2'>Notification Mode<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.NOTIFICATION_MODE} id="lexpT">
                                    <div className='col-4 flex gap-3 -ml-3'>
                                        <div>
                                            <RadioButton className="radio-inline" value={NOTIFICATION_MODES.ALERT} inputid="coyes" name="mode"
                                                onChange={(e) => {
                                                    onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {
                                                        this.updateFields(['title', 'description'], ['whatsAppTemplateId', 'message', 'subject', 'body', 'smsTemplateId'])

                                                    });

                                                }}
                                                checked={notification.mode === NOTIFICATION_MODES.ALERT} />
                                            <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.ALERT}>Alert</label>
                                        </div>
                                        <div>
                                            <RadioButton className="radio-inline ma-ml10" value={NOTIFICATION_MODES.SMS} inputid="cono" name="mode"
                                                onChange={(e) => {
                                                    onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {
                                                        this.updateFields(['message', 'smsTemplateId'], ['whatsAppTemplateId', 'title', 'description', 'subject', 'body'])
                                                    })

                                                }}
                                                checked={notification.mode === NOTIFICATION_MODES.SMS} />
                                            <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.SMS}>SMS</label>
                                        </div>
                                        <div>
                                            <RadioButton className="radio-inline ma-ml10" value={NOTIFICATION_MODES.EMAIL} inputid="cono" name="mode"
                                                onChange={(e) => {
                                                    onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {
                                                        this.updateFields(['subject', 'body',], ['whatsAppTemplateId', 'title', 'description', 'message', 'smsTemplateId'])
                                                    })

                                                }}
                                                checked={notification.mode === NOTIFICATION_MODES.EMAIL} />
                                            <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.EMAIL}>Email</label>
                                        </div>
                                        <div>
                                            <RadioButton className="radio-inline ma-ml10" value={NOTIFICATION_MODES.WHATS_APP} inputid="cono" name="mode"
                                                onChange={(e) => {
                                                    onChoiceChange(e.value, 'mode', this, formFields, notification, formValidations, 'notification', 'formValidations', () => {

                                                        this.updateFields(['whatsAppTemplateId'], ['body', 'title', 'description', 'message', 'smsTemplateId', 'subject'])
                                                    });
                                                }}

                                                checked={notification.mode === NOTIFICATION_MODES.WHATS_APP} />
                                            <label className='ma-label-s1' htmlFor={NOTIFICATION_MODES.WHATS_APP}>WhatsApp</label>
                                        </div>
                                    </div>
                                </InputTextB>
                                {formValidations && !formValidations.fields['mode'].isValid && <p className="p-error">{formValidations.fields['mode'].errorMsg}</p>}
                            </div>
                        </div>

                        <div>
                            {notification.mode == NOTIFICATION_MODES.ALERT && <>
                                <div className='grid'>
                                    <div className='col-12 p-md-6 p-lg-6'>
                                        <div className='col-6'>
                                            <p className='ma-label-s2'>Title<span className='ma-required'>*</span></p>
                                            <InputTextB info={FIELDS_INFO.NOTIFICATION_TITLE} id="kexpT">
                                                <InputText value={notification.title}
                                                    onChange={(e) => { onTextChange(e.target.value, 'title', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                    className='p-inputtext-style1 w-full' />
                                            </InputTextB>
                                            {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                                        </div>
                                        <div className='col-6'>
                                            <p className='ma-label-s2'>Description<span className='ma-required'>*</span></p>
                                            <InputTextB info={FIELDS_INFO.NOTIFICATION_DESCRIPTION} id="kexpT">
                                                <InputTextarea
                                                    rows={4}
                                                    value={notification.description}
                                                    onChange={(e) => { onTextChange(e.target.value, 'description', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                    className='p-inputtext-style1 w-full' />
                                            </InputTextB>
                                            {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                                        </div>
                                        <div className='col-2'>
                                            <FileUploadLambda id={'notifatt'}
                                                multiple={false}
                                                onProgress={this._onProgress}
                                                onSucess={(fileName, data) => this._onAttachSuccess('attachment')(fileName, data)}
                                                maxFileSize={307200}
                                                onError={this._onFilsUploadError}
                                                accept={'images'}
                                                title={'Images'}
                                                dirName=''
                                                afterFilesUploaded={this._afterFilesUploaded}
                                            >
                                                <label className='ma-label-s2' htmlFor={'notifatt'}>
                                                    Attachment <i className='pi pi-file' />
                                                </label>
                                            </FileUploadLambda>
                                            <div className='ma-clearFix'>
                                            </div>
                                            {this.props.getAllImagesUrlInfo[notification['attachment']] &&
                                                <AttachPreviewDelete
                                                    imagePath={this.props.getAllImagesUrlInfo[notification['attachment']]}
                                                    removeAttach={() => { this.removeAttach('attachment') }}

                                                />}
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {notification.mode == NOTIFICATION_MODES.SMS && <>
                                {
                                    this.props.smsConfig ? <>

                                        <div className='grid '>
                                            <div className='col-12 p-md-6 p-lg-6'>
                                                <div className='col-6'>
                                                    <p className='ma-label-s2'>Message Template<span className='ma-required'>*</span></p>
                                                    <InputTextB info={FIELDS_INFO.NOTIFICATION_SCHEDULE} id="lexpT">
                                                        <Dropdown value={notification.smsTemplateId}
                                                            className='w-full'
                                                            options={this.state.smsTemplates}
                                                            onChange={(e) => {
                                                                this.onMessageTemplateChange('smsTemplateId', e.value)
                                                            }}
                                                            optionLabel='name'
                                                            optionValue='_id'
                                                            placeholder="Select Template" />
                                                    </InputTextB>
                                                    {formValidations && !formValidations.fields['smsTemplateId'].isValid && <p className="p-error">{formValidations.fields['smsTemplateId'].errorMsg}</p>}
                                                </div>


                                            </div>
                                        </div>
                                        {
                                            notification.smsTemplateId && <div className='grid'>
                                                <div className='col-12 p-md-6 p-lg-6'>
                                                    <div className='col-6 '>
                                                        <p className='ma-label-s2'>Message<span className='ma-required'>*</span></p>
                                                        <div className='sms-template-txt card'>
                                                            {reactStringReplace(this.state.smsTemplateContent, '{#var#}', (match, i, offset) => (
                                                                <InputText value={textMsgs[offset] ? textMsgs[offset] : ''} onChange={(e) => { this.onTxtMsgFieldChange(offset, e.target.value) }} className='p-inputtext-style1' />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {formValidations && !formValidations.fields['message'].isValid && <p className="p-error">{formValidations.fields['message'].errorMsg}</p>}
                                    </> : <>
                                        <p className='ma-danger '>  SMS Configurations are not added. please contact MAcademy.</p>
                                    </>}
                            </>}
                            {notification.mode == NOTIFICATION_MODES.EMAIL && <>
                                {
                                    this.props.emailConfig ? <>
                                        <div className='grid'>
                                            <div className='col-12 p-md-6 p-lg-6'>
                                                <div className='col-6'>
                                                    <p className='ma-label-s2'>Subject<span className='ma-required'>*</span></p>
                                                    <InputTextB info={FIELDS_INFO.NOTIFICATION_TITLE} id="kexpT">
                                                        <InputText value={notification.subject}
                                                            onChange={(e) => { onTextChange(e.target.value, 'subject', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                            className='p-inputtext-style1 w-full' />
                                                    </InputTextB>
                                                    {formValidations && !formValidations.fields['subject'].isValid && <p className="p-error">{formValidations.fields['subject'].errorMsg}</p>}
                                                </div>
                                                <div className='col-6'>
                                                    <p className='ma-label-s2'>Message Body<span className='ma-required'>*</span></p>
                                                    <InputTextB info={FIELDS_INFO.NOTIFICATION_DESCRIPTION} id="kexpT">
                                                        {/* <InputTextarea
                                                    rows={4}
                                                    value={notification.body}
                                                    onChange={(e) => { onTextChange(e.target.value, 'body', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                    className='p-inputtext-style1 ma-w100p' /> */}

                                                        <CkEditorComponent
                                                            value={notification.body}
                                                            onChange={(value) => { onTextChange(value, 'body', this, formFields, notification, formValidations, 'notification', 'formValidations') }}
                                                        />
                                                    </InputTextB>
                                                    {formValidations && !formValidations.fields['body'].isValid && <p className="p-error">{formValidations.fields['body'].errorMsg}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </> : <>
                                        <p className='ma-danger '>  SMTP Configurations are not added. please contact MAcademy.</p>
                                    </>
                                }
                            </>}
                            {notification.mode == NOTIFICATION_MODES.WHATS_APP && <>
                                {
                                    this.props.whatsAppConfig ? <>

                                        <div className='grid'>
                                            <div className='col-12 p-md-6 p-lg-6'>
                                                <div className='col-6'>
                                                    <p className='ma-label-s2'>Message Template<span className='ma-required'>*</span></p>
                                                    <InputTextB info={FIELDS_INFO.WHATSAPP_TEMPLATE_NAME} id="lexpT">
                                                        <Dropdown value={notification.whatsAppTemplateId}
                                                            className='w-full'
                                                            options={this.state.whatsAppTemplates}
                                                            onChange={(e) => {
                                                                this.onWhatsAppTemplateChange('whatsAppTemplateId', e.value)
                                                            }}
                                                            optionLabel='name'
                                                            optionValue='_id'
                                                            placeholder="Select Template" />
                                                    </InputTextB>
                                                    {formValidations && !formValidations.fields['whatsAppTemplateId'].isValid && <p className="p-error">{formValidations.fields['whatsAppTemplateId'].errorMsg}</p>}
                                                </div>


                                            </div>
                                        </div>
                                        {
                                            notification.whatsAppTemplateId && <div className='grid'>
                                                <div className='col-12 p-md-6 p-lg-6'>
                                                    <div className='col-4'>
                                                        <p className='ma-label-s2'>Message<span className='ma-required'>*</span></p>
                                                        <div className='sms-template-txt card'>
                                                            {reactStringReplace(this.state.whatsAppTemplateBody, '{#var#}', (match, i, offset) => (
                                                                <InputText value={textMsgs[offset] ? textMsgs[offset] : ''} onChange={(e) => { this.onTxtMsgFieldChange(offset, e.target.value) }} className='p-inputtext-style1' />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {formValidations && !formValidations.fields['message'].isValid && <p className="p-error">{formValidations.fields['message'].errorMsg}</p>}
                                    </> : <>
                                        <p className='ma-danger '>WhatsApp Configurations are not added. please contact MAcademy.</p>
                                    </>}
                            </>}
                        </div>

                        <Button label={'Proceed and Next'} className='ma-mt10' onClick={this.onSubmitClick} />
                    </>}
                    {
                        currentStep == 1 && <>
                            <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-secondary p-button-outlined mb-3" aria-label="Back" onClick={() => {
                                this.setState({
                                    currentStep: 0
                                })
                            }} />
                            <AssignNotification examToAssign={this.state.examToAssign} onCancel={() => {
                                this.setState({
                                    isShowAssignDialog: false
                                })
                            }}
                                onAssignSuccess={this.props.onAssignSuccess}

                                notification={this.state.notification}

                            />
                        </>}

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

                {/* <div>
                    {reactStringReplace(str, '{#var#}', (match, i, offset) => (
                        <InputText value={textMsgs[offset] ? textMsgs[offset] : ''} onChange={(e) => { this.onTxtMsgFieldChange(offset, e.target.value) }} className='p-inputtext-style1' />
                    ))}
                </div> */}
            </div >
        )
    }
}




const mapStateToProps = (state) => ({
    getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},
    emailConfig: state.orgData.emailConfig,
    smsConfig: state.orgData.smsConfig,
    whatsAppConfig: state.orgData.whatsAppConfig,
    orgData: state.orgData
});

const mapDispatchToProps = (dispatch) => ({
    getImageUrl: (url) => dispatch(getImageUrl(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotification);