import { Tooltip } from 'primereact/tooltip';


function InputTextB(props) {
    return (
        <>
            {!props.isHide ? <div style={{ display: 'flex'}}>
                {props.children}
                <i style={{ float: 'left' }} className={`pi pi-info-circle ma-info-i toolId${props.id} my-auto ml-1`} data-pr-tooltip={props.info} data-pr-position="bottom"  ></i>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.toolId${props.id}`} />
            </div> : <><div style={{ display: 'flex' }}> {props.children} </div></>}

        </>
    );
}



export default InputTextB;