import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import SvgViewer from '../../customComponents/svgViewer';
import LoadingComponent from '../../loadingComponent';
import CreateAdmissionType from './createAdmissionType';



const items = [
    { label: 'Organization' },
    { label: 'Admission Type', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

export default class AdmissionType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showCreateAdmissionType: false,
            admissionTypeData: [],
            globalFilter: null,
            showSearch: false,
            columns: [{ columnName: '', isVisible: false, id: '' }]
        }

        this.service = new Service()
    }


    componentDidMount() {
        this.getAdmissionTypes()
    }

    getAdmissionTypes = () => {

        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admission-types`;
        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                console.log(res, 'res');
                this.setState({
                    isLoading: false,
                    admissionTypeData: res?.res?.data
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            console.log(err, 'err')
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Admission Types</h4>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    showEditRoleDialog = (editData) => {

        this.setState({
            editData,
            showCreateAdmissionType: true,
        });
    }

    editTemplate = (rowData, { rowIndex }) => {
        return (<>
            <div className="p-d-flex ma-mlr10 ">
                <span data-pr-tooltip="Edit Role" data-pr-position="bottom" className={`ma-ml10 ar${rowIndex}`} onClick={() => this.showEditRoleDialog(rowData)} >
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                </span>
            </div>
        </>
        );
    }

    onClose = () => {
        this.setState({
            showCreateAdmissionType: false, editData: null
        })
    }

    onSaveOrUpdate = (isUpdate) => {
        this.toast.show({ severity: 'success', summary: 'Saved', detail: `Admission Type ${isUpdate ? 'updated' : 'created'} successfuly`, life: 3000 });
        this.setState({
            showCreateAdmissionType: false, editData: null
        }, this.getAdmissionTypes)
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    render() {

        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Admission Types</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4 h-2rem w-15rem" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <i data-pr-tooltip="View Columns"
                            onClick={(event) => this.menu.toggle(event)}
                            data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" /> */}
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4>View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <div>
                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>
                <div className='ma-main'>
                    <Button label="+ Add Admission Type" className='p-button-raised my-3' onClick={() => this.setState({ showCreateAdmissionType: true })} />


                    <div className='card datatable-crud-demo ma-m30'>
                        <DataTable ref={(el) => this.dt = el}

                            value={this.state.admissionTypeData}
                            dataKey="_id"
                            paginator
                            rows={10}
                            showGridlines
                            responsiveLayout="scroll"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            header={header}
                        >
                            <Column field="admissionType" header="Admission Type" />
                            <Column field="admissionCode" header="Admission Code" />
                            <Column field="desciption" header="Description" />
                            <Column header="Actions" body={this.editTemplate} ></Column>
                        </DataTable>
                        {this.state.showCreateAdmissionType &&
                            <CreateAdmissionType
                                editData={this.state.editData}
                                visible={this.state.showCreateAdmissionType}
                                onClose={this.onClose}
                                onSaveOrUpdate={this.onSaveOrUpdate}
                            />
                        }
                    </div>

                </div>


                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </>

        )
    }
}
