import React, { Component } from 'react';
import { discussionBoardEndpoint } from './../../../store/apiConstants';
import Service from './../service';
import { getProfileURL } from './../../../utile';
import { NotificationIcon } from '../../svgIcons';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: '-createdAt',
            limit: 10,
            page: 1,
            search: '',
            notfications: [],
            stopRequests: false,
            isLoading: false,
            currentScrollPosition: 0
        }
        this.service = new Service();
    }

    getNotifications = (sort, limit, page, search, isSearch) => {

        this.setState({
            isLoading: true,
            notfications: []
        });
        const url = `${discussionBoardEndpoint}/notifications/all?sort=${sort}&limit=${limit}&page=${page}&search=${search}`
        this.service.get(url, true).then(data => {
            let re = [];
            if (data && data.status) {
                if (data.res.data.length > 0) {
                    re = re.concat(data.res.data)
                    this.setState((prevState) => {
                        return {
                            notfications: re,
                            currentScrollPosition: window.scrollY,
                            page: data.res.page + 1,
                            isLoading: false,
                        }
                    });
                } else {
                    this.setState({ isLoading: false, notfications: re });
                }
            } else {
                this.setState({ isLoading: false, notfications: re });
            }
        });
    }

    componentDidMount() {
        this.getNotifications(this.state.sort, this.state.limit, this.state.page, this.state.search);
    }

    render() {
        return (
            <>
                <div className="ma-box notifications-c">
                    <div className="grid notifi-header">
                        <div className='col-1'>
                            <NotificationIcon  width={20} height={20} />
                            {/* <img src="./images/Notification.svg" className="notifi-img" /> */}
                        </div>
                        <div className='col-10'>
                            <h5 className="">Notifications</h5>
                        </div>

                    </div>
                    <div className="notifi-c">
                        {this.state.notfications.map((n, io) => {
                            return <div key={io} style={{ width: '100%', overflow: 'hidden', margin: '8px' }}>
                                <div style={{ width: '40px', float: 'left' }}>
                                    <img src={getProfileURL(n?._id?.photo)} alt="n" className="profile-img" />
                                </div>
                                <div style={{ marginLeft: '40px' }}>
                                    <p className="">
                                        {n._id.userName} {n.total > 1 ? `and ${n.total - 1} ${n.total > 2 ? 'others' : 'other'} ` : ''} {n._id.title}
                                    </p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </>
        )
    }
}

export default Notifications;