import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import Authentication from './../session';
import { connect } from 'react-redux';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';


class BulkFeeReciepts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reciept: [
                {
                    "_id": "669a07bbe683a49f6add31f8",
                    "feePaidDetails": [
                        {
                            "feeType": "Tution Fee",
                            "term": 1,
                            "termWiseActualValue": 12500,
                            "feeTypeId": "6687819ffe42641e5f99ec30",
                            "amountPaying": 12500,
                            "gstPaid": 375,
                            "gstPaidNow": 375,
                            "gst": 375,
                            "amountPaid": 12500,
                            "balance": 0
                        },
                        {
                            "feeType": "Hostel Fee",
                            "term": 1,
                            "termWiseActualValue": 25000,
                            "feeTypeId": "668786afdccd2ab3c90ec739",
                            "amountPaid": 2063.11,
                            "amountPaying": 2063.11,
                            "gstPaidNow": 61.89,
                            "gst": 750,
                            "gstPaid": 61.89,
                            "balance": 22936.89
                        }
                    ],
                    "amountPaid": 15000,
                    "given_name": "madhu",
                    "username": "madhu",
                    "recieptDate": "2024-07-19T06:29:14.840Z",
                    "className": "ICSC G1",
                    "academicCode": "2024 - 2025",
                    "paymentMode": "Cash",
                    "user_id": "2153ddba-a061-7066-c9a3-8feff59928f2",
                    "destinationBank": "666802aada32c9482d9d5dfb",
                    "academicYear": "6656cebd3612f3cbe3dc301d",
                    "createdAt": "2024-07-19T06:29:15.392Z",
                    "updatedAt": "2024-07-19T06:29:15.392Z",
                    "__v": 0
                },
                {
                    "_id": "669a083c3e6dab5df8c9f73b",
                    "feePaidDetails": [
                        {
                            "feeType": "Hostel Fee",
                            "term": 1,
                            "termWiseActualValue": 25000,
                            "feeTypeId": "668786afdccd2ab3c90ec739",
                            "amountPaid": 11771.85,
                            "amountPaying": 9708.74,
                            "gstPaidNow": 291.26,
                            "gst": 750,
                            "gstPaid": 353.15,
                            "balance": 13228.15
                        }
                    ],
                    "amountPaid": 10000,
                    "given_name": "madhu",
                    "username": "madhu",
                    "recieptDate": "2024-07-19T06:31:24.006Z",
                    "className": "ICSC G1",
                    "academicCode": "2024 - 2025",
                    "paymentMode": "Cash",
                    "user_id": "2153ddba-a061-7066-c9a3-8feff59928f2",
                    "destinationBank": "666802aada32c9482d9d5dfb",
                    "academicYear": "6656cebd3612f3cbe3dc301d",
                    "createdAt": "2024-07-19T06:31:24.544Z",
                    "updatedAt": "2024-07-19T06:31:24.544Z",
                    "__v": 0
                },
                {
                    "_id": "669a08b23e6dab5df8c9f760",
                    "feePaidDetails": [
                        {
                            "feeType": "Hostel Fee",
                            "term": 1,
                            "termWiseActualValue": 25000,
                            "feeTypeId": "668786afdccd2ab3c90ec739",
                            "amountPaying": 13228.15,
                            "gstPaid": 750,
                            "gstPaidNow": 396.85,
                            "gst": 396.85,
                            "amountPaid": 25000,
                            "balance": 0
                        },
                        {
                            "feeType": "Uniform Fee",
                            "term": 1,
                            "termWiseActualValue": 7500,
                            "feeTypeId": "66878800144df2d045083441",
                            "amountPaying": 7500,
                            "gstPaid": 225,
                            "gstPaidNow": 225,
                            "gst": 225,
                            "amountPaid": 7500,
                            "balance": 0
                        },
                        {
                            "feeType": "Food Fee",
                            "term": 1,
                            "termWiseActualValue": 22500,
                            "feeTypeId": "6687872273e322b0a38e6120",
                            "amountPaying": 22500,
                            "amountPaid": 22500,
                            "balance": 0
                        },
                        {
                            "feeType": "Dhobi Fee",
                            "term": 1,
                            "termWiseActualValue": 15000,
                            "feeTypeId": "66878512313ac10ea4d7ef8d",
                            "amountPaying": 15000,
                            "gstPaid": 450,
                            "gstPaidNow": 450,
                            "gst": 450,
                            "amountPaid": 15000,
                            "balance": 0
                        },
                        {
                            "feeType": "Material Fee",
                            "term": 1,
                            "termWiseActualValue": 15000,
                            "feeTypeId": "66878443313ac10ea4d7ef21",
                            "amountPaying": 15000,
                            "gstPaid": 450,
                            "gstPaidNow": 450,
                            "gst": 450,
                            "amountPaid": 15000,
                            "balance": 0
                        },
                        {
                            "feeType": "Caution Fee",
                            "term": 1,
                            "termWiseActualValue": 15000,
                            "feeTypeId": "66878401313ac10ea4d7eef2",
                            "amountPaying": 15000,
                            "gstPaid": 450,
                            "gstPaidNow": 450,
                            "gst": 450,
                            "amountPaid": 15000,
                            "balance": 0
                        },
                        {
                            "feeType": "Transport Fee",
                            "term": 1,
                            "termWiseActualValue": 25000,
                            "feeTypeId": "668781f3d193ad327d23e449",
                            "amountPaying": 25000,
                            "gstPaid": 750,
                            "gstPaidNow": 750,
                            "gst": 750,
                            "amountPaid": 25000,
                            "balance": 0
                        }
                    ],
                    "amountPaid": 150000,
                    "given_name": "madhu",
                    "username": "madhu",
                    "recieptDate": "2024-07-19T06:33:21.741Z",
                    "className": "ICSC G1",
                    "academicCode": "2024 - 2025",
                    "paymentMode": "Cash",
                    "user_id": "2153ddba-a061-7066-c9a3-8feff59928f2",
                    "destinationBank": "666802aada32c9482d9d5dfb",
                    "academicYear": "6656cebd3612f3cbe3dc301d",
                    "createdAt": "2024-07-19T06:33:22.262Z",
                    "updatedAt": "2024-07-19T06:33:22.262Z",
                    "__v": 0
                }
            ]

        }
    }
    render() {
        const { receiptData } = this.props;
        debugger
        const { logoRectangle, orgName, orgAddress, mobileOperational } = this.props;
        console.log(this.props, 'recieptnew')
        // let receiptData = this.state.reciept
        return (
            <>
                <Dialog
                    style={{ width: '80%' }}
                    header='Term Fee Reciepts'
                    modal
                    footer={() => {
                        return <>
                            <ReactToPrint
                                pageStyle="padding:'10px'"
                                content={() => this.componentRef}>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <>  <Button data-pr-position="bottom"
                                            data-pr-tooltip="Download "
                                            onClick={handlePrint}
                                            className='print-button ma-mr20 p-ml-10 p-mb-4'
                                            label='Print'
                                        ></Button>
                                            <Tooltip className="table-li-tooltip" target=".egraph" />
                                        </>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </>
                    }}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    onHide={this.props.hideFeeReciept}
                >
                    {receiptData?.map(receiptData => <div className='flex justify-between ma-m20 mb-8 ' ref={(el) => this.componentRef = el}>
                        <div style={{ width: '48%', border: '1px dashed' }}>
                            <div className='flex'>
                                <div className='w-30 ma-mt10' >
                                    <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{ marginLeft: '10%' }} />
                                </div>
                                <div className='flex justify-center w-70'>
                                    <div>
                                        <div className='flex justify-center'>
                                            <h2 className='p-mb-2 orgHeader' >{orgName}</h2>
                                        </div>
                                        <h4 className='heading-font'>{orgAddress}</h4>
                                        <div className=''>
                                            <h4 className='heading-font '>Contact Number :{mobileOperational}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100'>
                                <hr className='dashed' />
                            </div>
                            <div className='flex justify-end mr-3' >
                                <h5>Office Copy</h5>
                            </div>
                            <div className='flex justify-center mb-3'>
                                <h3>FEE RECEIPT</h3>
                            </div>
                            <div className='ml-3' >
                                <h6 className='payinfo'>RECEIPT NUMBER : {receiptData?._id}</h6>
                                <h6 className='payinfo'>RECEIPT DATE : {moment(receiptData?.recieptDate).format('DD-MM-YYYY')}</h6>
                                <h6 className='payinfo'>NAME OF THE STUDENT : {receiptData?.given_name}</h6>
                                <h6 className='payinfo'>USER NAME : {receiptData?.username}</h6>
                                <h6 className='payinfo'>ACADAMIC YEAR : {receiptData?.academicYear}</h6>
                                <h6 className='payinfo'>CLASSNAME : {receiptData?.className}</h6>
                                <h6 className='payinfo'>PAYMENT MODE : {receiptData?.paymentMode}</h6>
                                {receiptData?.paymentMode !== 'cash' && receiptData?.paymentMode !== 'Cheque' && receiptData?.paymentMode !== 'DD' && <h6 className='payinfo'>TRANSACTION ID : {receiptData?.transctionId}</h6>}
                                <div className='mr-3'>
                                    <table className='table-border1 w-100 '>
                                        <tr>
                                            <th className='table-border heading-font w-1'>S.no</th>
                                            <th className='table-border heading-font'>Particulars</th>
                                            <th className='table-border heading-font'>Amount</th>
                                            <th className='table-border heading-font'>GST</th>
                                        </tr>
                                        {receiptData?.feePaidDetails.map((e, i) => {

                                            return (
                                                <>
                                                    <tr>
                                                        <td className='table-border heading-font'>{i + 1}</td>
                                                        <td className='table-border heading-font'>{e.feeType + '- Term ' + e.term}</td>
                                                        <td className='table-border heading-font'>{e.amountPaying}</td>
                                                        <td className='table-border heading-font'>{e?.gstPaidNow || 0}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}

                                        {/* <tr>
                                <td className='table-border heading-font'>1</td>
                                <td className='table-border heading-font'>Application Fee ( )</td>
                                <td className='table-border heading-font'>1000.00</td>
                            </tr> */}

                                        <tr>
                                            <td className='table-border heading-font totalAmt' colSpan='4'>Total Amount Paid - {receiptData.amountPaid}</td>
                                            {/* <td className='table-border heading-font'></td> */}
                                        </tr>
                                    </table>
                                    <div>
                                        <h6>Note : <span></span></h6>
                                    </div>
                                    <div className='flex justify-end mt-7'>
                                        <h6 >Cashier/Manager</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: '48%', border: '1px dashed' }}>
                            <div className='flex'>
                                <div className='w-30 ma-mt10' >
                                    <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{ marginLeft: '10%' }} />
                                </div>
                                <div className='flex justify-center w-70'>
                                    <div>
                                        <div className='flex justify-center'>
                                            <h2 className='p-mb-2 orgHeader' >{orgName}</h2>
                                        </div>
                                        <h4 className='heading-font'>{orgAddress}</h4>
                                        <div className=''>
                                            <h4 className='heading-font '>Contact Number :{mobileOperational}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100'>
                                <hr className='dashed' />
                            </div>
                            <div className='flex justify-end mr-3'>
                                <h5>Duplicate Copy</h5>
                            </div>
                            <div className='flex justify-center mb-3'>
                                <h3 >FEE RECEIPT</h3>
                            </div>
                            <div className='ml-3' >
                                <h6 className='payinfo'>RECEIPT NUMBER : {receiptData?._id}</h6>
                                <h6 className='payinfo'>RECEIPT DATE : {moment(receiptData?.recieptDate).format('DD-MM-YYYY')}</h6>
                                <h6 className='payinfo'>NAME OF THE STUDENT : {receiptData?.given_name}</h6>
                                <h6 className='payinfo'>USER NAME : {receiptData?.username}</h6>
                                <h6 className='payinfo'>ACADAMIC YEAR : {receiptData?.academicYear}</h6>
                                <h6 className='payinfo'>CLASSNAME : {receiptData?.className}</h6>
                                <h6 className='payinfo'>PAYMENT MODE : {receiptData?.paymentMode}</h6>
                                {receiptData?.paymentMode !== 'cash' && receiptData?.paymentMode !== 'Cheque' && receiptData?.paymentMode !== 'DD' && <h6 className='payinfo'>TRANSACTION ID : {receiptData?.transctionId}</h6>}
                                <div className='mr-3'>
                                    <table className='table-border1 w-100 '>
                                        <tr>
                                            <th className='table-border heading-font w-1'>S.no</th>
                                            <th className='table-border heading-font'>Particulars</th>
                                            <th className='table-border heading-font'>Amount</th>
                                            <th className='table-border heading-font'>GST</th>
                                        </tr>
                                        {receiptData?.feePaidDetails.map((e, i) => {

                                            return (
                                                <>
                                                    <tr>
                                                        <td className='table-border heading-font'>{i + 1}</td>
                                                        <td className='table-border heading-font'>{e.feeType + '- Term ' + e.term}</td>
                                                        <td className='table-border heading-font'>{e.amountPaying}</td>
                                                        <td className='table-border heading-font'>{e?.gstPaidNow || 0}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}

                                        {/* <tr>
                                <td className='table-border heading-font'>1</td>
                                <td className='table-border heading-font'>Application Fee ( )</td>
                                <td className='table-border heading-font'>1000.00</td>
                            </tr> */}

                                        <tr>
                                            <td className='table-border heading-font totalAmt' colSpan='4'>Total Amount Paid - {receiptData.amountPaid}</td>
                                            {/* <td className='table-border heading-font'>{receiptData.amountPaid}</td> */}
                                        </tr>
                                    </table>
                                    <div>
                                        <h6>Note : <span></span></h6>
                                    </div>
                                    <div className='flex justify-end mt-7'>
                                        <h6>Cashier/Manager</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgAddress: state.orgData.address,
    mobileOperational: state.orgData.mobileOperational,
});

export default connect(mapStateToProps, {})(
    Authentication(BulkFeeReciepts)
);