import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { I18Next } from './../../internationalization';
import InstallmentsAdd from './../organizationManage/feePlan/installmentsAdd';
import InputTextB from './../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import { baseUrlAdmin } from '../../store/apiConstants';
import { getRolesList } from '../../store/actions/rolesAction';
import { selectActiveAndAcademicRoles } from '../../store/selectors/authorize';
import { Dropdown } from 'primereact/dropdown';
import { sumBy, set, cloneDeep } from 'lodash'
import Service from '../../services';
import { Toast } from 'primereact/toast';

class FeeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            installmentWiseFeeDetails: [],
            selectedTab: 1,
            data: [
                {
                    "id": "4cd08f03-455e-4cf3-8af4-3c7410a1a3d5",
                    "feeType": "mess fee",
                    "amount": "10000",
                    "isItMandatory": true,
                    "installments": [
                        {
                            "id": "c8dea29b-0363-40ad-a11b-49a6a9be7a14",
                            "name": "single",
                            "percentage": "100",
                            "amount": 10000,
                            "dueDate": "2023-02-21T18:30:00.000Z",
                            "paidAmount": 0,
                            "conession": 0,
                            "balance": 10000
                        }
                    ],
                    "paidAmount": 0,
                    "conession": 0,
                    "balance": "10000"
                },
                {
                    "id": "534931ef-3dc2-4f1a-81a3-4136f17906bf",
                    "feeType": "lab fee",
                    "amount": "2000",
                    "isItMandatory": true,
                    "installments": [
                        {
                            "id": "ea5cde87-1c2f-4568-a17d-a8c171caaa2b",
                            "name": "single",
                            "percentage": "100",
                            "amount": 2000,
                            "dueDate": "2023-02-21T18:30:00.000Z",
                            "paidAmount": 0,
                            "conession": 0,
                            "balance": 2000
                        }
                    ],
                    "paidAmount": 0,
                    "conession": 0,
                    "balance": "2000"
                }
            ],
        }
        this.service = new Service();

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.feeDetails != this.props.feeDetails) {
            this.setState({
                feeDetails: this.props.feeDetails
            })
        }

    }

    componentDidMount = () => {
        if (this.props.feeDetails) {
            this.setState({
                feeDetails: this.props.feeDetails
            })
        }
        this.props.getRolesListReq();
    }

    changeTab = (num) => {
        let installmentWiseFeeDetails = [];

        this.state?.feeDetails?.map(f => {
            f.installments.map(i => {
                i.planName = f.planName
                i.feeType = f.feeType;
                i.status = f.status
                installmentWiseFeeDetails.push(i)
            })
        });
        this.setState({
            selectedTab: num,
            installmentWiseFeeDetails
        })
    }
    getConcessionDialog = (installments, userId) => {
        console.log("installments", installments)
        this.setState({
            assignConcessionToStudPopup: true,
            feeInstallments: installments
        });

    }

    onTextChange = (value, field, index) => {
        console.log("valuevalue", value)
        let data = cloneDeep(this.state.feeInstallments)
        set(data, `installments[${index}][${field}]`, value)
        // data.installments[index][field] = value;
        console.log("datsssssss", data)
        this.setState((prevState) => ({
            ...prevState,
            feeInstallments: data,
        }));
    }
    submitConcession = () => {
        // call api to submit concession
        console.log("feeInstallmentsfeeInstallments", this.state.feeInstallments)
        this.setState({
            isLoading: true
        });
        let concessionData = this.state.feeInstallments
        concessionData.type = "Concession"
        concessionData.fee_id = concessionData._id
        delete concessionData._id
        let url = `${baseUrlAdmin}/student-fee/create-concession`;
        if (concessionData) {
            this.service.post(url, concessionData, true).then(data => {
                if (data.status) {
                    this.toast.show({ severity: 'success', summary: 'Sucess', detail: data?.res?.messsage, life: 3000 });
                    this.props.getProfileDetails(this.props.id)
                } else {

                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some Error Occured', life: 3000 });
                }
                this.setState({
                    isLoading: false,
                    assignConcessionToStudPopup: false,
                    feeInstallments: null
                })
            });
        }
    }

    onChangeSection = (key, field) => {
        let data = this.state.feeInstallments
        set(data, `${field}`, key)
        this.setState((prevState) => ({
            ...prevState,
            feeInstallments: data,
        }));
    };
    render() {

        const { selectedTab, feeInstallments } = this.state;
        const { defaultLanguage, rolesList } = this.props
        const langType = defaultLanguage
        // console.log("feeInstallmentsfeeInstallments", feeInstallments)
        // console.log("this.state.feeDetails ", this.state.feeDetails)
        return (<>

            <div className='question-bank ml-4 '>
                <div className='mt-4 mb-3'>
                    <div className='qb-tabs pb-3'>
                        <span onClick={() => { this.changeTab(1) }} className={selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>{I18Next('feeTypeWise', langType).COMMON_KEY}</span>
                        <span onClick={() => { this.changeTab(2) }} className={selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>{I18Next('installementWise', langType).COMMON_KEY}</span>
                    </div>
                </div>
                {
                    this.state.selectedTab == 1 ? <>
                        <DataTable className='card'
                            value={this.state.feeDetails || []}
                        >
                            <Column header={I18Next('sNo', langType).COMMON_KEY} body={(rowData, index) => {
                                return (
                                    <p>{index.rowIndex + 1}</p>
                                )
                            }} />
                            <Column header={I18Next('feePlan', langType).COMMON_KEY} field='planName' />
                            <Column header={I18Next('feeType', langType).COMMON_KEY} field='feeType' />
                            <Column header={I18Next('actualFee', langType).COMMON_KEY} field='amount' />
                            <Column header={I18Next('concession', langType).COMMON_KEY}
                                body={(rowData) => {
                                    return (
                                        <>
                                            <span>
                                                {sumBy(rowData?.installments, function (o) { return o.concession || 0; })}
                                            </span>{' '}
                                            {/* {['Pending', 'Approved', 'Rejected'].includes(rowData?.status) ? <h4>{rowData?.status}</h4> : <Button label='Add' onClick={() => this.setState({ assignConcessionToStudPopup: true, selectedUserId: rowData.user_id }, this.getConcessionDialog(rowData, rowData.user_id))} />} */}
                                        </>
                                    )
                                }}
                                field='conession' />
                            <Column header={""}
                                body={(rowData) => {
                                    return (
                                        <div style={{ display: 'flex' }}>
                                            {
                                                ['Pending', 'Rejected'].includes(rowData?.status) ?
                                                    <>
                                                        <h4 style={{marginRight:"10px"}}>{rowData?.status}</h4>{' '}
                                                        <Button label='View' onClick={() => this.setState({ assignConcessionToStudPopup: true, selectedUserId: rowData.user_id }, this.getConcessionDialog(rowData, rowData.user_id))} />
                                                    </>
                                                    :
                                                    ['Approved'].includes(rowData?.status) ? <><h4 style={{ marginRight: "10px" }}>{rowData?.status}</h4>{' '}</> :
                                                        <>
                                                            {parseInt(rowData?.amount) !== parseInt(rowData?.paidAmount) && <Button icon="pi pi-plus" className='p-button-rounded ma-button-icon' onClick={() => this.setState({ assignConcessionToStudPopup: true, selectedUserId: rowData.user_id }, this.getConcessionDialog(rowData, rowData.user_id))} />}
                                                        </>
                                            }
                                            {/* {['Pending', 'Approved', 'Rejected'].includes(rowData?.status) ? <h4>{rowData?.status}</h4> : <Button label='Add' onClick={() => this.setState({ assignConcessionToStudPopup: true, selectedUserId: rowData.user_id }, this.getConcessionDialog(rowData, rowData.user_id))} />} */}
                                        </div>
                                    )
                                }}
                                field='conession' />
                            <Column header={I18Next('netFee', langType).COMMON_KEY}
                                body={(rowData) => {
                                    return (
                                        <>
                                            {sumBy(rowData?.installments, function (o) { return o.netAmount || 0; })}
                                        </>
                                    )
                                }}
                                field='conession' />
                            {/* <Column header={I18Next('netFee', langType).COMMON_KEY} field='amount' /> */}
                            <Column header={I18Next('paid', langType).COMMON_KEY} field='paidAmount' />
                            {/* <Column header={I18Next('balance', langType).COMMON_KEY} field='balance' /> */}
                            <Column header={I18Next('balance', langType).COMMON_KEY}
                                body={(rowData) => {
                                    return (
                                        <>
                                            {sumBy(rowData?.installments, 
                                                function (o) { return o.balance>=0?o.balance:!o.balance? o.amount:0 })}
                                        </>
                                    )
                                }}
                                field='conession' />
                            {/* <Column header='' /> */}
                        </DataTable>
                    </> : <>
                        <DataTable className='card'
                            value={this.state.installmentWiseFeeDetails || []}>
                            <Column header={I18Next('sNo', langType).COMMON_KEY} body={(rowData, index) => {
                                return (
                                    <p>{index.rowIndex + 1}</p>
                                )
                            }} />
                            <Column header={I18Next('feePlan', langType).COMMON_KEY} field='planName' />
                            <Column header={I18Next('installmentName', langType).COMMON_KEY} field='name' />
                            <Column header={I18Next('feeType', langType).COMMON_KEY} field='feeType' />
                            <Column header={I18Next('actualFee', langType).COMMON_KEY} field='amount' />
                            <Column header={I18Next('concession', langType).COMMON_KEY}
                                body={(rowData) => {
                                    return (
                                        <>{
                                            ['Pending', 'Rejected'].includes(rowData?.status) ? 0 :
                                                ['Approved'].includes(rowData?.status) ? rowData?.concession :
                                                    0}
                                        </>
                                    )
                                }}
                                field='conession' />
                            <Column header={I18Next('netFee', langType).COMMON_KEY}
                                body={(rowData) => {
                                    return (
                                        <>{
                                            rowData?.netAmount || rowData?.amount || 0}
                                        </>
                                    )
                                }}
                                field='conession' />
                            {/* <Column header={I18Next('netFee', langType).COMMON_KEY} field='amount' /> */}
                            <Column header={I18Next('paid', langType).COMMON_KEY} field='paidAmount' />
                            {/* <Column header={I18Next('balance', langType).COMMON_KEY} field='balance' /> */}
                            <Column header={I18Next('balance', langType).COMMON_KEY}
                                body={(rowData) => {
                                    return (
                                        <>{!rowData.balance && !rowData?.paidAmount ? rowData?.amount : rowData.balance >= 0 ? rowData.balance : 0
                                        }
                                        </>
                                    )
                                }}
                                field='conession' />
                            <Column header='' />
                        </DataTable>
                    </>
                }
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            <Dialog
                visible={this.state.assignConcessionToStudPopup}
                blockScroll={true}
                style={{ width: '40%' }}
                header={'Concession Details'}
                modal
                footer={() => {
                    return (<div className='ma-mt20'>
                        <Button label="Cancel" className='p-button-outlined' onClick={() => this.setState({ assignConcessionToStudPopup: false, feeInstallments: undefined })} />
                        <Button label='Submit' disabled={feeInstallments?.status === 'Pending'} className='ma-m-lr10' onClick={this.submitConcession} />
                    </div>)
                }}
                draggable={false}
                closeOnEscape={false}
                dismissableMask={false}
                closable={false}
                onHide={this.state.assignConcessionToStudPopup}
            >
                <div className="p-grid subject-add-dialog">
                    {Array.isArray(feeInstallments?.installments) && feeInstallments?.installments?.length > 0
                        &&
                        <>
                            <p>{feeInstallments?.feeType}</p><br />
                            <p>Student Details</p>
                            <DataTable className=''
                                value={this.state.feeInstallments?.installments || []}>
                                <Column header={I18Next('sNo', langType).COMMON_KEY} body={(rowData, index) => {
                                    return (
                                        <p>{index.rowIndex + 1}</p>
                                    )
                                }} />
                                <Column header={I18Next('installmentName', langType).COMMON_KEY} field='name' />
                                <Column header={I18Next('amount', langType).COMMON_KEY} field='amount' />
                                <Column header={I18Next('concession', langType).COMMON_KEY}
                                    body={(rowData, inde) => {
                                        return (
                                            <InputTextB info={"Enter concession amount"} id="cr1">
                                                <InputText
                                                    value={rowData?.concession}
                                                    onChange={(e) => {
                                                        if (rowData?.balance && rowData?.balance > 0) {
                                                            if (rowData?.balance >= parseInt(e?.target?.value)) {
                                                                this.onTextChange(parseInt(e?.target?.value), 'concession', inde?.rowIndex)
                                                            }
                                                        }
                                                        else if (rowData?.amount >= parseInt(e?.target?.value)) {
                                                            this.onTextChange(parseInt(e?.target?.value), 'concession', inde?.rowIndex)
                                                        } else {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                    disabled={feeInstallments?.status === 'Pending'}
                                                    className='p-inputtext-style1' />
                                            </InputTextB>
                                        )
                                    }}
                                    field='conession' />
                            </DataTable>
                        </>}
                    <Dropdown
                        value={feeInstallments?.approver || feeInstallments?.approver?._id || ''}
                        className='ma-w100p'
                        optionLabel={"roleName"}
                        optionValue={"_id"}
                        options={rolesList || []}
                        onChange={(e) => this.onChangeSection(e.value, "approver")}
                        placeholder="Select Roles"
                        disabled={feeInstallments?.status === 'Pending'}
                        style={{marginTop:"15px"}}
                    />
                </div>
            </Dialog>
        </>)
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
    rolesList: selectActiveAndAcademicRoles(state)
});

const mapDispatchToProps = (dispatch) => ({
    getRolesListReq: () => dispatch(getRolesList()),
});
export default connect(mapStatesToProps, mapDispatchToProps)(FeeDetails);