import React, { Component } from 'react';
import { connect } from 'react-redux';
import Authentication from './../../session';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import InputTextB from './../../customComponents/inputTextB';
import { FeeApplicableType, FeeRegistrationType, FIELDS_INFO } from '../../../constants';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid, onChipsChange, onTextChange } from '../../../utile/formHelper';
import uuidv4 from 'uuid/v4';
import { trimObj } from '../../../utile';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import {
    getBoardsData,
    getBranchesLatest
} from './../../../store/actions';
import { MultiSelect } from 'primereact/multiselect';
import { checkAndReturnOnlyBranches, userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { RadioButton } from 'primereact/radiobutton';
import InstallmentsAdd from './installmentsAdd';
import _ from 'lodash';
import withRouter from '../../lib/withRouter';

var formFields = require('./planFields.json');



class CreateFeePlan extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editFeePlan);
        this.state = {
            feePlan: this.formFields.data,
            formValidations: this.formFields.formValidations,
            classes: [],
            boardId: this.props.editFeePlan ? this.props.editFeePlan.boardId : null,
            classIds: this.props.editFeePlan ? this.props.editFeePlan.classIds : [],
            allFeeTypes: [],
            feeTypes: this.props.editFeePlan ? this.props.editFeePlan.feeTypes : [{
                id: uuidv4(),
                feeType: '',
                amount: '',
                isItMandatory: null,
                installments: [],
            }],
            selectedFeeType: null,
            sectionIds: []
        }
        this.service = new Service();
    }

    getAdmissionMetaData = () => {
        let url = `${baseUrlAdmin}/admissions/meta-data`;
        this.service.get(url, true).then(res => {
            
            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        academicYears: res?.res?.data?.academicYears || [],
                        admissionTypes: res?.res?.data?.admissionTypes || []
                    };
                });
            } else {
                // this.setState({
                //     isLoading: false
                // });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            // this.setState({
            //     isLoading: false
            // });
            console.log(e);
        })
    }

    getFeeTypes = () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/fee-types`;
        this.service.get(url, true).then(data => {
            if (data.status) {
                if (data.res && data.res.data && data.res.data.length) {
                    this.setState({
                        allFeeTypes: data.res.data,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }
    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else if (this.props.editFeePlan && this.props.boards.length) {
            this.formGradesDataForEdit(this.props.editFeePlan);
        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {

            if (this.props.editFeePlan && this.props.branchData) {


                let finaldata = this.getSectionsData(this.props.branchData, this.props.editFeePlan.sectionIds[0], this.props.branchLevels.length);
                let sectionIds = this.getSectionIds(this.props.branchData, this.props.editFeePlan.levelId)

                this.setState((prevState) => {
                    return {
                        sectionsData: finaldata,
                        sectionIds,
                        levelId: this.props.editFeePlan.levelId
                    }
                });

            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }

                this.setState({
                    sectionsData
                });
            }



        }
        this.getAdmissionMetaData();
        this.getFeeTypes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.branchData !== this.props.branchData) {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


        if (prevProps.branchData !== this.props.branchData) {
            if (this.props.editFeePlan) {
                let finaldata = this.getSectionsData(this.props.branchData, this.props.editFeePlan.sectionIds[0], this.props.branchLevels.length);
                let sectionIds = this.getSectionIds(this.props.branchData, this.props.editFeePlan.levelId)
                this.setState((prevState) => {
                    return {
                        sectionsData: finaldata,
                        sectionIds,
                        levelId: this.props.editFeePlan.levelId
                    }
                });
            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }

                this.setState({
                    sectionsData
                });
            }
        }



        if (prevProps.boards.length !== this.props.boards.length && this.props.editFeePlan) {
            this.formGradesDataForEdit(this.props.editFeePlan);
        }
    }

    formGradesDataForEdit = (editFeePlan) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editFeePlan.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ label: item.className, value: item.classId }));
            }

            this.setState(
                {
                    boardId: selectedBoard?.boardId,
                    classes: _classes,
                    boardName: selectedBoard?.boardName,
                });
        }
    }



    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            classIds: [],
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
        });

    };

    onChangeClass = (classIds) => {
        this.setState({
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            filteredContentDetails: [],
            classIds
        });
    };


    getSectionsData = (nodes, key, totalLevels) => {

        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }

        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }



    getSectionIds = (nodes, key) => {

        const _nodes = _.cloneDeep(nodes);
        let sectionIds = [];
        function recurse(node) {
            if (node?.children) {
                node.children = node.children.map(childNode => {
                    childNode = _nodes.find((n) => n.key == childNode);
                    childNode = recurse(childNode);
                    return childNode
                });
            } else {
                sectionIds.push(node?.key);
            }
            return node;
        }


        let mainNode = _nodes.find(n => n.key == key);
        recurse(mainNode);



        return sectionIds;
    }

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }


    onChangeSection = (key, level) => {
        let { sectionsData, timeTable } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)
        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }


        let markedIndex = this.props.branchLevels.findIndex(l => l.isMarked);


        let sectionIds = [];
        if (markedIndex + 1 == level) {
            sectionIds = this.getSectionIds(this.props.branchData, key)
        } else {
            sectionIds = [];
        }




        this.setState((prevState) => {
            return {
                sectionsData: newSectionData,
                sectionIds,
                levelId: key,

            }
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onChangeText = (id, value) => {
        let feeType = this.state.feeTypes.find(s => s.feeType == value)
        if (feeType) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This fee type already added.', life: 3000 });
        } else {
            let a = this.state.feeTypes.map(a => {
                if (a.id == id) {
                    a.feeType = value
                }

                return a;
            })

            ;
            this.setState({
                feeTypes: a
            })
        }
    }

    onNumberChange = (id, value) => {
        
        if (isNaN(value) || parseInt(value) < 0) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Invalid amount', life: 3000 });
        } else {
            let a = this.state.feeTypes.map(a => {
                if (a.id == id) {
                    a.amount = value;
                    a.installments = [];
                }

                return a;
            })

            ;
            this.setState({
                feeTypes: a
            })
        }
    }

    onChoiceChange = (id, value) => {
        let a = this.state.feeTypes.map(a => {
            if (a.id == id) {
                a.isItMandatory = value
            }
            return a;
        })
        this.setState({
            feeTypes: a
        })
    }

    addFeeTypeRow = () => {
        this.setState((prevState) => {
            return {
                feeTypes: [...prevState.feeTypes, {
                    id: uuidv4(),
                    feeType: undefined,
                    amount: '',
                    isItMandatory: null,
                    installments: [],
                }]
            }
        })
    }


    removeFeeTypeRow = (id) => {
        this.setState((prevState) => {
            return {
                feeTypes: [...prevState.feeTypes.filter(a => a.id != id)]
            }
        })
    }

    addInstallments = (id, installments) => {
        let feeTps = this.state.feeTypes.map(a => {
            if (a.id == id) {
                a.installments = installments
            }
            return a;
        })

        this.setState({
            feeTypes: feeTps,
            isShowInstallmentsPopup: false,
            selectedFeeType: null
        });
    }


    isAdditionalValid = () => {
        let isValid = true;

        if (!this.state.boardId || !this.state.classIds || !this.state.classIds.length || !this.state.sectionIds.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
            isValid = false
        }

        
        if (!this.state.feeTypes.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Fee Types are required', life: 3000 });
            isValid = false;
        } else {
            // let installmentsAdded = true;
            // this.state.feeTypes.map(fT => {
            //     if (!fT.installments || !fT.installments?.length) {
            //         installmentsAdded = false;
            //     }
            // });
            // this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all isntallments in all fee types', life: 3000 });
            // if (!installmentsAdded) {
            //     isValid = installmentsAdded;
            // }
        }

        if (this.state.feeTypes.find(r => r.feeType == '' || r.amount == '' || r.isItMandatory == null || !r.installments.length)) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all fields in fee types.', life: 3000 });
            isValid = false;
        }

        if (!this.state.feeTypes.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Fee Types are required', life: 3000 });
            isValid = false;
        }


        return isValid;


    }

    onSaveFeePlan = () => {
        this.setState({
            isSaveClicked: true
        });


        let isCompleteFormValid = true;
        const formStatus = isFormValid(formFields, this.formFields.formValidations, this.state.feePlan);
        if (!formStatus.formValidations.isFormValid) {
            isCompleteFormValid = false;
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
        } else if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }


        if (isCompleteFormValid) {
            const feePlan = this.state.feePlan;
            let classes = [];
            this.props.boards.forEach(board => {
                board.classes && board.classes.forEach(cls => {
                    if (this.state.classIds.includes(cls.classId)) {
                        classes.push({
                            boardId: board.boardId,
                            boardName: board.boardName,
                            classId: cls.classId,
                            className: cls.className,
                            groupId: cls.groups[0].groupId,
                            groupName: cls.groups[0].groupName,
                        })
                    }
                });
            });
            feePlan.classes = classes;
            feePlan.sectionIds = this.state.sectionIds;
            feePlan.feeTypes = this.state.feeTypes;
            feePlan.classIds = this.state.classIds;
            feePlan.boardId = this.state.boardId;
            feePlan.levelId = this.state.levelId;
            console.log(feePlan, 'feeplan')


            this.setState({
                isLoading: true
            })
            let url = `${baseUrlAdmin}/fee-plans`;
            this.service[this.props.editFeePlan ? 'put' : 'post'](url, feePlan, true).then(res => {
                
                if (res && res.status && res.res.status) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false
                        };
                    }, () => {
                        this.props.onFeePlanCreate(this.props.editFeePlan ? true : false)
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })


        }
    }



    feeTypeRender = (rowData) => {
        return <Dropdown value={rowData.feeType}
            className='w-10'
            options={this.state.allFeeTypes}
            optionLabel='feeType'
            optionValue='feeType'
            onChange={(e) => this.onChangeText(rowData.id, e.value)}
            placeholder="Select Fee Type" />
    }

    amountRender = (rowData) => {
        return <InputText
            value={rowData.amount}
            onChange={(e) => { this.onNumberChange(rowData.id, e.target.value) }}
            className='p-inputtext-style1 w-10' />

    }

    isMandateryRender = (rowData) => {
        return <>
        <div className="flex">
        <div className="flex">
            <label className='ma-label-s1 mr-2' htmlFor='Yes'>Yes</label>
            <RadioButton className="radio-inline mr-3" value={true} inputid="Yes" name="isItMandatory"
                onChange={(e) => {
                    this.onChoiceChange(rowData.id, e.value)
                }}
                checked={rowData.isItMandatory}
            />
        </div>
        <div className='flex'>
            <label className='ma-label-s1 mr-2' htmlFor='No'>No</label>
            <RadioButton className="radio-inline ma-ml10" value={false} inputid="No" name="isItMandatory"
                onChange={(e) => {
                    this.onChoiceChange(rowData.id, e.value)
                }}
                checked={rowData.isItMandatory == false}
            />
        </div>
        </div>
        </>

    }

    installmentsRender = (rowData) => {

        return <>
        
        <span> {rowData.installments.length} installments </span>
            <Button label="+ Add" className='p-button-raised ' onClick={() => this.showAddInstallPopup(rowData)} />
       
        </>
        
    }

    showAddInstallPopup = (rowData) => {
        if (rowData.feeType == '' || rowData.amount == '' || rowData.isItMandatory == null) {
            return this.toast.show({ severity: 'error', summary: 'invalid', detail: 'Please select Fee type, amount, is madatory field to add installments.', life: 3000 });
        }



        this.setState({
            selectedFeeType: rowData,
            isShowInstallmentsPopup: true
        })

    }

    installmentNameRender = (rowData) => {
        return <Dropdown value={rowData.feeType}
            className='ma-w100p'
            options={this.state.allFeeTypes}
            optionLabel='feeType'
            optionValue='feeType'
            onChange={(e) => this.onChangeText(rowData.id, e.value)}
            placeholder="Select Board" />
    }



    render() {
        const { feePlan, formValidations } = this.state;
        return (
            <div className="card mt-4 ma-box-style2 create-org-w">
                <div className='flex justify-content-between'>
                    <div>
                        <span className='create-org-title'>{this.props.editOrganization ? 'Edit' : 'Create'} Fee Plan</span>
                    </div>
                    <div>
                        <p className='back-arrow' onClick={() => this.props.onClick()}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                    </div>
                </div>
                <div className='ma-clearFix'>
                </div>
                <p className='craete-org-subtitle'>Plan Details</p>
                <hr className="ma-hr-light" />
                <div className="grid ma-w100p" >
                    <div className="col-3">
                        <p className='ma-label-s2'>Fee Plan Name<span className='ma-required'>*</span></p>
                        {/* <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4"> */}
                        <InputText value={feePlan.planName} onChange={(e) => { onTextChange(e.target.value, 'planName', this, formFields, feePlan, formValidations, 'feePlan', 'formValidations') }} className='w-11 p-inputtext-style1 ma-w100p' />
                        {/* </InputTextB> */}
                        {formValidations && !formValidations.fields['planName'].isValid && <p className="p-error">{formValidations.fields['planName'].errorMsg}</p>}
                    </div>
                    <div className="col-3">
                        <p className='ma-label-s2'>Academic Year<span className='ma-required'>*</span></p>
                        {/* <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4"> */}
                        <Dropdown
                            options={this.state.academicYears || []}
                            className='w-10'
                            optionLabel='academicCode'
                            optionValue='academicCode'
                            value={feePlan.academicYear}
                            onChange={(e) => onTextChange(e.target.value, 'academicYear', this, formFields, feePlan, formValidations, 'feePlan', 'formValidations')}
                        />
                        {/* </InputTextB> */}
                        {formValidations && !formValidations.fields['academicYear'].isValid && <p className="p-error">{formValidations.fields['academicYear'].errorMsg}</p>}
                    </div>
                    <div className="col-3 p-md-3">
                        <p className='ma-label-s2'>Applicable Students<span className='ma-required'>*</span></p>
                        {/* <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4"> */}
                        <MultiSelect
                            value={feePlan.applicableType}
                            className='w-10'
                            options={FeeApplicableType}
                            optionLabel="label"
                            optionValue="value"
                            onChange={(e) => onChipsChange(e.value, 'applicableType', this, formFields, feePlan, formValidations, 'feePlan', 'formValidations')}
                            placeholder="--Select--" />
                        {/* </InputTextB> */}
                        {formValidations && !formValidations.fields['applicableType'].isValid && <p className="p-error">{formValidations.fields['applicableType'].errorMsg}</p>}
                    </div>
                    <div className="col-3 p-md-3">
                        <p className='ma-label-s2'>Admission Types<span className='ma-required'>*</span></p>
                        {/* <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4"> */}
                        <MultiSelect
                            value={feePlan.registrationTypes}
                            className='w-10'
                            options={this.state.admissionTypes || []}
                            optionLabel='admissionType'
                            optionValue='admissionCode'
                            onChange={(e) => onChipsChange(e.value, 'registrationTypes', this, formFields, feePlan, formValidations, 'feePlan', 'formValidations')}
                            placeholder="--Select--" />
                        {/* </InputTextB> */}
                        {formValidations && !formValidations.fields['registrationTypes'].isValid && <p className="p-error">{formValidations.fields['registrationTypes'].errorMsg}</p>}
                    </div>

                </div>

                <div className="grid ma-w100p" >
                    <div className="col-3">
                        <p className='ma-label-s2'>Board Name<span className='ma-required'>*</span></p>
                        {/* <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4"> */}
                        <Dropdown value={this.state.boardId}
                            className='w-10'
                            options={this.props.boards || []}
                            optionLabel='boardName'
                            optionValue='boardId'
                            onChange={(e) => this.onChangeBoard(e.value)}
                            placeholder="Select Board" />
                        {/* </InputTextB> */}
                        {!this.state.boardId && <p className="p-error">Board is required</p>}
                    </div>
                    <div className="col-3">
                        <p className='ma-label-s2'>Class Names<span className='ma-required'>*</span></p>
                        {/* <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4"> */}
                        <MultiSelect value={this.state.classIds}
                            options={this.state.classes || []}
                            className='w-10'
                            onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Classes" />
                        {/* </InputTextB> */}
                        {!this.state.classIds && !this.state.classIds.length && <p className="p-error">Classes required</p>}
                    </div>

                </div>
                <div className="grid" >
                    <div className="col-12">
                        <p className='ma-label-s2'>Branches Info<span className='ma-required'>*</span></p>
                        {this.props.isLevelMarked ? <>
                            <div>
                                {
                                    this.props.branchLevelsTillMarked ? this.props.branchLevelsTillMarked.map((level, index) => {
                                        return (<Dropdown
                                            key={'a' + index}
                                            className='w-2'
                                            value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            // className='ma-w100p'
                                            //    disabled={this.state.hideUpdateButton}
                                            tooltip={level.level}

                                            optionLabel={"name"}
                                            optionValue={"key"}
                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                            //disabled={this.state.editTimetableData}
                                            onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                            placeholder={`--Select ${level.levelName}--`} />

                                            //     : <MultiSelect
                                            //     className='ma-mr20 ma-mt10 ma-w200'
                                            //     value={this.state.sectionIds ? this.state.sectionIds : []}
                                            //     // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            //     onChange={(e) => this.onMultiChange(e.value)}
                                            //     options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                            //     optionLabel={"name"}
                                            //     optionValue={"key"}
                                            //     placeholder={`--Select ${level.levelName}--`}
                                            //     disabled={this.state.isShowAssignTeacher}
                                            // // onChange={(e) => setCities(e.value)} 
                                            // />
                                        )
                                    }) : null
                                }

                                <label className='ma-label-s1 ml-3'>Total Sections: {this.state.sectionIds?.length}</label>
                            </div>
                            {/* {formValidations && !formValidations.fields['sectionIds'].isValid && <p className="p-error">{formValidations.fields['sectionIds'].errorMsg}</p>} */}

                        </> : <>
                            <p className='ma-danger'>Please mark one level in branch master to selecte sections from here</p>

                        </>}
                    </div>
                </div>
                <div className="p-grid ma-w100p" >
                    <div className="p-col-12">
                        <p className='ma-label-s2 '>Assign Fee Types<span className='ma-required'>*</span></p>
                        <Button label="+ Add" className='ma-m10 p-button-raised my-2' onClick={() => this.addFeeTypeRow()} />
                        <DataTable ref={(el) => this.dt = el} className='card'
                            value={this.state.feeTypes}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            responsiveLayout='scroll'
                        // header={header}
                        >
                            <Column field="" header="Fee Type" body={this.feeTypeRender} />
                            <Column field="" header="Amount" body={this.amountRender} />
                            <Column field="" header="Is it mandatory" body={this.isMandateryRender} />
                            <Column field="" header="Installments" body={this.installmentsRender} />
                            <Column field="" header="Actions" body={(rowData) => {
                                return <> <Button icon='pi pi-times' className='p-button-raised' onClick={() => this.removeFeeTypeRow(rowData.id)} /></>
                            }} />
                            {/* 

                            <Column header="Edit" body={this.editTemplate} ></Column>
                               <Column header="Show Chapters" body={this.showChaptersTemplate}></Column> 
                    */}

                        </DataTable>
                    </div>
                </div>
                <div className='ma-mt20 flex justify-content-end mt-4 gap-4'>
                    <Button label="Cancel" className='p-button-outlined'
                        onClick={this.props.hideCreateFeePlanDialog}
                    />
                    <Button
                        label={`${this.props.editFeePlan ? "Update" : "Save"}`}
                        className='ma-m-lr10 p-button-raised'
                        onClick={this.onSaveFeePlan} />
                </div>
                {
                    this.state.isShowInstallmentsPopup && <InstallmentsAdd onCancel={() => {
                        this.setState({
                            isShowInstallmentsPopup: false
                        })
                    }}
                        selectedFeeType={this.state.selectedFeeType}
                        addInstallments={this.addInstallments}
                    />
                }

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div >
        )
    }
}




const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: _.cloneDeep(state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels),
    branchData: _.cloneDeep(state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data),
    branchLevelsTillMarked: checkAndReturnOnlyBranches(state, true),
    isLevelMarked: checkAndReturnOnlyBranches(state, false)
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(CreateFeePlan)));