import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { Component } from 'react'
import PreviewQuestion from '../../questionBank/common/previewQuestion'
import QuestionInTable from '../../questionBank/common/questionInTable'
import SingleQuestion from '../../questionBank/common/singleQuestion'
import { QuestionTypeKeys } from '../../questionBank/constants'
import { PreviewIcon } from '../../svgIcons'

export default class QuizPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowQuestionPreview: false
        }
    }

    render() {
        const { exam } = this.props;

        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="">
                    <h4 className=''>Questions List</h4>
                </div>

            
               
            </div>
        );


        return (<div className='preview-quiz mt-4 ml-3'>
            <div className='grid ma-no-pm'>
                <div className='col-6 p-md-6 card'>
                    <div className='ma-m20'>
                        <p className='exam-name'>{exam.examName}</p>
                        <div className='grid exam-info'>
                            <div className='col-3 p-md-3'>
                                <div className=''>
                                    <p className='e-label'>Number Of Questions</p>
                                    <p className='e-value'>{exam.noOfQuestions}</p>
                                </div>
                            </div>
                            <div className='col-3 p-md-3'>
                                <div className=' '>
                                    <p className='e-label'>Total Time</p>
                                    <p className='e-value'>{exam.totalTime} Min</p>
                                </div>
                            </div>
                            <div className='col-3 p-md-3'>
                                <div className=' '>
                                    <p className='e-label'>Total Marks</p>
                                    <p className='e-value'>{exam.totalMarks}</p>
                                </div>
                            </div>
                            <div className='col-3 p-md-3'>
                                <div className=''>
                                    <p className='e-label'>Question Types</p>
                                    <p className='e-value'>{(exam.questionType && exam.questionType.length) ? exam.questionType.join(', ') : 'All'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card datatable-crud-demo ma-mt20 mt-4">
                <DataTable ref={(el) => this.dt = el}
                    scrollable scrollHeight="500px"
                    responsiveLayout="scroll"
                    dataKey="_id"
                    value={exam.questions}
                    // filters={this.state.lazyParams.filters}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                header={header}
                >

                    <Column headerStyle={{ width: '130px' }} field="questionId" header="Question ID" />


                    {/* <Column headerStyle={{ width: '140px' }} field="questionType" header="Question Type" />
                    <Column header="Question" body={(rowData) => {
                        return (<>
                            {this.renderQuestion(rowData)}
                        </>)
                    }} /> */}
                    <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                        return (<>
                            <QuestionInTable question={rowData} />
                        </>)
                    }} />
                    <Column field="questionType" header="Question Type" />



                    <Column headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                        return (<>
                            <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id}`}
                                onClick={() => {
                                    this.setState({
                                        isShowQuestionPreview: true,
                                        questionToPreview: rowData
                                    })
                                }}>
                                <PreviewIcon width={20} height={20} />
                                {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                {/* <i className="pi pi-folder-open"></i>  */}
                            </span>
                        </>
                        );
                    }} ></Column>
                    {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                </DataTable>

            </div>

            {
                this.state.isShowQuestionPreview && <PreviewQuestion
                    question={this.state.questionToPreview}
                    onHide={() => {
                        this.setState({
                            isShowQuestionPreview: false
                        })
                    }}
                />
            }
            {/* <Button label='Submit' className='ma-mt20' /> */}
        </div>
        )
    }
}
