import React, { Component } from 'react'
import Service from '../../services';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';
import Authentication from './../../session';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}
const arr = [
    {no :1,type: "Videos",count : "1"},
    {no :2,type: "PDF",count : "2"},
    {no :3,type: "PPT",count : "3"},
]
class ResourceWiseContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            inlineLoadingD : false,
            resourceWiseContents : [],
            dialogHeader : "",
            showDiolog : false,
            arrToDataTable : [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }
        this.service = new Service();
    }

    componentDidMount() {
    }

    onClickMore = (h) => {
        this.setState({
            showDiolog: true,
            dialogHeader : h
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event });
    }
    render() {
        const { defaultLanguage} = this.props;
        const {inlineLoading,resourceWiseContents} = this.state;

        const langType = defaultLanguage
     
        return (
            <div className='ma-relative'>
                <div className='resource-usage-report ' style={{background:"#f5ebfc"}}>
                    <h3 className="resource-title ma-mt10">ResourceWiseContent</h3>
                    <div className='p-grid col-12'>
                        <div className='p-col-12 col-12'>
                        <div className='trending-table-rup' style={{width: "100%"}}>
                            <table className='trending-table'>
                            <tr>
                                <th style={{color:"#800000",font:"normal normal bold 14px/16px Roboto"}}>Number</th>
                                <th style={{color:"#3cb371",font:"normal normal bold 14px/16px Roboto"}}>Type</th>
                                <th style={{color:"#778899",font:"normal normal bold 14px/16px Roboto"}}>Count</th>
                            </tr>
                            {this.props?.resourceWiseContent?.slice(0,3)?.map((branch,index) => {
                            return (
                            <>
                            <tr>
                                <td>{index+1}</td>
                                {branch._id === "video" ? <td>Videos</td> : ""}
                                {branch._id === "mp4" ? <td>MP4</td> : ""}
                                {branch._id === "pdf" ? <td>PDF</td> : ""}
                                {branch._id === "ppt" ? <td>PPT</td> : ""}
                                {branch._id === "zip" ? <td>Zip</td> : ""}
                                {branch._id === "quiz" ? <td>Quiz</td> : ""}
                                <td>{branch.count}</td>
                            </tr>
                            </>
                            )})}
                            </table>
                            <div className='flex-reverse ma-mr10 '>
                                <span className='ma-mt10 ma-pointer ma-mr10' onClick={() => this.onClickMore("Resource Wise Content")}
                                    style={{color:"#004cff",font:"normal normal bold italic 13px Roboto ma-mr10"}}>More</span>
                            </div>
                        </div>
                        </div>
                        <div className='p-col-12 p-md-1'>
                        </div>
                    </div>
                </div>
                <Dialog className='ma-alert-box p-dialog-dash p-overflow-hidden'
                header={<h4>{this.state.dialogHeader}</h4>}
                draggable={false}
                closeOnEscape={true}
                blockScroll={true}
                dismissableMask={false}
                closable={true}
                visible={this.state.showDiolog} style={{ maxWidth: '40vw', minWidth: '40vw' }}
                onHide={() => this.setState({showDiolog : false})}
            >
                <div className='card datatable-crud-demo'>
                <DataTable stripedRows className='asdf' 
                    ref={(el) => this.dt = el}
                    value={this.props?.resourceWiseContent?.reverse()}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"
                    paginator
                    // lazy
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    responsiveLayout="scroll"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Resources"
                    globalFilter={this.state.globalFilter}
                    header={''}
                >
                    <Column header='Sl No.' body={(rowdata,rowIndex) => {
                        return(
                            <p>{rowIndex.rowIndex+1}</p>
                        )
                    }}/>
                    <Column header='Type' field='_id'/>
                    <Column header='Count' field='count'/>
                </DataTable>
                </div>
                {
                this.state.inlineLoadingD && <InLoadingComponent />
                }
                </Dialog>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(ResourceWiseContent));
