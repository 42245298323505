import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { AddIconInButtonFull, ClassetEditIcon, CodeLinkIcon, EditIcon, InputIcon, MailIcon, PlusBlackIcon } from '../svgIcons';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import InputTextB from '../customComponents/inputTextB';
import { FIELDS_INFO } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import "./style.scss";
import ClassetInputText from '../../classetComponents/classetInputText';
import { getFormFields, isFormValid, onDropDownChange, onTextChange, onToggleChange } from '../../utile/formHelper';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import { getBoardsData } from '../../store/actions';
import ClassetCalendar from '../../classetComponents/classetCalender';
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD';
import LoadingComponent from '../loadingComponent';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BranchToSectionDD from '../BaseDropdownComponents/BranchToSectionDD';



const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 2, label: "sample 2" },
    { value: 3, label: "sample 3" },
    { value: 4, label: "sample 4" },
    { value: 5, label: "sample 5" },
]



const applicableOptions = [
    { value: "ChequeBounce", label: "Cheque Bounce" },
    { value: "FeeInstallments", label: "Fee Installments" },

]
const LogicOptions1 = [
    { label: 'Fixed Amount', value: "FixedAmount" },
]

const home = { icon: 'pi pi-home' }

const LogicOptions = [
    { label: 'Fixed Amount', value: "FixedAmount" },
    { label: 'Fixed Percentage', value: 'FixedPercentage' },
    { label: 'Day Wise Amount', value: 'DayWiseAmount' },
    { label: 'Slab Wise Amount', value: 'SlabWiseAmount' },
    { label: 'Slab Percentage ', value: 'SlabPercentage' },

];



const createFineFields = require('./fine.json')


class Fine extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFineFields);
        this.state = {
            fineField: this.formFields.data,
            formValidations: this.formFields.formValidations,
            currentTab: 0,
            concessions: [],
            isDialogOpenCreateFine: false,
            logicOptions: LogicOptions,
            percentgeSwitch: false,
            studentSwitch: false,
            amount: '',
            feeCategory: '',
            feeType: '',
            board: '',
            grade: '',
            percentageValue: '',
            slabsWiseAmmount: [{ fromToDates: '', fineAmt: '' }],
            slabsPercentage: [{ fromToDates: '', finePer: '' }],
            percentage:'',
            feeCategoryOptions: [],
            feeTypeOptions: [],
            fineTypeOptions: [],
            fines: [],
            fineEdit: false,
            isLoading: false,
            students: [],
            selectedStudents: [],


        };
        this.service = new Service()
    }

    componentDidMount = () => {
        this.getFines()
        // this.getFineTypeOptions()
        this.props.getBoardsData(true)
        this.getFeeCategoryOptions()
        this.getFeeTypeOptions()
    }

    openCreateFine = () => {
        this.setState({ isDialogOpenCreateFine: true })

    }
    Editfine = (fine) => {
        const dropdownsData = {
            branchId: fine?.branchId,
            boardId: fine?.boardId,
            classId: fine?.classIds,
            sectionId: fine?.sectionId
        };

        this.setState({
            isDialogOpenCreateFine: true,
            fineField: fine,
            fineEdit: true,
            selectedStudents: fine?.studentInfo,
            dropdownsData: { ...dropdownsData },
            amount:fine?.amount,
            percentage:fine?.percentage
        });

    }

    toggleEdit = (e, fine) => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let data = fine
        let slab_info = data?.fineType === 'SlabWiseAmount' ? this.state?.slabsWiseAmmount : data?.fineType === 'SlabPercentage' ? this.state?.slabsPercentage : {};
        if (data?.fineType === 'SlabWiseAmount' || data?.fineType === 'SlabPercentage') {
            slab_info = slab_info?.map((each) => {
                if (each.fineAmt) {
                    return {
                        startDate: each.fromToDates[0],
                        endDate: each.fromToDates[1],
                        fineAmt: each.fineAmt
                    }
                }
                return {
                    startDate: each.fromToDates[0],
                    endDate: each.fromToDates[1],
                    finePer: each.finePer
                }

            })
        }

        data.slab_info = slab_info
        data.academicYear = AcademicYear

        data.status = e.target.value
        let url = `${baseUrlAdmin}/fine`
        this.service.put(url, data, true).then(res => {
            if (res?.status) {
                // this.setState({
                //     fineField: '',
                // },

                // )
                this.toast?.show({ severity: 'info', summary: 'Success', detail: 'Fine Status Updated Successfully', life: 3000 });
                this.getFines()

            } else {
                this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
            }
        }).catch(err => {
            this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
        })


    }
    handleCloseCreateFine = () => {
        this.setState({
            isDialogOpenCreateFine: false,
            fineField: {},
            fineEdit: false,
            selectedStudents:[],
            amount:'',
            percentage:'',
            slabsWiseAmmount: [{ fromToDates: '', fineAmt: '' }],
            slabsPercentage: [{ fromToDates: '', finePer: '' }],

        })

    }
    addSlab = () => {
        this.setState((prevState) => ({
            slabsWiseAmmount: [...prevState.slabsWiseAmmount, { fromToDates: '', fineAmt: '' }],
        }));
    };
    handleSlabChange = (index, field, value) => {
        this.setState((prevState) => {
            const slabsWiseAmmount = [...prevState.slabsWiseAmmount];
            slabsWiseAmmount[index][field] = value;
            return { slabsWiseAmmount };
        });
    };




    addSlabForPercentage = () => {
        this.setState((prevState) => ({
            slabsPercentage: [...prevState.slabsPercentage, { fromToDates: '', fineAmount: '' }],
        }));
    }


    handleSlabPercentageChange = (index, field, value) => {
        this.setState((prevState) => {
            const slabsPercentage = [...prevState.slabsWiseAmmount];
            slabsPercentage[index][field] = value;
            return { slabsPercentage };
        });
    };

    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }





    getFeeCategoryOptions = () => {
        const academicYear = localStorage.getItem('userAcademicYear')
        let payload = {}
        payload.academicYear = academicYear
        let url = `${baseUrlAdmin}/fee-category/filtered`
        this.service.post(url, payload, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each?.categoryName,
                    value: each?._id
                }
            })

            if (data?.status && data?.res?.data) {

                this.setState({
                    feeCategoryOptions: options
                })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the fee category options', life: 3000 });

            }
        })
    }
    getFeeTypeOptions = () => {
        let url = `${baseUrlAdmin}/fee-types?academicYear=${localStorage.getItem('userAcademicYear')}`;
        this.service.get(url, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data?.response) {


                    this.setState({
                        feeTypeOptions: data?.res?.data?.response,
                    })
                }
            }
        });
    }
    getFineTypeOptions = () => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        // let url = `${baseUrlAdmin}/fine-types?academicYear=${AcademicYear}`;
        let url = ''
        this.service.post(url, {}, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data) {

                    this.setState({
                        fineTypeOptions: data?.res?.data,
                    })
                }
            }
        });


    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.permissionIds !== this.props.permissionIds && this.props?.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {
                if (!(isAuthorized(this.props.permissionIds, PERMISSIONS.SHOW_ROLES_LIST, this.props?.userInfo))) {

                    this.setState({
                        currentTab: 1
                    })
                }
            }
        }
    }

    onCreateFine = () => {    
    //Create Fine
    this.setState({isLoading:true})
        const academicYear = localStorage.getItem('userAcademicYear')
        let data = this.state?.fineField
        let slab_info = data?.fineType === 'SlabWiseAmount' ? this.state?.slabsWiseAmmount : data?.fineType === 'SlabPercentage' ? this.state?.slabsPercentage : {};
        if (data?.fineType === 'SlabWiseAmount' || data?.fineType === 'SlabPercentage') {

            slab_info = slab_info.map((each) => {
                if (each.fineAmt) {
                    return {
                        startDate: each?.fromToDates[0],
                        endDate: each?.fromToDates[1],
                        fineAmt: each?.fineAmt
                    }
                }
                return {
                    startDate: each?.fromToDates[0],
                    endDate: each?.fromToDates[1],
                    finePer: each?.finePer
                }

            })
        }

        let payload = {}
        payload.fineName = data?.fineName
        payload.applicableTo = data?.applicableTo
        payload.feeCategory = data?.feeCategory
        payload.feeTypes = data?.feeTypes
        payload.fineType = data?.fineType

        if (data.fineType === "FixedAmount") {
            payload.amount = Number(this.state?.amount)

        } else if (data.fineType === 'FixedPercentage' &&  payload.isStudents) {
            payload.percentage = Number(this.state?.percentage)
            payload.boardId = data.isStudents ? this.state?.dropdownsData?.boardId : ""
            payload.branchId = data.isStudents ? this.state?.dropdownsData?.branchId :""
            payload.sectionId = data.isStudents ? this.state?.dropdownsData?.sectionId:''
            payload.classIds = data.isStudents ? this.state?.dropdownsData?.classId : ""
            payload.studentInfo = this.state.selectedStudents
            payload.isStudents = data?.isStudents
            payload.isGrades = data?.isGrades

        } else if (data.fineType === 'FixedPercentage' &&  payload.isGrades) {
            payload.percentage = Number(this.state?.percentage)
            payload.boardId =data.boardId
            // payload.branchId = data.isStudents ? this.state?.dropdownsData?.branchId :""
            // payload.sectionId = data.isStudents ? this.state?.dropdownsData?.sectionId:''
            payload.classIds = data.classIds
            payload.isStudents = data?.isStudents
            payload.isGrades = data?.isGrades

        }
         else if (data.fineType === 'DayWiseAmount') {
            payload.amount =Number(this.state?.amount)
            payload.boardId = data?.boardId
            payload.classIds = data.classIds
            payload.isGrades = data?.isGrades

        }
        else if (data.fineType === 'SlabWiseAmount' || data.fineType === 'SlabPercentage') {
            payload.slab_info = slab_info
            payload.boardId =  data?.boardId
            payload.classIds = data.classIds
            payload.isGrades = data.isGrades

        }

        payload.academicYear =data.academicYear || academicYear
        payload.status = data.status || true
       
        const formStatus = isFormValid(createFineFields, this.formFields.formValidations, data);
        let isCompleteFormValid = true
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations,});
            isCompleteFormValid = false;
        }

        let flag=(data.fineType === 'DayWiseAmount' || data.fineType === "FixedAmount") ? (this.state.amount !== undefined && this.state?.amount !== "") : true 
        let flag2=data.fineType === 'FixedPercentage' ? ( this.state.percentage !== undefined &&  this.state?.percentage !== "" ) : true

        let url = `${baseUrlAdmin}/fine`
        
        if( isCompleteFormValid && flag && flag2){
        if (this.state?.fineEdit && data._id) {
            payload._id=data._id  
            this.service.put(url, payload, true).then(res => {
                if (res?.status) {
                    this.setState({
                        isDialogOpenCreateFine: false,
                        fineEdit: false,
                        fineField: {},
                        selectedStudents:[],
                        dropdownsData:{},
                        isLoading: false,
                    },
                       
                    )
                    this.getFines() 
                    this.toast?.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });

                } else {
                    this.setState({isLoading:false})
                    this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(err => {
                this.setState({isLoading:false})
                this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
            })

        }
        else {
            this.service.post(url, payload, true).then(res => {
                console.log(res?.data?.message, "fineresponse")
                if (res?.status) {
                    this.setState({
                        isDialogOpenCreateFine: false,
                        fineField: {},
                        selectedStudents:[],
                        dropdownsData:{},
                        isLoading:false
                    },
                      
                    )
                    this.getFines() 
                    this.toast?.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });

                } else {
                    this.setState({isLoading:false})
                    this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
                }
            }).catch(err => {
                this.setState({isLoading:false})
                this.toast?.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured', life: 3000 });
            })
        }

    }else{
    if( !flag){
        this.setState({isLoading:false})
        this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter Amount value ', life: 3000 });
    }else if(!flag2){
        this.setState({isLoading:false})
        this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter Percentage value ', life: 3000 });

    }
}


    }
    getFines = () => {
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/fine/get-fines?academicYear=${localStorage.getItem('userAcademicYear')}`
        this.service.post(url, {}, true).then((res) => {
            if (res.status) {
                this.setState({
                    fines: res?.res?.data?.response,
                    isLoading: false

                })


            }

        }).catch(e => {
            this.setState({ isLoading: false })
            this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting Fines', life: 3000 });

        })

    }


    setDropdownValues = (dropdownsData) => {

        this.setState({
            dropdownsData
        },
            () => this.getStudentData(dropdownsData.branchId, dropdownsData.boardId, dropdownsData.classId, dropdownsData.sectionId)
        )


    }

    getStudentData = (branchId, boardId, classId, sectionId) => {
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            "branchId": branchId,
            "boardId": boardId,
            "classId": classId,
            "sectionId": sectionId,
            "academicYear": AcademicYear

        }
        this.service.post(url, payload, true).then(data => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data) {
                    this.setState({
                        students: data?.res?.data
                    })
                }
            }
        });



    }
    onSelectionChange = (event) => {
        const value = event.value;
        let selectedAll = (value.length === this.state.students?.length)
        this.setState({
            selectedStudents: event.value,
            selectAll: selectedAll

        })
    }

    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedStudents: this.state?.students,
                selectAll: true
            })
        } else {
            this.setState({
                selectedStudents: [],
                selectAll: false
            })
        }
    };



    render() {

        const { fineField, formValidations, fines, fineEdit } = this.state
        return (
            <>
                <div>

                    <div className="">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                        </div>
                        {/* <div className="-mt-3">
                        <Toolbar className="border-none" 
                         left={this.leftToolbarTemplate} 
                        right={this.rightToolbarTemplate}></Toolbar>
                    </div> */}
                    </div>


                    <div className="admin-management">
                        {/* <div style={{ marginBottom: "110px" }}> */}

                        {/* <div className="ml-5 grid h-4rem"> */}
                        {/* <div className="mt-3 md:col-12 lg:col-12 xl:col-12"> */}
                        <div className="grid mb-6">
                            <div className='col-11 flex justify-content-end align-items-center'>
                                <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }}
                                    // onClick={() => { this.setState({ isDialogOpenForCreateConcession: true }) }}
                                    onClick={this.openCreateFine}
                                >
                                    <p className=' text-xl'>Add New Fine</p>
                                    <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                                </Button>
                            </div>

                        </div>
                        <div className='grid doubts-tabs'>
                            {
                                fines?.map((fine, index) => {
                                    return <div
                                        className="mx-6 card cursor-pointer col-3 doubts-tab-w"
                                        style={this.state.currentTab === index ? { height: '170px', width: '320px' } : { height: '170px', width: '320px' }}
                                    >
                                        <div className="flex flex-column justify-content-center align-items-center ml-3">
                                            <div className='mt-1 w-full flex justify-content-end align-items-center'>
                                                <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center' onClick={() => this.Editfine(fine)} >
                                                    <ClassetEditIcon width={32} height={32} color={'#000000'}
                                                    />
                                                </div>
                                                <div className="">
                                                    <InputSwitch
                                                        className='mt-1'
                                                        onChange={(e) => { this.toggleEdit(e, fine) }}
                                                        checked={fine?.status} />
                                                </div>
                                            </div>
                                            <div className="w-full flex mt-4  ml-3">
                                                <h3 className='Montserrat24'>{fine?.fineName}</h3>
                                            </div>


                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {this.state.isLoading && <LoadingComponent />}

                        {/* </div> */}
                        {/* </div>
                        </div> */}

                    </div>




                    <Dialog
                        visible={this.state.isDialogOpenCreateFine}
                        className='ma-scroll-vr-smoke'
                        style={{ width: '70vw', height: '80vh', overflowX: 'clip', overflowY: 'visible' }}
                        breakpoints={{ '1024px': '90vw', '1366px': '90vw' }}
                        // style={{ width: '100vw'}}
                        draggable={false}
                        onHide={this.handleCloseCreateFine}
                        closable={true}
                        header={() => {
                            return (<div className='mb-2 p-2'>
                                <h3 className="text-center formhead mb-5">{fineEdit ? 'Update Fine' : 'Add New Fine'}</h3>
                            </div>)
                        }}


                    >
                        <div className="mb-5 ml-5 mr-5 p-2 " >
                            <div className="">
                                <div className="grid " >
                                    <div className="col-12 md:col-6 lg:col-4">
                                        <label className='label'>Fine Name<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetInputText
                                                icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                id="fineName"
                                                className='border-round-md groupFormInputText  border-none'
                                                value={fineField.fineName}
                                                onChange={(e) => { onTextChange(e.target.value, 'fineName', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                placeholder='Fine Name'
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['fineName'].isValid && <p className="p-error text-lg ">{formValidations.fields['fineName'].errorMsg}</p>}

                                    </div>
                                    <div className="col-12 md:col-6 lg:col-4">
                                        <label className='label'>Applicable to<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='applicableTo'
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                options={applicableOptions}
                                                optionLabel="label"
                                                className=' no-border text-2xl'
                                                value={fineField.applicableTo}
                                                onChange={(e) => {
                                                    let data = cloneDeep(fineField)
                                                    data.fineType = ""
                                                    data.applicableTo = null
                                                    this.setState({
                                                        fineField: { ...data },
                                                    })
                                                    onDropDownChange(e.target.value, 'applicableTo', this, createFineFields, data, formValidations, 'fineField', 'formValidations')
                                                }}
                                                placeholder={(
                                                    <div>
                                                        <span className='text-xl'>Select</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {formValidations && !formValidations.fields['applicableTo'].isValid && <p className="p-error text-lg ">{formValidations.fields['applicableTo'].errorMsg}</p>}

                                    </div>
                                    <div className="col-12 md:col-6 lg:col-4">
                                        <label className='label'>Logic<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetDropdown
                                                id='fineType'
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                options={fineField.applicableTo === "ChequeBounce" ? LogicOptions1 : this.state.logicOptions}
                                                className=' no-border text-2xl'
                                                value={fineField.fineType}
                                                onChange={(e) => { 
                                                    let data = cloneDeep(fineField)
                                                    data.isGrades=false
                                                    data.isStudents=false
                                                    this.setState({
                                                        fineField: { ...data },
                                                        amount:''
                                                    })
                                                    onDropDownChange(e.target.value, 'fineType', this, createFineFields, data, formValidations, 'fineField', 'formValidations') }}
                                                placeholder=
                                                {(
                                                    <div>
                                                        <span className='text-xl'>Select Logic</span>
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        {formValidations && !formValidations.fields['fineType'].isValid && <p className="p-error text-lg">{formValidations.fields['fineType'].errorMsg}</p>}

                                    </div>

                                    {(fineField.fineType === 'FixedAmount') || (fineField.fineType === "DayWiseAmount") ? <div className="col-12 md:col-6 lg:col-4">
                                        {fineField.fineType === 'FixedAmount' && <label className='label'>Amount<span className='ma-required'>*</span></label>}
                                        {fineField.fineType === 'DayWiseAmount' && <label className='label'>Ammount per Day<span className='ma-required'>*</span></label>}
                                        <div className='mt-2'>
                                            <ClassetInputText
                                                icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                id="amount"
                                                className='border-round-md groupFormInputText  border-none'
                                                // value={fineField.amount}
                                                value={this.state?.amount}
                                                // onChange={(e) => { onTextChange(e.target.value, 'amount', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                onChange={(e)=>this.setState({amount:e.target.value})}
                                                placeholder="Amount"
                                                keyfilter="int"
                                            />

                                        </div>
                                        {/* {formValidations && !formValidations.fields['amount'].isValid && <p className="p-error text-lg">{formValidations.fields['amount'].errorMsg}</p>} */}


                                    </div> : <></>}
                                    {fineField.fineType === 'FixedPercentage' &&
                                        <div className="col-12 md:col-6 lg:col-4">
                                            <label className='label'>Percentage<span className='ma-required'>*</span></label>
                                            <div className='mt-2'>
                                                <ClassetInputText
                                                    icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                    // id="percentage"
                                                    className='border-round-md groupFormInputText  border-none'
                                                    // value={fineField.percentage}
                                                    value={this.state?.percentage}
                                                    onChange={(e)=>this.setState({percentage:e?.target?.value})}
                                                    keyfilter='int'
                                                    // onChange={(e) => { onTextChange(e.target.value, 'percentage', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                    placeholder=' Percentage'


                                                />
                                            </div>
                                            {/* {formValidations && !formValidations.fields['percentage'].isValid && <p className="p-error text-lg">{formValidations.fields['percentage'].errorMsg}</p>} */}


                                        </div>}

                                   
                                    {fineField.fineType &&

                                        <div className="col-12 md:col-6 lg:col-4">
                                            <label className='label'>Fee Categories<span className='ma-required'>*</span></label>
                                            <div className='mt-2'>
                                                <ClassetDropdown
                                                    id='feeCategory'
                                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                    options={this.state?.feeCategoryOptions}
                                                    // optionLabel="label"
                                                    className=' no-border text-2xl'
                                                    value={fineField.feeCategory}

                                                    onChange={(e) => { onDropDownChange(e.target.value, 'feeCategory', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                    placeholder='Select Fee Category'
                                                />
                                                {formValidations && !formValidations.fields['feeCategory'].isValid && <p className="p-error text-lg">{formValidations.fields['feeCategory'].errorMsg}</p>}

                                            </div>

                                        </div>}
                                    {fineField.fineType &&
                                        <div className="col-12 md:col-6 lg:col-4">
                                            <label className='label'>Fee Types<span className='ma-required'>*</span></label>
                                            <div className='mt-2'>
                                                <ClassetMultiSelect
                                                    id="feeTypes"
                                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                    className=' no-border text-xl'
                                                    options={this.state.feeTypeOptions}
                                                    value={fineField?.feeTypes}
                                                    onChange={(e) => { onDropDownChange(e.target.value, 'feeTypes', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                    placeholder={<p style={{ fontSize: '15px' }}>Select Fee Types</p>}
                                                    optionValue='_id'
                                                    optionLabel='feeType'
                                                />

                                            </div>
                                            {formValidations && !formValidations.fields['feeTypes'].isValid && <p className="p-error text-lg">{formValidations.fields['feeTypes'].errorMsg}</p>}

                                        </div>
                                    }


                                    {(fineField.fineType === 'FixedPercentage' || fineField.fineType === 'DayWiseAmount' || fineField.fineType === "SlabWiseAmount" ||
                                        fineField.fineType === "SlabPercentage" || fineField.fineType === 'FixedPercentage') && <div className="col-12 flex flex-column  align-items-center ">
                                            {(fineField.fineType === 'FixedPercentage' || fineField.fineType === 'DayWiseAmount' || fineField.fineType === "SlabWiseAmount" ||
                                                fineField.fineType === "SlabPercentage") &&
                                                <div className=' mt-1 w-full flex align-items-center'>
                                                    {/* <div className=""> */}
                                                    <InputSwitch
                                                        id='isGrades'
                                                        className='mt-1'
                                                        checked={fineField?.isGrades}
                                                        onChange={(e) => {
                                                            let data = cloneDeep(fineField)
                                                            data.isStudents = false

                                                            this.setState({
                                                                fineField: { ...data },
                                                            })

                                                            onToggleChange(e.value, 'isGrades', this, createFineFields, data, formValidations, 'fineField', formValidations)
                                                        }}

                                                    />
                                                    {/* </div> */}

                                                    <div className="flex   ml-3 mt-3">
                                                        <p className='label'>Assign Fine based on Classes</p>
                                                    </div>
                                                </div>}
                                            {fineField.fineType === 'FixedPercentage' &&
                                                <div className=' w-full   mt-1  flex align-items-center'>
                                                    {/* <div className=""> */}
                                                    <InputSwitch
                                                        id='isStudents'
                                                        className='mt-1'
                                                        checked={fineField?.isStudents}
                                                        onChange={(e) => {
                                                            let data = cloneDeep(fineField)
                                                            data.isGrades = false

                                                            this.setState({
                                                                fineField: { ...data },
                                                            })
                                                            onToggleChange(e.target.value, 'isStudents', this, createFineFields, data, formValidations, 'fineField', formValidations)
                                                        }}

                                                    />
                                                    {/* </div> */}

                                                    <div className=" flex mt-3  ml-3">
                                                        <p className='label'>Assign Fine based on Students</p>
                                                    </div>
                                                </div>}

                                        </div>}

                                    {(fineField.fineType === 'FixedPercentage' && fineField.isGrades) ||
                                        (fineField.fineType === "SlabWiseAmount" && fineField.isGrades) ||
                                        (fineField.fineType === 'SlabPercentage' && fineField.isGrades) ||
                                        (fineField.fineType === 'DayWiseAmount' && fineField.isGrades)
                                        ?
                                        <>

                                            <div className="col-12 md:col-6 lg:col-4 mb-4">
                                                <label className='label'>Board<span className='ma-required'>*</span></label>
                                                <div className='mt-2'>
                                                    <ClassetDropdown
                                                        id='boardId'
                                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                        className=' no-border text-2xl'
                                                        options={this.props?.boards}
                                                        optionLabel="boardName"
                                                        optionValue="boardId"
                                                        value={fineField.boardId}
                                                        onChange={(e) => { onDropDownChange(e.target.value, 'boardId', this, createFineFields, fineField, formValidations, 'fineField', 'formValidations') }}
                                                        placeholder='Select Board'
                                                    />

                                                </div>
                                                {formValidations && !formValidations.fields['boardId'].isValid && <p className="p-error text-lg">{formValidations.fields['boardId'].errorMsg}</p>}

                                            </div>
                                            <div className="col-12 md:col-6 lg:col-4 mb-4">
                                                <label className='label'>Grade<span className='ma-required'>*</span></label>
                                                <div className='mt-2'>
                                                    <ClassetDropdown
                                                        id='classIds'
                                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                        className=' no-border text-2xl'
                                                        options={this.props?.boards?.find((board) => board?.boardId === fineField?.boardId)?.classes}
                                                        optionLabel="className"
                                                        optionValue="classId"

                                                        value={fineField.classIds}
                                                        onChange={(e) => onDropDownChange(e.target?.value, 'classIds', this, createFineFields, fineField, formValidations, 'fineField', formValidations)}
                                                        placeholder='Select Grade'
                                                    />

                                                </div>

                                            </div>
                                        </> : <></>}

                                        {fineField.fineType === "SlabWiseAmount" && <>
                                        {this.state.slabsWiseAmmount?.map((slab, index) => (

                                            <div className="col-12 md:col-6 lg:col-4 generalsetting-card justify-content-center align-content-center ml-3 mr-3"

                                            >
                                                <div key={index} className=' justify-content-center align-content-center ml-3'>
                                                    <div className=''>
                                                        <ClassetCalendar
                                                            calendarMode={'range'}
                                                            required={false}
                                                            // label={'Date'}
                                                            icon={<i className="pi pi-calendar"></i>}
                                                            value={slab?.fromToDates}

                                                            onChange={(e) => this.handleSlabChange(index, 'fromToDates', e.target.value)}

                                                            placeholder={'From and To Dates'}
                                                        />
                                                    </div>
                                                </div>


                                                <div key={index} className='mt-3 justify-content-center align-content-center ml-3'>

                                                    <ClassetInputText
                                                     icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                        id="concessionName"
                                                        className='border-round-md groupFormInputText  border-none'
                                                        value={slab?.fineAmt}

                                                        onChange={(e) => this.handleSlabChange(index, 'fineAmt', e.target.value)}
                                                        placeholder="Fine Amount" />

                                                </div>
                                            </div>
                                        ))}
                                        <div className='col-12 mt-2' >
                                            <Button

                                                icon={<PlusBlackIcon height={15} width={15} />}
                                                style={{ borderRadius: 10, backgroundColor: '#F2F0F0', border: 'none', height: '30px' }}
                                                disabled={!this.state?.slabsWiseAmmount?.every(each => each?.fromToDates && each?.fineAmt)}
                                                onClick={this.addSlab}
                                            >
                                                <p className='w-1' style={{ color: 'black' }}>Add</p>
                                            </Button>
                                        </div>



                                    </>}
                                    {fineField.fineType == 'SlabPercentage' && <>

                                        {this.state?.slabsPercentage?.map((slab, index) => (
                                            <div className="col-12 md:col-6 lg:col-4 generalsetting-card justify-content-center align-content-center mr-3 ml-3"  >
                                                <div key={index} className=' justify-content-center align-content-center ml-3'>
                                                    <div className=''>
                                                        <ClassetCalendar
                                                            calendarMode={'range'}
                                                            required={false}
                                                            // label={'Date'}
                                                            icon={<i className="pi pi-calendar"></i>}
                                                            value={slab?.fromToDates}

                                                            onChange={(e) => this.handleSlabPercentageChange(index, 'fromToDates', e.value)}

                                                            placeholder={'From and To Dates'} />

                                                    </div>
                                                </div>


                                                <div key={index} className='mt-3 justify-content-center align-content-center ml-3'>


                                                    <ClassetInputText
                                                        // id="concessionName"
                                                        icon={<CodeLinkIcon width={24} height={24} color={"black"} />}
                                                        className='border-round-md groupFormInputText  border-none'
                                                        placeholder="Fine Percentage"
                                                        value={slab?.finePer}
                                                        onChange={(e) => this.handleSlabPercentageChange(index, 'finePer', e.value)}
                                                    />

                                                </div>
                                            </div>
                                        ))}
                                        <div className='col-12 mt-2'>
                                            <Button
                                                icon={<PlusBlackIcon height={15} width={15} />}
                                                style={{ borderRadius: 10, backgroundColor: '#F2F0F0', border: 'none' }}
                                                onClick={this.addSlabForPercentage}
                                                disabled={!this.state?.slabsPercentage?.every(each => each?.fromToDates && each?.finePer)}
                                                
                                            >
                                                <p className='w-5rem' style={{ color: 'black' }}>Add Slab</p>
                                            </Button>
                                        </div>

                                    </>}
                                    {(fineField.fineType === 'FixedPercentage' && fineField.isStudents) ?
                                        <>

                                            <BranchToSectionDD setValues={this.setDropdownValues} 
                                            defaultDDValues={this.state.dropdownsData} 
                                            />


                                        </>



                                        : <></>}

                                    {(fineField.fineType === 'FixedPercentage' && fineField.isStudents) ?
                                        <div className="col-12">

                                            <DataTable ref={(el) => this.dt = el}
                                                value={this.state?.students}
                                                dataKey='user_id'
                                                paginator
                                                rows={10}
                                                // showGridlines
                                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                                // globalFilter={this.state.globalFilter}
                                                responsiveLayout="scroll"
                                                // header={header}
                                                sortable
                                                selection={this.state?.selectedStudents}
                                                onSelectionChange={this.onSelectionChange}
                                                selectAll={this.state.selectAll}
                                                onSelectAllChange={this.onSelectAllChange}
                                                selectionMode="checkbox"

                                            >

                                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                                                <Column field="name" header="Student Name" className="datatable-cel" sortable />


                                            </DataTable>

                                        </div>

                                        : <></>}


                                </div>
                                <div className='flex justify-content-center mb-4 mt-4 p-2'>
                                    <Button label={fineEdit ? 'update' : 'Add'} className='p-button-primary submitButton mr-4 button px-6 text-lg w-4'
                                        onClick={() => this.onCreateFine()}
                                    />
                                    <Button className='cancelButtonn w-2'
                                        onClick={this.handleCloseCreateFine} >
                                        <p className='cancelButtonnText'>Cancel</p>
                                    </Button>



                                </div>
                            </div >

                        </div>


                    </Dialog>

                </div >
                <Toast ref={(el) => this.toast = el} position="bottom-right" />




            </>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: state.boardsData.boards,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(Fine)));
