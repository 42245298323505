import React, { Component, createRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import Authentication from '../../session';
import { primaryColor } from '../../../store/apiConstants';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import AdmissionEnquiry from './enquiry';
import DynamicForm from '../../../classetComponents/classetDynamicForm';
import { Dialog } from 'primereact/dialog';
import { AddIconInButton, AddIconInButtonFull, ClassetEditIcon } from '../../svgIcons';
import PopulateDynamicForm from '../../../classetComponents/classetPopulateDynamicForm';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { trimObj } from '../../../utile';
import { cloneDeep } from 'lodash';
import Service from './../../services';
import { Toast } from 'primereact/toast';
import { constrainPoint } from '@fullcalendar/core';
import LoadingComponent from '../../loadingComponent';



const createAdmissionEnrollement = require('./enquiryCreate.json');


class AdmissionProcessIndex extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createAdmissionEnrollement);
        this.state = {
            enrollAdmission: this.formFields.data,
            formValidations: this.formFields.formValidations,
            currentTab: 0,
            isDialogOpenForCreateEnquiry: false,
            parsedFields: [],
            enrolladmissionData: {},
            enquiry: true,
            enqformLoad: false,
            isLoading: false,
            registration: false,
            application: false,
            examTest: false,

        };
        this.service = new Service();

    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Transport Management</h4>
            </React.Fragment>
        )
    }

    componentDidUpdate(prevProps, prevState) {

        const storedFields = localStorage.getItem('fields');
        if (storedFields) {
            if (JSON.parse(storedFields).length !== prevState.parsedFields.length) {
                console.log(prevState, "prevStateNavyaa", JSON.parse(storedFields))
                this.updateParsedFieldsFromLocalStorage()
            }
        }



    }


    componentDidMount() {
        this.getAdmissionEnrollData()
        this.updateParsedFieldsFromLocalStorage()
    }





    updateParsedFieldsFromLocalStorage = () => {
        const storedFields = localStorage.getItem('fields');
        if (storedFields) {
            const parsedFields = JSON.parse(storedFields);
            if (JSON.stringify(parsedFields) !== JSON.stringify(this.state.parsedFields)) {
                this.setState({
                    parsedFields: parsedFields
                }, () => {
                    console.log(this.state.parsedFields, "parsedFields");
                });
            }
        }
    }


    getData = (enrollAdmission, formValidations) => {
        this.setState((prevState) => ({
            enrollAdmission: enrollAdmission,
            //formValidations:formValidations

        }), () => {

        });
    }


    getFormLoad = (enqformLoad) => {
        this.setState({
            enqformLoad: enqformLoad,

        }, () => {

        });
    }

    onSubmitClick = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        const formStatus = isFormValid(createAdmissionEnrollement, this.state.formValidations, trimObj(this.state?.enrollAdmission));

        console.log(this.state?.enrollAdmission, "this.state.formValidationsttttttt")
        if (!formStatus.formValidations.isFormValid) {

            this.setState({
                formValidations: formStatus.formValidations,
            }, () => { this.getFormLoad(!this.state.enqformLoad) });
        } else {


            let payload = cloneDeep(this.state?.enrollAdmission)

            console.log(payload,"ppppppppp")

            if (payload !== null && Object.keys(payload).length > 0) {

                if (this.state.parsedFields && this.state.parsedFields.length > 0) {


                    payload.enrcustomFields = this.state.parsedFields || []

                }
                if (this.state.enrollAdmission?._id) {

                    let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;

                    try {
                        const res = await this.service.post(url, payload, true);
                        if (res?.res?.status && res.status) {
                            this.getAdmissionEnrollData()
                            this.toast.show({ severity: 'success', summary: 'Admission Enroll Updated successfully', detail: "", life: 3000 });
                        } else {

                            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                        }
                    } catch (e) {
                        console.log(e);
                        this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
                    }
                }
                else {

                    let url = `${baseUrlAdmin}/admissions/process`;

                    try {
                        const res = await this.service.post(url, payload, true);
                        if (res?.res?.status && res.status) {

                            console.log(res?.res, "res?.res")

                            this.getAdmissionEnrollData()



                            this.toast.show({ severity: 'success', summary: 'Admission Enroll Created successfully', detail: res?.res?.message, life: 3000 });
                        } else {
                            const error = 'Some error occurred';
                            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                        }
                    } catch (e) {
                        console.log(e);
                        this.toast.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                    }
                }
            }


        }

    }


    getAdmissionEnrollData = async () => {

        const academicYear = localStorage.getItem("userAcademicYear")
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState(
                    { enrolladmissionData: res?.res?.data, isLoading: false },
                    () => {


                    }
                );
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    };

    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state?.enrollAdmission));
       
        data[field] = e.value;
       
        this.setState({
            enrollAdmission: data,
           
        }, () => {});
    }

    render() {
        const { parsedFields,enrollAdmission } = this.state;
       
        return (
            <div className='mt-4'>

                <div className="admin-management">
                    <div style={{ marginBottom: "110px" }}>
                        <div className="mr-5 flex justify-content-end align-items-center">
                            <Button
                                label="Save"
                                icon={<i className='pi pi-download text-lg'></i>}
                                className='saveBtn'
                                onClick={this.onSubmitClick}
                            />
                        </div>
                        <div className=" mt-4">
                            <div className="grid">
                                {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST}> */}
                                <div className="mx-4 subCardContainer mb-0 h-10rem  card cursor-pointer md:col-6 col-12 lg:col-4 xl:col-2 lg:mb-3 md:mb-3 sm:mb-3">
                                    <div className="flex flex-column justify-content-center align-items-center">
                                        <div className="mt-1 w-full flex justify-content-between align-items-center">
                                            <p className="subCardParaText">Step 1</p>

                                            <div onClick={() => this.setState({ isDialogOpenForCreateEnquiry: true })}>
                                                <AddIconInButtonFull width={40} height={40} color={'#076EFF'} />
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-content-start mt-3 ml-3">
                                            <p className='subCardMainText mt-3'>Enquiry</p>
                                        </div>
                                    </div>
                                </div>
                                {/* </Authorizer> */}
                                {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST}> */}
                                <div className="mx-4 subCardContainer mb-0 h-10rem card cursor-pointer md:col-6 col-12 lg:col-4 xl:col-2 lg:mb-3 md:mb-3 sm:mb-3">
                                    <div className="flex flex-column justify-content-center align-items-center">
                                        <div className="mt-1 w-full flex justify-content-between align-items-center">
                                            <p className="subCardParaText w-5" >Step 2</p>
                                          
                                            <InputSwitch
                                                className=''
                                                onChange={(e) => this.onToggleChange(e, 'application')}

                                                checked={enrollAdmission?.application} />
                                        </div>
                                        <div className="w-full flex justify-content-start mt-3 ml-3">
                                            <p className='subCardMainText mt-3'>Application</p>
                                        </div>
                                    </div>
                                </div>
                                {/* </Authorizer> */}
                                {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST}> */}
                                <div className="mx-4 subCardContainer mb-0 h-10rem card cursor-pointer md:col-6 col-12 lg:col-4 xl:col-2 lg:mb-3 md:mb-3 sm:mb-3">
                                    <div className="flex flex-column justify-content-start align-items-center">
                                        <div className="mt-1 w-full flex justify-content-between align-items-center">
                                            <p className="subCardParaText  w-5" >Step 3</p>

                                           
                                            <InputSwitch
                                                className=''
                                                onChange={(e) => this.onToggleChange(e, 'examTest')}

                                                checked={enrollAdmission?.examTest} />
                                        </div>
                                        <div className="w-full flex justify-content-start mt-3 ml-3">
                                            <p className='subCardMainText mt-3'>Exam/Test</p>
                                        </div>
                                    </div>
                                </div>
                                {/* </Authorizer> */}
                                {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST}> */}
                                <div className="mx-4 subCardContainer mb-0 h-10rem card cursor-pointer md:col-6 col-12 lg:col-4 xl:col-2 lg:mb-3 md:mb-3 sm:mb-3">
                                    <div className="flex flex-column justify-content-center align-items-center">
                                        <div className="mt-1 w-full flex justify-content-between align-items-center">
                                            <p className="subCardParaText  w-5" >Step 4</p>
                                            <InputSwitch
                                                className=''
                                                onChange={(e) => this.onToggleChange(e, 'registration')}

                                                checked={enrollAdmission?.registration} />
                                        </div>
                                        <div className="w-full flex justify-content-start mt-3 ml-3">
                                            <p className='subCardMainText mt-3'>Registration</p>
                                        </div>
                                    </div>
                                </div>
                                {/* </Authorizer> */}
                                {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST}> */}
                                <div className="mx-4 subCardContainer mb-0 h-10rem card cursor-pointer md:col-6 col-12 lg:col-4 xl:col-2 lg:mb-3 md:mb-3 sm:mb-3">
                                    <div className="flex flex-column justify-content-center align-items-center">
                                        <div className="mt-1 w-full flex justify-content-between align-items-center">
                                            <p className="subCardParaText  w-5" >Step 5</p>
                                        </div>
                                        <div className="w-full flex justify-content-start mt-3 ml-3">
                                            <p className='subCardMainText mt-3'>Enrollment</p>
                                        </div>
                                    </div>
                                </div>
                                {/* </Authorizer> */}

                            </div>
                        </div>

                    </div>
                    <div className='-mt-8'>
                        {this.state.enrolladmissionData && Object.keys(this.state.enrolladmissionData).length > 0 && <AdmissionEnquiry enrolladmissionData={this.state?.enrolladmissionData} getData={this.getData} parsedFields={this.state.parsedFields} enqformLoad={this.state.enqformLoad} formValidations={this.state.formValidations} getFormLoad={this.getFormLoad} initialForm={this.state.initialForm} enrollAdmission={this.state?.enrollAdmission} />}
                    </div>

                    {
                        this.state.isLoading && <>
                            <LoadingComponent />
                        </>
                    }
                    <Dialog
                        visible={this.state.isDialogOpenForCreateEnquiry}
                        draggable={false}
                        closeOnEscape={false}
                        className='ma-alert-box'
                        style={{ width: "80%", height: "80%" }}
                        dismissableMask={false}
                        closable={true}
                        header={() => {
                            return (<div className='mt-2'>
                                <h4 className="formHeadingInter">Add Enquiry</h4>
                            </div>)
                        }}

                        onHide={() => this.setState({ isDialogOpenForCreateEnquiry: false })}
                    >
                        <div >
                            {/* <DynamicForm /> */}
                            {parsedFields && <DynamicForm parsedFields={parsedFields} />}
                        </div>

                    </Dialog>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>

            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},

});
export default connect(mapStatesToProps, {})(Authentication(withRouter(AdmissionProcessIndex)));