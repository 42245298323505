import React from "react";
class NumPad extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        show: false
    }


    render() {
        return (

            <div class="num-pad">
                <div className="grid ma-no-pm">
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('1')}>
                        1</div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('2')}>
                        2</div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('3')}>
                        3
                    </div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('-')}>
                        -
                    </div>
                </div>
                <div className="grid ma-no-pm">
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('4')} >
                        4 </div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('5')} >
                        5</div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('6')} >
                        6
                    </div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('.')}>
                        .
                    </div>
                </div>
                <div className="grid ma-no-pm">
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('7')}>
                        7</div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('8')}>
                        8</div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('9')}>
                        9
                    </div>
                    <div className="col-3 each-key" onClick={() => this.props.onClearLastClick()}>
                        <i className="pi pi-times clear-r"></i>
                        {/* <img src={require('./../../../images/clear_text.png')} alt="" /> */}
                    </div>
                </div>
                <div className="grid ma-no-pm">
                    <div className="col-3 each-key" ></div>
                    <div className="col-3 each-key" onClick={() => this.props.onNumberClick('0')}>0</div>
                    <div className="col-3 each-key"></div>
                   
                </div>
            </div>



        )
    }
}


export default NumPad;