import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import SvgViewer from './../../customComponents/svgViewer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import CreateClass from './createClass';
import cloneDeep from 'lodash/cloneDeep';
import uuidv4 from 'uuid/v4';
import { warningDailogInit } from '../../../utile';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import './styles.scss'
import { WarningIcon } from '../../svgIcons';
import { baseUrlForLongRunningTask } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { I18Next } from './../../../internationalization';
class ClassesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            isShowCreate: false,
            editClassData: null,
            isAdd: true,
            warningDialog: warningDailogInit,
            globalFilter: '',
            columns: [
                { columnName: 'Class Id', isVisible: false, id: 'classId' }],
            warningDialog2: warningDailogInit
        }
        this.service = new Service();
    }



    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isActivity = true
        } else {
            data.isActivity = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Class status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editClassData: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            // isLoading: true
        });
        const { editClassData } = this.state;
        let oldClasses = cloneDeep(this.props.classes);
        let classes = oldClasses.map(sub => {
            if (sub.classId === editClassData.classId) {
                sub.isActivity = editClassData.isActivity;
            }
            return sub
        });

        this.props.addOrUpdateClassInfo(classes);
    }



    ///templates end----

    checkIsDuplicate(classes, classInfo, isEdit) {
        let err, errMsg;
        classes &&
            classes.length &&
            classes.forEach((c) => {
                if (isEdit) {
                    if (c.classId != classInfo.classId) {
                        if (
                            c.className === classInfo.className
                        ) {
                            err = true;
                            errMsg =
                                c.className.toLowerCase() === classInfo.className.toLowerCase()
                                    ? 'Duplicate Class Name'
                                    : 'Enter Valid Details';
                            return;
                        }
                    }
                } else {
                    if (
                        c.className === classInfo.className
                    ) {
                        err = true;
                        errMsg =
                            c.className.toLowerCase() === classInfo.className.toLowerCase()
                                ? 'Duplicate Class Name'
                                : 'Enter Valid Details';
                        return;
                    }
                }
            });

        return { err, errMsg };
    }

    addClass = (classInfo) => {

        if (this.state.isAdd) {
            let { err, errMsg } = this.checkIsDuplicate(this.props.classes, classInfo);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {
                let classes = cloneDeep(this.props.classes);

                classes.push({
                    classId: classInfo.classId,
                    className: classInfo.className,
                    isActivity: classInfo.isActivity,
                    groups: [{
                        groupId: uuidv4(),
                        groupName: 'NO_GROUP',
                        isGroupActivity: true
                    }]
                });

                this.props.addOrUpdateClassInfo(classes);



                this.setState({
                    isShowCreate: false
                });
            }

        } else {
            let classes = [];
            if (this.props.classes && this.props.classes.length) {
                let oldClasses = cloneDeep(this.props.classes);
                classes = oldClasses.map(sub => {
                    if (sub.classId === classInfo.classId) {
                        sub.className = classInfo.className;
                        sub.isActivity = classInfo.isActivity;
                    }
                    return sub
                });
            }
            let { err, errMsg } = this.checkIsDuplicate(this.props.classes, classInfo, true);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {


                this.props.addOrUpdateClassInfo(classes);

                this.setState({
                    isShowCreate: false
                });
            }


        }
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }


    hideCreateSubjectDialog = () => {
        this.setState({
            isShowCreate: false,
            isAdd: null
        })
    }

    showCreateClassDialog = () => {
        this.setState({
            isShowCreate: true,
            isAdd: true,
            editClassData: null
        });
    }


    showEditClassDialog = (editClassData) => {
        this.setState({
            editClassData,
            isShowCreate: true,
            isAdd: false
        });
    }

    hideCreateClassDialog = () => {
        this.setState({
            isShowCreate: false,
            editClassData: null
        });
    }


    statusTemplate = (rowData) => {
        return (
            <div className="ma-status">
                <span className="ma-status-text">{rowData.isActivity ? 'Active' : 'InActive'}</span>
                <Authorizer permId={PERMISSIONS.EDIT_CLASS} >
                    <InputSwitch checked={rowData.isActivity} onChange={(e) => { this.changeStatus(e, rowData) }} />
                </Authorizer>
            </div>
        );


    }
    showSyncData = (rowData) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `It will sync data with content repository and any deleted resources or questions will added again from content repository.`
            },
            syncData: rowData
        });

    }

    onSyncConfirm = () => {
        this.setState({
            warningDialog2: warningDailogInit,
            isLoading: true
        });



        let obj = {
            level: 'class',
            boardId: this.props.boardId,
            parentBoardId: this.props.parentBoardId,
            classId: this.state.syncData.classId,
            parentClassId: this.state.syncData.parentClassId,
            groupId: this.state.syncData.groups[0].groupId,
            parentGroupId: this.state.syncData.groups[0].parentGroupId,
        }


        const url = `${baseUrlForLongRunningTask}/content/synccontent`;
        this.service.post(url, obj, true, { "If-Match": this.props.etag }).then((res) => {

            if (res && res.status && res.res.status) {

                this.setState({
                    isLoading: false
                });

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }

    editTemplate = (rowData) => {
        return (<>
            <Authorizer permId={PERMISSIONS.EDIT_CLASS} >
                <span data-pr-tooltip="Edit Class" data-pr-position="bottom" className={`edit${rowData.classId.substr(0, 5)}`} onClick={() => { this.showEditClassDialog(rowData) }}>
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData.classId.substr(0, 5)}`} />
            </Authorizer>
            {
                this.props.syncEnabled && <Authorizer permId={PERMISSIONS.EDIT_CLASS} >
                    <span data-pr-tooltip="Sync Data" data-pr-position="bottom" className={`ma-ml10 sync${rowData.classId.substr(0, 5)}`}
                        onClick={() => this.showSyncData(rowData)}
                    >
                        <i className='pi pi-replay ma-icon-svg ma-pointer' width="20" height="20" />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.sync${rowData.classId.substr(0, 5)}`} />
                </Authorizer>
            }

        </>
        );
    }


    render() {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 >{I18Next('classesList', langType).COMMON_KEY}</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.BOARDS_VIEWCOLUMNS} >
                            <i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4>View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (<div >
            <div className='flex justify-content-between my-3'>
                {/* <Button label="Show Subjects" className='p-button-outlined ma-m-lr10' onClick={() => { this.props.goBackToSubjects() }} /> */}
                <Authorizer permId={PERMISSIONS.ADD_CLASS} >
                    <Button label={I18Next('addClass', langType).COMMON_KEY} className='p-button-raised'
                        onClick={this.showCreateClassDialog} />
                </Authorizer>
                <div className=' cursor-pointer'>
                    <p onClick={() => this.props.onGoBack()}><span><i className="pi pi-arrow-left"></i></span> Back</p>
                </div>
            </div>
            <div className="card table-demo">
                <DataTable ref={(el) => this.dt = el}
                    //  lazy
                    value={this.props.classes} showGridlines
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"
                    responsiveLayout="scroll"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Classes"
                    globalFilter={this.state.globalFilter}
                    header={header}>

                    <Column filter filterMatchMode='contains' sortable field="className" header={I18Next('className', langType).COMMON_KEY} />
                    {
                        this.isColumnVisible('classId') && <Column field="classId" header="Class Id" />
                    }
                    <Column field="isActivity" sortable header={I18Next('status', langType).COMMON_KEY} body={this.statusTemplate} />
                    <Column header={I18Next('edit', langType).COMMON_KEY} body={this.editTemplate} ></Column>
                </DataTable>
                {this.state.isShowCreate && <CreateClass isAdd={this.state.isAdd} hideCreateDialog={this.hideCreateClassDialog} editClassData={this.state.editClassData} addClass={this.addClass} />}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
            <Dialog
                header={<></>}
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '200px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editClassData: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />
                    </div>)
                }}
                onHide={() => this.setState({ warningDialog: warningDailogInit, editClassData: null })}>
                <div>
                    <span className="warning-icon flex justify-content-center" >
                        <WarningIcon width={"30%"} height={"30%"} />
                    </span>
                </div>
                <p className='text-xl mt-2 text-900 font-bold flex justify-content-center'>{this.state.warningDialog.headerMsg}</p>
                <p className='text-lg text-600 text-center font-bold white-space-normal'>{this.state.warningDialog.message}</p>
            </Dialog>
            <Dialog
                header={<></>}
                blockScroll={true}
                draggable={false}
                closeOnEscape={false}
                className='ma-alert-box'
                dismissableMask={false}
                closable={false}
                visible={this.state.warningDialog2.visible}
                style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit, syncData: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onSyncConfirm} />

                    </div>)
                }}
                onHide={() => this.setState({ warningDialog2: warningDailogInit, syncData: null })}>
                <div>

                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog2.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog2.message}</p>
            </Dialog>

            {this.state.isLoading && <LoadingComponent />}
        </div>
        )
    }
}
const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,

});

export default connect(mapStatesToProps, {})(ClassesList);

