import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { FilterIcon } from '../svgIcons';

import { isUserAuthorized } from '../../utile/isAuthorized';
import PERM_CONSTANTS from './permissionConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';



//import './styles.scss'
// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//     },
//     paper: {
//         marginRight: theme.spacing(2),
//     },
// }));


class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: this.props.search,
            sort: '-createdAt',
            open: false,
            moreOptionsRef: null,
            assignedSubjects: this.props.assignedSubjects ? this.props.assignedSubjects : [],
            isFilterChanged: false
        }

        //this.service = new Service();
        // this.anchorRef = React.useRef(null);
    }

    componentWillReceiveProps(newProps) {
        ;
        if (newProps.assignedSubjects != this.props.assignedSubjects) {
            this.setState({
                assignedSubjects: newProps.assignedSubjects ? newProps.assignedSubjects : []
            }, () => {
                this.props.onsearchApply(this.state.search, newProps.assignedSubjects)
            });
        }

        if (newProps.search != this.props.search) {
            this.setState({
                search: newProps.search
            });
        }
    }

    componentDidMount() {
        ;
        this.props.onsearchApply(this.state.search, this.props.assignedSubjects)
    }

    // componentWillUnmount() {
    //     window.removeEventListener('click', this.handleOutSideClick)
    // }



    // handleOutSideClick = () => {
    //     if (this.state.open) {
    //         this.setState({
    //             open: false,
    //         })
    //     }
    // }



    handleClose = () => {
        this.setState({
            open: false,
            moreOptionsRef: null
        });
    }

    handleSelect = (subject) => {
        let assignedSubjects = this.state.assignedSubjects.map((s) => {
            if (s.subjectId == subject.subjectId) {
                s.isSelected = s.isSelected ? false : true;
            }

            return s;
        });

        this.setState({
            assignedSubjects,
            isFilterChanged: true
        });


    }


    onMoreClick = (event) => {
        const e = event.currentTarget;

        this.setState({
            open: !this.state.open,
            moreOptionsRef: e
        });
    }


    applyFilter = (event) => {
        this.menu.toggle(event)
        let subjects = this.state.assignedSubjects.filter((item) => {

            if (item.isSelected) {
                return item;
            }
        });

        let subj = this.state.isFilterChanged ? subjects : this.state.assignedSubjects;

        this.setState({
            open: false,
        }, () => {
            this.props.onsearchApply(this.state.search, subj)
        })

    }

    resetFilter = () => {
        let subjects = this.state.assignedSubjects.map((item) => {
            item.isSelected = false;
            return item;
        });
        this.setState({
            open: false,
            assignedSubjects: subjects,
            isFilterChanged: false
        }, () => {
            this.props.onsearchApply(this.state.search, subjects)
        });
    }

    onSearch = (e) => {
        let search = e.target.value;
        let subjects = this.state.assignedSubjects.filter((item) => {

            if (item.isSelected) {
                return item;
            }
        });

        let subj = this.state.isFilterChanged ? subjects : this.state.assignedSubjects;
        this.setState({
            search
        }, () => {
            this.props.onsearchApply(search, subj)
        })
    }

    getDisplayName = (subject) => {
        return subject.boardName + '-' + subject.className + '-' + subject.groupName + '-' + subject.subjectName;
    }

    render() {
        // this.state.assignedSubjects.length =3
        return (
            <div className="desc-search-bar">

                <div className="flex-row-reverse">

                    <div style={{ width: '40px', float: 'right' }}>
                        <Authorizer permId={PERMISSIONS.ASK_A_DOUBT_GENERAL_SORT_FILTER}  >
                            <span className="ma-pointer mt-2 mr-2" style={{ float: 'right', marginTop: '4px' }} onClick={(event) => this.menu.toggle(event)}>
                                <FilterIcon width={30} height={30} />
                            </span>
                        </Authorizer>
                    </div>
                    <div>
                        <Authorizer permId={PERMISSIONS.ASK_A_DOUBT_GENERAL_SEARCH}  >
                            <span className="p-input-icon-right w-10 ml-1">
                                <i className="pi pi-search text-xl"/>
                                <InputText

                                    placeholder="Search"
                                    value={this.state.search}
                                    onChange={this.onSearch}
                                    className='p-inputtext-style w-full h-3rem text-lg'
                                />
                            </span>
                        </Authorizer>
                    </div>
                </div>


                <Menu
                    style={{ width: "400px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <span className="sort-title">Sort & Filter</span>
                                <div className="form-group-filter ma-scroll-vr-smoke">
                                    {this.state.assignedSubjects && this.state.assignedSubjects.length ? this.state.assignedSubjects.map((subject, index) => {
                                        return <div key={index + 39} className="cnt-lbl p-col-12">
                                            <Checkbox inputId={subject.subjectId} value="New York"
                                                onChange={() => this.handleSelect(subject)}
                                                checked={subject.isSelected}
                                            ></Checkbox>
                                            <label htmlFor={subject.subjectId} className="p-checkbox-label">{this.getDisplayName(subject)}</label>
                                        </div>
                                    }) : <p>No Subjects assigned to you</p>}
                                </div>
                                <Toolbar className="ma-toolbard" right={() => <>
                                    <Button label="Reset" className='p-button-outlined' onClick={this.resetFilter} />
                                    <Button label='Done' className='ma-m-lr10 ml-3'
                                        onClick={this.applyFilter}
                                    /></>}></Toolbar>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        )
    }
}


const mapStatesToProps = (state) => ({
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    assignedSubjects: state.currentUserPerms && state.currentUserPerms.userInfo && state.currentUserPerms.userInfo.assignedSubjects,
});

export default connect(mapStatesToProps, {})(SearchBar);
