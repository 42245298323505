import React, { Component } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toolbar } from 'primereact/toolbar';
import { connect } from 'react-redux';
import { selectPermissions } from '../../../store/selectors/authorize';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { getPermissionList } from '../../../store/actions/getPermissionsAction';
import { addRole, updateAddRolesRequest } from '../../../store/actions/rolesAction';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import Service from '../../../services';
import _, { trimEnd } from 'lodash';
import PermissionsTab from './permissionsTab';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import { baseUrlAdmin } from '../../../store/apiConstants';
import InputTextB from './../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
import { trimObj } from '../../../utile';
import LoadingComponent from '../../loadingComponent';
import { Dropdown } from 'primereact/dropdown';

const formFields = [
    {
        "Type": "Text",
        "Label": "Parent Role",
        "FieldName": "parentId",
        "Required": false
    },
    {
        "Type": "Text",
        "Label": "Role Name",
        "FieldName": "roleName",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Role Description",
        "FieldName": "description",
        "Required": true
    },
    {
        "Type": "Choice",
        "Label": "Role Type",
        "FieldName": "roleType",
        "Required": true
    },
    {
        "Type": "Object",
        "Label": "Permissions",
        "FieldName": "permissions",
        "Required": true,
        'errMsgCustom': true,
        'errMsg': 'Please select atleast one permission'
    }

]

const parsePermissionsData = (permissions) => {

    let selectedPermissions = {};

    Object.keys(permissions).forEach((permissionGroupId) => {
        selectedPermissions[permissionGroupId] = [];
        permissions[permissionGroupId].permissions.forEach(selectedPermissionObj => {
            selectedPermissions[permissionGroupId].push(selectedPermissionObj.id);
        })
    });

    return selectedPermissions;
};


class CreateRole extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editRoleData || {});
        console.log(" this.formFields.data this.formFields.data", this.formFields.data)
        this.state = {
            role: this.formFields.data,
            formValidations: this.formFields.formValidations,
            permissions: parsePermissionsData((this.props.editRoleData && this.props.editRoleData.permissions) ? this.props.editRoleData.permissions : {}),
            errors: {},
            permissionsMasterList1: [],

            summary: null,
            isShowCreateOrUpdateSuccessDlg: false,
            // isShowCreate:false,
            allRolesForParentRolesOptions: this.props.isAdd ? this.props.rolesData : this?.props?.rolesData?.filter((vl) => vl?._id !== this?.formFields?.data?._id)
        };

        this.service = new Service();
    }

    componentDidMount() {
        if (!this.props.permissionsMasterList.length) {
            this.props.getPermissions();
        }


        if (this.props.permissionsMasterList.length) {

            if (!this.props.isAdd && this.props.editRoleData) {
                let permissionLO = _.cloneDeep(this.props.permissionsMasterList);
                let permissionsMasterList1 = [];
                if (this.props.editRoleData.roleType == 'academic') {
                    permissionsMasterList1 = permissionLO;
                } else {
                    permissionsMasterList1 = permissionLO.filter((role) => {
                        let isNonAcademicGroup = false;
                        if (role.permissions && role.permissions) {
                            role.permissions = role.permissions.filter((permission) => {
                                if (permission.isNonAcademic) {
                                    isNonAcademicGroup = true;
                                    return permission;
                                }
                            });
                        }
                        if (isNonAcademicGroup) {
                            return role
                        }
                    });
                }

                this.setState({
                    permissionsMasterList1: permissionsMasterList1
                });

            }

        }

    }

    componentWillReceiveProps(newProps) {
        if (this.props.permissionsMasterList !== newProps.permissionsMasterList) {

            if (!this.props.isAdd && this.props.editRoleData) {
                let permissionLO = _.cloneDeep(newProps.permissionsMasterList);
                let permissionsMasterList1 = [];
                if (this.props.editRoleData.roleType == 'academic') {
                    permissionsMasterList1 = permissionLO;
                } else {
                    permissionsMasterList1 = permissionLO.filter((role) => {
                        let isNonAcademicGroup = false;
                        if (role.permissions && role.permissions) {
                            role.permissions = role.permissions.filter((permission) => {
                                if (permission.isNonAcademic) {
                                    isNonAcademicGroup = true;
                                    return permission;
                                }
                            });
                        }
                        if (isNonAcademicGroup) {
                            return role
                        }
                    });
                }

                this.setState({
                    permissionsMasterList1: permissionsMasterList1
                });

            }

        }
    }

    handleCheckBoxClick = (groupId, permissionId, checked) => {

        const permissionList = this.state.permissions;
        if (Object.keys(permissionList).includes(groupId)) {
            if (checked) {
                permissionList[groupId].push(permissionId);
            } else {
                permissionList[groupId] = permissionList[groupId].filter((item) => item !== permissionId);
                if (permissionList[groupId].length === 0) {
                    delete permissionList[groupId];
                }
            }
        } else {
            permissionList[groupId] = [permissionId];
        }

        this.setState((prevState) => {

            return {
                permissions: permissionList,
                errors: {
                    ...prevState.errors,
                    permissions: Object.keys(permissionList).length === 0
                }
            };
        });
    };

    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.role));
        let formValidations = this.state.formValidations;
        if (field === 'parentId') {
            data[field] = e
        } else {
            data[field] = e.target.value;
        }
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            role: data,
            formValidations
        });
    }

    getPermissionListByGroup = (groupId) => {
        const { permissionsMasterList } = this.props;
        const groupedPermissions = permissionsMasterList.find((item) => item.id === groupId);
        return groupedPermissions;
    };

    onChoiceChange(value, field) {
        if (value) {
            let data = JSON.parse(JSON.stringify(this.state.role));
            let formValidations = this.state.formValidations;
            data[field] = value;
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }

            let permissionLO = _.cloneDeep(this.props.permissionsMasterList);
            let permissionsMasterList1 = [];
            if (value == 'academic') {
                permissionsMasterList1 = permissionLO;
            } else {
                permissionsMasterList1 = permissionLO.filter((role) => {
                    let isNonAcademicGroup = false;
                    if (role.permissions && role.permissions) {
                        role.permissions = role.permissions.filter((permission) => {
                            if (permission.isNonAcademic) {
                                isNonAcademicGroup = true;
                                return permission;
                            }
                        });
                    }
                    if (isNonAcademicGroup) {
                        return role
                    }
                });
            }

            this.setState({
                role: data,
                formValidations,
                permissions: {},
                permissionsMasterList1
            });
        }


    }

    createRoleDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.hideRoleCreateDialog} />
            <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />

        </div>)
    };

    getSummaryReport = (pList, roleInfo) => {
        let summary = {
            roleName: roleInfo.roleName,
            description: roleInfo.description,
            roleType: roleInfo.roleType,
            permissions: [
            ]
        };
        pList.forEach(permission => {
            let perm = {
                groupName: permission.groupName,
                totalPermissions: permission.permissions.length,
                assignedPermissions: 0
            };

            if (roleInfo && roleInfo.permissions && roleInfo.permissions[permission.id] && roleInfo.permissions[permission.id].permissions) {
                perm.assignedPermissions = roleInfo.permissions[permission.id].permissions.length
            }

            summary.permissions.push(perm);

        }
        );
        // console.log(summary, 'summary');

        return summary;
    }

    onSubmitClick = () => {
        let { permissions } = this.state;

        let role = this.state.role;


        if (permissions && Object.keys(permissions).length) {
            Object.keys(permissions).forEach(key => {
                if (!(permissions[key] && permissions[key].length)) {
                    delete permissions[key]
                }
            })
        }

        role.permissions = permissions;

        role = trimObj(role);
        const formStatus = isFormValid(formFields, this.formFields.formValidations, role);

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            const permissionOrderedByGroups = Object.keys(permissions).reduce((acc, curr) => {
                const groupedPermissions = this.getPermissionListByGroup(curr);
                if (groupedPermissions) {
                    const perms = permissions[curr].map((permId) => {
                        return groupedPermissions.permissions.find((permObject) => {
                            if (permObject.id === permId) {
                                return permObject;
                            }
                        });
                    });

                    acc[curr] = {
                        groupName: groupedPermissions.groupName,
                        permissions: perms.filter((item) => item !== undefined)
                    };
                }
                return acc;
            }, {});

            role = trimObj(role);
            const payload = {
                ...role,
                permissions: permissionOrderedByGroups
            };

            this.setState({
                isLoading: true
            })


            if (this.props.isAdd) {
                const url = `${baseUrlAdmin}/authorize/addRole`;
                this.service.post(url, payload, true).then(res => {
                    if (res && res.status) {


                        let summary = this.getSummaryReport(this.state.permissionsMasterList1, payload)

                        this.setState({
                            isLoading: false,
                            summary,
                            isShowCreateOrUpdateSuccessDlg: true,
                            isCreated: true
                        }, () => {
                            // this.props.onRoleCreate(true, payload);
                        })

                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        });
                    }
                }).catch(e => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                    console.log(e);
                })
            } else {


                const url = `${baseUrlAdmin}/authorize/updateRole`;
                this.service.put(url, { ...payload }, true).then((res) => {
                    if (res && res.status) {
                        // this.toast.show({ severity: 'success', summary: 'Role Updated', detail: res.res.message, life: 3000 });
                        let summary = this.getSummaryReport(this.state.permissionsMasterList1, payload)

                        this.setState({
                            isLoading: false,
                            summary,
                            isShowCreateOrUpdateSuccessDlg: true,
                            isCreated: true
                        }, () => {
                            //   this.props.onRoleCreate(false);
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(e => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                });

            }
            //  this.props.createRole(payload);
            // this.setState({
            //     formValidations: formStatus.formValidations,
            // });
        }
        // if (Object.values(errorState).every((item) => item === false)) {
        // }
    };



    render() {

        const { permissionsMasterList1, role, formValidations } = this.state;
        let parentId = this?.state?.allRolesForParentRolesOptions.find((vl) => vl?._id === role?.parentId)
        return (<>

            <Dialog
                //visible={this.state.isShowCreate}
                style={{ width: '80%' }}
                header={this.props.isAdd ? "Create Role" : "Edit Role"}
                headerClassName = "text-center"
                modal
                footer={this.createRoleDialogFooter}
                draggable={false}
                // footer={this.footer}
                blockScroll={true}
                closeOnEscape={false}
                dismissableMask={false}
                visible={true}
                closable={false}
                onHide={this.props.hideRoleCreateDialog}>
                <div className="">
                    <div className="">
                        <div className="grid mt-2 px-8 ">
                            <div className="col-12 grid ">
                                <div className="lg:col-2 md:col-4">
                                    <p className='ma-label-s1 mt-3'>Parent Role<span className='ma-required'></span></p>
                                </div>
                                <div className="lg:col-10 md:col-8 ml-0">
                                    <Dropdown
                                        value={parentId}
                                        onChange={(e) => this.onTextChange(e?.value?._id, 'parentId')}
                                        options={this.state.allRolesForParentRolesOptions}
                                        optionLabel="roleName"
                                        className="w-full md:w-9rem ml-0"
                                        placeholder="Select a parent role" />
                                    {/* {formValidations && !formValidations.fields['parentRole'].isValid && <p className="p-error">{formValidations.fields['parentRole'].errorMsg}</p>} */}
                                    <Button label={`Show Hierarchy`} className='rol-add-btn' onClick={this.props.showHierarchy} />
                                </div>

                            </div>
                            <div className="col-12 grid">
                                <div className="lg:col-2 md:col-4">
                                    <p className='ma-label-s1'>Role Name<span className='ma-required'>*</span></p>
                                </div>
                                <div className="lg:col-10 md:col-8">
                                    <InputTextB info={FIELDS_INFO.ADMIN_ROLE_NAME} id="cr1">
                                        <InputText value={role.roleName} onChange={(e) => { this.onTextChange(e, 'roleName') }} className='p-inputtext-style1' />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['roleName'].isValid && <p className="p-error">{formValidations.fields['roleName'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 grid">
                                <div className="lg:col-2 md:col-4">
                                    <p className='ma-label-s1'>Role Descripiton<span className='ma-required'>*</span></p>
                                </div>
                                <div className=" lg:col-10 md:col-8 p-col-12 p-md-10">
                                    <InputTextB info={FIELDS_INFO.ADMIN_ROLE_DESCRIPTION} id="cr1">
                                        <InputTextarea value={role.description} onChange={(e) => { this.onTextChange(e, 'description') }} className='p-inputtext-style1 ma-w60' rows={5} cols={20} />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="col-12 grid">
                                <div className="lg:col-2 md:col-4">
                                    <p className='ma-label-s1'>Role Type <span className='ma-required'>*</span></p>
                                </div>
                                <div className="lg:col-10 md:col-8">
                                    <InputTextB info={FIELDS_INFO.ADMIN_ROLE_TYPE} id="cr1" className='grid'>
                                        <div className='gap-5 flex ml-0 '>
                                            <div className='flex gap-2'>
                                                <RadioButton className="radio-inline" value='academic' inputId="academic" name="roleType" onChange={(e) => this.onChoiceChange(e.value, 'roleType')} checked={role.roleType === 'academic'} />
                                                <label className='ma-label-s1' htmlFor="academic">Academic</label>
                                            </div>
                                            <div className='flex gap-2'>
                                                <RadioButton className="radio-inline ma-ml10" value='non-academic' inputId="non-academic" name="roleType" onChange={(e) => this.onChoiceChange(e.value, 'roleType')} checked={role.roleType === 'non-academic'} />
                                                <label className='ma-label-s1' htmlFor="non-academic">Non Academic</label>
                                            </div>
                                        </div>

                                    </InputTextB>
                                    {formValidations && !formValidations.fields['roleType'].isValid && <p className="p-error">{formValidations.fields['roleType'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.permissionsMasterList1 && this.state.permissionsMasterList1.length ? <>
                        <Accordion className='ma-m16'>
                            {
                                permissionsMasterList1.map((item) => {
                                    return <AccordionTab id={item.id} key={item.id} header={item.groupName}>
                                        <PermissionsTab
                                            id={item.id}
                                            details={item.permissions}
                                            selectedPermissions={this.state.permissions}
                                            handleCheckBoxClick={this.handleCheckBoxClick}
                                        />
                                    </AccordionTab>
                                })
                            }

                        </Accordion>
                        {formValidations && !formValidations.fields['permissions'].isValid && <p className="p-error">{formValidations.fields['permissions'].errorMsg}</p>}

                    </> : <></>}


                    {/* <Toolbar className="ma-toolbar" right={() => { return (<Button label={`Save`} className='' onClick={this.onSubmitClick} />) }}></Toolbar> */}
                </div>
            </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            {(this.state.isLoading || this.props.isLoading) && <LoadingComponent />}


            <Dialog
                style={{ width: '50%' }}
                draggable={false}
                closable={false}
                className='success-dialog2'
                footer={() => {
                    return (
                        <>
                            <Button label="Done" className='ma-m-lr10' onClick={() => {
                                this.props.onRoleCreate(this.state.isCreated);
                            }} />
                        </>
                    )
                }}
                visible={this.state.isShowCreateOrUpdateSuccessDlg}>
                {
                    this.state.summary ? <>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src="./images/success-icon.PNG" className='img-center' alt="success" />
                            <p className='success-msg'>Role {this.props.isAdd ? "Created" : "Updated"} Successfully.</p>
                            <p className='desc-s1 ma-mt10'>Tiltle of the role : {this.state.summary.roleName}</p>
                            <p className='desc-s1 ma-mt10'>Tiltle Description : {this.state.summary.description} </p>
                            <p className='desc-s1 ma-mt10'>Role Type : {this.state.summary.roleType}</p>
                        </div>
                        <div className='p-grid ma-mt20'>
                            {
                                this.state.summary.permissions && this.state.summary.permissions.map(i =>
                                    <div className='p-col-6 sp-no-pm ma-mt10'>
                                        <p className='desc-s1' style={{ marginLeft: '40px' }}>{i.groupName} : {i.assignedPermissions}/
                                            {i.totalPermissions}
                                        </p>
                                    </div>)
                            }
                        </div>
                    </>
                        : <>
                            <p>No sumamry report available</p>
                        </>}
            </Dialog>
        </>
        )
    }
}



const mapStateToProps = (state) => ({
    permissionsMasterList: selectPermissions(state),
    isLoading: state.permissions.isLoading,
    isCreateRoleSuccessful: state.roles.isCreateRoleSuccessful,
    isCreateRoleFailed: state.roles.isCreateRoleFailed
});

const mapDispatchToProps = (dispatch) => ({
    getPermissions: () => dispatch(getPermissionList()),
    createRole: (data) => dispatch(addRole(data)),
    updateAddRolesRequest: () => dispatch(updateAddRolesRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);