import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { Component } from 'react'
import { trimObj } from '../../../utile';
import { getFormFields, isFormValid, onChoiceChange, onNumberChange, onTextChange } from '../../../utile/formHelper';
import staffFieldsJson from './transportStaffFields.json'
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';


const Designation = [
    { label: 'Driver', value: 'driver' },
    { label: 'Cleaner', value: 'cleaner' },
    { label: 'Incharge', value: 'incharge' }
]

export default class AddStaff extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(staffFieldsJson, {})
        this.state = {
            staffField: this.props.editStaffDetails ? this.props.editStaffDetails : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
        }

        this.service = new Service()

    }

    // componentDidMount() {
    //     console.log('first',this.props.editStaffDetails)
    // }

    onSubmitClick = () => {

        const { staffField } = this.state

        let body = cloneDeep(this.state.staffField)
        const formStatus = isFormValid(staffFieldsJson, this.formFields.formValidations, trimObj(body));
        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            })
            isCompleteFormValid = false
        }

        if (new Date(staffField.rtoIssuedDate).getTime() > new Date(staffField.rtoExpiryDate).getTime()) {
            this.toast.show({ severity: 'error', summary: 'RTO Issue and Expiry Dates', detail: 'Please Check RTO Issue and Expiry Dates', life: 3000 });
            isCompleteFormValid = false
        }

        if (isCompleteFormValid) {
            

            if (this.props.editStaffDetails) {

                this.setState({
                    isLoading: true
                })

                let url = `${baseUrlAdmin}/transport-staff`

                this.service.put(url, staffField, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate(true))
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            } else {

                this.setState({
                    isLoading: true
                })

                let url = `${baseUrlAdmin}/transport-staff`

                this.service.post(url, staffField, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                            // users: res?.res?.data
                        }, () => this.props.onSaveOrUpdate())
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            }
        }
    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.editStaffDetails ? 'Update' : 'Save'}`} className='p-button-raised ma-m-lr10' onClick={this.onSubmitClick} />
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
        </div>)
    }

    render() {
        const { formValidations, staffField } = this.state

        return (
            <>
                <Dialog
                    visible={this.props.visible}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    // style={{ maxWidth: '50%', minWidth: '50%' }}
                    style={{width:"50%"}}

                    footer={this.footer} headerClassName='text-center'
                    header={`${this.props.editStaffDetails ? 'Update' : 'Add New'} Staff Details`}
                >
                    <div className="grid subject-add-dialog">
                        <div className="md:col-6 col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Designation<span className='ma-required'>*</span></p>
                        </div>
                        <div className="md:col-6 col-4 p-md-11 p-lg-4 ma-mt2">
                            <Dropdown className='w-11 ma-w90p'
                                options={Designation} optionLabel='label' optionValue='value'
                                value={staffField.designation}
                                onChange={(e) => onTextChange(e.target.value, 'designation', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['designation'].isValid && <p className="p-error">{formValidations.fields['designation'].errorMsg}</p>}
                        </div>

                        <div className=" col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Name<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.name}
                                onChange={(e) => onTextChange(e.target.value, 'name', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>

                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Date Of Joining<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <Calendar className='w-11 ma-w90p' showIcon
                                value={staffField.dateOfJoining ? new Date(staffField.dateOfJoining) : null}
                                onChange={(e) => onTextChange(e.target.value, 'dateOfJoining', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['dateOfJoining'].isValid && <p className="p-error">{formValidations.fields['dateOfJoining'].errorMsg}</p>}

                        </div>

                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Aadhar Number<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.aadharCard}
                                onChange={(e) => onNumberChange(e.target.value, 'aadharCard', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['aadharCard'].isValid && <p className="p-error">{formValidations.fields['aadharCard'].errorMsg}</p>}

                        </div>

                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Address<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.address}
                                onChange={(e) => onTextChange(e.target.value, 'address', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>}

                        </div>

                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Driving Licence<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.drivingLicence}
                                onChange={(e) => onTextChange(e.target.value.toUpperCase(), 'drivingLicence', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['drivingLicence'].isValid && <p className="p-error">{formValidations.fields['drivingLicence'].errorMsg}</p>}

                        </div>

                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Phone Number<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.phoneNumber}
                                onChange={(e) => onNumberChange(e.target.value, 'phoneNumber', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['phoneNumber'].isValid && <p className="p-error">{formValidations.fields['phoneNumber'].errorMsg}</p>}

                        </div>

                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Years Of Experience<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.yearsOfExperience}
                                onChange={(e) => onNumberChange(e.target.value, 'yearsOfExperience', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['yearsOfExperience'].isValid && <p className="p-error">{formValidations.fields['yearsOfExperience'].errorMsg}</p>}

                        </div>

                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>No Of Cases Involved<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.noOfCasesInvolved}
                                onChange={(e) => onNumberChange(e.target.value, 'noOfCasesInvolved', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['noOfCasesInvolved'].isValid && <p className="p-error">{formValidations.fields['noOfCasesInvolved'].errorMsg}</p>}

                        </div>



                        <div className="col-2 md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Issuing Authority (RTO)<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w90p'
                                value={staffField.rtoAuthority}
                                onChange={(e) => onTextChange(e.target.value.toUpperCase(), 'rtoAuthority', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['rtoAuthority'].isValid && <p className="p-error">{formValidations.fields['rtoAuthority'].errorMsg}</p>}

                        </div>

                        <div className="col-2  md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>RTO Issue Date<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4  md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <Calendar showIcon className='w-11 ma-w90p' placeholder='Issue Date'
                                value={staffField.rtoIssuedDate ? new Date(staffField.rtoIssuedDate) : null}
                                onChange={(e) => onTextChange(new Date(e.value).toString(), 'rtoIssuedDate', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['rtoIssuedDate'].isValid && <p className="p-error">{formValidations.fields['rtoIssuedDate'].errorMsg}</p>}
                        </div>

                        <div className="col-2  md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>RTO Expiry Date <span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4  md:col-6 p-md-11 p-lg-4 ma-mt2">
                            <Calendar showIcon className='w-11 ma-w90p' placeholder='Expiry Date'
                                minDate={new Date(staffField.rtoIssuedDate)}
                                value={staffField.rtoExpiryDate ? new Date(staffField.rtoExpiryDate) : null}
                                onChange={(e) => onTextChange(new Date(e.value).toString(), 'rtoExpiryDate', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['rtoExpiryDate'].isValid && <p className="p-error">{formValidations.fields['rtoExpiryDate'].errorMsg}</p>}
                        </div>


                        <div className="col-2  md:col-6 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Class Of Vehicle<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-8  md:col-6 p-md-8 p-lg-8 ma-mt2 p-mt-2">
                            <div className="flex">
                            <RadioButton
                                inputId="LMV" name="classOfVehicle" value="lmv" className='md:mb-2'
                                onChange={(e) => onChoiceChange(e.value, 'classOfVehicle', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                                checked={staffField.classOfVehicle === 'lmv'} />
                            <label htmlFor="LMV" className="ml-2">LMV</label>
                            </div>
                            <div className="flex">
                            <RadioButton
                                inputId="LMV-TR" name="classOfVehicle" value="lmv-tr" className='md:mb-2'
                                onChange={(e) => onChoiceChange(e.value, 'classOfVehicle', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                                checked={staffField.classOfVehicle === 'lmv-tr'} />
                            <label htmlFor="LMV-TR" className="ml-1">LMV-TR</label>
                            </div>
                            <div className="flex">
                            <RadioButton
                                inputId="MGV" name="classOfVehicle" value="mgv" className='md:mb-2'
                                onChange={(e) => onChoiceChange(e.value, 'classOfVehicle', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                                checked={staffField.classOfVehicle === 'mgv'} />
                            <label htmlFor="MGV" className="p-ml-2">MGV</label>
                            </div>

                            <div className="flex">
                            <RadioButton
                                inputId="HMV" name="classOfVehicle" value="hmv" className='md:mb-2'
                                onChange={(e) => onChoiceChange(e.value, 'classOfVehicle', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                                checked={staffField.classOfVehicle === 'hmv'} />
                            <label htmlFor="HMV" className="ml-2">HMV</label>
                            </div>
                            <div className="flex">
                            <RadioButton
                                inputId="HGMV" name="classOfVehicle" value="hgmv" className='md:mb-2'
                                onChange={(e) => onChoiceChange(e.value, 'classOfVehicle', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                                checked={staffField.classOfVehicle === 'hgmv'} />
                            <label htmlFor="HGMV" className="ml-2">HGMV</label>
                            </div>
                            <div className="flex">
                            <RadioButton
                                inputId="HPMV/HTV" name="classOfVehicle" value="hpmv/htv" className=''
                                onChange={(e) => onChoiceChange(e.value, 'classOfVehicle', this, staffFieldsJson, staffField, formValidations, 'staffField', 'formValidations')}
                                checked={staffField.classOfVehicle === 'hpmv/htv'} />
                            <label htmlFor="HPMV/HTV" className="ml-2">HPMV/HTV</label>
                            </div>

                            {formValidations && !formValidations.fields['classOfVehicle'].isValid && <p className="p-error">{formValidations.fields['classOfVehicle'].errorMsg}</p>}

                        </div>
                    </div>
                </Dialog>
                {
                    this.state.isLoading && <LoadingComponent />
                }

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}
