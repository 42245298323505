import React, { Component } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import LoadingComponent from '../../loadingComponent';
import { Button } from 'primereact/button';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import APIService from './../../../services';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import Service from './../service';
import { discussionBoardEndpoint, baseUrlAdmin } from './../../../store/apiConstants';
import { getProfileURL, getFileType } from './../../../utile';
import { S3_UPLOAD_CONFIG, StaticConstants } from './../../../constants'
import { MELUHA_BOARD_ID } from './../../../store/actionTypes';
import {
    clearAddQuestion,
    clearEditQuestion,
    clearGetQuestion,
    clearGradeDetails,
    clearSelectedOrganizationData,
    dispatchClearCurriculumData,
    getCurriculum,
    getGradeDetails,
    getOrganizationData,
    getActiveOrganizationData,
    getSubject,
    getTopics,
    selectBoard,
    selectChapter,
    selectQuestionTarget,
    selectClass,
    selectGroup,
    selectSubject,
    selectTopic,
    updateSubject
} from '../../../store/actions';
import FileUpload from '../common/fileUpload';
import AttachmentsU from '../common/attachmentU';
import withRouter from '../../lib/withRouter';




class CreateDiscussion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCreateDialog: this.props.discussion ? true : false,
            subjects: [],
            subjectId: this.props.discussion ? this.props.discussion.subjectId : '',
            subjectName: this.props.discussion ? this.props.discussion.subjectName : '',
            openSubjectSelector: false,
            content: this.props.discussion ? this.props.discussion.content : '',
            attachments: this.props.discussion ? this.props.discussion.attachments : [],
            isQuestionOfTheDay: this.props.discussion ? this.props.discussion.isQuestionOfTheDay : false,
            userBoard: this.props.discussion ? this.props.discussion.boardId : null,
            userClass: this.props.discussion ? this.props.discussion.classId : null,
            userGroup: this.props.discussion ? this.props.discussion.groupId : null,
            userBoardName: this.props.discussion ? this.props.discussion.boardName : '',
            userClassName: this.props.discussion ? this.props.discussion.className : '',
            userGroupName: this.props.discussion ? this.props.discussion.groupName : '',
            uploadedFilesInfo: this.props.discussion ? this.props.discussion.attachments[0] : [],
            Reset: false,

            rows: 5,
            minRows: 5,
            maxRows: 1000,


            classData: [],
            subjectData: [],
            chapterData: [],
            board: [],
            groups: [],
            classes: [],
            subjects: [],
            chapters: [],
            topics: [],
            selectedSubjectName: null,
            selectedSubjectId: null,
            subjectCRUDMethod: null,
            topicData: [],
            isHistory: false,
            isNotes: false,
            value: '',
            boardValue: '',
            chapterId: ' ',
            topicId: ' ',
            gradeId: ' ',
            isEdit: false,
            isFetch: false,
            isAdd: false,
            isCreateTest: false,
            isEditTest: false,
            isTemplate: false,

            errors: {
                board: false,
                class: false,
                group: false,
                subject: false,
                content: false
            }
        }

        this.apiService = new APIService();
        this.anchorRef = React.createRef(null);
        this.service = new Service();
        this.contentRef = React.createRef();


    }

    getCurriculumData = () => {
        const { selectedOrganizationDataIds, getCurriculum } = this.props;
        if (selectedOrganizationDataIds && Object.keys(selectedOrganizationDataIds).length) {
            const boardId = selectedOrganizationDataIds.boardId || '';
            const classId = selectedOrganizationDataIds.classId;
            const groupId = selectedOrganizationDataIds.groupId;

            if (boardId && classId && groupId) {
                getCurriculum(boardId, classId, groupId, true);
            } else {
                this.toast.show({ severity: 'error', summary: 'Select grades', detail: 'select board, class and group id', life: 3000 });
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Select grades', detail: 'select board, class and group id', life: 3000 });
        }
    };


    getSubjectName = (id) => {
        const _subject =
            this.state.subjects && this.state.subjects.length && this.state.subjects.find((s) => s.subjectId === id);
        return (_subject && _subject.subjectName) || ' ';
    };

    getTopicName = (id, topicsInfo) => {
        let topicData = this.state.topicsMultiple;
        if (topicsInfo) topicData = topicsInfo;
        const _topic = topicData && topicData.length > 0 && topicData.find((t) => t.topicId === id);
        return (_topic && _topic.topicName) || ' ';
    };

    getChapterName = (id, chaptersInfo) => {
        let chapterData = this.state.chaptersMultiple;
        if (chaptersInfo) chapterData = chaptersInfo;
        const _chapter = chapterData && chapterData.length > 0 && chapterData.find((c) => c.chapterId === id);
        return (_chapter && _chapter.chapterName) || ' ';
    };

    getBoardName = (id) => {
        const _board = this.state.board && this.state.board.length && this.state.board.find((b) => b.boardId === id);
        return (_board && _board.boardName) || ' ';
    };

    getClassName = (id) => {
        const _class = this.state.classes && this.state.classes.length && this.state.classes.find((c) => c.classId === id);
        return (_class && _class.className) || '';
    };

    getGroupName = (id) => {
        const _group = this.state.groups && this.state.groups.length > 0 && this.state.groups.find((g) => g.groupId === id);
        return (_group && _group.groupName) || ' ';
    };

    handleChangeBoard = (boardId) => {

        const boardName = this.getBoardName(boardId);
        this.props.selectBoard(boardId, boardName);
        this.setState({ classes: [], groups: [], userClass: null, userGroup: null });
        const _classes = [];
        const selectedBoard =
            this.props.boardsInfo &&
            this.props.boardsInfo.boards &&
            this.props.boardsInfo.boards.length > 0 &&
            this.props.boardsInfo.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
        }

        this.setState({
            classes: _classes,
            userBoard: boardId,
            userBoardName: boardName,
            userClass: null,
            userGroup: null,
            groups: [],
            subjects: [],
            errors: {
                ...this.state.errors,
                board: false
            },
            subjectId: '',
        });

    };

    handleChangeClass = (classId) => {
        // this.props.clearSelectedOrganizationData();
        // this.handleChangeBoard(MELUHA_BOARD_ID);

        const className = this.getClassName(classId);
        this.props.selectClass(classId, className);
        this.setState({ groups: [], userGroup: null });
        const boardId = this.state.userBoard;
        const _groups = [];
        const selectedBoard =
            this.props.boardsInfo.boards &&
            this.props.boardsInfo.boards.length > 0 &&
            this.props.boardsInfo.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        this.setState({
            user: { ...this.state.user, grade: selectedClass && selectedClass.className }
        });
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            //selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
            const selectedGroup =
                selectedClass &&
                selectedClass.groups &&
                selectedClass.groups.length &&
                selectedClass.groups[0]
            this.props.selectGroup(selectedGroup.groupId, selectedGroup.groupName);


            this.setState(
                {
                    groups: _groups,
                    userClass: classId,
                    userClassName: className,
                    chapters: [],
                    topics: [],
                    errors: {
                        ...this.state.errors,
                        class: false,
                        group: false
                    },
                    userGroup: selectedGroup.groupId,
                    userGroupName: selectedGroup.groupName,
                    subjects: [],
                    subjectId: '',
                },
                () => {

                    this.getCurriculumData()
                    const _groupId =
                        this.state.groups && this.state.groups.length && this.state.groups[0] && this.state.groups[0].groupId;
                    this.setState(
                        {
                            _groupId
                        },
                        () => {
                            if (this.props.isEditQuestionValid) {
                                const groupName = this.getGroupName(this.state._groupId);
                                this.props.selectGroup(_groupId, groupName);
                            }
                        }
                    );
                }
            );
        }
    };

    handleChangeGroup = (groupId) => {
        const groupName = this.getGroupName(groupId);
        this.props.selectGroup(groupId, groupName);

        const selectedBoard =
            this.props.boardsInfo.boards &&
            this.props.boardsInfo.boards.find((board) => board.boardId === this.state.userBoard);

        const selectedClass =
            selectedBoard &&
            selectedBoard.classes &&
            selectedBoard.classes.length &&
            selectedBoard.classes.find((_class) => _class.classId === this.state.userClass);

        const selectedGroup =
            selectedClass &&
            selectedClass.groups &&
            selectedClass.groups.length &&
            selectedClass.groups.find((_group) => _group.groupId === groupId);

        this.setState(
            {
                userGroup: groupId,
                userGroupName: groupName,
                subjects: [],
                subjectId: '',
                errors: {
                    ...this.state.errors,
                    group: false
                }
            },
            this.getCurriculumData
        );
    };

    handleChangeSubject = (subjectId) => {

        const subjectName = this.getSubjectName(subjectId)
        this.setState(
            {
                subjectName,
                subjectId,
                errors: {
                    ...this.state.errors,
                    subject: false
                }
            },
            () => {
                // const subjectName = this.getSubjectName(subjectId);
                // this.props.selectSubject2(subjectId, subjectName);
                //  this.props.getSubject2(subjectId);
            }
        );
    };


    componentDidMount() {
        // this.toast.show({ severity: 'error', summary: 'Select grades', detail: 'select board, class and group id', life: 300000, });
        if (this.props.boardsInfo) {
            const boards = [];

            this.props.boardsInfo.boards &&
                this.props.boardsInfo.boards.map((item, index) => {
                    boards.push({ boardId: item.boardId, boardName: item.boardName });
                });

            this.setState(
                {
                    board: boards
                });


        }

        // if (this.props.discussion) {
        //     setTimeout(() => {
        //         this.setState({
        //             content: this.state.content + ' '
        //         });
        //     }, 1000);

        // }


        if (this.props.discussion && this.props.boardsInfo) {
            const boards = [];

            this.props.boardsInfo.boards &&
                this.props.boardsInfo.boards.map((item, index) => {
                    boards.push({ boardId: item.boardId, boardName: item.boardName });
                });

            this.setState(
                {
                    board: boards
                },
                () => {
                    this.handleChangeBoard(this.props.discussion.boardId);
                    this.handleChangeClass(this.props.discussion.classId);
                    //this.handleChangeGroup(this.props.discussion.groupId);
                    //  setTimeout(() => {
                    this.handleChangeSubject(this.props.discussion.subjectId);
                    // }, 2000)
                }
            );


            if (this.props.subjectData && this.props.subjectData.length) {
                const subjects = [];
                this.props.subjectData.map((sub) => {
                    subjects.push({ subjectId: sub.subjectId, subjectName: sub.subjectName });
                });

                this.setState({
                    subjects: subjects //[{ subjectId: '', subjectName: 'Select Subject' }, ...subjects]
                });
            }
        }

        if (!this.props.boardsInfo) {

            this.props.getActiveOrganizationData(true);
        }




    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.isEditQuestionValid !== this.props.isEditQuestionValid) {
        if (prevProps.discussion != this.props.discussion) {
            this.handleChangeBoard(this.props.discussion.boardId);
            this.handleChangeClass(this.props.discussion.classId);
            // this.handleChangeGroup(this.props.discussion.groupId);
            setTimeout(() => {
                this.handleChangeSubject(this.props.discussion.subjectId);
            }, 2000);
        }
        // }

        if (prevProps.boardsInfo !== this.props.boardsInfo) {
            if (this.props.boardsInfo) {
                const boards = [];
                this.props.boardsInfo.boards &&
                    this.props.boardsInfo.boards.map((item, index) => {
                        boards.push({ boardId: item.boardId, boardName: item.boardName });
                    });
                this.setState(
                    {
                        board: boards
                    },
                    () => this.handleChangeBoard()
                );
            }
        }

        if (prevProps.subjectData !== this.props.subjectData) {
            if (this.props.subjectData && this.props.subjectData.length) {
                const subjects = [];
                this.props.subjectData.map((sub) => {
                    subjects.push({ subjectId: sub.subjectId, subjectName: sub.subjectName });
                });

                this.setState({
                    subjects: subjects //[{ subjectId: '', subjectName: 'Select Subject' }, ...subjects]
                });
            } else {
                this.setState({
                    subjects: [
                        {
                            subjectName: 'no data found',
                            subjectId: ''
                        }
                    ]
                });
            }


        }

        if (!this.state.isRowsSet && this.contentRef?.current) {
            this.calcRows(this.contentRef?.current)
        }


        // if (prevProps.editQuestionDetails !== this.props.editQuestionDetails) {
        //     if (this.props.editQuestionDetails) {
        //         const boards = [];
        //         this.props.boardsInfo &&
        //             this.props.boardsInfo.boards &&
        //             this.props.boardsInfo.boards.map((item, index) => {
        //                 boards.push({ boardId: item.boardId, boardName: item.boardName });
        //             });
        //     }
        // }

        // if (prevProps.editQuestionInfoSuccess !== this.props.editQuestionInfoSuccess) {
        //     if (this.props.editQuestionInfoSuccess) {
        //         this.setState(
        //             {
        //                 snackBarMsg: this.props.editQuestionInfoSuccess,
        //                 snackBarOpen: true
        //             },
        //             () => {
        //                 setTimeout(() => {
        //                     this.setState(
        //                         {
        //                             changeComponent: true,
        //                             isFetch: true,
        //                             isEdit: false,
        //                             isTemplate: false,
        //                             isCreateTest: false,
        //                             isEditTest: false,
        //                             groups: [],
        //                             subjects: [],
        //                             chapters: [],
        //                             topics: []
        //                         },
        //                         () => this.handleChangeBoard(MELUHA_BOARD_ID)
        //                     );
        //                 }, 1000);
        //             }
        //         );
        //     }
        // }
    }


    calcRows = (event) => {


        const textareaLineHeight = 24;
        const { minRows, maxRows } = this.state;

        const previousRows = event.rows;
        event.rows = minRows; // reset number of rows in textarea 

        const currentRows = ~~(event.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.rows = maxRows;
            event.scrollTop = event.scrollHeight;
        }


        this.setState((prevState) => ({
            rows: currentRows < maxRows ? currentRows : maxRows,
            isRowsSet: true
        }));
    }



    onFocus = () => {
        this.setState({
            showCreateDialog: true,

        }, () => {
            // this.getSubjects()
        });
    }

    onClose = () => {

        this.setState({
            showCreateDialog: false,
            subjects: [],
            userBoard: null,
            userClass: null,
            userGroup: null,
            userBoardName: '',
            userClassName: '',
            userGroupName: '',

            subjectId: '',
            subjectName: '',
            openSubjectSelector: false,
            content: '',
            errorMsg: '',
            isQuestionOfTheDay: false,
            attachments: [],
            uploadedFilesInfo: [],
            Reset: true,

            errors: {
                board: false,
                class: false,
                group: false,
                subject: false,
                content: false
            }
        }, () => {
            if (this.props.discussion) {
                this.props.onEditComplete();
            }

            this.props.clearSelectedOrganizationData()
        });
    }

    openSubjectSelector = (event) => {
        this.setState({
            openSubjectSelector: true,
            anchorRef: event.currentTarget
        });
    }

    onTextChnage = (event) => {
        let e = event.currentTarget.value;

        const textareaLineHeight = 24;
        const { minRows, maxRows } = this.state;

        const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea 

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }


        this.setState((prevState) => ({
            content: e,
            errors: { ...prevState.errors, content: e.length < 10 ? true : false },
            rows: currentRows < maxRows ? currentRows : maxRows
        }));
    }


    _onProgress = (filesInfo) => {

        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == filesInfo.name) {
                file = filesInfo;
            }
            return file;
        })

        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });
    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Invalid', detail: errMsg, life: 3000 });
    }

    _afterFilesUploaded = (filess) => {
        let files = _.cloneDeep(Array.from(filess));
        let uploadedFilesInfo = this.state.uploadedFilesInfo;
        for (let i = 0; i < files.length; i++) {
            if (!uploadedFilesInfo.find(a => a.name == files[i].name)) {
                uploadedFilesInfo = [...uploadedFilesInfo, files[i]]
            }
        }
        this.setState((prevState, prevProps) => ({
            uploadedFilesInfo
        }));
    }

    _onSuccess = (fileName, data) => {
        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == fileName) {
                file.s3Info = data;
                file.uri = data.Location;
            }
            return file;
        })
        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });

    }

    removeAttachment = (attachment) => {
        let uploadedFilesInfo = this.state.uploadedFilesInfo.filter(a => a.id != attachment.id);
        this.setState({ uploadedFilesInfo });
    }



    createDiscussion = () => {
        if (!this.state.isLoading) {


            const { content, attachments, uploadedFilesInfo, userBoard, userClass, userGroup,
                userBoardName,
                userClassName,
                userGroupName,
                subjectId, subjectName, isQuestionOfTheDay } = this.state;

            let errors = {
                board: false,
                class: false,
                group: false,
                subject: false,
                content: false
            };
            let errorMsg = '';

            if (!userBoard || userBoard == '') {
                errors.board = true;
                // this.setState({
                //     errorMsg: 'Please select board',

                // });
                // return;
            }
            if (!userClass || userClass == '') {
                errors.class = true;
                // this.setState({
                //     errorMsg: 'Please select class'
                // });
                //return;
            }
            if (!userGroup || userGroup == '') {
                errors.group = true;
                // this.setState({
                //     errorMsg: 'Please select group'
                // });
                // return;
            }
            if (subjectId == '') {
                errors.subject = true;
            }
            if (content == '') {
                errors.content = true;
            }
            if (content.length <= 10) {
                errors.contentLegnth = true;
                errorMsg = 'Minimum 10 characters required';
            }



            if (Object.values(errors).some(e => e === true)) {

                this.setState({
                    errors,
                    errorMsg
                });

                setTimeout(() => {
                    this.setState({
                        errorMsg: null
                    });
                }, 3000);
            }





            if (Object.values(errors).every(e => e === false)) {




                if (uploadedFilesInfo && uploadedFilesInfo.length) {

                    let uploadInProgress = false;
                    for (let i = 0; i < uploadedFilesInfo.length; i++) {
                        if (!uploadedFilesInfo[i].uri) {
                            uploadInProgress = true;
                            break;
                        }
                    }
                    if (uploadInProgress) {
                        this.setState({
                            errorMsg: 'Please wait attachments are uploading...'
                        });

                        setTimeout(() => {
                            this.setState({
                                errorMsg: ''
                            });

                        }, 3000);
                        return;
                    }


                }


                let attachmentT = uploadedFilesInfo.map(attach => {
                    return {
                        type: getFileType(attach.type),
                        uri: attach.uri,
                        name: attach.name,
                        newFileName: attach.newFileName,
                        mimeType: attach.type
                    }
                })


                const discussionInfo = {
                    boardId: userBoard,
                    classId: userClass,
                    groupId: userGroup,
                    boardName: userBoardName,
                    className: userClassName,
                    groupName: userGroupName,
                    subjectId, subjectName, content,
                    attachments: [attachmentT],
                    isQuestionOfTheDay,

                }
                this.setState({
                    isLoading: true,
                });
                if (this.props.discussion) {

                    const url = `${discussionBoardEndpoint}/posts/${this.props.discussion._id}`;
                    this.service.put(url, discussionInfo, true).then(data => {

                        if (data && data.status) {
                            if (data.res && data.res.status == 'success') {
                                this.setState({
                                    showCreateDialog: false,
                                    subjects: [],
                                    userBoard: null,
                                    userClass: null,
                                    userGroup: null,
                                    subjectId: '',
                                    subjectName: '',
                                    openSubjectSelector: false,
                                    content: '',
                                    errorMsg: '',
                                    isQuestionOfTheDay: false,
                                    attachments: [],
                                    uploadedFilesInfo: [],
                                    Reset: true,
                                    isLoading: false
                                }, () => {
                                    this.props.onCreateDiscussion(data.res.data.doc);
                                    this.props.clearSelectedOrganizationData()
                                });
                            } else {

                                this.setState({
                                    errorMsg: data.res.message,
                                    isLoading: false
                                })
                            }
                        } else {
                            this.setState({
                                errorMsg: data.errMessage,
                                isLoading: false
                            })

                        }
                    });



                } else {
                    const url = `${discussionBoardEndpoint}/posts`;
                    this.service.post(url, discussionInfo, true).then(data => {

                        if (data && data.status) {

                            if (data.res && data.res.status == 'success') {
                                this.setState({
                                    showCreateDialog: false,
                                    subjects: [],
                                    userBoard: null,
                                    userClass: null,
                                    userGroup: null,
                                    subjectId: '',
                                    subjectName: '',
                                    openSubjectSelector: false,
                                    content: '',
                                    errorMsg: '',
                                    isQuestionOfTheDay: false,
                                    attachments: [],
                                    uploadedFilesInfo: [],
                                    Reset: true,
                                    isLoading: false
                                }, () => {
                                    this.props.onCreateDiscussion(data.res.data.doc);
                                    this.props.clearSelectedOrganizationData()
                                });

                            } else {

                                this.setState({
                                    errorMsg: data.res.message,
                                    isLoading: false
                                })
                            }
                        } else {
                            this.setState({
                                errorMsg: data.errMessage,
                                isLoading: false
                            })

                        }
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                <div className="ma-box create-desc pb-3">
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <div style={{ width: '40px', float: 'left' }}>
                            <img src={getProfileURL(this.props?.discussionUser && this.props?.discussionUser?.photo)} alt="" className="profile-img" />
                        </div>
                        <div style={{ marginLeft: '40px' }}>
                            <p className="hello-txt">Hello!</p>
                            <p className="profile-name">{this.props.discussionUser && this.props.discussionUser.name}</p>
                            <p className="teacher-label"> <span>Teacher</span></p>
                        </div>
                    </div>
                    <div onClick={this.onFocus}>
                        <p className="start-desc" >Let's Start Discussion!</p>
                    </div>
                </div>
                <Dialog
                    visible={this.state.showCreateDialog}
                    onHide={this.onClose}
                    closable={false}
                    blockScroll={true}
                    showHeader={false}
                    contentClassName={""}
                    className='create-discussion-dilog'
                >
                    <div className="create-discussion">
                        <div className='grid' >
                            <div className='col-9 ' >
                                <p className="title" >{this.props.discussion ? 'Edit discussion' : 'Create Discussion'} </p>
                            </div>
                            <div className="col-3 create-desc" >
                                <div className='grid' >
                                    <div className='col-9' >
                                        <div className='flex justify-content-end' style={{ width: '100%', overflow: 'hidden' }}>
                                            <div style={{ width: '40px', float: 'left' }}>
                                                <img src={getProfileURL(this.props.discussionUser && this.props.discussionUser.photo)} alt="n" className="profile-img" />
                                            </div>
                                            <div>
                                                <p className="hello-txt">Hello!</p>
                                                <p className="profile-name">{this.props.discussionUser && this.props.discussionUser.name}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-1' >
                                        <span style={{ float: 'right' }} className='ma-pointer' onClick={this.onClose} >
                                            <i className="pi pi-times"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <Toolbar className="ma-toolbard"
                            left={() => {
                                return (
                                    <React.Fragment>
                                        <p className="title" >{this.props.discussion ? 'Edit discussion' : 'Create Discussion'} </p>
                                    </React.Fragment>
                                )
                            }}
                            right={() => {
                                return (
                                    <React.Fragment>
                                        <div className='grid create-disc-head' >
                                            <div className='col-9' >
                                                <div style={{ width: '100%', overflow: 'hidden', marginRight: '10px' }}>
                                                    <div style={{ width: '40px', float: 'left' }}>
                                                        <img src={getProfileURL(this.props?.discussionUser && this.props?.discussionUser?.photo)} alt="n" className="profile-img" />
                                                    </div>
                                                    <div style={{ marginLeft: '40px' }}>
                                                        <p className="hello-txt">Hello!</p>
                                                        <p className="profile-name">{this.props.discussionUser && this.props.discussionUser.name}</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-1' >
                                                <span style={{ float: 'right' }} className='ma-pointer' onClick={this.onClose} >
                                                    <i className="pi pi-times"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }}
                        ></Toolbar> */}

                        <hr style={{ width: '100%' }} className="ma-hr2" />
                        <div className='grid mt-3 ml-3'>
                            <div className='col-3'>
                                <Dropdown
                                    value={
                                        (this.props.selectedOrganizationDataIds && this.props.selectedOrganizationDataIds.boardId) || ''
                                    }
                                    className='ma-w90p w-11'
                                    options={this.state.board.map((item) => { return { value: item.boardId, label: item.boardName } })}
                                    onChange={(e) => this.handleChangeBoard(e.value)}
                                    placeholder="Select Board" />
                                <span className='ma-required'>*</span>
                            </div>
                            <div className='col-3'>
                                <Dropdown
                                    options={this.state.classes.map((item) => { return { value: item.classId, label: item.className } })}
                                    onChange={(e) => this.handleChangeClass(e.value)}
                                    value={
                                        (this.props.selectedOrganizationDataIds && this.props.selectedOrganizationDataIds.classId) || ''
                                    }
                                    className='ma-w90p w-11'
                                    placeholder="Select Class" />
                                <span className='ma-required'>*</span>
                            </div>
                            {/* <div className='p-col-3'>
                                <Dropdown
                                    //value={this.state.groupId}
                                    options={this.state.groups.map((item) => { return { value: item.groupId, label: item.groupName } })}
                                    onChange={(e) => this.handleChangeGroup(e.value)}
                                    value={
                                        (this.props.selectedOrganizationDataIds && this.props.selectedOrganizationDataIds.groupId) || ''
                                    }
                                    className='ma-w90p'
                                    placeholder="Select Group" />
                                <span className='ma-star-mark'>*</span>
                            </div> */}
                            <div className='col-3'>
                                <Dropdown
                                    // value={this.state.groupId}
                                    options={this.state.subjects.map((item) => { return { value: item.subjectId, label: item.subjectName } })}
                                    value={this.state.subjectId}
                                    onChange={(e) => this.handleChangeSubject(e.value)}
                                    className='ma-w90p w-11'
                                    placeholder="Select Subject" />
                                <span className='ma-required'>*</span>
                            </div>
                        </div>


                        <div className={`discussion-scoll-area  ${this.state.errors.content ? 'error-bord' : ''}`}>
                            <textarea
                                className="discussion-txtarea"
                                value={this.state.content}
                                placeholder="Type Your Question Here...."
                                onChange={this.onTextChnage}
                                rows={this.state.rows}
                                ref={this.contentRef}
                            />
                            <AttachmentsU attachments={this.state.uploadedFilesInfo} removeAttachment={this.removeAttachment} />
                        </div>
                        <div className="col-12">
                            <Checkbox inputId="qod" value="Question of the day" onChange={(e) => { this.setState({ isQuestionOfTheDay: e.checked }) }}
                                checked={this.state.isQuestionOfTheDay}
                            ></Checkbox>
                            <label htmlFor="qod" className="p-checkbox-label ml-2">Question of the day</label>
                        </div>
                        {this.state.errorMsg != '' && <p className="create-err">{this.state.errorMsg} </p>}
                    </div>

                    <div >
                        <div className="ma-clearFix"> </div>

                        {/* <Toolbar className="crete-disc-toolbar"
                            left={() => {
                                return ( */}
                                    <div className='flex justify-content-between mx-4'>
                                        <div>
                                            <ul className="comment-attachments" >
                                                <li>
                                                    <FileUpload id={"file" + new Date().getTime().toString().substring(4) + 12}
                                                        multiple={false}
                                                        onProgress={this._onProgress}
                                                        onSucess={this._onSuccess}
                                                        Reset={this.state.Reset}
                                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                                        onError={this._onFilsUploadError}
                                                        accept={'audios'}
                                                        title={'add audio'}
                                                        afterFilesUploaded={this._afterFilesUploaded} >
                                                        <img src="./images/Voice_recorder.svg" className="li-img-icon" />
                                                    </FileUpload>
                                                </li>
                                                <li>
                                                    <FileUpload id={"file" + (new Date().getTime().toString().substring(5) + 30)}
                                                        multiple={false}
                                                        onProgress={this._onProgress}
                                                        Reset={this.state.Reset}
                                                        onSucess={this._onSuccess}
                                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                                        onError={this._onFilsUploadError}
                                                        accept={'images'}
                                                        title={'add image'}
                                                        afterFilesUploaded={this._afterFilesUploaded} >
                                                        <img src="./images/Upload_Image.svg" className="li-img-icon" />
                                                    </FileUpload>
                                                </li>
                                                <li>
                                                    <FileUpload id={"file" + (new Date().getTime().toString().substring(5) + 40)}
                                                        multiple={false}
                                                        onProgress={this._onProgress}
                                                        Reset={this.state.Reset}
                                                        onSucess={this._onSuccess}
                                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                                        onError={this._onFilsUploadError}
                                                        accept={'videos'}
                                                        title={'add video'}
                                                        afterFilesUploaded={this._afterFilesUploaded} >
                                                        <img src="./images/Upload_video.svg" className="li-img-icon" />
                                                    </FileUpload>
                                                </li>
                                                <li>
                                                    <FileUpload id={"file" + (new Date().getTime().toString().substring(5) + 50)}
                                                        multiple={false}
                                                        onProgress={this._onProgress}
                                                        Reset={this.state.Reset}
                                                        onSucess={this._onSuccess}
                                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                                        onError={this._onFilsUploadError}
                                                        accept={'pdfs'}
                                                        title={'add pdf'}
                                                        afterFilesUploaded={this._afterFilesUploaded} >
                                                        <img src="./images/Upload_Documents.svg" className="li-img-icon" />
                                                    </FileUpload>
                                                </li>
                                            </ul>
                                        </div>
                                    {/* </React.Fragment>
                                )
                            }}
                            right={() => {
                                return (
                                    <React.Fragment> */}
                                        <div className='mb-3'>
                                            <Button
                                                disabled={(this.state.subjectId == '' || this.state.content == '')}
                                                label={this.props.discussion ? 'Update' : 'Create'}
                                                className={`${(this.state.subjectId == '' || this.state.content == '') ? 'p-button-raised' : ''}`}
                                                onClick={this.createDiscussion}
                                            />
                                        </div>
                                    </div>
                                {/* )
                            }}
                        ></Toolbar> */}





                    </div>
                </Dialog>
                {
                    this.props.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} className="test-bottm" position="bottom-right" />
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
        loadingInfo: state.gradeDetailsInfo && state.gradeDetailsInfo.loading,
        gradeDetailsInfo: state.gradeDetailsInfo && state.gradeDetailsInfo.response,
        editQuestionDetails: state.editQuestionInfo && state.editQuestionInfo.questionIdInfo,
        isEditQuestionValid: state.editQuestionInfo && state.editQuestionInfo.editQuestion,
        editQuestionInfoSuccess:
            state.editQuestionInfo && state.editQuestionInfo.response && state.editQuestionInfo.response.success,

        isLoading: state.curriculumData && state.curriculumData.loading,
        subjectData:
            state.curriculumData &&
            state.curriculumData.response &&
            state.curriculumData.response.Item &&
            state.curriculumData.response.Item.subjects &&
            state.curriculumData.response.Item.subjects,
        chapterData:
            state.curriculumData &&
            state.curriculumData.subjectInfo &&
            state.curriculumData.subjectInfo.length &&
            state.curriculumData.subjectInfo[0] &&
            state.curriculumData.subjectInfo[0].chapters,
        topicData:
            state.curriculumData &&
            state.curriculumData.topicsInfo &&
            state.curriculumData.topicsInfo.length &&
            state.curriculumData.topicsInfo[0] &&
            state.curriculumData.topicsInfo[0].topics,
        selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
        organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
        addQuestionInfoResponse:
            state.addQuestionInfo && state.addQuestionInfo.response && state.addQuestionInfo.response.success,
        isTemplateInfo: state.editTemplateInfo && state.editTemplateInfo.isTemplate,
        boardsInfo:
            state.organizationData &&
            state.organizationData.response &&
            state.organizationData.response.Item &&
            state.organizationData.response.Item,
        editQuestionInfoSuccess:
            state.editQuestionInfo &&
            state.editQuestionInfo.response &&
            state.editQuestionInfo.response.successResponse &&
            state.editQuestionInfo.response.successResponse[0].message
    }
}

export default connect(mapStateToProps, {
    selectBoard,
    selectClass,
    selectGroup,
    updateSubject,
    getSubject,
    selectTopic,
    selectChapter,
    selectQuestionTarget,
    selectSubject,
    getTopics,
    getOrganizationData,
    getActiveOrganizationData,
    getCurriculum,
    clearSelectedOrganizationData,
    clearGetQuestion,
    clearAddQuestion,
    dispatchClearCurriculumData
})(withRouter(CreateDiscussion));
