import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';

// import Navbar from './../navbar';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { TabMenu } from 'primereact/tabmenu';
import { primaryColor } from '../../store/apiConstants';
import FeeConcession from './feeConcession';
import Banking from './banking';
import Fine from './fine';


const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const tabs = [
    { label: 'Vehicle Management' },
    { label: 'Route Management' },
    { label: 'Assign Management' },
    { label: 'Transport Fee ' },
];


class FinanceNewIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        };
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Finance</h4>
            </React.Fragment>
        )
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps.permissionIds !== this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {
                if (!(isAuthorized(this.props.permissionIds, PERMISSIONS.SHOW_ROLES_LIST, this.props.userInfo))) {

                    this.setState({
                        currentTab: 0
                    })
                }
            }
        }
    }

    render() {

        return (
            <div >

                <div className="">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                    </div>
                    {/* <div className="-mt-3">
                        <Toolbar className="border-none" 
                         left={this.leftToolbarTemplate} 
                        right={this.rightToolbarTemplate}></Toolbar>
                    </div> */}
                </div>


                <div className="">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-4 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tabheadText font-bold " style={{color:'black'}} onClick={() => { this.onTabChange(0) }}>Fee Concession</p>
                                </div>
                                <div className=" cursor-pointer col-4 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tab-p" style={{color:'black'}} onClick={() => { this.onTabChange(1) }}>Banking</p>
                                </div>
                                <div className=" cursor-pointer col-4 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tab-p" style={{color:'black'}} onClick={() => { this.onTabChange(2) }}>Fine</p>
                                </div>
                                
                                {/* <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                                    <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>List</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {
                        this.state.currentTab == 0 && <>
                            {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} > */}
                                <FeeConcession />
                            {/* </Authorizer> */}

                        </>
                    }
                    {
                        this.state.currentTab == 1 && <>
                            {/* <Authorizer permId={PERMISSIONS.SHOW_USERS_LIST} > */}
                                <Banking />
                            {/* </Authorizer> */}
                        </>
                    }
                    {
                        this.state.currentTab == 2 && <>
                            {/* <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} > */}
                                <Fine />
                            {/* </Authorizer> */}

                        </>
                    }


                </div>

            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(FinanceNewIndex)));