import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';
import './styles.scss';
import { connect } from 'react-redux';
import Authentication from './../session';

import Authorizer from '../session/authorizer';
import axios from 'axios';
import LoadingComponent from '../loadingComponent';
import { getServerTime } from '../../utile';
import UsersStats from './widgets/usersStats';
import ResourceUsageReport from './widgets/resourseUsageReport';
import TrendingChapters from './widgets/trendingChapters';
import AskADoubtStats from './widgets/askADoubtStats';
import DiscussionBoardStats from './widgets/discussionboardStats';

import Notifications from './widgets/notifications'
import ChapterStrength from './widgets/chapterStrength';
import BrachWiseUsageReport from './widgets/BrachWiseUsageReport';
import DeviceWiseAtiveReport from './widgets/DeviceWiseAtiveReport';
import QuestionBankReport from './widgets/QuestionBankReport';
import ResourceWiseContent from './widgets/ResourceWiseContent';
import LanguageWiseResources from './widgets/LanguageWiseResources';
import TicketManagement from './widgets/ticketManagement';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import { cloneDeep } from 'lodash';
// import SampleReport from './widgets/sampleReport';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import GridLayout from 'react-grid-layout';
import DashBoardLayout from './widgets/dashboardLayout';


const ResizeHandle = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";


class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            questionBankReport: [],
            resourceWiseContent: [],
            langWiseResource: [],
            dashboardWidgets: [
                { label: "Usage", value: 1, isVisible: true },
                { label: "Resource usage report", value: 2, isVisible: true },
                { label: "Course completion status", value: 3, isVisible: true },
                { label: "Resource wise content", value: 4, isVisible: true },
                { label: "Language wise resources", value: 5, isVisible: true }

            ],
        }
        this.service = new Service()
    }



    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>DashBoard</h2>
            </React.Fragment>
        )
    }

    handleDownload = (url, filename) => {

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {

                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    getServerTime().getTime().toString() + '.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }


    genAndDownloadPaper = () => {
        this.setState({
            isLoading: true
        });
        let url = 'http://localhost:3000/v1/data/examination/d0c5e162-d23d-468c-9b60-958266f8f46d/download-paper';
        axios.get(url, {})
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.paperLink) {
                    this.handleDownload('https://classetofflinecontent.s3.ap-south-1.amazonaws.com/' + res.data.data.paperLink)
                    this.setState({
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch(e => {

                this.setState({
                    isLoading: false
                });
            });

    }

    componentDidMount() {
        // this.getWidgetsData()
    }

    getWidgetsData = () => {
        this.setState({ isLoading: true })
        const url = `${baseUrlAdmin}/notification-manager/dashboard-stats`;
        this.service.get(url, true).then(res => {
            if (res && res.status) {
                let array1 = cloneDeep(res?.res?.languages)
                let array2 = []
                array1.forEach(item => {
                    let existingItem = array2.find(i => i.contentLanguage === item.contentLanguage);
                    if (existingItem) {
                        existingItem[item.contentResourceType] = item.count;
                    } else {
                        let newItem = {
                            contentLanguage: item.contentLanguage,
                            [item.contentResourceType]: item.count
                        };
                        array2.push(newItem);
                    }
                });
                this.setState({
                    questionBankReport: res?.res?.questions,
                    resourceWiseContent: res?.res?.resource,
                    langWiseResource: array2,
                    isLoading: false
                })
            } else {
                this.setState({ isLoading: false });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
        })
    }
    render() {

        const handleGrid = function (widget) {
            const defaultLayout = { x: 0, y: 0, h: 6, w: 6 };
            let fullWidthLayout = { x: 6, y: 0, h: 6, w: 6 };
            if (widget.value == 1) {
                fullWidthLayout = { x: 0, y: 0, h: 2, w: 6 }
            }

            return widget.isVisible ? fullWidthLayout : defaultLayout;
        }
        return (
            <>
            <DashBoardLayout/>

                {/* <div className='p-d-flex p-jc-end p-ai-center p-mr-4'>
                    <Button className='p-px-3 p-py-2'
                        onClick={(e) => {
                            this.overlayPanelRef.current.toggle(e)
                            this.setState({
                                dashboardFields: true,
                                selectedDashboardWidgets: this.state.dashboardWidgets
                            })
                        }}
                    ><i style={{ fontSize: '1.5rem' }} class="pi pi-cog 
                        "></i></Button>
                    <OverlayPanel
                        ref={this.overlayPanelRef}
                        showCloseIcon={true}
                        style={{
                            position: 'absolute',
                            top: '100%',
                            left: '0',
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            zIndex: 1000,
                            width: '200px', // Adjust the width as needed
                        }}
                    > */}
                {/* {this.renderOverlayContent()} */}
                {/* </OverlayPanel>
                </div> */}
                {/* <div className='ma-toolbar'>
                        <Toolbar className="p-mb-4" right={this.rightToolbarTemplate}></Toolbar>
                    </div> */}


                {/* <div className="ma-main">

                    <div className='p-grid '>
                        <div className='p-col-12 p-md-9 '>
                            <UsersStats />
                            <div className='p-grid'>
                                <div className='p-col-12 p-md-6'>
                                    <ResourceUsageReport />
                                </div>
                                <div className='p-col-12 p-md-6'>
                                    <TrendingChapters />
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-12 p-md-6'>
                                    <AskADoubtStats />
                                </div>
                                <div className='p-col-12 p-md-6'>
                                    <DiscussionBoardStats />
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-12 p-md-6'>
                                    <BrachWiseUsageReport />
                                </div>
                                <div className='p-col-12 p-md-6'>
                                    {/* <DeviceWiseAtiveReport /> */}
                {/* <QuestionBankReport questionBankReport={this.state.questionBankReport} />
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-12 p-md-6'>
                                    <ResourceWiseContent resourceWiseContent={this.state.resourceWiseContent} />
                                </div>
                                <div className='p-col-12 p-md-6'>
                                    <LanguageWiseResources langWiseResource={this.state.langWiseResource} />
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-12 p-md-6'>
                                    <TicketManagement />
                                </div>
                                <div className='p-col-12 p-md-6'>
                                    {/* <SampleReport /> */}
                {/* </div>
                            </div> */}
                {/* <div className='p-grid'>
                                    <div className='p-col-12 p-md-6'>
                                        <ChapterStrength />
                                    </div>
                                   
                                </div> */}
                {/* </div>
                        <div className='p-col-12 p-md-3'>
                            <Notifications />

                        </div>

                    </div>

                </div> */}


                {/* start */}

                {/* <div>
                    <GridLayout cols={12} rowHeight={10} width={1650} >

                        {this.state.dashboardWidgets[0].isVisible === true ? <div resizeHandles={true} allowOverlap={false} key="a" data-grid={{ x: 0, y: 0, h: 5, w: 12 }}>
                            <UsersStats />
                        </div> : <></>}

                        {this.state.dashboardWidgets[1].isVisible === true ? <div resizeHandles={true} key="b" allowOverlap={false} data-grid={{ x: 0.1, y: 0, w: 5.7, h: 6 }}>
                            <ResourceUsageReport />
                        </div> : <></>}

                        {this.state.dashboardWidgets[2].isVisible === true ? <div allowOverlap={false} key="c" data-grid={{ x: 6, y: 0, w: 5.7, h: 6 }}>
                            <TrendingChapters />
                        </div> : <></>}

                        {this.state.dashboardWidgets[3].isVisible === true ? <div allowOverlap={false} key="e" data-grid={{ x: 0.1, y: 0, w: 5.7, h: 7.6 }}>
                            <AskADoubtStats resourceWiseContent={this.state.resourceWiseContent} />
                        </div> : <></>}
                        {this.state.dashboardWidgets[3].isVisible === true ? <div allowOverlap={false} key="f" data-grid={{ x: 0.1, y: 0, w: 5.7, h: 7.6 }}>
                            <DiscussionBoardStats resourceWiseContent={this.state.resourceWiseContent} />
                        </div> : <></>}
                        {this.state.dashboardWidgets[3].isVisible === true ? <div allowOverlap={false} key="g" data-grid={{ x: 0.1, y: 0, w: 5.7, h: 7.6 }}>
                            <BrachWiseUsageReport resourceWiseContent={this.state.resourceWiseContent} />
                        </div> : <></>}
                        {this.state.dashboardWidgets[3].isVisible === true ? <div allowOverlap={false} key="h" data-grid={{ x: 0.1, y: 0, w: 5.7, h: 7.6 }}>
                            <DeviceWiseAtiveReport resourceWiseContent={this.state.resourceWiseContent} />
                        </div> : <></>}
                        {this.state.dashboardWidgets[3].isVisible === true ? <div allowOverlap={false} key="i" data-grid={{ x: 0.1, y: 0, w: 5.7, h: 7.6 }}>
                            <ResourceWiseContent resourceWiseContent={this.state.resourceWiseContent} />
                        </div> : <></>}
                        {this.state.dashboardWidgets[3].isVisible === true ? <div allowOverlap={false} key="" data-grid={{ x: 0.1, y: 0, w: 5.7, h: 7.6 }}>
                            <LanguageWiseResources langWiseResource={this.state.langWiseResource} />
                        </div> : <></>}

                    </GridLayout>
                </div> */}

                {/* end */}




                {/* <button onClick={() => this.genAndDownloadPaper()}>Download</button> */}
                {/* <div className="p-grid">

                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Organization</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Curriculum</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Content Master</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Package Master</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> */}
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }


            </>
        )
    }
}



export default connect(null, {})(Authentication(DashBoard));