import React, { useState } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Barcharts1 from './barcharts1';
import AreaChart from './areaChart';
import RadarChart from './radarChart';
import LineChart from './lineChart';
import HeatMapChart from './heatMapChart';
import PieChart from './pieChart';
import ScatterChart from './scatterChart';
import TableOne from './tableOne';
import BusinessTable from './tableTwo';

const DashBoardLayout = () => {
  const [layoutStyle, setLayoutStyle] = useState([]);

  const handleLayoutChange = (newLayout) => {
    setLayoutStyle(newLayout);
  };

  const layout = [
    { i: '1', x: 0, y: 0, w: 3, h: 1 },
    { i: '2', x: 3, y: 0, w: 3, h: 1 },
    { i: '3', x: 6, y: 0, w: 3, h: 1 },
    { i: '4', x: 9, y: 0, w: 3, h: 1 },
    
    { i: '5', x: 0, y: 1, w: 3, h: 1 },
    { i: '6', x: 3, y: 1, w: 3, h: 1 },
    { i: '7', x: 6, y: 1, w: 3, h: 1 },
    { i: '8', x: 9, y: 1, w: 3, h: 1 },
    
    { i: '9', x: 0, y: 2, w: 6, h: 1 },
    { i: '10', x: 6, y: 2, w: 6, h: 1 },
   
  ];

  // const layout = [
  //   { i: '1', x: 0, y: 0, w: 1, h: 1 },
  //   { i: '2', x: 3, y: 0, w: 1, h: 1 },
  //   { i: '3', x: 6, y: 0, w: 1, h: 1 },
  //   { i: '4', x: 9, y: 0, w: 1, h: 1 },
  //   { i: '5', x: 0, y: 1, w: 1, h: 1 },
  //   { i: '6', x: 3, y: 1, w: 1, h: 1 },
  //   { i: '7', x: 6, y: 1, w: 1, h: 1 },
  //   { i: '8', x: 9, y: 1, w: 1, h: 1 },
  //   { i: '9', x: 0, y: 2, w: 1, h: 1 },
  //   { i: '10', x: 6, y: 2, w: 1, h: 1 },
    
  // ];

  
  
  return (
    <>
      <style>
        {`
          /* Default styles for all devices */
          .layout .responsive-grid-item {
            boxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            borderRadius: 10px;
            border: 1px grey solid;
          }

          /* Add responsive styles for medium devices (e.g., tablets) */
          @media (max-width: 1024px) {
            .layout .responsive-grid-item {
              width: 100% !important;
            }
          }

          /* Add responsive styles for small devices (e.g., mobile phones) */
          @media (max-width: 768px) {
            .layout .responsive-grid-item {
              width: 100% !important;
            }
          }
        `}
      </style>

      <GridLayout
        className="layout"
        layout={layout}
        cols={16} // Four columns for each screen size
        rowHeight={250}
        width={1600}
      
        onLayoutChange={handleLayoutChange}
      >
        {layout.map((item) => (
          <div
            key={item.i}
            resizeHandles={true}
            allowOverlap={false}
            data-grid={item}
            style={{
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: "10px",
              border: "1px grey solid",
            }}
            className='responsive-grid-item'
          >
            {item.i === '1' ? <div>
              <Barcharts1  />
            </div> : null}

            {item.i === '2' ? <div >
              <AreaChart />
            </div> : null}

            {item.i === '3' ? <div >
              <RadarChart />
            </div> : null}

            {item.i === '4' ? <div >
              <LineChart />
            </div> : null}

            {item.i === '5' ? <div >
              <HeatMapChart />
            </div> : null}

            {item.i === '6' ? <div >
              <PieChart />
            </div> : null}

            {item.i === '7' ? <div >
              <HeatMapChart />
            </div> : null}

            {item.i === '8' ? <div >
              <PieChart />
            </div> : null}
            {item.i === '9' ? <div style={{
              backgroundColor: "rgb(167 243 208)",
              width: "100%", // Set to 100% for responsiveness
           
           
              borderRadius: "10px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // Set your maximum height
             
            }}><TableOne /></div> : null}

            {item.i === '10' ? <div style={{
              backgroundColor: "rgb(191 219 254)",
             
              borderRadius: "10px",

              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%", // Set to 100% for responsiveness
           
         
           
            }}><BusinessTable /></div> : null}
          </div>
        ))}
      </GridLayout>
    </>
  );
};

export default DashBoardLayout;
