import React from 'react';
import { connect } from 'react-redux';

import './styles.scss'
import DoubtsList from './doubtsList';
import Conversation from './conversation';
import { askADoubtSocketEndpoint, getSoketEndPoint } from '../../store/apiConstants';
import SocketService from './socketService';
import Authentication from '../session';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
const TEACHER_ID = localStorage.getItem('discussion_uId');

const items = [
    { label: 'Academics' },
    { label: 'Ask a doubt ?', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class AskADoubt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            selectedDoubt: null,
            currentSocketMessage: null,
            eventUID: 1,
        };
        this.socketService = new SocketService(getSoketEndPoint(), this.onConnectionOpen, this.onMessageRecieve);
    }

    componentWillUnmount() {
        this.socketService.closeConnection();
    }

    onConnectionOpen = (data) => {
        this.connectToOnline();
    };

    connectToOnline = async () => {
        if (this.props.discussionUser) {
            let payload = {
                action: "online",
                data: {
                    user: this.props.discussionUser ? this.props.discussionUser._id : null,
                    isOnline: true
                },
            };
            await this.socketService.socketSend(payload)
        }
    }

    onMessageRecieve = (data) => {
        let response = JSON.parse(data.data);
        //  console.log('onMessageRecieve', response)
        let eventUID = this.state.eventUID + 1;
        this.setState({
            currentSocketMessage: { ...response, eventUID },
            eventUID
        });
    }

    onTabChange = (newValue) => {
        this.setState({
            currentTab: newValue
        });
    }

    onDoubtRespond = (tab) => {
        setTimeout(() => {
            this.setState({
                currentTab: tab
            });
        }, 2000);
    }

    // onDoubtClosed = () => {
    //     setTimeout(() => {
    //         this.setState({
    //             currentTab: 1
    //         });
    //     }, 2000);
    // }


    onDoubtSelect = (doubt) => {
        this.setState({
            selectedDoubt: doubt
        });
    }

    connectToOnline2 = async (user) => {
        let payload = {
            action: "online",
            data: {
                user,
                isOnline: true
            },
        };
        await this.socketService.socketSend(payload)
    }

    componentWillReceiveProps(newProps) {
        if (this.props.discussionUser != newProps.discussionUser) {
            this.connectToOnline2(newProps.discussionUser._id)
        }
    }
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className=''>Ask a Doubt ?</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    render() {

        return (
            <>
                    <div className="">
                        <div className="-mt-4">
                            <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className="">
                        <div className="grid mt-3">
                            <div className="col-4 ml-3">
                                <div className="grid doubts-tabs mr-3">
                                    <div className={`col-4 p-xs-4 doubts-tab-w ${this.state.currentTab == 0 ? 'tab-active' : ''}`}>
                                        <p className={`ma-pointer   ${this.state.currentTab == 0 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(0) }}>New Doubts</p>
                                    </div>
                                    <div className={`col-4 p-xs-4 doubts-tab-w ${this.state.currentTab == 1 ? 'tab-active' : ''}`}>
                                        <p className={`ma-pointer  ${this.state.currentTab == 1 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(1) }}>Attempted Doubts</p>
                                    </div>
                                    <div className={`col-4 p-xs-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                                        <p className={`ma-pointer  ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>Closed Doubts</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className={`col-4 p-xs-4 ma-no-pm pt-0`}>
                                <DoubtsList currentSocketMessage={this.state.currentSocketMessage}
                                    socketSend={this.socketService.socketSend}
                                    currentTab={this.state.currentTab}
                                    selectedDoubt={this.state.selectedDoubt}
                                    onDoubtSelect={this.onDoubtSelect} />
                            </div>
                            <div className={`col-8 p-xs-8 ma-no-pm`}>
                                <Conversation currentSocketMessage={this.state.currentSocketMessage}
                                    socketSend={this.socketService.socketSend}
                                    currentTab={this.state.currentTab}
                                    selectedDoubt={this.state.selectedDoubt}
                                    onDoubtRespond={this.onDoubtRespond} />
                            </div>
                        </div>
                    </div>
            </>
        );
    }
}

const mapStatesToProps = (state) => ({
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
});

export default connect(mapStatesToProps, {})(Authentication(AskADoubt));
