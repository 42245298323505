import React, { useState } from 'react';


const TableOne = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const data = [
    { id: 1, name: 'John Doe', email: 'john@example.com', city: 'New York', country: 'USA' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', city: 'London', country: 'UK' },
    { id: 3, name: 'Bob Johnson', email: 'bob@example.com', city: 'Paris', country: 'France' },
    { id: 4, name: 'Alice Brown', email: 'alice@example.com', city: 'Sydney', country: 'Australia' },
    { id: 5, name: 'Charlie Wilson', email: 'charlie@example.com', city: 'Tokyo', country: 'Japan' },
    // Add more data as needed
  ];

  const handleRowClick = (id) => {
    const index = selectedRows.indexOf(id);
    if (index === -1) {
      setSelectedRows([...selectedRows, id]);
    } else {
      const updatedRows = [...selectedRows];
      updatedRows.splice(index, 1);
      setSelectedRows(updatedRows);
    }
  };

  return (
    <div style={{boxShadow:" 0 2px 4px 0 rgb(0 0 0 / 0.05)"}} className="table-container">
      <table style={{width:"590px",height:"246px",padding:"15px"}} className='bg-lime-300'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>City</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr
            style={{textAlign:"center"}}
              key={row.id}
              className={selectedRows.includes(row.id) ? 'selected' : ''}
              onClick={() => handleRowClick(row.id)}
            >
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td>{row.email}</td>
              <td>{row.city}</td>
              <td>{row.country}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableOne;
