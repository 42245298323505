import React from 'react';
import './styles.scss';
import { baseUrlAdmin, baseUrlForLongRunningTask } from './../../../store/apiConstants';
import LoadingIndicator from './../../loadingComponent';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { MultiSelect } from 'primereact/multiselect';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import { connect } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';
import {
    getOrganizationData,
    clearOrganizationData,
    getBranchCourseSection,
    clearCurriculumData,
    getActiveOrganizationData,
    getAllExamsByFilter,
    getImageUrl
} from './../../../store/actions';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Service from '../../../services';
import LoadingComponent from './../../loadingComponent';
import { Toast } from 'primereact/toast';
import { BackArrowIcon } from '../../svgIcons';
import { getRegex } from '../../../utile';





const testType = [
    // {
    //     value: 'Quiz',
    //     label: 'Quiz'
    // },
    {
        value: 'Assessment',
        label: 'Assessment'
    },
    // {
    //     value: 'MockTest',
    //     label: 'Mock Test'
    // },
    // {
    //     value: 'Template List',
    //     label: 'Template'
    // },
    {
        value: 'AdhocMockTest',
        label: 'Adhoc Mock Test'
    },
    {
        value: 'AdhocMockTestT2',
        label: 'Adhoc Mock Test T2'
    }
];




class CumulativeAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            board: [],
            classes: [],
            branch: [],
            courses: [],
            sections: [],
            boardId: null,
            boardName: null,
            classId: null,
            className: null,
            groupId: null,
            groupName: null,
            branchId: null,
            branchName: null,
            courseId: [],
            courseName: null,
            sectionId: [],
            sectionName: null,
            examType: null,
            examId: null,
            // examType: 'Assessment',
            // examId: 'd211daea-afa5-49ce-b95f-39a08df2544b',
            startDateError: null,
            endDateError: null,
            exams: [],
            startDate: null,
            endDate: null,
            questionwiseReport: null,
            userWiseReport: null,
            currentViewType: 'user',
            examsListByFilter: [],
            isLoading: false,
            showGradeSearch: false,
            //graphData: [],
            currentViewRange: 0,
            subjectName: 'All',
            subjects: [],
            questionId: null,
            question: null,
            examIds: []

        }
        this.service = new Service()

    };



    componentDidUpdate(prevProps) {

        if (prevProps.examsListByFilter !== this.props.examsListByFilter) {
            if (!this.props.examsListByFilter.length) {
                this.toast.show({ severity: 'error', summary: 'No Exams', detail: 'No exams found.', life: 3000 });
            } else {
                this.setState({
                    examsListByFilter: this.props.examsListByFilter
                });
            }
        }
    };






    setExamType = (examType) => {

        if (examType != "AdhocMockTest" && examType) {
            this.setState({
                showGradeSearch: true,
                examType,
                examIds: [],
                examsListByFilter: [],
                singleUserRecord: null,
                cumulativeReport: null,

            });
        } else {
            this.setState({
                examType,
                examIds: [],
                examsListByFilter: [],
                showGradeSearch: false,
                singleUserRecord: null,
                cumulativeReport: null,

            });
        }



    }

    fetchExams = () => {
        let { dateRange, examType, } = this.state;

        if (!dateRange || !dateRange.length > 1) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        } else if (!dateRange[0] || !dateRange[1]) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        } else if (!examType) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Exam Type', life: 3000 });
            return;
        }




        // else {
        //     if (!boardId || !classId || !groupId || !examType || !startDate || !endDate) {
        //         this.setState({
        //             openSnackBar: true,
        //             snakBarMsg: 'Please select all the fields'
        //         });
        //         return;
        //     }
        // }


        let tStartDate = new Date(dateRange[0]);
        tStartDate.setHours(0);
        tStartDate.setMinutes(0);
        tStartDate.setSeconds(0);


        let tEndDate = new Date(dateRange[1]);
        tEndDate.setHours(23);
        tEndDate.setMinutes(59);
        tEndDate.setSeconds(59);


        let filters = { startDate: tStartDate.getTime(), endDate: tEndDate.getTime(), examType };
        // this.props.getAllExamsByFilter(filters);


        this.setState({
            isLoading: true,
            examsListByFilter: [],
            
        })


        //this.props.getAllExamsByFilter(filters);
        let url = `${baseUrlAdmin}/examination/getexamNamesbyfilter`;
        this.service.post(url, filters, true).then(res => {

            if (res && res.res && res.res.Items) {
                if (res.res.Items.length) {
                    this.setState({
                        examsListByFilter: res.res.Items,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        examsListByFilter: [],
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'No Exams', detail: 'No exams found with the given search criteria.', life: 3000 });
                }


            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
          ///  console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    handleClearSearch = () => {
        this.setState(
            {
                examType: null,
                dateRange: [],
                examIds: []
            }
        );
    }

    getGraphData = (data) => {
        let questionwiseReport = data;
        let temp = questionwiseReport.length;
        let t1 = 0;
        let t2 = 0;
        let t3 = 0;
        let graphTabs = [];
        if (questionwiseReport && questionwiseReport.length) {
            for (let ie = 0; ie <= temp; ie++) {
                if (temp >= 20) {
                    t2 += 20;
                    let values = [];
                    for (let le = t1; le < t2; le++) {
                        values.push({
                            qNo: questionwiseReport[le].qNo,
                            questionId: questionwiseReport[le].questionId,
                            "Correct Perfect Time": questionwiseReport[le].correctPerfectTimePercentage,
                            "Correct Perfect TimeColor": "#AF9CFA",
                            "Correct Over Time": questionwiseReport[le].correctOverTimePercentage,
                            "Correct Over TimeColor ": "#472BB8",
                            background: "#472BB8",
                            backgroundColor: "#472BB8",
                            color: 'orange',
                            fill: 'red'
                            // "Wrong Perfect Time": questionwiseReport[le].wrongPerfectTimePercentage,
                            // "Wrong Perfect TimeColor": "hsl(70, 70%, 50%)",
                            // "Wrong Over Time": questionwiseReport[le].wrongOverTimePercentage,
                            // "Wrong Over TimeColor": "hsl(306, 70%, 50%)",
                        });
                    }
                    graphTabs.push({
                        label: (questionwiseReport[t1] ? questionwiseReport[t1].qNo : '') + '-' + (questionwiseReport[t2 - 1] ? questionwiseReport[t2 - 1].qNo : ''),
                        values,
                        key: t3
                    });
                    t1 += 20;
                    t3++;
                } else {
                    t2 += temp;
                    let values = [];
                    for (let le = t1; le < t2; le++) {
                        values.push({
                            qNo: questionwiseReport[le].qNo,
                            questionId: questionwiseReport[le].questionId,
                            "Correct Perfect Time": questionwiseReport[le].correctPerfectTimePercentage,
                            "Correct Perfect TimeColor": "#AF9CFA",
                            "Correct Over Time": questionwiseReport[le].correctOverTimePercentage,
                            "Correct Over TimeColor ": "#472BB8",
                            // "Wrong Perfect Time": questionwiseReport[le].wrongPerfectTimePercentage,
                            // "Wrong Perfect TimeColor": "hsl(70, 70%, 50%)",
                            // "Wrong Over Time": questionwiseReport[le].wrongOverTimePercentage,
                            // "Wrong Over TimeColor": "hsl(306, 70%, 50%)",
                        });
                    }
                    graphTabs.push({
                        //   label: t1.toString() + '-' + t2.toString(),
                        //label: questionwiseReport[t1].qNo + '-' + questionwiseReport[t2].qNo,
                        label: (questionwiseReport[t1] ? questionwiseReport[t1].qNo : '') + '-' + (questionwiseReport[t2 - 1] ? questionwiseReport[t2 - 1].qNo : ''),
                        values,
                        key: t3
                    });
                    t3++;
                }
                temp -= 20;
            }
        }

        return graphTabs;
    }

    handleSearch = () => {
        // if (true) {
        //     let res = {
        //         res: require('./cumulative.json')
        //     }


        //     let subjects = ['All'];
        //     if (res.res && res.res.singleUserRecord && res.res.singleUserRecord.length) {

        //         res.res.singleUserRecord[0].userAnswered.forEach((d) => {
        //             subjects.push(
        //                 d.subjectName
        //             );
        //         });
        //     }


        //     this.setState({
        //         singleUserRecord: res.res && res.res.singleUserRecord && res.res.singleUserRecord[0],
        //         cumulativeReport: res.res && res.res.cumulativeReport && res.res.cumulativeReport,
        //         // questionwiseReport: res.res && res.res.questionwiseReport && res.res.questionwiseReport,
        //         //graphData,
        //         isLoading: false,
        //         //   graphTabs,
        //         subjects
        //     });

        // } else {





        let { examType, examIds } = this.state;

        if ((!examIds && !examIds.length)) {

            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select exams', life: 3000 });
            return;
        }

        if ((examIds.length < 2)) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select atleast two exams', life: 3000 });
            return;
        }

        this.setState({
            isLoading: true
        });
        const service = new Service();
        let url = `${baseUrlForLongRunningTask}/analytics/getcumulativeperformanceanalyticsbyexamIds`;
        let data = { examIds: examIds, examType };



        service.post(url, data, false).then((res) => {
            if (res.status) {
                //let graphData = [];
                // let graphTabs = [];
                // if (res.res && res.res.questionwiseReport && res.res.questionwiseReport) {
                //     graphTabs = this.getGraphData(res.res.questionwiseReport);
                // }
                let subjects = ['All'];
                if (res.res && res.res.singleUserRecord && res.res.singleUserRecord.length) {

                    res.res.singleUserRecord[0].userAnswered.forEach((d) => {
                        subjects.push(
                            d.subjectName
                        );
                    });
                }


                this.setState({
                    singleUserRecord: res.res && res.res.singleUserRecord && res.res.singleUserRecord[0],
                    cumulativeReport: res.res && res.res.cumulativeReport && res.res.cumulativeReport,
                    // questionwiseReport: res.res && res.res.questionwiseReport && res.res.questionwiseReport,
                    //graphData,
                    isLoading: false,
                    //   graphTabs,
                    subjects
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });

            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

        // }
    }

    onSubjectChange = (subjectName) => {
        if (subjectName == 'All') {
            this.setState({
                subjectName,
                graphTabs: this.getGraphData(this.state.questionwiseReport),
                currentViewRange: 0
            });
        } else {
            let subjectWiseQuestions = this.state.questionwiseReport.filter((q => q.subjectName == subjectName));

            this.setState({
                subjectName,
                graphTabs: this.getGraphData(subjectWiseQuestions),
                currentViewRange: 0
            });
        }
    }





    showUserFullAnalytics = (exams) => {

        this.setState({
            showUserFullAnalytics: true,
            fullUserAnalytics: exams
        });

    }


    handleTabChange = (event, newValue) => {

        this.setState({ currentViewRange: event.index });
    };


    render() {
        // console.log('individual', this.state);

        return (
            <>
                <div>
                    <div className='grid' >
                        <Authorizer permId={PERMISSIONS.EXAMANALYTICS_FETCHEXAMS} >
                            <div className=" md:col-6">
                                <div className='p-grid' >

                                    <Calendar showIcon={true}
                                        placeholder="Select Dates"
                                        selectionMode="range"
                                        className='mt-2 '
                                        readOnlyInput
                                        value={this.state.dateRange}
                                        onChange={(e) => {
                                            this.setState({
                                                dateRange: e.value,
                                            });
                                        }}
                                    ></Calendar>
                                    <span className='ma-required-mark mr-2'>*</span>


                                    <Dropdown value={this.state.examType}
                                        className='mt-2 w-12rem'
                                        options={testType}
                                        onChange={(e) => { this.setExamType(e.target.value) }}
                                        placeholder="Select Exam Type" />
                                    <span className='ma-required-mark '>*</span>

                                </div>
                                <p className="ma-error-msg">{this.state.startDateError ? this.state.startDateError : ''}</p>
                                <div className='mt-2'>
                                    <Button
                                        label="Fetch Exams"
                                        onClick={() => {
                                            this.fetchExams()
                                        }}
                                    >
                                    </Button>
                                    <Button
                                        label="Clear"
                                        className='mt-2 ml-2 p-button-outlined white-bg'
                                        onClick={() => {
                                            this.handleClearSearch()
                                        }}
                                    >
                                    </Button>
                                </div>
                            </div>
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.EXAMANALYTICS_SHOW_RESULTS} >
                            <div className="md:col-6">
                                <div className='mx-5'>
                                    <div>
                                        {/* <Dropdown value={this.state.examId}
                                        className='ma-w140 '
                                        options={this.state.examsListByFilter}
                                        onChange={(e) => this.setState({ examId: e.target.value })}
                                        placeholder="Select Exam Type" /> */}

                                        <MultiSelect value={this.state.examIds}
                                            className='w-12rem'
                                            options={this.state.examsListByFilter}
                                            optionLabel={'examName'}
                                            optionValue={'examId'}
                                            onChange={(e) => {
                                                this.setState({
                                                    examIds: e.value
                                                });
                                            }}
                                            placeholder="Select Exam" />
                                        <span className='ma-required-mark '>*</span>
                                    </div>
                                    <Button
                                        className='mt-4'
                                        label='Show Results'
                                        onClick={() => {
                                            this.handleSearch()
                                        }}
                                    >
                                    </Button>
                                </div>
                            </div>
                        </Authorizer>
                    </div>




                    <Toolbar className="ma-toolbard"
                        //  right={() => {
                        //     return <>

                        //         <span>
                        //             <i className='pi pi-chart-bar ma-pointer ma-m10 graph' data-pr-tooltip="Graphical View" onClick={() => {
                        //                 this.showGraphView()
                        //             }} data-pr-position="bottom" ></i>
                        //         </span>
                        //         <Tooltip className="table-li-tooltip" target=".graph" />


                        //     </>
                        // }}
                        left={() => {
                            return <>
                                {
                                    this.state.showUserFullAnalytics && <span style={{
                                        top: '10px',
                                        position: 'relative'
                                    }}
                                        onClick={() => {
                                            this.setState({
                                                showUserFullAnalytics: false,
                                            });
                                        }}>
                                        <BackArrowIcon height={20} width={20} color='var(--primary-color)' />
                                    </span>}
                            </>
                        }}
                    ></Toolbar>



                    <div className="adhoc-report-tbl">
                        <Authorizer permId={PERMISSIONS.EXAMANALYTICS_TESTWISE} >
                            <div>
                                {!this.state.showUserFullAnalytics && this.state.cumulativeReport && this.state.cumulativeReport.length && <>
                                    <table className="exam-tbl" style={{}}>
                                        <tr>
                                            <th rowspan="2">S.No</th>
                                            <th rowspan="2">Mobile No</th>
                                            <th rowspan="2" >Name</th>
                                            <th rowspan="2" >Exams Attended</th>
                                            <th colSpan="6" >Overall</th>
                                            {
                                                this.state.singleUserRecord && this.state.singleUserRecord.userAnswered.map((subject, ind) => {
                                                    return <th colSpan="6" key={'a' + ind}>{subject.subjectName}</th>
                                                })
                                            }

                                        </tr>
                                        <tr>
                                            <th>C</th>
                                            <th>W</th>
                                            <th>U</th>
                                            <th>Total <span style={{ fontSize: '12px' }}>{this.state.singleUserRecord && this.state.singleUserRecord.totalExamMarks}</span></th>
                                            <th>Percentile</th>
                                            <th>Rank</th>
                                            {
                                                this.state.singleUserRecord && this.state.singleUserRecord.userAnswered.map((subject) => {
                                                    let total = 0;
                                                    if (this.state.examType == 'AdhocMockTest' || this.state.examType == 'AdhocMockTestT2') {
                                                        subject.questionTyeWise.forEach(q => {
                                                            total += q.totalMarks;
                                                        })
                                                    } else {
                                                        total = subject.totalMarks
                                                    }


                                                    return <>
                                                        <th>C</th>
                                                        <th>W</th>
                                                        <th>U</th>
                                                        <th>Total <span style={{ fontSize: '12px' }}>{total}</span> </th>
                                                        <th>Percentile</th>
                                                        <th>Rank</th>
                                                    </>
                                                })
                                            }
                                        </tr>
                                        {
                                            this.state.cumulativeReport.map((userRecord, index) => {
                                                return <tr key={'b' + index} className=' question-item' onClick={() => { this.showUserFullAnalytics(userRecord.exams) }}>
                                                    <td>{index + 1}</td>
                                                    <td className="ma-pointer phone-number"  >{userRecord.phoneNumber}</td>
                                                    <td>{userRecord.userName}</td>
                                                    <td>{userRecord.examsAttended}/ {this.state.examIds.length}</td>
                                                    <td>{userRecord.allRightAnswers}</td>
                                                    <td>{userRecord.allWrongAnswers}</td>
                                                    <td>{userRecord.allUnattemptedAnswers}</td>
                                                    <td>{userRecord.totalMarks}</td>
                                                    <td>{userRecord.percentile}</td>
                                                    <td>{userRecord.rank}</td>
                                                    {
                                                        this.state.singleUserRecord && this.state.singleUserRecord.userAnswered.map((subject) => {
                                                            return <>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_rightAnswer`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_wrongAnswer`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_unAnswered`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_marks`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_percentile`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_rank`]}</td>
                                                            </>
                                                        })
                                                    }

                                                </tr>
                                            })
                                        }
                                    </table>
                                </>
                                }
                                {this.state.showUserFullAnalytics && this.state.fullUserAnalytics && this.state.fullUserAnalytics.length && <>

                                    <table className="exam-tbl" style={{}}>
                                        <tr>
                                            <th rowspan="2">S.No</th>
                                            <th rowspan="2">Mobile No</th>
                                            <th rowspan="2" >Name</th>
                                            <th colSpan="6" >Overall</th>
                                            {
                                                this.state.singleUserRecord && this.state.singleUserRecord.userAnswered.map((subject, ind) => {
                                                    return <th colSpan="6" key={'ind' + ind} >{subject.subjectName}</th>
                                                })
                                            }

                                        </tr>
                                        <tr>
                                            <th>C</th>
                                            <th>W</th>
                                            <th>U</th>
                                            <th>Total <span style={{ fontSize: '12px' }}>{this.state.singleUserRecord && this.state.singleUserRecord.totalExamMarks}</span></th>
                                            <th>Percentile</th>
                                            <th>Rank</th>
                                            {
                                                this.state.singleUserRecord && this.state.singleUserRecord.userAnswered.map((subject) => {
                                                    let total = 0;
                                                    if (this.state.examType == 'AdhocMockTest' || this.state.examType == 'AdhocMockTest2') {
                                                        subject.questionTyeWise.forEach(q => {
                                                            total += q.totalMarks;
                                                        })
                                                    } else {
                                                        total = subject.totalMarks
                                                    }


                                                    return <>
                                                        <th>C</th>
                                                        <th>W</th>
                                                        <th>U</th>
                                                        <th>Total <span style={{ fontSize: '12px' }}>{total}</span> </th>
                                                        <th>Percentile</th>
                                                        <th>Rank</th>
                                                    </>
                                                })
                                            }
                                        </tr>
                                        {
                                            this.state.fullUserAnalytics.map((userRecord, index) => {
                                                return <tr key={'s' + index}>
                                                    <td>{index + 1}</td>
                                                    <td className="phone-number">{userRecord.phoneNumber}</td>
                                                    <td>{userRecord.userName}</td>
                                                    <td>{userRecord.allRightAnswers}</td>
                                                    <td>{userRecord.allWrongAnswers}</td>
                                                    <td>{userRecord.allUnattemptedAnswers}</td>
                                                    <td>{userRecord.totalMarks}</td>
                                                    <td>{userRecord.percentile}</td>
                                                    <td>{userRecord.rank}</td>
                                                    {
                                                        this.state.singleUserRecord && this.state.singleUserRecord.userAnswered.map((subject) => {
                                                            return <>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_rightAnswer`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_wrongAnswer`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_unAnswered`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_marks`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_percentile`]}</td>
                                                                <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_rank`]}</td>

                                                            </>
                                                        })
                                                    }

                                                </tr>
                                            })
                                        }
                                    </table>
                                </>}

                            </div>
                        </Authorizer>
                    </div>




                </div >


                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }
            </>
        );
    }
}



const mapStatesToProps = (state) => ({


    isLoading: (state.curriculumData && state.curriculumData.loading) || (state.examinationDetailsInfo && state.examinationDetailsInfo.loading),
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    branchInfo:
        state.curriculumData &&
        state.curriculumData.branch &&
        state.curriculumData.branch.Item &&
        state.curriculumData.branch.Item,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    //examsListByFilter: state.examinationDetailsInfo && state.examinationDetailsInfo.examsListByFilter,
    imageInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    roleBasedBoardIds: localStorage.getItem('boardId'),

});

export default connect(mapStatesToProps, {
    getOrganizationData,
    getActiveOrganizationData,
    clearOrganizationData,
    clearCurriculumData,
    getBranchCourseSection,
    getAllExamsByFilter,
    getImageUrl
})((CumulativeAnalytics));