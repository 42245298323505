import React, { Component } from 'react'
import { trimObj } from '../../../utile';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { getFormFields, isFormValid, onTextChange } from '../../../utile/formHelper';
import Service from '../../services';
import InputTextB from '../../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import { FIELDS_INFO } from '../../../constants';
import { Button } from 'primereact/button';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';

var formFields = require('./addEmailConfig.json');

let abc = {
    host: 'in-v3.mailjet.com',
    email: '2b4929f0e4f8551fa97d6b637e4f9a4c',
    password: '3640b52e1f08b58544f1c1946776df08',
    from: '"Shashidhar k" <shashidhar.k@meluhaedu.com>'
}

class EmailConfig extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, {});


        this.state = {
            emailConfig: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isEdit: false,
            isConfigured: true,
            isVerified: false,
            orgId: this.props.orgId,
            isNotAdded: false

        }
        this.service = new Service();
    }

    componentDidMount() {
        this.getConfig(this.props.orgId);

    }


    componentDidUpdate(prevProps) {
        if (prevProps.orgId != this.props.orgId)
            this.getConfig(this.props.orgId);

    }



    getConfig = (orgId) => {
        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/emailconfig/${orgId}`;
        this.service.get(url, true).then((res) => {
            if (res && res.res && res.res.status) {
                this.setState({
                    isLoading: false,
                    emailConfig: res.res.data
                })

            } else {

                this.setState({
                    isLoading: false,
                    isNotAdded: res.errMessage.includes('No Configurations') ? true : false

                })
              //  this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });


            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });


    }

    testConnection = () => {

        const { currentStep, emailConfig } = this.state;
        let submitData = trimObj(emailConfig)
        const formStatus = isFormValid(formFields, this.formFields.formValidations, submitData);
        
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else {
            this.setState({
                isLoading: true
            });

            const url = `${baseUrlAdmin}/emailconfig/test-conn`;
            this.service.post(url, submitData, true).then((res) => {
                
                if (res && res.res && res.res.data) {
                    this.setState({
                        isLoading: false,
                        isVerified: res.res.data.verified
                    })
                    if (res.res.data.verified) {
                        this.toast.show({ severity: 'success', summary: 'Verified', detail: 'Succussfully connect params.', life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Unable to connect to server', life: 3000 });
                    }

                } else {

                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }


            }).catch(e => {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                this.setState({
                    isLoading: false
                });
            });
        }




    }

    onSubmitClick = () => {

        const { currentStep, emailConfig } = this.state;
        let submitData = trimObj(emailConfig)

        const formStatus = isFormValid(formFields, this.formFields.formValidations, submitData);
        
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else {
            this.setState({
                isLoading: true
            });

            submitData.orgId = this.props.orgId;

            

            const url = `${baseUrlAdmin}/emailconfig`;


            this.service[submitData._id ? 'put' : 'post'](url, submitData, true).then((res) => {
                
                if (res && res.res && res.res.status) {
                    this.setState({
                        isLoading: false,
                        emailConfig: res.res.data,
                        isEdit: false,
                        isNotAdded: false

                    })
                } else {

                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }


            }).catch(e => {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                this.setState({
                    isLoading: false
                });
            });
        }
    }

    render() {
        const { emailConfig, formValidations, isEdit, isNotAdded } = this.state;
      //  console.log(this.props, 'dddd')
        return (
            <div className='ma-ml20'>


                {isEdit ? <>
                    <div className='grid mt-2'>


                        <div className='col-12 grid ml-2 mt-2'>
                            <div className='col-12 p-0'>
                                <p className='ma-label-s2'>Host<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.HOST} id="kexpT">
                                    <InputText value={emailConfig.host}
                                        onChange={(e) => { onTextChange(e.target.value, 'host', this, formFields, emailConfig, formValidations, 'emailConfig', 'formValidations') }}
                                        className='w-4 p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['host'].isValid && <p className="p-error">{formValidations.fields['host'].errorMsg}</p>}
                            </div>

                            <div className='col-12 p-0 mt-1' >
                                <p className='ma-label-s2'>Email<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.EMAIL} id="kexpT">
                                    <InputText value={emailConfig.email}
                                        onChange={(e) => { onTextChange(e.target.value, 'email', this, formFields, emailConfig, formValidations, 'emailConfig', 'formValidations') }}
                                        className='w-4 p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                            </div>

                            <div className='col-12 p-0 mt-1' >
                                <p className='ma-label-s2'>Password<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.PASSWORD} id="kexpT">
                                    <InputText value={emailConfig.password}
                                        onChange={(e) => { onTextChange(e.target.value, 'password', this, formFields, emailConfig, formValidations, 'emailConfig', 'formValidations') }}
                                        className='w-4 p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                            </div>

                            <div className='col-12 p-0 mt-1' >
                                <p className='ma-label-s2'>From<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.FROM_NAME} id="kexpT">
                                    <InputText value={emailConfig.from}
                                        onChange={(e) => { onTextChange(e.target.value, 'from', this, formFields, emailConfig, formValidations, 'emailConfig', 'formValidations') }}
                                        className='w-4 p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['from'].isValid && <p className="p-error">{formValidations.fields['from'].errorMsg}</p>}
                            </div>


                            <Button label={'Test'} className='mt-3' onClick={this.testConnection} /> <Button label={'Save'} className='mt-3 ml-2' onClick={this.onSubmitClick} />
                            <Button label={'Cancel'} className='mt-3 ml-2 p-button-outlined' onClick={() => {
                                this.setState({
                                    isEdit: false
                                })
                            }} />
                        </div>
                    </div>
                </> : <>

                    {
                        isNotAdded ? <> <p className='config-txt'>No Configuration Added. Please add</p>
                            <Button label={'Add'} className='mt-2' onClick={() => {
                                this.setState({
                                    isEdit: true
                                })
                            }} />
                        </> : <div className='grid mt-2'>
                            <div className='col-6 '>
                                <p className='config-txt'><b>Host : </b>{emailConfig.host}
                                    <Button onClick={() => {
                                        this.setState({
                                            isEdit: true
                                        })
                                    }} icon="pi pi-pencil" className="p-button-rounded p-button-secondary p-button-text ml-6 " aria-label="Edit" /></p>
                                <p className='config-txt'><b>Email : </b>{emailConfig.email}</p>
                                <p className='config-txt'><b>Password : </b>*********</p>
                                <p className='config-txt'><b>From : </b>{emailConfig.from}</p>
                            </div>

                        </div>
                    }

                </>}
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    emailConfig: state.orgData.emailConfig,
});


export default connect(mapStateToProps, {
})(Authentication(EmailConfig));
