import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './ClassetComponents.scss';  // Import the CSS file
import ClassetInputText from './classetInputText';
import { CalendarIcon, ClipIcon, MailIcon } from '../components/svgIcons';
import ClassetCalendar from './classetCalender';
import ClassetDropdown from './classetDropDown';

const fieldOptions = [
    { type: 'text', value: '', label: 'name', col: 3 },
    { type: 'number', value: '', label: 'age', col: 3 },
    { type: 'date', value: '', label: 'fromdate', col: 3 },
    { type: 'date', value: '', label: 'fromdate1', col: 3 },
    { type: 'date', value: '', label: 'fromdate2', col: 3 },
    { type: 'date', value: '', label: 'fromdate3', col: 3 }
]

class PopulateDynamicForm extends Component {
    constructor(props) {
        super(props);
        console.log(props,"proopsss")
        this.state = {
            //fields: fieldOptions,
            fields:props?.fieldArray?props?.fieldArray:[]
         
        };
    }


    handleFieldChange = (index, type, value) => {
       
        const fields = [...this.state.fields];
        console.log(fields, "fieldsfields")
        fields[index][type] = value;
        //fields.push(fields)

        console.log(fields, "fieldsfieldspush")
        this.setState({ fields });
    };


    renderField = (field, index) => {
        const { type, value, label } = field;
        console.log(type, "type", label)
        switch (type) {
            case 'text':

                return <>


                    <div className="field col">
                        <ClassetInputText
                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                            className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                            height="48px"
                            width='368px'
                            value={value}
                            onChange={(e) => { this.handleFieldChange(index, 'value', e.target.value, label) }}
                            placeholder={label}

                        />
                    </div>


                </>
                    ;
            case 'number':
                return <>

                    <div className="field col">

                        <ClassetInputText
                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                            className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                            height="48px"
                            width='368px'
                            value={value}
                            type='number'
                            onChange={(e) => { this.handleFieldChange(index, 'value', e.target.value, label) }}
                            placeholder={label}



                        />
                    </div>


                </>

            case 'date':
                return <>

                    <div className="field col">



                        <ClassetCalendar
                            icon={<CalendarIcon width={24} height={24} color={"black"} />}
                            className='border-round-md no-border custom-field-focused border-none pl-3 body-medium'
                            calendarMode={'single'}
                            height="48px"
                            width='368px'
                            value={new Date(value)}
                            onChange={(e) => this.handleFieldChange(index, 'value', e.value, label)}
                            placeholder={label}


                        />
                    </div>
                </>
            default:
                return null;
        }
    };

    render() {
        return (
            <div className="grid">

                {this.state.fields.map((field, index) => (
                    <div key={index} >
                        {this.renderField(field, index)}


                    </div>
                ))}






            </div>
        );
    }
}

export default PopulateDynamicForm;
