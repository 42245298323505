
import { SelectButton } from "primereact/selectbutton";
import { Component } from "react";
import './styles.scss';
import AddExam from "./addExam";
import ExamTemplate from "./examTemplate";
const options = [
    { value: 1, label: 'Add Exam Template' },
    { value: 2, label: 'Exam Templates' }];


const tabHeadStyle = { backgroundColor: 'white', borderRadius: 13, width: '50%', display: "flex", justifyContent: 'center' }
class CreateTemplate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTab: '1'
        }
    }
    render() {
        return (
            <>

                <div className='shadow-2  mx-3  flex  mb-3 justify-content-center p-1' style={{ backgroundColor: '#F4F5F6', borderRadius: 13, alignItems: 'center', borderWidth: 4, borderColor: '#E6E8EA' }}>
                    <div className={` cursor-pointer ${this.state.selectedTab === '1' ? 'shadow-2' : ''}`} style={this.state.selectedTab === '1' ? tabHeadStyle : { width: '50%', display: "flex", justifyContent: 'center' }} onClick={() => this.setState({ selectedTab: '1' })}>
                        <p className='px-8 py-3 inter500semibold ' >{'Add Exam Template'}</p>
                    </div>
                    <div className={` cursor-pointer ${this.state.selectedTab === '2' ? 'shadow-2' : ''}`} style={this.state.selectedTab === '2' ? tabHeadStyle : { width: '50%', display: "flex", justifyContent: 'center' }} onClick={() => this.setState({ selectedTab: '2' })}>
                        <p className='px-8 py-3 inter500semibold'>{'Exam Templates'}</p>
                    </div>
                </div>
                {
                    this.state.selectedTab == '1' && <AddExam />
                }
                {
                    this.state.selectedTab === '2' && <ExamTemplate />
                }
            </>
        )
    }

}
export default CreateTemplate;