import React, { Component } from 'react'
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';
import Authentication from './../../session';
class TrendingChapters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: true,
            trendingChapters: [

            ]
        };


        this.service = new Service();



    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${baseUrlAdmin}/analytics/trendingChapters`;
        this.service.get(url, true).then(res => {
            
            if (res && res.status) {
                this.setState({
                    trendingChapters: res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {

        this.getReport();
        
    }

    
    render() {
        const { defaultLanguage } = this.props;
        const { trendingChapters, inlineLoading } = this.state;
        const langType = defaultLanguage


        return (
            <div className='ma-relative'>
                <div className='resource-usage-report' style={{background:"#f4e1fc"}}>
                    <h3 className="resource-title ma-mt10"><img src='/new-assets/Path_3271.png' style={{height:'14px'}} /> TrendingChapters</h3>
                    <div className='trending-table-w ma-mt10'>
                        <table className='trending-table'>
                          
                            {
                              trendingChapters&&   trendingChapters.map(chapterR => {

                                    return <tr>
                                        <td className='t-head-trend'>{chapterR.chapterName}</td>
                                        <td>{chapterR.subjectName}</td>
                                        <td>{chapterR.className}</td>
                                        <td>{chapterR.total} Users</td>

                                    </tr>
                                })
                            }
                            <tr>

                            </tr>
                        </table>
                    </div>
                    {

                    }
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-3'>

                        </div>
                        <div className='p-col-12 p-md-3'>

                        </div>
                        <div className='p-col-12 p-md-3'>

                        </div>
                        <div className='p-col-12 p-md-3'>

                        </div>
                    </div>
                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>

        )
    }
}


const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,

});

export default connect(mapStatesToProps, {})(Authentication(TrendingChapters));
