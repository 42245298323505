


import React, { Component, createRef } from 'react'
import {

    getBranchesLatest
} from './../../store/actions';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import SvgViewer from './../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import SearchBar from './searchBar';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactToPrint, { PrintContextConsumer }  from 'react-to-print';
import withRouter from '../lib/withRouter';


class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.service = new Service();
        this.idCardRef = createRef()
    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }

    componentDidMount() {

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            if (this.props.selectedUser && this.props.branchData) {
                let finaldata = this.getSectionsData(this.props.branchData, this.props.selectedUser.sectionId, this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata
                });

            }
        }
    }



    componentDidUpdate(prevProps) {
        if (prevProps.branchData !== this.props.branchData) {
            if (this.props.editUser && this.props.branchData) {
                let finaldata = this.getSectionsData(this.props.branchData, this.props.editUser.sectionId, this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata
                });
            }
        }



    }

    // printDocument = () => {
    //     const input = this.idCardRef.current
    //     html2canvas(input)
    //       .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF();
    //         pdf.addImage(imgData, 'JPEG', 0, 0);
    //         // pdf.output('dataurlnewwindow');
    //         pdf.save("IdCard.pdf");
    //       })
    //     ;
    //   }

    render() {
        const { selectedUser, isShowUserInfo } = this.props;

        // console.log(this.state)
        return (
            <>
                <Dialog 
                //header="User Info" 
                style={{minWidth  : '70%',maxWidth:"380px"}}
                    blockScroll={true}
                    draggable={false} footer={() => {
                        return (<div>
                            <Button label="Close" className='p-button-outlined' onClick={this.props.onHide} />
                        </div>)
                    }}
                    className="user-info-d"
                    closeOnEscape={true}
                    dismissableMask={true}
                    visible={isShowUserInfo}
                    closable={false}
                    onHide={this.props.onHide}>
                        <h4 className="text-center mb-3 text-primary ">
                            User Info
                        </h4>
                    <div className='grid'>
                        <div className='col-6  '>
                            <div className='id-container background-container ' ref={el => this.idCardRef = el} >
                                <div className='org-img-head'>
                                    <img src={`${selectedUser.user_info?.idCardBanner?.storageLocation}`} className='id-card' alt='Organization Logo'/>
                                </div>
                                <div className='student-photo'>
                                    <img src={`${selectedUser.user_info?.studnetPhoto?.storageLocation ? selectedUser.user_info?.studnetPhoto?.storageLocation : "./images/img_placeholder_avatar.jpg"}`} className='' alt='student photo' width='165' height='165'/>
                                </div>
                                <div>
                                    <div className='flex justify-content-center pt-2'>
                                    <h5 style={{whiteSpace : "wrap"}}>{selectedUser.user_info?.given_name}</h5>
                                    </div>
                                    <div className='bg-logo-container'>
                                        <img className='bg-logo' src={`${selectedUser.user_info?.idCardWatermark?.storageLocation}`}/>
                                    </div>
                                    <div className='grid  ml-5 -mt-7 ' style={{marginTop : "-40px", }}>
                                        <div className='col-4 '><h6 className='text-base'>Father Name </h6></div>
                                        <div className='col-6  ml-3  '><h6 className='text-base'>: {selectedUser.user_info?.fatherName}</h6></div>
                                        <div className='col-4 -mt-5  desc-div'><h6 className='text-base'>Class & Sec </h6></div>
                                        <div className='col-6 -mt-5  ml-3  desc-div'><h6 className='text-base'>: {selectedUser.user_info["custom:grade"]}</h6></div>
                                        <div className='lg:col-4 xl:col-4 col-3 -mt-5 '><h6 className='text-base ' >Mobile </h6></div>
                                        <div className='col-6 -mt-5  ml-3 '><h6 className='text-base'>: {selectedUser.user_info?.phone_number}</h6></div>
                                        <div className='col-4 -mt-5 '><h6 className='text-base'>Address </h6></div>
                                        <div className='col-6 -mt-5 h-2  ml-3' >
                                            <h6 className='det-value' style={{whiteSpace : "wrap"}}>
                                                :{`${selectedUser.user_info?.houseNum ? selectedUser.user_info?.houseNum+",": ''}`} {selectedUser.user_info?.streetOrVillage? selectedUser.user_info?.streetOrVillage+"," : ""}  {selectedUser.user_info?.user_info?.mandal? selectedUser.user_info?.mandal+"," : ""}  {selectedUser.user_info?.cityOrDist ? selectedUser.user_info?.cityOrDist+"," : ""} {selectedUser.user_info?.state ? selectedUser.user_info?.state+"," : ""} {selectedUser.user_info?.pinCode ? selectedUser.user_info?.pinCode : ""} </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='prince-sign -mt-4 ' >
                                    <img src={`${selectedUser.user_info?.idCardSignature?.storageLocation}`} alt='principle signature' height="40" width='100'/>
                                </div>
                            </div>
                            <ReactToPrint
                                content={() => this.idCardRef}
                                onAfterPrint={() => this.props.onHide()}
                            >
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <Button label="Download ID Card" className='mt-4 ml-auto' onClick={handlePrint} />
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                            {/* <Button className='ma-mt10' 
                            // onClick={this.printDocument}
                            >Download ID Card</Button> */}
                        </div>
                        <div className='col-6 '>
                            {selectedUser && <div className='user-info'>
                            <div className="grid  ">
                                <div className="col-3">
                                    <p className='text-label2'>Name</p>
                                </div>
                                <div className="col-6">
                                    <InputText value={selectedUser.user_info?.given_name} className='p-inputtext-style1 w-full' />
                                </div>
                            </div>
                            <div className="grid ">
                                <div className="col-3">
                                    <p className='text-label2'>Phone</p>
                                </div>
                                <div className="col-6">
                                    <InputText value={selectedUser.phone_number} className='p-inputtext-style1 w-full' />
                                </div>
                            </div>
                            <div className="grid ">
                                <div className="col-3">
                                    <p className='text-label2'>Email ID</p>
                                </div>
                                <div className="col-6">
                                    <InputText value={selectedUser.email} className='p-inputtext-style1 w-full' />
                                </div>
                            </div>
                            <div className="grid ">
                                <div className="col-3">
                                    <p className='text-label2'>Board</p>
                                </div>
                                <div className="col-6">
                                    <InputText value={selectedUser['custom:board']} className='p-inputtext-style1 w-full' />
                                </div>
                            </div>
                            <div className="grid ">
                                <div className="col-3">
                                    <p className='text-label2'>Class</p>
                                </div>
                                <div className="col-6">
                                    <InputText value={selectedUser['custom:grade']} className='p-inputtext-style1 w-full ' />
                                </div>
                            </div>
                            {
                                this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                    return <>
                                        <div className="grid">
                                            <div className="col-3">
                                                <p className='text-label2'>{level.levelName}</p>
                                            </div>
                                            <div className="col-6">
                                                <Dropdown
                                                    value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                    className='w-full'
                                                    optionLabel={"name"}
                                                    optionValue={"key"}
                                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                                    disabled
                                                    placeholder={`No data`} />
                                            </div>
                                        </div>
                                    </>
                                }) : null
                            }
                        </div>}
                        </div>
                    </div>
                </Dialog >
                {
                    this.props.isBranchLoading && <>
                        <LoadingComponent />
                    </>
                }
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});


export default connect(mapStateToProps, {

    getBranchesLatest
})((withRouter(UserInfo)));