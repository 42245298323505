import React from 'react';

const BusinessTable = () => {
  // Sample business data
  const businessData = [
    { id: 1, name: 'ABC Inc.', industry: 'Technology', revenue: '$1.5B', location: 'New York', employees: 1000 },
    { id: 2, name: 'XYZ Corp.', industry: 'Finance', revenue: '$2.2B', location: 'London', employees: 1500 },
    { id: 3, name: 'PQR Ltd.', industry: 'Healthcare', revenue: '$800M', location: 'San Francisco', employees: 800 },
    { id: 4, name: 'LMN Co.', industry: 'Manufacturing', revenue: '$1.0B', location: 'Tokyo', employees: 1200 },
    { id: 5, name: 'EFG Ltd.', industry: 'Retail', revenue: '$600M', location: 'Paris', employees: 500 },
  ];

  return (
    <table style={{width:"590px",height:"246px",padding:"15px"}} className='bg-lime-300'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Industry</th>
          <th>Revenue</th>
          <th>Location</th>
          <th>Employees</th>
        </tr>
      </thead>
      <tbody>
        {businessData.map((company) => (
          <tr style={{textAlign:"center"}} key={company.id}>
            <td>{company.id}</td>
            <td>{company.name}</td>
            <td>{company.industry}</td>
            <td>{company.revenue}</td>
            <td>{company.location}</td>
            <td>{company.employees}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BusinessTable;
