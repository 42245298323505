import React, { Component } from 'react'
import { connect } from 'react-redux';

//import { withRouter } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid, onTextChange, isFieldValid, onNumberChange, onEmailChange, onDropDownChange } from '../../../utile/formHelper';
import _, { cloneDeep } from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import InputTextB from '../../customComponents/inputTextB';
import Service from './../../services';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import {

    getBoardsData,
    getBranchesLatest
} from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
import LoadingComponent from '../../loadingComponent';
import { FIELDS_INFO } from '../../../constants';
import { I18Next } from '../../../internationalization';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Add New User', className: 'ma-active-item' }
];


const home = { icon: 'pi pi-home' }


const processEditUser = (editUser) => {

    editUser = _.cloneDeep(editUser);
    editUser.gradeId = editUser.classId;
    editUser.phone_number = editUser.phone_number.substring(3);

    editUser.grade = editUser['custom:grade'];
    editUser.group = editUser['custom:group'];
    editUser.board = editUser['custom:board'];
    editUser.board = editUser['custom:board'];
    return editUser;
}




const createVehicleFields = require('./createVehicle.json');
const formFields = createVehicleFields


class CreateVehicle extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createVehicleFields);
        this.state = {
            vehicle: this.props?.editData?._id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            boards: [],
            classes: [],
            isGps: this.props?.editData?.isGps || false,
            videoRec: this.props?.editData?.videoRec || false,
            driverDropdown: [],
            attenderDropdown: [],
            vehicleType: [
                { label: 'Bus', value: 'BUS' },
                { label: 'Car', value: 'CAR' },
                { label: 'Mini Bus', value: 'MINI BUS' },
                { label: 'Mini Van', value: 'MINI VAN' },
                { label: 'Van', value: 'VAN' },
                { label: 'Electric Bus', value: 'ELECTRIC BUS' },
                { label: 'Auto', value: 'AUTO' },
                { label: 'Jeep', value: 'JEEP' },
            ],
            vehicleCategory: [
                { label: 'Owned', value: 'OWNED' },
                { label: 'Leased', value: 'LEASED' },
                { label: 'Rented', value: 'RENTED' },
            ],
            airCondition: [
                { label: 'AC', value: 1 },
                { label: 'NON-AC', value: 2 },
            ],
            purchaseDate: null,
            isShowCreateSuccess: false,
            hideUpdateButton: false,
            sectionsData: {},
        }

        this.service = new Service();
    }

    componentDidMount() {
        if (this.props?.editData?._id) {
            const driver = this.props?.editData?.staffDetails
                .filter(staff => staff.designation === 'DRIVER')
                .map(staff => staff._id);
            const attender = this.props?.editData?.staffDetails
                .filter(staff => staff.designation === 'ATTENDER')
                .map(staff => staff._id);
            const cc = this.state.vehicle.driver
            this.state.vehicle.driver = driver
            this.state.vehicle.attender = attender

        }

        const edit = this.props?.editData
        this.getDropdownsData()
    }

    getDropdownsData = async () => {

        const academicYear = localStorage.getItem("userAcademicYear");
          let url = `${baseUrlAdmin}/transport-route/meta-data?academicYear=${academicYear}`

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const drivers = res?.res?.data?.drivers.map(driver => ({
                    label: driver.name,
                    value: driver._id
                }));
                const attender = res?.res?.data?.attender.map(driver => ({
                    label: driver.name,
                    value: driver._id
                }));
                this.setState({ driverDropdown: drivers, attenderDropdown: attender });
            } else {
                this.props?.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.props?.toastMessage.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };


    onCreateVehicle = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        let bodyForApi = cloneDeep(this.state.vehicle)

        const formStatus = isFormValid(createVehicleFields, this.formFields.formValidations, bodyForApi);

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            if (!this.state.vehicle._id) {
                const drivers = bodyForApi?.driver || [];
                const attenders = bodyForApi?.attender || [];
                const staff = [...drivers, ...attenders]
                const payload = {
                    "vehicleRegistrationNo": bodyForApi?.vehicleRegistrationNo,
                    "vehicleType": bodyForApi?.vehicleType,
                    "vehicleName": bodyForApi?.vehicleName,
                    "seatingCapacity": bodyForApi?.seatingCapacity,
                    "vehicleCategory": bodyForApi?.vehicleCategory,
                    "acNonAC": bodyForApi?.acNonAC == 1 ? true : false,
                    "purchasedOn": bodyForApi?.purchasedOn,
                    "staff": staff,
                    "isGps": this.state.isGps,
                    "videoRec": this.state.videoRec
                }
                let url = `${baseUrlAdmin}/transport-vehicle?academicYear=${academicYear}`
                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {

                        this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel()
                        this.props.getVehicleData()
                    } else {
                        const error = 'Some error occurred';
                        this.props.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                    }
                } catch (e) {
                    console.log(e);
                    this.props.toastMessage.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                }
            }

            else if (this.state.vehicle._id) {
                const drivers = bodyForApi?.driver || [];
                const attenders = bodyForApi?.attender || [];
                const staff = [...drivers, ...attenders]
                const payload = {
                    '_id': this.state.vehicle._id,
                    "vehicleRegistrationNo": bodyForApi?.vehicleRegistrationNo,
                    "vehicleType": bodyForApi?.vehicleType,
                    "vehicleName": bodyForApi?.vehicleName,
                    "seatingCapacity": bodyForApi?.seatingCapacity,
                    "vehicleCategory": bodyForApi?.vehicleCategory,
                    "acNonAC": bodyForApi?.acNonAC == 1 ? true : false,
                    "purchasedOn": bodyForApi?.purchasedOn,
                    "staff": staff,
                    "isGps": this.state.isGps,
                    "videoRec": this.state.videoRec,
                    "academicYear": academicYear
                }
                let url = `${baseUrlAdmin}/transport-vehicle`
                try {
                    const res = await this.service.put(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.props.toastMessage.show({ severity: 'success', summary: 'Updated successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel();
                        this.props.getVehicleData();
                    } else {
                        const error = 'Some error occurred';
                        this.props.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                    }
                } catch (e) {
                    console.log(e);
                    this.props.toastMessage.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                }
            }






        }

    }


    render() {
        const { vehicle, formValidations } = this.state;
        const { defaultLanguage, onCancel } = this.props
        const langType = defaultLanguage
        return (<>


            <div className=' mt-3'>
                <div className="grid  md:grid-cols-2 md:justify-content-center lg:grid-cols-3 mx-8 gap-5" >
                    <div className="col-span-1">
                        <p className='add-vehicle-field-label  w-auto'>Vehicle Type<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel='label'
                                optionValue='value'
                                className=' w-full'
                                value={vehicle.vehicleType}
                                options={this.state.vehicleType}
                                onChange={(e) => { onDropDownChange(e.target.value, 'vehicleType', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className=''>Select Vehicle Type</span>
                                    </div>
                                )}
                            />
                        </div>
                        {formValidations && !formValidations.fields['vehicleType'].isValid && <p className="p-error">{formValidations.fields['vehicleType'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='add-vehicle-field-label'>Vehicle Name<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                // id="title"
                                className=' w-full '
                                value={vehicle.vehicleName}
                                onChange={(e) => onTextChange(e.target.value, 'vehicleName', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations')}
                                placeholder="Vehicle Name" />
                        </div>
                        {formValidations && !formValidations.fields['vehicleName'].isValid && <p className="p-error">{formValidations.fields['vehicleName'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='add-vehicle-field-label'>Registration Number<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                className=' border-round-md  w-full '
                                value={vehicle.vehicleRegistrationNo}
                                onChange={(e) => onTextChange(e.target.value, 'vehicleRegistrationNo', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations')}
                                placeholder="Registered Vehicle Number" />
                        </div>
                        {formValidations && !formValidations.fields['vehicleRegistrationNo'].isValid && <p className="p-error">{formValidations.fields['vehicleRegistrationNo'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='add-vehicle-field-label'>Seating Capacity<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                className=' border-round-md  w-full '
                                value={vehicle.seatingCapacity}
                                onChange={(e) => { onTextChange(e.target.value, 'seatingCapacity', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder="Seating Capacity" />
                        </div>
                        {formValidations && !formValidations.fields['seatingCapacity'].isValid && <p className="p-error">{formValidations.fields['seatingCapacity'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='add-vehicle-field-label'>Vehicle Category<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel='label'
                                optionValue='value'
                                className=' w-full'
                                value={vehicle.vehicleCategory}
                                disabled={this.state.hideUpdateButton}
                                options={this.state.vehicleCategory}
                                onChange={(e) => { onDropDownChange(e.target.value, 'vehicleCategory', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className=''>Select Vehicle Type</span>
                                    </div>
                                )}
                            />
                        </div>
                        {formValidations && !formValidations.fields['vehicleCategory'].isValid && <p className="p-error">{formValidations.fields['vehicleCategory'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='add-vehicle-field-label'>Airconditioning<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel='label'
                                optionValue='value'
                                className=' w-full'
                                value={vehicle.acNonAC}
                                options={this.state.airCondition}
                                onChange={(e) => { onDropDownChange(e.target.value, 'acNonAC', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className=''>Select AirCondition Type</span>
                                    </div>
                                )}
                            />
                        </div>
                        {formValidations && !formValidations.fields['acNonAC'].isValid && <p className="p-error">{formValidations.fields['acNonAC'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className='add-vehicle-field-label'>Purchased On<span className='ma-required'>*</span></p>

                        <div className='flex'>
                            <ClassetCalendar
                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                className=' border-round-md add-vehicle-field-input text-2xl w-full'
                                calendarMode={'single'}
                                value={this.props?.editData?._id ? new Date(vehicle.purchasedOn) : ''}
                                onChange={(e) => { onTextChange(e.target.value, 'purchasedOn', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder="Purchased On" />
                        </div>
                        {formValidations && !formValidations.fields['purchasedOn'].isValid && <p className="p-error">{formValidations.fields['purchasedOn'].errorMsg}</p>}
                    </div>

                    <div className="col-span-1">
                        <p className='add-vehicle-field-label'>Driver<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetMultiSelect
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel='label'
                                optionValue='value'
                                className='w-full'
                                value={vehicle.driver}
                                disabled={this.state.hideUpdateButton}
                                options={this.state.driverDropdown}
                                onChange={(e) => { onDropDownChange(e.target.value, 'driver', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className=''>Assign Driver</span>
                                    </div>
                                )}
                            />
                        </div>
                        {formValidations && !formValidations.fields['driver'].isValid && <p className="p-error">{formValidations.fields['driver'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className='add-vehicle-field-label'>Attender<span className='ma-required'>*</span></p>
                        <div className='flex'>
                            <ClassetMultiSelect
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel='label'
                                optionValue='value'
                                className='  w-full'
                                value={vehicle.attender}
                                options={this.state.attenderDropdown}
                                onChange={(e) => { onDropDownChange(e.target.value, 'attender', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className=''> Select Attender</span>
                                    </div>
                                )}
                            />
                        </div>
                        {formValidations && !formValidations.fields['attender'].isValid && <p className="p-error">{formValidations.fields['attender'].errorMsg}</p>}
                    </div>
                </div>
                <div className="grid lg:ml-7 lg:pl-1 md:ml-8 md:pl-4 md:grid-cols-2 md:ml-8 lg:grid-cols-3 mx-8 gap-5" >
                    <div className=" col-span-1 md:ml-8 md:pl-2 lg:pl-0 lg:ml-4 mt-8 flex " >
                        <InputSwitch
                            onChange={(e) => this.setState({ isGps: e.value })}
                            checked={this.state.isGps} />
                        <p className="add-vehicle-field-label w-auto ml-2 text-lg">Enable GPS</p>
                    </div>
                </div>

                <div className="grid lg:ml-7 lg:pl-1 md:ml-8 md:pl-3 md:grid-cols-2 md:ml-8 lg:grid-cols-3 mx-8 gap-5" >
                    <div className="col-span-1 md:ml-8 md:pl-3 lg:pl-0 lg:ml-4 mt-7 flex">
                        <Checkbox
                            className='mt-1'
                            onChange={(e) => this.setState({ videoRec: e.target.checked })}
                            checked={this.state.videoRec} />
                        <p className="add-vehicle-field-label w-auto ml-3 text-lg">Video Reacording Available</p>

                    </div>
                </div>



                <div className='flex justify-content-center mb-4 gap-3 mt-8'>
                    <Button label={this.props.editUser ? 'Update Vehicle' : 'Add Vehicle'} className='confirmDialogAcceptBtn px-6 mr-5 w-19rem' onClick={() => this.onCreateVehicle()} />
                    <Button label="Cancel" className='confirmDialogCancelBtn' onClick={onCancel} />
                </div>
            </div>


            {/* <Toast ref={(el) => this.props.toastMessage = el} position="bottom-right" /> */}
            {
                (this.state.isLoading || this.props.isBranchLoading || this.props.isLoading) && <>
                    <LoadingComponent />
                </>
            }
        </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})((withRouter(CreateVehicle)));
