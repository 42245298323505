import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import './styles.scss'
import { Button } from 'primereact/button';
// import { AddIcon2, DownloadIcon, MailIcon } from '../svgIcons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
// import { BasicLazyParams } from '../../utile';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { BasicLazyParams } from '../../../../utile';
import { AddIcon2, ClassetEditIcon, DownloadIcon, MailIcon, QuestionMarkIcon, RefreshIcon, UserIcon } from '../../../svgIcons';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]
const branchData = [
    {
        branchName: "Branch A",
        branchHead: "John Doe",
        contactNumber: "123-456-7890",
        creationDate: "2023-01-15",
        totalStaff: "X-A",
        totalStudents: 300,
    },
    {
        branchName: "Branch B",
        branchHead: "Jane Smith",
        contactNumber: "987-654-3210",
        creationDate: "2022-09-20",
        totalStaff: "X-A",
        totalStudents: 250,
    },
    {
        branchName: "Branch C",
        branchHead: "Alice Johnson",
        contactNumber: "456-789-0123",
        creationDate: "2023-05-10",
        totalStaff: "X-A",
        totalStudents: 350,
    },
    {
        branchName: "Branch D",
        branchHead: "Michael Brown",
        contactNumber: "789-012-3456",
        creationDate: "2021-11-30",
        totalStaff: "X-A",
        totalStudents: 280,
    },
    {
        branchName: "Branch E",
        branchHead: "Emily Wilson",
        contactNumber: "234-567-8901",
        creationDate: "2022-07-25",
        totalStaff: "X-A",
        totalStudents: 320,
    },
    {
        branchName: "Branch F",
        branchHead: "David Lee",
        contactNumber: "890-123-4567",
        creationDate: "2024-02-18",
        totalStaff: "X-A",
        totalStudents: 270,
    },
    {
        branchName: "Branch G",
        branchHead: "Sophia Garcia",
        contactNumber: "345-678-9012",
        creationDate: "2023-09-05",
        totalStaff: "X-A",
        totalStudents: 290,
    },
    {
        branchName: "Branch H",
        branchHead: "James Rodriguez",
        contactNumber: "012-345-6789",
        creationDate: "2021-12-20",
        totalStaff: "X-A",
        totalStudents: 330,
    },
    {
        branchName: "Branch I",
        branchHead: "Olivia Martinez",
        contactNumber: "678-901-2345",
        creationDate: "2022-04-10",
        totalStaff: "X-A",
        totalStudents: 260,
    },
    {
        branchName: "Branch J",
        branchHead: "Ethan Taylor",
        contactNumber: "901-234-5678",
        creationDate: "2023-11-15",
        totalStaff: "X-A",
        totalStudents: 310,
    },
];
const testFormat = [{ value: 1, label: "Open Test" }, { value: 2, label: "Closed Test" }]

const testTemplates = [
    { value: 1, label: "NEET 2024" },
    { value: 2, label: "CBSE" },
    { value: 3, label: "Custom" },
]




const testData = [
    {
        examName: "Exam Name 1",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 2",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 3",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 4",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 5",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 6",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 7",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 8",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 9",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
    {
        examName: "Exam Name 10",
        examType: "eCommerce Team",
        status: false,
        creationDate: "2023-01-15",
        totalStudents: 300,
    },
];
export class TestsandExam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,


            openUserDialog: false,
            openEditTestDialog: false,
            openCreateDialog: false,

            searchType: 1,
            searchInput: "",
            teacher: [{ id: 1, title: '', description: '' }],
            selfSelection: true,
            autoSelection: true,
            onEdit: false,
            onhide: false,
            testFormats: testFormat,
            testFormatType: null,
            startDate: null,
            testTemplates: testTemplates,
            selectedTestTemplate: null
        }
    }

    handleUser = () => {
        this.setState({ openUserDialog: true })
    }
    onHideUserDialog = () => {
        this.setState({ openUserDialog: false })

    }

    handleEditTest = () => {
        this.setState({ openEditTestDialog: true })
    }

    onHideEditDialog = () => {
        this.setState({ openEditTestDialog: false })
    }



    question = [
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" },
        { question: "eCommerce Team", solutionandkey: "eCommerce Team" }
    ]


    handleSelfSelection = () => {
        this.setState(prevState => ({
            onEdit: prevState.selfSelection,
            autoSelection: false,
            onGenerateQuestions: false,
            onhide: true

        }))


    }
    handleGenerateQuestions = () => {
        this.setState(prevState => ({
            onEdit: prevState.selfSelection,
            autoSelection: false,
            onhide: true,
            onGenerateQuestions: true

        }))


    }
    handleAutoPaperGenerate = () => {
        this.setState(prevState => ({
            onEdit: prevState.autoSelection,
            selfSelection: false,
            onGenerateQuestions: false,
            onhide: true

        }))


    }
    handleOnEdit = () => {
        this.setState(prevState => ({
            onhide: false,
            selfSelection: true,
            autoSelection: true,
            onGenerateQuestions: false,


        }));


    }





    render() {
        return (
            <>
                <div className="m-2">
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        value={testData}
                        dataKey="id"
                        paginator
                        lazy
                        className="dataTableValuesText"
                        responsiveLayout="scroll"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        resizableColumns
                        // selection={this.state.selectedProducts} 
                        // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    // header={header}
                    >

                        <Column style={{ width: "5rem", height: "6rem" }} alignHeader={"center"}
                            header={() => {
                                return (

                                    <Checkbox
                                        // onChange={} 
                                        checked={false}>

                                    </Checkbox>

                                )
                            }}
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <div className='flex justify-content-center'>
                                        <Checkbox
                                            // onChange={} 
                                            checked={false}>

                                        </Checkbox>
                                    </div>
                                )
                            }} ></Column>

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="examName" header="Exam Name" field="examName" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="examType" header="Exam Type" field="examType" />
                        <Column style={{ width: "17rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStudents" header="Total Students" field="totalStudents" />
                        <Column alignHeader={"center"} sortable
                            bodyClassName='text-center'
                            header="Status" body={(rowData, { rowIndex }) => {
                                return (
                                    <>
                                        <div className="flex justify-content-center">
                                            <InputSwitch width={32} height={32} color={'#000000'} />
                                        </div>
                                    </>

                                )
                            }} ></Column>
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="creationDate" header="Creation Date" field="creationDate" />
                        <Column alignHeader={"center"} bodyClassName='text-center' header="" body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <div className="flex ">
                                        <div className="mr-4 " onClick={this.handleUser}>
                                            <UserIcon width={32} height={32} />
                                        </div>
                                        <div className="mr-4" onClick={this.handleEditTest} >
                                            <ClassetEditIcon width={32} height={32} className="mr-4" />
                                        </div>
                                        <div className="mr-4">
                                            <DownloadIcon width={32} height={32} color={'#000000'} className="mr-4" />
                                        </div>
                                        <div className="mr-4">
                                            <RefreshIcon width={32} height={32} className="mr-4" />
                                        </div>
                                    </div>
                                </>

                            )
                        }} ></Column>





                    </DataTable>

                </div>
                <Dialog
                    draggable={false}
                    // blockScroll={true}

                    closable={true}
                    open={this.state.openUserDialog}
                    visible={this.state.openUserDialog}
                    // style={{ maxWidth: '22vw', minWidth: '300px' }}
                    onHide={this.onHideUserDialog}
                    header={()=>{
                        return <>
                          <div className='grid m-2 container'>
                        <div className="col-3">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Branch<span className='ma-required'>*</span></p>
                            <div className='flex w-10'>
                                <MultiSelect
                                    className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4'>Select Branch</span>
                                        </div>
                                    )} />
                            </div>
                        </div>
                        <div className="col-3">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Board<span className='ma-required'>*</span></p>
                            <div className='flex w-10'>
                                <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                    <MultiSelect
                                        className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                        options={sampleOptions}
                                        // value={term.academicBoard}
                                        // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center mt-1">
                                                <MailIcon width={20} height={16} color={'#667A85'} />
                                                <span className='text-xl ml-4'>Select Board</span>
                                            </div>
                                        )} />
                                </div>
                            </div>
                        </div>
                        <div className="col-3 ">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Grade<span className='ma-required'>*</span></p>
                            <div className='flex w-10'>
                                <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                    <MultiSelect
                                        className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                        options={sampleOptions}
                                        // value={term.academicBoard}
                                        // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center mt-1">
                                                <MailIcon width={20} height={16} color={'#667A85'} />
                                                <span className='text-xl ml-4'>Select Grade</span>
                                            </div>
                                        )} />
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Section<span className='ma-required'>*</span></p>
                            <div className='flex w-10'>
                                <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                    <Dropdown
                                        className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                        options={sampleOptions}
                                        // value={term.academicBoard}
                                        // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center mt-1">
                                                <MailIcon width={20} height={16} color={'#667A85'} />
                                                <span className='text-xl ml-4'>Select Section</span>
                                            </div>
                                        )} />
                                </div>
                            </div>
                        </div>
                    </div>
                        </>
                    }}
                    
                >
                  
                    <div className="mt-9">
                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            value={branchData}
                            dataKey="id"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            responsiveLayout="scroll"
                            showGridlines
                            selectionMode={'checkbox'}
                            columnResizeMode="expand"
                            resizableColumns
                            // selection={this.state.selectedProducts} 
                            // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        // header={header}
                        >

                            <Column style={{ width: "5rem", height: "6rem" }} alignHeader={"center"}
                                header={() => {
                                    return (

                                        <Checkbox
                                            // onChange={} 
                                            checked={false}>

                                        </Checkbox>

                                    )
                                }}
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <div className='flex justify-content-center'>
                                            <Checkbox
                                                // onChange={} 
                                                checked={false}>

                                            </Checkbox>
                                        </div>
                                    )
                                }} ></Column>

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branchName" field="branchName" header="Student Name" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branchHead" field="branchHead" header="Enrollment No." />
                            <Column style={{ width: "17rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="contactNumber" field="contactNumber" header="Branch" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="creationDate" field="creationDate" header="Board" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStaff" field="totalStaff" header="Grade-Section" />


                        </DataTable>
                        <div className="flex justify-content-center mt-2">
                            <Button className='mr-2 addButton' style={{ borderRadius: '12px' }} >
                                {/* <AddIcon2/>  */}
                                <p className=''>Save & Continue</p>
                            </Button>
                            <Button className='formCancelButton p-4' style={{ borderRadius: '12px' }}
                                onClick={this.onHideUserDialog} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    </div>







                </Dialog>
                <Dialog
                    draggable={false}
                    // blockScroll={true}

                    closable={true}
                    open={this.state.openEditTestDialog}
                    visible={this.state.openEditTestDialog}
                    // style={{ maxWidth: '22vw', minWidth: '300px' }}
                    onHide={this.onHideEditDialog}>
                    <div className="p-6">
                        {!this.state.onGenerateQuestions &&
                            <div className="createtest-card " >
                                <div className="grid">
                                    <div className=" col-4">
                                        <label htmlFor="title" className="block font-serif text-lg font-bold text-800 mb-2">Title of the Test<span className="ma-required">*</span></label>
                                        <div className='flex'>
                                            <div className="p-inputgroup border-300 shadow-2 border-round-md" style={this.state.onhide ? { backgroundColor: '#C6CED2' } : {}}>
                                                <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'

                                                    //  value={term.academicTitle} 
                                                    //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                                    placeholder="Name of the Test"
                                                    disabled={this.state.onhide}
                                                />
                                            </div>
                                            {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                                        </div>
                                        {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                                    </div>
                                    {!this.state.onGenerateQuestions && <div className="col-4 ">
                                        <label className='block font-serif text-lg font-bold text-800 mb-2'>Test Type<span className='ma-required'>*</span></label>
                                        
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md' style={this.state.onhide ? {backgroundColor:'#C6CED2'}:{}}>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Dropdown
                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    options={sampleOptions}
                                                    placeholder="Test Type" 
                                                    disabled={this.state.onhide}
                                                    />
                                            </div>

                                        
                                    </div>}
                                    {!this.state.onGenerateQuestions && <div className=" col-4">
                                        <label htmlFor="title" className="block font-serif text-lg font-bold text-800 mb-2">No. Of Questions<span className="ma-required">*</span></label>
                                        <div className='flex'>
                                            <div className="p-inputgroup border-300 shadow-2 border-round-md" style={this.state.onhide ? {backgroundColor:'#C6CED2'}:{}}>
                                                <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                                    //  value={term.academicTitle} 
                                                    //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                                    placeholder="No. Of Questions"
                                                    disabled={this.state.onhide}
                                                />
                                            </div>
                                            {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                                        </div>
                                        {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                                    </div>}
                                    {!this.state.onhide &&

                                        <div className=" col-4">
                                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800 mb-2">Total Time (in minutes)<span className="ma-required">*</span></label>
                                            <div className='flex'>
                                                <div className="p-inputgroup border-300 shadow-2 border-round-md">
                                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                    <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                                        //  value={term.academicTitle} 
                                                        //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                                        placeholder="00"
                                                    />
                                                </div>
                                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                                            </div>
                                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                                        </div>
                                    }


                                    {!this.state.onhide &&
                                        <div className=" col-4">
                                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800 mb-2">Total Marks<span className="ma-required">*</span></label>
                                            <div className='flex'>
                                                <div className="p-inputgroup border-300 shadow-2 border-round-md">
                                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                    <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                                        //  value={term.academicTitle} 
                                                        //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                                        placeholder="Total Marks"
                                                    />
                                                </div>
                                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                                            </div>
                                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                                        </div>
                                    }
                                    {!this.state.onhide &&
                                        <div className="col-4 ">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Test Format<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>

                                                    <MailIcon />
                                                </div>
                                                <Dropdown

                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    options={this.state.testFormats}
                                                    value={this.state.testFormatType}
                                                    onChange={(e) => this.setState({ testFormatType: e.target.value })}
                                                    placeholder="Test Format" />

                                            </div>
                                        </div>
                                    }
                                    {!this.state.onhide && this.state.testFormatType == 2 &&
                                        <>

                                            <div className=" col-4">
                                                <label className='block font-serif text-lg font-bold text-800 mb-2'>Start Date & Time<span className='ma-required'>*</span></label>
                                                <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                    <div className='ml-3' style={{ alignSelf: 'center', }}>

                                                        <MailIcon />
                                                    </div>
                                                    <Calendar id="time24" className='h-3rem text-lg pl-3 w-12 border-round-md  border-none' placeholder="Start Date & Time" showTime showSeconds />
                                                </div>
                                            </div>

                                            <div className=" col-4">
                                                <label className='block font-serif text-lg font-bold text-800 mb-2'>End Date & Time<span className='ma-required'>*</span></label>
                                                <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                    <div className='ml-3' style={{ alignSelf: 'center', }}>

                                                        <MailIcon />
                                                    </div>
                                                    <Calendar id="time24" className='h-3rem text-lg pl-3 w-12 border-round-md  border-none' placeholder="End Date & Time" showTime showSeconds />
                                                </div>
                                            </div>

                                        </>}
                                    {!this.state.onhide &&

                                        <div className="col-4 ">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Test Template<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>

                                                    <MailIcon />
                                                </div>
                                                <Dropdown
                                                    options={this.state.testTemplates}
                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    value={this.state.selectedTestTemplate}
                                                    onChange={(e) => { this.setState({ selectedTestTemplate: e.target.value }) }}
                                                    placeholder="Select Template" />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="">
                                    <div className="flex justify-content-center ">
                                        {/* <Button className="addButton" onclick={this.handleSelfSelection}>
                                <p >Self selection</p>
                            </Button> */}

                                        {this.state.selfSelection && this.state.selectedTestTemplate !== 3 &&
                                            <Button className='formCancelButton '
                                                onClick={this.handleSelfSelection} style={this.state.onhide ? { backgroundColor: '#076EFF', borderRadius: '12px', marginRight: '17px', borderWidth: 'none' } : {}}
                                            >
                                                <p className='formCancelButtonText' style={this.state.onhide ? { color: '#FFFFFF' } : {}}>Self selection</p>
                                            </Button>}
                                        {this.state.autoSelection && this.state.selectedTestTemplate !== 3 &&
                                            <Button className="addButton ml-4 " onClick={this.handleAutoPaperGenerate} style={this.state.onhide ? { marginRight: '10px' } : {}}>
                                                <p >Auto Paper Generate</p>
                                            </Button>}
                                        {this.state.onhide && this.state.selectedTestTemplate !== 3 &&
                                            <div onClick={this.handleOnEdit} className="cursor-pointer mt-2 ml-2" >
                                                <ClassetEditIcon width={32} height={32} color={'#000000'} onClick={this.onEdit} className="mt-2 " />
                                            </div>}
                                    </div>


                                </div>



                            </div>}
                        {this.state.selectedTestTemplate == 3 &&
                            <>
                                <div className={`createtest-card `} >
                                    <div className="grid">
                                        <div className="col-4 ">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Board<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Dropdown
                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    options={sampleOptions}
                                                    placeholder="Select Board" />
                                            </div>
                                        </div>
                                        <div className="col-4 ">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Grade<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Dropdown
                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    options={sampleOptions}
                                                    placeholder="Select Grade" />
                                            </div>
                                        </div>
                                        <div className="col-4 ">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Subject<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Dropdown
                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    options={sampleOptions}
                                                    placeholder="Select Subject" />
                                            </div>
                                        </div>
                                        <div className="col-4 ">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Chapter<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Dropdown
                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    options={sampleOptions}
                                                    placeholder="Select Chapter" />
                                            </div>
                                        </div>
                                        <div className="col-4 ">
                                            <label className='block font-serif text-lg font-bold text-800 mb-2'>Topic<span className='ma-required'>*</span></label>
                                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                                    <MailIcon />
                                                </div>
                                                <Dropdown
                                                    className='h-3rem text-lg pl-3 w-12 border-round-md  border-none'
                                                    options={sampleOptions}
                                                    placeholder="Select Topic" />
                                            </div>
                                        </div>
                                        {this.state.onGenerateQuestions &&
                                            <div className="h-3rem mt-6 flex justify-content-center">
                                                <Button className="addButton ml-4 " onClick={this.handleGenerateQuestions}>
                                                    <p >Generate Questions</p>
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                {!this.state.onhide && <div className="mt-7 flex justify-content-center">
                                    <Button className="addButton ml-4 " onClick={this.handleGenerateQuestions} style={this.state.onhide ? { marginRight: '10px' } : {}}>
                                        <p >Generate Questions</p>
                                    </Button>
                                </div>}

                            </>
                        }

                        <div>
                            <div className="">
                                {this.state.onhide &&

                                    <DataTable ref={(el) => this.dt = el}
                                        value={this.question}
                                        dataKey="id"
                                        paginator
                                        rows={10}
                                        // showGridlines
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        globalFilter={this.state.globalFilter}
                                        responsiveLayout="scroll"
                                        // header={header}
                                        selectionMode={  this.state.onhide && (this.state.selfSelection || this.state.selectedTestTemplate !== 3) &&'checkbox'}
                                    >
                                        {this.state.onhide && (this.state.selfSelection || this.state.selectedTestTemplate !== 3) &&
                                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}
                                        <Column field="question" header="Question" className="datatable-cell" sortable />
                                        <Column field="solutionandkey" header="Solution and Key" className="datatable-cell" sortable />
                                        <Column className=' ' header="" sortable body={(rowData, { rowIndex }) => {
                                            return (<>
                                                <div className="flex flex-column">
                                                    {this.state.onhide && this.state.autoSelection && <RefreshIcon onclick={this.handleRefresh} width={32} height={32} />}
                                                    {this.state.onhide && (this.state.selfSelection || this.state.selectedTestTemplate == 3) && <ClassetEditIcon width={32} height={32} color={'#000000'} />}
                                                    {this.state.onhide && (this.state.selfSelection || this.state.selectedTestTemplate == 3) && <QuestionMarkIcon width={32} height={32} />}

                                                </div>
                                            </>
                                            );
                                        }} ></Column>

                                    </DataTable>


                                }

                            </div >

                        </div>
                        {/* <div>

                    </div> */}

                    </div>


                </Dialog>

            </>
        )
    }
}






export default TestsandExam