import React, { Component } from 'react';
import moment from 'moment';
import { discussionBoardEndpoint } from './../../../store/apiConstants';
import { Button } from 'primereact/button';
import Service from './../service';
import { Toolbar } from 'primereact/toolbar';
import ReportDialog from './report';
import { getProfileURL } from './../../../utile';
import { connect } from 'react-redux';
import Comments from '../allComments';
import CreateAnswer from '../allAnswers/createAnswer';
import Attachments from './attachments';
import { Menu } from 'primereact/menu';
import { DisLikeIcon, Edit2Icon, LikeIcon, ReplyIcon, ReportingHighlightIcon, TickMarkIcon } from '../../svgIcons';
import withRouter from '../../lib/withRouter';

const TEACHER_ID = localStorage.getItem('discussion_uId');





class Answers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            showReport: false,
            showComments: false,
            open: false,
            showEdit: false,
            moreOptionsRef: React.createRef(null),
            isDeleted: false,
            isLoading: false
        }
        this.service = new Service();
    }

    likeOrDislike = (id, liked, event) => {
        if (liked && event == 'like') {
            return;
        }
        if (liked == false && event == 'dislike') {
            return;
        }

        let likeCount = 0;
        let dislikeCount = 0;
        if (liked) {
            if (event == 'dislike') {
                likeCount = -1;
                dislikeCount = 1;
            }
        } else if (liked == null) {
            if (event == 'dislike') {
                dislikeCount = 1;
            } else if (event == 'like') {
                likeCount = 1;
            }
        } else if (liked == false) {
            if (event == 'like') {
                likeCount = 1;
                dislikeCount = -1;
            }
        }

        const url = `${discussionBoardEndpoint}/answers/${id}/${event}`;
        let reqBody = {
            postId: this.props.postId
        }

        this.service.post(url, reqBody, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {

                    this.setState((prevState) => ({
                        item: {
                            ...prevState.item,
                            liked: event == 'like' ? true : false,
                            likeCount: prevState.item.likeCount + likeCount,
                            dislikeCount: prevState.item.dislikeCount + dislikeCount
                        },
                        isLoading: false
                    }));
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }

    reportThis = (reason) => {
        const url = `${discussionBoardEndpoint}/reports`;
        this.service.post(url, {
            for: 'Answer',
            doc: this.state.item._id,
            content: reason,
            postId: this.props.postId

        }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        item: { ...prevState.item, reported: true },
                        showReport: false
                    }));
                } else {
                    this.setState((prevState) => ({
                        showReport: false
                    }));
                }
            } else {
                this.setState((prevState) => ({
                    showReport: false
                }));
            }
        });
    }


    onReportClick = (reported) => {
        if (reported) {
            return;
        } else {
            this.setState({
                showReport: true
            });
        }
    }

    onMoreClick = (event) => {
        this.setState({
            open: true,
            moreOptionsRef: event.currentTarget
        });
    }

    markAsCorrectAnswer = (answerId) => {

        const url = `${discussionBoardEndpoint}/answers/${answerId}`;
        this.service.patch(url, {
            isCorrect: true
        }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        item: { ...prevState.item, isCorrect: true },
                        open: false
                    }));
                } else {
                    this.setState((prevState) => ({
                        open: false

                    }));
                }
            } else {
                this.setState((prevState) => ({
                    open: false
                }));
            }
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    onEditClick = () => {
        this.setState({
            open: false,
            showEdit: true
        });
    }

    onEditComplete = () => {
        this.setState({
            showEdit: false
        });
    }

    onEditAnswer = (answer) => {
        this.setState((prevState) => ({
            item: answer,
            showEdit: false
        }));
    }

    onDeleteClick = () => {
        this.setState({
            open: false
        });
        const url = `${discussionBoardEndpoint}/answers/${this.state.item._id}`;
        this.service.delete(url, {}, true).then(data => {

            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        isDeleted: true
                    }));
                }
            }
        });

    }


    render() {
        const { item, isDeleted } = this.state;
        const items = [];


        if (!item.isCorrect) {
            items.push({
                template: (<div onClick={(event) => { this.markAsCorrectAnswer(item._id); this.menu.toggle(event) }}> <span className='li-item-text'><TickMarkIcon width={"16px"} /> Mark as Correct Answer</span></div>)
            });
        }

        if (this.props.discussionUser && this.props.discussionUser._id != item.postedBy._id && !item.reported) {
            items.push({
                template: (<div onClick={(event) => { this.setState({ showReport: true, open: false }); this.menu.toggle(event) }}>
                    <span className='li-item-text'><ReportingHighlightIcon width={"16px"} /> Report this Answer</span></div>)
            })
        }

        if (this.props.discussionUser && this.props.discussionUser._id == item.postedBy._id) {
            items.push({
                template: (<div onClick={(event) => { this.onEditClick(item); this.menu.toggle(event) }}>  <span className='li-item-text'><Edit2Icon width={"16px"} /> Edit</span></div>)
            },
                {
                    template: (<div onClick={(event) => { this.onDeleteClick(item); this.menu.toggle(event) }}> <i className='pi pi-trash'></i><span className='li-item-text'>Delete</span> </div>)
                })
        }


        return (
            <>
                {(!item.reported && !isDeleted) &&
                    <>
                        <div className="discussion-item disc-answer">
                            {this.state.showEdit ? <>
                                <CreateAnswer onEditAnswer={this.onEditAnswer} postId={this.props.postId} answer={item} />
                            </> : <>
                                <div style={{ width: '100%', overflow: 'hidden' }}>
                                    <div style={{ width: '40px', float: 'left' }}>
                                        <img src={getProfileURL(item?.postedBy?.photo)} alt="n" className="profile-img" />
                                    </div>
                                    <div style={{ marginLeft: '40px' }} className="answer-c">
                                        <p className="profile-name2">{item?.postedBy?.name}
                                            {item.isCorrect && <span className="correct-ans">Correct</span>}
                                        </p>
                                        <p className="created-at">{moment(item?.createdAt).fromNow()} </p>
                                        <p className="answer-txt">{item.content}</p>
                                        {(item.attachments && item.attachments.length) ? <Attachments attachments={item.attachments} /> : <></>
                                        }

                                        <Toolbar className="ma-toolbard"
                                            left={() => {
                                                return <ul className="discussion-actions" >
                                                    <li onClick={() => { this.likeOrDislike(item._id, item.liked, 'like') }}>
                                                        <span className='ma-pointer'>
                                                            <LikeIcon width={16} height={16} color={item.liked ? 'var(--primary-color)' : '#9D9A9A'} />
                                                        </span>
                                                        {item.likeCount}</li>
                                                    <li onClick={() => { this.likeOrDislike(item._id, item.liked, 'dislike') }}  >
                                                        <span className='ma-pointer'>
                                                            <DisLikeIcon width={16} height={16} color={item.liked == false ? "#ec8888" : '#9d9a9a'} />
                                                        </span>
                                                        {item.dislikeCount}</li>

                                                    <li onClick={() => { this.setState({ showComments: !this.state.showComments }) }}>
                                                        <span className='ma-pointer'>
                                                            <ReplyIcon width={16} height={16} /> </span>{item.commentCount}</li>

                                                </ul>
                                            }}
                                            right={() => {
                                                return <>
                                                    {items && items.length ? <span className="favourite-icon"
                                                        onClick={(event) => this.menu.toggle(event)}
                                                    >
                                                        <i className="pi pi-ellipsis-h ma-pointer" />
                                                    </span> : <></>}
                                                </>
                                            }}>
                                        </Toolbar>
                                        <Menu className='answer-menu' model={items} popup ref={el => this.menu = el} />
                                    </div>
                                </div>
                            </>}
                            {
                                this.state.showReport && <ReportDialog open={true} onClose={() => { this.setState({ showReport: false }) }} reportThis={this.reportThis} />
                            }
                        </div>
                        {
                            this.state.showComments && <Comments postId={this.props.postId} answerId={item._id} commentCount={item.commentCount} />
                        }
                    </>
                }
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}

export default connect(mapStateToProps, {})(withRouter(Answers));