import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import SvgViewer from '../../customComponents/svgViewer';
import LoadingComponent from '../../loadingComponent';
import AddStaff from './addStaff';
import '../styles.scss'
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { DeleteIcon, PreviewIcon, WarningIcon } from '../../svgIcons';
import { Dialog } from 'primereact/dialog';
import PreViewDetials from './preViewDetials'
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import moment from 'moment';


const items = [
    { label: 'Organization' },
    { label: 'Transport Staff Management', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


export default class TransportStaffManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transportStaffDetials: [
                // { designation: 'driver' }
            ],
            isLoading: false,
            showAddStaff: false,
            globalFilter: null,
            globalSearch: '',
            showSearch: false,
            columns: [
                { columnName: 'Aadhar No', isVisible: false, id: 'aadharCard' },
                { columnName: 'Driving Liecence', isVisible: false, id: 'drivingLicence' },
            ],
            warningDialog: warningDailogInit,
            warningDialogForDelete: warningDailogInit,
            previewDetails: false,
            editStaffData: null,
            lazyParams: BasicLazyParams,
            totalRecords: 0,
        }

        this.service = new Service()
    }

    componentDidMount() {
        this.getStaffData()

    }

    getStaffData = () => {
        this.setState({
            isLoading: true
        })
        let lazyParams = this.state.lazyParams;

        let url = `${baseUrlAdmin}/transport-staff?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`

        this.service.get(url, true).then(res => {
            
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    transportStaffDetials: res?.res?.data?.data,
                    totalRecords: res?.res?.data?.totalRecords
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })
    }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4>Transport Staff Management</h4>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    onClickAddStaff = () => {

        this.setState({
            showAddStaff: true
        })
    }

    onClose = () => {
        this.setState({
            editStaffDetails: '',
            showAddStaff: false
        })
    }

    onSaveOrUpdate = (isUpdate) => {
        this.toast.show({ severity: 'success', summary: 'Saved', detail: `Staff ${isUpdate ? 'updated' : 'created'} successfuly`, life: 3000 });
        this.setState({
            editStaffDetails: '',
            showAddStaff: false
        }, this.getStaffData)
    }

    onClosePreview = () => {
        this.setState({
            previewDetails: false
        })
    }

    showEditRoleDialog = (editData) => {

        this.setState({
            editStaffDetails: editData,
            showAddStaff: true,
        });
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    handleDelete = async (rowData) => {
        this.setState({
            warningDialogForDelete: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want to delete the ${rowData} from the staff.`
            },
            editStaffData: rowData
        });
    }

    deleteTimeTable = () => {
        // let { editStaffData } = this.state;
        // const url = `${baseUrlAdmin}/`;

        // this.setState({
        //     warningDialogForDelete: warningDailogInit,
        //     isLoading: true
        // })
        // this.service.delete(url, true).then((res) => {
        //     if (res && res.status && res.res.status) {
        //         this.setState({
        //             isLoading: false
        //         }
        //  () => {
        //     this.();
        // }
        //         )
        //     } else {
        //         this.setState({
        //             isLoading: false
        //         });
        //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        //     }
        // }).catch(e => {
        //     console.log(e);
        //     this.setState({
        //         isLoading: false
        //     });
        //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        // })
    }

    showPreview = (rowData) => {
        this.setState({
            previewDetails: true,
            viewStaffDetails: rowData
        })
    }


    editTemplate = (rowData, { rowIndex }) => {
        return (<>
            <div className="p-d-flex  ">
                <span data-pr-tooltip="Preview" data-pr-position="bottom" className={`ma-ml10 ml-3 edd${rowIndex}`}
                    onClick={() => {
                        this.showPreview(rowData)
                    }}>
                    <PreviewIcon height={15} width={15} />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.edd${rowIndex}`} />
                <span data-pr-tooltip="Edit" data-pr-position="bottom" className={`ma-ml10 ml-3 ar${rowIndex}`} onClick={() => this.showEditRoleDialog(rowData)} >
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="15" height="15" />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.ar${rowIndex}`} />

                {/* <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`dlete${rowIndex} p-ml-3 ma-ml10`}
                    onClick={() => { this.handleDelete(rowData) }}
                >
                    <DeleteIcon height={15} width={15} />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.dlete${rowIndex}`} /> */}

            </div>
        </>
        );
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getStaffData);
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getStaffData);
    }


    render() {

        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Transport Staff List</h4>
                    {this.state.showSearch && <InputText placeholder="Search by Staff Name" className="ml-4 h-2rem w-15rem" value={this.state.globalSearch} onChange={this.onGlobalSearch} />}
                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <i data-pr-tooltip="View Columns"
                            onClick={(event) => this.menu.toggle(event)}
                            data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className = "mt-2"
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                    <div>
                        <div className="-mt-4">
                            <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        <div className='ma-mtb25'>
                            <Button label="+ Add Staff" className='p-button-raised my-3' onClick={this.onClickAddStaff} />
                        </div>
                        <div className='card table-demo ma-m30'>
                            <DataTable ref={(el) => this.dt = el}
                                value={this.state.transportStaffDetials || []}
                                dataKey="_id"
                                paginator
                                lazy
                                showGridlines
                                onPage={this.onPage}
                                rows={this.state.lazyParams.rows}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                totalRecords={this.state.totalRecords}
                                responsiveLayout="scroll"
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"

                                globalFilter={this.state.globalFilter}
                                header={header}
                            >
                                <Column header="S.No." className='' body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + index.rowIndex + 1}</p>
                                        </>
                                    )
                                }} />
                                <Column field="designation" className='' header="Designation" />
                                <Column field="name" header="Name" />
                                <Column field="" header="Date of Joining" body={(rowData) => {
                                        return (
                                            <>
                                                <p>{moment(rowData.dateOfJoining).format('DD/MM/YYYY')}</p>
                                            </>
                                        )
                                    }}/>
                                <Column field="phoneNumber" header="Phone Number" />
                                {
                                    this.isColumnVisible('drivingLicence') && <Column field="drivingLicence" header="Driving Liecence" />
                                }
                                {
                                    this.isColumnVisible('aadharCard') && <Column field="aadharCard" header="Aadhar Card No." />
                                }
                                <Column field="yearsOfExperience" header="Years Of Experience" />
                                <Column field="noOfCasesInvolved" header="No. of Cases Involved" />
                                <Column field="classOfVehicle" header="Class of Vehicle" />
                                <Column field="rtoAuthority" header="RTO Authority" />
                                <Column field="" header="RTO Issued & Expiry Dates"
                                    body={(rowData) => {
                                        return (
                                            <>
                                                <p>{moment(rowData.rtoIssuedDate).format('DD/MM/YYYY')} - {moment(rowData.rtoExpiryDate).format('DD/MM/YYYY')}</p>
                                            </>
                                        )
                                    }}
                                />
                                <Column field="" header="Actions" body={this.editTemplate} />
                            </DataTable>
                            {this.state.showAddStaff &&
                                <AddStaff
                                    editStaffDetails={this.state.editStaffDetails}
                                    visible={this.state.showAddStaff}
                                    onClose={this.onClose}
                                    onSaveOrUpdate={this.onSaveOrUpdate}
                                />
                            }
                        </div>
                    </div>


                    <Dialog
                        draggable={false}
                        blockScroll={true}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        dismissableMask={true}
                        closable={false}
                        visible={this.state.warningDialogForDelete.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                        footer={() => {
                            return (<div>
                                <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, editStaffData: null }) }} />
                                <Button label="Yes" className='ma-m-lr10' onClick={this.deleteTimeTable} />

                            </div>)
                        }}
                        onHide={() => this.setState({ warningDialogForDelete: warningDailogInit, editStaffData: null })}>
                        <div>
                            <span className="warning-icon" >
                                <WarningIcon width={"100%"} height={"100%"} />
                            </span>
                        </div>
                        <p className='ma-alert-head'>{this.state.warningDialogForDelete.headerMsg}</p>
                        <p className='ma-alert-msg'>{this.state.warningDialogForDelete.message}</p>
                    </Dialog>

                    {this.state.previewDetails &&
                        <PreViewDetials
                            viewStaffDetails={this.state.viewStaffDetails}
                            previewDetails={this.state.previewDetails}
                            onClosePreview={this.onClosePreview}
                        />}

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {this.state.isLoading && <LoadingComponent />}

            </>
        )
    }
}
