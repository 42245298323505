import React, { Component } from 'react'
import Service from '../../discussionBoard/service';
import { baseUrlAdmin, ticketMangementEndpoint } from '../../../store/apiConstants';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';
import Authentication from './../../session';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}

class TicketManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            inlineLoadingD: false,
            ticketManagementArr: [],
            dialogHeader: "",
            showDiolog: false,
            arrToDataTable: [],
            totalRecords: 11,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            isLoading: false
        }
        this.service = new Service();
    }

    componentDidMount() {
        this.getTicketsData()
    }
    getTicketsData = () => {
        this.setState({ isLoading: true })
        const url = `${ticketMangementEndpoint}/dashboard-stats`;
        this.service.get(url, true).then(res => {
            if (res && res.status) {
                this.setState({
                    ticketManagementArr: res?.res?.data,
                    isLoading: false
                })
            } else {
                this.setState({ isLoading: false });
            }
        }).catch(e => {
            this.setState({ isLoading: false });
        })
    }
    onClickMore = (h) => {
        this.setState({
            showDiolog: true,
            dialogHeader: h
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event });
    }

    onClickTm = () => {
        this.props.navigate('/ticket-management')
    }
    render() {
        const { defaultLanguage } = this.props;
        const { inlineLoading, ticketManagementArr } = this.state;
        const langType = defaultLanguage


        return (
            ticketManagementArr &&   <div className='ma-relative'>
                <div className='resource-usage-report' style={{ background: "#f7ecd7" }}>
                    <div className='flex-between'>
                        <h3 className="resource-title ma-mt10">TicketMangement</h3>
                        <span className="mar"><i data-pr-tooltip={`$ticketMangement`}
                            data-pr-position="top" className="pi pi-arrow-circle-right ma-mt15 ma-mr20" style={{ fontSize: '1rem' }}
                            onClick={this.onClickTm}
                        ></i></span>
                        <Tooltip className="" autoHide={false} target=".pi-arrow-circle-right" />
                    </div>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-11'>
                            <div className='trending-table-rup' style={{ width: "120%" }}>
                                <table className='trending-table'>
                                    <tr>
                                        {/* <th style={{color:"#98fb98",font:"normal normal bold 14px/16px Roboto"}}>number</th> */}
                                        <th style={{ color: "#ff0000", font: "normal normal bold 14px/16px Roboto" }}>status</th>
                                        <th style={{ color: "#6b8e23", font: "normal normal bold 14px/16px Roboto" }}>count</th>
                                    </tr>
                                    {ticketManagementArr && ticketManagementArr.map((ticket, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{ticket._id}</td>
                                                    <td>{ticket.count}</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </table>
                                <div className='flex-reverse ma-mr100'>
                                    {/* <span className='ma-mt10 ma-pointer' onClick={() => this.onClickMore("Language Wise Resources")}
                                    style={{color:"#004cff",font:"normal normal bold 13px Roboto"}}>{`<< $more >>`}</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog className='ma-alert-box p-dialog-dash p-overflow-hidden'
                    header={<h4>{this.state.dialogHeader}</h4>}
                    draggable={false}
                    closeOnEscape={true}
                    blockScroll={true}
                    dismissableMask={false}
                    closable={true}
                    visible={this.state.showDiolog} style={{ maxWidth: '50vw', minWidth: '50vw' }}
                    onHide={() => this.setState({ showDiolog: false })}
                >
                    <div className='card datatable-crud-demo'>
                        <DataTable stripedRows className='asdf'
                            ref={(el) => this.dt = el}
                            value={this.state.arrToDataTable}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                            dataKey="id"
                            paginator
                            // lazy
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Languages"
                            globalFilter={this.state.globalFilter}
                            header={''}
                        >
                            <Column header='Sl No.' field='no' />
                            <Column header='Type' field='type' />
                            <Column header='Videos' field='count' />
                            <Column header='PPT' field='count' />
                            <Column header='PDF' field='count' />
                            <Column header='Quizzes' field='count' />
                        </DataTable>
                    </div>
                    {
                        this.state.inlineLoadingD && <InLoadingComponent />
                    }
                </Dialog>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(TicketManagement));
