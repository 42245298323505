export const StaticConstants = {
    ERUDEX_API_KEY: "ed09892d-b315-4f42-b24c-e41bd7e01cc0",
    ERUDEX_SECRET_KEY: "RPFLU7gZORJctpOnUpQ7AlKCoR0G1tybFuUZW/lETY4=",
    ERUDEX_CONTENT_URL: "https://api-q.erudex.com/content/getContentUrl",
    MAX_FILE_SIZE: 209715200
}



export const S3_UPLOAD_CONFIG = {
    bucketName: 'classet-profiles',
    dirName: 'uploads/', /* optional */
    region: 'ap-south-1',
    accessKeyId: 'AKIAQ3ITD5EMFRXJSJO2',
    secretAccessKey: 'kLEubEBqbKFzyBAC3N0MuwygSvMM4cKHVsK3LIQs',
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}

export const MELUHA_BOARD_ID = '498fc658-d02f-4fd1-9181-0fb3912a0046';



export const FIELDS_INFO = {
    ORG_NAME: "Enter the name of the organization registered as per the ROC",
    ORG_TITLE: "Enter the title of the organization registered as per the ROC",
    ORG_ADDRESS: 'Enter the address of the organization registered as per the ROC',
    ORG_ESTD: "Enter the company established Years",
    ORG_COUNTRY: "Enter the country of the organization registerd as per the ROC",
    ORG_STATE: "Enter the state of the organization registerd as per the ROC",
    ORG_CITY: "Enter the city of the organization registered as per the ROC",
    ORG_PINCODE: 'Enter the pincode of the organization registered.',
    ORG_MOBILE_NUMBER_OPERATIONAL: "Enter the organization operational mobile number",
    ORG_MOBILE_NUMBER_ACCOUNTS: "Enter the organization accounts mobile number",
    ORG_EMAIL_ID_OPERATIONAL: "Enter the operational email-id of the organization",
    ORG_EMAIL_ID_ACCOUNTS: "Enter the accounts email_id of the organization",
    ORG_WEBSITE: "Enter the website of organization",
    ORG_SMS_CLIENT_WEBSITE: "Enter the sms client website of the organization",
    ORG_EMAIL_CLIENT_WEBSITE: "Enter the email client website of the organization",
    ORG_SUBDOMAIN: "Enter the subdomain of the organization",
    ORG_LOGO_RECTANGUALAR: "Upload the rectangular-logo of the organization",
    ORG_IDCARD_BANNER_IMAGE:"Upload the banner image of the organization",
    ORG_IDCARD_SIGNATURE:"Upload the signature image for the idcard",
    ORG_IDCARD_WATERMARK:"Upload the signature image for the idcard",
    ORG_IDCARD_QRCODE:"Please select the QR code genteration type",
    ORG_QRCODE_URL:"Please enter the valid url",
    ORG_QRCODE_TEXT:"Please enter the valid text",
    ORG_LOGO_SQUARE: "Upload the square-logo of the organization",
    ORG_GSTNO: "Enter the GST number",
    ORG_GST_CERTIFICATE: "Upload GST certificate.",
    ORG_AGREEMENT_TENURE_STARTDATE: "Select the start date of agreement tenure",
    ORG_AGREEMENT_TENURE_ENDDATE: "Select the end date of agreement tenure",
    ORG_AGREEMENT: "Upload agreement of the organization",
    ORG_OTP: "Please enter OTP to verify",
    ORG_ADMIN_USER: "Please enter Super User Phone Number for login",
    ORG_ADMIN_PASSWORD: "Please enter the password",
    ORG_ADMIN_CONFIRM_PASSWORD: "Please confirm the enter above password here",

    ROLE_SELECT: 'Please select the role you want to switch.',

    ADMIN_CHANGE_ROLE: 'Does user can change his role',
    ADMIN_USER_TYPE: 'Please select the user type',
    ADMIN_USER_ROLE: 'Please select user role',
    ADMIN_USER_NAME: 'Please enter the user name',
    ADMIN_USER_LOGIN_NAME: 'Please enter the username for login',
    ADMIN_USER_EMAIL_ID: 'Please enter the email id',
    ADMIN_USER_MOBILE: 'Please enter the mobile number',
    ADMIN_USER_PASSWORD: 'Please enter the password',
    ADMIN_USER_CONFIRM: 'Please renter the password',
    ADMIN_USER_CURRICULUM: 'Please assign the curriculum',

    ADMIN_ROLE_NAME: 'Please enter the role name',
    ADMIN_ROLE_DESCRIPTION: 'Please enter the role description',
    ADMIN_ROLE_TYPE: 'Please select role type',

    USER_USER_NAME: 'Please enter user name',
    USER_PASSWORD: 'Please enter password',
    USER_CONFIRM: 'Please enter confirm password',
    USER_GIVEN_NAME: 'Please enter given name',
    USER_MOBILE_NUMBER: 'Please enter mobile number',
    USER_EMAIL_ID: 'Please enter email id',
    USER_BOARD: 'Please select board',
    USER_CLASS: 'Please select class',
    PLEASE_SELECT: 'Please select ',
    ACADEMIC_YEAR:"Please select academic year",

    SUBJECT_NAME_1: 'Please enter subject name',
    SUBJECT_CODE: 'Please enter subject code',
    SUBJECT_STATUS: 'Please select subject status ',


    CHAPTER_NUMBER: 'Please enter chapter number',
    CHAPTER_NAME: 'Please enter chapter name',
    CHAPTER_CODE: 'Please enter chapter code',
    CHAPTER_DESCRIPTION: 'Please enter chapter description',
    CHAPTER_LONG_DESCRIPTION: 'Please enter chapter long description',
    CHAPTER_STATUS: 'Please select chapter status ',

    TOPIC_NAME: 'Please enter topic name',
    TOPIC_CODE: 'Please enter topic code',
    TOPIC_STATUS: 'Please select topic status',

    BOARD_NAME: 'Please enter board name',
    BOARD_CODE: 'Please enter board code',
    BOARD_STATUS: 'Please select board status',



    TEMPLATE_NAME: 'Please enter template name',
    TEMPALTE_CONTENT: 'Please enter template content',
    TEMPLATE_ID: 'Please enter template Id',

    TEMPALTE_BODY: 'Please enter template body',


    CLASS_NAME: 'Please enter class name',
    CLASS_STATUS: 'Please select class status',




    RESOURCE_TITLE: 'Please enter resource title',
    RESOURCE_FILE: 'Please select the file by clicking on select file, the selected file name will display here.',
    RESOURCE_TYPE: 'Please select resource type',
    RESOURCE_ORIGIN: 'Please select resource origin',
    RESOURCE_URLKEY: 'Please enter resource URL or key',
    RESOURCE_SOURCE: 'Resource source',
    RESOURCE_FILENAME: 'Resource file name',
    RESOURCE_LANGUAGE:'Select Content Language',



    LEVEL_NAME: 'Please enter the level name',
    SCHEME_OF_VALIDATION: 'Please enter the schema of validation.',
    KEY_ANSWER: 'Please enter the key or answer to validate the answer.',
    QUESTION: 'Please enter the question.',
    PARAGRAPH: 'Please enter the paragraph.',
    OPTION: 'Please enter the option.',
    KEY: 'Please select the key',
    KEYS: 'Please select the keys',
    HINT: 'Please enter the hint',
    EXPLAINATION: 'Please enter the explaination',
    DIFFICULTY: 'Please select the question difficulty.',
    SKILLTYPE: 'Please select the skill type.',
    EXAM_TYPE: 'Please select the exam type.',
    QUESTION_SOURCE: 'Please select the source type.',
    QUESTION_STATUS: 'Please select the question status.',
    QUESTION_TAGS: 'Please enter the question tags.',
    EXAM_NAME: 'Please enter the name of the test',
    NO_OF_QUESTIONS: 'Please enter the number of questions',
    EXAM_TIME: 'Please enter the exam time in minutes',
    TOTAL_MARKS: 'Please enter the total marks',
    VALID_FROM: 'Please enter the exam start date & time',
    VALID_TO: 'Please enter the exam end date & time',
    TEST_TYPE: 'Please select test type.',
    TEMPLATE: 'Please select exam template .',
    BOARD_SELECT: 'Please select board',
    CLASS_SELECT: 'Please select class',
    QUESTION_TYPES: 'Please select question types',
    SUBJECT_SELECT: 'Please select subject',
    EXAM_SELECT: 'Please select exam',

    NAME_OF_BREAK: 'Please enter name of break',
    DURATION: 'Please enter duration',

    TEACHER_NAME: 'Please select teacher',
    SUBJECT_NAME: 'Please select subject',
    DESCRIPTION: 'Please enter description',
    LEARNING_OBJECTIVE: 'Please enter learning objective',
    LEARNING_OUTCOME: 'Please enter learning outcome',
    NOTIFICATION_NAME: 'Please enter notification name',
    NOTIFICATION_TYPE: 'Please enter notification type',
    NOTIFICATION_SCHEDULE: 'Please enter notification schedule',
    DATEANDTIME: 'Please enter date and time',
    NOTIFICATION_MODE: 'Please enter notification mode',
    NOTIFICATION_TITLE: 'Please enter notification title',
    NOTIFICATION_DESCRIPTION: 'Please enter notification description',

    API_KEY: 'Please enter API Key',
    DLT_HEADER: 'Please enter DLT header',

    PHONE_NUMBER_ID: 'Please enter phone number Id registered in whatsapp.',
    SYSTEM_USER_TOKEN: 'Please system user token',

    HOST: 'Please enter host address',
    EMAIL: 'Please enter email',
    PASSWORD: 'Please enter password',
    FROM_NAME: 'Please enter from address',

    WHATSAPP_TEMPLATE_NAME: 'Please select whatsapp template',




    FEE_TYPE: 'Please enter Fee Type',
    IS_CONSESSION_APPLICABLE: 'Please enter is consession applicable for this fee type.',
    IS_STUDENT_SPECIFIC: 'Please select student specific',
    RELIGION : "Please select religion",
    CASTE : "Please select caste",
    GENDER : "Please select gender",
    ADMISSION_DATE :"Please select admission date",
    AADHAR_NUMBER : "Please enter aadhar number",
    DATE_OF_BIRTH:"Please select date of birth",
    AGE : "Please enter age",
    FATHER_NAME : "Please Enter Father Name",
    OCCUPATION : "Please Select Occopation",
    QUALIFICATION : "Please Select Qualification",
    MOTHER_NAME : "Please Enter Mother Name",
    GUARDIAN_NAME : "Please Enter Guardian Name",
    HOUSE_NUMBER : "Please Enter House Number",
    STREET : "Please Enter Street",
    MANDAL : "Please Enter Mandal",
    CITY : "Please Enter City",
    STATE : "Please Enter State",
    PINCODE : "Please Enter PIN-Code",
    UPLOAD : "Upload a file",


    ROLE_ROLE_NAME: 'Please enter Role name',

}


export const FeeApplicableType = [
    {
        label: "New Students",
        value: 'new'
    },
    {
        label: "Promoted Students",
        value: 'promoted'
    }
]


export const FeeRegistrationType = [
    {
        label: "Day Scholar",
        value: 'days'
    },
    {
        label: "Residential",
        value: 'residential'
    },
    {
        label: 'Semi Residential',
        value: 'semiresidential'

    }
]


export  const admissionTypes=[
    {label:'Day Scholar', value:'19d0361e-4578-43be-ba2d-8736ad875a06'},
    {label:'Residential', value:'899f5bea-c85f-4a71-8faf-8f22b8b5d3db'}  
]


export const paymentTerms=[
//   { label:'One Term',value:'8c4f487d-b42b-4599-8c10-2f203eab965a'},

  { label:'Term 1',value:'ca4b7f44-d568-4d82-8e9b-820cbcc11c73'},

  { label:'Term 2',value:'8cb98167-013d-4ac0-b93a-2a5dde7b9626'},

  { label:'Term 3',value:'50eed730-dc05-4798-9eef-7698f9989bd4'},

  { label:'Term 4',value:'c507eb71-4e0d-4e37-935d-5b1511c6009c'},

  { label:'Term 5',value:'f0387aa7-c6a3-4a59-8a35-7a6f955d28cb'},

  { label:'Term 6',value:'da829ce0-c52b-4668-b983-5a59d7dd40e1'},

  { label:'Term 7',value:'12da4720-f137-4cb7-afd7-113ba7d727ed'},

  { label:'term 8',value:'3866e143-dac3-46c4-a173-7cad8bbd71a6'},

  { label:'Term 9',value:'527bb551-f946-41a7-a93a-2f9c178eca2b'},

  { label:'Term 10',value:'05b3b223-cd8d-48e3-b1e9-9c34fa6d1616'},

  { label:'Term 11',value:'22644b8f-444e-4924-a11f-e7ead100a77f'},

  { label:'Term 12',value:'fd883d57-9dfa-4e99-8066-e4de76876e60'},
]
export const paymentModes=[
    { label:'Cash',value:'74b95cc8-9736-44a0-b2df-325595783c3b'},
    { label:'Online',value:'1b1a76b4-c538-4968-a20b-0e5a3b4a1c54'},
    { label:'Credit Card',value:'b9e46415-1b11-4377-9105-58b98c230935'},
    { label:'Debit Card',value:'274a4416-2454-4f3a-b741-89c67361ae1f'},
    { label:'Qr Code',value:'32b180cf-7727-46ef-b2a8-e7b1b19fd489'},
    { label:'Cheque',value:'1c98862e-46dc-4454-a120-7d15e2e56e2f'},
    { label:'DD',value:'9d08ebab-2d3e-4a30-8460-4e4092fe30a1'},
]

export const States = ['Andaman and Nicobar', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh',
    'Chhattisgarh', 'Delhi', 'Daman and Diu', 'Dadra and Nagar Haveli', 'Goa', 'Gujarat', 'Haryana',
    'Himachal Pradesh', 'Jammu and Kashmir', 'Ladakh', 'Lakshadweep', 'Jharkhand', 'Karnataka', 'Kerala',
    'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Odisha', 'Mizoram', 'Orissa', 'Punjab', 'Rajasthan',
    'Sikkim', 'Tamilnadu', 'Telangana', 'Tripura', 'Uttarkhand', 'Uttar Pradesh', 'West Bengal', 'Puducherry'];