import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authentication from './../session';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import LoadingComponent from '../loadingComponent';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Service from '../../services';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import {
    getBoardsData
} from './../../store/actions';
import './styles.scss'
import AddFromRepo from './addFromRepo';
import AddFromLocal from './addFromLocal';
import { ContetOrigin, ContentOrigins, getFileTypes, getContentResourseType, warningDailogInit, ContentSource, getContentSource, FileTypes, BasicLazyParams } from '../../utile/index';
import uuidv4 from 'uuid/v4';
import EditContent from './editContent';
// import ContentPlayer from './contentPlayers';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { QuestionTargets, } from '../questionBank/constants';
import PreviewQuestion from '../questionBank/common/previewQuestion';
import SingleQuestion from '../questionBank/common/singleQuestion';
import SvgViewer from '../customComponents/svgViewer';
import QuestionInTable from '../questionBank/common/questionInTable';
import { DeleteIcon, PreviewIcon, WarningIcon } from '../svgIcons';
import { toHaveAccessibleDescription } from '@testing-library/jest-dom/dist/matchers';
import withRouter from '../lib/withRouter';
const items = [
    { label: 'Academics' },
    { label: 'Exams' },
    { label: 'Assign Questions', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }



class AssignQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: null,
            filteredContentDetails: [],
            warningDialog: warningDailogInit,
            isShowEditContent: false,
            selectedContent: null,
            globalFilter: '',
            //contests[3],
            columns: [
                { columnName: 'Question Type', isVisible: true, id: 'questionType' },
                { columnName: 'Video Linked', isVisible: true, id: 'videoLinking' },
                { columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
                { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
                { columnName: 'Exam Type', isVisible: false, id: 'examType' },
                { columnName: 'Source', isVisible: false, id: 'source' },
                { columnName: 'Status', isVisible: false, id: 'status' },

            ],
            showSearch: false,
            globalFilter: null,
            questionTarget: null,
            globalSearch: '',
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            isShowAssignCountDialog: false,
            assignedCount: null


        }
        this.service = new Service();
    }



    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }
    }



    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            filteredContentDetails: [],

        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a,b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                questions: [],
                totalRecords: 0,
                content: null,
                filteredContentDetails: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0
            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopicResource = [];
        let selectedTopic = this.state.topics.filter(t => t.topicId == topicId)
        let selectedTopicResourceTypes = selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [];
        if (selectedTopicResourceTypes && selectedTopicResourceTypes.length) {
            selectedTopicResourceTypes = selectedTopicResourceTypes.map((a, index) => {
                if (index == 0) {
                    a.isSelected = true;
                } else {
                    a.isSelected = false;
                }
                return a;
            })
        }

        this.setState(
            {
                topicId,
                selectedTopicResourceTypes: selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [],
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0
            });
    };


    filterResource = (contents, sTR) => {
        let selectedTopicResourceTypes = _.cloneDeep(sTR)

        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);

        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == filter.value)
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))

        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }


    // fixCrashData = (content1) => {
    //     let contentDetails = content1.contentDetails;
    //     let fileTypes = ['mp4', 'zip', 'pdf', 'quiz'];

    //     let contents = [];
    //     fileTypes.forEach(filetype => {
    //         let filtered = contentDetails.filter((conent) => conent.contentFileType.toLowerCase() == filetype.toLowerCase());


    //         filtered = filtered.map((a, index) => {
    //             a.priority = index + 1;
    //             let id = uuidv4();
    //             a.contentId = id;
    //             return a;
    //         });

    //         contents = [...contents, ...filtered];
    //     });

    //     const { boardId, classId, groupId, subjectId, chapterId, topicId } = content1;
    //     this.updateContent({ boardId, classId, groupId, subjectId, chapterId, topicId, contents });

    // }


    getContentDetails = () => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        this.setState({
            isLoading: true,

        });
        const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}?etag=true`;
        this.service.get(url, true).then((res) => {

            const etag = res && res.etag;



            if (res && res.res && res.res.Item) {
                let filteredContentDetails = [];
                if (res.res.Item.contentDetails) {
                    filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                }
                this.setState((prevState) => {
                    return {
                        content: { boardId, classId, groupId, subjectId, chapterId, topicId, ...res.res.Item },
                        filteredContentDetails,
                        isLoading: false,
                        etag
                    }
                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    updateContent = (content) => {
        this.setState({
            isLoading: true
        });


        const url = `${baseUrlForLongRunningTask}/long/content`;
        this.service.put(url, content, true, { "If-Match": this.state.etag }).then((data) => {


            if (data && data.res && data.res.success) {

                this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Quiz Assigned successfully, It may reflect in 10-15 mins', life: 6000 });
                this.setState({
                    isLoading: false,
                    isShowAddDialog: false,
                    resourseOrigin: this.state.isBaseBoard ? 'upload' : null
                }, this.getContentDetails);
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });


        })
    }

    addClick = (type) => {

        this.setState({
            selectedType: type,
            isShowAddDialog: true
        });


    }



    onSelectQuestion = (checkedQuestionsForAssign) => {
        this.setState({
            checkedQuestionsForAssign
        })
    };


    assignContentTo = () => {
        const { boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            resourseOrigin,
            topicId,
            checkedQuestionsForAssign,
        } = this.state;

        if (!checkedQuestionsForAssign || checkedQuestionsForAssign.length == 0) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select questions to assign', life: 3000 });
            return;
        }

        const content = {
            boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId,
            source: resourseOrigin,
            questions: checkedQuestionsForAssign.map((q) => { return { questionId: q.question.questionId, question: q.question._id, questionType: q.question.questionType, questionTarget: q.question.questionTarget } })
        };


        this.setState({
            isLoading: true
        });

        this.service.post(`${baseUrlAdmin}/questionlinking/assignQuestions`, content, true).then((data) => {
            if (data && data.res && data.res.data) {
                this.setState({
                    isLoading: false,
                    isShowAddDialog: false,
                    resourseOrigin: null,
                    assignedCount: data.res.data,
                    isShowAssignCountDialog: true
                }
                    //, this.fetchQuestions
                );
            } else {
                this.setState({
                    isLoading: false
                });
            }

        }).catch(e => {

            this.setState({
                isLoading: false
            });


        })

    };

    deleteLinking = (rowData) => {
        this.setState({
            isLoading: true
        });
        this.service.delete(`${baseUrlAdmin}/questionlinking/delete/${rowData._id}`, true).then((data) => {
            if (data?.res?.success) {

                this.setState({
                    isLoading: false,
                    isShowAddDialog: false
                }, () => {
                    this.toast.show({ severity: 'success', summary: 'Deleted', detail: 'Question deleted successfully.', life: 3000 });
                    this.fetchQuestions()
                });
            } else {
                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured.', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured.', life: 3000 });
        });

    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    createRoleDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null }) }} />
            <Button label={`Assign`} className='ma-m-lr10' onClick={this.assignContentTo} />
        </div>)
    };


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className=''>Assign Questions</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    moveRecords = (records, old_index, new_index) => {
        let arr = _.cloneDeep(records);
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        arr = arr.map((a, index) => {
            a.priority = index + 1;
            return a;
        })
        return arr; // for testing
    };

    updatePriorityAndUpdateContent = () => {
        let { filteredContentDetails, content } = this.state;
        let conten = _.cloneDeep(content)


        filteredContentDetails.forEach(c => {
            conten.contentDetails = conten.contentDetails.map(cont => {
                if (cont.contentId == c.contentId) {
                    cont.priority = parseInt(c.priority);
                }
                return cont;
            });
        })



        let { boardId, classId, groupId, subjectId, chapterId, topicId,
            contentDetails } = conten;


        this.updateContent({ boardId, classId, groupId, subjectId, chapterId, topicId, contents: contentDetails });

    }

    onMoveClick = (rowData, isUp) => {
        let contentDetail = this.state.filteredContentDetails;
        let findIndex = contentDetail.findIndex((a) => a.contentId == rowData.contentId);
        if (findIndex > 0 && isUp) {
            let newArray = this.moveRecords(contentDetail, findIndex, isUp ? findIndex - 1 : findIndex + 1);

            this.setState((prevState) => {
                return {
                    filteredContentDetails: newArray
                }
            }, this.updatePriorityAndUpdateContent);
        }
        else if ((findIndex + 1) < contentDetail.length && !isUp) {
            let newArray = this.moveRecords(contentDetail, findIndex, isUp ? findIndex - 1 : findIndex + 1);
            // 

            this.setState((prevState) => {
                return {
                    filteredContentDetails: newArray
                }
            }, this.updatePriorityAndUpdateContent);
        }
    }

    onSelectResourceType = (type) => {

        if (type.value == 'ppt') {
            type.value = 'zip'
        }

        let selectedTopicResourceTypes = this.state.selectedTopicResourceTypes;
        let selectedTopicResourceType;
        selectedTopicResourceTypes = selectedTopicResourceTypes.map(r => {
            if (type.value == r.value) {
                r.isSelected = true;

                selectedTopicResourceType = r.value
            } else {
                r.isSelected = false
            }
            return r;
        });




        let contentDetails = (this.state.content && this.state.content.contentDetails) ? this.state.content.contentDetails : [];

        contentDetails = this.filterResource(contentDetails, selectedTopicResourceTypes)

        this.setState({
            selectedTopicResourceTypes,
            filteredContentDetails: contentDetails
        });
    }


    onEditContent = (content) => {
        this.setState({
            selectedContent: content,
            isShowEditContent: true
        });
    }

    onDeleteContent = (content) => {

        this.setState({
            selectedContent: content,
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `${content.contentName} will be deleted, it can't be undone.`
            },
        });
    }



    onYesClick = () => {
        this.setState({
            warningDialog: warningDailogInit,
            //isLoading: true
        }, this.deleteAndUpdate);
    }

    onNoClick = () => {
        this.setState({ warningDialog: warningDailogInit, selectedContent: null })


    }


    deleteAndUpdate = () => {
        let { content, selectedContent } = this.state;
        content.contentDetails = content.contentDetails.filter(c => c.contentId != selectedContent.contentId);
        const { boardId, classId, groupId, subjectId, chapterId, topicId, contentDetails } = content;
        this.updateContent({
            boardId, classId, groupId, subjectId, chapterId, topicId,
            contents: contentDetails
        });

    }

    onUpdateContent = (selectedContent) => {
        let { content } = this.state;
        content.contentDetails = content.contentDetails.map(c => {
            if (c.contentId == selectedContent.contentId) {
                c = selectedContent;
            }
            return c;
        });

        const { boardId, classId, groupId, subjectId, chapterId, topicId, contentDetails } = content;

        this.setState({ isShowEditContent: false }, () => {
            this.updateContent({
                boardId, classId, groupId, subjectId, chapterId, topicId,
                contents: contentDetails
            })
        });
    }

    showPreview = (contentInfo) => {

        this.setState({
            isShowPreview: true,
            selectedContent: contentInfo
        });


    }

    isGradeValid = () => {
        let { boardId, classId, groupId, subjectId, chapterId, topicId, questionTarget } = this.state;

        if (!questionTarget) {
            return false
        } else if (!boardId || !classId || !groupId || !subjectId || (chapterId && !topicId)) {
            return false
        } else if (questionTarget == 'Normal' && (!chapterId || !topicId)) {
            return false
        } else {
            return true
        }
    }


    fetchQuestions = () => {


        if (!this.isGradeValid()) {
            this.toast.show({ severity: 'error', summary: 'Grades Required', detail: 'Please select grades', life: 3000 });
        } else {

            // this.toast.show({ severity: 'success', summary: 'Success', detail: 'valid', life: 3000 });


            // return true;

            const { questionTarget,
                boardId, classId,
                groupId, subjectId,
                chapterId, topicId,

                lazyParams, globalSearch
            } = this.state;


            let filters = { questionTarget };

            // if (!questionTarget) {
            //     this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select question Target', life: 3000 });
            //     return

            // }


            if (questionTarget == 'Normal') {
                if (topicId) {
                    filters = { ...filters, topicId: [topicId] }
                } else if (chapterId) {
                    filters = { ...filters, chapterId: [chapterId] }
                } else if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            } else {
                if (topicId) {
                    filters = { ...filters, topicId: [topicId] }
                } else if (chapterId) {
                    filters = { ...filters, chapterId: [chapterId] }
                } else if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            }


            this.setState({
                isLoading: true,
                questions: [],
                totalRecords: 0
            });


            this.service.post(`${baseUrlAdmin}/questionlinking/filter/questions/page?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {

                if (data && data.status && data?.res?.data) {
                    this.setState({
                        totalRecords: data.res.data.totalRecords,
                        questions: data.res.data.questions,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }


            }).catch(e => {
                this.setState({
                    isLoading: false,

                });
                console.log(e);

            });



        }

    }
    onQuestionTargetChange = (questionTarget) => {
        this.setState({
            questionTarget,
            questions: [],
            totalRecords: 0
        }, () => {
        })
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.fetchQuestions);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.fetchQuestions);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.fetchQuestions);
    }

    onFilter = (event) => {
        event['first'] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.fetchQuestions);
    }



    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    onConfirmOk = () => {

        this.setState({
            isShowAssignCountDialog: false,
            assignedCount: null
        }, this.fetchQuestions)


    }



    questionsAvailableR = () => {
        return <>
            <p className='avail-q'> Available Questions:  <span>{this.state.totalRecords}</span> </p>
        </>
    }



    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Questions List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ml-4  h-2rem w-15rem" onChange={this.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className='mt-2'
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        // console.log('content-master', this.state)

        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;
        return <div>
            < >
                <div className="">
                    {/*<div className="p-col-12 p-md-1 ma-no-p">
                         {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } 
                    </div>*/}
                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>


                <div className="ask-a-doubt ma-main mt-5">
                    <Authorizer permId={PERMISSIONS.EXAM_QUESTIONS_LIST} >
                        <Dropdown value={this.state.questionTarget}
                            className='w-2 ma-w200 ma-mt10'
                            options={QuestionTargets}
                            optionLabel="value"
                            optionValue="id"
                            onChange={(e) => this.onQuestionTargetChange(e.value)}
                            placeholder="Question Target" /><span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.boardId}
                            className='w-2 ml-3 ma-w200  ma-mt10'
                            //options={this.state.boards}
                            options={this.props.boards}
                            optionLabel="boardName"
                            optionValue="boardId"
                            onChange={(e) => this.onChangeBoard(e.value)}
                            placeholder="Select Board" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.classId}
                            options={this.state.classes}
                            className='w-2 ml-3 ma-w200  ma-mt10'
                            onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.subjectId}
                            options={this.state.subjects}
                            optionLabel='subjectName'
                            optionValue='subjectId'
                            className='w-2 ml-3 ma-w200  ma-mt10'
                            onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.chapterId}
                            options={this.state.chapters}
                            optionLabel='chapterName'
                            optionValue='chapterId'
                            className='w-2 ml-3 ma-w200  ma-mt10'
                            onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                        {this.state.questionTarget == 'Normal' ? <span className='ma-required-mark '>*</span> : <span className='ma-required-mark '></span>}
                        <Dropdown value={this.state.topicId}
                            options={this.state.topics}
                            optionLabel='topicName'
                            optionValue='topicId'
                            className='w-2 mt-3 ma-w200  ma-mt10'
                            onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                        {this.state.questionTarget == 'Normal' ? <span className='ma-required-mark '>*</span> : <span className='ma-required-mark '></span>}

                        <Button label='Fetch Questions' className='ma-mt20 ml-3' onClick={this.fetchQuestions} />
                    </Authorizer>

                    {/* <span onClick={() => this.addClick({})} className='add-ic' >
                    <i className="pi pi-plus"></i>
                </span> */}
                    <div className='flex mt-3'>
                        {/* <h3 className='info-txt'>INFO</h3> */}
                        {/* {
                        this.state.topicId ? (this.state.selectedTopicResourceTypes ? this.state.selectedTopicResourceTypes.map((type) => {
                            return <div className='resourse-add'>
                                <p className='type-title'>{type.label}</p>
                                <div className='add-ico'>
                                    <span onClick={() => this.addClick(type)} className='add-ic' >
                                        <i className="pi pi-plus"></i>
                                    </span>
                                    <span className='add-txt'>Add {type.label.toLowerCase()} </span>
                                </div>
                            </div>
                        }) : <>
                            <p>Please assign resource types for selected hierarchy</p>
                        </>) : null
                    } */}
                        <Authorizer permId={PERMISSIONS.EXAM_QUESTIONS_ASSIGN} >
                            {
                                ((this.state.subjectId && this.state.questionTarget == 'Adhoc') || this.state.topicId) ? <div className='resourse-add'>
                                    <p className='type-title'>Questions</p>
                                    <div className='add-ico'>
                                        <span onClick={() => this.addClick()} className='add-ic ma-pointer' >
                                            <i className="pi pi-plus"></i>
                                        </span>
                                        <span className='add-txt'>Assign Questions</span>
                                    </div>
                                </div>
                                    : null
                            }
                        </Authorizer>
                    </div>
                    <div className='ma-clearFix'>
                    </div>
                    <div className="card datatable-crud-demo fetch-q-tabled fetch-q-table ma-mt20 mt-3">
                        <DataTable ref={(el) => this.dt = el}
                            scrollable scrollHeight="500px"
                            responsiveLayout="scroll"
                            dataKey="_id"
                            value={this.state.questions}
                            filters={this.state.lazyParams.filters}
                            // loading={this.props.isLoading}

                            className="ma-table-d"
                            tableClassName='ma-table-tbl'

                            paginatorLeft={this.questionsAvailableR}

                            showGridlines
                            paginator
                            lazy
                            onPage={this.onPage}
                            onSort={this.onSort}
                            onFilter={this.onFilter}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            sortField={this.state.lazyParams.sortField}
                            sortOrder={this.state.lazyParams.sortOrder}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}

                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            header={header}>
                            <Column headerClassName='question-id' field="questionId" header="Question ID" />

                            <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                                return (<>
                                    {
                                        rowData.question && <QuestionInTable question={rowData.question} />
                                    }

                                </>)
                            }} />
                            {
                                this.isColumnVisible('questionType') && <Column headerClassName='difficulty' field="question.questionType" header="Question Type" />}

                            {
                                this.isColumnVisible('videoLinking') && <Column headerClassName='difficulty' field="videoLinking" header="Video Linked"
                                    body={(rowData) => {
                                        return (<>
                                            <InputSwitch
                                                checked={(rowData?.question?.videoLinking && rowData?.question?.videoLinking.length) ? true : false} />

                                        </>)
                                    }}

                                />
                            }

                            {
                                this.isColumnVisible('difficulty') && <Column headerClassName='difficulty' field="question.difficulty" header="Difficulty"

                                />}
                            {
                                this.isColumnVisible('skillType') && <Column headerClassName='difficulty' field="question.skillType" header="Skill Type"

                                />}
                            {
                                this.isColumnVisible('examType') && <Column headerClassName='difficulty' field="question.examType" header="Exam Type"
                                    body={(rowData) => {
                                        return (<>
                                            {rowData.examType && rowData.examType.length ? rowData.examType.join(', ') : ''}
                                        </>)
                                    }}
                                />}
                            {
                                this.isColumnVisible('source') && <Column headerClassName='difficulty' field="question.source" header="Source"
                                />}
                            {
                                this.isColumnVisible('status') && <Column headerClassName='difficulty' field="question.status" header="Status"
                                />}

                            <Column headerClassName='difficulty' headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                                return (<>

                                    {/* <span data-pr-tooltip="Edit Question" data-pr-position="bottom" className={`edit${rowData._id} ma-mr20`} onClick={() => { this.props.onEditQuestion(rowData) }}>
                                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} /> */}

                                    <Authorizer permId={PERMISSIONS.EXAM_QUESTIONS_PREVIEW} >
                                        <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id} ma-mr20`}
                                            onClick={() => {
                                                this.setState({
                                                    isShowQuestionPreview: true,
                                                    questionToPreview: rowData.question
                                                })
                                            }}>
                                            {/* <i className='pi pi-eye ma-mr10' /> */}
                                            <PreviewIcon height={20} width={20} />
                                            {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                            {/* <i className="pi pi-folder-open"></i>  */}
                                        </span>
                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />
                                    </Authorizer>
                                    <Authorizer permId={PERMISSIONS.EXAM_QUESTIONS_DELETE} >
                                        <span data-pr-tooltip="Delete Question" data-pr-position="bottom" className={`edit${rowData._id} ma-mr20 ml-2`} onClick={() => { this.deleteLinking(rowData) }}>
                                            <DeleteIcon height={20} width={20} />
                                            {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        </span>
                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} />
                                    </Authorizer>
                                </>
                                );
                            }} ></Column>
                            {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                        </DataTable>
                        <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    </div>


                    {
                        this.state.isShowQuestionPreview && <PreviewQuestion
                            question={this.state.questionToPreview}
                            onHide={() => {
                                this.setState({
                                    isShowQuestionPreview: false
                                })
                            }}
                        />
                    }

                </div>

                <Dialog
                    //visible={this.state.isShowCreate}
                    style={{ width: '96%', height: '96vh' }}
                    //  header={this.props.isAdd ? "Create Role" : "Edit Role"}
                    modal
                    blockScroll={true}
                    footer={this.createRoleDialogFooter}
                    draggable={false}
                    // footer={this.footer}
                    header={null}
                    closeOnEscape={false}

                    dismissableMask={false}
                    className='add-a-resource-dialog'
                    visible={this.state.isShowAddDialog}
                    closable={false}
                    //header="Add a Resource"
                    onHide={() => { this.setState({ isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null }) }}>
                    <div className='add-a-resource' >
                        {

                            this.state.resourseOrigin == null ? <>
                                <p className='title'>Assign Questions</p>
                                <div className="grid  ">
                                    <div className="col-6 p-md-6 line-v">
                                        <div className='section section-1'>
                                            <div className='add-ico'>
                                                <span className='add-ic add-ic1' onClick={() => { this.setState({ resourseOrigin: 'repo' }) }} >
                                                    <i className="pi pi-plus"></i>
                                                </span>
                                            </div>
                                            <p className='section-text'>Add From Content Library</p>
                                        </div>
                                    </div>
                                    <div className="col-6 p-md-6">
                                        <div className='section section-2'>
                                            <div className='add-ico'>
                                                <span className='add-ic add-ic2' onClick={() => { this.setState({ resourseOrigin: 'upload' }) }} >
                                                    <i className="pi pi-upload"></i>
                                                </span>
                                            </div>
                                            <p className='section-text'>Assign From Curriculum</p>
                                        </div>
                                    </div>
                                </div>
                            </> : <AddFromRepo
                                questionTarget={this.state.questionTarget || 'Normal'}
                                // selectedTopicResourceTypes={this.state.selectedTopicResourceTypes}
                                // contentInfo={this.state.qustions ? this.state.qustions : []}
                                resourseOrigin={this.state.resourseOrigin} selectedType={this.state.selectedType}

                                onSelectQuestion={this.onSelectQuestion}
                            />}

                    </div>
                </Dialog >
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={this.onNoClick} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onYesClick} />

                        </div>)
                    }}
                    onHide={this.onNoClick}>
                    <div>

                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>

                {
                    this.state.isShowEditContent && <EditContent
                        editContent={this.state.selectedContent}
                        onCancelEdit={() => { this.setState({ isShowEditContent: false, selectedContent: null }) }}
                        onUpdateContent={this.onUpdateContent}
                    />
                }
                {
                    this.state.isShowPreview && <></>
                    
                    // <ContentPlayer onCloseDialog={() => {
                    //     this.setState({
                    //         isShowPreview: false
                    //     })
                    // }} contentInfo={this.state.selectedContent} />
                }

                <Dialog
                    header={<>Status</>} headerClassName='text-center'
                    draggable={false}
                    // closeOnEscape={true}
                    className='ma-alert-box'
                    blockScroll={true}
                    //  dismissableMask={true}
                    closable={false}
                    visible={this.state.isShowAssignCountDialog}

                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="Ok" className='ma-m-lr10 p-button-raised' onClick={this.onConfirmOk} />

                        </div>)
                    }}
                    onHide={this.onConfirmOk}>

                    <p className='text-xl mt-2 text-900 font-bold text-center p-button-success'>
                        <b>  {this.state.assignedCount && this.state.assignedCount.added}</b>  questions assigned to selected hierarchy</p>
                    <p className='text-lg text-600 text-center font-bold white-space-normal p-button-success'><b>{this.state.assignedCount && this.state.assignedCount.notAdded}</b>
                        {' '} questions not assigned to selected hierarchy due to duplicate</p>
                </Dialog>

            </>
        </div >
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(AssignQuestions)));