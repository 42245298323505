import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import { connect } from 'react-redux';
import '../styles.scss'

import {
    getBoardsData, getBranchesLatest
} from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { MultiSelect } from 'primereact/multiselect';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import LoadingComponent from '../../loadingComponent';
import moment from 'moment/moment';
import withRouter from '../../lib/withRouter';


const items = [
    { label: 'Academics' },
    { label: 'Transport Attendance Report', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }
class TransportAttendanceReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { columnName: 'Chassis No', isVisible: false, id: 'chassisNo' },
                { columnName: 'Engine No', isVisible: false, id: 'engineNo' }
            ],
            report: [


            ],
            // dateRange: [],
            transportRoutes: [],
            attendanceReport: [],
            reportColumns: []
        }
        this.service = new Service()
    }


    getTransportRoutes = () => {

        const url = `${baseUrlAdmin}/transport-route/get-routes`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res) {

                this.setState({
                    transportRoutes: res.res?.data?.transportRoutes,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className=''>Transport Attendance Report</h4>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }
        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

        // let url = `${baseUrlAdmin}/`

        // this.service.get(url, true).then(res => {
        //     if (res?.status && res?.res?.status) {
        //         this.setState({
        //             isLoading: false,
        //             attendanceDetails: res?.res?.data
        //         })
        //     } else {
        //         this.setState({
        //             isLoading: false
        //         })
        //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        //     }
        // }).catch(err => {
        //     this.setState({
        //         isLoading: false
        //     })
        //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });


        // })

        this.getTransportRoutes();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });



                this.setState({
                    boards: boards
                });
            }
        }


        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }


    onChangeDate = (date, key) => {
        this.setState({
            [key]: date,
            admissions: []
        })
    }

    onSearch = () => {
        if (!this.state.routeId) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Route is required', life: 3000 });
        }
        if (this.state.dateRange.filter(a => a).length !== 2) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select dates ', life: 3000 });
        }
        this.setState({
            isLoading: true,
            attendance: []
        })

        
        const url = `${baseUrlAdmin}/transport-attendance/report`;
        this.service.post(url, {
            routeId: this.state.routeId,
            startDate: this.state.dateRange[0],
            endDate: this.state.dateRange[1]
        }, true).then(res => {
            
            if (res && res.res && res.res.data) {
                this.setState({
                    reportColumns: res.res.data?.columns || [],
                    attendanceReport: res.res.data?.report || [],
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }


    render() {

        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>Attendance Report</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4 h-2rem w-15rem" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className="mt-2"
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                    <div className="">
                        <div className="-mt-4">
                            <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        {/* {
                            this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                return (!(this.props.branchLevels.length == index + 1) ? <Dropdown
                                    className='ma-mr20 ma-mt10 ma-w200'
                                    value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                    // className='ma-w100p'
                                    //    disabled={this.state.hideUpdateButton}
                                    optionLabel={"name"}
                                    optionValue={"key"}
                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                    onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                    placeholder={`--Select ${level.levelName}--`} /> : <MultiSelect
                                    className='ma-mr20 ma-mt10 ma-w200'
                                    value={this.state.sectionIds ? this.state.sectionIds : []}
                                    // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                    onChange={(e) => this.onMultiChange(e.value)}
                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                    optionLabel={"name"}
                                    optionValue={"key"}
                                    placeholder={`--Select ${level.levelName}--`}

                                // onChange={(e) => setCities(e.value)} 
                                />
                                )
                            }) : null
                        } */}
                        <div className='grid my-2'>
                            <div className='col-3'>
                            <Dropdown value={this.state.routeId}
                                options={this.state.transportRoutes || []}
                                optionValue="_id"
                                optionLabel="routeName"
                                className='w-11'
                                onChange={(e) => this.onChangeDate(e.value, 'routeId')} placeholder="Select Route" />
                            </div>
                            <div className='col-3'> 
                                <Calendar showIcon={true}
                                    placeholder="Select Dates"
                                    selectionMode="range"
                                    className='w-11'
                                    readOnlyInput
                                    maxDate={new Date()}
                                    value={this.state.dateRange}
                                    onChange={(e) => {
                                        this.onChangeDate(e.value, 'dateRange')
                                    }}
                                ></Calendar>
                            </div>
                            <div className='col-1'>
                                <Button label='Search' className='ma-ml10' onClick={this.onSearch} />
                            </div>
                        </div>
                        <div className='card datatable-crud-demo ma-m30 fetch-q-tabled'>
                            <DataTable ref={(el) => this.dt = el}
                                scrollable
                                value={this.state.attendanceReport || []}
                                dataKey="_id"
                                paginator
                                rows={10}
                                showGridlines
                                responsiveLayout="scroll"
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                header={header}
                            >
                                <Column header="S.No." className='w-5' body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{index.rowIndex + 1}</p>
                                        </>
                                    )
                                }} />
                                {
                                    this.state.reportColumns.map(c => {
                                        return <Column headerClassName='difficulty' field={c.field} header={c.label} />
                                    })
                                }

                            </DataTable>

                            <div className='mt-3'>
                                <Button label='Save' />
                            </div>
                        </div>
                    </div>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})((withRouter(TransportAttendanceReport)));
// Authentication
