import React, { Component } from 'react'
import { connect } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import Service from '../../services';
import Authentication from '../../session';
import withRouter from '../../lib/withRouter';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { DeleteIconClasset, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';


const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const createTransportFields = require('./createTransportSetting.json');
const formFields = createTransportFields
class TransfortSettingCreation extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createTransportFields);
        this.state = {
            isLoading: false,
            feePlans: [],
            admissionType: [],
            pickupFee: 'average',
            preferences: false,
            refundable: false,
            refundErr: '',
            transport: this.props?.editData?._id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            transportFeeTypes: [{ id: 1, feeType: '', feeName: '', amount: '' }],
            addDifferentialFeeAllow: false
        }
        this.service = new Service();
    }


    componentDidMount() {
        this.getAdmissionTypeDropdown()
        this.getFeePlansData()
    }


    addNewDifferentialFee = () => {
        const newFee = { id: this.state.transportFeeTypes.length + 1, feeType: '', feeName: '', amount: '' };
        this.setState(prevState => ({
            transportFeeTypes: [...prevState.transportFeeTypes, newFee]
        }));
    }

    getAdmissionTypeDropdown = async () => {

        let url = `${baseUrlAdmin}/admission-types`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admision => ({
                    label: admision.categoryName,
                    value: admision._id
                }));

                this.setState({ admissionType: result });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };

    onSubmitTransport = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        let bodyForApi = cloneDeep(this.state.transport)
        const formStatus = isFormValid(createTransportFields, this.formFields.formValidations, bodyForApi);

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        }
        else {
            const payload = {
                "name": bodyForApi?.name,
                "admissionType": bodyForApi?.admissionType,
                "gstVatper": bodyForApi?.GSTPercentage,
                "isGstVat": this.state?.preferences,
                "avgPickDrp": this.state.pickupFee === 'average' ? true : false,
                "singleTriper": bodyForApi?.transportPercentage,
                "isRefundable": this.state.refundable,
                 "academicYear":academicYear

            }
            let url = `${baseUrlAdmin}/bus-fee-plans`;
            try {
                const res = await this.service.post(url, payload, true);
                if (res?.res?.status && res.status) {
                    this.toast.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                } else {
                    const error = 'Some error occurred';
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                }
            } catch (e) {
                console.log(e);
                this.props.toastMessage.show({ severity: 'error', summary: e, detail: e.toString(), life: 3000 });
            }
        }
    }

    deleteDifferentialFee = (index) => {
        const updatedFeeTypes = this.state.transportFeeTypes.filter((_, i) => i !== index);
        this.setState({ transportFeeTypes: updatedFeeTypes });
    };

    handleInputChange = (index, field, value) => {
        const feeType = [...this.state.transportFeeTypes];
        feeType[index][field] = value;
        this.setState({ feeType });
    };


    handleRadioButtonChange = (option) => {
        this.setState({ pickupFee: option });
    }


    getFeePlansData = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/bus-fee-plans?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const feePlan = res?.res?.data?.feePlans[0];
                if (feePlan) {
                    this.setState({
                        transport: {
                            ...this.state.transport,
                            name: feePlan.name,
                            admissionType: feePlan.admissionType,
                            GSTPercentage: feePlan.gstVatper,
                            transportPercentage: feePlan.singleTriper
                        },
                        preferences: feePlan.isGstVat,
                        refundable: feePlan.isRefundable,
                        pickupFee: feePlan.avgPickDrp ? 'average' : 'maximum',
                        isLoading: false
                    });
                }
                this.setState({ feePlans: res?.res?.data?.feePlans, isLoading: false });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };


    render() {
        const { transport, formValidations } = this.state;
        return (
            <>
                <div className=' lg:mt-5 md:mt-7 '>
                    <div className="grid  md:grid-cols-2 lg:grid-cols-3 mx-3 gap-5" >

                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Receipt Display Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    className=' border-round-md add-vehicle-field-input  pl-3 w-full'
                                    value={transport.name}
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    autoComplete='off'
                                    onChange={(e) => onTextChange(e.target.value, 'name', this, formFields, transport, formValidations, 'transport', 'formValidations')}
                                    placeholder="Receipt Display Name" />
                            </div>
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error text-lg">{formValidations.fields['name'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Admission Type<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className=' add-vehicle-field-input w-full'
                                    value={transport?.admissionType}
                                    options={this.state?.admissionType}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'admissionType', this, formFields, transport, formValidations, 'transport', 'formValidations') }}
                                    placeholder='Admission Types'
                                />
                            </div>
                            {formValidations && !formValidations.fields['admissionType'].isValid && <p className="p-error text-lg">{formValidations.fields['admissionType'].errorMsg}</p>}

                        </div>

                    </div>
                    <hr className='mt-6' />
                    <div className=' mt-6'>
                        {/* <h4 className='ml-3  transport-fee-form-heading text-3xl'>Preferences:</h4> */}
                        {/* <div className="grid mt-6  md:grid-cols-2 lg:grid-cols-6 mx-3" style={{ columnGap: "150px", display: 'flex' }} >
                            <div className=" col-span-2  flex">
                                <InputSwitch
                                    onChange={(e) => { this.setState({ preferences: e.target.value }) }}
                                    checked={this.state.preferences} />

                                <div className='ml-3 -mt-2'>
                                    <p className="transport-fee-form-label">GST/VAT Applicable</p>
                                    <p className="transport-fee-form-para"> GST/VAT</p>
                                </div>

                            </div>
                            <div className="col-span-2 -mt-3">
                                <p className='transport-fee-form-label'>GST/VAT percentage
                                </p>
                                <div className='flex'>
                                    <ClassetInputText
                                        className=' border-round-md add-vehicle-field-input text-xl pl-3 w-full'
                                        value={transport.GSTPercentage}
                                        onChange={(e) => onTextChange(e.target.value, 'GSTPercentage', this, formFields, transport, formValidations, 'transport', 'formValidations')}
                                        placeholder='GST/VAT Percentage' />
                                </div>

                            </div>

                            <div className=" col-span-2 ma-status mt-3">
                                <div className='flex'>
                                    <InputSwitch
                                        onChange={(e) => this.setState({ refundable: e.target.value, refundErr: '' })}
                                        checked={this.state.refundable} />
                                    <div className='ml-3 -mt-2'>
                                        <p className="transport-fee-form-heading">Refundable Fee</p>
                                        <p className="transport-fee-form-para">Enable this will allow the users to receive refund based on pro-data</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="grid mt-6  md:grid-cols-2 lg:grid-cols-6 mx-3" >
                            <p className='transport-fee-form-heading'>What should be the transport fee if a person's pickup & drop services are diffrent?</p>
                            <div className="grid col-12" >
                                <div className=" col-3 ma-status" >
                                    <RadioButton
                                        className='mt-1'
                                        onChange={() => this.handleRadioButtonChange('average')}
                                        checked={this.state.pickupFee === 'average'} />
                                    <p className="transport-fee-form-heading ml-3">Average of pickup and drop fee</p>
                                </div>
                                <div className=" col-3 ma-status">
                                    <RadioButton
                                        className='mt-1'
                                        onChange={() => this.handleRadioButtonChange('maximum')}
                                        checked={this.state.pickupFee === 'maximum'} />

                                    <p className="transport-fee-form-heading ml-3">Maximum of pickup and drop fee</p>

                                </div>
                            </div>
                        </div>
                        <div className="grid mt-6  md:grid-cols-2 lg:grid-cols-6 mx-3 " >
                            <div className=" flex col-8 -mt-3">
                                <p className=' transport-fee-form-heading'>What should be the transport fee if a person has availed only pickup or drop service(that is,only one way service) please Define the percentage of fee to be charged in such case</p>
                            </div>
                            <div className="  col-2 -mt-3">
                                <ClassetInputText
                                    className=' border-round-md add-vehicle-field-input text-xl pl-3'
                                    value={transport.transportPercentage}
                                    width={"209px"}
                                    height={"48px"}
                                    onChange={(e) => onTextChange(e.target.value, 'transportPercentage', this, formFields, transport, formValidations, 'transport', 'formValidations')}
                                    placeholder='%' />
                                {formValidations && !formValidations.fields['transportPercentage'].isValid && <p className="p-error text-lg">{formValidations.fields['transportPercentage'].errorMsg}</p>}
                            </div>
                        </div>
                    </div>
                    <div style={{ position: 'fixed', bottom: 0, left: '50%', transform: 'translateX(-50%)', zIndex: 999, backgroundColor: '#ffffff', padding: '20px', width: '100%', textAlign: 'center' }}>
                        <Button label='Save' className='confirmDialogAcceptBtn  mr-5 w-17rem' onClick={this.onSubmitTransport} />
                        <Button label="Cancel" className='confirmDialogCancelBtn ' onClick={(e) => { }} />
                    </div>

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

export default connect(null, {})(Authentication(withRouter(TransfortSettingCreation)));