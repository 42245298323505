import { Component } from "@fullcalendar/core";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { RadioButton } from 'primereact/radiobutton';
import { CodeLinkIcon, MailIcon } from "../../svgIcons";
import ClassetDropdown from "../../../classetComponents/classetDropDown";
import ClassetInputText from "../../../classetComponents/classetInputText";
import Service from "../../../services";
import { getFormFields, onChoiceChange, onDropDownChange, onTextChange, onToggleChange } from "../../../utile/formHelper";
import { baseUrlAdmin } from "../../../store/apiConstants";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../loadingComponent";
const generalSettingsformFields = require('./generalSettings.json');
const principalNames = [
    { value: 1, label: "sudha" },
    { value: 2, label: "sudharshan" },
]
const sampleOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
]


class GeneralSettings extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(generalSettingsformFields);


        this.state = {
            generalSettings: {},
            formValidations: this.formFields.formValidations,



        }
        this.service = new Service();

    }
    componentDidMount() {

        this.getGeneralSettings()

    }
    getGeneralSettings = () => {
        let academicYear=localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/feeSettings?academicYear=${academicYear}`
        this.setState({
            isLoading: true
        });
        this.service.get(url, true).then(data => {
            // console.log(data?.res?.data?.feeSettings, "generalSettings")

            if (data.status) {
                if (data.res.status && data?.res?.data?.feeSettings) {

                    let generalsettings = {
                        ...data?.res?.data?.feeSettings, ...data?.res?.data?.feeSettings?.blockStudent, ...data?.res?.data?.feeSettings?.concessionSettings,
                        ...data?.res?.data?.feeSettings.reportCards, ...data?.res?.data?.feeSettings.feeReceiptSettings
                    }
                    delete generalsettings.concessionSettings
                    delete generalsettings.blockStudent
                    delete generalsettings.reportCards
                    delete generalsettings.feeReceiptSettings


                    this.setState({
                        generalSettings: generalsettings,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });

    }

    onSubmitGeneralSettings = () => {
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear')
        let g = this.state.generalSettings
        let payload =
        {
            "authorizedSignatory": g.authorizedSignatory,
            "isLockFeeCollection": g.isLockFeeCollection,
            "isPrintTransportDetails": g.isPrintTransportDetails,
            "isTransportdetailsinFeeReceipt": g.isTransportdetailsinFeeReceipt,
            "isStudentLoginFeeCollection": g.isStudentLoginFeeCollection,
            "isPartialPayment": g.isPartialPayment,
            "isEnablePaymentGateway": g.isEnablePaymentGateway,
            "isEnableFeeRefundApproval": g.isEnableFeeRefundApproval,
            "concessionSettings": {
                //  "onTotalFees": g.onTotalFees,
                //  "onRemainingFees": g.onRemainingFees
                'fee': g.fee
            },
            "blockStudent": {
                "isBlockStudent": g.isBlockStudent,
                "dueDays": g.dueDays,
                "comments": g.comments,
            },
            "reportCards": {
                // "isFeeAmount": g.isFeeAmount,
                // "isFeePercentage": g.isFeePercentage,
                'feeamount': g.feeamount,
                "minAmount": g.minAmount,
                "isEnabled": g.isEnabled,
            },
            "feeReceiptSettings": {
                "noOfReceiptsPrinted": g.noOfReceiptsPrinted,
                "noOfParentCopies": g.noOfParentCopies,
                "noOfAdminCopies": g.noOfAdminCopies,
                "isReceiptsBasedOnFeeType": g.isReceiptsBasedOnFeeType
            },
            "partialPayment":g.partialPayment  , //newly added this for collect fee
            // "_id":g._id
        }
        payload.academicYear=AcademicYear
        let url = `${baseUrlAdmin}/feeSettings`;
        if(this.state.generalSettings._id){
            payload._id=g._id

        }else{
            delete payload._id
        }
        this.service.post(url, payload, true).then(data => {
            // console.log(data, "create or update")
            if (data?.status) {
                this.setState({
                    isLoading: false
                })
                this.toast?.show({
                    severity: 'info', summary: 'Success', detail: data.res.message
                    , life: 3000
                });
                this.getGeneralSettings()
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating the fee type', life: 3000 });

            }

        });




    }
    render() {
        const { generalSettings, formValidations } = this.state
        // console.log(generalSettings, 'sudha')
        return (
            <>
                <div className="content-container mb-5 mt-5 mr-5 ml-5">
                    <div className="grid mb-2" style={{padding:'20px'}} >
                        <div className="sm:col-6 lg:col-3 md:col-6">
                            <label className='label mb-1'>Fee Certificate Authorized Signatory<span className='ma-required'>*</span></label>
                            <div className="mt-2">
                                <ClassetDropdown
                                    id='authorizedSignatory'
                                    icon={<MailIcon />}
                                    className="text-xl"
                                    height="48px"
                                    width="368px"
                                    options={principalNames}
                                    value={generalSettings.authorizedSignatory}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'authorizedSignatory', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                    placeholder={(
                                        <div>
                                            <span className='text-xl'>Select Principal Name</span>
                                        </div>
                                    )}
                                />
                            </div>
                            {formValidations && !formValidations.fields['authorizedSignatory'].isValid && <p className="p-error">{formValidations.fields['authorizedSignatory'].errorMsg}</p>}
                        </div>


                    </div>
                 
                    <div className="grid my-6 " style={{padding:'20px'}}>
                        <div className='flex my-4'>
                            <div className=" flex  ">
                                <InputSwitch className="" id='isLockFeeCollection'
                                    checked={generalSettings?.isLockFeeCollection}
                                    onChange={(e) => { onToggleChange(e.value, 'isLockFeeCollection', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                />
                                <div className="flex flex-column lg:ml-3 ">
                                    <label className="generalSettings-label1" htmlFor='isLockFeeCollection'>Lock Fee Collection </label>
                                    <p className="generalSettings-label2">Any user would not be able to do any fee cancellation and parents won't be able to do online payment</p>
                                </div>
                            </div>

                            <div className=" flex  ">
                                <InputSwitch className="" 
                                 id='isPrintTransportDetails'
                                 checked={generalSettings?.isPrintTransportDetails}
                                 onChange={(e) => { onToggleChange(e.value, 'isPrintTransportDetails', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                  
                                />
                                <div className="flex flex-column lg:ml-3 ">
                                    <label className="generalSettings-label1" htmlFor='isPrintTransportDetails'>Print Transport Details</label>
                                    <p className="generalSettings-label2">It allows schools to include the transport detals of the student in the fee receipt</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex my-4'>
                            <div className=" flex  ">
                                <InputSwitch className="" 
                             id="isTransportdetailsinFeeReceipt"
                             checked={generalSettings?.isTransportdetailsinFeeReceipt}
                             onChange={(e) => { onToggleChange(e.value, 'isTransportdetailsinFeeReceipt', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                />
                                <div className="flex flex-column lg:ml-3 ">
                                    <label className="generalSettings-label1" htmlFor='isLockFeeCollection'>Transport Details of the student in the fee receipt </label>
                                    <p className="generalSettings-label2">When is is enables system will not allow user to pay selected dues are still pending </p>
                                </div>
                            </div>

                            <div className=" flex  ">
                                <InputSwitch className="" 
                                 id='isStudentLoginFeeCollection'
                                 checked={generalSettings?.isStudentLoginFeeCollection}
                                 onChange={(e) => { onToggleChange(e.value, 'isStudentLoginFeeCollection', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                               
                                />
                                <div className="flex flex-column lg:ml-3 ">
                                    <label className="generalSettings-label1" htmlFor='isStudentLoginFeeCollection'>Student Login Fee Collection </label>
                                    <p className="generalSettings-label2">payment can be done from student login as well</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex my-4'>
                            <div className=" flex  ">
                                <InputSwitch className="" 
                                id='isPartialPayment'
                                checked={generalSettings?.isPartialPayment}
                                onChange={(e) => { onToggleChange(e.value, 'isPartialPayment', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                />
                                <div className="flex flex-column lg:ml-3">
                                    <label className="generalSettings-label1" htmlFor='isPartialPayment'>Partial Payment-Parent Login </label>
                                    <p className="generalSettings-label2">Allow Partial Payment in Parent Login</p>
                                </div>
                            </div>

                            <div className=" flex  ">
                                <InputSwitch className="" 
                                 id='isEnablePaymentGateway'
                                 checked={generalSettings?.isEnablePaymentGateway}
                                 onChange={(e) => { onToggleChange(e.target.value, 'isEnablePaymentGateway', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                />
                                <div className="flex flex-column lg:ml-3 ">
                                    <label className="generalSettings-label1" htmlFor='isEnablePaymentGateway'>Enable Payment Gateway - parents  </label>
                                    <p className="generalSettings-label2">Parents will be able to pay fees online using Mobile/Web</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex my-4'>
                            <div className=" flex  ">
                                <InputSwitch className="" 
                                id='isEnableFeeRefundApproval'
                                checked={generalSettings.isEnableFeeRefundApproval}
                                onChange={(e) => { onChoiceChange(e.value, 'isEnableFeeRefundApproval', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                />
                                <div className="flex flex-column lg:ml-3 ">
                                    <label className="generalSettings-label1" htmlFor='isEnableFeeRefundApproval'>Enable Fee Refund Approval</label>
                                    <p className="generalSettings-label2">Admin's Approval will be needed for Refunding Fees</p>
                                </div>
                            </div>
                            <div className=" flex  ">
                                <InputSwitch className="" 
                                id='partialPayment'
                                checked={generalSettings?.partialPayment}
                                onChange={(e) => { onChoiceChange(e.value, 'partialPayment', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                />
                                <div className="flex flex-column lg:ml-3 ">
                                    <label className="generalSettings-label1" htmlFor='isEnableFeeRefundApproval'>Enable Partial Fee Payment</label>
                                    <p className="generalSettings-label2">Admin's Approval will be needed for Partial Fee Payment</p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="generalsetting-card cursor-pointer" style={{paddingLeft:'30px'}}>
                        <div className="flex flex-column ">
                            <h3 className='genaralsettings-cardhead  ' >Concession Settings</h3>
                            <div className='flex mt-5 '>
                                <div className="flex  align-items-center mr-2 ml-8 mt-2 " style={{padding:'20px'}}>
                                    <RadioButton inputId="onTotalFees"
                                        name="fee"s
                                        id='onTotalFees'
                                        value='onTotalFees'
                                        checked={generalSettings?.fee === 'onTotalFees'}

                                        onChange={(e) => {
                                            onChoiceChange(e.value, 'fee', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations)
                                        }}
                                    />
                                    <label htmlFor="onTotalFees" className="ml-3 generalSettings-label1">On Total Fees</label>

                                </div>


                                <div className="flex align-items-center ml-4">
                                    <RadioButton inputId="onRemainingFees" name="fee"
                                        id='onRemainingFees'
                                        value='onRemainingFees'
                                        checked={generalSettings?.fee === 'onRemainingFees'}

                                        onChange={(e) => {
                                            onChoiceChange(e.value, 'fee', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations)
                                        }}
                                    />
                                    <label htmlFor="onRemainingFees" className="ml-3 generalSettings-label1">On Remaining Fees</label>
                                </div>
                            </div>
                            <p className="ml-4 mt-3 generalsettings-cardtext">Select The Method of Calculator for concession amount when multiple concessions are assigned to the student</p>
                        </div>
                    </div>
                    <div className='generalsetting-card  cursor-pointer' style={{paddingLeft:'30px'}}>
                        <div className="flex flex-column ">
                            <h3 className='genaralsettings-cardhead mb-5'>Fee Due Block Student</h3>
                            {/* <div className="flex mt-2 "> */}
                                <div className="flex row mt-5 " style={{paddingTop:'20px',paddingBottom:'20px'}}>
                                    <InputSwitch
                                        checked={generalSettings?.isBlockStudent}
                                        onChange={(e) => { onToggleChange(e.value, 'isBlockStudent', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                        id='isBlockStudent' />
                                    <div className="flex flex-column ml-3">
                                        <h3 className='generalSettings-label1'>Enable Block Student</h3>
                                        <p className="ml-0 mt-0 generalsettings-cardtext">Enable this setting if you want to block acounts of students who haven't paid the fees</p>
                                    </div>
                                    {/* {formValidations && !formValidations.fields['isBlockStudent'].isValid && <p className="p-error">{formValidations.fields['isBlockStudent'].errorMsg}</p>} */}



                                </div>
                            {/* </div> */}

                            <div className="grid">
                                <div className="sm:col-12 lg:col-6">
                                    <label className='ml-0 generalSettings-label1'>Due Days<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            id='dueDays'
                                            icon={<CodeLinkIcon height={22}/>}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Due Days"
                                            value={generalSettings?.dueDays}
                                            onChange={(e) => { onTextChange(e.value, 'dueDays', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                        />
                                        {/* {formValidations && !formValidations.fields['dueDays'].isValid && <p className="p-error">{formValidations.fields['dueDays'].errorMsg}</p>} */}


                                    </div>
                                </div>
                                <div className=" sm:col-12 lg:col-6">
                                    <label className='ml-0 generalSettings-label1'>Comment<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            id='comments'
                                            icon={<CodeLinkIcon height={22}/>}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Comment/Records"
                                            value={generalSettings?.blockStudent?.comments}
                                            onChange={(e) => { onTextChange(e.value, 'comments', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                        />
                                        {/* {formValidations && !formValidations.fields['comments'].isValid && <p className="p-error">{formValidations.fields['comments'].errorMsg}</p>} */}



                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=" generalsetting-card cursor-pointer " style={{paddingLeft:'30px'}}>
                        <div className="flex flex-column">
                            <h3 className="genaralsettings-cardhead">Record Cards Fee Defaulters</h3>
                            <div className="mt-2">
                                <div className='flex mt-5 '>
                                    <div className="flex  align-items-center mr-2 ml-8 " style={{padding:'20px'}}>
                                        <RadioButton inputId="isFeeAmount" name='feeamount'
                                            id='isFeeAmount'
                                            value='isFeeAmount'
                                            checked={generalSettings?.feeamount === 'isFeeAmount'}
                                            onChange={(e) => {
                                                onChoiceChange(e.value, 'feeamount', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations)

                                            }}

                                        />
                                        <label htmlFor="isFeeAmount" className="ml-3 generalSettings-label1">Fee Amount</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="isFeePercentage" name='feeamount'
                                            id='isFeePercentage'
                                            value='isFeePercentage'
                                            checked={generalSettings?.feeamount === 'isFeePercentage'}
                                            onChange={(e) => {
                                                onChoiceChange(e.value, 'feeamount', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations)
                                            }}

                                        />
                                        <label htmlFor="isFeePercentage" className="ml-3 generalSettings-label1">Fee Percentage on Total fee</label>
                                    </div>
                                </div>
                            </div>
                            <div className="grid mt-2">
                                <div className="lg:col-3 sm:col-12">
                                    <label className='ml-0 generalSettings-label1'>Minimum Amount <span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            id='minAmount'
                                            icon={<CodeLinkIcon height={22}/>}
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Minimum Amount Over Due"
                                            value={generalSettings?.minAmount}
                                            onChange={(e) => { onTextChange(e.target.value, 'minAmount', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}

                                        />
                                        {/* {formValidations && !formValidations.fields['minAmount'].isValid && <p className="p-error">{formValidations.fields['minAmount'].errorMsg}</p>} */}

                                    </div>

                                </div>


                                <div className="lg:col-3 col-4 mt-5 sm:col-12 ">
                                    <div className="flex mt-3">
                                        <InputSwitch inputId="isEnabled"
                                            id='isEnabled'
                                            className="mr-2"
                                            checked={generalSettings?.isEnabled}
                                            onChange={(e) => { onToggleChange(e.target.value, 'isEnabled', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}

                                        />
                                        {/* {formValidations && !formValidations.fields['isEnabled'].isValid && <p className="p-error">{formValidations.fields['isEnabled'].errorMsg}</p>} */}


                                        <label className='generalSettings-label1 ml-1'>Enable </label>
                                    </div>
                                </div>

                                {/* </div> */}
                            </div>

                        </div>
                    </div>
                    <div className="generalsetting-card  cursor-pointer " style={{paddingLeft:'30px'}}>
                        <div className="flex flex-column">
                            <h3 className="genaralsettings-cardhead">
                                Fee Receipt Settings
                            </h3>
                            <div className="flex -ml-5  mt-4" style={{padding:'16px'}}>

                                <div className="lg:col-4 sm:col-4">
                                    <label className='generalSettings-label1'>Total Fee Receipt Printed<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetDropdown
                                            id='noOfReceiptsPrinted'
                                            icon={<MailIcon />}
                                            className="text-xl"
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            value={generalSettings?.noOfReceiptsPrinted}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'noOfReceiptsPrinted', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}

                                            placeholder={(
                                                <div>
                                                    <span className='text-xl'>Total Fee Receipt</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {/* {formValidations && !formValidations.fields['noOfReceiptsPrinted'].isValid && <p className="p-error">{formValidations.fields['noOfReceiptsPrinted'].errorMsg}</p>} */}

                                </div>
                                <div className="lg:col-4 sm:col-4">
                                    <label className='generalSettings-label1'>Total Parent Copies<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetDropdown
                                            id='noOfParentCopies'
                                            icon={<MailIcon />}
                                            className="text-xl"
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            value={generalSettings?.noOfParentCopies}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'noOfParentCopies', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                            placeholder={(
                                                <div>
                                                    <span className='text-xl'>Total Parent Copies</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {/* {formValidations && !formValidations.fields['noOfParentCopies'].isValid && <p className="p-error">{formValidations.fields['noOfParentCopies'].errorMsg}</p>} */}





                                </div>
                                <div className="lg:col-4 sm:col-4">
                                    <label className='generalSettings-label1'>Total Admin Copies<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetDropdown
                                            id='noOfAdminCopies'
                                            icon={<MailIcon />}
                                            className="text-xl"
                                            height="48px"
                                            width="368px"
                                            options={sampleOptions}
                                            value={generalSettings?.noOfAdminCopies}
                                            onChange={(e) => { onDropDownChange(e.target.value, 'noOfAdminCopies', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}

                                            placeholder={(
                                                <div>
                                                    <span className='text-xl'>Total Admin Copies</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {/* {formValidations && !formValidations.fields['noOfAdminCopies'].isValid && <p className="p-error">{formValidations.fields['noOfAdminCopies'].errorMsg}</p>} */}
                                </div>
                            </div>
                            <div className="grid lg:col-6 sm:col-6 mb-4" style={{padding:'16px'}}>
                                <div className="flex">

                                    <InputSwitch className="mt-1"
                                        id='isReceiptsBasedOnFeeType'
                                        checked={generalSettings?.isReceiptsBasedOnFeeType}
                                        onChange={(e) => { onToggleChange(e.target.value, 'isReceiptsBasedOnFeeType', this, generalSettingsformFields, generalSettings, formValidations, 'generalSettings', formValidations) }}
                                    />
                                    <div className="flex flex-column">
                                        <h3 htmlFor="ontotalfee" className="ml-3 generalSettings-label1">Recipts Bsed on Fee Types</h3>
                                        <p className="generalSettings-label2 ml-3">Will Generate Fee Receipt Based on fee Type collection Preference
                                            but parent will have single recipt and will override above requirements
                                        </p>
                                    </div>
                                    {/* {formValidations && !formValidations.fields['isReceiptsBasedOnFeeType'].isValid && <p className="p-error">{formValidations.fields['isReceiptsBasedOnFeeType'].errorMsg}</p>} */}

                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="button-wrapper">
                            <Button className='submitButton'
                                onClick={this.onSubmitGeneralSettings}

                            >
                                <p className='submitButtonText'>Save</p>
                            </Button>

                            <Button
                                className="cancelButtonn"

                                onClick={this.onHideCreateFeeCategoryDialog}>
                                <p className='cancelButtonnText'>Cancel</p>

                            </Button>

                        </div>


                    {/* <div className="fixed-button-container ">
                        <div className="button-wrapper">
                            <Button className='submitButton'
                                onClick={this.onSubmitGeneralSettings}

                            >
                                <p className='submitButtonText'>Save</p>
                            </Button>

                            <Button
                                className="cancelButtonn"

                                onClick={this.onHideCreateFeeCategoryDialog}>
                                <p className='cancelButtonnText'>Cancel</p>

                            </Button>

                        </div>
                    </div> */}
                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

                {this.state.isLoading && <LoadingComponent />}
            </>
        )
    }
}
export default GeneralSettings