import React, { Component } from 'react'
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import SvgViewer from '../../../customComponents/svgViewer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import moment from 'moment';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import withRouter from '../../../lib/withRouter';
class TemplateList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      globalFilter: null,
      loading: false,
      totalRecords: 0,
      customers: null,
      selectAll: false,
      selectedCustomers: null,
      selectedRepresentative: null,
      columns: [
        { columnName: 'Total Number Of Questions', isVisible: true, id: 'totalNoOfQuestions' },
        { columnName: 'Total Marked Questions', isVisible: true, id: 'totalMarkedQuestions' },
        { columnName: 'Total Time', isVisible: true, id: 'totalTime' },
        { columnName: 'Instructions', isVisible: true, id: 'instructions' }
      ]

    }
  }

  onColumnClick = (column) => {
    let columns = this.state.columns.map(c => {
      if (column.id == c.id) {
        c.isVisible = !c.isVisible;
      }
      return c;
    });
    this.setState({
      columns
    });
  }


  isColumnVisible = (id) => {
    return this.state.columns.filter(c => c.id == id)[0].isVisible;
  }


  exportCSV = () => {
    this.dt.exportCSV();
  }

  removeDisable = (classNames) => {
    return classNames.replace('p-disabled', '')

  }




  onNextClick = (e, onClick) => {
    console.log(e);

    this.props.getNextRecords();

    // let productsTemp = records.map((r) => {
    //     r.code = 'sha' + index;
    //     index++;
    //     return r;
    // })
    // this.setState({ products: productsTemp }, () => {
    //     onClick(e);
    // })
  }


  render() {
    const header = (
      <div className="flex justify-content-between bg-white">
        <div className="flex">
          <h4 className=''>Template List</h4>
          {this.state.showSearch && <InputText placeholder="Search" value={this.props.globalSearch} className="ml-4 h-2rem w-15rem" onChange={this.props.onGlobalSearch} />}

        </div>

        <div className="bg-blue-50 cursor-pointer mb-2 border-round">
          <ul className="flex gap-5 mr-6">
            <Authorizer permId={PERMISSIONS.SUBJECTIVE_TEMPLATE_SEARCH} >
              <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
              <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
            </Authorizer>
            {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
            <Authorizer permId={PERMISSIONS.SUBJECTIVE_TEMPLATE_VIEW_COLUMNS} >
              <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
              <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
            </Authorizer>
            <Authorizer permId={PERMISSIONS.SUBJECTIVE_TEMPLATE_VIEW_DOWNLOAD} >
              <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
              <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
            </Authorizer>
          </ul>
        </div>
        <Menu
          className=''
          model={[
            {
              template: (<div className='ml-2 p-2'>
                <h4 className="filter-title">View Columns</h4>
                <ul>
                  {this.state.columns.map((column) => {
                    return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                      checked={column.isVisible} className='mt-2'
                    ></Checkbox>
                      <label htmlFor={column.id} className='ml-2'>{column.columnName}</label>
                    </li>
                  })}
                </ul>
              </div>)
            }]
          }
          popup
          ref={el => this.menu = el} />
      </div>
    );

    return (<>
      <div className="card datatable-crud-demo fetch-q-tabled fetch-q-table ma-mt20">
        <DataTable ref={(el) => this.dt = el}
          scrollable scrollHeight="500px"
          responsiveLayout="scroll"
          className="ma-table-d"
          tableClassName='ma-table-tbl'
          dataKey="_id"
          value={this.props.templates}
          filters={this.props.lazyParams.filters}
          // loading={this.props.isLoading}
          paginatorLeft={() => {
            return <>
              <p className='avail-q'> Available Templates:  <span>{this.props.totalRecords}</span> </p>
            </>
          }}
          paginator
          lazy
          onPage={this.props.onPage}
          onSort={this.props.onSort}
          onFilter={this.props.onFilter}
          first={this.props.lazyParams.first}
          last={this.props.totalRecords}
          rows={this.props.lazyParams.rows}
          sortField={this.props.lazyParams.sortField}
          sortOrder={this.props.lazyParams.sortOrder}
          totalRecords={this.props.totalRecords}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          //paginatorTemplate={paginatorTemplate}
          //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          // globalFilter={this.state.globalFilter}
          header={header}>

          <Column sortable headerClassName='difficulty' field="name" header="Template Name" />
          <Column headerClassName='difficulty' field="description" header="Description" body={(rowData) => {
            return (<div dangerouslySetInnerHTML={{ __html: rowData.description }}>

            </div>
            );
          }} />



          {
            this.isColumnVisible('totalNoOfQuestions') && <Column headerClassName='col-w240px' field="totalNoOfQuestions" header="Total Number of Questions" />}
          {
            this.isColumnVisible('totalMarkedQuestions') && <Column headerClassName='col-w240px' field="totalMarkedQuestions" header="Total Marked Questions" />}
          {
            this.isColumnVisible('totalTime') && <Column headerClassName='col-w240px' field="totalTime" header="Total Time" />}
          {
            this.isColumnVisible('instructions') && <Column headerClassName='col-w240px' header="Instructions" body={(rowData) => {
              return (<div dangerouslySetInnerHTML={{ __html: rowData.instructions }}>

              </div>
              );
            }} />}




          <Column header="Edit" headerClassName='question-id' body={(rowData) => {
            return (<>
              <Authorizer permId={PERMISSIONS.SUBJECTIVE_TEMPLATE_EDIT} >
                <span data-pr-tooltip="Edit Template" data-pr-position="bottom" className={`edit${rowData._id}`} onClick={() => { this.props.onEditTemplateClick(rowData) }}>
                  <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                  {/* <i className="pi pi-folder-open"></i>  */}
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} />
              </Authorizer>
              <Authorizer permId={PERMISSIONS.SUBJECTIVE_TEMPLATE_DUPLICATE} >
                <span data-pr-tooltip="Duplicate Template" data-pr-position="bottom" className={`dpl${rowData._id}`} onClick={() => { this.props.duplicateTemplate(rowData) }}>
                  <i className="pi pi-book ma-ml20" style={{ height: '20px', width: '20px', top: '-2px', position: 'relative' }}></i>
                </span>
                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.dpl${rowData._id}`} />
              </Authorizer>
            </>
            );
          }} ></Column>
          {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
        </DataTable>
        <Toast ref={(el) => this.toast = el} position="bottom-right" />
      </div>
    </>
    )
  }
}



const mapStateToProps = (state) => ({

});



export default connect(mapStateToProps, {

})((withRouter(TemplateList)));

//Authentication