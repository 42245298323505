import React, { Component } from 'react'
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import {
    VideoIcon, PptIcon,
    PdfIcon,
    QuizIcon,
    QuizCountIcon
} from '../../svgIcons';

let totalAnalyticsTypes = [
    { field: 'mp4', title: 'VIDEOS', icon: <VideoIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'pdf', title: 'PDF', icon: <PdfIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'zip', title: 'PPT', icon: <PptIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quiz', title: 'QUIZS', icon: <QuizIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quizCount', title: 'QUIZ ATTEMPTS', icon: <QuizCountIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Attempts' }

]

//https://b2c-admin-long-srigayatri.macademy.in:3000/v1/data/analytics/getuseranalytics

// {
//     "boardId": "3bcb8873-7949-454e-911a-90008826c117",
//     "classId": "804d9416-e248-4ae7-a8dd-4c01859fe449",
//     "groupId": "45162249-a1f2-4689-9df6-ca633875bab7",
//     "branchId": null,
//     "courseIds": null,
//     "sectionIds": null,
//     "userId": "33c9ff62-9799-4092-ae6a-ea3dcbf20cde",
//     "dateRange": [
//         "2021-09-01",
//         "2021-09-02",
//         "2021-09-03",
//         "2021-09-04",
//         "2021-09-05",
//         "2021-09-06",
//         "2021-09-07",
//         "2021-09-08",
//         "2021-09-09",
//         "2021-09-10",
//         "2021-09-11",
//         "2021-09-12",
//         "2021-09-13",
//         "2021-09-14",
//         "2021-09-15"
//     ],
//     "timeRange": {
//         "from": 0,
//         "to": 24
//     },
//     "subjectId": null,
//     "contentType": null
// }



export default class singleUserAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: '',
            columns: [
                { columnName: 'Class Id', isVisible: false, id: 'classId' }],



        }

    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }
    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }



    questionsAvailableR = () => {
        return <>
            <p className='avail-q'>Total Reports:  <span>{this.props.finalRecords && this.props.finalRecords.length}</span> </p>
        </>
    }

    render() {
        const { finalRecords, userTotalAnalytics, totalCount, userTotalAnalyticsPerc } = this.props.singleUserRecord;


        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 >{this.props.userName} Status</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6 list-none">
                        <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalFilter: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                    <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.BOARDS_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}
                        <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div key={column.id} className='p-2' onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (<>

            <div className='grid sp-no-pm  highlights-tabs flex flex-row'>
                {totalAnalyticsTypes.map((a) => {
                    return <div key={a.subTitle} className=" sm:col-6 md:col-2 ">
                        <div className='paper-s1 highlights-tab lg:w-15rem md:w-8rem md:h-10rem'>
                            <p className='h-title'><span className='content-type'>{a.icon}</span> {a.title}</p>
                            <p ><span className='h-time-l'>{a.subTitle}:</span> <span className='h-time'>{userTotalAnalytics[a.field]}</span></p>
                        </div>
                    </div>
                })}
            </div>
            <div className="card datatable-crud-demo ma-mt20">
                <DataTable ref={(el) => this.dt = el}
                    //  lazy
                    value={finalRecords}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"

                    paginatorLeft={() => {
                        return <>
                            <p className='avail-q'>Total Reports:  <span>{finalRecords && finalRecords.length}</span> </p>
                        </>
                    }}
                    showGridlines
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    responsiveLayout='scroll'
                    header={header}>
                    <Column field="date" header="Date" />
                    <Column field="subjectName" header="Subject" />
                    <Column field="chapterName" header="Chapter" />
                    <Column field="topicName" header="Topic" />
                    <Column field="contentName" header="Content" />
                    <Column field="startTime" header="Start Time" />
                    <Column field="endTime" header="End Time" />
                    <Column field="timeSpent" header="Time Spent" />
                </DataTable>
            </div>
            <Toolbar className="total-stats border-none" right={() => {
                return (
                    <div>
                        <p className='total-time'><span>Total Time: </span>{totalCount}</p>
                        <p className='total-time'><span>Total Stats: </span>{finalRecords.length}</p>
                    </div>
                )
            }}></Toolbar>
        </>

        )
    }
}
