import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import './styles.scss';
import { MultiSelect } from "primereact/multiselect";
const totalSections = [
    { value: 1, label: 'One(1)' },
    { value: 2, label: 'Two(2)' },
    { value: 3, label: 'Three(3)' },
    { value: 4, label: 'Four(4)' }
]

const QuestionTypes = [
    { value: 1, label: 'SCCT' },
    { value: 2, label: 'MCCT' },
    { value: 3, label: 'Integer' },
    { value: 4, label: 'Numerical' },
    { value: 5, label: 'Fill in the Balnk' },
    { value: 6, label: 'Passage SCCT' },
    { value: 7, label: 'Passage MCCT' },
    { value: 8, label: 'Matrix Matching' },
    { value: 9, label: 'True/False' },
    { value: 10, label: 'Audio Based' },
    { value: 11, label: 'Video Based' },
    { value: 12, label: 'Subjective - VSAQ' },
    { value: 13, label: 'Subjective - SAQ' },
    { value: 14, label: 'Subjective - LAQ' },
]
export class AddExam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tab: '1',
            totalSections: totalSections,
            selectedSection: null,
            QuestionTypes: QuestionTypes,
            selectedQuestionType: []
        }
    }
    handleSelect = (item) => {
        this.setState({ selectedQuestionType: [...item] });
    }
    render() {
        return (
            <>
                <div className="px-6 py-3 grid">
                    <div className="grid" >
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Name of the Template<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                        //  value={term.academicTitle} 
                                        //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                        placeholder="Template Name"
                                    />
                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                        </div>

                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800"> Template Type<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                        //  value={term.academicTitle} 
                                        //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                        placeholder="Template Type"
                                    />
                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                        </div>
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Total Questions<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                        //  value={term.academicTitle} 
                                        //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                        placeholder="Total Questions"
                                    />
                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                        </div>
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Marked Questions<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                        //  value={term.academicTitle} 
                                        //  onChange={(e) => { onTextChange(e.target.value, 'academicTitle', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                        placeholder="Marked Questions"
                                    />
                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['TeacherTitle'].isValid && <p className="p-error text-lg ">{formValidations.fields['TeacherTitle'].errorMsg}</p>} */}
                        </div>
                        <div className="col-6 mt-2">
                            <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Template Description<span className="ma-required">*</span></label>
                            <div className="p-inputgroup">
                                <InputTextarea className='h-7rem border-300 shadow-2 w-full border-round-md pt-2 border-none text-lg pl-3' id="description"
                                    //  value={term.description} 
                                    //  onChange={(e) => { onTextChange(e.target.value, 'description', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                    placeholder="Description" rows={3} />
                                {/* <i className="pi pi-info-circle mt-4 font-bold text-lg ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['description'].isValid && <p className="p-error text-lg">{formValidations.fields['description'].errorMsg}</p>} */}
                        </div>
                        <div className="col-6 mt-2">
                            <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Exam Instructions<span className="ma-required">*</span></label>
                            <div className="p-inputgroup">
                                <InputTextarea className='h-7rem border-300 shadow-2 w-full border-round-md pt-2 border-none text-lg pl-3' id="description"
                                    //  value={term.description} 
                                    //  onChange={(e) => { onTextChange(e.target.value, 'description', this, createTermFields, term, formValidations, 'term', 'formValidations') }} 
                                    placeholder="Exam Instructions" rows={3} />
                                {/* <i className="pi pi-info-circle mt-4 font-bold text-lg ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['description'].isValid && <p className="p-error text-lg">{formValidations.fields['description'].errorMsg}</p>} */}
                        </div>
                        <div className="col-3 mt-2">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Total Sections<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <Dropdown id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'
                                        options={this.state.totalSections}
                                        value={this.state.selectedSection}
                                        onChange={(e) => { this.setState({ selectedSection: e.target.value }) }}
                                        placeholder="Total Sections"
                                    />
                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['description'].isValid && <p className="p-error text-lg">{formValidations.fields['description'].errorMsg}</p>} */}
                        </div>
                    </div>
                </div>

                {this.state.selectedSection &&
                    (
                        <Accordion className="section-accordion pl-3" activeIndex={0}>
                            {Array.from({ length: this.state.selectedSection }).map((el, index) => {
                                return (
                                    <AccordionTab key={index} header={<span>Section {index + 1}</span>}>
                                        <div className="grid" >
                                            <div className=" col-3">
                                                <label htmlFor="title" className="block font-serif font-bold text-800">Section Name<span className="ma-required">*</span></label>
                                                <div className='flex'>
                                                    <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                                        <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                        <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'

                                                            placeholder="Section Name"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className=" col-3">
                                                <label htmlFor="title" className="block font-serif  font-bold text-800">Total Questions<span className="ma-required">*</span></label>
                                                <div className='flex'>
                                                    <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                                        <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                        <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'

                                                            placeholder="Total Questions"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className=" col-3">
                                                <label htmlFor="title" className="block font-serif  font-bold text-800">Marked Questions<span className="ma-required">*</span></label>
                                                <div className='flex'>
                                                    <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                                        <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                        <InputText id="title" className='h-3rem  border-round-md  border-none text-lg pl-3 w-10'

                                                            placeholder="Marked Questions"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-3">
                                                <label htmlFor="title" className="block font-serif  font-bold text-800">Question Type (This Section)<span className="ma-required">*</span></label>
                                                <div className='flex'>
                                                    <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                                        <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                                        <MultiSelect
                                                            className='w-11'
                                                            value={this.state.selectedQuestionType}
                                                            onChange={(e) => this.handleSelect(e.target.value)}
                                                            options={QuestionTypes}
                                                            optionLabel={"label"}
                                                            optionValue={"value"}
                                                            placeholder={<span className='text-lg ml-4'>Select Question Types</span>}


                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-12">
                                                <label htmlFor="title" className="block font-serif  font-bold text-800">Section Instructions</label>
                                                <div className='flex'>
                                                    <div className="p-inputgroup border-300 shadow-2 border-round-md mb-2">
                                                        <InputTextarea className='h-7rem border-300 shadow-2 w-full border-round-md pt-2 border-none text-lg pl-3' id="description" placeholder="Section Instructions" rows={3} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid mt-3">
                                            {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(1) &&
                                                <div className="col-6 flex my-0 py-1 justify-content-between">
                                                    <div className='flex'>
                                                        <div className="p-inputgroup border-300 shadow-2 border-round-md">
                                                            <span className="question-type-label">SCCT</span>
                                                            <InputText id="title" className='question-type-input  border-round-md  border-none text-lg pl-3 w-10'
                                                                placeholder="Mandatory Questions"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <InputText id="title" className='question-type-input border-round-md  text-lg pl-3 w-12'
                                                            placeholder="Optional Questions"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(2) &&
                                                <div className="col-6 flex my-0 py-1 justify-content-between">
                                                    <div className='flex'>
                                                        <div className="p-inputgroup border-300 shadow-2 border-round-md">
                                                            <span className="question-type-label">MCCT</span>
                                                            <InputText id="title" className='question-type-input  border-round-md  border-none text-lg pl-3 w-10'
                                                                placeholder="Mandatory Questions"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <InputText id="title" className='question-type-input  border-round-md  text-lg pl-3 w-11'
                                                            placeholder="Optional Questions"
                                                        />
                                                    </div>
                                                </div>
                                            }{this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(3) &&
                                                <div className="col-6 flex my-0 py-1 justify-content-between">
                                                    <div className='flex'>
                                                        <div className="p-inputgroup border-300 shadow-2 border-round-md">
                                                            <span className="question-type-label">Integer</span>
                                                            <InputText id="title" className='question-type-input border-round-md  border-none text-lg pl-3 w-10'
                                                                placeholder="Mandatory Questions"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <InputText id="title" className='question-type-input  border-round-md  text-lg pl-3 w-12'
                                                            placeholder="Optional Questions"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.state?.selectedQuestionType && this.state?.selectedQuestionType?.includes(4) &&
                                                <div className="col-6 flex my-0 py-1 justify-content-between">
                                                    <div className='flex'>
                                                        <div className="p-inputgroup border-300 shadow-2 border-round-md">
                                                            <span className="question-type-label">Numerical</span>
                                                            <InputText id="title" className='question-type-input  border-round-md  border-none text-lg pl-3 w-10'
                                                                placeholder="Mandatory Questions"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <InputText id="title" className='question-type-input  border-round-md  text-lg pl-3 w-11'
                                                            placeholder="Optional Questions"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </AccordionTab>
                                );
                            })}
                        </Accordion>
                    )
                }
            </>
        )
    }
}

export default AddExam