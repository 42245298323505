import { DataTable } from 'primereact/datatable'
import React, { Component } from 'react'
import SvgViewer from './../../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { warningDailogInit } from '../../../../utile';
import { InputText } from 'primereact/inputtext';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';


export default class QuestionWiseReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: []
        };
    }

    getData = (examsResult) => {
        const data = examsResult && examsResult.length > 0 && examsResult.map((result) => {
            const answers = {};
            result.userAnswered && result.userAnswered.forEach((ans, index) => {
                answers[index + 1] = ans;
            });
            return {
                ...result,
                ...answers
            };
        });
        return data;
    };


    getColumns = (examsResult) => {
        let columns = [];
        examsResult && examsResult.length > 0 && examsResult[0].userAnswered.forEach((ans, index) => {
            columns.push({
                label: (index + 1).toString(),
                field: (index + 1).toString(),
            })
        });
        return columns;
    };


    componentDidMount() {
        if (this.props.examsResult && this.props.examsResult.length) {
            let c = this.getColumns(this.props.examsResult);

            this.setState({
                data: this.getData(this.props.examsResult),
                columns: c
            })
        }

    }


    componentDidUpdate(prevProps) {

        if (this.props.examsResult != prevProps.examsResult) {
            if (this.props.examsResult && this.props.examsResult.length) {
                let c = this.getColumns(this.props.examsResult);

                this.setState({
                    data: this.getData(this.props.examsResult),
                    columns: c
                })
            }
        }

    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    render() {
        // console.log(this.props, 'sddddd')

        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 >Question Wise Report</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalFilter: '' }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.BOARDS_VIEWCOLUMNS} >
                            <i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}
                        <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                {/* <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} /> */}
            </div>
        );
        return (
            <div className="card datatable-crud-demo q-wise-report ma-mt20 mt-3">
                <DataTable
                    ref={(el) => this.dt = el}
                    value={this.state.data}
                    className="ma-table-d"
                    tableClassName='ma-table-tbl'
                    scrollable showGridlines


                    rows={10}
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    header={header}>
                    <Column headerClassName='col-header' sortable field="userName" header="User Name" />
                    <Column headerClassName='col-header' sortable field="phoneNumber" header="Phone Number" />
                    {
                        this.state.columns.map(c => <Column sortable field={c.field} headerClassName='col-answer' header={c.label} />)
                    }
                </DataTable>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        )
    }
}
