import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import { getFormFields, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../utile/formHelper'
import axios from 'axios';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
// import { ClassetEditIcon } from '../svgIcons';
import { ClassetEditIcon, ClipIcon, MailIcon, MinusSquareIcon, PlusSquareIcon } from '../svgIcons';
import { AddUserIcon } from '../svgIcons';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import _, { cloneDeep } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { baseUrlAdmin } from '../../store/apiConstants';
import { Toast } from 'primereact/toast';
import Service from '../../services';
import LoadingComponent from '../loadingComponent';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';


const createAdmissionFeeFields = require('./admissionFeeCreate.json');

const admissionSteps = [
    { value: 'Enquiry', label: 'Enquiry' },
    { value: 'Application', label: 'Application' },
    { value: 'Exam', label: 'Exam' },
    { value: 'Registration', label: 'Registration' },
    { value: 'Enrollment', label: 'Enrollment' }
];




const home = { icon: 'pi pi-home' }

const tabs = [
    { label: 'Vehicle Management' },
    { label: 'Route Management' },
    { label: 'Assign Management' },
    { label: 'Transport Fee ' },
];


class AdmissionRegistrationFee extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(createAdmissionFeeFields);
        this.state = {
            currentTab: 0,
            admissionFee: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isDialogOpenForCreateRegistration: false,
            admissionFeeData: [],
            admissionStatusObj: {},
            isStatusChangeDialog: false,
            formStatus: null,
            isLoading: false,
            boardsListError: "",
            boardsList: [
                { board: "", grade: [], classes: [] },
            ],
            addBoardCardCount: 2

        };
        this.service = new Service();
    }


    componentDidMount() {
        this.getAdmissionFeesData()
    }



    getAdmissionFeesData = async () => {
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/application-fee/`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ admissionFeeData: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    };


    confirm1Change = (e, fee) => {
        this.setState({ isStatusChangeDialog: true, admissionStatusObj: { ...fee, isActiveStatus: e.target.value } })
    };





    handleChangeTheStatusAdmissionCategory = async () => {
        const { _id, isActiveStatus } = this.state.admissionStatusObj;
        let url = `${baseUrlAdmin}/application-fee/`;
        axios({
            method: 'patch',
            url: url,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
            },
            data: {
                "_id": _id,
                "isActive": isActiveStatus
            },
        }).then((res) => {

            if (res?.data?.status && res.status == 200) {
                this.setState({ isStatusChangeDialog: false, admissionStatusObj: {} });
                this.getAdmissionFeesData()
                this.toast.show({ severity: 'success', summary: 'success', detail: res?.data?.message, life: 3000 });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        });

    };




    onChangeBoard = (boardId, indexPosition) => {
        // let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName
        const _classes = [];
        const UpdatedBoardList = this.state.boardsList.map((each, index) => {
            if (indexPosition == index) {

                const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
                if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                    selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                }
                return { ...each, board: boardId, classes: _classes }
            }
            else {
                return each
            }
        })

        this.setState({
            boardsList: UpdatedBoardList
        });

    };







    onChangeClass = (classId, indexPosition) => {
        const UpdatedClassList = this.state.boardsList.map((each, index) => {
            if (indexPosition == index) {
                return { ...each, grade: classId }
            }
            else {
                return each
            }
        })


        this.setState({
            boardsList: UpdatedClassList
        });


    };


    handleRemoveBoard = ()=>{
        if (this.state?.boardsList?.length > 1) {
            this.setState((prevState) => ({ boardsList: prevState.boardsList.slice(0, -1) }));
        }
    }



    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Transport Management</h4>
            </React.Fragment>
        )
    }

    // leftToolbarTemplate() {
    //     return (
    //         <React.Fragment>
    //             <BreadCrumb model={items} home={home} />
    //         </React.Fragment>
    //     )
    // }
    onCreateAdmissionFee = async () => {
        const { boardsList, editAdmissionFeeType, admissionFee } = this.state

        const updatedList = boardsList.map(each => {
            return { board: each.board, grade: each.grade }
        })
        let body = cloneDeep(admissionFee)
        body.assign = updatedList



        const formStatus = isFormValid(createAdmissionFeeFields, this.formFields.formValidations, body);
        this.setState({ formStatus: formStatus })
        let isCompleteFormValid = true



        if (boardsList[0].board == "" || boardsList[0].grade == []) {

            if (boardsList[0].board == "" || boardsList[0].grade == []) {
                this.setState({ boardsListError: "Select atleast one Board && Grades" });
                isCompleteFormValid = false;
            }
        }

        if (!formStatus.formValidations.isFormValid) {
            if (!body.academicDate?.length) {
                this.setState({ formValidations: formStatus.formValidations, dateError: true });
                isCompleteFormValid = false;
            }
            else {
                this.setState({ formValidations: formStatus.formValidations, });
                isCompleteFormValid = false;
            }
        }

        if (isCompleteFormValid) {
            if (editAdmissionFeeType?._id) {
                const payload = {
                    "_id": editAdmissionFeeType._id,
                    "orgId": editAdmissionFeeType.orgId,
                    'feeName': body.feeName,
                    "feeCode": body.feeCode,
                    "label": body.feeRecieptLabel,
                    "admissionStep": body.admissionStep,
                    "amount": body.feeAmount,
                    "assign": body.assign,
                }

                let url = `${baseUrlAdmin}/application-fee/`;

                try {
                    const res = await this.service.put(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({ isDialogOpenForCreateRegistration: false,editAdmissionFeeType:{}, admissionFee: {}, boardsList: [{ board: "", grade: [], classes: [] }], editAdmissionFeeType: {} })
                        this.getAdmissionFeesData()
                        this.toast.show({ severity: 'success', summary: 'Admission Category Updated successfully', detail: res?.res?.message, life: 3000 });
                    } else {

                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                    }
                } catch (e) {
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
                }
            }
            else {
                const payload = {
                    'feeName': body.feeName,
                    "feeCode": body.feeCode,
                    "label": body.feeRecieptLabel,
                    "admissionStep": body.admissionStep,
                    "amount": body.feeAmount,
                    "assign": body.assign,

                }
                let url = `${baseUrlAdmin}/application-fee/`;
                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({ isDialogOpenForCreateRegistration: false,editAdmissionFeeType:{}, admissionFee: {}, boardsList: [{ board: "", grade: [], classes: [] }], })
                        this.getAdmissionFeesData()
                        this.toast.show({ severity: 'success', summary: 'Admission Category Created successfully', detail: res?.res?.message, life: 3000 });
                    } else {
                        const error = 'Some error occurred';
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                    }
                } catch (e) {
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                }
            }
        }


    }





    handleEditRequest = (feeType, rowIndex) => {
        const updatedList = feeType.assign.map((each, index) => {
            let filterGrades=[];
            const _classes = [];
            const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === each.board);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes?.length > 0) {
                selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
            }


            _classes?.filter(eachClass=>{
                if(each?.grade?.includes(eachClass.value)){
                    return filterGrades.push(eachClass.value)
                }
            })

            return { board: each.board, grade: filterGrades, classes: _classes }
        })


        const filteredFields = {
            feeName: feeType.feeName || "",
            feeCode: feeType.feeCode || "",
            feeRecieptLabel: feeType.label || "",
            admissionStep: feeType.admissionStep || "",
            feeAmount: feeType.amount || ""


        };
        this.setState({
            editAdmissionFeeType: feeType,
            admissionFee: filteredFields,
            boardsList: updatedList,
            isDialogOpenForCreateRegistration: true
        });
    }


    componentDidUpdate(prevProps, prevState) {
        
    }

    render() {
        const { admissionFee, formValidations, admissionFeeData, formStatus, boardsList, boardsListError } = this.state

        return (
            <div>
                <div className="admin-management">
                    <div style={{ marginBottom: "110px" }}>

                        <div className=" grid">
                            <div className="mt-3 md:col-12 lg:col-12 xl:col-12">
                                <div className="grid mb-4 mt-4 -mr-6">
                                    <div className='col-11 flex justify-content-end align-items-center'>
                                        <Button
                                            label="Add Registration/Application Fee"
                                            icon={<i className='pi pi-plus-circle text-2xl pl-4 pr-3 opacity-70'></i>}
                                            className='addRegistrationBtn w-auto pr-3'
                                            onClick={() => { this.setState({ isDialogOpenForCreateRegistration: true }) }}
                                        // onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, editVehicleData: null }) }} 
                                        />
                                    </div>

                                </div>
                                {(admissionFeeData?.length && !this.state.isLoading) ?
                                    <div className="mt-4 grid doubts-tabs flex lg:justify-content-start xl:justify-content-start sm:justify-content-center xs:justify-content-center align-items-Satrt">
                                        {admissionFeeData.map((fee, index) => (
                                            // <Authorizer key={term.id} permId={PERMISSIONS.SHOW_ROLES_LIST}>
                                            <div
                                                className="mx-6 card admissionCategoryCardContainer cursor-pointer col-3 doubts-tab-w"

                                            >
                                                <div className="flex flex-column justify-content-center align-items-center">
                                                    <div className="mt-1 w-full flex justify-content-end align-items-center">
                                                        <InputSwitch
                                                            className='mt-1'
                                                            onChange={(e) => this.confirm1Change(e, fee)}
                                                            checked={fee.isActive} />
                                                    </div>
                                                    <div className="flex flex-column justify-content-center align-items-center mt-5">
                                                        <h3 className='categoryCardHeadText text-overflow-ellipsis'>{fee.feeName}</h3>
                                                        <p className="subCardParaText w-17rem text-2xl pb-2">Rs {fee.amount}</p>
                                                    </div>

                                                    <div className=' mr-5 w-full flex justify-content-end align-items-center' onClick={() => { this.handleEditRequest(fee, index) }}>
                                                        <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                    </div>
                                                </div>
                                            </div>
                                            // </Authorizer>
                                        ))}
                                    </div>
                                    :
                                    !this.state.isLoading &&
                                    <div className='mt-8 card h-full w-full  flex flex-column justify-content-center align-items-center'>
                                        <h3>No Admission Fee Types Found</h3>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>


                </div>
                <Dialog
                    visible={this.state.isDialogOpenForCreateRegistration}
                    draggable={false}
                    closeOnEscape={false}
                    // position={"right"}
                    className='addAdmissionDilogBox mr-0'
                    width={"500px"}
                    height={"800px"}
                    position={"right"}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h3 className="text-center text-black-alpha-90 font-bold">{`${this.state.editAdmissionFeeType?._id ? "Update" : "Add"}`} Registration Fee</h3>

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-2 mt-2'>
                            <Button label='Accept' icon={<i className='pi pi-plus-circle text-lg px-2 '></i>} className='addAdmissionDialogAceeptBtn border-radius-xs mr-4  px-8' onClick={() => this.onCreateAdmissionFee()} />
                            <Button label="Cancel" className='border-none addAdmissionDialogCancelBtn border-radius-xs px-8' onClick={() => this.setState({ admissionFee: {},editAdmissionFeeType:{}, isDialogOpenForCreateRegistration: false, boardsList: [{ board: "", grade: [], classes: [] }], })} />

                        </div>
                    )}
                    onHide={() => this.setState({ admissionFee: {},editAdmissionFeeType:{}, isDialogOpenForCreateRegistration: false, boardsList: [{ board: "", grade: [], classes: [] }], })}

                >

                    <div className='flex flex-column justify-content-center ml-5'>
                        <div className='grid col-12 flex justify-content-center align-content-center'>
                            <div className="field col-12 flex justify-content-center align-content-center -mt-3">
                                <div>
                                    <label htmlFor="Fee Name" className="block groupFormLabelText">Fee Name<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            id={"Fee Name"}
                                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                                            className='h-4rem border-round-md groupFormInputText  border-none pl-5'
                                            formHelperError={formValidations && !formValidations.fields['feeName'].isValid && formStatus}
                                            value={admissionFee.feeName}
                                            onChange={(e) => { onTextChange(e.target.value, 'feeName', this, createAdmissionFeeFields, admissionFee, formValidations, 'admissionFee', 'formValidations') }}
                                            placeholder="Enter Fee Name" />
                                    </div>
                                    {formValidations && !formValidations.fields['feeName'].isValid && <p className="p-error text-lg ">{formValidations.fields['feeName'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="field col-12 flex justify-content-center align-content-center -mt-3">
                                <div>
                                    <label htmlFor="Fee Code" className="block groupFormLabelText">Fee Code<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                                            className='h-4rem border-round-md groupFormInputText  border-none pl-5'
                                            id={"Fee Code"}
                                            value={admissionFee.feeCode}
                                            formHelperError={formValidations && !formValidations.fields['feeCode'].isValid && formStatus}
                                            onChange={(e) => { onTextChange(e.target.value, 'feeCode', this, createAdmissionFeeFields, admissionFee, formValidations, 'admissionFee', 'formValidations') }}

                                            placeholder="Enter Fee Code" />
                                    </div>
                                    {formValidations && !formValidations.fields['feeCode'].isValid && <p className="p-error text-lg ">{formValidations.fields['feeCode'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="field col-12 flex justify-content-center align-content-center -mt-3">
                                <div>
                                    <label htmlFor="Fee Receipt" className="block groupFormLabelText">Fee Receipt Label<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                                            className='h-4rem border-round-md groupFormInputText  border-none pl-5'
                                            id={"Fee Receipt"}
                                            formHelperError={formValidations && !formValidations.fields['feeRecieptLabel'].isValid && formStatus}
                                            value={admissionFee.feeRecieptLabel}
                                            onChange={(e) => { onTextChange(e.target.value, 'feeRecieptLabel', this, createAdmissionFeeFields, admissionFee, formValidations, 'admissionFee', 'formValidations') }}

                                            placeholder="Enter Label On the Receipt" />
                                    </div>
                                    {formValidations && !formValidations.fields['feeRecieptLabel'].isValid && <p className="p-error text-lg ">{formValidations.fields['feeRecieptLabel'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className="field col-12 flex justify-content-center align-content-center -mt-3">
                                <div>
                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        options={admissionSteps}
                                        label={"Admission Step"}
                                        required={true}
                                        optionLabel="label"
                                        optionValue="value"
                                        formHelperError={formValidations && !formValidations.fields['admissionStep'].isValid && formStatus}
                                        className='addAdmissionDilogBoxNameField no-border border-round-md shadow-2 text-xl pt-1 pl-4'
                                        value={admissionFee.admissionStep}
                                        onChange={(e) => { onTextChange(e.target.value, 'admissionStep', this, createAdmissionFeeFields, admissionFee, formValidations, 'admissionFee', 'formValidations') }}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className='text-xl ml-2 '>Select Admission Step</span>
                                            </div>
                                        )}
                                    />
                                    {formValidations && !formValidations.fields['admissionStep'].isValid && <p className="p-error text-lg ">{formValidations.fields['admissionStep'].errorMsg}</p>}
                                </div>

                            </div>
                            <div className="field col-12 flex justify-content-center align-content-center -mt-3">
                                <div>
                                    <label htmlFor="Fee Amount" className="block groupFormLabelText">Amount<span className="ma-required">*</span></label>
                                    <div className='flex'>
                                        <ClassetInputText
                                            icon={<ClipIcon width={24} height={24} color={'#000000'} />}
                                            className='h-4rem border-round-md groupFormInputText  border-none pl-5'
                                            id={"Fee Amount"}
                                            formHelperError={formValidations && !formValidations.fields['feeAmount'].isValid && formStatus}
                                            value={admissionFee.feeAmount}
                                            onChange={(e) => { onNumberChange(e.target.value, 'feeAmount', this, createAdmissionFeeFields, admissionFee, formValidations, 'admissionFee', 'formValidations') }}

                                            placeholder="Enter Fee Amount" />
                                    </div>
                                    {formValidations && !formValidations.fields['feeAmount'].isValid && <p className="p-error text-lg ">{formValidations.fields['feeAmount'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>
                        <div className=''>

                            {boardsList?.map((boardType, index) => (

                                <div className='addCardSize grid col-12 card -mt-3 flex justify-content-center align-content-center'>
                                    <div className="col-12 px-6 flex justify-content-center align-content-center">

                                        <ClassetDropdown
                                            className='dialogCardInField border-round-md shadow-2 text-xl pl-2 pt-2'
                                            icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                            options={this.props.boards}
                                            optionLabel='boardName'
                                            optionValue='boardId'
                                            value={boardType.board}
                                            onChange={(e) => this.onChangeBoard(e.value, index)}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className='text-xl ml-4 body-medium'>Select Board</span>
                                                </div>
                                            )} />

                                    </div>
                                    <div className='col-12 px-6 flex justify-content-center align-content-center'>
                                        <ClassetMultiSelect
                                            value={boardType.grade}
                                            onChange={(e) => this.onChangeClass(e.value, index)}
                                            icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                            options={boardType.classes}
                                            optionLabel="label"
                                            className='dialogCardInFiel text-xl shadow-2 pl-2'
                                            maxSelectedLabels={3}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center mt-1">
                                                    <span className='text-xl ml-4 body-medium'>Select Grades</span>
                                                </div>
                                            )} />
                                    </div>

                                </div>
                            ))}
                            {(boardsList[0].board == "" || boardsList[0].grade?.length == 0) && <p className="p-error text-lg my-2">{boardsListError}</p>}
                            <div className='flex justify-content-between align-items-center'>
                                <Button
                                    icon={<PlusSquareIcon width={24} height={24} color={'#000000'} />}
                                    label='Add Board'
                                    className='border-none addBoardBtn mr-4 w-auto px-4 pl-2'
                                    onClick={() => { this.setState((prevState) => ({ boardsList: [...prevState.boardsList, { board: "", grade: [], classes: [] }] })) }} />
                                <Button
                                    icon={<MinusSquareIcon width={24} height={24} color={'#000000'} />}
                                    label='Remove Board'
                                    className='border-none addBoardBtn mr-4 w-auto px-4 pl-2'
                                    onClick={() => {this.handleRemoveBoard();}} />
                                    
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    header={<div className='flex justify-content-center align-items-center'>
                        <i className="pi pi-exclamation-triangle text-center text-7xl text-red-400"></i>
                    </div>}
                    visible={this.state.isStatusChangeDialog}
                    style={{ width: '400px', height: "350px" }}
                    onHide={() => { this.setState({ isStatusChangeDialog: false }) }}>
                    <div className="flex flex-column align-items-center surface-overlay border-round -mt-2">
                        <h4 className="font-bold text-2xl block mb-2 mt-4" >
                            Confirmation ?
                        </h4>
                        <p className="mb-0 text-base">
                            {`Are you sure you want to Change The status `}
                            <span className='text-color text-base font-semibold'> {this.state.admissionStatusObj.isActiveStatus ? "InActive to Active" : "Active to InActive"} </span>For the
                            <span className='p-error text-xl mt-1 font-semibold'>{` ${this.state.admissionStatusObj.feeName}`}</span>?
                        </p>
                        <div className="flex align-items-center gap-2 mt-4">
                            <Button
                                label="Change"
                                onClick={() => { this.handleChangeTheStatusAdmissionCategory() }}
                                className="confirmDialogAcceptBtn"
                            ></Button>
                            <Button
                                label="Cancel"
                                outlined
                                onClick={() => { this.setState({ isStatusChangeDialog: false, admissionStatusObj: {} }) }}
                                className="confirmDialogCancelBtn"
                            ></Button>
                        </div>
                    </div>
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {(this.state.isLoading) && <LoadingComponent />}

            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    boardsDropDownOptions: state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions || [],
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data || [],
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(AdmissionRegistrationFee)));