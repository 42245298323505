import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authentication from './../session';
import moment from 'moment';
import { Toast } from 'primereact/toast';
// import Navbar from './../navbar';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import Service from '../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'react-formio/lib/types';
import Resources from './resources';
import LoadingComponent from '../loadingComponent';
import './style.scss'
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { handleDownload } from '../../utile';
import axios from 'axios';
import CurriculumPlayer from './curriculumPlayer';

const items = [
    { label: 'Academics' },
    { label: 'Classroom', className: 'ma-active-item' },
];

const home = { icon: 'pi pi-home' }


class ClassRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            periods: [],
            totalRecords: 0,
            columns: [],
            period: {},
            isShowPeriodDetails: false,
            selectedTab: 1
        }

        this.service = new Service();
    }




    changeTab = (tab) => {
        this.setState({ selectedTab: tab })

    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }
    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }



    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Class Room</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }


    onSubmitClick = () => {
        const { date } = this.state;
        if (!date) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select date', life: 3000 });
        }

        if (date) {
            this.setState({
                isLoading: true
            })

            const url = `${baseUrlForLongRunningTask}/timetable/getteacherperiods`;
          
            this.service.post(url, { date }, true).then((data) => {

                if (data && data.res && data.res.data) {

                    this.setState({
                        periods: data.res.data,
                        totalRecords: data.res.data.length,
                        isLoading: false,


                    });
                } else {
                    this.setState({
                        isLoading: false
                    });

                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    }

    render() {
        const { date, period, selectedTab } = this.state;
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>Periods</h4>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={(e) => {
                        this.setState({
                            globalSearch: e.target.value
                        })
                    }} />}

                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6 list-none">
                        <Authorizer permId={PERMISSIONS.CLASSROOM_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.CLASSROOM_GENERAL_VIEW_COLUMNS} >
                            <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} >
                            
                        </Authorizer> */}
                        <Authorizer permId={PERMISSIONS.CLASSROOM_GENERAL_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (<>
            <div className="p-grid ma-toolbar">
                <div className="p-col-12 p-md-1 ma-no-p">
                </div>
                <div className="-mt-4">
                    <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                </div>
            </div>
            <div className=" question-bank  mt-3">
                <div className='qb-tabs'>
                    <span onClick={() => { this.changeTab(1) }} className={selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>Time Table</span>
                    <span onClick={() => { this.changeTab(2) }} className={selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>Curriculum</span>
                </div>

                {
                    selectedTab == 1 && <>
                        <div>



                            <p className='search-users-txt'>Select Date</p>
                            <Calendar showIcon={true}
                                placeholder="Select Date"
                                readOnlyInput
                                className='mt-2 '
                                value={date}

                                onChange={(e) => this.setState({
                                    date: new Date(e.value),
                                    totalRecords: 0,
                                    periods: [],
                                    period: {},
                                })}
                            ></Calendar>
                            <span className='ma-required-mark '>*</span>
                        </div>
                        <Button label={'Fetch'} className='mt-3 w-1' onClick={() => this.onSubmitClick()} />
                        <div className="card datatable-crud-demo mt-4">
                            <DataTable ref={(el) => this.dt = el}
                                scrollable scrollHeight="500px"
                                responsiveLayout="scroll"
                                dataKey="_id"
                                value={this.state.periods}
                                paginator
                                showGridlines
                                rows={10}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorLeft={() => {
                                    return <>
                                        <p className='avail-q'>Available Periods:  <span>{this.state.totalRecords}</span> </p>
                                    </>
                                }}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                header={header}>
                                <Column header="Actions" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        {rowIndex + 1}
                                    </>
                                    );
                                }} ></Column>
                                <Column field="subjectName" header="Subject Name" />
                                <Column field="chapterName" header="Chapter Name" />
                                <Column field="startTime" header="Start Time" body={(rowData) => {
                                    return (<> {moment(rowData.startTime).format('LT')}</>)
                                }} />
                                <Column field="startTime" header="End Time" body={(rowData) => {
                                    return (<>  {moment(rowData.endTime).format('LT')}</>)
                                }} />
                                <Column field="startTime" header="Show Details" body={(rowData) => {
                                    return (<>


                                        <Authorizer permId={PERMISSIONS.CLASSROOM_SHOW_DETAILS} >
                                            <Button label='Show Details' onClick={() => this.setState({ isShowPeriodDetails: true, period: rowData })} />
                                        </Authorizer>
                                    </>)
                                }} />
                            </DataTable>
                            <Toast ref={(el) => this.toast = el} position="bottom-right" />
                        </div >
                        {
                            this.state.isShowPeriodDetails && period && <div className='ma-card ma-mt20'>
                                <h3 className='ma-poppins'>Period Details</h3>
                                <p className='ma-poppins'><b>Subject Name</b> : {period.subjectName}</p>
                                <p className='ma-poppins'><b>Chapter Name</b> : {period.chapterName}</p>
                                <p className='ma-poppins'><b>Description</b> : {period.description}</p>
                                <p className='ma-poppins'><b>Learning Objective</b> : {period.learningObjective}</p>
                                <p className='ma-poppins'><b>Learning Outcome</b> : {period.learningOutcome}</p>
                                <p className='ma-poppins'><b>Resources: </b></p>
                                <Resources resources={period.resources}
                                    period={period}
                                />

                                <p className='ma-poppins'><b>Additional Resources: </b></p>
                                {
                                    period.addLinks && period.addLinks.map(e => <a className='ma-link-t' href={e?.data?.url} target='_blank'>{e?.data?.url}</a>)
                                }
                            </div>
                        }
                    </>
                }

                {
                    selectedTab == 2 && <>
                        <CurriculumPlayer />
                    </>
                }

            </div>



            {
                this.state.isLoading && <LoadingComponent />
            }

            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </ >
        )
    }
}





export default Authentication(ClassRoom);