import React from 'react'
import Chart from 'react-apexcharts';

function areaChart() {

    const options = {
        chart: {
          type: 'donut',
        },
        labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4'],
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
      };
    
      const series = [65, 59, 80, 81];

    const chartContainerStyle = {
       
      };
    return (
        <>

            <div  style={{width:"280px",height:'300px',marginTop:"30px"}}>
                <Chart options={options} series={series} type='donut'  />
            </div>
        </>
    )
}

export default areaChart