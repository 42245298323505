import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import ContentPlayers from '../contentMaster/contentPlayers';
import _ from 'lodash';

export default class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowLinkVideo: false,
        }
    }

    onAssignVideos = (videos) => {
        this.setState({
            isShowLinkVideo: false
        }, () => this.props.onAssignVideos(videos))
    }

    render() {
        const { resources } = this.props;
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">

                </div>

            </div>
        );
        return (
            <div className="card datatable-crud-demo vidoes-linked">
                <DataTable
                    header={header}
                    value={resources || []}
                    dataKey="contentId"
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    rows={10}
                    responsiveLayout="scroll"
                    selectionMode="checkbox"
                    showGridlines
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                >
                    <Column field="contentName" header="Resource Name" />
                    <Column body={(rowData) => {
                        return (
                            <div className="preview-c" >

                                <span onClick={() => {
                                    this.setState({
                                        previewContent: rowData,
                                        isShowPreview: true
                                    });
                                }} > <i className='pi pi-play ma-mr10' />Play </span>
                            </div>
                        )
                    }} field="status" header="Preview" />
                </DataTable>
               {/*  {this.state.isShowPreview && <ContentPlayers onCloseDialog={() => {
                    this.setState({
                        isShowPreview: false
                    })
                }}
                    contentInfo={this.state.previewContent}
                    saveAnalytics={true}
                    period={_.cloneDeep(this.props.period)}
                

                />} */}
            </div>
        )
    }
}
