import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams } from '../../../utile';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Checkbox } from 'primereact/checkbox';
import SvgViewer from '../../customComponents/svgViewer';
import AssignDiagnosticTestToContent from './assignDignoseTestToChapter';
import LoadingComponent from '../../loadingComponent';
import { AssignIcon, EditIcon } from '../../svgIcons';

export default class DiagnosticTestList extends Component {
    constructor(props) {
        super(props);

        this.state = {

            exams: [],

            columns: [{ columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
            { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
            { columnName: 'Exam Type', isVisible: false, id: 'examType' },
            { columnName: 'Source', isVisible: false, id: 'source' },
            { columnName: 'Status', isVisible: false, id: 'status' },
            { columnName: 'Question Type', isVisible: false, id: 'questionType' }
            ],
            showSearch: false,
            globalFilter: null,
            globalSearch: '',
            // totalRecords: 18,
            lazyParams: BasicLazyParams
        }
        this.service = new Service();
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.onSearch);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    componentDidMount() {
        this.onSearch();

    }


    onSearch = () => {

        const {
            lazyParams, globalSearch
        } = this.state;


        let filters = { examType: 'DiagnosticTest' };


        this.setState({
            isLoading: true,
            questions: [],
            totalRecords: 0
        });


        this.service.post(`${baseUrlAdmin}/examination/filter?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {
            if (data && data.status && data?.res?.data) {
                this.setState({
                    totalRecords: data.res.data.totalRecords,
                    exams: data.res.data.exams,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'No Exams', detail: "No Exams Found", life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);

        });


    }

    assignExam = (examToAssign) => {
        this.setState({
            isShowAssignDialog: true,
            examToAssign
        })
    }

    onAssignSuccess = () => {


        this.setState({
            isShowAssignDialog: false
        })

        this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Successfully assigned dignose test to chapter', life: 6000 });
    }


    render() {
        const { exams } = this.state;
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Digonstic Test List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ml-4  h-2rem w-15rem" onChange={this.onGlobalSearch} />}

                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} > */}
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} > */}
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        {/* </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className='mt-2'
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (<>
            <div className="card datatable-crud-demo ma-mt20 mt-3">
                <DataTable ref={(el) => this.dt = el}
                    scrollable scrollHeight="500px"
                    responsiveLayout="scroll"
                    dataKey="_id" showGridlines
                    value={this.state.exams}
                    filters={this.state.lazyParams.filters}
                    // loading={this.props.isLoading}
                    paginatorLeft={() => {
                        return <>
                            <p className='avail-q'>Total Reports:  <span>{this.state.totalRecords}</span> </p>
                        </>
                    }}
                    paginator
                    lazy
                    onPage={this.onPage}
                    onSort={this.onSort}
                    onFilter={this.onFilter}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    sortField={this.state.lazyParams.sortField}
                    sortOrder={this.state.lazyParams.sortOrder}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}

                    //  selection={this.state.selectedQuestions}
                    //  onSelectionChange={this.onSelectQuestions}

                    //paginatorTemplate={paginatorTemplate}
                    //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    // globalFilter={this.state.globalFilter}
                    header={header}>
                    <Column sortable sortField='examName' headerStyle={{ width: '130px' }} field="examName" header="Exam Name" />
                    <Column sortable sortField='boardName' headerStyle={{ width: '140px' }} field="boardName" header="Board Name" />
                    <Column sortable sortField='className' headerStyle={{ width: '140px' }} field="className" header="Class Name" />
                    <Column sortable sortField='noOfQuestions' headerStyle={{ width: '140px' }} field="noOfQuestions" header="Number of Questions" />
                    <Column sortable sortField='totalTime' headerStyle={{ width: '140px' }} field="totalTime" header="Total Time (Mins)" />
                    <Column sortField='totalMarks' headerStyle={{ width: '140px' }} field="totalMarks" header="Total Marks " />
                    <Column headerStyle={{ width: '140px' }} header="Actions" body={(rowData, { rowIndex }) => {
                        return (<>
                            {/* {console.log('rowData', rowData)} */}

                            <Authorizer permId={PERMISSIONS.EXAM_EDIT_QUIZ} >
                                <span data-pr-tooltip="Edit Exam" data-pr-position="bottom" className={`lk${rowIndex}`} onClick={() => { this.props.onEditExam(rowData.examId, 'DiagnosticTest') }}>
                                    <EditIcon width={13} height={15} />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.lk${rowIndex}`} />
                            </Authorizer>
                            <Authorizer permId={PERMISSIONS.EXAM_ASSIGN_QUIZ} >
                                <span data-pr-tooltip="Assign Digonstic Test" data-pr-position="bottom" className={`mk${rowIndex} ml-3`} onClick={() => { this.assignExam(rowData) }}>
                                    <AssignIcon width={13} height={15} />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.mk${rowIndex}`} />
                            </Authorizer>
                        </>
                        );
                    }} ></Column>
                    {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                </DataTable>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
            {
                this.state.isShowAssignDialog && <AssignDiagnosticTestToContent onAssignSuccess={this.onAssignSuccess} examToAssign={this.state.examToAssign} onCancel={() => {
                    this.setState({
                        isShowAssignDialog: false
                    })
                }} />
            }
            {
                this.state.isLoading && <LoadingComponent />
            }
        </>)
    }
}
