import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import InputTextB from './../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
import { Checkbox } from 'primereact/checkbox';

import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import uuidv4 from 'uuid/v4';
//import './styles.scss'
import { trimObj } from '../../../utile';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';

const formFields = [{
    "Type": "Text",
    "Label": "Fee Type",
    "FieldName": "feeType",
    "Required": true
},
{
    "Type": "Toggle",
    "Label": "Consession Applicable",
    "FieldName": "isConsApplicable",
    "Required": false
},
{
    "Type": "Toggle",
    "Label": "Student Specific",
    "FieldName": "isStudentSpecific",
    "Required": false
}]

class CreateFeeType extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editFeeType);
        this.state = {
            feeType: this.formFields.data,
            formValidations: this.formFields.formValidations
        }
        this.service = new Service();
    }

    footer = () => {
        return (<>
            <Button label="Cancel" className='p-button-outlined' onClick={this.onCloseDialog} />
            <Button label={`${!this.props.editFeeType ? 'Add' : 'Update'}`} className='p-button-raised' onClick={this.onSubmitClick} />

        </>)
    }

    onSubmitClick = () => {

        let data = trimObj(this.state.feeType);
        const formStatus = isFormValid(formFields, this.formFields.formValidations, data);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            if (this.props.editFeeType) {

                const url = `${baseUrlAdmin}/fee-types`;
                this.service.put(url, data, true).then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.onFeeTypeCreate(true);
                        })

                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }

                }).catch(e => {
                    console.log(e);
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            } else {
                const url = `${baseUrlAdmin}/fee-types`;
                this.service.post(url, data, true).then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.onFeeTypeCreate(true);
                        })

                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }

                }).catch(e => {
                    console.log(e);
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                })
            }


            // this.props.addSubject(trimObj(this.state.feeType));
            // // this.setState({
            // //     formValidations: formStatus.formValidations,
            // // });
        }
    }

    onCloseDialog = () => {
        this.props.hideCreateFeeTypeDialog();
        // this.setState({
        //     showCreateDialog: false,
        //     subject: {
        //         subjectName: '',
        //         subjectCode: '',
        //         isSubjectActive: false
        //     }
        // }, () => { })
    }

    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.feeType));
        let formValidations = this.state.formValidations;
        data[field] = e.target.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            feeType: data,
            formValidations
        });
    }
    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.feeType));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            feeType: data,
            formValidations
        });
    }

    render() {
        const { feeType, formValidations } = this.state;
        return (
            <Dialog header={`${!this.props.editFeeType ? 'Add' : 'Edit'} Fee Type`} headerClassName='text-center'
                blockScroll={true} draggable={false} footer={this.footer}
                closeOnEscape={true} dismissableMask={true} visible={true}
                closable={false}
                onHide={() => this.setState({ showCreateDialog: false })}
                style={{width:"500px"}}
                
                >
                <div className="grid subject-add-dialog">
                    <div className="col-4">
                        <p className='text-label'>Fee Type<span className='ma-required'>*</span></p>
                    </div>
                    <div className="col-8">
                        {/* <InputTextB info={FIELDS_INFO.FEE_TYPE} id="feeType"> */}
                            <InputText value={feeType.feeType} onChange={(e) => { this.onTextChange(e, 'feeType') }} className='w-10' />
                        {/* </InputTextB> */}
                        {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>}
                    </div>
                </div>
                <div className="grid subject-add-dialog">
                    <div className="col-4 p-md-4 p-lg-4">
                        <p className='text-label' >Is Consession Applicable<span className='ma-required'>*</span></p>
                    </div>
                    <div className="col-8">
                        {/* <InputTextB info={FIELDS_INFO.IS_CONSESSION_APPLICABLE} id="isConsApplicable"> */}
                            <InputSwitch checked={feeType.isConsApplicable} onChange={(e) => { this.onToggleChange(e, 'isConsApplicable') }} />
                        {/* </InputTextB> */}
                    </div>
                </div>
                <div className="grid subject-add-dialog">
                    <div className="col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Is Student Specific</p>
                    </div>
                    <div className="col-8">
                        {/* <InputTextB info={FIELDS_INFO.IS_STUDENT_SPECIFIC} id="isStudentSpecific"> */}
                            <InputSwitch checked={feeType.isStudentSpecific} onChange={(e) => { this.onToggleChange(e, 'isStudentSpecific') }} />
                        {/* </InputTextB> */}
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Dialog >
        )
    }
}




export default CreateFeeType;