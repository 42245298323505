import { Dropdown } from 'primereact/dropdown'
import React, { Component } from 'react'
import Service from '../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { getContentSource, warningDailogInit } from '../../utile';
import {
    getBoardsData
} from './../../store/actions';
import _ from 'lodash';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Authentication from './../session';
import { connect } from 'react-redux';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
// import ContentPlayer from '../contentMaster/contentPlayers';
import withRouter from '../lib/withRouter';

class CurriculumPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: null,
            filteredContentDetails: [],
            warningDialog: warningDailogInit,
            isShowEditContent: false,
            selectedContent: null,
            globalFilter: ''
            //contests[3],

        }
        this.service = new Service();
    }



    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }
    }



    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a,b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                content: null,
                filteredContentDetails: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: []
            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopicResource = [];
        let selectedTopic = this.state.topics.filter(t => t.topicId == topicId)
        let selectedTopicResourceTypes = selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [];
        if (selectedTopicResourceTypes && selectedTopicResourceTypes.length) {
            selectedTopicResourceTypes = selectedTopicResourceTypes.map((a, index) => {
                if (index == 0) {
                    a.isSelected = true;
                } else {
                    a.isSelected = false;
                }
                return a;
            })
        }

        this.setState(
            {
                topicId,
                selectedTopicResourceTypes: selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [],
                content: null,
                filteredContentDetails: []
            }, () => {
                if (this.state.selectedTopicResourceTypes && this.state.selectedTopicResourceTypes.length) {
                    this.getContentDetails();
                }
            });
    };


    filterResource = (contents, sTR) => {
        let selectedTopicResourceTypes = _.cloneDeep(sTR)

        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);

        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == filter.value)
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))

        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }






    getContentDetails = () => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        this.setState({
            isLoading: true,

        });
        const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}?etag=true`;
        this.service.get(url, true).then((res) => {

            const etag = res && res.etag;



            if (res && res.res && res.res.Item) {
                let filteredContentDetails = [];
                if (res.res.Item.contentDetails) {
                    filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                }
                this.setState((prevState) => {
                    return {
                        content: { boardId, classId, groupId, subjectId, chapterId, topicId, ...res.res.Item },
                        filteredContentDetails,
                        isLoading: false,
                        etag
                    }
                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    filterByAssignedBoards = (boards) => {
        return boards.filter(b => this.props.assignedBoards.includes(b.boardId))
    }
    filterByAssignedClasses = (classes) => {
        
        return classes.filter(b => this.props.assignedClasses.includes(b.value))
    }
    filterByAssignedSubjects = (subjects) => {
        return subjects.filter(b => this.props.assignedSubjects.includes(b.subjectId))
    }


    showPreview = (contentInfo) => {

        this.setState({
            isShowPreview: true,
            selectedContent: contentInfo
        });


    }


    render() {
        console.log(this.props, 'dddd')
        const header = (
            <div >
              

            </div>
        );
        return (<>
            <div className='my-3'>
                <Dropdown value={this.state.boardId}
                    className='w-2'
                    //options={this.state.boards}
                    options={this.filterByAssignedBoards(this.props.boards)}
                    optionLabel="boardName"
                    optionValue="boardId"
                    onChange={(e) => this.onChangeBoard(e.value)}
                    placeholder="Select Board" />
                <span className='ma-required-mark mr-3'>*</span>
                <Dropdown value={this.state.classId}
                    options={this.filterByAssignedClasses(this.state.classes)}
                    className='w-2'
                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                <span className='ma-required-mark mr-3'>*</span>
                <Dropdown value={this.state.subjectId}
                    options={this.filterByAssignedSubjects(this.state.subjects)}
                    optionLabel='subjectName'
                    optionValue='subjectId'
                    className='w-2'
                    onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                <span className='ma-required-mark mr-3'>*</span>
                <Dropdown value={this.state.chapterId}
                    options={this.state.chapters}
                    optionLabel='chapterName'
                    optionValue='chapterId'
                    className='w-2'
                    onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                <span className='ma-required-mark mr-3'>*</span>
                <Dropdown value={this.state.topicId}
                    options={this.state.topics}
                    optionLabel='topicName'
                    optionValue='topicId'
                    className='w-2'
                    onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                <span className='ma-required-mark mr-3'>*</span>
            </div>
            <div className='ma-clearFix'>
            </div>

            <div className='ma-mt30'>
                <div className="card datatable-crud-demo">
                    <DataTable
                        ref={(el) => this.dt = el}
                        id="contentt"
                        //  lazy
                        //value={this.state.content && this.state.content.contentDetails}
                        value={this.state.filteredContentDetails}
                        //value={[{ priority: 1, fileName: 'ddd' }]}
                        // selection={this.state.selectedProducts}
                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                        dataKey="contentId"
                     //   header={header}
                        rows={100}
                        responsiveLayout="scroll"
                        scrollable
                        // scrollDirection="both"
                        scrollWidth="flex"
                        //selectionMode="checkbox"
                        showGridlines
                        globalFilter={this.state.globalFilter}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"

                    >
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                        {/* <Column sortable filterField="priority" headerStyle={{ width: "100px" }} field="priority" header="Sl No" /> */}
                        <Column filterField="contentName" field="contentName" header="Resourse Name" />
                        {/* <Column sortable filterField="contentResourceType" headerStyle={{ width: "130px" }} field="contentResourceType" header="File Type" /> */}
                        {/* <Column sortable filterField="source" field="source" header="Source" body={(rowData) => {
                            return (<span>{getContentSource(rowData.source)}</span>)
                        }} /> */}
                        <Column header="Preview" body={(rowData) => {
                            return (<div className='preview-text'>
                                <span onClick={() => { this.showPreview(rowData) }} > <i className='pi pi-eye ma-mr10' />Preview </span>
                            </div>)
                        }}
                        />
                    </DataTable>
                </div>
                {/* {
                    this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                        this.setState({
                            isShowPreview: false
                        })
                    }} contentInfo={this.state.selectedContent} />
                } */}
            </div>
        </>
        )
    }
}




const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    userData: state.userData && state.userData.data && state.userData.data,
    assignedSubjects: state.currentUserPerms && state.currentUserPerms.userInfo && state.currentUserPerms.userInfo.assignedSubjects.map(a => a.subjectId) || [],
    assignedClasses: state.currentUserPerms && state.currentUserPerms.userInfo && state.currentUserPerms.userInfo.assignedSubjects.map(a => a.classId) || [],
    assignedBoards: state.currentUserPerms && state.currentUserPerms.userInfo && state.currentUserPerms.userInfo.assignedSubjects.map(a => a.boardId) || [],
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(CurriculumPlayer)));