import React, { Component } from 'react';
import { connect } from 'react-redux';
import Service from '../../services';
import moment from 'moment';
import { Toolbar } from 'primereact/toolbar';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import InputTextB from '../customComponents/inputTextB';
import { FIELDS_INFO } from '../../constants';
import uuidv4 from 'uuid/v4';
import Authentication from '../session';
import './styles.scss'
import { trimObj } from '../../utile';
import { Calendar } from 'primereact/calendar';
import {
    getBoardsData,
    getBranchesLatest
} from '../../store/actions';
import { checkAndReturnOnlyBranches, userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { Toast } from 'primereact/toast';
import { baseUrlAdmin } from '../../store/apiConstants';
import axios from 'axios';
// import { isSupportedImage } from 'html2canvas/dist/types/css/types/image';
import { getFormFields, isFormValid, onChipsChange, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../utile/formHelper';
import LoadingComponent from '../loadingComponent';
import AssignTeachers from './assignTeachers';
import { InputNumber } from 'primereact/inputnumber';
import { CalendarIcon } from './../svgIcons/index';
import withRouter from '../lib/withRouter';

const Durations = Array.from({ length: 91 }, (_, index) => index + 30).map((l, index) => {
    return { label: `${l} minutes`, value: `${l}` }
})

const BreakDurations = Array.from({ length: 20 }, (_, index) => (index * 5) + 5).map((l, index) => {
    return { label: `${l} minutes`, value: `${l}` }
})

const Periods = Array.from({ length: 16 }, (_, index) => index + 1).map((l, index) => {
    return { label: `${l}`, value: `${l}` }
})

const formFields = [
    {
        "Type": "Text",
        "Label": "Title",
        "FieldName": "title",
        "Required": true,
        "MaxLength": 50
    },
    {
        "Type": "Number",
        "Label": "No of Periods per day",
        "FieldName": "noOfPeriodsPerDay",
        "Required": true
    },
    {
        "Type": "Number",
        "Label": "Duration",
        "FieldName": "duration",
        "Required": true
    },
    {
        "Type": "String",
        "Label": "Peroid Start Time",
        "FieldName": "periodStartTime",
        "Required": true
    },
    {
        "Type": "Array",
        "Label": "Section Ids",
        "FieldName": "sectionIds",
        "Required": true
    }
]

const breakFormFields = [
    {
        "Type": "Text",
        "Label": "Name of Break",
        "FieldName": "name",
        "Required": true,
    },
    {
        "Type": "Number",
        "Label": "Duration",
        "FieldName": "duration",
        "Required": true
    }
]

const defaultChapter = {
    "chapterName": "",
    "chapterId": "",
    "startEndDate": null,
    "preKnowledge": "",
    "learningObjective": "",
    "learningOutcome": ""
}

class CreateTimeTable extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editTimetableData || { boardId: uuidv4() });

        this.breakformFields = getFormFields(breakFormFields || { type: 'break' });
        this.state = {
            timeTable: this.formFields.data,
            formValidations: this.formFields.formValidations,

            boards: [],
            classes: [],
            chapters: [],
            masterChapters: [],
            masterSubjects: [],
            selectedSubject: "",
            selectedRow: 0,
            subjectChaptes: {},
            boardId: null,
            classId: null,
            groupId: null,
            selectedSubjectIndex: null,
            subjects: [],
            periods: this.props.editTimetableData ? this.props.editTimetableData.periods : [],
            isShowAddBreakDialog: false,
            breakPosition: null,
            // periodBreak: {
            //     name: '',
            //     duration: ''
            // },
            periodBreak: this.breakformFields.data,
            periodBreakformValidations: this.breakformFields.formValidations,
            sectionIds: this.props.editTimetableData ? this.props.editTimetableData.sections.map(a => a.sectionId) : [],
            editTimetableData: this.props.editTimetableData ? this.props.editTimetableData : null

        }
        this.service = new Service();
    }


    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            // let sectionsData = {
            //     level1: this.props.branchData.filter((a) => a.level == 1)
            // }
            // this.setState({
            //     sectionsData
            // });
            if (this.props.editTimetableData && this.props.branchData) {


                let finaldata = this.getSectionsData(this.props.branchData, this.props.editTimetableData.sectionIds[0], this.props.branchLevels.length);
                let sectionIds = this.getSectionIds(this.props.branchData, this.props.editTimetableData.levelId)

                this.setState((prevState) => {
                    return {
                        sectionsData: finaldata,
                        sectionIds,
                        timeTable: {
                            ...prevState.timeTable,
                            sectionIds,
                        }
                    }
                });

            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }

                this.setState({
                    sectionsData
                });
            }
        }
    }





    componentDidUpdate(prevProps) {
        if (prevProps.boards != this.props.boards) {
            // if (this.props.editTimetableData) {
            //     this.generateEditForm(this.props.editTimetableData);
            // }
        }

        if (prevProps.branchData !== this.props.branchData) {

            // let sectionsData = {
            //     level1: this.props.branchData.filter((a) => a.level == 1)
            // }
            // this.setState({
            //     sectionsData
            // });
            if (this.props.editTimetableData) {
                // let finaldata = this.getSectionsData(this.props.branchData, this.props.editClassData.sectionIds[0], this.props.branchLevels.length);
                // this.setState({
                //     sectionsData: finaldata
                // });

                //    let _filtered = this.filterTeacherAssignedSections(this.props.teacherAssignedSections)

                let finaldata = this.getSectionsData(this.props.branchData, this.props.editTimetableData.sectionIds[0], this.props.branchLevels.length);
                let sectionIds = this.getSectionIds(this.props.branchData, this.props.editTimetableData.levelId)

                // this.setState({
                //     sectionsData: finaldata,
                //     sectionIds,

                //     // filteredSections: _filtered.filteredSections
                //     //sectionIds:
                // });


                this.setState((prevState) => {
                    return {
                        sectionsData: finaldata,
                        sectionIds,
                        timeTable: {
                            ...prevState.timeTable,
                            sectionIds,
                        }
                    }
                });
            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }

                this.setState({
                    sectionsData
                });
            }
        }


        this.callMathJax();
    }

    callMathJax = () => {
        if (window && window.MathJax && window.MathJax.Hub) {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }
    }

    isAdditionalValid = () => {
        let { formValidations, periods } = this.state;
        let isValid = true;

        if (!periods || !periods.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Periods are required.', life: 3000 });
            isValid = false;
        }





        return isValid;
    }


    createTimetable = (data) => {


        this.setState({
            isLoading: true
        });
        let thisObj = this;
        const url = `${baseUrlAdmin}/timetable/${(this.state.editTimetableData) ? 'update' : 'create'}`;

        this.service[(this.state.editTimetableData) ? 'put' : 'post'](url, data, true).then((res) => {


            if (res && res.status && res.res.status) {

                this.setState({
                    isLoading: false,
                    timeTableInfo: res.res.data,
                    editTimetableData: res.res.data,
                    isShowAssignTeacher: true,
                }, () => {

                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    onSubmitClick = async (e) => {
        const { timeTable, periods } = this.state;

        let isCompleteFormValid = true

        let _data = trimObj(timeTable);
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(timeTable));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
            isCompleteFormValid = false;
        }

        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
            //  this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields or enter correct data', life: 3000 });
            return;
        }

        let sections = this.props.branchData;

        _data.sections = _data.sectionIds.map((s) => {

            return {
                sectionName: sections.find(a => a.key == s).name,
                sectionId: s
            }
        })

        _data.periodStartTime = new Date(_data.periodStartTime).toISOString();

        if (isCompleteFormValid) {

            // if () {

            //     this.setState({
            //         isShowAssignTeacher: true,
            //         timeTableInfo: { ..._data, periods }
            //     })

            // } else {
            this.createTimetable({ ...this.state.editTimetableData, ..._data, periods })
            //}






        }



    }

    cancelCreate = () => {

        this.props.cancelCreate();


    }



    getSectionsData = (nodes, key, totalLevels) => {

        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }

        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }



    getSectionIds = (nodes, key) => {
        const _nodes = _.cloneDeep(nodes);
        let sectionIds = [];
        function recurse(node) {
            if (node.children) {
                node.children = node.children.map(childNode => {
                    childNode = _nodes.find((n) => n.key == childNode);
                    childNode = recurse(childNode);
                    return childNode
                });
            } else {
                sectionIds.push(node.key);
            }
            return node;
        }


        let mainNode = _nodes.find(n => n.key == key);
        recurse(mainNode);



        return sectionIds;
    }

    onChangeSection = (key, level) => {
        let { sectionsData, timeTable } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)
        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }


        let markedIndex = this.props.branchLevels.findIndex(l => l.isMarked);


        let sectionIds = [];
        if (markedIndex + 1 == level) {
            sectionIds = this.getSectionIds(this.props.branchData, key)
        } else {
            sectionIds = [];
        }




        this.setState((prevState) => {
            return {
                sectionsData: newSectionData,
                sectionIds,
                levelId: key,
                timeTable: {
                    ...prevState.timeTable,
                    sectionIds,
                    levelId: key
                }
            }
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onMultiChange = (value) => {


        let timeTable = {
            ...this.state.timeTable,
            sectionIds: value
        };
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'sectionIds'), this.state.formValidations, timeTable);

        this.setState((prevState) => {
            return {
                sectionIds: value,
                timeTable,
                formValidations
            };
        });
    }



    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }



    formPeriods = (periods, duration, startTime) => {
        let obj = {
            name: '',
            startTime: '',
            endTime: '',
            type: 'class'
        }

        let totalPeriods = [];

        for (let i = 0; i < parseInt(periods); i++) {
            let _temp = JSON.parse(JSON.stringify(obj));
            startTime = new Date(startTime);
            _temp.name = 'Period ' + (i + 1);
            _temp.startTime = moment(new Date(startTime))
            //.format('LT');
            startTime = moment(new Date(startTime)).add(parseInt(duration), 'minutes');
            _temp.endTime = moment(new Date(startTime))
            _temp.periodId = uuidv4()
            //.format('LT');
            totalPeriods.push(_temp);
        }


        return totalPeriods;

    }


    addBreak = () => {
        let { periodBreak, periods, breakPosition, timeTable } = this.state;

        let isCompleteFormValid = true

        const formStatus = isFormValid(breakFormFields, this.breakformFields.formValidations, trimObj(periodBreak));

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                periodBreakformValidations: formStatus.formValidations
            });
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
            isCompleteFormValid = false;
        } else {

            let breakData = {
                name: '',
                startTime: '',
                endTime: '',
                type: 'break',
                breakDuration: periodBreak.duration
            }
            let _temp = JSON.parse(JSON.stringify(breakData));

            let startTime = periods[breakPosition].endTime;

            _temp.name = periodBreak.name;
            _temp.startTime = moment(new Date(startTime))
            //.format('LT');
            startTime = moment(new Date(startTime)).add(parseInt(periodBreak.duration), 'minutes');
            _temp.endTime = moment(new Date(startTime))
            //.format('LT');

            periods.splice(breakPosition + 1, 0, _temp);

            for (let i = breakPosition + 2; i < periods.length; i++) {
                let _period = JSON.parse(JSON.stringify(periods[i]));
                startTime = new Date(startTime);
                _period.startTime = moment(new Date(startTime))
                //.format('LT');
                if (_period.type == 'class') {
                    startTime = moment(new Date(startTime)).add(parseInt(timeTable.duration), 'minutes');
                } else {
                    startTime = moment(new Date(startTime)).add(parseInt(_period.breakDuration), 'minutes');
                }

                _period.endTime = moment(new Date(startTime))
                //.format('LT');
                periods[i] = _period;
            }

            this.setState({
                periods: periods,
                periodBreak: {
                    name: '',
                    duration: '',
                },
                breakPosition: null,
                isShowAddBreakDialog: false
            })


        }



    }

    deleteBreak = (breakPosition) => {

        const { periods, timeTable } = this.state;

        periods.splice(breakPosition, 1);


        let startTime = periods[breakPosition - 1].endTime;
        for (let i = breakPosition; i < periods.length; i++) {
            let _period = JSON.parse(JSON.stringify(periods[i]));
            startTime = new Date(startTime);
            _period.startTime = moment(new Date(startTime))
            //.format('LT');
            if (_period.type == 'class') {
                startTime = moment(new Date(startTime)).add(parseInt(timeTable.duration), 'minutes');
            } else {
                startTime = moment(new Date(startTime)).add(parseInt(_period.breakDuration), 'minutes');
            }

            _period.endTime = moment(new Date(startTime))
            //.format('LT');
            periods[i] = _period;
        }

        this.setState({
            periods
        })
    }


    generatePeriods = () => {
        const { timeTable } = this.state;

        let isCompleteFormValid = true

        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(timeTable));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
            isCompleteFormValid = false;
        } else {
            let periods = this.formPeriods(timeTable.noOfPeriodsPerDay, timeTable.duration, timeTable.periodStartTime)

            this.setState({
                periods
            })
        }






    }


    showAddBreakDialog = (breakPosition) => {

        //alert(breakPosition);



        this.setState({
            isShowAddBreakDialog: true,
            breakPosition
        })
    }





    render() {
        const { } = this.state;
        const { formValidations, timeTable, periodBreak, periodBreakformValidations } = this.state;

        let markedFound = false;
        return (
            <>

                <h4 className=''>{this.props.editTimetableData ? 'Edit' : 'Create'} Timetable</h4>
                <div className="grid">
                    <div className="col-12 md-12 lg-12 m-3">
                        <div className="grid">
                            <div className="sm-12 md-3 lg-3 mr-4 flex flex-column">
                                <label className='text-label12 d-block '>Title<span className='ma-required'>*</span></label>
                                <InputText value={timeTable.title}
                                    disabled={this.state.editTimetableData}
                                    className=""
                                    onChange={(e) => { onTextChange(e.target.value, 'title', this, formFields, timeTable, formValidations, 'timeTable', 'formValidations') }}
                                    name="title" />
                                {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                            </div>
                            <div className="sm-12 md-3 lg-3 mr-4 flex flex-column">
                                <label className='text-label12 d-block'>No of Periods per day<span className='ma-required'>*</span></label>
                                <Dropdown
                                    value={timeTable.noOfPeriodsPerDay}
                                    className=''
                                    disabled={this.state.editTimetableData}
                                    options={Periods}
                                    onChange={(e) => { onTextChange(e.target.value, 'noOfPeriodsPerDay', this, formFields, timeTable, formValidations, 'timeTable', 'formValidations', { periods: [] }) }}
                                    placeholder="Select Periods"
                                />
                                {/* <InputNumber
                                    inputId="minmax-buttons"
                                    className='ma-w100p'
                                    placeholder="Select Periods"
                                    value={timeTable.noOfPeriodsPerDay}
                                    onValueChange={(e) => onTextChange(e.value, 'noOfPeriodsPerDay', this, formFields, timeTable, formValidations, 'timeTable', 'formValidations', { periods: [] })}
                                    mode="decimal"
                                     showButtons
                                    min={1}
                                    max={100} /> */}
                                {formValidations && !formValidations.fields['noOfPeriodsPerDay'].isValid && <p className="p-error">{formValidations.fields['noOfPeriodsPerDay'].errorMsg}</p>}
                            </div>
                            <div className="sm-12 md-3 lg-3 mr-4 flex flex-column">
                                <label className='text-label12 d-block'>Duration / Period<span className='ma-required'>*</span></label>
                                <Dropdown
                                    value={timeTable.duration}
                                    className=''
                                    disabled={this.state.editTimetableData}
                                    options={Durations}
                                    onChange={(e) => { onTextChange(e.target.value, 'duration', this, formFields, timeTable, formValidations, 'timeTable', 'formValidations', { periods: [] }) }}
                                    placeholder="Select Duration"
                                />

                                {formValidations && !formValidations.fields['duration'].isValid && <p className="p-error">{formValidations.fields['duration'].errorMsg}</p>}
                            </div>
                            <div className="sm-12 md-3 lg-3 mr-4 flex flex-column">
                                <label className='text-label12 d-block'>Start Time<span className='ma-required'>*</span></label>
                                <Calendar
                                    timeOnly
                                    hourFormat="12"
                                    disabled={this.state.editTimetableData}
                                    value={timeTable.periodStartTime ? new Date(timeTable.periodStartTime) : null}
                                    // name='endDate'
                                    onChange={(e) => { onTextChange(new Date(e.value).toString(), 'periodStartTime', this, formFields, timeTable, formValidations, 'timeTable', 'formValidations', { periods: [] }) }}
                                    showIcon={true}
                                    // minDate={new Date(moment(timeTable.startDate).add(1, 'day'))}
                                    placeholder='Start Time'
                                    //  id='fromDates'
                                    className=''
                                ></Calendar>

                                {formValidations && !formValidations.fields['periodStartTime'].isValid && <p className="p-error">{formValidations.fields['periodStartTime'].errorMsg}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.isLevelMarked ? <>
                    <div>
                        {
                            this.props.branchLevelsTillMarked ? this.props.branchLevelsTillMarked.map((level, index) => {
                                return (<Dropdown
                                    key={'a' + index}
                                    className='w-10rem my-3'
                                    value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                    // className='ma-w100p'
                                    //    disabled={this.state.hideUpdateButton}
                                    tooltip={level.level}

                                    optionLabel={"name"}
                                    optionValue={"key"}
                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                    disabled={this.state.editTimetableData}
                                    onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                    placeholder={`--Select ${level.levelName}--`} />

                                    //     : <MultiSelect
                                    //     className='ma-mr20 ma-mt10 ma-w200'
                                    //     value={this.state.sectionIds ? this.state.sectionIds : []}
                                    //     // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                    //     onChange={(e) => this.onMultiChange(e.value)}
                                    //     options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                    //     optionLabel={"name"}
                                    //     optionValue={"key"}
                                    //     placeholder={`--Select ${level.levelName}--`}
                                    //     disabled={this.state.isShowAssignTeacher}
                                    // // onChange={(e) => setCities(e.value)} 
                                    // />
                                )
                            }) : null
                        }

                        <label className='mx-2'>Total Sections: {this.state.sectionIds.length}</label>
                    </div>
                    {formValidations && !formValidations.fields['sectionIds'].isValid && <p className="p-error">{formValidations.fields['sectionIds'].errorMsg}</p>}

                </> : <>
                    <p className='ma-danger'>Please mark one level in branch master to selecte sections from here</p>

                </>}


                {this.state.isShowAssignTeacher ? <>
                    <AssignTeachers timeTable={this.state.timeTableInfo} />
                </> : <>
                    <Button label={'Generate Periods'} disabled={this.state.editTimetableData} className='ma-mt20 ' onClick={this.generatePeriods} />
                    <h4 className=''>Define Periods</h4>

                    <div className="grid">
                        <div className="sm:col-12 md:col-12 lg:col-8">
                            <div className='periods-wrapper'>
                                {
                                    this.state.periods && this.state.periods.length ? this.state.periods.map((period, index) => {
                                        return <div className='grid ma-mt10' key={'a' + index}>
                                            <div className='sm:col-3 md:col-3 lg:col-3 period-name mt-3 '>
                                                {period.name}
                                            </div>
                                            <div className='sm:col-3 md:col-2'>
                                                <label className='time-lbl'>Start time</label>
                                                <div className={`period-time ${period.type == 'break' ? "period-break" : ""} `}>
                                                    {moment(period.startTime).format('LT')}
                                                    <span className='ma-right'>
                                                        <CalendarIcon width={16} height={16} color={period.type == 'break' ? 'white' : null} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='sm:col-3 md:col-2'>
                                                <label className='time-lbl'>End time</label>
                                                <div className={`period-time ${period.type == 'break' ? "period-break" : ""} `}>
                                                    {moment(period.endTime).format('LT')}
                                                    <span className='ma-right'>
                                                        <CalendarIcon width={16} height={16} color={period.type == 'break' ? 'white' : null} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='md:col-5 sm:col-5  ma-mt20'>
                                                {
                                                    (this.state.periods.length - 1 != index) && <>
                                                        {
                                                            period.type == "break" ? <Button
                                                                icon="pi pi-trash"
                                                                className='p-button-danger mt-4'
                                                                disabled={this.state.editTimetableData}
                                                                onClick={() => { this.deleteBreak(index) }}
                                                            /> :
                                                                <Button
                                                                    label="Add Break"
                                                                    icon="pi pi-plus"
                                                                    className='p-button-outlined mt-4'
                                                                    disabled={this.state.editTimetableData}
                                                                    onClick={() => { this.showAddBreakDialog(index) }}
                                                                />}
                                                    </>
                                                }

                                            </div>


                                        </div>
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                </>}



                <Toolbar className="border-none ma-mt20"
                    right={() => {
                        return <>
                            {
                                this.state.isShowAssignTeacher ? <Button label={'Save'} className='ma-mr20' onClick={this.props.dummySave} /> : <Button label={'Save and Proceed'} className='mr-2 ' onClick={this.onSubmitClick} />
                            }
                            {
                                !this.state.isShowAssignTeacher && <Button label="Cancel" className='p-button-outlined' onClick={this.cancelCreate} />
                            }

                        </>
                    }}
                ></Toolbar>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

                {
                    (this.state.isLoading || this.props.isBranchLoading) && <LoadingComponent />
                }


                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    header="Add Break"
                    visible={this.state.isShowAddBreakDialog} style={{ maxWidth: '30vw', minWidth: '400px' }}
                    footer={() => {
                        return (<div>
                            <Button label="Cancel" className='p-button-outlined' onClick={() => {
                                this.setState({
                                    isShowAddBreakDialog: false,
                                    breakPosition: null,
                                    periodBreak: {
                                        name: '',
                                        duration: ''
                                    }
                                })
                            }} />
                            <Button label="Add" className='ma-m-lr10' onClick={this.addBreak} />
                        </div>)
                    }}
                >

                    <div className="p-grid subject-add-dialog">
                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label'>Name of Break<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputTextB info={FIELDS_INFO.NAME_OF_BREAK} id="topicn">
                                <InputText value={periodBreak.name}

                                    onChange={(e) => { onTextChange(e.target.value, 'name', this, breakFormFields, periodBreak, periodBreakformValidations, 'periodBreak', 'periodBreakformValidations') }}
                                    className='p-inputtext-style1 ' />
                            </InputTextB>
                            {periodBreakformValidations && !periodBreakformValidations.fields['name'].isValid && <p className="p-error">{periodBreakformValidations.fields['name'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid subject-add-dialog">
                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label'>Duration<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputTextB info={FIELDS_INFO.DURATION} id="topicn">
                                <Dropdown
                                    value={periodBreak.duration}
                                    //className='ma-w100p'
                                    options={BreakDurations}
                                    onChange={(e) => { onTextChange(e.target.value, 'duration', this, breakFormFields, periodBreak, periodBreakformValidations, 'periodBreak', 'periodBreakformValidations') }}
                                    placeholder="Select Duration"
                                />
                            </InputTextB>
                            {periodBreakformValidations && !periodBreakformValidations.fields['duration'].isValid && <p className="p-error">{periodBreakformValidations.fields['duration'].errorMsg}</p>}
                        </div>
                    </div>
                </Dialog>

            </>
        )
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: _.cloneDeep(state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels),
    branchData: _.cloneDeep(state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data),
    branchLevelsTillMarked: checkAndReturnOnlyBranches(state, true),
    isLevelMarked: checkAndReturnOnlyBranches(state, false)
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(CreateTimeTable)));