import { MELUHA_BOARD_ID } from './../constants'
import _ from 'lodash';
import axios from 'axios';
import striptags from 'striptags';
export const getOptionsKey = option => {
    if (option && Array.isArray(option)) {
        let optionValue = ''
        option.map(o => {
            optionValue += optionToAlphabet(o);

        })
        return optionValue.split('').join(',');
    }

    return optionToAlphabet(option);

}

export const getChangeOptionsKey = option => {
    if (option && Array.isArray(option)) {
        let optionValue = ''
        option.map(o => {
            optionValue += alphabetToOptions(o);

        })
        return optionValue;
    }

    return alphabetToOptions(option);
}

export const optionToAlphabet = option => {
    switch (option) {
        case 'Option1':
            return 'A';
        case 'Option2':
            return 'B';
        case 'Option3':
            return 'C';
        case 'Option4':
            return 'D';
        case 'Option5':
            return 'E';
        case 'Option6':
            return 'F';
        case 'Option7':
            return 'G';
        case 'Option8':
            return 'H';
        default:
            return option || '';

    }
}

export const removeSpecialHtmlTags = (htmlStr) => {

    htmlStr = striptags(htmlStr);
    htmlStr = htmlStr.replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<");
    return htmlStr;
};

export const IndexToAlphabet = number => {

    switch (number) {
        case 1:
            return 'A';
        case 2:
            return 'B';
        case 3:
            return 'C';
        case 4:
            return 'D';
        case 5:
            return 'E';
        case 6:
            return 'F';
        case 7:
            return 'G';
        case 8:
            return 'H';
        default:
            return number || '';

    }
}

export const OptionToAlphabet = option => {

    switch (option) {
        case 'Option 1':
            return 'A';
        case 'Option 2':
            return 'B';
        case 'Option 3':
            return 'C';
        case 'Option 4':
            return 'D';
        case 'Option 5':
            return 'E';
        case 'Option 6':
            return 'F';
        case 'Option 7':
            return 'G';
        case 'Option 8':
            return 'H';
        default:
            return option || '';

    }
}

export const AlphabetSingleKey = key => {
    switch (key) {
        case "A":
            return 'Option 1';
        case "B":
            return 'Option 2';
        case "C":
            return 'Option 3';
        case "D":
            return 'Option 4';
        case "E":
            return 'Option 5';
        case "F":
            return 'Option 6';
        case "G":
            return 'Option 7';
        case "H":
            return 'Option 8';
        default:
            return key || ' '
    }
}
export const alphabetToOptions = (option, questionType) => {

    if (questionType == 'MCCT' || 'SCCT') {
        const _keys = option && option.length > 0 && option.split(',');
        const _options = _keys.map(o => alphabetSingleKey(o));
        return _options;

    } else {
        return alphabetSingleKey(option);
    }


}

const alphabetSingleKey = key => {
    switch (key) {
        case "A":
            return 'Option1';
        case "B":
            return 'Option2';
        case "C":
            return 'Option3';
        case "D":
            return 'Option4';
        case "E":
            return 'Option5';
        case "F":
            return 'Option6';
        case "G":
            return 'Option7';
        case "H":
            return 'Option8';
        default:
            return key || ' '
    }
}


export function convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export const filterOfQuestionType = (obj) => {
    const noOfQuestionTypes = []
    const x = Object.keys(obj).map(x => {
        noOfQuestionTypes.push({
            label: x,
            value: obj[x]
        })
    });
    return noOfQuestionTypes;

}


export const getTagName = (id, tagData) => {
    const tagInfo = tagData.find(tag => tag.tagid === id);
    return tagInfo.tagname;
}


export const contentSizeCheck = (contentSize, size) => (contentSize / 1024) > size

export const duplicateNameInContent = (contentInfo, checkValue, keyName) => {
    return contentInfo && contentInfo.length > 0 && contentInfo.find(x => {
        return x[keyName] === checkValue
    })
}


export const ContentSource = {
    UPLOAD: 1,
    CONTENT_REPO: 2,

}

export const getContentSource = (type) => {
    switch (type) {
        case ContentSource.UPLOAD:
            return 'Upload';
        case ContentSource.CONTENT_REPO:
            return 'Content Repository';
        default:
            return '';
    }

}


export const ContetOrigin = {
    MELUHA: 1,
    ERUDEX: 2,
    COLEARNERS: 3,
};


export const ContentOrigins = [
    //{
    //      value: ContetOrigin.MELUHA,
    //      displayName: 'MELUHA'
    //  },
    {
        value: ContetOrigin.ERUDEX,
        displayName: 'ERUDEX'
    },
    {
        value: ContetOrigin.COLEARNERS,
        displayName: 'COLEARNERS'
    },
];

export const FileTypes = {
    VIDEO: 'mp4',
    PPT: 'ppt',
    PDF: 'pdf',
    QUIZ: 'quiz'
}

export const getFileTypes = () => {
    let files = [];
    Object.keys(FileTypes).forEach((item) => {
        files = [...files, {
            displayName: item,
            value: FileTypes[item]
        }]
    })
    return files;
}

export const getContentResourseType = (type) => {
    switch (type) {
        case FileTypes.VIDEO:
            return 'video';
        case FileTypes.PPT:
            return 'ppt'
        case FileTypes.PDF:
            return 'pdf'
        case FileTypes.QUIZ:
            return 'quiz'
        default:
            return '';
    }

}



export const generateRandomKey = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}






export const secondsToTime = (seconds) => {

    var minutes = parseInt(seconds / 60, 10);
    seconds = seconds % 60;
    var hours = parseInt(minutes / 60, 10);
    minutes = minutes % 60;

    return hours + ':' + minutes + ':' + seconds;
}


export const getDateString = (dateObj) => {
    var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    var date = ('0' + dateObj.getDate()).slice(-2);
    var year = dateObj.getFullYear();
    return year + '-' + month + '-' + date;
}


export const getDatesBetweenDates = (startDate, endDate) => {
    let dates = []
    //to avoid modifying the original date
    const theDate = new Date(startDate)
    while (theDate <= endDate) {

        dates = [...dates, getDateString(new Date(theDate))]
        theDate.setDate(theDate.getDate() + 1)
    }
    return dates
}

export const getProfileURL = (url) => {
    if (url) {
        return url.includes("https://ui-avatars.com") ? url : `https://classet-profiles.s3.ap-south-1.amazonaws.com/${url}`;
    } else {
        return '';
    }
}





export const onProgress = (filesInfo, tempFiles) => {
    let added = false;
    if (tempFiles.length > 0) {
        for (let j = 0; j < tempFiles.length; j++) {
            if (tempFiles[j].name === filesInfo.name) {
                tempFiles = tempFiles.map(
                    (file) => {
                        if (file.name === filesInfo.name) {
                            return file;
                        } else {
                            return file
                        }
                    });
            } else {
                if (!added) {
                    if (tempFiles[j].name === filesInfo.name) {
                        tempFiles = tempFiles.map(
                            (file) => {
                                if (file.name === filesInfo.name) {
                                    return file;
                                } else {
                                    return file
                                }
                            });
                    } else {
                        tempFiles = [...tempFiles, filesInfo];
                    }
                }
                added = true;
            }
        }
    } else {
        tempFiles = [...tempFiles, filesInfo];
    }
    return getUnique(tempFiles);
}

const getUnique = (array) => {
    var uniqueArray = [];
    if (array.length > 0) {
        for (let value of array) {
            if (uniqueArray.indexOf(value) === -1) {
                uniqueArray.push(value);
            }
        }
    }
    return uniqueArray;
}




export const getFileType = (mimeType) => {
    if (mimeType.includes('image')) {
        return 'image';
    } else if (mimeType.includes('video')) {
        return 'video';
    } else if (mimeType.includes('pdf')) {
        return 'pdf';
    } else if (mimeType.includes('audio')) {
        return 'audio';
    } else {
        return 'other'
    }

}


export const MESSAGE_STATUS = {
    sending: 1,
    sent: 2,
    delivered: 3,
    read: 4
}


export const getESTDYears = () => {
    let years = [];
    for (let i = new Date().getFullYear(); i > 1900; i--) {
        years.push({ label: i, value: i })
    }

    return years;
}

export const geCountries = () => {
    let years = [{
        label: 'India', value: 'India'
    }];


    return years;
}

export const geQRCodeTypes = () => {
    let years = [{
        label: 'Text', value: 'text'
    },
    {
        label: 'Link/URL', value: 'link'
    }];


    return years;
}


export const countries = [
    { id: 'India', name: 'India' }
];

export const states = [
    { id: 'AndhraPradesh', name: 'Andhra Pradesh', countryId: 'India' },
    { id: 'ArunachalPradesh', name: 'Arunachal Pradesh', countryId: 'India' },
    { id: 'Assam', name: 'Assam', countryId: 'India' },
    { id: 'Bihar', name: 'Bihar', countryId: 'India' },
    { id: 'Chhattisgarh', name: 'Chhattisgarh', countryId: 'India' },
    { id: 'Goa', name: 'Goa', countryId: 'India' },
    { id: 'Gujarat', name: 'Gujarat', countryId: 'India' },
    { id: 'Haryana', name: 'Haryana', countryId: 'India' },
    { id: 'HimachalPradesh', name: 'Himachal Pradesh', countryId: 'India' },
    { id: 'Jharkhand', name: 'Jharkhand', countryId: 'India' },
    { id: 'Karnataka', name: 'Karnataka', countryId: 'India' },
    { id: 'Kerala', name: 'Kerala', countryId: 'India' },
    { id: 'MadhyaPradesh', name: 'Madhya Pradesh', countryId: 'India' },
    { id: 'Maharashtra', name: 'Maharashtra', countryId: 'India' },
    { id: 'Manipur', name: 'Manipur', countryId: 'India' },
    { id: 'Meghalaya', name: 'Meghalaya', countryId: 'India' },
    { id: 'Mizoram', name: 'Mizoram', countryId: 'India' },
    { id: 'Nagaland', name: 'Nagaland', countryId: 'India' },
    { id: 'Odisha', name: 'Odisha', countryId: 'India' },
    { id: 'Punjab', name: 'Punjab', countryId: 'India' },
    { id: 'Rajasthan', name: 'Rajasthan', countryId: 'India' },
    { id: 'Sikkim', name: 'Sikkim', countryId: 'India' },
    { id: 'TamilNadu', name: 'Tamil Nadu', countryId: 'India' },
    { id: 'Telangana', name: 'Telangana', countryId: 'India' },
    { id: 'Tripura', name: 'Tripura', countryId: 'India' },
    { id: 'UttarPradesh', name: 'Uttar Pradesh', countryId: 'India' },
    { id: 'Uttarakhand', name: 'Uttarakhand', countryId: 'India' },
    { id: 'WestBengal', name: 'West Bengal', countryId: 'India' },
    { id: 'AndamanAndNicobarIslands', name: 'Andaman and Nicobar Islands', countryId: 'India' },
    { id: 'Chandigarh', name: 'Chandigarh', countryId: 'India' },
    { id: 'DadraAndNagarHaveliAndDamanAndDiu', name: 'Dadra and Nagar Haveli and Daman and Diu', countryId: 'India' },
    { id: 'Delhi', name: 'Delhi', countryId: 'India' },
    { id: 'Lakshadweep', name: 'Lakshadweep', countryId: 'India' },
    { id: 'Puducherry', name: 'Puducherry', countryId: 'India' }
];


export const cities = [
    { id: 'Visakhapatnam', name: 'Visakhapatnam', stateId: 'AndhraPradesh' },
    { id: 'Vijayawada', name: 'Vijayawada', stateId: 'AndhraPradesh' },
    { id: 'Guwahati', name: 'Guwahati', stateId: 'Assam' },
    { id: 'Patna', name: 'Patna', stateId: 'Bihar' },
    { id: 'Raipur', name: 'Raipur', stateId: 'Chhattisgarh' },
    { id: 'Panaji', name: 'Panaji', stateId: 'Goa' },
    { id: 'Ahmedabad', name: 'Ahmedabad', stateId: 'Gujarat' },
    { id: 'Surat', name: 'Surat', stateId: 'Gujarat' },
    { id: 'Gandhinagar', name: 'Gandhinagar', stateId: 'Gujarat' },
    { id: 'Gurgaon', name: 'Gurgaon', stateId: 'Haryana' },
    { id: 'Shimla', name: 'Shimla', stateId: 'HimachalPradesh' },
    { id: 'Ranchi', name: 'Ranchi', stateId: 'Jharkhand' },
    { id: 'Bangalore', name: 'Bangalore', stateId: 'Karnataka' },
    { id: 'Mysore', name: 'Mysore', stateId: 'Karnataka' },
    { id: 'Kochi', name: 'Kochi', stateId: 'Kerala' },
    { id: 'Thiruvananthapuram', name: 'Thiruvananthapuram', stateId: 'Kerala' },
    { id: 'Bhopal', name: 'Bhopal', stateId: 'MadhyaPradesh' },
    { id: 'Indore', name: 'Indore', stateId: 'MadhyaPradesh' },
    { id: 'Mumbai', name: 'Mumbai', stateId: 'Maharashtra' },
    { id: 'Pune', name: 'Pune', stateId: 'Maharashtra' },
    { id: 'Imphal', name: 'Imphal', stateId: 'Manipur' },
    { id: 'Shillong', name: 'Shillong', stateId: 'Meghalaya' },
    { id: 'Aizawl', name: 'Aizawl', stateId: 'Mizoram' },
    { id: 'Kohima', name: 'Kohima', stateId: 'Nagaland' },
    { id: 'Bhubaneswar', name: 'Bhubaneswar', stateId: 'Odisha' },
    { id: 'Cuttack', name: 'Cuttack', stateId: 'Odisha' },
    { id: 'Amritsar', name: 'Amritsar', stateId: 'Punjab' },
    { id: 'Chandigarh', name: 'Chandigarh', stateId: 'Punjab' },
    { id: 'Jaipur', name: 'Jaipur', stateId: 'Rajasthan' },
    { id: 'Jodhpur', name: 'Jodhpur', stateId: 'Rajasthan' },
    { id: 'Gangtok', name: 'Gangtok', stateId: 'Sikkim' },
    { id: 'Chennai', name: 'Chennai', stateId: 'TamilNadu' },
    { id: 'Coimbatore', name: 'Coimbatore', stateId: 'TamilNadu' },
    { id: 'Hyderabad', name: 'Hyderabad', stateId: 'Telangana' },
    { id: 'Agartala', name: 'Agartala', stateId: 'Tripura' },
    { id: 'Lucknow', name: 'Lucknow', stateId: 'UttarPradesh' },
    { id: 'Varanasi', name: 'Varanasi', stateId: 'UttarPradesh' },
    { id: 'Dehradun', name: 'Dehradun', stateId: 'Uttarakhand' },
    { id: 'Kolkata', name: 'Kolkata', stateId: 'WestBengal' },
    { id: 'PortBlair', name: 'Port Blair', stateId: 'AndamanAndNicobarIslands' },
    { id: 'Chandigarh2', name: 'Chandigarh', stateId: 'Chandigarh' }, // Renamed to avoid duplicate ids
    { id: 'Silvassa', name: 'Silvassa', stateId: 'DadraAndNagarHaveliAndDamanAndDiu' },
    { id: 'NewDelhi', name: 'New Delhi', stateId: 'Delhi' },
    { id: 'Kavaratti', name: 'Kavaratti', stateId: 'Lakshadweep' },
    { id: 'Puducherry', name: 'Puducherry', stateId: 'Puducherry' }
];


export const States = ['Andaman and Nicobar', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh',
    'Chhattisgarh', 'Delhi', 'Daman and Diu', 'Dadra and Nagar Haveli', 'Goa', 'Gujarat', 'Haryana',
    'Himachal Pradesh', 'Jammu and Kashmir', 'Ladakh', 'Lakshadweep', 'Jharkhand', 'Karnataka', 'Kerala',
    'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Odisha', 'Mizoram', 'Orissa', 'Punjab', 'Rajasthan',
    'Sikkim', 'Tamilnadu', 'Telangana', 'Tripura', 'Uttarkhand', 'Uttar Pradesh', 'West Bengal', 'Puducherry'];

export const StatesAndDirstricts = [
    { State: 'Andaman and Nicobar', Districts: ['Port Blair'] },
    {
        State: 'Andhra Pradesh', Districts: ['Anantapur', 'Chittoor', 'East Godavari', 'Guntur', 'Kadapa', 'Krishna', 'Kurnool',
            'Prakasam', 'Sri Potti Sriramulu Nellore', 'Srikakulam', 'Visakhapatnam', 'Vizianagaram', 'West Godavari']
    },
    {
        State: 'Arunachal Pradesh',
        Districts: ['Hawai', 'Changlang', 'East Kameng', 'East Siang', 'Kamle', 'Kra Daadi', 'Kurung Kumey', 'Lepa Rada', 'Lohit',
            'Longding', 'Lower Dibang Valley', 'Lower Siang', 'Lower Subansiri', 'Namsai', 'Pakke-Kessang', 'Papum Pare', 'Shi Yomi',
            'Siang', 'Tawang', 'Tirap', 'Upper Dibang Valley', 'Upper Siang', 'Upper Subansiri', 'West Kameng', 'West Siang']
    },
    {
        State: 'Assam', Districts: ['Baksa', 'Barpeta', 'Bishwanath', 'Bongaigaon', 'Cachar', 'Charaideo', 'Chirang', 'Darrang', 'Dhemaji',
            'Dhubri', 'Dibrugarh', 'Dima Hasao', 'Goalpara', 'Golaghat', 'Hailakandi', 'Hojai', 'Jorhat', 'Kamrup', 'Kamrup Metropolitan',
            'Karbi Anglong', 'Karimganj', 'Kokrajhar', 'Lakhimpur', 'Majuli', 'Morigaon', 'Nagaon', 'Nalbari', 'Sivasagar', 'South Salmara',
            'Sonitpur', 'Tinsukia', 'Udalguri', 'West Karbi Anglong']
    },
    {
        State: 'Bihar', Districts: ['Araria', 'Arwal', 'Aurangabad', 'Banka', 'Begusarai', 'Bhagalpur', 'Bhojpur', 'Buxar', 'Darbhanga',
            'East Champaran', 'Gaya', 'Gopalganj', 'Jamui', 'Jehanabad', 'Kaimur', 'Katihar', 'Khagaria', 'Kishanganj', 'Lakhisarai', 'Madhepura',
            'Madhubani', 'Munger', 'Muzaffarpur', 'Nalanda', 'Nawada', 'Patna', 'Purnia', 'Rohtas', 'Saharsa', 'Samastipur', 'Saran', 'Sheikhpura',
            'Sheohar', 'Sitamarhi', 'Siwan', 'Supaul', 'Vaishali', 'West Champaran']
    },
    {
        State: 'Chhattisgarh', Districts: ['Balod', 'Baloda Bazar', 'Balrampur', 'Bastar', 'Bemetara', 'Bijapur', 'Bilaspur', 'Dantewada',
            'Dhamtari', 'Durg', 'Gariaband', 'Gaurela-Pendra-Marwahi', 'Janjgir-Champa', 'Jashpur', 'Kabirdham', 'Kanker', 'Kondagaon', 'Korba',
            'Koriya', 'Mahasamund', 'Mungeli', 'Narayanpur', 'Raigarh', 'Raipur', 'Rajnandgaon', 'Sukma', 'Surajpur', 'Surguja']
    },
    { State: 'Goa', Districts: ['North Goa', 'South Goa'] },

    {
        State: 'Gujarat', Districts: ['Ahmedabad', 'Amreli', 'Anand', 'Aravalli', 'Banaskantha', 'Bharuch', 'Bhavnagar', 'Botad',
            'Chhota Udepur', 'Dahod', 'Dang', 'Devbhoomi Dwarka', 'Gandhinagar', 'Gir Somnath', 'Jamnagar', 'Junagadh', 'Kheda', 'Kutch',
            'Mahisagar', 'Mehsana', 'Morbi', 'Narmada', 'Navsari', 'Panchmahal', 'Patan', 'Porbandar', 'Rajkot', 'Sabarkantha', 'Surat',
            'Surendranagar', 'Tapi', 'Vadodara', 'Valsad']
    },

    {
        State: 'Haryana', Districts: ['Ambala', 'Bhiwani', 'Charkhi Dadri', 'Faridabad', 'Fatehabad', 'Gurugram', 'Hissar', 'Jhajjar',
            'Jind', 'Kaithal', 'Karnal', 'Kurukshetra', 'Mahendragarh', 'Nuh', 'Palwal', 'Panchkula', 'Panipat', 'Rewari', 'Rohtak', 'Sirsa',
            'Sonipat', 'Yamuna Nagar']
    },

    {
        State: 'Himachal Pradesh', Districts: ['Bilaspur', 'Chamba', 'Hamirpur', 'Kangra', 'Kinnaur', 'Kullu', 'Lahaul and Spiti',
            'Mandi', 'Shimla', 'Sirmaur', 'Solan', 'Una']
    },

    {
        State: 'Jharkhand', Districts: ['Bokaro', 'Chatra', 'Deoghar', 'Dhanbad', 'Dumka', 'East Singhbhum', 'Garhwa', 'Giridih',
            'Godda', 'Gumla', 'Hazaribag', 'Jamtara', 'Khunti', 'Koderma', 'Latehar', 'Lohardaga', 'Pakur', 'Palamu', 'Ramgarh', 'Ranchi',
            'Sahibganj']
    },

    {
        State: 'Karnataka', Districts: ['Bagalkot', 'Ballari', 'Belagavi', 'Bengaluru Rural', 'Bengaluru Urban', 'Bidar', 'Chamarajnagar',
            'Chikkaballapur', 'Chikkamagaluru', 'Chitradurga', 'Dakshina Kannada', 'Davanagere', 'Dharwad', 'Gadag', 'Hassan', 'Haveri',
            'Kalaburagi', 'Kodagu', 'Kolar', 'Koppal', 'Mandya', 'Mysuru', 'Raichur', 'Ramanagara', 'Shivamogga', 'Tumakuru', 'Udupi',
            'Uttara Kannada', 'Vijayapura', 'Yadgir']
    },

    {
        State: 'Kerala', Districts: ['Alappuzha', 'Ernakulam', 'Idukki', 'Kannur', 'Kasaragod', 'Kollam', 'Kottayam', 'Kozhikode',
            'Malappuram', 'Palakkad', 'Pathanamthitta', 'Thrissur', 'Thiruvananthapuram', 'Wayanad']
    },

    {
        State: 'Madhya Pradesh', Districts: ['Agar Malwa', 'Alirajpur', 'Anuppur', 'Ashok Nagar', 'Balaghat', 'Barwani', 'Betul',
            'Bhind', 'Bhopal', 'Burhanpur', 'Chhatarpur', 'Chhindwara', 'Damoh', 'Datia', 'Dewas', 'Dhar', 'Dindori', 'Guna', 'Gwalior', 'Harda',
            'Hoshangabad', 'Indore', 'Jabalpur', 'Jhabua', 'Katni', 'Khandwa (East Nimar)', 'Khargone (West Nimar)', 'Mandla', 'Mandsaur', 'Morena',
            'Narsinghpur', 'Neemuch', 'Niwari', 'Panna', 'Raisen', 'Rajgarh', 'Ratlam', 'Rewa', 'Sagar', 'Satna', 'Sehore', 'Seoni', 'Shahdol',
            'Shajapur', 'Sheopur', 'Shivpuri', 'Sidhi', 'Singrauli', 'Tikamgarh', 'Ujjain', 'Umaria', 'Vidisha']
    },

    {
        State: 'Maharashtra', Districts: ['Ahmednagar', 'Akola', 'Amravati', 'Aurangabad', 'Beed', 'Bhandara', 'Buldhana', 'Chandrapur',
            'Dhule', 'Gadchiroli', 'Gondia', 'Hingoli', 'Jalgaon', 'Jalna', 'Kolhapur', 'Latur', 'Mumbai City', 'Mumbai suburban', 'Nanded',
            'Nandurbar', 'Nagpur', 'Nashik', 'Osmanabad', 'Palghar', 'Parbhani', 'Pune', 'Raigad', 'Ratnagiri', 'Sangli', 'Satara', 'Sindhudurg',
            'Solapur', 'Thane', 'Wardha', 'Washim', 'Yavatmal']
    },

    {
        State: 'Manipur', Districts: ['Bishnupur', 'Chandel', 'Churachandpur', 'Imphal East', 'Imphal West', 'Jiribam', 'Kakching',
            'Kamjong', 'Kangpokpi', 'Noney', 'Pherzawl', 'Senapati', 'Tamenglong', 'Tengnoupal', 'Thoubal', 'Ukhrul']
    },
    {
        State: 'Meghalaya', Districts: ['East Garo Hills', 'East Khasi Hills', 'East Jaintia Hills', 'North Garo Hills', 'Ri Bhoi',
            'South Garo Hills', 'South West Garo Hills', 'South West Khasi Hills', 'West Jaintia Hills', 'West Garo Hills', 'West Khasi Hills']
    },
    { State: 'Mizoram', Districts: ['Aizawl', 'Champhai', 'Kolasib', 'Lawngtlai', 'Lunglei', 'Mamit', 'Saiha', 'Serchhip'] },
    {
        State: 'Nagaland', Districts: ['Dimapur', 'Kiphire', 'Kohima', 'Longleng', 'Mokokchung', 'Mon', 'Noklak', 'Peren', 'Phek', 'Tuensang',
            'Wokha', 'Zunheboto']
    },

    {
        State: 'Odisha', Districts: ['Angul', 'Boudh (Bauda)', 'Bhadrak', 'Balangir', 'Bargarh (Baragarh)', 'Balasore', 'Cuttack',
            'Debagarh (Deogarh)', 'Dhenkanal', 'Ganjam', 'Gajapati', 'Jharsuguda', 'Jajpur', 'Jagatsinghpur', 'Khordha', 'Kendujhar (Keonjhar)',
            'Kalahandi', 'Kandhamal', 'Koraput', 'Kendrapara', 'Malkangiri', 'Mayurbhanj', 'Nabarangpur', 'Nuapada', 'Nayagarh', 'Puri', 'Rayagada',
            'Sambalpur', 'Subarnapur (Sonepur)', 'Sundargarh']
    },

    {
        State: 'Punjab', Districts: ['Amritsar', 'Barnala', 'Bathinda', 'Firozpur', 'Faridkot', 'Fatehgarh Sahib', 'Fazilka',
            'Gurdaspur', 'Hoshiarpur', 'Jalandhar', 'Kapurthala', 'Ludhiana', 'Mansa', 'Moga', 'Sri Muktsar Sahib', 'Pathankot', 'Patiala',
            'Rupnagar', 'Sahibzada Ajit Singh Nagar', 'Sangrur', 'Shahid Bhagat Singh Nagar', 'Tarn Taran']
    },

    {
        State: 'Rajasthan', Districts: ['Ajmer', 'Alwar', 'Bikaner', 'Barmer', 'Banswara', 'Bharatpur', 'Baran', 'Bundi', 'Bhilwara',
            'Churu', 'Chittorgarh', 'Dausa', 'Dholpur', 'Dungarpur', 'Ganganagar', 'Hanumangarh', 'Jhunjhunu', 'Jalore', 'Jodhpur', 'Jaipur',
            'Jaisalmer', 'Jhalawar', 'Karauli', 'Kota', 'Nagaur', 'Pali', 'Pratapgarh', 'Rajsamand', 'Sikar', 'Sawai Madhopur', 'Sirohi', 'Tonk',
            'Udaipur']
    },

    { State: 'Sikkim', Districts: ['East Sikkim', 'North Sikkim', 'South Sikkim', 'West Sikkim'] },

    {
        State: 'Tamilnadu', Districts: ['Ariyalur', 'Chengalpattu', 'Chennai', 'Coimbatore', 'Cuddalore', 'Dharmapuri', 'Dindigul',
            'Erode', 'Kallakurichi', 'Kanchipuram', 'Kanyakumari', 'Karur', 'Krishnagiri', 'Madurai', 'Nagapattinam', 'Nilgiris', 'Namakkal',
            'Perambalur', 'Pudukkottai', 'Ramanathapuram', 'Ranipet', 'Salem', 'Sivaganga', 'Tenkasi', 'Tirupur', 'Tiruchirappalli', 'Theni',
            'Tirunelveli', 'Thanjavur', 'Thoothukudi', 'Tirupattur', 'Tiruvallur', 'Tiruvarur', 'Tiruvannamalai', 'Vellore', 'Viluppuram',
            'Virudhunagar']
    },

    {
        State: 'Telangana', Districts: ['Adilabad', 'Komaram Bheem', 'Bhadradri Kothagudem', 'Hyderabad', 'Jagtial', 'Jangaon',
            'Jayashankar Bhupalpally', 'Jogulamba Gadwal', 'Kamareddy', 'Karimnagar', 'Khammam', 'Mahabubabad', 'Mahbubnagar', 'Mancherial',
            'Medak', 'Medchal-Malkajgiri', 'Mulugu', 'Nalgonda', 'Narayanpet', 'Nagarkurnool', 'Nirmal', 'Nizamabad', 'Peddapalli',
            'Rajanna Sircilla', 'Ranga Reddy', 'Sangareddy', 'Siddipet', 'Suryapet', 'Vikarabad', 'Wanaparthy', 'Warangal Urban',
            'Warangal Rural', 'Yadadri Bhuvanagiri']
    },

    {
        State: 'Tripura', Districts: ['Dhalai', 'Gomati', 'Khowai', 'North Tripura', 'Sepahijala', 'South Tripura', 'Unokoti',
            'West Tripura']
    },

    {
        State: 'Uttar Pradesh', Districts: ['Agra', 'Aligarh', 'Allahabad', 'Ambedkar Nagar', 'Amethi', 'Amroha', 'Auraiya',
            'Azamgarh', 'Bagpat', 'Bahraich', 'Ballia', 'Balrampur', 'Banda', 'Barabanki', 'Bareilly', 'Basti', 'Bhadohi', 'Bijnor', 'Budaun',
            'Bulandshahr', 'Chandauli', 'Chitrakoot', 'Deoria', 'Etah', 'Etawah', 'Faizabad', 'Farrukhabad', 'Fatehpur', 'Firozabad',
            'Gautam Buddh Nagar', 'Ghaziabad', 'Ghazipur', 'Gonda', 'Gorakhpur', 'Hamirpur', 'Hapur', 'Hardoi', 'Hathras', 'Jalaun',
            'Jaunpur', 'Jhansi', 'Kannauj', 'Kanpur Dehat', 'Kanpur Nagar', 'Kasganj', 'Kaushambi', 'Kushinagar', 'Lakhimpur Kheri',
            'Lalitpur', 'Lucknow', 'Maharajganj', 'Mahoba', 'Mainpuri', 'Mathura', 'Mau', 'Meerut', 'Mirzapur', 'Moradabad', 'Muzaffarnagar',
            'Pilibhit', 'Pratapgarh', 'Raebareli', 'Rampur', 'Saharanpur', 'Sambhal', 'Sant Kabir Nagar', 'Shahjahanpur', 'Shamli',
            'Shravasti', 'Siddharthnagar', 'Sitapur', 'Sonbhadra', 'Sultanpur', 'Unnao', 'Varanasi']
    },

    {
        State: 'Uttarkhand', Districts: ['Almora', 'Bageshwar', 'Chamoli', 'Champawat', 'Dehradun', 'Haridwar', 'Nainital',
            'Pauri Garhwal', 'Pithoragarh', 'Rudraprayag', 'Tehri Garhwal', 'Udham Singh Nagar', 'Uttarkashi']
    },

    {
        State: 'West Bengal', Districts: ['Alipurduar', 'Bankura', 'Paschim Bardhaman', 'Purba Bardhaman', 'Birbhum', 'Cooch Behar',
            'Dakshin Dinajpur', 'Darjeeling', 'Hooghly', 'Howrah', 'Jalpaiguri', 'Jhargram', 'Kalimpong', 'Kolkata', 'Maldah', 'Murshidabad',
            'Nadia', 'North 24 Parganas', 'Paschim Medinipur', 'Purba Medinipur', 'Purulia', 'South 24 Parganas', 'Uttar Dinajpur']
    },

    {
        State: 'Andaman and Nicobar', Districts: ['Nicobar',
            'North and Middle Andaman',
            'South Andaman']
    },
    { State: 'Chandigarh', Districts: ['Chandigarh'] },
    { State: 'Dadra and Nagar Haveli', Districts: ['Dadra and Nagar Haveli'] },
    { State: 'Daman and Diu', Districts: ['Daman', 'Diu'] },
    {
        State: 'Jammu and Kashmir', Districts: ['Anantnag', 'Bandipora', 'Baramulla', 'Badgam', 'Doda', 'Ganderbal', 'Jammu',
            'Kathua', 'Kishtwar', 'Kulgam', 'Kupwara', 'Poonch', 'Pulwama', 'Rajouri', 'Ramban', 'Reasi', 'Samba', 'Shopian', 'Srinagar',
            'Udhampur']
    },
    { State: 'Ladakh', Districts: ['Kargil', 'Leh'] },
    { State: 'Lakshadweep', Districts: ['Lakshadweep'] },
    {
        State: 'Delhi', Districts: ['Central Delhi', 'East Delhi', 'New Delhi', 'North Delhi', 'North East Delhi',
            'North West Delhi', 'Shahdara', 'South Delhi', 'South East Delhi', 'South West Delhi', 'West Delhi']
    },
    { State: 'Puducherry', Districts: ['Karaikal', 'Mahé', 'Pondicherry', 'Yanam'] }
];


export const getMeluhaBoard = (boards) => {
    return boards.filter(b => b.boardId == MELUHA_BOARD_ID)
}

export const getActiveBoards = (boards, withoutMeluha) => {
    let activeBoards = _.cloneDeep(boards);

    for (let i = activeBoards.length - 1; i >= 0; i--) {
        if (!activeBoards[i].boardActivity) {
            activeBoards.splice(i, 1);
        } else {
            if (activeBoards[i].classes && activeBoards[i].classes.length) {
                for (let j = activeBoards[i].classes.length - 1; j >= 0; j--) {
                    if (!activeBoards[i].classes[j].isActivity) {
                        activeBoards[i].classes.splice(j, 1);
                    } else {
                        if (activeBoards[i].classes[j].groups && activeBoards[i].classes[j].groups.length) {
                            for (let k = activeBoards[i].classes[j].groups.length - 1; k >= 0; k--) {
                                if (!activeBoards[i].classes[j].groups[k].isGroupActivity) {
                                    activeBoards[i].classes[j].groups.splice(k, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    if (withoutMeluha) {
        activeBoards = activeBoards.filter(b => b.boardId !== MELUHA_BOARD_ID)
    }

    return activeBoards;
}


export const getUsersWithRoles = (data) => {
    const { userData, roleData } = data;

    if (userData) {
        return userData.data.map((rec, index) => {
            const user = {
                _id: rec._id,
                userId: rec.user_id,
                ...rec.user_info
            };
            if (user.roles && roleData && roleData.data.length > 0) {
                user.roles = user.roles
                    .map((roleId) => {
                        const role = roleData.data.find((item) => item._id === roleId);
                        if (role) {
                            return role.roleName;
                        }
                        return '';
                    })
                    .join(',');
            } else {
                user.roles = '-';
            }

            user.slNo = index + 1;
            return user;
        });
    }
    return [];
};


export const warningDailogInit = {
    visible: false,
    headerMsg: '',
    message: ''
}


export const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}

export const isDigit = (val) => {
    return /[^0-9]/.test(val);
}
export const handleDownload = (url, filenameWithExtension) => {


    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {

            const url = window.URL.createObjectURL(
                new Blob([res.data]),
            );

            // const extension = name.slice(name.lastIndexOf('.')).split('.')[1];
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                filenameWithExtension
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            // fileDownload(res.data, filename)
        }).catch(e => {


        })
}


// export const trimAll = (object) => {
//     if (object && Object.keys(object).length) {
//         Object.keys(object).forEach(key => {
//             if (typeof object[key] == 'string') {
//                 object[key] = object[key].trim();
//             }


//             if (object[key].constructor == Object) {
//                 object[key] = trimAll(object[key])
//             }

//             if (object[key].constructor == Array) {
//                 object[key] = object[key].map((a) => trimAll(a));
//             }
//         });
//     }
//     return object

// }


export const trimObj = (obj) => {
    if (obj != null && !Array.isArray(obj) && typeof obj != 'object') return obj;
    return Object.keys(obj).reduce(function (acc, key) {
        acc[key.trim()] = typeof obj[key] == 'string' ? obj[key].trim() : (obj[key] == null || obj[key] == undefined) ? obj[key] : trimObj(obj[key]);
        return acc;
    }, Array.isArray(obj) ? [] : {});
}


export const isLengthValid = (previousText, key) => {
    if (previousText.length == 5 && previousText.includes('-') && !previousText.includes('.')) {
        if (key == '.') {
            return true;
        } else {
            return false;
        }

    } else if (previousText.length == 8 && previousText.includes('-') && previousText.includes('.')) {
        return false;
    } else if (previousText.length == 4 && !previousText.includes('-') && !previousText.includes('.')) {
        if (key == '.') {
            return true;
        } else {
            return false;
        }
    } else if (previousText.length == 7 && !previousText.includes('-') && previousText.includes('.')) {
        return false;
    } else {
        return true;
    }
}

export const isValidNum = (previousText, key) => {
    if (isLengthValid(previousText, key)) {
        if (key == '-') {
            if (previousText.length == 0) {
                return true;
            } else {
                return false;
            }
        } else if (key != '.') {
            if (previousText.indexOf('.') > -1) {
                if (previousText.indexOf('.') + 3 != previousText.length) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else if (key == '.') {
            if (previousText.indexOf('.') > -1) {
                return false;
            } else {
                return true;
            }
        }
    } else {
        return false;
    }

}

export const generateNumKey = (v) => {
    let d = Number(v).toFixed(2).toString();
    let str = '';
    if (d.length < d.includes('-') ? 8 : 7) {
        for (let i = 0; i < (d.includes('-') ? 8 : 7) - d.length; i++) {
            str += '0';
        }
    }
    return d.includes('-') ? '-' + str + d.slice(1) : str + d;
}



export const getServerTime = () => {
    let time = localStorage.getItem('serverTime1');
    if (time !== undefined) {
        var date = new Date();
        date.setTime(Number(time));
        return date;
    } else {
        return new Date();
    }
}



export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export const getRegex = (txt) => {
    return new RegExp(`${txt}`, 'g');
}


export const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    if (res == null)
        return false;
    else
        return true;
}




function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}


export const exportToCSV = (items, headers, fileTitle) => {

    // var headers = {
    //     model: 'Phone Model'.replace(/,/g, ''), // remove commas to avoid errors
    //     chargers: "Chargers",
    //     cases: "Cases",
    //     earphones: "Earphones"
    // };

    // //   var  itemsNotFormatted = [
    // //         {
    // //             model: 'Samsung S7',
    // //             chargers: '55',
    // //             cases: '56',
    // //             earphones: '57',
    // //             scratched: '2'
    // //         },
    // //         {
    // //             model: 'Pixel XL',
    // //             chargers: '77',
    // //             cases: '78',
    // //             earphones: '79',
    // //             scratched: '4'
    // //         },
    // //         {
    // //             model: 'iPhone 7',
    // //             chargers: '88',
    // //             cases: '89',
    // //             earphones: '90',
    // //             scratched: '6'
    // //         }
    // //     ];

    // //     var itemsFormatted = [];

    // // format the data
    // itemsNotFormatted.forEach((item) => {
    //     itemsFormatted.push({
    //         model: item.model.replace(/,/g, ''), // remove commas to avoid errors,
    //         chargers: item.chargers,
    //         cases: item.cases,
    //         earphones: item.earphones
    //     });
    // });

    // var fileTitle = 'orders'; // or 'my-unique-title'

    exportCSVFile(headers, items, fileTitle);
}




export const calcuateFee = (fees, amount) => {
    var feeDetails = _.cloneDeep(fees);
    var amountPaying = amount;
    feeDetails.forEach(feeD => {
        if (feeD.paidAmount == undefined) {
            feeD.paidAmount = 0;
        }
        if (feeD.balance == undefined) {
            feeD.balance = parseInt(feeD.amount);
        }


        feeD.balance = parseInt(feeD.balance);

        if (amountPaying > 0) {
            if (feeD.balance > amountPaying) {
                let paying = amountPaying
                feeD.paidAmount += amountPaying;


                feeD.balance = feeD.balance - paying;
                amountPaying = 0;
            } else {
                let paying = feeD.balance
                feeD.paidAmount += feeD.balance;
                feeD.balance = feeD.balance - paying;
                amountPaying = amountPaying - paying;
            }

            var amountPayingInThisFee = feeD.paidAmount;
            feeD.installments.forEach(inst => {
                if (inst.paidAmount == undefined) {
                    inst.paidAmount = 0;
                }
                if (inst.balance == undefined) {
                    inst.balance = parseInt(inst.amount);
                }


                if (inst.amount > amountPayingInThisFee) {
                    inst.paidAmount = amountPayingInThisFee;
                    inst.balance = inst.amount - amountPayingInThisFee;
                    amountPayingInThisFee = 0;
                } else {
                    inst.paidAmount = inst.amount;
                    inst.balance = inst.amount - inst.paidAmount;
                    amountPayingInThisFee = amountPayingInThisFee - inst.amount;
                }
            })
        }
    });
    return feeDetails;
}


export const generateFeeReciept = (fees, amount) => {
    var feeDetails = _.cloneDeep(fees);
    var amountPaying = amount;
    feeDetails.forEach(feeD => {
        if (feeD.paidAmount == undefined) {
            feeD.paidAmount = 0;
        }
        if (feeD.balance == undefined) {
            feeD.balance = parseInt(feeD.amount);
        }


        feeD.balance = parseInt(feeD.balance);

        if (amountPaying > 0) {
            if (feeD.balance > amountPaying) {
                let paying = amountPaying
                feeD.paidAmount = amountPaying;


                feeD.balance = feeD.balance - paying;
                amountPaying = 0;
            } else {
                let paying = feeD.balance
                feeD.paidAmount = feeD.balance;
                feeD.balance = feeD.balance - paying;
                amountPaying = amountPaying - paying;
            }

            var amountPayingInThisFee = feeD.paidAmount;
            feeD.installments.forEach(inst => {
                if (inst.paidAmount == undefined) {
                    inst.paidAmount = 0;
                }
                if (inst.balance == undefined) {
                    inst.balance = parseInt(inst.amount);
                }


                if (inst.amount > amountPayingInThisFee) {
                    inst.paidAmount = amountPayingInThisFee;
                    inst.balance = inst.amount - amountPayingInThisFee;
                    amountPayingInThisFee = 0;
                } else {
                    inst.paidAmount = inst.amount;
                    inst.balance = inst.amount - inst.paidAmount;
                    amountPayingInThisFee = amountPayingInThisFee - inst.amount;
                }
            })
        }
    });
    return feeDetails;
}

export const getLanguageNameByCode = (code) => {

    switch (code) {
        case 'en':
            return 'English';
        case 'hi':
            return 'Hindi';
        case 'te':
            return 'Telugu';
        case 'od':
            return 'Odiya';
        default:
            return code || '';

    }

}


