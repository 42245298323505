import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './ClassetComponents.scss';  // Import the CSS file
import { Tooltip } from 'primereact/tooltip';

const ClassetInputText = ({ icon, height, width, className, title, formHelperError, tooltipInfo, disabled, infoIcon, placeholder, label, labelClassName, required, isPassword, ...props }) => {
    const defaultHeight = height ? height : "48px";
    const defaultWidth = width ? width : "343px";
    const [focused, setFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const inputRef = React.useRef(null);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <>
            <label htmlFor={title} className={`ma-label-s2 font-serif font-semibold text-900 text-lg ${labelClassName}`}>{label}
                {required && <span className="ma-required">*</span>}
            </label>
            <div className="flex">
                <div style={{ height: defaultHeight, width: defaultWidth }}
                    className={classNames('p-inputgroup', 'custom-input-group', `border-300 shadow-2 border-round-lg flex justify-content-center align-items-center ${formHelperError && 'border-red-400 border-2'}`, { 'custom-input-focused': focused })}
                    onClick={handleFocus}
                >
                    <span className={icon ? " p-inputgroup-addon pl-3" : ""}>
                        {icon}
                    </span>
                    <InputText id={title} type={isPassword && !isPasswordVisible ? "password" : "text"} className={`${className}  no-border`} placeholder={placeholder} ref={inputRef} {...props} onFocus={handleFocus} disabled={disabled !== null ? disabled : false} onBlur={handleBlur} />
                    {isPassword &&
                        <button type="button" className="p-inputgroup-addon" onClick={togglePasswordVisibility}>
                            <i className={`pi ${isPasswordVisible ? "pi-eye-slash" : "pi-eye"}`}></i>
                        </button>
                    }
                </div>
                {(tooltipInfo != undefined || tooltipInfo != null) &&
                    <div className="">
                        <Tooltip target={`.${title}`}>
                            <div className='flex justify-content-center align-items-center'>
                                <i className="pi pi-info-circle mr-3"></i>
                                {tooltipInfo}
                            </div>
                        </Tooltip>

                        <i className={`${title} pi pi-info-circle mt-2 font-bold text-xl ml-1`}
                            data-pr-position="right"
                            data-pr-at="right+5 center"
                            data-pr-my="left center-2"
                            style={{ fontSize: '2rem', cursor: 'pointer' }}>
                        </i>
                    </div>
                }
            </div>
        </>
    );
};

export default ClassetInputText;
