import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import Authentication from '../../session';
import { baseUrlAdmin, primaryColor } from '../../../store/apiConstants';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { AddIconInGrade, BoardsIconActive, BoardsIconInActive, BookIcon, ClassetEditIcon, GradesIcon, GradesIconActive, SubjectIcon, TopicIcon } from '../../svgIcons';
import { AcademicSessionCalIcon, AssignFormIcon, CodeLinkIcon, } from '../../svgIcons';
import { getFormFields, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../utile/formHelper'
import { Dropdown } from 'primereact/dropdown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import Service from '../../../services';
import { trimObj } from '../../../utile';
import { getBoardsData } from '../../../store/actions';
import { sortCurriculam, userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { cloneDeep } from 'lodash';
import uuidv4 from 'uuid/v4';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';



const createSubjectFields = require('./subjectFields.json');
const createChapterFields = require('./ChapterFields.json');
const createTopicFields = require('./topicFields.json');


class SubjectManagement extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createSubjectFields);
        this.ChapterformFields = getFormFields(createChapterFields);
        this.TopicformFields = getFormFields(createTopicFields);
        this.state = {
            isDialogOpenForCreateSubject: false,
            isDialogOpenForCreateChapter: false,
            isDialogOpenForCreateTopic: false,
            boardId: null,
            classId: null,
            groupId: null,
            curriculumInfo: {},
            selectedSubject: {

            },
            selectedSubjectIndex: null,
            selectedChapterIndex: null,
            selectedChapter: {

            },
            selectedTopic: {

            },
            subject: this.formFields.data,
            chapter: this.ChapterformFields.data,
            topic: this.TopicformFields.data,
            formValidations: this.formFields.formValidations,
            chapterFormValidations: this.ChapterformFields.formValidations,
            topicFormValidations: this.TopicformFields.formValidations,
            IsDailogBoxopenForYearChange: false,
            academicYearOne: false,
            academicYearTwo: false,
            academicYearThree: false,
            title: '',
            date: null,
            description: '',
            edit: false,
            selectedBoardName: '',
            selectedClassName: ''

        };
        this.service = new Service()
    }

    componentDidMount() {
        this.props.getBoardsData(true);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.boardId == null && this.props.boards.length) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const _classes = [];
            const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === this.props.boards[0].boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
            }
            this.setState({
                boardId: this.props.boards[0]?.boardId,
                selectedBoardName: this.props.boards[0]?.boardName,
                classes: _classes,
            }, () => this.onChangeClass(_classes[0]?.value))
        }
    }
    getGradesBySelectedBoard = (board) => {
        this.setState({ selectedBoard: board })
    }
    getSectionssBySelectedGrade = (grade) => {
        this.setState({ selectedGrade: grade })
    }
    getChaptersBySelectedsubject = (subject, index) => {
        this.setState({ selectedSubject: subject, selectedSubjectIndex: index })

    }
    getTopicsBySelectedChapter = (chapter, index) => {
        this.setState({ selectedChapter: chapter, selectedChapterIndex: index })

    }
    getSubTopicsBySelectedTopic = (topic) => {
        this.setState({ selectedTopic: topic })

    }
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], classId: null, curriculumInfo: null,
            isShowSubjects: false,
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
        }
        let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName
        this.setState({
            classes: _classes,
            boardId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedBoardName: selectedBoardName
        });

    };

    onChangeClass = (classId) => {
        let selectedClass = this.state.classes?.find((classd) => classd.value == classId)

        this.setState({
            classId: classId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedClassName: selectedClass?.label,
            groupId: selectedClass?.groupId
        }, () => this.getCurriculumData());
    };

    getCurriculumData = () => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
            this.service.get(url, true).then((data) => {
                const etag = data && data.etag;
                if (data && data.res && data.res.Item) {
                    let sortedData = sortCurriculam(data.res.Item)
                    this.setState({
                        curriculumInfo: sortedData,
                        selectedSubject: sortedData?.subjects[0],
                        selectedSubjectIndex: 0,
                        selectedChapter: sortedData?.subjects[0]?.chapters[0],
                        selectedChapterIndex: 0,
                        isLoading: false,
                        etag
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });
        } else {
            this.setState({
                isLoading: false,

            })
        }
    };

    addOrUpdateCurriculam = (payload) => {
        this.setState({
            isLoading: true,
        });
        let url = `${baseUrlAdmin}/subject`
        let headers = {
            "If-Match": this.state.etag,
        }
        console.log(payload)

        this.service.post(url, payload, true, headers).then(res => {

            if (res?.status && res?.res?.success) {
                this.state.edit ? this.toast.show({ severity: 'success', summary: 'Curriculam Updated Successfully', detail: ``, life: 3000 }) :
                    this.toast.show({ severity: 'success', summary: 'Curriculam Created Successfully', detail: ``, life: 3000 });
                this.setState({
                    isLoading: false,
                    isDialogOpenForCreateSubject: false,
                    isDialogOpenForCreateChapter: false,
                    isDialogOpenForCreateTopic: false,
                    subject: {},
                    chapter: {},
                    topic: {},
                    curriculumInfo: {},
                    // selectedSubject: {
                    // },
                    // selectedSubjectIndex: null,
                    // selectedChapter: {
                    // },
                    // selectedTopic: {
                    // },
                    edit: false
                }, () => { this.getCurriculumData() })
            } else {
                this.setState({
                    isLoading: false,
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {

            console.log(err, 'err')
            this.setState({
                isLoading: false,
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
    }

    onSubmitCreateOrUpdatedSubject = () => {
        const formStatus = isFormValid(createSubjectFields, this.formFields.formValidations, trimObj(this.state.subject));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            let subjectPayload = cloneDeep(this.state.subject)
            let curriculumData = cloneDeep(this.state.curriculumInfo)
            if (this.state.edit) {
                curriculumData.subjects?.forEach(subject => {
                    if (subject.subjectId == subjectPayload.subjectId) {
                        subject.subjectName = subjectPayload.subjectName;
                        subject.subjectCode = subjectPayload.subjectCode;
                        subject.priority = Number(subjectPayload.priority);
                    }
                })
                console.log(curriculumData)

                this.addOrUpdateCurriculam(curriculumData)
            } else {
                subjectPayload.subjectId = uuidv4();
                subjectPayload.isSubjectActive = true
                subjectPayload.priority = Number(subjectPayload.priority)
                subjectPayload.chapters = []
                curriculumData.subjects.push(subjectPayload)
                this.addOrUpdateCurriculam(curriculumData)
            }
        }
        // this.setState({ isDialogOpenForCreateSubject: false })
    }

    onSubmitCreateOrUpdatedChapter = () => {
        const formStatus = isFormValid(createChapterFields, this.ChapterformFields.formValidations, trimObj(this.state.chapter));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                chapterFormValidations: formStatus.formValidations,
            });
        } else {
            let chapterPayload = cloneDeep(this.state.chapter)
            let curriculumData = cloneDeep(this.state.curriculumInfo)
            if (this.state.edit) {
                curriculumData.subjects[this.state.selectedSubjectIndex]?.chapters.forEach(chapter => {
                    if (chapter.chapterId == chapterPayload.chapterId) {
                        chapter.chapterName = chapterPayload.chapterName;
                        chapter.chapterCode = chapterPayload.chapterCode;
                        chapter.priority = chapterPayload.priority;
                    }
                })
                console.log(curriculumData)

                this.addOrUpdateCurriculam(curriculumData)
            } else {
                chapterPayload.chapterId = uuidv4();
                chapterPayload.isChapterActive = true
                chapterPayload.topics = []
                chapterPayload.priority = Number(chapterPayload.priority)
                curriculumData.subjects[this.state.selectedSubjectIndex]?.chapters.push(chapterPayload)
                console.log(curriculumData)
                this.addOrUpdateCurriculam(curriculumData)
            }
        }
    }

    onSubmitCreateOrUpdatedTopic = () => {
        const formStatus = isFormValid(createTopicFields, this.TopicformFields.formValidations, trimObj(this.state.topic));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                topicFormValidations: formStatus.formValidations,
            });
        } else {
            let topicPayload = cloneDeep(this.state.topic)
            let curriculumData = cloneDeep(this.state.curriculumInfo)
            if (this.state.edit) {

                curriculumData.subjects[this.state.selectedSubjectIndex]?.chapters[this.state.selectedChapterIndex]?.topics.forEach(topic => {
                    if (topic.topicId == topicPayload.topicId) {
                        topic.topicName = topicPayload.topicName;
                        topic.topicCode = topicPayload.topicCode;
                        topic.priority = topicPayload.priority;
                    }
                })
                console.log(curriculumData)

                this.addOrUpdateCurriculam(curriculumData)
            } else {
                topicPayload.topicId = uuidv4();
                topicPayload.isTopicActivity = true
                topicPayload.resources = [
                    {
                        "label": "VIDEO",
                        "value": "mp4",
                        "isActive": true
                    },
                    {
                        "label": "PPT",
                        "value": "ppt",
                        "isActive": true
                    },
                    {
                        "label": "PDF",
                        "value": "pdf",
                        "isActive": true
                    },
                    {
                        "label": "QUIZ",
                        "value": "quiz",
                        "isActive": true
                    }
                ]
                topicPayload.priority = Number(topicPayload.priority)
                curriculumData.subjects[this.state.selectedSubjectIndex]?.chapters[this.state.selectedChapterIndex]?.topics.push(topicPayload)
                console.log(curriculumData)

                this.addOrUpdateCurriculam(curriculumData)
            }

        }
        // this.setState({ isDialogOpenForCreateTopic: false })
    }
    render() {
        const { subject, chapter, formValidations, chapterFormValidations, topic, topicFormValidations, curriculumInfo, selectedSubject, selectedChapter } = this.state

        return (
            <div>
                <div >
                    <div className='grid m-2'>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.props.boards} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                optionValue="boardId" />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Grade'} icon={<GradesIcon />} placeholder={'Select Grade'} options={this.state?.classes}
                                value={this.state.classId} onChange={(e) => this.onChangeClass(e.value)} />
                        </div>
                    </div>
                    <div className='grid col-12'>
                        <div className='col-4 mr-12'>
                            <h4 style={{ fontWeight: 'bold' }}>Subject</h4>
                            <div className='ma-scroll-vr-smoke' style={{ height: '57vh', }}>
                                {
                                    curriculumInfo?.subjects?.map((subject, index) => {
                                        return <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: subject.subjectId == this.state.selectedSubject?.subjectId ? '#D1E4FF' : '', }} onClick={() => this.getChaptersBySelectedsubject(subject, index)}>
                                            <div className='flex flex-row justify-content-between'>
                                                <div className='flex flex-row justify-content-start'>
                                                    <div className='mr-3'>
                                                        <SubjectIcon height={16.5} width={19.5} color={subject.subjectId == this.state.selectedSubject?.subjectId ? '#0056D0' : '#000000'} />
                                                    </div>
                                                    <div className='-mt-3'>
                                                        <div className="w-full flex justify-content-start ">
                                                            <p className='Montserrat24 text-overflow-ellipsis' style={{ fontWeight: 'bold', fontSize: '16px', color: subject.subjectId == this.state.selectedSubject?.subjectId ? '#0056D0' : '', }}  >{subject.subjectName.toUpperCase()}</p>
                                                        </div>
                                                        <p style={{ fontSize: '15px', color: subject.subjectId == this.state.selectedSubject?.subjectId ? '#0056D0' : '', }}>{subject?.chapters?.length} Chapters</p>
                                                    </div>
                                                </div>
                                                <div onClick={() => this.setState({ isDialogOpenForCreateSubject: true, edit: true, subject: subject })}>
                                                    <ClassetEditIcon width={18.75} height={18.75} color={subject.subjectId == this.state.selectedSubject?.subjectId ? '#0056D0' : '#000000'} />
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                                {curriculumInfo?.subjects?.length <= 3 &&
                                    <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: '#D1E4FF' }} onClick={() => this.setState({ isDialogOpenForCreateSubject: true })}>
                                        <div className='flex flex-row justify-content-between'>
                                            <div className='flex flex-row justify-content-start'>
                                                <div className='mr-3'>
                                                    <GradesIconActive />
                                                </div>
                                                <div className='-mt-2'>
                                                    <div className="w-full flex justify-content-start ">
                                                        <p className='inter24Text' style={{ fontSize: '24px', color: '#0056D0', }}>{'Add Subject'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <AddIconInGrade />
                                        </div>
                                    </div>}
                            </div>
                            {curriculumInfo?.subjects?.length > 3 &&
                                <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: '#D1E4FF' }} onClick={() => this.setState({ isDialogOpenForCreateSubject: true })}>
                                    <div className='flex flex-row justify-content-between'>
                                        <div className='flex flex-row justify-content-start'>
                                            <div className='mr-3'>
                                                <GradesIconActive />
                                            </div>
                                            <div className='-mt-2'>
                                                <div className="w-full flex justify-content-start ">
                                                    <p className='inter24Text' style={{ fontSize: '24px', color: '#0056D0', }}>{'Add Subject'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <AddIconInGrade />
                                    </div>
                                </div>}
                        </div>
                        {curriculumInfo?.subjects?.length > 0 && <div className='col-4 mr-12'>
                            <h4 style={{ fontWeight: 'bold' }}>Chapter</h4>
                            <div className='ma-scroll-vr-smoke' style={{ height: '57vh', }}>
                                {
                                    curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters?.map((chapter, index) => {
                                        return <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: chapter.chapterId == this.state.selectedChapter?.chapterId ? '#D1E4FF' : '', }} onClick={() => this.getTopicsBySelectedChapter(chapter, index)}>
                                            <div className='flex flex-row justify-content-between'>
                                                <div className='flex flex-row justify-content-start'>
                                                    <div className='mr-3'>
                                                        <SubjectIcon height={16.5} width={19.5} color={chapter.chapterId == this.state.selectedChapter?.chapterId ? '#0056D0' : '#000000'} />

                                                    </div>
                                                    <div className='-mt-3'>
                                                        <div className="w-full flex justify-content-start ">
                                                            <p className='Montserrat24' style={{ fontWeight: 'bold', fontSize: '16px', color: chapter.chapterId == this.state.selectedChapter?.chapterId ? '#0056D0' : '', }}>{chapter.chapterName.toUpperCase()}</p>
                                                        </div>
                                                        <p style={{ fontSize: '15px', color: chapter.chapterId == this.state.selectedChapter?.chapterId ? '#0056D0' : '', }}>{chapter?.topics?.length} Topics</p>
                                                    </div>
                                                </div>
                                                <div onClick={() => this.setState({ isDialogOpenForCreateChapter: true, edit: true, chapter: chapter })}>
                                                    <ClassetEditIcon width={18.75} height={18.75} color={chapter.chapterId == this.state.selectedChapter?.chapterId ? '#0056D0' : '#000000'} />
                                                </div>
                                            </div>
                                        </div>

                                    })
                                }
                                {curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters?.length <= 3 &&
                                    <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: '#D1E4FF' }} onClick={() => this.setState({ isDialogOpenForCreateChapter: true })}>
                                        <div className='flex flex-row justify-content-between'>
                                            <div className='flex flex-row justify-content-start'>
                                                <div className='mr-3'>
                                                    <GradesIconActive />
                                                </div>
                                                <div className='-mt-2'>
                                                    <div className="w-full flex justify-content-start ">
                                                        <p className='inter24Text' style={{ fontSize: '24px', color: '#0056D0', }}>{'Add Chapter'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <AddIconInGrade />
                                        </div>
                                    </div>}
                            </div>
                            {curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters?.length > 3 &&
                                <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: '#D1E4FF' }} onClick={() => this.setState({ isDialogOpenForCreateChapter: true })}>
                                    <div className='flex flex-row justify-content-between'>
                                        <div className='flex flex-row justify-content-start'>
                                            <div className='mr-3'>
                                                <GradesIconActive />
                                            </div>
                                            <div className='-mt-2'>
                                                <div className="w-full flex justify-content-start ">
                                                    <p className='inter24Text' style={{ fontSize: '24px', color: '#0056D0', }}>{'Add Chapter'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <AddIconInGrade />
                                    </div>
                                </div>}
                        </div>}
                        {this.state.selectedSubjectIndex >= 0 ? curriculumInfo && curriculumInfo?.subjects && curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters && curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters?.length ?
                            <div className='col-4 mr-12'>
                                <h4 style={{ fontWeight: 'bold' }}>Topics</h4>
                                <div className='ma-scroll-vr-smoke' style={{ height: '57vh', }}>
                                    {
                                        curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters[this.state.selectedChapterIndex]?.topics?.map((topic) => {
                                            return <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: topic.topicId == this.state.selectedTopic?.topicId ? '#E6E8EA' : '', }} onClick={() => this.getSubTopicsBySelectedTopic(topic)}>
                                                <div className='flex flex-row justify-content-between'>
                                                    <div className='flex flex-row justify-content-start'>
                                                        <div className='mr-3'>
                                                            <TopicIcon />
                                                        </div>
                                                        <div className='-mt-3'>
                                                            <div className="w-full flex justify-content-start ">
                                                                <p className='Montserrat24' style={{ fontWeight: 'bold', fontSize: '16px', }}>{topic?.topicName.toUpperCase()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => this.setState({ isDialogOpenForCreateTopic: true, edit: true, topic: topic })}>
                                                        <ClassetEditIcon width={18.75} height={18.75} color={'#000000'} />
                                                    </div>
                                                </div>
                                            </div>

                                        })
                                    }
                                    {curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters[this.state.selectedChapterIndex]?.topics?.length <= 3 &&
                                        <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: '#D1E4FF' }} onClick={() => this.setState({ isDialogOpenForCreateTopic: true })}>
                                            <div className='flex flex-row justify-content-between'>
                                                <div className='flex flex-row justify-content-start'>
                                                    <div className='mr-3'>
                                                        <GradesIconActive />
                                                    </div>
                                                    <div className='-mt-2'>
                                                        <div className="w-full flex justify-content-start ">
                                                            <p className='inter24Text' style={{ fontSize: '24px', color: '#0056D0', }}>{'Add Topic'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <AddIconInGrade />
                                            </div>
                                        </div>}
                                </div>
                                {curriculumInfo?.subjects[this.state.selectedSubjectIndex]?.chapters[this.state.selectedChapterIndex]?.topics?.length > 3 &&
                                    <div className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5" style={{ backgroundColor: '#D1E4FF' }} onClick={() => this.setState({ isDialogOpenForCreateTopic: true })}>
                                        <div className='flex flex-row justify-content-between'>
                                            <div className='flex flex-row justify-content-start'>
                                                <div className='mr-3'>
                                                    <GradesIconActive />
                                                </div>
                                                <div className='-mt-2'>
                                                    <div className="w-full flex justify-content-start ">
                                                        <p className='inter24Text' style={{ fontSize: '24px', color: '#0056D0', }}>{'Add Topic'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <AddIconInGrade />
                                        </div>
                                    </div>}
                            </div>
                            : <></> : <></>}






                    </div>
                    <Dialog
                        visible={this.state.isDialogOpenForCreateSubject}
                        draggable={false}
                        closeOnEscape={false}
                        // position={"right"}
                        className='ma-alert-box'
                        style={{ width: "1000px", height: "600px" }}
                        dismissableMask={false}
                        closable={true}
                        header={() => {
                            return (<div className='mt-2'>
                                <h4 className="formHeadingInter">Add Subject</h4>
                            </div>)
                        }}
                        footer={() => (
                            <div className='flex justify-content-center mb-4'>
                                <Button style={{ borderRadius: '10px' }} className='formSubmitButton px-8' onClick={() => this.onSubmitCreateOrUpdatedSubject()} >
                                    <p className='formSubmitButtonText'> {this.state.edit ? 'Update' : "Create"} </p>
                                </Button>
                                <Button style={{ borderRadius: '10px' }} className='formCancelButton px-4' onClick={() => this.setState({ subject: {}, isDialogOpenForCreateSubject: false, edit: false })} >
                                    <p className='formCancelButtonText'>Cancel</p>
                                </Button>
                            </div>
                        )}
                        onHide={() => this.setState({ isDialogOpenForCreateSubject: false, subject: {}, edit: false })}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <div className='grid lg:col-10 md:col-12 gap-6 mx-6 px-5'  >
                                <div className=" lg:col-5 md:col-5 " >
                                    <ClassetInputText required={true} className='w-full' label={'Subject Title'} icon={<BookIcon height={18} />} placeholder={'Subject Title '} value={subject.subjectName} onChange={(e) => { onTextChange(e.target.value, 'subjectName', this, createSubjectFields, subject, formValidations, 'subject', 'formValidations') }} />
                                    {formValidations && !formValidations.fields['subjectName'].isValid && <p className="p-error font-semibold">{formValidations.fields['subjectName'].errorMsg}</p>}
                                </div>

                                <div className="lg:col-5 md:col-5" >
                                    <ClassetInputText icon={<CodeLinkIcon height={22} />} id="title" label={'Subject Code'} className='h-3rem w-full  border-round-md  border-none text-lg pl-3' value={subject.subjectCode} onChange={(e) => { onTextChange(e.target.value, 'subjectCode', this, createSubjectFields, subject, formValidations, 'subject', 'formValidations') }} placeholder="Code (Alpha Numeric)" />
                                    {formValidations && !formValidations.fields['subjectCode'].isValid && <p className="p-error font-semibold">{formValidations.fields['subjectCode'].errorMsg}</p>}
                                </div>

                                <div className='lg:col-5 md:col-5' >
                                    <ClassetInputText required={true} className='w-full' label={'Priority'} icon={<CodeLinkIcon height={22} />} placeholder={'Code (Alpha Numeric)'}
                                        value={subject.priority} onChange={(e) => { onNumberChange(e.target.value, 'priority', this, createSubjectFields, subject, formValidations, 'subject', 'formValidations') }}
                                    />
                                    {formValidations && !formValidations.fields['priority'].isValid && <p className="p-error font-semibold">{formValidations.fields['priority'].errorMsg}</p>}
                                </div>

                            </div>
                        </div>


                    </Dialog>

                    <Dialog
                        visible={this.state.isDialogOpenForCreateChapter}
                        draggable={false}
                        closeOnEscape={false}
                        // position={"right"}
                        className='ma-alert-box '
                        style={{ width: "1000px", height: "600px" }}
                        dismissableMask={false}
                        closable={true}
                        header={() => {
                            return (<div className='mt-2'>
                                <h4 className="formHeadingInter">Add Chapter</h4>
                            </div>)
                        }}
                        footer={() => (
                            <div className='flex justify-content-center mb-4'>
                                <Button style={{ borderRadius: '10px' }} className='formSubmitButton px-8' onClick={() => this.onSubmitCreateOrUpdatedChapter()} >
                                    <p className='formSubmitButtonText'> {this.state.edit ? 'Update' : 'Create'}</p>
                                </Button>
                                <Button style={{ borderRadius: '10px' }} className='formCancelButton px-6' onClick={() => this.setState({ chapter: {}, isDialogOpenForCreateChapter: false, edit: false })} >
                                    <p className='formCancelButtonText'>Cancel</p>
                                </Button>
                            </div>
                        )}
                        onHide={() => this.setState({ isDialogOpenForCreateChapter: false, edit: false, chapter: {}, })}
                    >
                        <div className='flex flex-column justify-content-center mx-6'>
                            <div className='grid col-12 justify-content-center '>
                                <div className=" col-6" >
                                    <ClassetInputText width={'80%'} required={true} label={'Chapter Title'} icon={<BookIcon height={18} />} placeholder={'Chapter Title '} value={chapter.chapterName} onChange={(e) => { onTextChange(e.target.value, 'chapterName', this, createChapterFields, chapter, chapterFormValidations, 'chapter', 'chapterFormValidations') }} />
                                    {chapterFormValidations && !chapterFormValidations.fields['chapterName'].isValid && <p className="p-error font-semibold">{chapterFormValidations.fields['chapterName'].errorMsg}</p>}
                                </div>

                                <div className=" col-6" >
                                    <ClassetInputText width={'80%'} required={true} label={'Chapter Code'} icon={<CodeLinkIcon height={22} />} placeholder={'Chapter Code '} value={chapter.chapterCode} onChange={(e) => { onTextChange(e.target.value, 'chapterCode', this, createChapterFields, chapter, chapterFormValidations, 'chapter', 'chapterFormValidations') }} />
                                    {chapterFormValidations && !chapterFormValidations.fields['chapterCode'].isValid && <p className="p-error font-semibold">{chapterFormValidations.fields['chapterCode'].errorMsg}</p>}
                                </div>

                                <div className=" col-6" >
                                    <ClassetInputText required={true} width={'80%'} label={'Priority'} icon={<CodeLinkIcon height={22} />} placeholder={'Code (Alpha Numeric)'}
                                        value={chapter.priority} onChange={(e) => { onNumberChange(e.target.value, 'priority', this, createChapterFields, chapter, chapterFormValidations, 'chapter', 'chapterFormValidations') }}
                                    />
                                    {chapterFormValidations && !chapterFormValidations.fields['priority'].isValid && <p className="p-error font-semibold">{chapterFormValidations.fields['priority'].errorMsg}</p>}
                                </div>
                                <div className='col-12 md:col-6 mt-3'>
                                    <div className='grid col-12 gap-4'>
                                        <div className='col-5 border-round-md font-bold justify-content-center align-content-center' style={{ backgroundColor: '#E4E5E6', padding: '8px', textAlign: 'center' }}>
                                            Board: {this.state.selectedBoardName}
                                        </div>
                                        <div className='col-5 border-round-md font-bold justify-content-center align-content-center' style={{ backgroundColor: '#E4E5E6', padding: '8px', textAlign: 'center' }}>
                                            Grade: {this.state.selectedClassName}
                                        </div>
                                        <div className='col-10 border-round-md font-bold justify-content-center align-content-center' style={{ backgroundColor: '#E4E5E6', padding: '8px', textAlign: 'center', marginLeft: '10px' }}>
                                            Subject: {this.state.selectedSubject?.subjectName}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Dialog>

                    <Dialog
                        visible={this.state.isDialogOpenForCreateTopic}
                        draggable={false}
                        closeOnEscape={false}
                        // position={"right"}
                        className='ma-alert-box w-8'
                        style={{ width: "500px", height: "500px" }}
                        dismissableMask={false}
                        closable={true}
                        header={() => {
                            return (<div className='mt-2'>
                                <h4 className="formHeadingInter">Add Topic</h4>
                            </div>)
                        }}
                        footer={() => (
                            <div className='flex justify-content-center mb-4'>
                                <Button style={{ borderRadius: '10px' }} className='formSubmitButton px-8' onClick={() => this.onSubmitCreateOrUpdatedTopic()} >
                                    <p className='formSubmitButtonText'> {this.state.edit ? 'Update' : 'Create'}</p>
                                </Button>
                                <Button style={{ borderRadius: '10px' }} className='formCancelButton px-6' onClick={() => this.setState({ isDialogOpenForCreateTopic: false, edit: false, topic: {}, })} >
                                    <p className='formCancelButtonText '>Cancel</p>
                                </Button>
                            </div>
                        )}
                        onHide={() => this.setState({ isDialogOpenForCreateTopic: false, edit: false, topic: {}, })}
                    >
                        <div className='flex flex-column justify-content-center mx-8 '>
                            <div className='grid col-12 '>
                                <div className=" col-6" >
                                    <ClassetInputText width={'80%'} required={true} label={'Topic Title'} icon={<BookIcon height={18} />} placeholder={'Topic Title '} value={topic.topicName} onChange={(e) => { onTextChange(e.target.value, 'topicName', this, createTopicFields, topic, topicFormValidations, 'topic', 'topicFormValidations') }} />
                                    {topicFormValidations && !topicFormValidations.fields['topicName'].isValid && <p className="p-error font-semibold">{topicFormValidations.fields['topicName'].errorMsg}</p>}
                                </div>

                                <div className=" col-6" >
                                    <ClassetInputText width={'80%'} required={true} label={'Topic Code'} icon={<CodeLinkIcon height={22} />} placeholder={'Topic Code '} value={topic.topicCode} onChange={(e) => { onTextChange(e.target.value, 'topicCode', this, createTopicFields, topic, topicFormValidations, 'topic', 'topicFormValidations') }} />
                                    {topicFormValidations && !topicFormValidations.fields['topicCode'].isValid && <p className="p-error font-semibold">{topicFormValidations.fields['topicCode'].errorMsg}</p>}
                                </div>
                                <div className=" col-6" >
                                    <ClassetInputText required={true} width={'80%'} label={'Priority'} icon={<CodeLinkIcon height={22} />} placeholder={'Code (Alpha Numeric)'}
                                        value={topic.priority} onChange={(e) => { onNumberChange(e.target.value, 'priority', this, createTopicFields, topic, topicFormValidations, 'topic', 'topicFormValidations') }}
                                    />
                                    {topicFormValidations && !topicFormValidations.fields['priority'].isValid && <p className="p-error font-semibold">{topicFormValidations.fields['priority'].errorMsg}</p>}
                                </div>
                                <div className='col-12 md:col-6 mt-3'>
                                    <div className='grid col-12 gap-4'>
                                        <div className='col-5 border-round-md font-bold justify-content-center align-content-center' style={{ backgroundColor: '#E4E5E6', padding: '8px', textAlign: 'center' }}>
                                            Board: {this.state.selectedBoardName}
                                        </div>
                                        <div className='col-5 border-round-md font-bold justify-content-center align-content-center' style={{ backgroundColor: '#E4E5E6', padding: '8px', textAlign: 'center' }}>
                                            Grade: {this.state.selectedClassName}
                                        </div>
                                        <div className='col-5 border-round-md font-bold justify-content-center align-content-center' style={{ backgroundColor: '#E4E5E6', padding: '8px', textAlign: 'center' }}>
                                            Subject: {this.state.selectedSubject?.subjectName}
                                        </div>
                                        <div className='col-5 border-round-md font-bold justify-content-center align-content-center' style={{ backgroundColor: '#E4E5E6', padding: '8px', textAlign: 'center' }}>
                                            Chapter: {this.state.selectedChapter?.chapterName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    // boards: state.boardsData.boards,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(SubjectManagement)));