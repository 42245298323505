import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import Authentication from './../session';
import Service from '../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
// import Navbar from './../navbar/index';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import {
    getBoardsData,
    getBranchesLatest
} from './../../store/actions';
import './styles.scss'
import LoadingComponent from '../loadingComponent';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import SearchBar from './searchBar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { BasicLazyParams, exportToCSV, getServerTime } from '../../utile';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import withRouter from '../lib/withRouter';

const items = [
    { label: 'Attendance' },
    { label: 'Attendance Report', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const AbsentReasons = [
    {
        label: 'R1',
        value: 'R1'
    },
    {
        label: 'R2',
        value: 'R2'
    },
]

class AttendanceReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            groups: [],
            curriculumInfo: {},
            isShowBoardCreate: false,
            editBoardData: null,
            isBoardAdd: true,
            isShowBoards: true,
            isShowClasses: false,
            editChapterData: null,
            isShowExportToOffline: false,
            globalSearch: '',
            users: [],
            date: getServerTime(),
            // dateRange: [],
            reportData: {},
            sectionIds: [],
            sectionNames: {},
            grandTotal: [],
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            columns: []

        }
        this.service = new Service();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.branchData !== this.props.branchData) {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }


        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }
    }


    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };
    onSectionChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionId: value
            };
        });

    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Attendance</h4>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }



    getStudentsAttendance = (sectionIds, dateRange) => {
        // return

        this.setState({
            isLoading: true,
            users: [],
            reportData: [],
            sections: {},
            sectionNames: {},
            grandTotal: []
        })


        let { filterData } = this.state;
        const url = `${baseUrlForLongRunningTask}/attendance/getreport`;
        this.service.post(url, { sectionIds, dateRange }, true).then(res => {

            //    console.log(res.res.data)
            if (res && res.status && res.res.status) {

                if (Object.keys(res.res.data).length) {



                    this.setState({
                        isLoading: false,
                        reportData: res.res.data,
                        sections: res.res.sections,
                        sectionNames: res.res.sectionNames,
                        grandTotal: res.res.total
                        // attendance: res.res.data,
                        // totalRecords: res.res.totalRecords
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({ severity: 'error', summary: 'No reports', detail: 'No attendanc reports found on the selected dates.', life: 3000 });
                }

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    onSearchClick = () => {
        const {
            sectionIds, dateRange
        } = this.state;

        if (!sectionIds || !sectionIds.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one section', life: 3000 });

            return
        }

        if (!dateRange || !dateRange.length || dateRange.length != 2) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select date range', life: 3000 });
            return
        }




        this.getStudentsAttendance(sectionIds, dateRange)



    }
    onMultiDateChange = (dateRange) => {
        this.setState({
            dateRange,

        });
    }

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }
    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }

    showStudents = (sectionId, date, type) => {
        this.setState({
            isLoading: true,
            sectionId,
            date,
            type
        })


        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlForLongRunningTask}/attendance/getstrength?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;
        this.service.post(url, { sectionId, date, type }, true).then(res => {

            //   console.log(res.res.data)
            if (res && res.status && res.res.status) {

                if (res.res.data && res.res.data.length) {
                    let users1 = this.reFormUsersObj(res.res.data);
                    this.setState({
                        isLoading: false,
                        isShowStrength: true,
                        students: users1,
                        totalRecords: res.res.totalRecords
                        // reportData: res.res.data,
                        // sections: res.res.sections,
                        // sectionNames: res.res.sectionNames,
                        // grandTotal: res.res.total
                        // attendance: res.res.data,
                        // totalRecords: res.res.totalRecords
                    });
                } else {

                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'No users found', life: 3000 });
                }



            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })


    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, () => {
            this.showStudents(this.state.sectionId, this.state.date, this.state.type)
        });
    }


    render() {
        const { reportData } = this.state;
        const header = (
            <div className="table-header  ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Users List</h2>
                    {/* {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />} */}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />

                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />

                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            < >
      

                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb border-none" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>

                    <div className='ma-main mt-5'>
                        <div>
                            {
                                this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                    return (!(this.props.branchLevels.length == index + 1) ? <Dropdown
                                        className='mr-4 mt-10 '
                                        value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                        optionLabel={"name"}
                                        optionValue={"key"}
                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                        onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                        placeholder={`--Select ${level.levelName}--`} /> : <MultiSelect
                                        className='mr-4 mt-10 '
                                        value={this.state.sectionIds ? this.state.sectionIds : []}
                                        onChange={(e) => this.onMultiChange(e.value)}
                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                        optionLabel={"name"}
                                        optionValue={"key"}
                                        placeholder={`--Select ${level.levelName}--`}
                                    />
                                    )
                                }) : null
                            }

                            <Calendar showIcon={true}
                                placeholder="Select Dates"
                                selectionMode="range"
                                readOnlyInput
                                className=' mt-10 '
                                value={this.state.dateRange}
                                maxDate={getServerTime()}
                                onChange={(e) => this.onMultiDateChange(e.value)}
                            ></Calendar>
                            <span className='ma-required-mark mr-4'>*</span>
                            <Button label="Show Report" className='' onClick={this.onSearchClick} />
                        </div>
                        {
                            reportData && Object.keys(reportData).length ? <>
                                <div className='attendance-report '>
                                    <div>
                                        <table className="exam-tbl" >
                                            {Object.keys(reportData).map((key, index) => {
                                                return <>

                                                    {reportData[key] && reportData[key].length ? <>
                                                        {
                                                            (index == 0) && <>
                                                                <tr>
                                                                    <th rowspan="2" className='sno'>S.No</th>

                                                                    <th rowspan="2" className='sectionName'>Section Name</th>

                                                                    {reportData[key].map((r) => {
                                                                        return <th colSpan="5" >{moment(r.date).format('YYYY-MM-DD')}</th>
                                                                    })}
                                                                </tr>
                                                                <tr>
                                                                    {reportData[key].map(() => {
                                                                        return <>
                                                                            <th>Total Strength</th>
                                                                            <th>Present</th>
                                                                            <th>Absent</th>
                                                                            <th>Present(%)</th>
                                                                            <th>Absent(%)</th>
                                                                        </>
                                                                    })}
                                                                </tr>
                                                            </>
                                                        }

                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{this.state.sectionNames[key]}</td>
                                                            {reportData[key].map((userRecord, ind) => {
                                                                return <>

                                                                    <td><span className='strength-highlight' onClick={() => {
                                                                        if (userRecord.totalStrength > 0) {
                                                                            this.showStudents(key, userRecord.date, 'total')
                                                                        }
                                                                    }}>{userRecord.totalStrength}</span></td>
                                                                    <td><span className='strength-highlight' onClick={() => {
                                                                        if (userRecord.present > 0) {
                                                                            this.showStudents(key, userRecord.date, 'present')
                                                                        }
                                                                    }}>{userRecord.present}</span></td>
                                                                    <td><span className='strength-highlight' onClick={() => {
                                                                        if (userRecord.absent > 0) {


                                                                            this.showStudents(key, userRecord.date, 'absent')
                                                                        }
                                                                    }}>{userRecord.absent}</span></td>
                                                                    <td>{userRecord.presentPercentage}</td>
                                                                    <td>{userRecord.absentPercentage}</td>
                                                                </>
                                                            })}
                                                        </tr>


                                                    </> : <></>
                                                    }
                                                </>
                                            })}

                                            <tr>
                                                <td colSpan={2} className='grand-total' >Total </td>
                                                {this.state.grandTotal && this.state.grandTotal.length ? <>
                                                    {this.state.grandTotal.map(t => {
                                                        return <>
                                                            <td>{t.totalStrength}</td>
                                                            <td>{t.present}</td>
                                                            <td>{t.absent}</td>
                                                            <td>{t.presentPercentage}</td>
                                                            <td>{t.absentPercentage}</td>
                                                        </>
                                                    })}
                                                </> : <>
                                                </>
                                                }
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </> : ''
                        }
                    </div>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {
                        (this.props.isLoading || this.state.isLoading) && <>
                            <LoadingComponent />
                        </>
                    }
                


                <Dialog
                    visible={this.state.isShowStrength}
                    blockScroll={true}

                    draggable={false}
                    closeOnEscape={true}
                    dismissableMask={true}
                    closable={true}
                    onHide={() => {
                        this.setState({
                            isShowStrength: false,
                            students: [],
                            totalRecords: 0
                        })
                    }}
                    style={{ width: '90%', height: '90vh' }}
                >
                    <div>
                        <div className="card datatable-crud-demo ma-m30">
                            <DataTable ref={(el) => this.dt = el}
                                //  lazy
                                value={this.state.students}
                                dataKey="id"
                                paginator
                                lazy
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                //  globalFilter={this.state.globalFilter}

                                paginatorLeft={() => {
                                    return <>
                                        <p className='avail-q'>Users:  <span>{this.state.totalRecords}</span> </p>
                                    </>
                                }}
                                header={header}>
                                <Column filterField="username" field="username" body={(rowData) => {
                                    return (<span> {rowData.username ? rowData.username.substring(3) : ''}</span>

                                    )
                                }} header="User Name (for Login)" />
                                <Column filterField="given_name" field="given_name" header="Given Name" />
                                <Column filterField="phone_number" field="phone_number" header="Phone" />
                                <Column filterField="email" field="email" header="Email ID" />
                                <Column filterField="custom:board" field="custom:board" header="Board" />
                                <Column filterField="custom:grade" field="custom:grade" header="Class" />
                            </DataTable>
                        </div>
                    </div>

                </Dialog>
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(AttendanceReport)));

//Authentication