import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import { trimObj } from '../../../utile';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid, onTextChange, onNumberChange } from '../../../utile/formHelper';
import uuidv4 from 'uuid/v4';
import InputTextB from './../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
import './styles.scss'

const formFields = [
    {
        "Type": "Number",
        "Label": "Chapter Number",
        "FieldName": "chapterNumber",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Chapter Name",
        "FieldName": "chapterName",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Chapter Code",
        "FieldName": "chapterCode",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Chapter Description",
        "FieldName": "chapterDescription",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Chapter Long Description",
        "FieldName": "chapterLongDescription",
        "Required": true
    },
    {
        "Type": "Toggle",
        "Label": "Active Status",
        "FieldName": "isChapterActive",
        "Required": false
    }

]

class CreateChapter extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editChapterData || { chapterId: uuidv4() });
        this.state = {
            chapter: this.formFields.data,
            formValidations: this.formFields.formValidations
        }
    }

    footer = () => {
        return (<>
            <Button label="Cancel" className='p-button-outlined' onClick={this.onCloseDialog} />
            <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />

        </>)
    }

    onSubmitClick = () => {

        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.chapter));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            this.props.addChapter(trimObj(this.state.chapter));

        }
    }

    onCloseDialog = () => {
        this.props.hideCreateDialog();

    }


    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.chapter));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            chapter: data,
            formValidations
        });
    }

    render() {
        const { chapter, formValidations } = this.state;
        return (
            <Dialog header={`${this.props.isAdd ? 'Add' : 'Edit'} Chapter`}
                draggable={false} footer={this.footer}
                headerClassName="text-center"
                blockScroll={true}
                closeOnEscape={false}
                dismissableMask={false} visible={true} closable={false} onHide={() => this.setState({ showCreateDialog: false })}>

                <div className="grid col-12 ">
                    <div className="col-5">
                        <p className='text-label'>Chapter Number <span className='ma-required'>*</span>:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.CHAPTER_NUMBER} id="cWWhn">
                            <InputText value={chapter.chapterNumber}
                                //onChange={(e) => { onTextChange(e, 'chapterNumber') }}
                                onChange={(e) => { onNumberChange(e.target.value, 'chapterNumber', this, formFields, chapter, formValidations, 'chapter', 'formValidations') }}


                                className='p-inputtext-style1 ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['chapterNumber'].isValid && <p className="p-error">{formValidations.fields['chapterNumber'].errorMsg}</p>}
                    </div>
                </div>
                
                <div className="grid col-12 ">
                    <div className="col-5">
                        <p className='text-label'>Chapter Name <span className='ma-required'>*</span>:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.CHAPTER_NAME} id="cWhnF">
                            <InputText value={chapter.chapterName}
                                onChange={(e) => { onTextChange(e.target.value, 'chapterName', this, formFields, chapter, formValidations, 'chapter', 'formValidations') }}

                                className='p-inputtext-style1 ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['chapterName'].isValid && <p className="p-error">{formValidations.fields['chapterName'].errorMsg}</p>}
                    </div>
                </div>
               
                <div className="grid col-12 ">
                    <div className="col-5">
                        <p className='text-label' >Chapter Code<span className='ma-required'>*</span>:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.CHAPTER_CODE} id="chWn">
                            <InputText value={chapter.chapterCode}
                                onChange={(e) => { onTextChange(e.target.value, 'chapterCode', this, formFields, chapter, formValidations, 'chapter', 'formValidations') }}

                                className='p-inputtext-style1' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['chapterCode'].isValid && <p className="p-error">{formValidations.fields['chapterCode'].errorMsg}</p>}
                    </div>
                </div>
                
                <div className="grid col-12 ">
                    <div className="col-5">
                        <p className='text-label' >Chapter Description <span className='ma-required'>*</span>:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.CHAPTER_DESCRIPTION} id="chnFR">
                            <InputTextarea value={chapter.chapterDescription}
                                onChange={(e) => { onTextChange(e.target.value, 'chapterDescription', this, formFields, chapter, formValidations, 'chapter', 'formValidations') }}
                                className='p-inputtext-style1' rows={5} cols={30} />
                        </InputTextB>
                        {formValidations && !formValidations.fields['chapterDescription'].isValid && <p className="p-error">{formValidations.fields['chapterDescription'].errorMsg}</p>}
                    </div>
                </div>
                
                <div className="grid col-12 ">
                    <div className="col-5">
                        <p className='text-label' >Chapter Long Descripiton <span className='ma-required'>*</span>:</p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.CHAPTER_LONG_DESCRIPTION} id="chnD">
                            <InputTextarea value={chapter.chapterLongDescription}
                                onChange={(e) => { onTextChange(e.target.value, 'chapterLongDescription', this, formFields, chapter, formValidations, 'chapter', 'formValidations') }}
                                className='p-inputtext-style1' rows={5} cols={30} />
                        </InputTextB>
                        {formValidations && !formValidations.fields['chapterLongDescription'].isValid && <p className="p-error">{formValidations.fields['chapterLongDescription'].errorMsg}</p>}
                    </div>
                </div>
               
                <div className="grid col-12 ">
                    <div className="col-5">
                        <p className='text-label'>Active Status: </p>
                    </div>
                    <div className="col-7">
                        <InputTextB info={FIELDS_INFO.CHAPTER_STATUS} id="chnS">
                            <InputSwitch checked={chapter.isChapterActive} onChange={(e) => { this.onToggleChange(e, 'isChapterActive') }} />
                        </InputTextB>
                    </div>
                </div>
            </Dialog >
        )
    }
}




export default CreateChapter;