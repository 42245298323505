import React, { Component } from 'react';
import { connect } from 'react-redux';
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import InputTextB from '../customComponents/inputTextB';
import { FIELDS_INFO } from '../../constants';
import uuidv4 from 'uuid/v4';
import Authentication from './../session';
import './styles.scss'
import { getServerTime, trimObj } from '../../utile';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { Menu } from 'primereact/menu';
import { Calendar } from 'primereact/calendar';
import {
    getBoardsData, getYearlyPlannerData
} from './../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { ConnectedOverlayScrollHandler } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import CKEditor from 'react-ckeditor-component';
import { baseUrlAdmin } from '../../store/apiConstants';
import axios from 'axios';
// import { isSupportedImage } from 'html2canvas/dist/types/css/types/image';
import { getFormFields, isFormValid, onChipsChange, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../utile/formHelper';
import LoadingComponent from '../loadingComponent';
import PreviewPlanner from './previePlanner';
import withRouter from '../lib/withRouter';




const masterSubjects = [
    {
        subjectId: "abcqwtr",
        subjectName: "subject 1"
    },
    {
        subjectId: "abcsdqwtr",
        subjectName: "subject 2"
    },
    {
        subjectId: "abcasdqwtr",
        subjectName: "subject 3"
    },
]

const defaultChapters = Array(7).fill({
    "chapterName": "",
    "chapterId": "",
    "startEndDate": null,
    "preKnowledge": "",
    "learningObjective": "",
    "learningOutcome": ""
})

const formFields = [
    {
        "Type": "Text",
        "Label": "Title",
        "FieldName": "title",
        "Required": true,
        "MaxLength": 50
    },
    {
        "Type": "Text",
        "Label": "Start Date",
        "FieldName": "startDate",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "End Date",
        "FieldName": "endDate",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Description",
        "FieldName": "description",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Board Name",
        "FieldName": "boardName",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Board Id",
        "FieldName": "boardId",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Class Id",
        "FieldName": "classId",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "class Name",
        "FieldName": "className",
        "Required": true
    }

]

const defaultChapter = {
    "chapterName": "",
    "chapterId": "",
    "startEndDate": null,
    "preKnowledge": "",
    "learningObjective": "",
    "learningOutcome": ""
}

class CreatePlanner extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editPlannerData || { boardId: uuidv4() });
        this.state = {
            calendar: this.formFields.data,
            formValidations: this.formFields.formValidations,
            boards: [],
            classes: [],
            chapters: [],
            masterChapters: [],
            masterSubjects: [],
            selectedSubject: "",
            selectedRow: 0,
            subjectChaptes: {},
            plannerSubjects: this.props.editPlannerData ? this.props.editPlannerData.subjects : [],
            boardId: null,
            classId: null,
            groupId: null,
            selectedSubjectIndex: null,
            isShowPreview: false,
            subjects: []
            // calendar: {
            //     "boardId": "b1c0f61f-f03b-4c75-ae90-3a07ba13dbe7",
            //     "title": "werwer",
            //     "startDate": "Tue Jun 07 2022 00:00:00 GMT+0530 (India Standard Time)",
            //     "endDate": "Thu Jun 30 2022 00:00:00 GMT+0530 (India Standard Time)",
            //     "description": "werrwerererere",
            //     "boardName": "Board 1",
            //     "classId": "64db2053-577a-46e1-aa40-5cf66ee45a58",
            //     "className": "Class 2",
            //     "groupId": "e3fed082-da57-4ec4-b093-4f8644d61809",
            //     "groupName": "NO_GROUP",

            // },
            // plannerSubjects: [
            //     {
            //         "chapters": [
            //             {
            //                 "chapterName": "21212",
            //                 "chapterId": "bee7d421-e6fd-4db1-a230-d3d2d0b431bf",
            //                 "startEndDate": [
            //                     "2022-06-06T18:30:00.000Z",
            //                     "2022-06-14T18:30:00.000Z"
            //                 ],
            //                 "preKnowledge": "werwer",
            //                 "learningObjective": "erewre",
            //                 "learningOutcome": "rererererererer"
            //             }
            //         ],
            //         "subjectId": "038e2ef9-4710-418e-92a4-1542adaabd7d",
            //         "subjectName": "111"
            //     },
            //     {
            //         "chapters": [
            //             {
            //                 "chapterName": "test",
            //                 "chapterId": "748009cf-51f4-4f90-babf-56a20c97f5cd",
            //                 "startEndDate": [
            //                     "2022-06-06T18:30:00.000Z",
            //                     "2022-06-20T18:30:00.000Z"
            //                 ],
            //                 "preKnowledge": "ererwere",
            //                 "learningObjective": "rerere",
            //                 "learningOutcome": "rewrererererer"
            //             }
            //         ],
            //         "subjectId": "2bad5ea0-b3d9-485c-a65f-90493320e20d",
            //         "subjectName": "new chapter"
            //     }
            // ]

        }
        this.service = new Service();
    }

    formGradesDataForEdit = (editPlanner) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editPlanner.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editPlanner.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editPlanner.groupId)

                this.setState(
                    {
                        boardId: editPlanner.boardId,
                        classId: editPlanner.classId,
                        groupId: editPlanner.groupId,
                        classes: _classes,
                        groups: _groups,
                        //  boardName: selectedBoard.boardName,
                        //  className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }
    generateEditForm = (editPlanner) => {
        this.formGradesDataForEdit(editPlanner)
    }

    componentDidMount() {

        if (this.props.editPlannerData) {

            this.generateEditForm(this.props.editPlannerData);


        }

        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
    }


    getPlannerSubjects = (_tempSub) => {

        let subjects = _.cloneDeep(_tempSub);



        let plannerSubjects = [];
        if (this.props.editPlannerData) {
            let oldSubjects = this.props.editPlannerData.subjects;

            subjects = subjects.map((sub) => {
                let oldSub = oldSubjects.find(oSub => oSub.subjectId == sub.subjectId);
                if (oldSub) {
                    let oldChapters = oldSub.chapters;
                    let newChapters = [];
                    sub.chapters.map((chapter) => {
                        let oldChapterIndex = oldSub.chapters.findIndex(oCha => oCha.chapterId == chapter.chapterId)
                        if (oldChapterIndex != null) {
                            oldChapters[oldChapterIndex] = {
                                ...oldSub.chapters[oldChapterIndex],
                                chapterName: chapter.chapterName,
                                chapterId: chapter.chapterId
                            }
                            // oldChapters.push({
                            //     ...oldSub.chapters[oldChapterIndex],
                            //     chapterName: chapter.chapterName,
                            //     chapterId: chapter.chapterId
                            // });

                        } else {
                            newChapters.push({
                                ...defaultChapter,
                                chapterName: chapter.chapterName,
                                chapterId: chapter.chapterId
                            })
                        }


                        // let oldChapter = oldSub.chapters.find(oCha => oCha.chapterId == chapter.chapterId)
                        // if (oldChapter) {
                        //     return {
                        //         ...oldChapter,
                        //         chapterName: chapter.chapterName,
                        //         chapterId: chapter.chapterId
                        //     }
                        // } else {
                        //     return {
                        //         ...defaultChapter,
                        //         chapterName: chapter.chapterName,
                        //         chapterId: chapter.chapterId
                        //     }
                        // }
                    });


                    oldChapters = oldChapters.filter(c => sub.chapters.find(a => a.chapterId == c.chapterId));
                    sub.chapters = [...oldChapters, ...newChapters];
                } else {
                    sub = {
                        chapters: sub.chapters.map((chapter) => {
                            return {
                                ...defaultChapter,
                                chapterName: chapter.chapterName,
                                chapterId: chapter.chapterId
                            }
                        }),
                        subjectId: sub.subjectId,
                        subjectName: sub.subjectName
                    }
                }
                return sub;
            })
            plannerSubjects = subjects;

        } else {
            plannerSubjects = subjects.map(sub => {
                return {
                    chapters: sub.chapters.map((chapter) => {
                        return {
                            ...defaultChapter,
                            chapterName: chapter.chapterName,
                            chapterId: chapter.chapterId
                        }
                    }),
                    subjectId: sub.subjectId,
                    subjectName: sub.subjectName
                }

            });
        }
        return plannerSubjects;

    }


    getCurriculumData = (isEdit) => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })

            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {

                    let plannerSubjects = this.getPlannerSubjects(data.res.Item.subjects)


                    let obj = {};
                    data.res.Item.subjects.map(item => {
                        obj[item.subjectId] = item.chapters.map(x => ({
                            chapterId: x.chapterId,
                            chapterName: x.chapterName
                        }))
                    })


                    this.setState({
                        subjectChaptes: obj,
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,
                        plannerSubjects,
                        selectedSubject: plannerSubjects[0].subjectId,
                        selectedSubjectIndex: 0,


                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
        } else {
            this.setState({
                isLoading: false
            })
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.boards != this.props.boards) {
            if (this.props.editPlannerData) {
                this.generateEditForm(this.props.editPlannerData);
            }
        }

        this.callMathJax();
    }

    callMathJax = () => {
        if (window && window.MathJax && window.MathJax.Hub) {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }
    }

    // async componentDidUpdate() {

    //     const { boardId, classId, groupId } = this.state.calendar
    //     if (boardId.length > 0 && classId.length > 0 && groupId.length > 0) {
    //         console.log("before call 1")
    //         let { data } = await axios.get(`${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}`,
    //             {
    //                 headers: {
    //                     'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
    //                 }
    //             });

    //         //  var subjects = this.props.editData

    //         if (data?.Item?.subjects && this.state.masterSubjects.length === 0) {
    //             const activeSubjects = data?.Item?.subjects
    //             var obj = {}

    //             activeSubjects.map(item => {
    //                 obj[item.subjectId] = item.chapters.map(x => ({
    //                     chapterId: x.chapterId,
    //                     chapterName: x.chapterName
    //                 }))
    //             })
    //             this.setState({
    //                 ...this.state,
    //                 subjectChaptes: obj,
    //                 masterSubjects: activeSubjects.map(item => {
    //                     item.chapters = Array(7).fill(defaultChapter);
    //                     item.subjectId = item.subjectId
    //                     item.subjectName = item.subjectName
    //                     return item
    //                 }),
    //                 selectedSubject: data?.Item?.subjects.filter(item => item.isSubjectActive)[0].subjectId
    //             })
    //         }
    //     }

    // }
    // footer = () => {
    //     return (<div>
    //         <Button label="Cancel" className='p-button-outlined' onClick={this.onCloseDialog} />
    //         <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />

    //     </div>)
    // }

    onChangeBoard = (e) => {


        const boardId = e.target.value
        this.setState({
            classes: [], groups: [], classId: null, groupId: null, curriculumInfo: null,
            showSubjects: false,
        });
        var _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);


        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            _classes = selectedBoard.classes
            //selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        var calendar = this.state.calendar
        calendar.boardId = boardId
        calendar.boardName = selectedBoard?.boardName
        calendar.classId = ""
        calendar.className = ""
        calendar.groupId = ""

        this.setState({
            calendar: calendar,
            classes: _classes,
            boardId,
          //  subjects: null,
          //  plannerSubjects: null,
            selectedSubject: null,
            selectedSubjectIndex: null,
            subjectChaptes: null,
            curriculumInfo: null,
            subjects: [],
            plannerSubjects: [],
            selectedSubject: null,
           // selectedSubjectIndex: n,
        });

    };

    onChangeClass = (e) => {
        const boardId = this.state.boardId;
        const classId = e.target.value
        // this.setState({
        //     groups: [], groupId: null,
        //     curriculumInfo: null,
        //     showSubjects: false,
        // });

        var calendar = this.state.calendar
        // this.setState({
        //     calendar: calendar
        // })
        //  const boardId = this.state.calendar.boardId;
        //   var _groups = [];
        const selectedBoard = this.props.boards?.find((board) => board.boardId === boardId);

        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        calendar.classId = classId
        calendar.className = selectedClass.className
        let groupId;
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            calendar.groupId = selectedClass.groups[0].groupId;
            calendar.groupName = selectedClass.groups[0].groupName;
            groupId = selectedClass.groups[0].groupId;
            //  _groups = selectedClass.groups
        }


        this.setState({
            calendar: calendar,
            classId,
            groupId,
            subjectChaptes: null,
            curriculumInfo: null,
            subjects: [],
            plannerSubjects: [],
            selectedSubject: null,
            selectedSubjectIndex: null,

        }, () => {

            this.getCurriculumData();
        })




    };

    isAdditionalValid = () => {
        let isValid = true;
        let subjects = this.state.plannerSubjects;
        let { calendar } = this.state;

        if (subjects && subjects.length) {
            subjects.forEach(s => {
                if (s && s.chapters && s.chapters.length) {
                    s.chapters.forEach((c, cIndex) => {
                        if (!c.chapterId) {
                            c.chapterIdErr = 'Chapter Id is Required';
                            isValid = false
                        } else {
                            c.chapterIdErr = undefined;
                        }
                        if (!c.chapterName) {
                            c.chapterNameErr = 'Chapter Name is Required'
                            isValid = false
                        } else {
                            c.chapterNameErr = undefined;
                        }
                        // if (!c.learningObjective) {
                        //     c.learningObjectiveErr = 'Learning Objective is Required'
                        //     isValid = false
                        // } else {
                        //     c.learningObjectiveErr = undefined
                        // }
                        // if (!c.learningOutcome) {
                        //     c.learningOutcomeErr = 'Learning Outcome is Required'
                        //     isValid = false
                        // } else {
                        //     c.learningOutcomeErr = undefined
                        // }
                        // if (!c.preKnowledge) {
                        //     c.preKnowledgeErr = 'Pre Knowledge is Required'
                        //     isValid = false
                        // } else {
                        //     c.preKnowledgeErr = undefined;
                        // }
                        if (!c.startEndDate || !c.startEndDate.length || c.startEndDate.filter(a => a != null).length != 2) {
                            c.startEndDateErr = 'Start and end date is Required'
                            isValid = false
                        } else {
                            c.startEndDateErr = undefined
                        }
                    })
                }
            })

        } else {
            isValid = false
        }


        subjects.forEach(subject => {
            const chapters = subject.chapters;
            chapters.forEach((chapter, index) => {
                const dateRange = chapter.startEndDate;
                //if (index == 0) return;

                if (index && dateRange && dateRange.length && dateRange.map(a => a != null).length == 2 && dateRange[0] && chapters[index - 1] && chapters[index - 1].startEndDate && chapters[index - 1].startEndDate.map(a => a != null).length == 2 && chapters[index - 1].startEndDate[1] && new Date(dateRange[0]).getTime() <= new Date(chapters[index - 1].startEndDate[1]).getTime()) {
                    chapter.startEndDateErr = 'Start date must be next any day of previous chapter end date.'
                    isValid = false;
                } else if (dateRange && dateRange.length && dateRange.map(a => a != null).length == 2 && (new Date(dateRange[0]).getTime() < new Date(calendar.startDate).getTime() || new Date(dateRange[0]).getTime() > new Date(calendar.endDate).getTime() || new Date(dateRange[1]).getTime() < new Date(calendar.startDate).getTime() || new Date(dateRange[0]).getTime() > new Date(calendar.endDate).getTime())) {
                    chapter.startEndDateErr = 'Start date must be between planner start date and end date'
                    isValid = false;
                }
            })

            const chapterIds = subject.chapters.map(c => c.chapterId);
            let uniqueSet = new Set(chapterIds);
            let uniqueArray = [...uniqueSet];
            if (chapterIds.length != uniqueArray.length) {
                subject.chaptersErr = 'Duplicate chapters selected.';
                isValid = false;
            }
        })

        this.setState({
            plannerSubjects: subjects
        });


        return isValid;

    }

    savePlanner = () => {

        let finalPlanner = this.state.calendar
        finalPlanner.subjects = this.state.plannerSubjects
        let data = _.cloneDeep(finalPlanner);

        const url = `${baseUrlAdmin}/yearlyplanner`;
        this.setState({
            isLoading: true
        })

        data.startDate = moment(data.startDate).format('YYYY-MM-DD');
        data.endDate = moment(data.endDate).format('YYYY-MM-DD');

        if (data.subjects && data.subjects.length) {
            data.subjects = data.subjects.map(s => {
                if (s.chapters && s.chapters.length) {
                    s.chapters = s.chapters.map(c => {
                        c.startEndDate[0] = moment(c.startEndDate[0]).format('YYYY-MM-DD');
                        c.startEndDate[1] = moment(c.startEndDate[1]).format('YYYY-MM-DD');
                        return c;
                    })
                }
                return s;
            })

        }



        this.service[this.props.editPlannerData ? 'put' : 'post'](url, data, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.props.onPlannerCreateorEditSuccess(this.props.editPlannerData ? true : false);
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    onSubmitClick = async (e) => {

        let finalPlanner = this.state.calendar
        finalPlanner.subjects = this.state.plannerSubjects
        let isCompleteFormValid = true;

        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(finalPlanner));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
            isCompleteFormValid = false;
        }

        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields or enter correct data', life: 3000 });
            return;
        }





        if (isCompleteFormValid) {
            this.setState({
                isShowPreview: true
            })
        }



    }

    cancelCreate = () => {
        if (this.state.isShowPreview) {
            this.setState({
                isShowPreview: false
            })
        } else {
            this.props.cancelCreate();
        }

    }

    onExamStartTImeChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {
        let data = JSON.parse(JSON.stringify(formData));

        if ((moment(value).isAfter(moment(getServerTime()).add('-1', 'days')))) {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `start date must be greater than current date.`
            }
        }

        if (!(moment(value).isBefore(data.endDate))) {
            data.endDate = '';
            formValidations.fields['endDate'] = {
                ...formValidations.fields['endDate'],
                isValid: false,
                errorMsg: `Please select end date, start date changed.`
            }
        }

        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    onExamEndTimeChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

        let data = JSON.parse(JSON.stringify(formData));
        data[fieldName] = new Date(value).toString();
        if (moment(value).isAfter(data.fromDateTime)) {

            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `End date must be greater than start date.`
            }
        }
        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    handleChapterChange = (e, chapterIndex) => {


        let { plannerSubjects, selectedSubjectIndex } = this.state;
        let re = this.state.subjects[this.state.selectedSubjectIndex].chapters.find(a => a.chapterId == e.value);

        plannerSubjects[selectedSubjectIndex].chapters[chapterIndex]['chapterId'] = e.value;
        plannerSubjects[selectedSubjectIndex].chapters[chapterIndex]['chapterName'] = re.chapterName;


        this.setState({
            plannerSubjects
        });

        // const { name, value } = e.target
        // const subject = this.state.masterSubjects.find(item => item.subjectId === this.state.selectedSubject)
        // const chapterName = this.state.subjectChaptes[this.state.selectedSubject]?.find(item => item.chapterId === value)?.chapterName
        // const field = name.split("-")[0]
        // const chapters = subject?.chapters
        // if (chapters) {
        //     var chapter = { ...chapters[index], [field]: value, chapterName: chapterName }
        //     const updatedChapters = [...chapters.slice(0, index), chapter, ...chapters.slice(index + 1, 7)]
        //     subject.chapters = updatedChapters
        //     this.setState({
        //         ...this.state,
        //         masterSubjects: masterSubjects.map(item => {
        //             if (item.id === this.state.selectedSubject) {
        //                 return subject
        //             } else {
        //                 return item
        //             }
        //         })
        //     }, () => console.log("state: ,", this.state.masterSubjects))
        // }
    }

    chapterTemplate = (data, { rowIndex }) => {
        const chapters = this.state.plannerSubjects[this.state.selectedSubjectIndex].chapters;

        return (
            <>
                <Dropdown value={chapters && chapters[rowIndex] ? chapters[rowIndex].chapterId : ""}
                    className='w-12'
                    // name={`chapterId-${rowIndex}`}
                    options={this.state.subjects[this.state.selectedSubjectIndex].chapters}
                    // disabled={true}

                    onChange={(e) => { this.handleChapterChange(e, rowIndex) }}
                    optionLabel="chapterName"
                    optionValue="chapterId"
                    placeholder="Select Chapter" />
                {chapters && chapters[rowIndex] && chapters[rowIndex].chapterNameErr ? <p className='ma-required-nopm'>{chapters[rowIndex].chapterNameErr}</p> : ''}
            </>)
    }



    handleChapterDateChange = (dateRange, chapterIndex) => {


        let { plannerSubjects, selectedSubjectIndex } = this.state;
        plannerSubjects[selectedSubjectIndex].chapters[chapterIndex]['startEndDate'] = dateRange;


        if (dateRange.length && dateRange.filter(a => a != null).length == 2) {
            plannerSubjects[selectedSubjectIndex].chapters[chapterIndex]['startEndDateErr'] = undefined;
        }



        this.setState({
            plannerSubjects
        });

    }



    dateTemplate = (data, { rowIndex }) => {

        const subject = this.state.plannerSubjects[this.state.selectedSubjectIndex];
        const chapters = subject?.chapters
        const dateRange = chapters && chapters[rowIndex].startEndDate;


        return (
            <div >
                <Calendar
                    showIcon={true}
                    placeholder="Select Dates"
                    selectionMode="range"
                    readOnlyInput
                    // minDate={new Date()}
                    minDate={this.state.calendar.startDate ? new Date(this.state.calendar.startDate) : getServerTime()}
                    maxDate={this.state.calendar.endDate ? new Date(this.state.calendar.endDate) : getServerTime()}
                    value={(dateRange || []).map(a => a ? new Date(a) : a)}
                    onChange={(e) => this.handleChapterDateChange(e.value, rowIndex)}
                ></Calendar>
                {chapters && chapters[rowIndex] && chapters[rowIndex].startEndDateErr ? <p className='ma-required-nopm'>{chapters[rowIndex].startEndDateErr}</p> : ''}
            </div>
        )
    }

    handleTextEditor = (e, name, chapterIndex) => {

        const value = e.editor.getData()
        let { plannerSubjects, selectedSubjectIndex } = this.state;
        plannerSubjects[selectedSubjectIndex].chapters[chapterIndex][name] = value;

        if (value && value.length) {
            plannerSubjects[selectedSubjectIndex].chapters[chapterIndex][name + 'Err'] = undefined;
        }

        this.setState({
            plannerSubjects
        });
    }
    ckTextChange = (value, name, chapterIndex) => {


        let { plannerSubjects, selectedSubjectIndex } = this.state;
        plannerSubjects[selectedSubjectIndex].chapters[chapterIndex][name] = value;

        if (value && value.length) {
            plannerSubjects[selectedSubjectIndex].chapters[chapterIndex][name + 'Err'] = undefined;
        }

        this.setState({
            plannerSubjects
        });
    }

    ckEditorTemplate = (data, context) => {
        const { rowIndex, field } = context
        const subject = this.state.plannerSubjects[this.state.selectedSubjectIndex];
        if (subject) {
            const chapters = subject?.chapters
            return (<div className={`ckEditor-container`}>
                {/* <CKEditor
                    config={{
                        toolbar: [
                            // ['Styles', 'Format', 'Font', 'FontSize'],
                            ['Bold', 'Italic'],
                            ['Undo', 'Redo'],
                            ['About']
                        ], height: "30px", overflow: "hidden", border: "none"
                    }}
                    events={{
                        "change": (e) => this.handleTextEditor(e, field, rowIndex)
                    }}
                    content={chapters?.length && chapters[rowIndex] ? chapters[rowIndex][field] : ""}

                /> */}
                {/* <CkEditorComponent
                    value={chapters?.length && chapters[rowIndex] ? chapters[rowIndex][field] : ""}
                    onChange={(value) => { this.ckTextChange(value, field, rowIndex) }}
                /> */}
                <InputTextarea
                    value={chapters?.length && chapters[rowIndex] ? chapters[rowIndex][field] : ""}
                    onChange={(e) => { this.ckTextChange(e.target.value, field, rowIndex) }}
                    className='p-inputtext-style1 ma-w100p'
                    rows={5} cols={30}
                />

                {chapters && chapters[rowIndex] && chapters[rowIndex][field + 'Err'] ? <p className='ma-required-nopm'>{chapters[rowIndex][field + 'Err']}</p> : ''}
            </div>)
        }
    }

    handleSubjects = (id) => {
        this.setState({
            ...this.state,
            selectedSubject: id
        })
    }

    handleSelectedRow = (e) => {
        this.setState({
            ...this.state,
            selectedRow: e.index
        })
    }


    render() {
        const { formValidations } = this.state;
        const { calendar, plannerSubjects } = this.state
        return (
            <>
                {
                    this.state.isShowPreview ? <>
                        <PreviewPlanner calendar={{ ...calendar, subjects: plannerSubjects }} />
                    </> : <>
                        <h3 className=''>{this.props.editPlannerData ? 'Edit' : 'Create'} Planner</h3>
                        <div className="p-grid my-3">
                            <div className="p-col-12 p-md-12 p-lg-6">
                                <div className="p-grid flex">
                                    <div className="p-col-4 p-md-4 p-lg-4 m-2 flex flex-column ">
                                        <label className=''>Title<span className='ma-required'>*</span></label>
                                        <InputText value={calendar.title}
                                            className=""
                                            onChange={(e) => { onTextChange(e.target.value, 'title', this, formFields, calendar, formValidations, 'calendar', 'formValidations') }}
                                            name="title" />
                                        {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4 m-2 flex flex-column ">
                                        <label className='text-label12 p-d-block '>Start Date<span className='ma-required'>*</span></label>
                                        <Calendar
                                            showIcon={true}
                                            placeholder='Start Date'
                                            value={calendar.startDate ? new Date(calendar.startDate) : null}
                                            name='startDate'
                                            minDate={getServerTime()}
                                            onChange={(e) => this.onExamStartTImeChange(e.value, 'startDate', this, formFields, calendar, formValidations, 'calendar', 'formValidations')}
                                            id='fromDates'
                                            className='ma-w100p'
                                        ></Calendar>
                                        {formValidations && !formValidations.fields['startDate'].isValid && <p className="p-error">{formValidations.fields['startDate'].errorMsg}</p>}
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4 my-2  m-2 flex flex-column ">
                                        <label className='text-label12 p-d-block '>End Date<span className='ma-required'>*</span></label>
                                        <Calendar
                                            value={calendar.endDate ? new Date(calendar.endDate) : null}
                                            name='endDate'
                                            onChange={(e) => this.onExamEndTimeChange(e.value, 'endDate', this, formFields, calendar, formValidations, 'calendar', 'formValidations')}
                                            showIcon={true}
                                            minDate={new Date(moment(calendar.startDate).add(1, 'day'))}
                                            placeholder='End Date'
                                            id='fromDates'
                                            className='ma-w100p'
                                        ></Calendar>
                                        {formValidations && !formValidations.fields['endDate'].isValid && <p className="p-error">{formValidations.fields['endDate'].errorMsg}</p>}
                                    </div>
                                </div>
                                <div className="p-grid my-2">
                                    <div className="p-col-12 ma-mt10  m-2 flex flex-column w-5">
                                        <label className='text-label12 p-d-block'>Descripiton<span className='ma-required'>*</span></label>
                                        <InputTextarea
                                            value={calendar.description}
                                            onChange={(e) => { onTextChange(e.target.value, 'description', this, formFields, calendar, formValidations, 'calendar', 'formValidations') }}
                                            name="description" className='ma-w100p p-inputtext-style1 ' rows={5} cols={30} />
                                        {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                                    </div>
                                </div>
                                <div className="p-grid dropdown-grid   flex gap-3">
                                    <Authorizer permId={PERMISSIONS.CONTENT_SELECTION_LIST} >
                                        <div className='p-d-flex p-align-items-center w-11rem m-2 flex'>
                                            <Dropdown
                                                value={calendar.boardId}
                                                className='p-from-control w-12'
                                                optionLabel="boardName"
                                                optionValue="boardId"
                                                disabled={this.props.editPlannerData}
                                                options={this.props.boards}
                                                onChange={this.onChangeBoard}
                                                placeholder="Select Board"
                                            />
                                            <span className='ma-required-mark '>*</span>
                                        </div>
                                        <div className='p-d-flex p-align-items-center w-11rem flex p-col-4 p-md-4 p-lg-4'>
                                            <Dropdown
                                                value={calendar.classId}
                                                className='p-from-control'
                                                optionLabel='className'
                                                optionValue='classId'
                                                disabled={this.props.editPlannerData}
                                                options={this.state.classes}
                                                onChange={this.onChangeClass}
                                                placeholder="Select Class"
                                            />
                                            <span className='ma-required-mark '>*</span>
                                        </div>
                                    </Authorizer>
                                </div>
                            </div>
                        </div>

                        <div className="ask-a-doubt-section">

                            {(this.state.plannerSubjects && this.state.plannerSubjects.length > 0 && this.state.selectedSubjectIndex != null) && (<>

                                <div className='doubt-tab-block'>
                                    {this.state.plannerSubjects.map((item, index) => {
                                        return (<>
                                            <Button label={item.subjectName} className={`${this.state.selectedSubjectIndex === index ? "" : "ma-disabled "} mx-2`} onClick={() => this.setState({ selectedSubjectIndex: index })} />
                                        </>)
                                    })}
                                </div>
                                <div>
                                    <div className="card datatable-crud-demo ma-mt20 planner-table-wrap">
                                        <DataTable ref={(el) => this.dt = el}
                                            let-i="rowIndex"
                                            value={this.state.plannerSubjects[this.state.selectedSubjectIndex].chapters}
                                            dataKey="id"
                                            // onRowClick={this.handleSelectedRow}

                                            responsiveLayout="scroll"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        >
                                            {/* <Column  sortable body={(data, props) => {
                                        return props.rowIndex + 1;
                                    }} field="Index" header="No" /> */}
                                            <Column filterMatchMode='contains' field="chapter" header={<>Chapter <span className='ma-star-mark2 '> *</span></>} body={this.chapterTemplate} />
                                            <Column filterMatchMode='contains' field="boardName" header={<>Start Date and End Date <span className='ma-star-mark2 '> *</span></>} body={this.dateTemplate} />
                                            <Column filterMatchMode='contains' field="preKnowledge" header={<>Pre-knowledge </>} body={this.ckEditorTemplate} />
                                            <Column filterMatchMode='contains' field="learningObjective" header={<>Learning Objective </>} body={this.ckEditorTemplate} />
                                            <Column filterMatchMode='contains' field="learningOutcome" header={<>Learning Outcome </>} body={this.ckEditorTemplate} />
                                            <Column header="" body={() => {
                                                return (
                                                    <></>
                                                )
                                            }} />
                                        </DataTable>





                                    </div >
                                    <p className='ma-required'>{this.state.plannerSubjects[this.state.selectedSubjectIndex].chaptersErr}</p>

                                </div>
                            </>)}



                        </div >
                    </>
                }

                <Toolbar className="border-none"
                    right={() => {
                        return <>
                            {
                                this.state.isShowPreview ? <Button label={`${this.props.editPlannerData ? 'Update' : 'Add'}`} className=' ma-mr20 ' onClick={this.savePlanner} /> :
                                    <Button label={'Preview'} className=' ma-mr20 mr-5 ' onClick={this.onSubmitClick} />
                            }
                            <Button label="Cancel" className='p-button-outlined' onClick={this.cancelCreate} />
                        </>
                    }}
                ></Toolbar>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                <div className='ma-clearFix'>
                </div>
                {
                    this.state.isLoading && <LoadingComponent />
                }
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
});

export default connect(mapStateToProps, {
    getBoardsData,
})(Authentication(withRouter(CreatePlanner)));