import React, { Component, createRef } from 'react'
import InputTextB from '../../customComponents/inputTextB'
import { InputText } from 'primereact/inputtext'
import { FIELDS_INFO } from '../../../constants'
import _, { cloneDeep } from 'lodash';
import Service from './../../services';
import { getFormFields, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../utile/formHelper'
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';

import { baseUrlAdmin } from '../../../store/apiConstants';
import { Divider } from 'primereact/divider';
import Authentication from '../../session';
// import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { DeleteIconClasset, MailIcon } from '../../svgIcons';
import { MultiSelect } from 'primereact/multiselect';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { Toast } from 'primereact/toast';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
const createUserFields = require('./createUser.json');


// getFilterEditUser = (data)=>{
//     const filterData={
//         rolename: data?.userInfo.roles[0],
//         branchGroup: data?.userInfo,
//         branches: data?.userInfo,
//         boards: data?.userInfo?.boardId,
//         userNameOrId: data?.userInfo?.username,
//         password: data?.userInfo,
//         confirmPassword: data?.userInfo,
//         firstName: data?.userInfo,
//         lastName: data?.userInfo,
//         userType: data?.userInfo?.userType,
//         email: data?.userInfo?.email,
//         phone_number: data?.userInfo?.phone_number,
//         address: data?.userInfo,
//     }
//     return filterData

// }


export class CreateUserManagement extends Component {


    constructor(props) {
        super(props);
        this.formFields = getFormFields(createUserFields);
        this.state = {
            user: this.props.editUser?._id ? this.props.editUser : this.formFields.data,
            formValidations: this.formFields.formValidations,
            boards: [],
            classes: [],
            rolesOptions: [],
            groupBranchesOptions: [],
            branchesOptions: [],
            boardsOptions: [],
            boardsForAssignOptions: [],
            academicYears: [],
            assignedSubjects: [
                {
                    boardId: "",
                    classId: "",
                    subjectId: "",
                    boardName: "",
                    className: "",
                    subjectName: "",
                    gradeOptions: "",
                    subjectOptions: "",
                }
            ],
            // assignedSubject: [
            //     {
            //         board: { boardId: "", boardName: '', gradeOptions: [], },
            //         grade: { classId: "", className: "", groupId: "", subjectOptions: [] },
            //         subject: { subjectId: "", subjectName: "" },

            //     }
            // ],
            userTypeOptions: [
                { label: "ACADEMIC", value: "academic" },
                { label: "NON-ACADEMIC", value: "non-academic" }
            ],
            sections: [{ value: '21759c65-7c60-4745-bdbc-bcb47a65cf9c', label: 'Testing Section' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf11', label: 'Testing Section 1' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf22', label: 'Testing Section 2' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf33', label: 'Testing Section 3' }],
            isShowCreateSuccess: false,
            hideUpdateButton: false,
            sectionsData: {},
            religionData: [
                { label: "Hindu", value: "hindhu" },
                { label: "Muslim", value: "muslim" },
                { label: "Christian", value: "christian" },
                { label: "Others", value: "othera" }
            ],
            casteData: [
                { label: "General", value: "general" },
                { label: "BC-A", value: "bc-a" },
                { label: "BC-B", value: "bc-b" },
                { label: "BC-C", value: "bc-c" },
                { label: "BC-D", value: "bc-d" },
                { label: "BC-E", value: "bc-e" },
                { label: "SC", value: "sc" },
                { label: "ST", value: "st" }
            ],
            genderData: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
            ],
            qualificationData: [
                { label: "Matriculate", value: "matriculate" },
                { label: "Graduate", value: "graduate" },
                { label: "Post Graduate", value: "postGraduate" },
                { label: "Doctorate", value: "doctorate" },
                { label: "Others", value: "others" },
            ],
            classStudyingDrp: [
                { label: "I", value: "i" }, { label: "II", value: "ii" }, { label: "III", value: "iii" }, { label: "IV", value: "iv" },
                { label: "V", value: "v" }, { label: "VI", value: "vi" }, { label: "VII", value: "vii" }, { label: "VIII", value: "viii" },
                { label: "IX", value: "ix" }, { label: "X", value: "x" }, { label: "XI", value: "xi" }, { label: "XII", value: "xii" },
            ],
            siblings: [
                { name: "", gender: "", classStudying: "", schoolStudying: "" }
            ],
            prevAcadamicDetails: [
                { name: "", classStudied: "", schoolStudied: "", percentageOrGpa: "" }
            ],
        }
        this.toast = createRef();
        this.onUpload = this.onUpload.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.service = new Service();
    }







    componentDidMount() {
        this.getDropDownOptions();
    }

    getDropDownOptions = async () => {
        this.getRolesOptions();
        await this.getGroupsData();
        this.setState({ branchesOptions: this.props.branchData });
        // this.setState({ boardsOptions: this.props.boards });
        if (this.props.editUser?._id) {

            const { user, formValidations } = this.state;
            const nameuser = this.props.editUser?.user_info?.name.split(" ");
            const filterData = {
                rolename: this.props.editUser?.user_info.roles[0] || "",
                branchGroup: this.props.editUser?.user_info.branchgroups || [],
                branches: this.props.editUser?.user_info.branchs || [],
                boards: this.props.editUser?.user_info?.boardId || [],
                userNameOrId: this.props.editUser?.user_info?.username || "",
                firstName: nameuser[0] || "",
                lastName: nameuser[1] || "",
                userType: this.props.editUser?.user_info?.userType || "",
                email: this.props.editUser?.user_info?.email || "",
                phone_number: this.props.editUser?.user_info?.phone_number.slice(3) || "",
                address: this.props.editUser?.user_info?.address || "",
            };

            const filterAssignObjects = [];
            for (let i = 0; i < this.props.editUser?.user_info?.assignedSubjects?.length; i++) {
                const each = this.props.editUser.user_info.assignedSubjects[i];
                const _classes = [];
                const selectedBoard = this.props.boards?.find((board) => board.boardId === each?.boardId);
                if (selectedBoard && selectedBoard.classes?.length > 0) {
                    selectedBoard.classes.forEach((item) =>
                        _classes.push({
                            label: item.className,
                            value: item.classId,
                            groupName: item?.groups?.[0]?.groupName || '',
                            groupId: item?.groups?.[0]?.groupId || ''
                        })
                    );
                }
                const subjOptions = await this.getCurriculumData(i, "fromEdit");
                console.log(subjOptions, "subjectOptions: subjOptions");  // Logging the returned subjectOptions

                filterAssignObjects.push({ ...each, gradeOptions: _classes, subjectOptions: subjOptions });
            }

            const filterboards = this.props.boards.filter((each) => {
                if (this.props.editUser?.user_info?.boardId?.includes(each.boardId)) {
                    return each;
                }
            });

            this.setState({ user: filterData, assignedSubjects: filterAssignObjects, boardsForAssignOptions: filterboards });
        }
    };


    getRolesOptions = () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        let url = `${baseUrlAdmin}/authorize/getRoles?academicYear=${academicYear}`;
        this.service.get(url, true).then(res => {
            if (res && res?.res) {
                this.setState({
                    rolesOptions: res.res?.data
                })

            } else {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }


    getGroupsData = async () => {
        const AcademicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/group?academicYear=${AcademicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ groupBranchesOptions: res?.res?.data });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: "Some error occured", life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
        }

    };




    onUpload() {
        this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }

    onSelect(e) {
        const { user, formValidations } = this.state
        const file = e.files[0];
        if (file && file.type === 'image/png') {
            onTextChange(file, 'studnetPhoto', this, createUserFields, user, formValidations, 'user', 'formValidations')
        } else {
            onTextChange({}, 'studnetPhoto', this, createUserFields, user, formValidations, 'user', 'formValidations')
        }
    }



    onCreateUser = async () => {
        const { user } = this.state
        let body = cloneDeep(user);
        const filterAssignObjects = this.state.assignedSubjects?.map(each => {
            return {
                boardId: each?.boardId,
                classId: each?.classId,
                groupId: each?.groupId,
                subjectName: each?.subjectName,
                subjectId: each?.subjectId,
                boardName: each?.boardName,
                className: each?.className,
                groupName: each?.groupName
            }
        })
        const formStatus = isFormValid(createUserFields, this.formFields.formValidations, body);
        let isCompleteFormValid = true
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, dateError: true });
            isCompleteFormValid = false;
        }
        if (isCompleteFormValid) {
            if (this.props.editUser?._id) {
                const payload = {
                    "_id": this.props.editUser?._id,
                    "roles": user?.rolename, // the role id
                    "username": user?.userNameOrId,
                    "firstName": user?.firstName,
                    "lastName": user?.lastName,
                    "userType": user?.userType, //non-academic
                    "branchgroups": user?.branchGroup,
                    "branchs": user?.branches,
                    "email": user?.email,
                    'address': user?.address,
                    "phone_number": `+91${user?.phone_number}`,
                    "boardId": user?.boards,
                    "studentPhoto": user?.studentPhoto,
                    "assignedSubjects": filterAssignObjects
                }
                let url = `${baseUrlAdmin}/admin/updateAdminUser`;
                this.service.put(url, payload, true).then(res => {
                    console.log(res, "res")
                    if (res.res?.status == 200 && res?.status) {
                        this.setState({
                            user: {}, assignedSubjects: {
                                boardId: "",
                                classId: "",
                                subjectId: "",
                                boardName: "",
                                className: "",
                                subjectName: "",
                                gradeOptions: "",
                                subjectOptions: "",
                            }
                        })
                        this.props.onTabChange(2,"Edit");
                        this.toast.show({ severity: 'success', summary: 'User has been updated succesfully', detail: "", life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
                    }
                }).catch((e) => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
                });
            }
            else {
                const payload = {
                    "roles": user?.rolename, // the role id
                    "username": user?.userNameOrId,
                    "password": user?.password,
                    "firstName": user?.firstName,
                    "lastName": user?.lastName,
                    "userType": user?.userType, //non-academic
                    "branchgroups": user?.branchGroup,
                    "branchs": user?.branches,
                    "email": user?.email,
                    'address': user?.address,
                    "phone_number": `+91${user?.phone_number}`,
                    "boardId": user?.boards,
                    "studentPhoto": user?.studentPhoto,
                    "assignedSubjects": filterAssignObjects
                }
                let url = `${baseUrlAdmin}/admin/addAdminUser`;
                this.service.post(url, payload, true).then(res => {
                    if (res.status && res?.res?.success) {
                        this.setState({
                            user: {}, assignedSubjects: {
                                boardId: "",
                                classId: "",
                                subjectId: "",
                                boardName: "",
                                className: "",
                                subjectName: "",
                                gradeOptions: "",
                                subjectOptions: "",
                            }
                        })
                        this.props.onTabChange(2,"Create");
                        this.toast.show({ severity: 'success', summary: 'User has been created succesfully', detail: "", life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch((e) => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                });
            }
        }
    }


    addBoards = () => {
        const newBoard = {
            boardId: "",
            classId: "",
            subjectId: "",
            boardName: "",
            className: "",
            subjectName: "",
            gradeOptions: "",
            subjectOptions: "",
        }
        this.setState(prevState => ({
            assignedSubjects: [...prevState.assignedSubjects, newBoard]
        }));
    }

    removeBoards = (i) => {
        if (this.state.assignedSubjects.length > 1) {
            const filterAssignObjs = this.state.assignedSubjects.filter((each, index) => index != i)
            this.setState(prevState => ({
                assignedSubjects: [...filterAssignObjs]
            }));
        }
    }




    componentDidUpdate(prevProps, prevState) {
        const { user, formValidations, groupBranchesOptions } = this.state

        if (prevState.user.branchGroup?.length != this.state.user.branchGroup?.length) {
            const totalBranchgroups = user.branchGroup;
            let filterBranches = [];
            let filterBranchesData = [];
            groupBranchesOptions?.filter(each => {
                if (totalBranchgroups?.includes(each._id)) {
                    return each.branches?.map(each => filterBranches.push(each))
                }
            });


            if (filterBranches?.length != this.props.branchData) {
                this.props.branchData?.filter(each => {
                    if (filterBranches?.includes(each?.key)) {
                        return filterBranchesData.push(each?.key)
                    }
                })
            }
            console.log(filterBranchesData, "filterBranches")
            console.log(this.props.branchData, "this.props.branchData")
            onDropDownChange(filterBranchesData || [], 'branches', this, createUserFields, user, formValidations, 'user', 'formValidations')
        }
        if (prevState.user.branches?.length != this.state.user.branches?.length) {

            const filterBoards = [];
            this.state.user.branches?.map(eachId => {
                this.props.boards.filter(each => {
                    if (each?.assignedBranches?.includes(eachId)) {
                        return filterBoards.push(each);
                    }
                })
            })
            const uniqueBoards = Array.from(new Map(filterBoards.map(item => [item.boardId, item])).values());
            if (!uniqueBoards?.length) {
                onDropDownChange([], 'boards', this, createUserFields, user, formValidations, 'user', 'formValidations')
            }
            console.log(uniqueBoards, "uniqueBoards")
            this.setState({ boardsOptions: uniqueBoards })
        }
        if (prevState.user.boards?.length != this.state.user.boards?.length) {
            const filterboards = this.props.boards.filter(each => {
                if (user.boards?.includes(each.boardId)) {
                    return each
                }
            });
            this.setState({ boardsForAssignOptions: filterboards })
        }

    }


    getCurriculumData = async (indexPosition, type) => {
        try {
            const obj = this.props.editUser?._id ? this.props.editUser?.user_info?.assignedSubjects : this.state.assignedSubjects;
            const filterObj = obj?.filter((each, index) => index == indexPosition);
            const boardId = filterObj[0]?.boardId;
            const classId = filterObj[0]?.classId;
            const groupId = filterObj[0]?.groupId;

            if (boardId && classId) {
                this.setState({
                    isLoading: true,
                    curriculumInfo: {}
                });

                const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
                const data = await this.service.get(url, true);
                console.log(data, "Fetched curriculum data");  // Logging fetched data

                if (data && data.res && data.res.Item) {
                    if (this.props.editUser?._id && type == "fromEdit") {
                        this.setState({ isLoading: false });
                        return data.res?.Item;
                    } else {
                        console.log(this.state.assignedSubjects, "this.state.assignedSubjects")
                        const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
                            if (indexPosition == index) {
                                return { ...each, subjectOptions: data.res.Item };
                            } else {
                                return each;
                            }
                        });
                        console.log(filterAssignObjects, "filterAssignObjects")

                        this.setState({
                            assignedSubjects: filterAssignObjects,
                            isLoading: false
                        });

                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                isLoading: false
            });
        }
    };



    onChangeClass = (classId, indexPosition) => {

        let selectedClass = this.state.assignedSubjects[indexPosition]?.gradeOptions?.find((classd) => classd?.value == classId)

        const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
            if (indexPosition == index) {
                return { ...each, classId: classId, className: selectedClass?.label, groupId: selectedClass?.groupId, groupName: selectedClass?.groupName }
            }
            else {
                return each
            }
        })
        this.setState({
            assignedSubjects: filterAssignObjects,
        }, () => this.getCurriculumData(indexPosition, "onChange"));
    };


    onChangeSubject = (subjectId, indexPosition) => {
        let selectedSubject = this.state.assignedSubjects[indexPosition]?.subjectOptions.subjects?.find((classd) => classd.subjectId == subjectId)
        const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
            if (indexPosition == index) {
                return { ...each, subjectId: subjectId, subjectName: selectedSubject.subjectName }
            }
            else {
                return each
            }
        })
        this.setState({
            assignedSubjects: filterAssignObjects,
        });
    };


    onChangeBoard = (boardId, indexPosition) => {
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupName: item?.groups ? item?.groups[0]?.groupName : '', groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
        }
        let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName

        const filterAssignObjects = this.state.assignedSubjects.map((each, index) => {
            if (indexPosition == index) {
                return { ...each, boardId: boardId, boardName: selectedBoardName, gradeOptions: _classes }
            }
            else {
                return each
            }
        })

        this.setState({
            assignedSubjects: filterAssignObjects,
        });

    };



    render() {
        const { user, formStatus, assignedSubjects, formValidations, rolesOptions, groupBranchesOptions, sectionsData } = this.state;
        const isDisabled = true
        return (
            <>
                <div className='grid flex flex-column justify-content-center mt-2 ml-6'>
                    <div className='grid'>
                        <div className="col-11  mt-4 flex justify-content-end align-items-center">
                            <Button
                                label="Save"
                                icon={<i className='pi pi-plus-circle text-xl ml-3 -mr-3'></i>}
                                className='ageCriteriaSaveBtn'
                                onClick={() => this.onCreateUser()}
                            />
                        </div>
                    </div>

                    <div className='grid'>
                        <div className='col-12 flex flex-column justify-content-start align-items-start'>
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                options={rolesOptions}
                                label="Select Role"
                                required={true}
                                optionLabel="roleName"
                                optionValue="id"
                                formHelperError={formValidations && !formValidations.fields['rolename'].isValid && formStatus}
                                className=''
                                value={user.rolename}
                                onChange={(e) => { onTextChange(e.target.value, 'rolename', this, createUserFields, user, formValidations, 'user', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className=' ml-2 '>Select Role</span>
                                    </div>
                                )}
                            />
                            {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error text-lg ">{formValidations.fields['rolename'].errorMsg}</p>}

                        </div>
                        <div className='col-12 mt-5 flex justify-content-start align-items-center'>
                            <h3 className='font-bold'>Access Level</h3>
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetMultiSelect
                                value={user.branchGroup}
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                onChange={(e) => { onDropDownChange(e.target.value, 'branchGroup', this, createUserFields, user, formValidations, 'user', 'formValidations'); }}
                                options={groupBranchesOptions}
                                label="Branch Groups"
                                optionLabel='name'
                                optionValue="_id"
                                width={"343px"}
                                className=''
                                maxSelectedLabels={3}
                                placeholder='Select Branch Group' />
                            {formValidations && !formValidations.fields['branchGroup'].isValid && <p className="p-error">{formValidations.fields['branchGroup'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetMultiSelect
                                value={user.branches}
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                onChange={(e) => { onDropDownChange(e.target.value, 'branches', this, createUserFields, user, formValidations, 'user', 'formValidations') }}
                                options={this.props.branchData}
                                label="Branches"
                                optionLabel='name'
                                required={true}
                                width={"343px"}
                                optionValue='key'
                                className=''
                                maxSelectedLabels={3}
                                placeholder='Select Branches' />

                            {formValidations && !formValidations.fields['branches'].isValid && <p className="p-error">{formValidations.fields['branches'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetMultiSelect
                                value={user.boards}
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                onChange={(e) => { onDropDownChange(e.target.value, 'boards', this, createUserFields, user, formValidations, 'user', 'formValidations') }}
                                options={this.state.boardsOptions}
                                label="Boards"
                                optionLabel='boardName'
                                required={true}
                                width={"343px"}
                                optionValue='boardId'
                                className='text-lg shadow-2 pl-2 h-3rem'
                                maxSelectedLabels={3}
                                placeholder='Select Boards' />

                            {formValidations && !formValidations.fields['boards'].isValid && <p className="p-error">{formValidations.fields['boards'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                className='border-round-md  border-none'
                                label="UserName / ID"
                                required={true}
                                formHelperError={formValidations && !formValidations.fields['userNameOrId']?.isValid && formStatus}
                                value={user.userNameOrId}
                                onChange={(e) => { onTextChange(e.target.value, 'userNameOrId', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                placeholder="Enter UserName" />
                            {formValidations && !formValidations.fields['userNameOrId'].isValid && <p className="p-error">{formValidations.fields['userNameOrId'].errorMsg}</p>}
                        </div>
                        {!this.props.editUser?._id &&
                            <>
                                <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                                    <ClassetInputText
                                        icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                        className='border-round-md  border-none'
                                        label="Password"
                                        isPassword={true}
                                        required={true}
                                        formHelperError={formValidations && !formValidations.fields['password']?.isValid && formStatus}
                                        value={user.password}
                                        onChange={(e) => { onTextChange(e.target.value, 'password', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                        placeholder="Enter Password" />
                                    {formValidations && !formValidations.fields['password']?.isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                                </div>
                                <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                                    <ClassetInputText
                                        icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                        className='border-round-md  border-none'
                                        label="Confirm Password"
                                        isPassword={true}
                                        required={true}
                                        formHelperError={formValidations && !formValidations.fields['confirmPassword']?.isValid && formStatus}
                                        value={user.confirmPassword}
                                        onChange={(e) => { onTextChange(e.target.value, 'confirmPassword', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                        placeholder="Enter Confirm Password" />
                                    {formValidations && !formValidations.fields['confirmPassword'].isValid && <p className="p-error">{formValidations.fields['confirmPassword'].errorMsg}</p>}
                                </div>
                            </>
                        }
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                className='border-round-md  border-none'
                                label="First Name"
                                required={true}
                                formHelperError={formValidations && !formValidations.fields['firstName'].isValid && formStatus}
                                value={user.firstName}
                                onChange={(e) => { onTextChange(e.target.value, 'firstName', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                placeholder="Enter First Name" />
                            {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error">{formValidations.fields['firstName'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                className='border-round-md  border-none'
                                label="Last Name"
                                required={true}
                                formHelperError={formValidations && !formValidations.fields['lastName'].isValid && formStatus}
                                value={user.lastName}
                                onChange={(e) => { onTextChange(e.target.value, 'lastName', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                placeholder="Enter Last Name" />
                            {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error">{formValidations.fields['lastName'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                options={this.state.userTypeOptions}
                                optionLabel="label"
                                optionValue="value"
                                label={"User Type"}
                                required={true}
                                formHelperError={formValidations && !formValidations.fields['userType'].isValid && formStatus}
                                className=''
                                value={user.userType}
                                onChange={(e) => { onDropDownChange(e.target.value, 'userType', this, createUserFields, user, formValidations, 'user', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className='  '>Select User Type</span>
                                    </div>
                                )}
                            />
                            {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-8 lg:col-8 md:col-12 col-12">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                className='border-round-md  border-none'
                                label="Email Address"
                                width={"600px"}
                                required={true}
                                formHelperError={formValidations && !formValidations.fields['email'].isValid && formStatus}
                                value={user.email}
                                onChange={(e) => { onTextChange(e.target.value, 'email', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                placeholder="Email Address" />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                className='border-round-md  border-none'
                                label="Contact Number"
                                required={true}
                                formHelperError={formValidations && !formValidations.fields['phone_number'].isValid && formStatus}
                                value={user.phone_number}
                                onChange={(e) => { onNumberChange(e.target.value, 'phone_number', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                placeholder="Contact Number(Mobile)" />
                            {formValidations && !formValidations.fields['phone_number'].isValid && <p className="p-error">{formValidations.fields['phone_number'].errorMsg}</p>}
                        </div>
                        <div className="xl:col-8 lg:col-8 md:col-12 col-12">
                            <ClassetTextArea
                                className='addAdmissionDilogBoxdesc custom-field-focused border-300 shadow-2 border-round-sm pl-3 body-medium'
                                label="Address"
                                width={"600px"}
                                value={user.address}
                                onChange={(e) => { onTextChange(e.target.value, 'address', this, createUserFields, user, formValidations, 'user', 'formValidations') }}

                                placeholder="Address For Communication"
                            />
                            {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                        </div>
                        <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                className='border-round-md  border-none '
                                label="Photo"
                                required={true}
                                formHelperError={formValidations && !formValidations.fields['studnetPhoto'].isValid && formStatus}
                                value={user.studnetPhoto}
                                // onChange={(e) => { onTextChange(e.target.value, 'studnetPhoto', this, createUserFields, user, formValidations, 'user', 'formValidations') }}
                                placeholder="File Name" />
                            <div className=" flex justify-content-start mt-5">
                                <Toast ref={this.toast}></Toast>
                                <FileUpload
                                    mode="basic"
                                    name="demo[]"
                                    url="/api/upload"
                                    accept="image/png"
                                    maxFileSize={1000000}
                                    onUpload={this.onUpload}
                                    onSelect={this.onSelect}
                                    auto
                                    chooseLabel="Browse"
                                />
                                {this.state.selectedFileName && <p>Selected file: {this.state.selectedFileName}</p>}
                            </div>
                            {/* <Button className='mt-3' style={{ borderRadius: 10, backgroundColor: '#076EFF', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', color: 'white' }} onClick={() => { this.setState({ isDialogOpenForCreateConcession: false }) }}>
                                <p className='text-xl'>Browse</p>
                            </Button> */}
                        </div>
                        <Divider type="solid" className="custom-divider" align="center" />
                        <div className='col-12'>
                            {Array.isArray(assignedSubjects) && assignedSubjects.map((obj, index) => (
                                <div className="grid py-0 -mt-2" key={index}>
                                    <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={this.state.boardsForAssignOptions}
                                            optionLabel="boardName"
                                            optionValue="boardId"
                                            label={"Board"}
                                            required={true}
                                            // formHelperError={formValidations && !formValidations.fields['admissionStep'].isValid && formStatus}
                                            className=' no-border border-round-md shadow-2 '
                                            onChange={(e) => this.onChangeBoard(e.value, index)}
                                            value={obj?.boardId}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=''>Select Board</span>
                                                </div>
                                            )}
                                        />
                                        {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                                    </div>
                                    <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                        <ClassetDropdown
                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            options={obj?.gradeOptions}
                                            optionLabel="label"
                                            optionValue="value"
                                            label={"Grade"}
                                            required={true}
                                            onChange={(e) => this.onChangeClass(e.value, index)}
                                            className=' no-border border-round-md shadow-2 '
                                            value={obj?.classId}
                                            placeholder={(
                                                <div className="flex justify-content-start  align-items-center">
                                                    <span className=' '>Select Grade</span>
                                                </div>
                                            )}
                                        />
                                        {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                                    </div>
                                    <div className="grid xl:col-4 lg:col-4 md:col-4 col-12">
                                        <div className='col-10'>
                                            <ClassetDropdown
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                value={obj?.subjectId}
                                                options={obj?.subjectOptions?.subjects}
                                                label="Subjects"
                                                optionLabel='subjectName'
                                                optionValue="subjectId"
                                                required={true}
                                                width={"343px"}
                                                onChange={(e) => this.onChangeSubject(e.value, index)}
                                                className=''
                                                maxSelectedLabels={3}
                                                placeholder={(
                                                    <div className="flex justify-content-start  align-items-center">
                                                        <span className=''>Select Subject</span>
                                                    </div>
                                                )} />
                                        </div>

                                        <div className='col-2 pl-4 pt-5 flex justify-content-start align-items-center  cursor-pointer' onClick={() => this.removeBoards(index)} style={{ cursor: 'pointer' }}>
                                            <DeleteIconClasset width={22} height={22} />
                                        </div>

                                        {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                                    </div>



                                </div>
                            ))}
                            <div className='mt-2 flex justify-content-between align-content-center'>
                                <Button className='mt-2' style={{ borderRadius: 10, backgroundColor: '#F4F5F6', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', color: 'black' }} onClick={this.addBoards} >
                                    <p className='text-xl'>Add More</p>
                                </Button>
                            </div>
                        </div>


                    </div>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />

                </div >
            </>
        )
    }
}



const mapStatesToProps = (state) => ({
    // boards: userAssignedBoards(state, 'activeBoards'),
    boards: state.boardsData && state.boardsData.boards || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    boardsDropDownOptions: state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions || [],
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data?.data?.filter(each => {
        if (each.level == 1) {
            return { ...each }
        }
    }) || []

});
export default connect(mapStatesToProps, {})(Authentication(withRouter(CreateUserManagement)));