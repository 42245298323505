import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authentication from './../session';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import LoadingComponent from '../loadingComponent';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import {
    getContentRepoBoards
} from './../../store/actions';
import './styles.scss'
import ContentPlayer from './contentPlayers';
import _ from 'lodash';
import withRouter from '../lib/withRouter';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon, SubjectIcon } from '../svgIcons';


class AddFromRepo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: 'upload',
            globalFilter: '',
            selectedTopicResourceTypes: _.cloneDeep(this.props.selectedTopicResourceTypes),
            selectedContent: [],
            content: [],
            filteredContentDetails: []

        }
        this.service = new Service();
    }



    componentDidMount() {
        // if (!this.props.boards || !this.props.boards.length) {
        this.props.getContentRepoBoards();
        // } else {
        //     const boardId = this.props.boards[0].boardId;
        //     const _classes = [];
        //     const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        //     if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
        //         selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        //     }
        //     this.setState({
        //         classes: _classes,
        //         boardId,
        //     });
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards && this.props.boards.length) {
                const boardId = this.props.boards[0].boardId;
                const _classes = [];
                const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

                if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                    selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
                }
                this.setState({
                    classes: _classes,
                    boardId,
                });
            }
        }


    }

    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], classId: null,
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
        });

    };

    onChangeClass = (classId) => {
        this.setState({
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })

            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
        } else {
            this.setState({
                isLoading: false
            })
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a, b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                content: null,
                filteredContentDetails: []

            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: []
            });
    };

    onChangeTopic = (topicId) => {
        this.setState(
            {
                topicId,
                content: null
            }, () => {
                this.getContentDetails();
            });
    };

    checkIsAlreadyAssigned = (contentDetails) => {
        let assignedContents = [];
        if (this.props.contentInfo && this.props.contentInfo.length) {
            this.props.contentInfo.forEach((con) => {
                let inde1;
                let assignedCont = contentDetails.find((a, index) => {
                    if (a.contentId == con.contentId) {
                        inde1 = index
                        return a;
                    }
                });
                if (inde1) {
                    contentDetails[inde1].localName = con.contentName;
                }

                if (assignedCont) {
                    assignedContents.push(con);
                }
            });
        }
        return assignedContents;
    }

    getType = (type) => {
        switch (type) {
            case 'ppt':
                return 'zip';
            default:
                return type;
        }


    }

    filterResource = (contents, selectedTopicResourceTypes) => {
        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);
        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == this.getType(filter.value))
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))
        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }



    getContentDetails = () => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}`;
        this.service.get(url, true).then((res) => {
            if (res && res.res && res.res.Item) {
                let assignedContents = this.checkIsAlreadyAssigned(res.res.Item.contentDetails);
                let filteredContentDetails = [];
                if (res.res.Item.contentDetails) {
                    filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                }
                this.setState((prevState) => {
                    return {
                        content: res.res.Item,
                        isLoading: false,
                        selectedContent: assignedContents,
                        filteredContentDetails
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }


    onSelectResourceType = (type) => {


        let selectedTopicResourceTypes = this.state.selectedTopicResourceTypes;
        let selectedTopicResourceType;
        selectedTopicResourceTypes = selectedTopicResourceTypes.map(r => {
            if (type.value == r.value) {
                r.isSelected = true;

                selectedTopicResourceType = r.value
            } else {
                r.isSelected = false
            }
            return r;
        });

        let contentDetails = (this.state.content && this.state.content.contentDetails) ? this.state.content.contentDetails : [];
        contentDetails = this.filterResource(contentDetails, selectedTopicResourceTypes)
        this.setState({
            selectedTopicResourceTypes,
            filteredContentDetails: contentDetails
        });
    }








    onSelectContent = (e) => {

        let selectedContent = _.cloneDeep(this.state.selectedContent);

        selectedContent = [...selectedContent, ...e.value];


        selectedContent = Array.from(new Set(selectedContent.map(JSON.stringify))).map(JSON.parse)

        this.setState({ selectedContent: selectedContent }, this.props.onSelectContent(selectedContent, this.state.content))
    }

    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    isSelected = (rowData, selectedItems, uniqField) => {
        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {

        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts
        }, () => { this.props.onSelectContent(selectedIts, items) })





    }

    render() {

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="flex gap-2 mb-4">
                    {
                        this.state.selectedTopicResourceTypes && this.state.selectedTopicResourceTypes.length ? this.state.selectedTopicResourceTypes.map((type) => {
                            return <Button className={` formSubmitButton ${!type.isSelected ? 'p-button-outlined' : ''}`} label={type.label} onClick={() => this.onSelectResourceType(type)} />
                        }) : null
                    }
                </div>

            </div>
        );

        // console.log('boardsc', this.props.boards)
        return <div >
            <div className='flex gap-3 flex-wrap'>
                <div className=''>
                    <ClassetDropdown width={'250px'} required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.props.boards} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                        optionValue="boardId" />
                </div>
                <div>
                    <ClassetDropdown width={'250px'} required={true} label={'Grade'} icon={<GradesIcon />} placeholder={'Select Grade'} options={this.state?.classes}
                        value={this.state.classId} onChange={(e) => this.onChangeClass(e.value)} />
                </div>
                <div>
                    <ClassetDropdown width={'250px'} required={true} label={'Subject'} icon={<SubjectIcon height={16.5} width={19.5} color={'#000000'} />} placeholder={'Select Subject'} options={this.state.subjects} value={this.state.subjectId} onChange={(e) => this.onChangeSubject(e.value)} optionLabel="subjectName" optionValue="subjectId" />
                </div>
                <div>
                    <ClassetDropdown width={'250px'} required={true} label={'Chapter'} icon={<SubjectIcon height={16.5} width={19.5} color={'#000000'} />} placeholder={'Select Chapter'} options={this.state?.chapters} value={this.state.chapterId} onChange={(e) => this.onChangeChapter(e.value)} optionLabel="chapterName" optionValue="chapterId" />
                </div>
                <div>
                    <ClassetDropdown width={'250px'} required={true} label={'Topic'} icon={<SubjectIcon height={16.5} width={19.5} color={'#000000'} />} placeholder={'Select Topic'} options={this.state.topics} value={this.state.topicId} onChange={(e) => this.onChangeTopic(e.value)} optionLabel='topicName'
                        optionValue='topicId' />
                </div>
            </div>
            {/* <Button label="Fetch Resources" className=' ma-m-lr10' /> */}

            <h5 className='tbl-title mt-4 font-bold'>Available Resources</h5>
            <div className="card datatable-crud-demo -mt-2 ">
                {/* {this.state.filteredContentDetails && this.state.filteredContentDetails.length ? <> */}
                <DataTable
                    globalFilter={this.state.globalFilter}
                    //  lazy
                    header={header}

                    //  value={this.state.roles}
                    value={this.state.filteredContentDetails || []}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    // selection={this.state.selectedContent}
                    // onSelectionChange={this.onSelectContent}
                    dataKey="contentId"
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    rows={10}
                    showGridlines
                    responsiveLayout="scroll"
                    selectionMode="checkbox"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                >
                    <Column headerStyle={{ width: '4em' }} field='contentId' header={<Checkbox inputId={'item.id'}
                        checked={this.isSelectedAllItems(this.state.filteredContentDetails, this.state.selectedContent, 'contentId')}
                        onChange={(e) => { this.selectOrdeselect('list', this.state.selectedContent, this.state.filteredContentDetails, e.checked, 'selectedContent', 'contentId') }}
                    ></Checkbox>}
                        body={(rowData) => {
                            return <Checkbox inputId={'item.id'}
                                checked={this.isSelected(rowData, this.state.selectedContent, 'contentId')}
                                onChange={(e) => { this.selectOrdeselect('single', this.state.selectedContent, rowData, e.checked, 'selectedContent', 'contentId') }}
                            ></Checkbox>
                        }}>
                    </Column>
                    <Column field="contentFileType" header="Resourse Type" />
                    <Column field="localName" header="Resource Name (in Local)" />
                    <Column field="contentName" header="Resource Name (in Repository)" />
                    <Column body={(rowData) => {
                        return (
                            <div className="preview-c" >

                                <span onClick={() => {
                                    this.setState({
                                        previewContent: rowData,
                                        isShowPreview: true
                                    });
                                }} > <i className='pi pi-eye ma-mr10' />Preview </span>
                            </div>
                        )
                    }} field="status" header="Preview" />
                </DataTable>
                {/* </>
                    : null} */}
            </div>

            {
                this.state.isLoading && <LoadingComponent />
            }
            {this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                this.setState({
                    isShowPreview: false
                })
            }} contentInfo={this.state.previewContent} />}
        </div>
    }
}



const mapStateToProps = (state) => ({
    boards: state.boardsData.contentRepoBoards,
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getContentRepoBoards
})(Authentication(withRouter(AddFromRepo)));