import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EventService from '../../../service/EventService';
import { Dropdown } from 'primereact/dropdown';
import { AddIconInButtonFull, CalenderIcon, ClockIcon, CodeLinkIcon, MailIcon } from '../../svgIcons';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { getFormFields, isFormValid, onDropDownChange, onTextChange, onToggleChange } from '../../../utile/formHelper';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import BoardToSectionDDCol from '../../BaseDropdownComponents/BoardToSectionDDCol';
import { cloneDeep } from 'lodash';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../services';
const createFields = require('./academicCalender.json');


const EverntTypeOptions = [
    { value: 'AE', label: "Academic Event" },
    { value: 'SE', label: "Sports Event" },
    { value: 'CE', label: "Cultural Event" },
    { value: 'ME', label: "Miscellaneous Event" },
    { value: 'EE', label: "Examination" },
    { value: 'HE', label: 'Holiday' }
]

const repeatypeOptions = [
    { value: 'daily', label: "Daily" },
    { value: 'weakly', label: "Weakly" },
    { value: 'monthly', label: "Monthly" },
    { value: 'yearly', label: "Yearly" },

]
class AcademicCalendar extends Component {
    constructor(props) {
        super(props)
        this.formFields = getFormFields(createFields, this.props.editData);
        this.state = {
            formValidations: this.formFields?.formValidations,
            formData: {},
            eventDialog: false,
            clickedEvent: null,
            changedEvent: { title: '', start: null, end: null, allDay: null },
            events: null,
            isDialogOpenForAddEvent: false,
            selectedEvent: '',
            dropdownsData:{}
        };
        this.service = new Service();

    }

    componentDidMount() {
        const eventService = new EventService();
        eventService.getEvents().then((data) => this.setState({ events: data }));
    }

    eventClick = (e) => {
        console.log(e.event, "eeeeeepppp", e.event.title)
        const { title, start, end } = e.event;
        this.setState({
            eventDialog: true,
            clickedEvent: e.event,
            changedEvent: { title, start, end, allDay: null }
        });
    };

    save = () => {
        this.setState({ eventDialog: false });
        const { clickedEvent, changedEvent } = this.state;
        clickedEvent.setProp('title', changedEvent.title);
        clickedEvent.setStart(changedEvent.start);
        clickedEvent.setEnd(changedEvent.end);
        clickedEvent.setAllDay(changedEvent.allDay);
        this.setState({ changedEvent: { title: '', start: null, end: '', allDay: null } });
    };

    reset = () => {
        const { clickedEvent } = this.state;
        const { title, start, end } = clickedEvent;
        this.setState({ changedEvent: { title, start, end, allDay: null } });
    };
    setDropdownValues = (dropdownsData) => {
        console.log(dropdownsData,"dropdownsDatadropdownsData")
        this.setState({
            dropdownsData:dropdownsData
        },
            // () => { this.getStudents()}
        )
    }
    onCancel = () => {
        this.setState({
            isDialogOpenForAddEvent: false,
            formData: {},
            fullDayEvent: '',
            startTime: "",
            endTime: '',
            date: '',
            startOn: '',
            endsOn: '',
            repeatype: ''


        })
    }


    onAddEvent = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = this.state.formData;

        payload.academicYear=academicYear;
        payload.boardId=this.state.dropdownsData.boardId
        payload.branchId=this.state.dropdownsData.branchId
        payload.classId=this.state.dropdownsData.classId
        payload.sectionId=this.state.dropdownsData.sectionId
        payload.selectedBoardName=this.state.dropdownsData.selectedBoardName
        payload.selectedClassName=this.state.dropdownsData.selectedClassName
        payload.selectedbranchName=this.state.dropdownsData.selectedbranchName
        payload.selectedsectionName=this.state.dropdownsData.selectedsectionName

        
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/event-calender/create`
        
        // let payload = {
        // }
        // if (data?.eventType === 'AE' || data?.eventType === 'SE' || data?.eventType === 'CE' || data?.eventType === 'ME') {

        // } else if (data?.eventType === 'ME' || data?.eventType === 'EE') {
        //     payload.transctionId = this.state?.transctionId
        // }

        const formStatus = isFormValid(createFields, this.formFields.formValidations, payload);
        let isCompleteFormValid = true
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations, });
            isCompleteFormValid = false;
        }
        console.log(payload, "dattttt")

        if (isCompleteFormValid) {
            console.log(payload, "payload")
            this.service.post(url, payload, true).then(data => {
                if (data.status) {
                    this.setState({
                        isLoading: false,
                        isDialogOpenForAddEvent: false,
                        formData: {},
                       

                    })
                    this.toast.show({ severity: 'success', summary: '', detail: data?.res?.data, life: 3000 });


                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Error', life: 3000 });

                }

            }).catch(e => {
                this.setState({ isLoading: false })
            })
        } else {
            //     if (!flag1) {
            //         this.setState({ isLoading: false })
            //         this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter All Require Fields', life: 3000 });
            //     } else if (!flag2) {
            //         this.setState({ isLoading: false })
            //         this.toast?.show({ severity: 'error', summary: 'Please Check Validation', detail: 'Please Enter Transaction Id ', life: 3000 });

            // }

        }
    }


    render() {
        const { eventDialog, changedEvent, events, formData, formValidations } = this.state


        return (
            <>
                <div className='grid'>
                    <div className='col-2 card shadow-5 my-5 '>
                        <div className='flex justify-content-center  '>
                            <Button className='shadow-5' style={{ borderRadius: 10, backgroundColor: '#076EFF', }} onClick={() => this.setState({ isDialogOpenForAddEvent: true })}>
                                <p className='addButtonClass' style={{ fontSize: '12px' }}>Add Event</p>
                            </Button>
                        </div>
                        <div className='mt-8'>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ borderColor: '#076EFF', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Today</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px', backgroundColor: '#F8F9FA' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Sunday</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ borderColor: '#419720', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Academic Event</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px', backgroundColor: '#268900' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Holiday</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#BF0031', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Examination</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#FAB710', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Sports Event</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex '>
                                    <div class="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Miscellaneous Event</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10">
                        {/* <div className="col-12"> */}
                        <div className=" calendar-demo">
                            <FullCalendar
                                events={events}
                                eventClick={this.eventClick}
                                initialDate="2023-01-01"
                                initialView="dayGridMonth"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{ left: 'title', center: '', right: 'prev,next' }}
                                editable
                                selectable
                                selectMirror
                                dayMaxEvents
                            />

                        </div>
                        {/* </div> */}
                    </div>
                </div>

                <Dialog
                    visible={this.state.isDialogOpenForAddEvent}
                    draggable={false}
                    // closeOnEscape={false}
                    style={{ position: 'fixed', right: '0', height: '100%' }}
                    closable={true}
                    className="pay-fee"

                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="sideHeading" style={{ fontSize: 36, color: 'black' }}>Add Event </h4>

                        </div>)
                    }}
                    onHide={this.onCancel}
                >
                    <div className='mb-5 ml-5 mr-5' >
                        <div className="">
                            <div className="col-12 ">
                                <label className='label mb-2 '>Eventname<span className='ma-required'>*</span></label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='eventName'
                                        icon={<CodeLinkIcon height={22} />}
                                        className=""
                                        height="48px"
                                        width="361px"
                                        placeholder="Enter Event name"
                                        value={formData.eventName}
                                        // disabled={true}
                                        onChange={(e) => onTextChange(e.target.value, 'eventName', this, createFields, formData, formValidations, 'formData', formValidations)}

                                    />
                                </div>
                                {formValidations && !formValidations.fields['eventName'].isValid && <p className="p-error">{formValidations.fields['eventName'].errorMsg}</p>}
                            </div>
                            <div className="col-12">
                                <label className='label mb-2 '>Event Type<span className='ma-required'>*</span></label>
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id='eventType'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="361px"
                                        options={EverntTypeOptions}
                                        value={formData.eventType}
                                        onChange={(e) => { onDropDownChange(e.target.value, 'eventType', this, createFields, formData, formValidations, 'formData', formValidations) }}
                                        placeholder={(
                                            <div>
                                                <span className=''>Select Event Type</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                {formValidations && !formValidations.fields['eventType'].isValid && <p className="p-error">{formValidations.fields['eventType'].errorMsg}</p>}
                            </div>


                            {formData.eventType && <>
                                {(formData.eventType == 'AE' || formData.eventType == 'SE' || formData.eventType == 'CE' || formData.eventType == 'ME') && <div className='col-12'>
                                    <div className="flex">
                                        <InputSwitch
                                            id="fdEvent"
                                            className=''
                                            checked={!formData?.recurring && formData?.fdEvent}
                                           
                                            onChange={(e) => {
                                                onToggleChange(e.value, 'fdEvent', this, createFields, formData, formValidations, 'formData', formValidations)
                                                this.setState({ fullDayEvent: false })
                                            }}

                                        />
                                        <label className='label  ml-2 '>Full Day Event</label>
                                    </div>
                                </div>}
                                {(!this.state.fullDayEvent && (formData.eventType == 'AE' || formData.eventType == 'SE' || formData.eventType == 'CE' || formData.eventType == 'ME')) && <div className="col-12 flex">
                                    <div>
                                        <label className='label mb-2 '>Start Time<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<ClockIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="100px"
                                                placeholder="Select Start Time"
                                                calendarMode="single"
                                                timeOnly
                                                id="startDateTime"
                                                value={formData?.startDateTime}
                                                onChange={(e) => { onTextChange(e.value, 'startDateTime', this, createFields, formData, formValidations, 'formData', formValidations) }}


                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                    <div className="ml-4">
                                        <label className='label mb-2 '>End Time<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<ClockIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="100px"
                                                placeholder="Select End Time"
                                                calendarMode="single"
                                                timeOnly
                                                value={formData?.endDateTime}
                                                id="endDateTime"
                                            
                                                onChange={(e) => { onTextChange(e.value, 'endDateTime', this, createFields, formData, formValidations, 'formData', formValidations) }}


                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                </div>}


                                {!(
                                    formData?.recurring &&
                                    (formData.eventType === 'HE' || formData.eventType === 'EE')
                                ) && (
                                    <div className="col-12 ">

                                    <label className='label mb-2 '>Date<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetCalendar
                                            icon={<CalenderIcon height={20} />}
                                            className=""
                                            height="48px"
                                            width="361px"
                                            placeholder="Select Date"
                                            calendarMode="single"
                                            id="eventDate"
                                            value={formData?.eventDate}
                                           
                                            onChange={(e) => { onTextChange(e.target.value, 'eventDate', this, createFields, formData, formValidations, 'formData', formValidations) }}


                                        />
                                    </div>
                                    {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                </div>
                                    )}
                           
                                <div className='col-12'>
                                    <div className="flex">
                                        <InputSwitch
                                            id='recurring'
                                            className=''
                                            checked={formData?.recurring}
                                            onChange={(e) => {
                                                onToggleChange(e.value, 'recurring', this, createFields, formData, formValidations, 'formData', formValidations)
                                                this.setState({ fullDayEvent: false })
                                            }}

                                        />
                                        <label className='label  ml-2 '>Recurring</label>
                                    </div>
                                </div>
                                {formData?.recurring && <>

                                    <div className="col-12 ">
                                        <label className='label mb-2 '>Start On<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<CalenderIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="361px"
                                                placeholder="Select Date"
                                                calendarMode="single"
                                                id="recstartDate"
                                                value={this.state.startOn}
                                                onChange={(e) => { onTextChange(e.target.value, 'recstartDate', this, createFields, formData, formValidations, 'formData', formValidations) }}
                                                

                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                    <div className="col-12 ">
                                        <label className='label mb-2 '>Ends On<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<CalenderIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="361px"
                                                placeholder="Select Date"
                                                calendarMode="single"
                                                id="recendDate"
                                                // timeOnly
                                                value={this.state.endsOn}
                                                //onChange={(e) => this.setState({ endsOn: e.value })}

                                                onChange={(e) => { onTextChange(e.target.value, 'recendDate', this, createFields, formData, formValidations, 'formData', formValidations) }}


                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                    </div>
                                    <div className="col-12">
                                        <label className='label mb-2 '>Repeat<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetDropdown
                                                id='repeat'
                                                icon={<MailIcon />}
                                                className=""
                                                height="48px"
                                                width="361px"
                                                options={repeatypeOptions}
                                            
                                                value={formData.repeat}

                                                onChange={(e) => { onDropDownChange(e.target.value, 'repeat', this, createFields, formData, formValidations, 'formData', formValidations) }}
                                                placeholder={(
                                                    <div>
                                                        <span className=''>Select Repet</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['eventType'].isValid && <p className="p-error">{formValidations.fields['eventType'].errorMsg}</p>} */}
                                    </div>
                                </>}
                                {/*  {(!formData?.recurring && (formData.eventType === 'HE' || formData.eventType === 'EE')) &&
                                    <div className="col-12 ">

                                        <label className='label mb-2 '>Datefff<span className='ma-required'>*</span></label>
                                        <div className="mt-2">
                                            <ClassetCalendar
                                                icon={<CalenderIcon height={20} />}
                                                className=""
                                                height="48px"
                                                width="361px"
                                                placeholder="Select Date"
                                                calendarMode="single"
                                                id="eventDate"
                                                value={this.state.date}
                                                onChange={(e) => this.setState({ date: e.value })}

                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                                {/* </div>}/ */}



                                {(formData.eventType === 'AE' || formData.eventType === 'SE' || formData.eventType === 'CE' || formData.eventType === 'ME') &&
                                    <div className="col-12 ">
                                        <label className='label'>Event Description<span className='ma-required'>*</span></label>
                                        <div className='mt-2'>
                                            <ClassetTextArea
                                                id="eventDescrption"
                                                className='border-round-md   border-none '
                                                height={50}
                                                value={formData?.description}
                                                onChange={(e) => { onTextChange(e.target.value, 'eventDescrption', this, createFields, formData, formValidations, 'formData', 'formValidations') }}
                                                placeholder="Description" />

                                        </div>
                                        {/* {formValidations && !formValidations.fields['description']?.isValid && <p className="p-error text-lg ">{formValidations.fields['description']?.errorMsg}</p>} */}

                                    </div>}

                                {(formData.eventType === 'AE' || formData.eventType === 'SE' || formData.eventType === 'CE' || formData.eventType === 'ME' && (!formData?.recurring)) &&

                                    <BoardToSectionDDCol setValues={this.setDropdownValues} Required={false} />}
                            </>

                            }
                            <div className="mt-3" style={{ display: 'flex' }}>
                                <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }}
                                    // onClick={() => { this.setState({ isDialogOpenForAddEvent: true iiii}) }}
                                    onClick={this.onAddEvent}
                                >
                                    <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />

                                    <p className='submitButtonnText'>Add Event</p>
                                </Button>
                                <Button style={{ border: 0, borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}
                                    onClick={this.onCancel}
                                // onClick={() => { this.setState({ isDialogOpenForAddEvent: false }) }}
                                >
                                    <p className='cancelButtonnText'>Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        );
    }
}

export default AcademicCalendar
