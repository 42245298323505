import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import SvgViewer from '../../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import ClassetDropdown from '../../../classetComponents/classetDropDown';

import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import './styles.scss'
// import UserInfo from './userInfo';
// import CreateUser from './createUser';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import IndividualUsageAnalytics from '../../analytics/usageAnalytics/individualUsageAnalytics';
import { AnalyticsIcon2, BoardsIconInActive, FlappyDiscIcon, GradesIcon, MailIcon, WarningIcon } from '../../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../../internationalization';
import withRouter from '../../lib/withRouter';
import InputTextB from '../../customComponents/inputTextB';
import { Dropdown } from 'primereact/dropdown';
import { getBoardsData } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';


class AssignManagementTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            users: [],
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            vehicleDropdown: [],
            selectedBuses: {},
            selectedStops: {},
            routeData: [],
            selectedStudentPickup: {},
            selectedStudentDrop: {},
            planId: '',
            busNo: null,
            boardId: null,
            classId: null,
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
        }
        this.service = new Service();
    }


    componentDidMount() {
        this.props.getBoardsData(true);
        this.getDropdownsData()
        this.getRoutesDataForDropdown()
    }

    onSaveStudent = async (rowData, rowIndex,) => {
        const payload = this.constructPayload(rowData, rowIndex);
        let url = `${baseUrlAdmin}/transport-route/student/add`
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({
                    selectedBuses: {},
                    selectedStudentPickup: {},
                    selectedStudentDrop: {}
                })
                this.toast.show({ severity: 'success', summary: res?.res?.message, detail: "created", life: 3000 });
                this.getAssignTransportData()

            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

            }
        } catch (e) {
            console.log(e);
            this.toast.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
        }
    }

    onSaveStudentAll = async () => {
        const { users } = this.state;
        const transportStudentDataArray = [];

        users.forEach((rowData, rowIndex) => {
            const payload = this.constructPayload(rowData, rowIndex);
            if (payload.transportRoute && payload.pickstop && payload.dropstop) {
                transportStudentDataArray.push({
                    transportRoute: payload.transportRoute,
                    pickstop: payload.pickstop,
                    dropstop: payload.dropstop,
                    studentId: payload.studentId
                });
            }
        });

        const payload = {
            transportStudentDataArray: transportStudentDataArray
        };

        let url = `${baseUrlAdmin}/transport-route/student/addall`
        if (payload?.transportStudentDataArray?.length > 0) {
            try {
                const res = await this.service.post(url, payload, true);
                if (res?.res?.status && res.status) {
                    this.setState({
                        selectedBuses: {},
                        selectedStudentPickup: {},
                        selectedStudentDrop: {}
                    })
                    this.toast.show({ severity: 'success', summary: res?.res?.message, detail: "created", life: 3000 });
                    this.getAssignTransportData();
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                }
            } catch (e) {
                console.log(e);
                this.toast.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
            }
        }
        else {
            this.toast.show({ severity: 'error', summary: "", detail: 'Please Assign Some Data', life: 3000 });
        }
    }

    constructPayload = (rowData, rowIndex) => {
        return {
            "transportRoute": this.state.selectedBuses[rowIndex],
            "pickstop": this.state.selectedStudentPickup[rowIndex],
            "dropstop": this.state.selectedStudentDrop[rowIndex],
            "studentId": rowData?.user_id,
            "planId": this.state?.planId
        };
    }

    getDropdownsData = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        let url = `${baseUrlAdmin}/transport-route/meta-data?academicYear=${academicYear}`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({
                    planId: res?.res?.data?.planId,
                })

            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error In Getting Dropdown', life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };


    getRoutesDataForDropdown = async () => {
        let url = `${baseUrlAdmin}/transport-route/get-routes/`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const routeName = res?.res?.data?.routeData.map(routeName => ({
                    label: routeName.routeName,
                    value: routeName._id
                }));
                this.setState({ vehicleDropdown: routeName, routeData: res?.res?.data?.routeData });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };


    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });

                this.setState({
                    boards: boards
                });
            }
        }

        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }
    }


    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], classId: null, curriculumInfo: null,
            isShowSubjects: false,
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard?.classes && selectedBoard?.classes?.length > 0) {
            selectedBoard?.classes.forEach((item) => _classes?.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
        }
        let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName
        this.setState({
            classes: _classes,
            boardId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedBoardName: selectedBoardName
        });
    };

    onChangeClass = (classId) => {
        let selectedClass = this.state?.classes?.find((classd) => classd.value == classId)
        this.setState({
            classId: classId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedClassName: selectedClass?.label,
            groupId: selectedClass.groupId

        },);
    };

    getAssignTransportData = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        this.setState({ isLoading: true })

        let url = `${baseUrlAdmin}/transport-route/students/getforRoutes?boardId=${this.state.dropdownsData.boardId}&gradeId=${this.state.dropdownsData.classId}&sectionId=${this.state.dropdownsData.sectionId}&academicYear=${academicYear}`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const users = res?.res?.data?.addmissionTransport;
                const selectedBuses = {};
                const selectedStops = {};
                const selectedStudentPickup = {};
                const selectedStudentDrop = {};

                users.forEach((user, index) => {
                    selectedBuses[index] = user.transportRoute;
                    selectedStudentPickup[index] = user.pickStop;
                    selectedStudentDrop[index] = user.dropstop;
                    const route = this.state.routeData.find(route => route._id === user.transportRoute);
                    if (route) {
                        selectedStops[index] = route.stops;
                    }
                });

                this.setState({
                    users,
                    selectedBuses,
                    selectedStops,
                    selectedStudentPickup,
                    selectedStudentDrop,
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.filterUsersData);
    }

    onCreateOrEditSuccess = () => {
        if (this.state.editUser) {

            this.setState({ isCreateUser: false, editUser: false }, () => {
                this.filterUsersData();
            })
        } else {
            this.setState({ isCreateUser: false, editUser: false })
        }
    }

    setDropdownValues = (dropdownsData) => {

        this.setState({
            dropdownsData
        }, () => this.getAssignTransportData())
    }

    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>Students List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>

                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state?.columns?.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        const { selectedUser, isShowUserInfo, isShowAnalytics, editUser } = this.state
        return (
            <>
                <div className="ml-5 mt-5 flex justify-content-start align-items-center">
                    <BoardToSectionDD setValues={this.setDropdownValues} />
                </div>
                <div className="card datatable-crud-demo mt-5">
                    <DataTable ref={(el) => this.dt = el}
                        className="dataTableValuesText"
                        columnResizeMode="expand"
                        value={this.state.users}
                        dataKey="id"
                        paginator
                        // lazy
                        responsiveLayout="scroll"
                        showGridlines
                        onPage={this.onPage}
                        rows={10}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={''}>

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="S.No." className="datatable-cell" body={(rowData, index) => {
                            return (
                                <>
                                    <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + index.rowIndex + 1}</p>
                                </>
                            )
                        }} />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="studentName" field="name" header="Student Name" />
                        {/* <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="parentName" field="parentName" header="Parent Name" /> */}
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="contactDetails" field="phone_number" header="Contact Details" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Assigned/Not Assigned" body={(rowData, { rowIndex }) => {
                            const style = {
                                color: 'black', 
                                backgroundColor: rowData?.transportRoute ? '#C4F3C7' : '#F1BFB8',
                                padding: '0.5rem', 
                                borderRadius: '7px'
                            };
                            return (

                                <p style={style}> {rowData?.transportRoute ? "Assigned" : "Not Assigned"}</p>
                            );
                        }} ></Column>
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Bus Name" body={(rowData, { rowIndex }) => {
                            return (
                                <ClassetDropdown
                                    optionLabel={"label"}
                                    optionValue={"value"}
                                    width={"169px"}
                                    height={"48px"}
                                    className='text-xl add-vehicle-field-input'
                                    value={this.state.selectedBuses[rowIndex] || null}
                                    options={this.state.vehicleDropdown}
                                    onChange={(e) => {
                                        const selectedRouteId = e.target.value;
                                        const selectedBuses = { ...this.state.selectedBuses, [rowIndex]: selectedRouteId };
                                        const selectedRoute = this.state.routeData.find(route => route._id === selectedRouteId);
                                        const selectedStops = { ...this.state.selectedStops, [rowIndex]: selectedRoute ? selectedRoute.stops : [] };
                                        this.setState({ selectedBuses, selectedStops });
                                    }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className='text-lg pl-1'> Select Bus</span>
                                        </div>
                                    )}
                                />
                            );
                        }} ></Column>
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="PickUp Location" body={(rowData, { rowIndex }) => {
                            return (
                                <ClassetDropdown
                                    optionLabel={"label"}
                                    optionValue={"value"}
                                    width={"169px"}
                                    height={"48px"}
                                    className='text-xl add-vehicle-field-input'
                                    value={this.state?.selectedStudentPickup[rowIndex] || null}
                                    options={this.state?.selectedStops[rowIndex]?.map(stop => ({
                                        label: stop.stopName,
                                        value: stop._id
                                    })) || []}
                                    onChange={(e) => {
                                        const selectedStudentPickup = { ...this.state.selectedStudentPickup, [rowIndex]: e.target.value };
                                        this.setState({ selectedStudentPickup });
                                    }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className='text-lg pl-1'> Select Location</span>
                                        </div>
                                    )}
                                />
                            );
                        }} ></Column>
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Drop Location" body={(rowData, { rowIndex }) => {
                            return (
                                <ClassetDropdown
                                    optionLabel={"label"}
                                    optionValue={"value"}
                                    width={"169px"}
                                    height={"48px"}
                                    className='text-xl add-vehicle-field-input'
                                    value={this.state?.selectedStudentDrop[rowIndex] || null}
                                    options={this.state?.selectedStops[rowIndex]?.map(stop => ({
                                        label: stop.stopName,
                                        value: stop._id
                                    })) || []}
                                    onChange={(e) => {
                                        const selectedStudentDrop = { ...this.state.selectedStudentDrop, [rowIndex]: e.target.value };
                                        this.setState({ selectedStudentDrop });
                                    }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className='text-lg pl-1'> Select Location</span>
                                        </div>
                                    )}
                                />
                            );
                        }} ></Column>
                    </DataTable >
                </div >
                <div className="text-center mt-4">
                    <Button label='Assign' onClick={this.onSaveStudentAll} className='confirmDialogAcceptBtn  mr-5 w-19rem' />
                    <Button label="Cancel" className='confirmDialogCancelBtn ' />
                </div>
                {
                    isShowAnalytics && <IndividualUsageAnalytics
                        userInfo={selectedUser}
                        onHide={() => { this.setState({ isShowAnalytics: false }) }} />
                }
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
});

export default connect(mapStateToProps, { getBoardsData })(Authentication(withRouter(AssignManagementTable)));