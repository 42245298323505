import React, { Component } from 'react'
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataToProfile: {}
    }

    this.service = new Service()
  }


  render() {
    const { dataToProfile } = this.props;
    return (
      <>
        <div className='grid white-bg tab-active-body  '>
          {/* <div className='p-col-6 p-grid'>
            <div className='p-col-6'>
              <h3 className='ma-table-title'>Admission Number</h3>
            </div>
            <div className='p-col-6'>
              <p className='ma-table-title'> : {dataToProfile?.admissionNo}</p>
            </div>
          </div> */}

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Student Name</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.given_name}</p>
            </div>
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Board</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile["custom:board"]}</p>
            </div>
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Class</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile["custom:grade"]}</p>
            </div>
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Section</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.section}</p>
            </div>
          </div>

          {/* <div className='p-col-6 p-grid'>
            <div className='p-col-6'>
              <h3 className='ma-table-title'>Admission Type</h3>
            </div>
            <div className='p-col-6'>
              <p className='ma-table-title'> : {dataToProfile?.admissionType}</p>
            </div>
          </div> */}

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Father Name</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.fatherName}</p>
            </div>
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Mobile No</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.phone_number}</p>
            </div>
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Occupation</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.fatherOccupation}</p>
            </div>
          </div>
          <div className='col-6'></div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Mother Name</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.motherName}</p>
            </div>
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Mobile No</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.motherMobileNo}</p>
            </div>
            
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Occupation</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.motherOccupation}</p>
            </div>
          </div>

          <div className='col-6 grid p-2'>
            <div className='col-6'>
              <h3 className='ma-table-title'>Guardian Name</h3>
            </div>
            <div className='col-6'>
              <p className='ma-table-title'> : {dataToProfile?.guardianName}</p>
            </div>
          </div>

        </div>

        <Toast ref={(el) => this.toast = el} position="bottom-right" />

        {this.state.isLoading && <LoadingComponent />}
      </>

    )
  }
}
