import React, { Component } from 'react';
import { connect } from 'react-redux';
import _, { cloneDeep } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authentication from './../session';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import LoadingComponent from '../loadingComponent';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Service from '../../services';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import {
    getBoardsData
} from './../../store/actions';
import './styles.scss'
import AddFromRepo from './addFromRepo';
import AddFromLocal from './addFromLocal';
import { ContetOrigin, ContentOrigins, getFileTypes, getContentResourseType, warningDailogInit, ContentSource, getContentSource, FileTypes, BasicLazyParams } from '../../utile/index';
import uuidv4 from 'uuid/v4';
import EditContent from './editContent';
import ContentPlayer from './contentPlayers';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { WarningIcon } from '../svgIcons';
import { RadioButton } from 'primereact/radiobutton';
import QRCode from 'qrcode.react';
import { InputSwitch } from 'primereact/inputswitch';
import withRouter from '../lib/withRouter';

const items = [
    { label: 'Academics' },
    { label: 'Content Master', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


class ContentMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: null,
            filteredContentDetails: [],
            warningDialog: warningDailogInit,
            isShowEditContent: false,
            selectedContent: null,
            globalFilter: '',
            //contests[3],

            qrDialog: false,
            selectedQrCode: "",
            qrCodes: [],
            globalFilter: '',
            showSearch: false,
            lazyParams: BasicLazyParams,
            globalSearch: '',
            viewQr: false,
            selectedResourceQrid: '',
            contentLanguagesList: [],
            contentParentId: null,
            isShowModalPreview: false
        }
        this.service = new Service();
    }



    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
        this.fetchContentLanguageApi()
    }

    componentDidUpdate(prevProps) {
        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }
    }

    fetchContentLanguageApi() {
        const url = `${baseUrlAdmin}/content/languages`;

        this.service.get(url, true).then((res) => {

            this.setState({ contentLanguagesList: res?.res?.data })
        });
    }

    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a,b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                content: null,
                filteredContentDetails: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: []
            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopicResource = [];
        let selectedTopic = this.state.topics.filter(t => t.topicId == topicId)
        let selectedTopicResourceTypes = selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [];
        if (selectedTopicResourceTypes && selectedTopicResourceTypes.length) {
            selectedTopicResourceTypes = selectedTopicResourceTypes.map((a, index) => {
                if (index == 0) {
                    a.isSelected = true;
                } else {
                    a.isSelected = false;
                }
                return a;
            })
        }

        this.setState(
            {
                topicId,
                selectedTopicResourceTypes: selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [],
                content: null,
                filteredContentDetails: []
            }, () => {
                if (this.state.selectedTopicResourceTypes && this.state.selectedTopicResourceTypes.length) {
                    this.getContentDetails();
                }
            });
    };


    filterResource = (contents, sTR) => {
        let selectedTopicResourceTypes = _.cloneDeep(sTR)

        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);

        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == filter.value)
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))

        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }


    // fixCrashData = (content1) => {
    //     let contentDetails = content1.contentDetails;
    //     let fileTypes = ['mp4', 'zip', 'pdf', 'quiz'];

    //     let contents = [];
    //     fileTypes.forEach(filetype => {
    //         let filtered = contentDetails.filter((conent) => conent.contentFileType.toLowerCase() == filetype.toLowerCase());


    //         filtered = filtered.map((a, index) => {
    //             a.priority = index + 1;
    //             let id = uuidv4();
    //             a.contentId = id;
    //             return a;
    //         });

    //         contents = [...contents, ...filtered];
    //     });

    //     const { boardId, classId, groupId, subjectId, chapterId, topicId } = content1;
    //     this.updateContent({ boardId, classId, groupId, subjectId, chapterId, topicId, contents });

    // }
    getContentDetailsWithLanguage = (API_URL) => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;
        const url = `${API_URL}?etag=true`;
        this.service.get(url, true).then((res) => {

            if (res && res.res && res.res.Item) {
                this.setState(() => {
                    return {
                        content: { boardId, classId, groupId, subjectId, chapterId, topicId, ...res.res.Item },
                    }
                });
            }
        }).catch(e => {
            console.log(e)
        });
    }

    getContentDetails = () => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        this.setState({
            isLoading: true,
        });
        const API_URL = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}`
        const url = `${API_URL}/contentLanguages?etag=true`;
        this.getContentDetailsWithLanguage(API_URL)
        this.service.get(url, true).then((res) => {

            const etag = res && res.etag;



            if (res && res.res && res.res.Item) {
                let filteredContentDetails = [];
                if (res.res.Item.contentDetails) {
                    filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                }
                this.setState((prevState) => {
                    return {
                        // content: { boardId, classId, groupId, subjectId, chapterId, topicId, ...res.res.Item },
                        filteredContentDetails,
                        isLoading: false,
                        etag
                    }
                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    updateContent = (content) => {
        this.setState({
            isLoading: true
        });
        console.log("contentcontentcontentcontentcontentcontentcontentcontentcontentcontent", content)

        if (content && content.contents && content.contents.length) {
            content.contents.forEach(ct => {
                if (ct.childContent) {
                    delete ct.childContent
                }
            })
        }
        const url = `${baseUrlForLongRunningTask}/long/content`;
        this.service.put(url, content, true, { "If-Match": this.state.etag }).then((data) => {


            if (data && data.res && data.res.success) {

                this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Successfully modified, modified file may reflect in 10-15 mins', life: 6000 });
                this.setState({
                    isLoading: false,
                    isShowAddDialog: false,
                    resourseOrigin: this.state.isBaseBoard ? 'upload' : null,
                    selectedParentData: null, contentParentId: null
                }, this.getContentDetails);
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });


        })
    }

    addClick = (type) => {

        this.setState({
            selectedType: type,
            isShowAddDialog: true
        });


    }

    addOrUpdateContent = (contentInfo) => {

        let duplicatePriority = false;
        let duplicateName = false;
        let priority = 1;

        if (this.state.content.contentDetails && this.state.content.contentDetails.length > 0) {
            this.state.content.contentDetails.forEach((x) => {
                // if (x.priority === this.state.contentInfo.contentPriority) {
                //     duplicatePriority = true;
                // }

                // if (parseInt(x.priority) > priority && contentInfo.contentFileType == x.contentFileType) {
                //     priority = parseInt(x.priority);
                // }
                if (x.contentName.toLowerCase() === contentInfo.contentName.toLowerCase() && x.contentFileType == contentInfo.contentFileType) {
                    duplicateName = true;
                }
            });
        }
        if (duplicateName) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Name matches with another resource.. Kindly change it.', life: 3000 });
        }
        // else if (duplicatePriority) {
        //     this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Priority matches with another resource.. Kindly change it.', life: 3000 });
        // } 
        else {
            const previousContentInfo = this.state.content.contentDetails || [];
            const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state.content;


            let filtered = previousContentInfo.filter((conent) => conent.contentFileType.toLowerCase() == contentInfo.contentFileType.toLowerCase());
            const content = {
                boardId, classId, groupId, subjectId, chapterId, topicId,
                contents: [
                    {
                        ...contentInfo,
                        priority: filtered.length + 1
                    },
                    ...previousContentInfo
                ]
            };

            // console.log('content', content);


            this.updateContent({ ...content });
        }

    };

    onSelectContent = (checkedContetForAssign, contentRepo) => {
        this.setState({
            checkedContetForAssign, contentRepo
        })
    };


    assignContentTo = () => {


        //  if (this.props.selectedOrganizationDataIds) {
        const { checkedContetForAssign, } = this.state;
        const { boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId } = this.state.content;


        if (!checkedContetForAssign || !checkedContetForAssign.length) {
            //    this.snackBarOpen('Please select content');
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one content to assign', life: 3000 });
            return;
        }

        if (boardId && classId && groupId && subjectId && chapterId && topicId) {
            const previousContentInfo = _.cloneDeep(this.state.content.contentDetails) || [];
            if (checkedContetForAssign && checkedContetForAssign.length) {
                // let duplicateContent = checkedContetForAssign;

                // checkedContetForAssign.forEach((element) => {
                //     this.props.contentInfo.forEach((ele) => {
                //         if (
                //             element.contentStorageKey !== ele.contentStorageKey &&
                //             element.priority === ele.priority &&
                //             element.contentResourceType.toLowerCase() === ele.contentResourceType.toLowerCase()
                //         ) {
                //             duplicatePriority = true;
                //         }
                //     });
                // });

                // if (checkedContetForAssign.length > 0) {
                //     checkedContetForAssign.forEach((element) => {
                //         duplicateContent.forEach((ele) => {
                //             if (
                //                 element.contentStorageKey !== ele.contentStorageKey &&
                //                 element.priority === ele.priority &&
                //                 element.contentResourceType.toLowerCase() === ele.contentResourceType.toLowerCase()
                //             ) {
                //                 duplicatePriority = true;
                //             }
                //         });
                //     });
                //     checkedContetForAssign.forEach((element) => {
                //         this.props.selectedContentDataForAssign.forEach((ele) => {
                //             if (element.contentId === ele.contentId) {
                //                 if (ele.childCurriculumContentKeys) {
                //                     ele.childCurriculumContentKeys.push(this.props.contentKey);
                //                 } else {
                //                     ele['childCurriculumContentKeys'] = [this.props.contentKey];
                //                 }
                //             }
                //         });
                //     });
                // }

                checkedContetForAssign.forEach((contentToAssign) => {
                    let record = previousContentInfo.find((a) => a.contentId == contentToAssign.contentId);

                    if (!record) {
                        let cont = _.cloneDeep(contentToAssign);
                        cont.source = 2;
                        cont.childCurriculumContentKeys = undefined;
                        let filtered = previousContentInfo.filter((conent) => conent.contentFileType.toLowerCase() == cont.contentFileType.toLowerCase());

                        cont.priority = filtered.length + 1;


                        previousContentInfo.push(cont);
                    }
                });

                const content = {
                    boardId,
                    classId,
                    groupId,
                    subjectId,
                    chapterId,
                    topicId,
                    contents: previousContentInfo
                };

                // if (duplicatePriority) {
                //     this.snackBarOpen('Priority matches with another resource.. Kindly change it.');
                // } else if (checkedContetForAssign === previousContentInfo) {
                //     this.setState({
                //         openSnackBar: true,
                //         snakBarMsg: 'duplicate content'
                //     });
                // } else {
                // this.setState({
                //     selectedContetIds: {}
                // });

                this.updateContent({ ...content });



                // let contentRepo = _.cloneDeep(this.state.contentRepo);
                // checkedContetForAssign.forEach((element) => {

                //     contentRepo.contentDetails = contentRepo.contentDetails.map((ele) => {
                //         if (element.contentId === ele.contentId) {
                //             if (ele.childCurriculumContentKeys && ele.childCurriculumContentKeys.length) {
                //                 if (!ele.childCurriculumContentKeys.includes(this.state.content.contentKey)) {
                //                     ele.childCurriculumContentKeys.push(this.state.content.contentKey);
                //                 }
                //             } else {
                //                 ele.childCurriculumContentKeys = [this.state.content.contentKey];
                //             }
                //         }
                //         return ele;
                //     });
                // });



                // const prevContent = {
                //     boardId: contentRepo.boardId,
                //     classId: contentRepo.classId,
                //     groupId: contentRepo.groupId,
                //     subjectId: contentRepo.subjectId,
                //     chapterId: contentRepo.chapterId,
                //     topicId: contentRepo.topicId,
                //     contents: contentRepo.contentDetails
                // };


                // 
                // this.updateContent({
                //     ...prevContent
                // });
                // }
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one content to assign', life: 3000 });
                return;
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please selct board, class, group', life: 3000 });
            return;
        }

    };


    exportCSV = () => {
        this.dt.exportCSV();
    }


    createRoleDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null, selectedParentData: null, contentParentId: null }) }} />
            {
                this.state.resourseOrigin == 'repo' && <>
                    <Button label={`Assign`} className='ma-m-lr10' onClick={this.assignContentTo} />
                </>
            }
        </div>)
    };


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 >Content master</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    moveRecords = (records, old_index, new_index) => {
        let arr = _.cloneDeep(records);
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        arr = arr.map((a, index) => {
            a.priority = index + 1;
            return a;
        })
        return arr; // for testing
    };

    updatePriorityAndUpdateContent = () => {
        let { filteredContentDetails, content } = this.state;
        let conten = _.cloneDeep(content)


        filteredContentDetails.forEach(c => {
            conten.contentDetails = conten.contentDetails.map(cont => {
                if (cont.contentId == c.contentId) {
                    cont.priority = parseInt(c.priority);
                }
                return cont;
            });
        })



        let { boardId, classId, groupId, subjectId, chapterId, topicId,
            contentDetails } = conten;


        this.updateContent({ boardId, classId, groupId, subjectId, chapterId, topicId, contents: contentDetails });

    }

    onMoveClick = (rowData, isUp) => {
        let contentDetail = this.state.filteredContentDetails;
        let findIndex = contentDetail.findIndex((a) => a.contentId == rowData.contentId);
        if (findIndex > 0 && isUp) {
            let newArray = this.moveRecords(contentDetail, findIndex, isUp ? findIndex - 1 : findIndex + 1);

            this.setState((prevState) => {
                return {
                    filteredContentDetails: newArray
                }
            }, this.updatePriorityAndUpdateContent);
        }
        else if ((findIndex + 1) < contentDetail.length && !isUp) {
            let newArray = this.moveRecords(contentDetail, findIndex, isUp ? findIndex - 1 : findIndex + 1);
            // 

            this.setState((prevState) => {
                return {
                    filteredContentDetails: newArray
                }
            }, this.updatePriorityAndUpdateContent);
        }
    }

    onSelectResourceType = (type) => {

        if (type.value == 'ppt') {
            type.value = 'zip'
        }

        let selectedTopicResourceTypes = this.state.selectedTopicResourceTypes;
        let selectedTopicResourceType;
        selectedTopicResourceTypes = selectedTopicResourceTypes.map(r => {
            if (type.value == r.value) {
                r.isSelected = true;

                selectedTopicResourceType = r.value
            } else {
                r.isSelected = false
            }
            return r;
        });




        let contentDetails = (this.state.content && this.state.content.contentDetails) ? this.state.content.contentDetails : [];

        contentDetails = this.filterResource(contentDetails, selectedTopicResourceTypes)

        this.setState({
            selectedTopicResourceTypes,
            filteredContentDetails: contentDetails
        });
    }


    onEditContent = (content) => {
        this.setState({
            selectedContent: content,
            isShowEditContent: true
        });
    }

    onDeleteContent = (content) => {

        this.setState({
            selectedContent: content,
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `${content.contentName} will be deleted, it can't be undone.`
            },
        });
    }



    onYesClick = () => {
        this.setState({
            warningDialog: warningDailogInit,
            //isLoading: true
        }, this.deleteAndUpdate);
    }

    onNoClick = () => {
        this.setState({ warningDialog: warningDailogInit, selectedContent: null })


    }


    deleteAndUpdate = () => {
        let { content, selectedContent } = this.state;
        content.contentDetails = content.contentDetails.filter(c => c.contentId != selectedContent.contentId);

        let fileTypes = ['mp4', 'zip', 'pdf', 'quiz'];

        let contents = [];
        fileTypes.forEach(filetype => {
            let filtered = content.contentDetails.filter((conent) => conent.contentFileType.toLowerCase() == filetype.toLowerCase());

            filtered = filtered.map((a, index) => {
                a.priority = index + 1;
                // let id = uuidv4();
                // a.contentId = id;
                return a;
            });

            contents = [...contents, ...filtered];
        });
        content.contentDetails = contents;

        const { boardId, classId, groupId, subjectId, chapterId, topicId, contentDetails } = content;
        this.updateContent({
            boardId, classId, groupId, subjectId, chapterId, topicId,
            contents: contentDetails
        });

    }

    onUpdateContent = (selectedContent) => {
        let { content } = this.state;
        content.contentDetails = content.contentDetails.map(c => {
            if (c.contentId == selectedContent.contentId) {
                c = selectedContent;
            }
            return c;
        });

        const { boardId, classId, groupId, subjectId, chapterId, topicId, contentDetails } = content;

        this.setState({ isShowEditContent: false }, () => {
            this.updateContent({
                boardId, classId, groupId, subjectId, chapterId, topicId,
                contents: contentDetails
            })
        });
    }

    showPreview = (contentInfo, currTypeTab) => {
        if (currTypeTab?.label === 'VIDEO') {
            this.setState({
                isShowModalPreview: true,
                selectedVideoContentInfo: contentInfo
            });
        } else {
            this.setState({
                isShowPreview: true,
                selectedContent: contentInfo
            });
        }

    }

    onAssignQrCode = () => {

        let body = {}
        body.boardId = this.state?.boardId
        body.classId = this.state.classId
        body.subjectId = this.state.subjectId
        body.chapterId = this.state.chapterId
        body.topicId = this.state.topicId
        body.contentFileType = this.state?.selectedResource.contentFileType
        body.qrId = this.state.selectedQrCode
        body.contentKey = this.state.content.contentKey
        body.contentId = this.state?.selectedResource.contentId
        if (body.qrId === "") {
            this.toast.show({ severity: 'error', summary: 'Select a QR Code', detail: '', life: 3000 });

        } else {
            this.setState({
                isLoading: false
            })

            let lazyParams = this.state.lazyParams;


            let url = `${baseUrlAdmin}/qrcodes/assignqrtocontent`

            this.service.put(url, body, true).then(res => {
                if (res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        selectedQrCode: '',
                        qrDialog: false,
                    }, this.getContentDetails)
                    this.toast.show({ severity: 'success', summary: 'QR Code Assigned', detail: '', life: 3000 })
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(err => {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

            })
        }
    }

    QRfooter = () => {
        return (
            <>
                <Button label='Assign QR Code' onClick={() => this.onAssignQrCode()} />
                <Button label='Cancel' className='p-button-outlined' onClick={() => { this.setState({ qrDialog: false, selectedQrCode: '' }) }} />
            </>
        )
    }
    showQRfooter = () => {
        return (
            <>
                <Button label='Unassign' onClick={this.onClickUnAssign} />
                <Button label='Cancel' className='p-button-outlined' onClick={() => { this.setState({ viewQr: false, selectedQrCode: '', selectedResourceQrid: '' }) }} />
            </>
        )
    }

    showVideoPreviewfooter = () => {
        return (
            <>
                <Button label='Cancel' className='p-button-outlined' onClick={() => { this.setState({ isShowModalPreview: false, selectedContent: null, selectedVideoContentInfo: null }) }} />
            </>
        )
    }

    onClickViewQr = (rowData) => {

        this.setState({
            viewQr: true,
            selectedResourceQrid: rowData?.qrId,
            contenIdToUnAssign: rowData.contentId
        })
    }

    onClickAddQrCode = (rowData) => {
        this.setState({
            qrDialog: true,
            selectedResource: rowData
        }, this.getQrCodesData)
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getQrCodesData);
    }

    onClickUnAssign = () => {
        this.setState({
            isLoading: true
        })


        let body = {}
        body.qrId = this.state.selectedResourceQrid
        body.contentKey = this.state.content.contentKey
        body.contentId = this.state.contenIdToUnAssign

        let url = `${baseUrlAdmin}/qrcodes/unassignqrfromresource`

        this.service.put(url, body, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    viewQr: false,
                }, this.getContentDetails)
                this.toast.show({ severity: 'success', summary: 'QR Code Unassigned Successfully', detail: '', life: 3000 })
            } else {
                this.setState({
                    isLoading: false,
                    viewQr: false,
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false,
                viewQr: false,
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })
    }

    getQrCodesData = () => {
        this.setState({
            isLoading: true
        })

        let lazyParams = this.state.lazyParams;


        let url = `${baseUrlAdmin}/qrcodes?limit=${lazyParams.rows}&sort=-createdAt&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`
        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    qrCodes: res?.res?.data?.qrCodes,
                    totalRecords: res?.res?.data?.totalRecords
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })
    }

    addChildContent = (data) => {
        this.setState({
            selectedType: "type",
            isShowAddDialog: true,
            contentParentId: data?.contentId,
            selectedParentData: data,
        });
    }

    getLanguagesList = () => {
        if (this?.state?.contentParentId) {
            let clonedLanguages = this?.state?.contentLanguagesList
            if (this?.state?.selectedParentData?.childContent?.length > 0) {
                let allUploadedLanguages = this?.state?.selectedParentData?.childContent?.map((vl) => vl?.contentLanguage)?.filter((e) => e)
                clonedLanguages = clonedLanguages.filter((vl) => !allUploadedLanguages?.includes(vl?.code))
            }
            return clonedLanguages
        } else {
            return this?.state?.contentLanguagesList
        }
    }
    render() {
        const currentSelectedTab = this?.state?.selectedTopicResourceTypes?.find((vl) => vl.isSelected)
        console.log("currentSelectedTab", currentSelectedTab)
        console.log("selectedParentData", this?.state?.selectedParentData)
        const allLanguagesList = this.getLanguagesList()
        console.log("allLanguagesListallLanguagesList", allLanguagesList)
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex gap-3">
                    {/* <h2 >Resources List</h2> */}
                    {
                        this.state.topicId ? (this.state.selectedTopicResourceTypes ? this.state.selectedTopicResourceTypes.map((type) => {
                            return <Button className={`p-button-raised cont-btn ${!type.isSelected ? 'p-button-outlined' : ''}`} label={type.label} onClick={() => this.onSelectResourceType(type)} />
                        }) : <>
                            <p>Please assign resources</p>
                        </>) : null
                    }
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.CONTENT_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.CONTENT_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}

                        <Authorizer permId={PERMISSIONS.CONTENT_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    style={{ width: "100px" }}
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="sort-title">Sort & Filter</h4>

                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        console.log('this.state.selectedContent', this.state.selectedContent)
        console.log('this.state.contentParentId', this?.state?.contentParentId)
        console.log('this.state.contentLanguagesList', this?.state?.contentLanguagesList)
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;
        return <div>
                <div className="">
                    {/* <div className="p-col-12 p-md-1 ma-no-p"> */}
                        {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                    {/* </div> */}
                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>


                <div className="ask-a-doubt ma-main flex-wrap">
                    <Authorizer permId={PERMISSIONS.CONTENT_SELECTION_LIST} >
                        <Dropdown value={this.state.boardId}
                            className='w-15rem ma-w200 md:mt-2 md:ml-0'
                            //options={this.state.boards}
                            options={this.props.boards}
                            optionLabel="boardName"
                            optionValue="boardId"
                            onChange={(e) => this.onChangeBoard(e.value)}
                            placeholder="Select Board" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.classId}
                            options={this.state.classes}
                            className='w-15rem ml-2 ma-w200 md:mt-2'
                            onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.subjectId}
                            options={this.state.subjects}
                            optionLabel='subjectName'
                            optionValue='subjectId'
                            className='w-15rem ml-2  ma-w200 md:mt-2'
                            onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.chapterId}
                            options={this.state.chapters}
                            optionLabel='chapterName'
                            optionValue='chapterId'
                            className='w-15rem ml-2 ma-w200 md:mt-2 md:ml-0 lg:ml-2'
                            onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.topicId}
                            options={this.state.topics}
                            optionLabel='topicName'
                            optionValue='topicId'
                            className='w-15rem ml-2 ma-w200 md:mt-2 '
                            onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                        <span className='ma-required-mark '>*</span>
                    </Authorizer>
                    {/* <span onClick={() => this.addClick({})} className='add-ic' >
                    <i className="pi pi-plus"></i>
                </span> */}
                    <div className='mt-3'>
                        {/* <h3 className='info-txt'>INFO</h3> */}
                        {/* {
                        this.state.topicId ? (this.state.selectedTopicResourceTypes ? this.state.selectedTopicResourceTypes.map((type) => {
                            return <div className='resourse-add'>
                                <p className='type-title'>{type.label}</p>
                                <div className='add-ico'>
                                    <span onClick={() => this.addClick(type)} className='add-ic' >
                                        <i className="pi pi-plus"></i>
                                    </span>
                                    <span className='add-txt'>Add {type.label.toLowerCase()} </span>
                                </div>
                            </div>
                        }) : <>
                            <p>Please assign resource types for selected hierarchy</p>
                        </>) : null
                    } */}
                        <Authorizer permId={PERMISSIONS.CONTENT_ADD} >
                            {
                                this.state.topicId ? (this.state.selectedTopicResourceTypes && this.state.selectedTopicResourceTypes.length ? <div className='resourse-add'>
                                    <p className='type-title'>Add Resources (Videos, Pdfs, Ppts)</p>
                                    <div className='add-ico'>
                                        <span onClick={() => this.addClick('type')} className='add-ic ma-pointer' >
                                            <i className="pi pi-plus"></i>
                                        </span>
                                        <span className='add-txt'>Add Resource </span>
                                    </div>
                                </div> : <><p className='select-warn'>Please assign Resource list for selected topic from Curriculum List to add or modify resources</p></>)

                                    : null
                            }
                        </Authorizer>
                    </div>
                    <div className='ma-clearFix'>
                    </div>
                    <div className='mt-3'>
                        <div className="card datatable-crud-demo">
                            <DataTable
                                ref={(el) => this.dt = el}
                                id="contentt"
                                //  lazy
                                //value={this.state.content && this.state.content.contentDetails}
                                value={this.state.filteredContentDetails}
                                //value={[{ priority: 1, fileName: 'ddd' }]}
                                // selection={this.state.selectedProducts}
                                // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                dataKey="contentId"
                                header={header}
                                rows={100}
                                showGridlines
                                responsiveLayout="scroll"
                                scrollable
                                // scrollDirection="both"
                                scrollWidth="flex"
                                //selectionMode="checkbox"
                                globalFilter={this.state.globalFilter}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"

                            >
                                {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                                <Column sortable filterField="priority" headerStyle={{ width: "100px" }} field="priority" header="Sl No" />
                                <Column sortable filterField="contentName" field="contentName" header="Resourse Name" />
                                <Column sortable filterField="contentResourceType" headerStyle={{ width: "130px" }} field="contentResourceType" header="File Type" />
                                <Column sortable filterField="languages" headerStyle={{ width: "130px" }} field="languages" header="Languages"
                                    body={(rowData) => {
                                        let filterLanagues = this?.state?.contentLanguagesList?.filter((vl) => rowData?.languages?.includes(vl?.code)).map((vl) => vl?.name)
                                        return (<span>{filterLanagues?.join(', ')}</span>)
                                    }} />
                                <Column sortable filterField="source" field="source" header="Source" body={(rowData) => {
                                    return (<span>{getContentSource(rowData.source)}</span>)
                                }} />
                                <Column header="Preview" body={(rowData) => {
                                    return (<div className='preview-text'>
                                        <Authorizer permId={PERMISSIONS.CONTENT_PREVIEW} >
                                            <span onClick={() => { this.showPreview(rowData, currentSelectedTab) }} > <i className='pi pi-eye ma-mr10' />Preview </span>
                                        </Authorizer>
                                    </div>)
                                }}
                                />
                                <Column header="QR Code Preview" body={(rowData) => {
                                    return (<div className='preview-text ma-mt20'>
                                        <Authorizer permId={PERMISSIONS.CONTENT_PREVIEW} >
                                            {rowData.qrId ? <span onClick={() => { this.onClickViewQr(rowData) }} > <img className='ma-mr5' src="./images/qr-code.png" />View QR </span> : null}
                                        </Authorizer>
                                    </div>)
                                }}
                                />
                                {this.state.isBaseBoard && <Column header="QR Code" body={(rowData) => {
                                    return (<div className=''>
                                        <Authorizer permId={PERMISSIONS.CONTENT_ADD} >
                                            <Button disabled={rowData.qrId ? true : false} className='p-button-outlined' label='Add QR' onClick={() => this.onClickAddQrCode(rowData)} />
                                        </Authorizer>
                                    </div>)
                                }}
                                />}
                                <Column body={(rowData, index) => {
                                    return (
                                        <div className="flex gap-1" >

                                            {/* icon="pi pi-sort-up" icon="pi pi-sort-down" */}
                                            <Authorizer permId={PERMISSIONS.CONTENT_CHANGE_PRIORITY} >
                                                <Button icon="pi pi-arrow-up" onClick={() => this.onMoveClick(rowData, true)} iconPos="right" />
                                                <Button icon="pi pi-arrow-down" className='ma-ml10 p-button-outlined' onClick={() => this.onMoveClick(rowData, false)} iconPos="right" />
                                            </Authorizer>
                                            <Authorizer permId={PERMISSIONS.CONTENT_MORE_ACTIONS} >
                                                <Button id={rowData.contentId} onClick={(event) => this[rowData.contentId].toggle(event)} icon="pi pi-ellipsis-v" className='p-button-outlined ma-ml10' />
                                                {currentSelectedTab?.label === "VIDEO" &&
                                                    <Button label="+ Add" className='ma-m-lr10' onClick={() => this.addChildContent(rowData)} />}
                                            </Authorizer>
                                            <Menu model={[
                                                {
                                                    label: 'Edit', icon: 'pi pi-fw pi-pencil',
                                                    command: () => { this.onEditContent(rowData) }
                                                },
                                                {
                                                    label: 'Delete', icon: 'pi pi-fw pi-trash',
                                                    command: () => { this.onDeleteContent(rowData) }
                                                }
                                            ]}
                                                className="content-more"
                                                popup ref={el => this[rowData.contentId] = el} />
                                        </div>
                                    )
                                }} field="status" header="Actions" />

                                {/* <Column field="source" header="Actions" body={(rowData) => {
                                return (<>
                                </>)
                            }} /> */}
                            </DataTable>
                        </div>
                    </div>

                </div >

                <Dialog
                    visible={this.state.qrDialog}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={false}
                    style={{ maxWidth: '50%', height: '100%' }}
                    footer={this.QRfooter}
                    header={`Select QR Code`}
                    onHide={() => { this.setState({ qrDialog: false }) }}
                >
                    <div className="grid subject-add-dialog">
                        <DataTable
                            ref={(el) => this.dt = el}
                            value={this.state.qrCodes}
                            dataKey="_id"
                            paginator
                            lazy
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            responsiveLayout="scroll"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            selection={this.state.selectedCustomers}
                            onSelectionChange={e => this.setState({ selectedCustomers: e.value })}
                        >
                            <Column header='Select' className='' body={(rowData) => {
                                return (
                                    <RadioButton value={rowData.qrId}
                                        onChange={(e) => { this.setState({ selectedQrCode: e.target.value }) }}
                                        checked={this.state.selectedQrCode === rowData.qrId}
                                        className="field-radiobutton"
                                        disabled={rowData.isAssigned === true ? true : false}
                                    />
                                )
                            }} />
                            <Column header='QR Codes' field='qrId' />
                            <Column field="isAssigned" header="Assigned" body={(rowData) => {
                                return <>
                                    <InputSwitch disabled
                                        checked={rowData.isAssigned}
                                    />
                                </>
                            }} />

                        </DataTable>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.viewQr}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    headerClassName = "text-center"
                    dismissableMask={false}
                    closable={true}
                    style={{ maxWidth: '40%', height: '60%' }}
                    footer={this.state.isBaseBoard ? this.showQRfooter : <></>}
                    header={`Scan QR Code`}
                    onHide={() => { this.setState({ viewQr: false }) }}
                >
                    <div className="grid qrCodeImg subject-add-dialog">
                        <div className=''>
                            <QRCode
                                size={200}
                                bgColor='white'
                                fgColor='black'
                                value={this.state.selectedResourceQrid}
                            />
                            <p className='ma-mt30 font-bold'>{this.state.selectedResourceQrid}</p>
                        </div>
                    </div>
                </Dialog>
                {/* popup to show parent and child preview for video tabs */}
                <Dialog
                    visible={this.state.isShowModalPreview}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    headerClassName = "text-center"
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={true}
                    style={{ maxWidth: '60%', height: '100%' }}
                    footer={this.showVideoPreviewfooter}
                    header={`Preview Video`}
                    onHide={() => { this.setState({ isShowModalPreview: false, selectedContent: null, selectedVideoContentInfo: null }) }}
                >
                    <div className='ma-mt30'>
                        <div className="card datatable-crud-demo">
                            <DataTable
                                ref={(el) => this.dt = el}
                                id="contentt"
                                value={this?.state?.selectedVideoContentInfo?.childContent || []}
                                dataKey="contentId"
                                header={<></>}
                                rows={100}
                                responsiveLayout="scroll"
                                scrollable
                                // scrollDirection="both"
                                scrollWidth="flex"
                                globalFilter={this.state.globalFilter}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            >
                                <Column sortable filterField="priority" headerStyle={{ width: "100px" }} field="priority" header="Sl No" />
                                <Column sortable filterField="contentName" field="contentName" header="Resourse Name" />
                                <Column sortable filterField="contentResourceType" headerStyle={{ width: "130px" }} field="contentResourceType" header="File Type" />
                                <Column body={({ contentLanguage }) => {
                                    if (contentLanguage && contentLanguage.length) {
                                        return (
                                            <div className="" >
                                                {
                                                    allLanguagesList.filter(lan => lan.code === contentLanguage).map(lan => lan.name)
                                                }
                                            </div>
                                        )
                                    } else {
                                        return (<div className="">English</div>)
                                    }
                                }} field="contentLanguage" header="Language" />

                                <Column body={(rowData) => {
                                    console.log("rowData", rowData)
                                    return (
                                        <div className="">
                                            <Authorizer permId={PERMISSIONS.CONTENT_CHANGE_PRIORITY} >
                                                <Button onClick={() => { this.showPreview(rowData) }} label='View' icon="pi pi-arrow-up" iconPos="right" />
                                            </Authorizer>
                                        </div>
                                    )
                                }} field="status" header="Actions" />
                            </DataTable>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    //visible={this.state.isShowCreate}
                    style={{ width: '90%', height: '80vh' }}
                    //  header={this.props.isAdd ? "Create Role" : "Edit Role"}
                    modal
                    blockScroll={true}
                    footer={this.createRoleDialogFooter}
                    draggable={false}
                    // footer={this.footer}
                    header={null}
                    closeOnEscape={false}

                    dismissableMask={false}
                    className='add-a-resource-dialog'
                    visible={this.state.isShowAddDialog}
                    closable={false}
                    //header="Add a Resource"
                    onHide={() => { this.setState({ contentParentId: null, selectedParentData: null, isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null }) }}>
                    <div className='add-a-resource' >
                        {

                            this.state.resourseOrigin == null ? <>
                                <p className='title'>Add a Resource</p>
                                <div className="grid col-12 ">
                                    <div className="col-6 p-md-6 line-v">
                                        <div className='section section-1'>
                                            <div className='add-ico'>
                                                <span className='add-ic add-ic1' onClick={() => { this.setState({ resourseOrigin: 'repo' }) }} >
                                                    <i className="pi pi-plus"></i>
                                                </span>
                                            </div>
                                            <p className='section-text'>Add From Content Library</p>
                                        </div>
                                    </div>
                                    <div className="col-6 p-md-6">
                                        <div className='section section-2'>
                                            <div className='add-ico'>
                                                <span className='add-ic add-ic2' onClick={() => { this.setState({ resourseOrigin: 'upload' }) }} >
                                                    <i className="pi pi-upload"></i>
                                                </span>
                                            </div>
                                            <p className='section-text'>Upload Your Own Content</p>
                                        </div>
                                    </div>
                                </div>
                            </> : this.state.resourseOrigin == 'repo' ? <AddFromRepo
                                selectedTopicResourceTypes={this.state.selectedTopicResourceTypes}
                                contentInfo={this.state.content ? this.state.content.contentDetails : []}
                                resourseOrigin="upload" selectedType={this.state.selectedType}
                                onSelectContent={this.onSelectContent}
                            /> : <>

                                <AddFromLocal
                                    grades={{ boardId, classId, groupId, subjectId, chapterId, topicId }}
                                    contentInfo={this.state.content ? this.state.content.contentDetails : []}
                                    addContent={this.addOrUpdateContent}
                                    contentLanguagesList={allLanguagesList || []}
                                    parentId={this.state.contentParentId}
                                />
                            </>}
                    </div>
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={this.onNoClick} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onYesClick} />

                        </div>)
                    }}
                    onHide={this.onNoClick}>
                    <div>

                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>

                {
                    this.state.isShowEditContent && <EditContent
                        editContent={this.state.selectedContent}
                        onCancelEdit={() => { this.setState({ isShowEditContent: false, selectedContent: null }) }}
                        onUpdateContent={this.onUpdateContent}
                        contentLanguagesList={this.state.contentLanguagesList}

                    />
                }
                {
                    this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                        this.setState({
                            isShowPreview: false
                        })
                    }} contentInfo={this.state.selectedContent} />
                }
        </div >
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(ContentMaster)));