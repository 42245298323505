import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
// import Navbar from './../../navbar';
import './styles.scss';
import IndividualAnalytics from './individualAnalytics'
import CumulativeAnalytics from './cumulativeAnalytics';

const items = [
    { label: 'Analytics' },
    { label: 'Exam Analytics', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }



export default class ExamAnalytics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 1,
            editExamType: null,
            editExamId: null,
            editedExamType: null
        }
    }

    changeTab = (tab) => {
        // if (this.state.editQuestion) {
        this.setState({
            selectedTab: tab, editQuestion: null, editExamId: null,
            editedExamType: null,
            editExamType: null
        })
        // }

    }

    onExamEditSuccess = () => {

        this.setState((prevState) => {

            return {
                editExamType: null,
                editExamId: null,
                selectedTab: 2,
                editedExamType: prevState.editExamType
            };
        });

    }

    onEditExam = (editExamId, editExamType) => {

        this.setState({
            editExamId,
            editExamType,
            selectedTab: 1

        })

    }

    // onEditAssessment = (editAssessmentId) => {

    //     this.setState({
    //         editAssessmentId,
    //         editExamType: 'Assessment',
    //         selectedTab: 1

    //     })

    // }

    onEditQuestion = (editQuestion) => {


        this.setState({
            editQuestion,
            selectedTab: 2
        })
    }


    onQuestionUpdate = () => {
        this.setState({
            editQuestion: null,
            selectedTab: 1
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Performance Analytics</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }





    render() {
        const { selectedTab, editExamId, editExamType, editedExamType } = this.state;

        return (<>
         
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="-mt-4">
                            <Toolbar className="p-breadcrumb"  left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>
                <div className="ask-a-doubt ma-main question-bank">
                    <div className='qb-tabs'>
                        <span onClick={() => { this.changeTab(1) }} className={selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>Individual Analytics</span>
                        <span onClick={() => { this.changeTab(2) }} className={selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>Cumulative Analytics</span>

                    </div>
            

                    {
                        selectedTab == 1 && <IndividualAnalytics />
                    }

                    {
                        selectedTab == 2 && <CumulativeAnalytics />
                    }
                    {/* {
                    selectedTab == 2 && <Examslist editedExamType={editedExamType} onEditExam={this.onEditExam} />
                }
                {
                    selectedTab == 3 && <ExamResults />
                } */}
                    {/* <PreviewQuestion /> */}

                    {/* <FetchQuestions /> */}
                </div>
       
        </>
        )
    }
}
