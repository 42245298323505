import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authentication from './../session';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import OrganizationList from './organizationList';
import CreateOrganization from './createOrganization';
import './styles.scss';
import Service from '../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import OrgConfiguration from './orgConfiguration';
import withRouter from '../lib/withRouter';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'title': { value: '', matchMode: 'contains' },
        'subDomain': { value: '', matchMode: 'contains' },
        // 'tenureEndDate': { value: '', matchMode: 'contains' },
        // 'isActive': { value: '', matchMode: 'contains' },
    }
}

const organizationItems = [
    { key: 'o1', label: 'Organization' },
    { key: 'o2', label: 'Organization Master', className: 'ma-active-item',url:"/organization"}
];

const createOrganizationItems = [
    { key: 'co1', label: 'Organization' },
    { key: 'co2', label: 'Organization Master',url:"/organization"},
    { key: 'co3', label: 'Create Organization', className: 'ma-active-item'}
];

const editOrganizationItems = [
    { key: 'co1', label: 'Organization' },
    { key: 'co2', label: 'Organization Master' },
    { key: 'co3', label: 'Edit Organization', className: 'ma-active-item'}
];

const editConfigItems = [
    { key: 'co1', label: 'Organization' },
    { key: 'co2', label: 'Organization Master' },
    { key: 'co3', label: 'Configurations', className: 'ma-active-item'}
];

const home = { icon: 'pi pi-home'}


class OrganizationMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowCreateOrEdit: false,
            editOrganization: null,
            items: organizationItems,
            organizations: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }

        this.service = new Service();
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
                isShowConfigChange: false,
            }
        }, this.getOrganizations);
    }

    onStatusChange = (e, rowData) => {
        let organizations = this.state.organizations.map((i) => {
            if (i._id == rowData._id) {
                i.isActive = e.value;
            }
            return i;
        });
        this.setState({
            organizations,
            isShowConfigChange: false,
        })
    }

    getOrganizations = () => {
        this.setState({
            isLoading: true,
            organizations: [],
            totalRecords: 0,
            isShowConfigChange: false,
            editOrganization: null
        });
        let { lazyParams, globalSearch } = this.state;



        let url = `${baseUrlAdmin}/organization/getall?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.post(url, lazyParams.filters, true).then(res => {
            if (res && res.status && res.res.success) {
                this.setState((prevState) => {
                    return {
                        organizations: res.res.data.organizations,
                        totalRecords: res.res.data.totalRecords - 1,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }


    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                },
                isShowConfigChange: false
            };
        }, this.getOrganizations);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                },
                isShowConfigChange: false

            };
        }, this.getOrganizations);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
                isShowConfigChange: false
            };
        }, this.getOrganizations);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    componentDidMount() {

        this.getOrganizations();
    }

    componentDidUpdate() {




    }

    showCreateOrEdit = () => {

        this.setState({
            isShowCreateOrEdit: true,
            editOrganization: null,
            items: createOrganizationItems,
            isShowConfigChange: false
        });


    }

    onCreateCancel = () => {

        this.setState({
            isShowCreateOrEdit: false,
            items: organizationItems,
            isShowConfigChange: false
        });
    }

    onEditOrgClick = (editOrganization) => {

        this.setState({
            editOrganization,
            isShowCreateOrEdit: true,
            items: editOrganizationItems,
            isShowConfigChange: false
        });
    }



    onCreateOrEditSuccess = () => {

        this.setState({
            editOrganization: null,
            isShowCreateOrEdit: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            items: organizationItems
        }, this.getOrganizations)
    }

    onEditConfigClick = (orgData) => {
        
        this.setState({
            editOrganization: orgData,
            isShowConfigChange: true,
            items: editConfigItems
        })
    }


    render() {
        return (<>
            
            < >
                <div className="">
                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb"
                            left={() => {
                                return (<>  <BreadCrumb model={this.state.items} home={home} />
                                </>)
                            }}
                            right={() => {
                                return (<h4>Organization master</h4>)
                            }}
                        ></Toolbar>
                    </div>
                    {/* <div className="">
                        {(this.state.isShowCreateOrEdit || this.state.isShowConfigChange) && <>
                            <p className='back-arrow' onClick={() => {
                                this.setState({
                                    isShowCreateOrEdit: false,
                                    items: organizationItems,
                                    isShowConfigChange: false,
                                })
                            }}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        }
                    </div> */}
                </div>
                <div className='ma-main'>
                    {
                        this.state.isShowCreateOrEdit ? <>
                            <CreateOrganization
                                onCreateOrEditSuccess={this.onCreateOrEditSuccess}
                                onCreateCancel={this.onCreateCancel}
                                editOrganization={_.cloneDeep(this.state.editOrganization)}
                                onClick={() => {
                                    this.setState({
                                        isShowCreateOrEdit: false,
                                        items: organizationItems,
                                        isShowConfigChange: false,
                                    })
                                }}
                            />
                        </> : <>
                            {this.state.isShowConfigChange ? <OrgConfiguration
                                editOrganization={this.state.editOrganization}
                                onClose={() => {
                                    this.setState({
                                        isShowConfigChange: false,
                                        items: organizationItems
                                    })
                                }}
                                orgId={this.state.editOrganization._id} /> : <>
                                <Authorizer permId={PERMISSIONS.CREATE_ORGANIZATION} >
                                    <Button label="+ Add Organization" className='ma-m-lr10 p-button-raised mt-3'
                                        onClick={this.showCreateOrEdit} />
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.VIEW_ORGANIZATION} >
                                    <OrganizationList
                                        getNextRecords={this.getNextRecords}
                                        lazyParams={this.state.lazyParams}
                                        onPage={this.onPage}
                                        onSort={this.onSort}
                                        onFilter={this.onFilter}
                                        onStatusChange={this.onStatusChange}
                                        organizations={this.state.organizations}
                                        totalRecords={this.state.totalRecords}
                                        isLoading={this.state.isLoading}
                                        onGlobalSearch={this.onGlobalSearch}
                                        globalSearch={this.state.globalSearch}
                                        isShowConfigChange={this.state.isShowConfigChange}
                                        onEditConfigClick={this.onEditConfigClick}
                                        editOrganization={this.state.editOrganization}
                                        onEditOrgClick={this.onEditOrgClick} />
                                </Authorizer>
                            </>}
                        </>
                    }

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
            </>
        </>
        )
    }
}



const mapStateToProps = (state) => ({
    isSuperOrg: state.orgData.isSuper,
});



export default connect(mapStateToProps, {})(Authentication(withRouter(OrganizationMaster)));

//Authentication