import React, { Component } from "react"
import { connect } from 'react-redux';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import cloneDeep from 'lodash/cloneDeep';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import _ from 'lodash';
import { BoardsIconInActive, CancelRed, CrossInBox, GreenCheck, MailIcon, WarningIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask, status } from './../../../store/apiConstants';
import { BreadCrumb } from 'primereact/breadcrumb';
import CreateFeeType from "../../organizationManage/feeTypes/createFeeType";
import { MultiSelect } from "primereact/multiselect";
import withRouter from '../../lib/withRouter';
import { AnalyticsIcon2 } from '../../svgIcons';
import moment from 'moment';
import InputTextB from '../../customComponents/inputTextB';
import { userAssignedBoards } from './../../../store/selectors/userAssignedBoards';
import './styles.scss'
import BranchToSectionDDCol from "../../BaseDropdownComponents/BranchToSectionDDCol";
import BranchToSectionMultiDD from "../../BaseDropdownComponents/BranchToSectionMultiDD";
import { RadioButton } from "primereact/radiobutton";
import ClassetStudentSearch from "../../../classetComponents/classetStudentSearch";
import { debounce } from 'lodash';
import BranchToSectionMultiDDPreFill from "../../BaseDropdownComponents/BranchToSectionMultiDDPreFill";
import ClassetDropdown from "../../../classetComponents/classetDropDown";
import { getBranchesLatest } from "../../../store/actions";

const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "Enrollment Number", value: "enrollmentNumber" }
]
class TransferPendingStudents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            filterData: null,

            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],

            isUserNameSearch: false,
            username: '',
            boardId: null,
            classId: null,
            groupId: null,
            sectionIds: [],
            boards: [],
            classes: [],
            groups: [],
            sections: [],
            branchLevels: {},
            lazyParams: BasicLazyParams,
            transfers: [],
            selectedUser: [],
            globalSearch: '',
            totalRecords: 0,
            isDialogOpenForApproveStudent: false,
            isRejectTransferDialogOpen: false,
            rowData: {},
            dropdownsData: {},
            flagForTransfer: null,
            isUserNameSearch: false,
            isUserEnrollSearch: false,
            searchValue: "",
            searchLevel: 1,
            searchType: searchTypeOptions[0].value,
            branchId: null,
            branchName: null,
            dropdownsDilogData: {},
            filterbranchId: this.props.branchData && this.props.branchData.find(e => e.key == this.props.branchData[0].key)?.key,
            focused: false


        };
        this.debouncedApiCall = debounce(this.filterUsersData, 500);

        this.service = new Service();

    }

    setDropdownValuesDilog = (dropdownsData) => {

        this.setState(prevState => ({
            dropdownsDilogData: dropdownsData,
            rowData: {
                ...prevState.rowData,
                tosectionName: dropdownsData?.selectedsectionName,
                tosectionId: dropdownsData?.sectionId,
                toTransferStatus: this.state?.flagForTransfer
            }
        }),
            () => {
                //this.filterUsersData(dropdownsData)
            })
    }


    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData
        }, () => this.filterUsersData(dropdownsData))
    }


    filterUsersData = (dropdownsData) => {
        this.setState({
            isLoading: true,
            transfers: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;

        const url = `${baseUrlAdmin}/transfers/get/all?academicYear=${localStorage.getItem('userAcademicYear')}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        const payload = {
            sectionId: this.state.searchLevel == 1 && this.state.dropdownsData?.sectionId,
            isUserNameSearch: this.state.searchLevel == 2 && this.state.searchType == "studentName" ? true : this.state.isUserNameSearch,
            username: this.state.searchValue,
            branchId: this.state.searchLevel == 3 && this.state.filterbranchId
        }
        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {

                this.setState({
                    isLoading: false,
                    transfers: res.res?.data?.data,
                    totalRecords: res.res.totalRecords,
                    isUserNameSearch: false,
                    isUserEnrollSearch: false,
                    searchValue: "",



                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }
    componentDidMount() {

        if (this.state?.dropdownsData?.sectionId) {
            this.filterUsersData();

        }
        this.props.getBranchesLatest();
    }

    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }
    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.filterUsersData);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    rejectedData = () => {

        this.setState(prevState => ({
            isRejectTransferDialogOpen: true,
            rowData: {
                ...prevState.rowData,
                toTransferStatus: this.state?.flagForTransfer
            }
        }), () => {
            if (this.state?.isRejectTransferDialogOpen) {
                this.updateTransfer()

            }

        })
    }

    acceptedData = () => {


        this.setState(prevState => ({
            isDialogOpenForApproveStudent: true,
            rowData: {
                ...prevState.rowData,
                toTransferStatus: this.state?.flagForTransfer
            },
        }), () => {
            console.log(this.state.toTransferStatus, this.state.rowData, "iiii", this.state.branchId)
            if (this.state?.isDialogOpenForApproveStudent) {
                this.updateTransfer()
            }
        })
    }
    updateTransfer = () => {



        if (this.state?.rowData && Object.keys(this.state?.rowData).length) {

            const { rowData } = this.state;
            let payload;
            if (rowData.transferType == "Section") {
                payload = rowData
            } else if (rowData.transferType == "Branch") {
                const branchName = this.state.branchId && this.props.branchData.find(e => e.key == this.state.branchId)?.name;
                payload = { ...rowData, tobranchId: this.state.branchId, tobranchName: branchName }
                console.log(payload, this.state?.rowData, "this.state?.rowData", this.state.rowData.tobranchId, this.state.branchId)
            }
            let url = `${baseUrlAdmin}/transfers/update`

            this.service.post(url, payload, true).then(res => {
                if (res?.status && res?.res?.status) {
                    this.setState({
                        isDialogOpenForApproveStudent: false,
                        isRejectTransferDialogOpen: false

                    }, () => { })

                    this.toast.show({ severity: 'success', summary: `Success Fully ${this.state?.flagForTransfer}`, detail: res.errMessage, life: 3000 });
                    this.filterUsersData()

                } else {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(err => {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
            })
        } else {
            this.toast.show({ severity: 'error', summary: `Assign Records shouldn't not empty`, detail: "Please select Atleast one record", life: 3000 });

        }
    }


    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "studentName") {
                this.setState({ isUserNameSearch: true })
            } else {
                this.setState({ isUserEnrollSearch: true })
            }

        })
    }

    handleSetSearchValue = (value) => {
        this.setState({ searchValue: value }, () => {

            this.debouncedApiCall(this.state.searchValue);

        })
    }

    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value }, () => {
            this.filterUsersData()
        })
    }


    handleFocus = () => {
        if (this.state.searchLevel == 3) {
            this.setState({ focused: true }, () => {

            })

        }

    };

    handleBlur = () => {
        if (this.state.searchLevel == 3) {
            this.setState({ focused: false }, () => {

            })
        }

    };

    render() {


        return (
            <div>


                <div className='ma-main  '>

                    <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                        <div className="flex">
                            <div className="flex flex-column justify-content-center align-items-center mt-3" >
                                <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                            </div>

                            <div>
                                <BranchToSectionMultiDDPreFill selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                            </div>
                        </div>
                        <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                            <span className="">OR</span>
                        </div>

                        <div className="flex mt-4 " >
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                            </div>

                            <div className="field ml-4">
                                <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                            </div>
                        </div>

                        <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                            <span className="">OR</span>
                        </div>

                        <div className="flex mt-4" onClick={this.handleFocus} >
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(3)} checked={this.state.searchLevel === 3} />
                            </div>

                            <div className="field ml-4">
                                <ClassetDropdown required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props?.branchData}
                                    value={this.state.filterbranchId} onChange={(e) => this.setState({ filterbranchId: e.value }, () => { this.filterUsersData() })} optionLabel="name"
                                    optionValue="key" onFocus={this.handleFocus} onBlur={this.handleBlur} disabled={this.state.searchLevel === 3 ? false : true} />
                            </div>
                        </div>

                    </div>


                    <div className="card datatable-crud-demo ma-m30  m-2">

                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            value={this.state.transfers}
                            dataKey="id"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            responsiveLayout="scroll"
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}

                        >

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStudents" field="transferType" header="Transfer Type" />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="name" field="name" header="Student Name" />
                            <Column style={{ width: "17rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="contactNumber" field="fmbranchName - tobranchName" header=" From Branch - To Branch" body={(rowData, index) => {
                                return (
                                    <>
                                        {
                                            rowData.transferType == "Branch" && <div>
                                                {rowData.fmbranchName}-{rowData.tobranchName}
                                            </div>}
                                    </>
                                )


                            }} />
                            {/*      <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="creationDate" field="fmboradName - toboradName" header=" From Board - To Board" 
                             body={(rowData, index) => {
                                return (
                                    <>  
                                        <div>
                                            {rowData.fmboradName}-{rowData.toboradName}
                                        </div>
                                    </>
                                )


                            }}/>

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStaff" field="fmclassName - toclassName" header="From Grade- To Grade" 
                            body={(rowData, index) => {
                                return (
                                    <>  
                                        <div>
                                            {rowData.fmclassName}-{rowData.toclassName}
                                        </div>
                                    </>
                                )


                            }}/> */}
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStaff" field="fmsectionName - tosectionName" header="From Section -To Section" body={(rowData, index) => {
                                return (
                                    <>
                                        {
                                            rowData.transferType == "Section" && <div>
                                                {rowData.fmsectionName}-{rowData.tosectionName}
                                            </div>
                                        }
                                    </>
                                )


                            }} />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="phoneNumber" field="phoneNumber" header="Phone Number" />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="status" field="status" header="Status" />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Approve / Reject" body={(rowData, { rowIndex }) => {
                                return (
                                    <div className="flex justify-content-evenly">
                                        <div className="cursor-pointer" onClick={() => this.setState({
                                            isDialogOpenForApproveStudent: true, rowData: rowData, branchId: rowData?.tobranchId, branchName: rowData?.tobranchName
                                            , flagForTransfer: "APROVE"
                                        })}>
                                            <GreenCheck />
                                        </div>

                                        <div className="cursor-pointer" onClick={() =>
                                            this.setState({
                                                isRejectTransferDialogOpen: true, rowData: rowData, branchId: rowData?.tobranchId, branchName: rowData?.tobranchName
                                                , flagForTransfer: "REJECT"
                                            })
                                        }>
                                            <CancelRed />
                                        </div>

                                    </div>
                                )
                            }} ></Column>


                        </DataTable>

                        <Toast ref={(el) => this.toast = el} position="bottom-right" />
                        <Dialog
                            visible={this.state.isDialogOpenForApproveStudent}
                            draggable={false}
                            closeOnEscape={false}
                            position={"right"}
                            className=' mr-0'
                            style={{}}
                            dismissableMask={false}
                            closable={false}
                            header={() => {
                                return (<div className='mt-1'>
                                    <h4 className="sideHeading" style={{ fontSize: 36, color: 'black' }}>Assign Transfer Student </h4>
                                    <div style={{ backgroundColor: 'black', height: 2, width: '100%', padding: 0 }}></div>
                                </div>)
                            }}
                            footer={() => (
                                <div className="" style={{ display: 'flex' }}>
                                    <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.acceptedData(this.state?.rowData) }}>
                                        <p className='text-xl ml-2'>Accept & Assign</p>
                                    </Button>
                                    <Button style={{ borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 0 }} onClick={() => { this.setState({ isDialogOpenForApproveStudent: false }) }}>
                                        <p className='text-xl'>Cancel</p>
                                    </Button>
                                </div>
                            )}
                            onHide={() => this.setState({ isDialogOpenForApproveStudent: false })}
                        >

                            <p className="topicName ml-6" style={{ color: 'black', alignSelf: 'center', paddingBottom: 10 }}>
                                <p>Student Name : {this.state?.rowData?.name}</p>
                                {/* <p>Enrollment no: 23M108708</p> */}
                                <p>Transferred from : {this.state?.rowData?.transferType}</p>
                                <p>Board: {this.state?.rowData?.fmboradName}</p>
                                <p>Grade-Section :{this.state?.rowData?.fmclassName} - {this.state?.rowData?.fmsectionName}</p>
                                {/* <p>Contact Person : Parent Name</p> */}
                                <p>Phone number : {this.state?.rowData?.phoneNumber}</p>
                                <p>Request Raised by : User Name</p>
                            </p>

                            <div style={{ backgroundColor: 'black', height: 2, width: '100%', padding: 0 }}></div>




                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {this.state.rowData?.transferType == "Branch" &&

                                    <div className="row-3 my-2">
                                        <ClassetDropdown required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props?.branchData}
                                            value={this.state.branchId} onChange={(e) => this.setState({ branchId: e.value })} optionLabel="name"
                                            optionValue="key" />
                                    </div>



                                }
                                {this.state.rowData?.transferType == "Section" &&
                                    <BranchToSectionDDCol setValues={this.setDropdownValuesDilog} rowData={this.state.rowData} />}
                            </div>








                        </Dialog>
                        <Dialog
                            visible={this.state.isRejectTransferDialogOpen}
                            draggable={false}
                            closeOnEscape={false}
                            className='w-3'
                            dismissableMask={false}
                            closable={false}
                            header={() => {
                                return (<div className='formHeadingInter' style={{}}>
                                    <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                </div>)
                            }}
                            footer={() => (
                                <div className='flex justify-content-center '>
                                    <Button className='formSubmitButton px-6' style={{ border: 0 }} onClick={() => { this.rejectedData(this.state?.rowData) }} >
                                        <p className='formSubmitButtonText'>Reject</p>
                                    </Button>
                                    <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejectTransferDialogOpen: false })} >
                                        <p className='formCancelButtonText'>Cancel</p>
                                    </Button>
                                </div>
                            )}
                            onHide={() => this.setState({ isRejectTransferDialogOpen: false })}
                        >
                            <div>
                                <p className='sideHeading' style={{ fontSize: 20 }}>Reject Transfer</p>
                                <p className="topicName" style={{ color: 'black', alignSelf: 'center', textAlign: 'center' }}>
                                    <p>Student Name : {this.state?.rowData?.name}</p>
                                    {/* <p>Enrollment no: 23M108708</p> */}
                                    <p>Transferred from : {this.state?.rowData?.transferType}</p>
                                    <p>Board: {this.state?.rowData?.fmboradName}</p>
                                    <p>Grade-Section :{this.state?.rowData?.fmclassName} - {this.state?.rowData?.fmsectionName}</p>
                                    {/* <p>Contact Person : Parent Name</p> */}
                                    <p>Phone number : {this.state?.rowData?.phoneNumber}</p>
                                    <p>Request Raised by : User Name</p>
                                </p>
                            </div>

                        </Dialog>
                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({

    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
});
export default connect(mapStatesToProps, {
    getBranchesLatest
})(Authentication(withRouter(TransferPendingStudents)));