import React, { Component } from 'react';
import { discussionBoardEndpoint } from '../../../store/apiConstants';
import Comment from '../common/comment';
import Service from '../service';
import { connect } from 'react-redux';
import { getProfileURL } from './../../../utile';
import CreateComment from './createComment';
import withRouter from '../../lib/withRouter';


class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: '-createdAt',
            limit: 10,
            page: 1,
            search: '',
            comments: [],
            stopRequests: false,
            isLoading: false,
            currentScrollPosition: 0,
            comment: {
                content: "",
                for: "Answer",
                postId: this.props.postId
            },
            errorMsg: '',
            totalRecords: 0
        }
        this.service = new Service();
        this.myRef = React.createRef();
    }



    getComments = (answerId, sort, limit, page, search, isSearch) => {
        this.setState({
            isLoading: true
        });
        const url = `${discussionBoardEndpoint}/answers/${answerId}/comments?sort=${sort}&limit=${limit}&page=${page}&search=${search}`
        this.service.get(url, true).then(data => {
            let re = isSearch ? [] : this.state.comments;
            if (data && data.status) {
                if (data.res.data.length > 0) {
                    re = re.concat(data.res.data)
                    this.setState((prevState) => {
                        return {
                            comments: re,
                            totalRecords: data.res.totalNumOfData,
                            currentScrollPosition: window.scrollY,
                            page: data.res.page,
                            isLoading: false,
                        }
                    });
                } else {
                    this.setState({ isLoading: false, stopRequests: true, comments: re });
                }
            } else {
                this.setState({ isLoading: false, stopRequests: true, comments: re });
            }
        });
    }


    onCommentChange = (event) => {
        let e = event.currentTarget.value;
        this.setState((prevState) => ({
            comment: {
                ...prevState.comment,
                content: e
            }
        }));
    }

    postComment = () => {
        const url = `${discussionBoardEndpoint}/answers/${this.props.answerId}/comments`
        if (this.state.comment.content == "") {
            this.setState({
                errorMsg: 'Type a comment'
            });
            return;
        } else {
            this.service.post(url, this.state.comment, true).then(data => {
                if (data && data.status) {
                    this.setState((prevState) => ({
                        comments: [data.res.data.doc, ...prevState.comments].flat(),
                        comment: {
                            ...prevState.comment,
                            content: '',
                        }
                    }));
                } else {
                    this.setState({ isLoading: false, errorMsg: data.errMessage });
                }
            });
        }



    }

    onCommentPost = (comment) => {

        this.setState((prevState) => ({
            comments: [comment, ...prevState.comments].flat()
        }));
    }


    componentDidMount() {
        // if (this.props.commentCount) {
        this.getComments(this.props.answerId, this.state.sort, this.state.limit, this.state.page, this.state.search);
        //}
    }

    loadMoreClick = () => {
        this.getComments(this.props.answerId, this.state.sort, this.state.limit, this.state.page + 1, this.state.search);
    }
    // onScroll = () => {
    //     const current = this.myRef.current;
    //     let total = current.scrollHeight - current.clientHeight;
    //     if (((current.scrollTop / total) * 100) > 70) {
    //         this.getComments(this.props.answerId, this.state.sort, this.state.limit, this.state.page + 1, this.state.search);
    //     }

    //     this.setState({
    //         currentScrollPosition: current.scrollTop
    //     });
    // }

    render() {

        // console.log('state', this.state)
        return (<>
            <CreateComment answerId={this.props.answerId} postId={this.props.postId} onCommentPost={this.onCommentPost} />
            <div className="answers-list ma-scroll-vr" onScroll={this.onScroll} ref={this.myRef} >
                <hr className="comment-hr" />
                {this.state.comments.length == 0 && <p className="no-comments">No Comments</p>}
                {this.state.comments.map((comment, index) => {
                    return <div key={comment._id}> <Comment item={comment} postId={this.props.postId} ></Comment> </div>
                })}
            </div>
            {
                (!this.state.stopRequests && this.state.totalRecords > this.state.comments.length) && <div className="load-w">
                    <span className="load-more" onClick={this.loadMoreClick}>Load More Comments <i className='pi pi-chevron-down' /></span>
                </div>
            }


        </>

        )
    }
}

//export default Comments;

const mapStateToProps = state => {
    return {

        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}

export default connect(mapStateToProps, {})(withRouter(Comments));