import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import Service from '../../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { BasicLazyParams } from '../../../../utile';
import LoadingComponent from '../../../loadingComponent';
import ExamCorrection from './examCorrection';
import StudentChallenges from './studentChallenges';
import { Dialog } from 'primereact/dialog';
import ReactApexChart from 'react-apexcharts';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

export default class SubjectiveEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChallenges: false,
            globalSearch: '',
            // totalRecords: 18,
            lazyParams: BasicLazyParams,
            columns: [{ columnName: 'User Name', isVisible: true, id: 'user_name' }],
            totalRecords: 0,
            showSearch: false,
            examResults: [],
            isShowCorrectionScreen: false,
            showingReports: false,
            objReport: {},
            series: [
                { name: 'Max marks', data: [] },
                { name: 'Sub.high score', data: [] },
                { name: 'Obtained marks', data: [] }
            ],
            options: {
                xaxis: {
                    categories: [],
                },
                yaxis: { title: { text: 'Marks' } },
                chart: { toolbar: { show: false } }
            },
        }

        this.service = new Service();

    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.onSearch);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    onSearch = () => {
        const {
            lazyParams, globalSearch
        } = this.state;

        let { assessExamId } = this.props;
        let filters = { isChallenged: { $ne: true } };
        this.setState({
            isLoading: true,
            examResults: [],
            totalRecords: 0
        });
        this.service.post(`${baseUrlAdmin}/results/getexamresults/${assessExamId}?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {
            

            if (data && data.status && data?.res?.data) {
                this.setState({
                    totalRecords: data.res.totalRecords,
                    examResults: data.res.data,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'No Exams', detail: "No Exams Found", life: 3000 });
            }


        }).catch(e => {
            this.setState({
                isLoading: false
            })

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);

        });


    }
    componentDidMount() {
        this.onSearch();

    }

    onCorrectionDone = () => {

        this.setState({
            isShowCorrectionScreen: false,
            examResultForCorrection: null
        }, () => {
            this.onSearch();
        });

        this.toast.show({ severity: 'success', summary: 'Success', detail: "Exam Correction Record saved Successfull.y", life: 3000 });

    }


    startCorrection = (examResult) => {
        this.setState({
            isShowCorrectionScreen: true,
            examResultForCorrection: examResult
        })
    }
    reports = (rowData) => {
        let examId = this.state.examResults[0]?.examId
        let userId = rowData.userId

        let url = `${baseUrlAdmin}/results/get/test/${examId}/${userId}`
        this.setState({isLoading : true})
        this.service.post(url,{"isChallenged": {"$ne": true}},true).then(res => {
            if(res?.status && res?.res?.status){
                let obj = res?.res?.examResult[0]
                let series = [
                    { name: 'Max marks', data: obj.maxMarks },
                    { name: 'Sub.high score', data: obj.subHighScore },
                    { name: 'Obtained marks', data: obj.obtainedMarks }
                ]
                let options = {
                    xaxis: { categories: obj.subjectCategories },
                    yaxis: { title: { text: 'Marks' } },
                    chart: { toolbar: { show: false } }
                }
                // if (obj.reportData.length < 6) {
                //     for (let i = 0; i < 7; i++) {
                //         if (i > obj.reportData.length) {
                //             obj.reportData.push({})
                //         }
                //     }
                // }
                this.setState({
                    isLoading: false,
                    showingReports: true,
                    objReport: obj,
                    series: series,
                    options: options
                })
            }else{
                this.setState({isLoading: false})
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            console.log('err',err)
            this.setState({isLoading: false})
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
    }

    render() {
   //    console.log(this.props.assessExamId, 'examid')
    const { objReport } = this.state
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>{this.props?.examName} - Users List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ml-4  h-2rem w-15rem" onChange={this.onGlobalSearch} />}

                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} > */}
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* </Authorizer> */}
                        {/* <i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} >
                            
                        </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} > */}
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        {/* </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className=''
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible} className='mt-2'
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text ma-bg-white my-3" aria-label="Back" onClick={this.props.onBackClick} />
                <div>
                    <div className='ma-mt20'>
                        <Button label="Users List" onClick={() => {
                            this.setState({
                                isChallenges: false
                            })
                        }} className={`${this.state.isChallenges === false ? '' : 'p-button-outlined p-button-raised'}`} />
                        <Button label="Student Challenges" onClick={() => {
                            this.setState({
                                isChallenges: true
                            })
                        }} className={`${this.state.isChallenges === true ? '' : 'p-button-outlined p-button-raised'} ml-3`} />
                    </div>

                </div>

                {this.state.isChallenges ? <>
                    <StudentChallenges  assessExamId={this.props.assessExamId} />


                </> : <>
                    {
                        this.state.isShowCorrectionScreen ? <>
                            <ExamCorrection examResultForCorrection={this.state.examResultForCorrection} onCancel={() => {
                                this.setState({
                                    isShowCorrectionScreen: false,
                                    examResultForCorrection: null
                                })

                            }}
                                onCorrectionDone={this.onCorrectionDone}
                            />
                        </> : <div className="card datatable-crud-demo ma-mt20 mt-3">
                            <DataTable ref={(el) => this.dt = el}
                                scrollable scrollHeight="500px"
                                responsiveLayout="scroll"
                                dataKey="_id"
                                value={this.state.examResults}
                                filters={this.state.lazyParams.filters}
                                paginator showGridlines
                                lazy
                                onPage={this.onPage}
                                onSort={this.onSort}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                sortField={this.state.lazyParams.sortField}
                                sortOrder={this.state.lazyParams.sortOrder}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorLeft={() => {
                                    return <>
                                        <p className='avail-q'>Available results:  <span>{this.state.totalRecords}</span> </p>
                                    </>
                                }}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                header={header}>
                                {this.isColumnVisible('user_name') && <Column field="user_name" header="User Name" />}
                                <Column field="examStatus" header="Exam Status" />
                                <Column header="Correction" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        {
                                            rowData.examStatus == 'SUBMITTED' ? <Button label="Start" onClick={() => { this.startCorrection(rowData) }} /> : <Button disabled label='Completed' className='p-button-secondary' />
                                        }
                                    </>
                                    );
                                }} ></Column>
                                <Column header="Reports" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        {
                                            rowData.examStatus === 'SUBMITTED' ?
                                                <></>
                                                : <>
                                                    <span data-pr-tooltip="Reports" data-pr-position="bottom" className={`e${rowIndex} ma-ml10`} onClick={() => { this.reports(rowData) }}>
                                                        <i className='pi pi-eye'></i></span>
                                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.e${rowIndex}`} />
                                                </>
                                        }
                                    </>
                                    );
                                }} ></Column>
                            </DataTable>
                            <Toast ref={(el) => this.toast = el} position="bottom-right" />
                        </div >
                    }

                </>}

                <Dialog
                    header={<>Report</>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={true}
                    visible={this.state.showingReports}
                    style={{ width: '50vw' }}
                    // footer={() => {
                    //     return (<div>
                    //         <Button label="Download Report" className='p-button-outlined ma-mt10' />
                    //         <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmReset} />
                    //     </div>)
                    // }}
                    onHide={() => this.setState({ showingReports: false, objReport: {}, series: [], options: {} })}
                >
                    <div ref={el => this.componentRef = el} style={{ border: "1px black solid" }} className='progress-report ma-w100p'>
                        <div className='pt-3 pl-3 pr-3' style={{ paddingBottom: "0px" }}>
                            <img src={`${objReport?.orgImageWithDetails?.storageLocation ? objReport?.orgImageWithDetails?.storageLocation : ""}`} width={'100%'} height={'80'} className='logo-with-details' />
                        </div>
                        <hr />
                        <h4 className='text-center -my-3'>STUDENT PROFILE</h4>
                        <hr />
                        <div className='grid'>
                            <div className='col-9 grid stud-details span-font-size p-col-9 ml-3 mt-1'>
                                <div className='col-4 ma-mt10' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Name of the Student </span>
                                </div>
                                <div className='col-8 ma-mt10' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: {objReport?.nameOfTheStudent}</span>
                                </div>
                                <div className='col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Father's Name </span>
                                </div>
                                <div className='col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: {objReport?.fathersName}</span>
                                </div>
                                <div className='col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Mother's Name </span>
                                </div>
                                <div className='col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: {objReport?.mothersName}</span>
                                </div>
                                <div className='col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Class </span>
                                </div>
                                <div className='col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: {objReport?.class}</span>
                                </div>
                                <div className='col-4' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>Admin No. </span>
                                </div>
                                <div className='col-8' style={{ padding: "0.2rem" }}>
                                    <span style={{ fontWeight: "bold" }}>: 1234</span>
                                </div>
                            </div>
                            <div className='col-2'>
                                <img src={`${objReport?.studentProfile?.storageLocation ? objReport?.studentProfile?.storageLocation : ""}`} height={'130'} width={'120'} className='ma-mt5' />
                            </div>
                        </div>
                        <div className='list-of-tables ma-ml10 ma-mr10'>
                            <table border="1" width={"95%"} className='test-table mx-3' style={{ borderCollapse: "collapse" }}>
                                <thead>
                                    <tr style={{ fontWeight: "bold" }}>
                                        <td colspan="12" width={"100%"} className='test-head text-center' >PERIODIC TEST - I EXAMINATION REPORT</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='test-head'>
                                        <th width={"20%"}>Subjects</th>
                                        <th width={"15%"}>Total marks</th>
                                        <th width={"15%"}>Obtained marks</th>
                                        <th width={"10%"}>Grade</th>
                                        <th width={"10%"}>Grade point</th>
                                        <th width={"25%"}>Remarks</th>
                                    </tr>
                                    {objReport?.reportData?.map((e, i) => {
                                        return (
                                            <tr className='test-data text-center' width={"20px"} height = {e?.subjectName ? "": "15px"}>
                                                <td style={{fontWeight:"bold"}}>{e?.subjectName}</td>
                                                <td>{objReport?.maxMarks[i]}</td>
                                                <td>{e?.obtainedMark}</td>
                                                <td>{e?.grade}</td>
                                                <td>{e?.gradePoint}</td>
                                                <td>{e?.remarks}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr className='test-head' style={{ fontWeight: "bold" }}>
                                        <td className='text-center'>GRAND TOTAL</td>
                                        <td className='text-center'>{objReport?.totalSujectsMarks}</td>
                                        <td className='text-center'>{objReport?.totalObtainedMarks}</td>
                                        <td className='text-center'>{objReport?.studentGrade}</td>
                                        <td className='text-center'>{objReport?.studentGradePoint}</td>
                                        <td className='text-center'>{objReport?.overallRemarks}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <table border='1' className='test-table mt-3 mx-3' width={"95%"} style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr className='test-head' style={{ fontWeight: "bold" }}>
                                        <th width={"20%"}>Grade</th>
                                        <th >A+</th><th >A</th><th >B+</th><th >B</th><th >C</th><th >D</th><th >E</th>
                                    </tr>
                                    <tr className='test-data text-center'>
                                        <td width={"20%"}>Range (%)</td>
                                        <td >90-100</td><td >80-89</td><td >70-79</td><td >60-69</td><td >45-59</td>
                                        <td >44-33</td><td >BELOW 33</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table border='1' className='test-table mt-3 mx-3' width={"95%"} style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr className='test-head text-center'>
                                        <td width={"15%"}>Month</td>
                                        <td >APR</td><td >MAY</td><td >JUN</td><td >JUL</td><td >AUG</td><td >SEP</td>
                                        <td >OCT</td><td >NOV</td><td >DEC</td><td >JAN</td><td >FEB</td><td >MAR</td>
                                        <td>TOTAL</td><td>PER(%)</td>
                                    </tr>
                                    <tr className='test-data text-center'>
                                        <td width={"15%"}>Working Days</td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                    </tr>
                                    <tr className='test-data text-center'>
                                        <td width={"15%"}>Days Present</td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                        <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table border='1' className='test-table mt-3 mx-3' width={"95%"} style={{ borderCollapse: "collapse" }}>
                                <tr className='test-head text-center'>
                                    <td width={'50%'}>CLASS TEACHER REMARK</td>
                                    <td>PARENTS REMARK</td>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td className='remarks-height' height={'100px'}></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h4 className='graph-head text-center ma-mt10'>Graphycal Analysis of {objReport?.nameOfTheStudent} Marks</h4>
                        <div>
                            <ReactApexChart className='graph-height' options={this.state?.options} series={this.state?.series} type="bar" height={250} />
                        </div>
                        <div className='mx-3' style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
                            <h5 className='mt-4'>Class Teacher's Sign</h5>
                            <h5>Bigmaster's Sign</h5>
                            <h5>Parent/Guardian's Sign</h5>
                        </div>
                    </div>
                    <div className='flex mt-3'>
                        <ReactToPrint
                            pageStyle="padding:'10px',size : A4 portrait @span: 10px"
                            content={() => this.componentRef}
                            onAfterPrint = {() => this.setState({showingReports: false, objReport: {}, series: [], options: {} })}
                        >
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button label="Download Report" className='ma-mt10 ml-auto' onClick={handlePrint} />
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                    </div>
                </Dialog>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
