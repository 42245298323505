import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import Authentication from '../../session';
import { baseUrlAdmin, baseUrlForLongRunningTask, primaryColor } from '../../../store/apiConstants';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { AddIconInGrade, AssignFormIcon, BoardsIconActive, BoardsIconInActive, ClassetEditIcon, CrossInBox, DeleteIconClasset, DownloadBoxIcon, EditBox, EyeIconClasset, FormatsIcon, GradesIcon, GradesIconActive, PlusInBoxIcon, SubjectIcon, TopicIcon, UploadBoxIcon } from '../../svgIcons';
import { Dropdown } from 'primereact/dropdown';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { sortCurriculam, userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Service from '../../../services';

import { dummydata } from './dummydata';
import ContentPlayer from '../../contentMaster/contentPlayers';
import PDFViewer from 'mgr-pdf-viewer-react';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import AddFromRepo from '../../contentMaster/addFromRepo';
import AddFromLocal from '../../contentMaster/addFromLocal';
import { getBoardsData, selectChapter } from '../../../store/actions';
import _, { cloneDeep } from 'lodash';


const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]


let selectedTopicResourceTypes = [
    {
        "label": "VIDEO",
        "value": "mp4",
        "isActive": true,
        "isSelected": false
    },
    {
        "label": "PPT",
        "value": "ppt",
        "isActive": true,
        "isSelected": false
    },
    {
        "label": "PDF",
        "value": "pdf",
        "isActive": true,
        "isSelected": false
    },
    {
        "label": "QUIZ",
        "value": "quiz",
        "isActive": true,
        "isSelected": false
    }
]

class ContentManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            isAddFormatDialogOpen: false,
            isEditResourceDailogOpen: false,
            isDeleteResourceOpen: false,
            selectedChapter: {

            },
            selectedTopic: {

            },
            selectedResource: {
            },
            assign3rdParty: false,
            curriculumInfo: {},
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            chapters: [],
            topics: [],
            resources: []
        };
        this.service = new Service()
    }
    componentDidMount() {
        this.props.getBoardsData(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.boardId == null && this.props.boards.length) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const _classes = [];
            const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === this.props.boards[0]?.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
            }
            this.setState({
                boardId: this.props.boards[0]?.boardId,
                selectedBoardName: this.props.boards[0]?.boardName,
                classes: _classes,
            }, () => this.onChangeClass(_classes[0]?.value))
        }
    }


    getResourcesFromSelectedTopicOrChapter = (topic, type) => {
        this.getResourcesByTopic(topic, type)
    }
    addClick = (type) => {
        this.setState({
            selectedType: type,
            isShowAddDialog: true
        });
    }
    getResourcesByTopic = (topic, type) => {
        this.setState({
            videos: [],
            ppts: [],
            pdfs: [],
            quizes: [],
            selectedResource: {},
            resources: [],
            isLoading: true,
        })
        let url;
        if (type == 'topic') {
            url = `${baseUrlAdmin}/board/${this.state.boardId}/class/${this.state.classId}/group/${this.state.groupId}/subject/${this.state.subjectId}/chapter/${this.state.chapterId}/topic/${topic?.topicId}?etag=true`

        } else {
            url = `${baseUrlAdmin}/board/${this.state.boardId}/class/${this.state.classId}/group/${this.state.groupId}/subject/${this.state.subjectId}/chapter/${topic?.chapterId}/content`
        }
        let videos = [];
        let ppts = [];
        let pdfs = [];
        let quizes = [];

        this.service.get(url, true).then((data) => {
            const etag = data && data.etag;
            if (data && data.res && data.res.Item) {
                let resources = data.res.Item;
                resources?.contentDetails && resources?.contentDetails?.map((resource) => {
                    if (resource?.contentResourceType == 'ppt') {
                        ppts.push(resource)
                    }
                    else if (resource?.contentResourceType == 'pdf') {
                        pdfs.push(resource)
                    }
                    else if (resource?.contentResourceType == 'quiz') {
                        quizes.push(resource)
                    }
                    else if (resource?.contentResourceType == 'video') {
                        videos.push(resource)
                    }
                })
                this.setState({
                    selectedTopic: type == 'topic' ? topic : {},
                    selectedChapter: type == 'chapter' ? topic : {},
                    videos,
                    ppts,
                    pdfs,
                    quizes,
                    isLoading: false,
                    contentEtag: etag,
                    topicId: topic.topicId,
                    content: { ...data.res.Item },
                }, () => topic?.resources?.length ? this.getResourcesFromResourseType(topic?.resources[0]) : this.getResourcesFromResourseType({
                    "label": "VIDEO",
                    "value": "mp4",
                    "isActive": true
                }))
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });
    }
    onSelectContent = (checkedContetForAssign, contentRepo) => {

        this.setState({
            checkedContetForAssign, contentRepo
        })
    };
    getResourcesFromResourseType = (resource) => {

        let content = [], selectedTopicResourceTypesIndex = 0
        if (resource?.value == 'ppt') {
            content = this.state.ppts || []
            selectedTopicResourceTypesIndex = 1
        }
        if (resource?.value == 'pdf') {
            content = this.state.pdfs || []
            selectedTopicResourceTypesIndex = 2
        }
        if (resource?.value == 'quiz') {
            content = this.state.quizes || []
            selectedTopicResourceTypesIndex = 3
        }
        if (resource?.value == 'mp4') {
            content = this.state.videos || []
            selectedTopicResourceTypesIndex = 0
        }

        if (this.state.selectedChapter?.chapterId || this.state.selectedTopic?.topicId)
            this.setState({
                selectedResource: resource,
                selectedTopicResourceTypesIndex: selectedTopicResourceTypesIndex,
                resources: content,
            })
    }

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], classId: null, curriculumInfo: null,
            isShowSubjects: false, resources: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
        }
        let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName
        this.setState({
            classes: _classes,
            boardId,
            selectedTopic: {},
            chapters: [],
            topics: [],
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            selectedTopic: {},
            selectedResource: {
            },
            selectedBoardName: selectedBoardName,
            resources: []
        });

    };

    onChangeClass = (classId) => {
        let selectedClass = this.state.classes?.find((classd) => classd.value == classId)
        this.setState({
            classId: classId,
            selectedClassName: selectedClass?.label,
            groupId: selectedClass?.groupId,
            chapters: [],
            topics: [],
            subjectId: null,
            chapterId: null,
            selectedTopic: {},
            curriculumInfo: {},
            selectedResource: {
            },
            resources: []
        }, () => this.getCurriculumData());
    };

    onChangeSubject = (subjectId) => {
        let chaptersBySubject = this.state.curriculumInfo?.subjects?.find((subject) => subject.subjectId == subjectId).chapters
        this.setState({
            chapters: chaptersBySubject,
            subjectId: subjectId,
            chapterId: null,
            topics: [],
            chapterId: null,
            selectedTopic: {},
            selectedChapter: {},
            selectedResource: {
            },
            resources: []
        });
    };
    onChangeChapter = (chapterId) => {
        let topicsByChapter = this.state.chapters?.find((chapter) => chapter.chapterId == chapterId).topics
        this.setState({
            topics: topicsByChapter,
            chapterId: chapterId,
            selectedResource: {
            },
            resources: [],
            selectedTopic: {},
            selectedChapter: {},
        });
    };


    getCurriculumData = () => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
            this.service.get(url, true).then((data) => {
                const etag = data && data.etag;
                if (data && data.res && data.res.Item) {
                    let sortedData = sortCurriculam(data.res.Item)
                    this.setState({
                        curriculumInfo: sortedData,
                        isLoading: false,
                        subjectId: sortedData?.subjects[0]?.subjectId,
                        chapters: sortedData?.subjects[0]?.chapters,
                        // chapterId: sortedData?.subjects[0]?.chapters[0]?.chapterId,
                        // topics: sortedData?.subjects[0]?.chapters[0]?.topics,
                        etag
                    }, () => this.getResourcesFromSelectedTopicOrChapter(sortedData?.subjects[0]?.chapters[0], 'chapter'));
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });
        } else {
            this.setState({
                isLoading: false,

            })
        }
    };
    updateContent = (content) => {

        this.setState({
            isLoading: true
        });
        content.chapterId = (content.chapterId == null) ? this.state.selectedChapter?.chapterId : content.chapterId

        if (content && content.contents && content.contents.length) {
            content.contents.forEach(ct => {
                if (ct.childContent) {
                    delete ct.childContent
                }
            })
        }
        const url = `${baseUrlForLongRunningTask}/long/content`;


        this.service.put(url, content, true, { "If-Match": this.state.etag }).then((data) => {


            if (data && data.res && data.res.success) {

                this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Successfully modified, modified file may reflect in 10-15 mins', life: 6000 });
                this.setState({
                    isLoading: false,
                    isShowAddDialog: false,
                    resourseOrigin: this.state.isBaseBoard ? 'upload' : null,
                    selectedParentData: null, contentParentId: null
                }, () => {
                    if (this.state.selectedTopic?.topicId) {
                        this.getResourcesFromSelectedTopicOrChapter(this.state.selectedTopic, 'topic')
                    } else {
                        this.getResourcesFromSelectedTopicOrChapter(this.state.selectedChapter, 'chapter')
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });


        })
    }
    assignContentTo = () => {

        const { checkedContetForAssign, } = this.state;
        const { boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId, selectedChapter, } = this.state;
        if (!checkedContetForAssign || !checkedContetForAssign.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one content to assign', life: 3000 });
            return;
        }

        if (boardId && classId && groupId && subjectId && (chapterId || selectedChapter.chapterId) && (topicId || true)) {
            const previousContentInfo = _.cloneDeep(this.state.content.contentDetails) || [];
            if (checkedContetForAssign && checkedContetForAssign.length) {


                checkedContetForAssign.forEach((contentToAssign) => {
                    let record = previousContentInfo.find((a) => a.contentId == contentToAssign.contentId);

                    if (!record) {
                        let cont = _.cloneDeep(contentToAssign);
                        cont.source = 2;
                        cont.childCurriculumContentKeys = undefined;
                        let filtered = previousContentInfo.filter((conent) => conent.contentFileType.toLowerCase() == cont.contentFileType.toLowerCase());

                        cont.priority = filtered.length + 1;


                        previousContentInfo.push(cont);
                    }
                });

                const content = {
                    boardId,
                    classId,
                    groupId,
                    subjectId,
                    chapterId,
                    topicId,
                    contents: previousContentInfo
                };

                this.updateContent({ ...content });



            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one content to assign', life: 3000 });
                return;
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select board, class', life: 3000 });
            return;
        }

    };
    createRoleDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button className='formCancelButton' onClick={() => { this.setState({ isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null, selectedParentData: null, contentParentId: null, border: 1 }) }} >
                <p className='formCancelButtonText'>Cancel</p>
            </Button>
            {
                this.state.resourseOrigin == 'repo' && <>
                    <Button label={`Assign`} className='formSubmitButton' onClick={this.assignContentTo} >

                    </Button>
                </>
            }
        </div>)
    };

    render() {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;
        return (
            <div>
                <div className='grid m-2'>
                    <div className=" col-3">
                        <ClassetDropdown required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.props.boards || []} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                            optionValue="boardId" />
                    </div>
                    <div className=" col-3">
                        <ClassetDropdown required={true} label={'Grade'} icon={<GradesIcon />} placeholder={'Select Grade'} options={this.state?.classes || []}
                            value={this.state.classId} onChange={(e) => this.onChangeClass(e.value)} />
                    </div>
                    <div className=" col-3">
                        <ClassetDropdown required={true} label={'Subject'} icon={<SubjectIcon height={16.5} width={19.5} color={'#000000'} />} placeholder={'Select Subject'} options={this.state?.curriculumInfo?.subjects || []}
                            value={this.state.subjectId} onChange={(e) => this.onChangeSubject(e.value)} optionLabel="subjectName" optionValue="subjectId" />
                    </div>
                    <div className=" col-3">
                        <ClassetDropdown label={'Chapter'} icon={<SubjectIcon height={16.5} width={19.5} color={'#000000'} />} placeholder={'Select Chapter'} options={this.state?.chapters || []}
                            value={this.state.chapterId} onChange={(e) => this.onChangeChapter(e.value)} optionLabel="chapterName" optionValue="chapterId" />
                    </div>
                </div>

                {<div class="grid mx-3 mt-4">
                    <div class="col-4" >
                        <div class="card border-round pb-7" style={{ height: '70vh', }}>
                            <p className='sideHeading'> {this.state.chapterId ? ' Select Topic' : 'Select Chapter'} </p>
                            <div className='ma-scroll-vr-smoke' style={{ height: '60vh' }}>
                                {
                                    this.state.chapterId && this.state.topics?.length ? this.state.topics?.map((topic, index) => {
                                        return <div className='my-3 cursor-pointer' style={topic.topicId == this.state.selectedTopic?.topicId ? { backgroundColor: '#F8F9FA', padding: '4px', paddingLeft: 10 } : { paddingLeft: 5 }} onClick={() => this.getResourcesFromSelectedTopicOrChapter(topic, 'topic')}>
                                            <p className='topicName interSemibold' style={{ color: topic.topicId == this.state.selectedTopic?.topicId ? '#076EFF' : '' }}>{topic.topicName?.toUpperCase()}</p>
                                        </div>
                                    }) : this.state.chapterId && <p>Topics Not Found</p>
                                }
                                {
                                    !this.state.chapterId && this.state?.chapters?.length ? this.state?.chapters?.map((chapter, index) => {
                                        return <div className='my-3 cursor-pointer' style={chapter.chapterId == this.state.selectedChapter.chapterId ? { backgroundColor: '#F8F9FA', padding: '4px', paddingLeft: 10 } : { paddingLeft: 5 }} onClick={() => this.getResourcesFromSelectedTopicOrChapter(chapter, 'chapter')}>
                                            <p className='topicName interSemibold' style={{ color: chapter.chapterId == this.state.selectedChapter?.chapterId ? '#076EFF' : '' }}>{chapter.chapterName?.toUpperCase()}</p>
                                        </div>
                                    }) : !this.state.chapterId && <p>Chapters Not Found</p>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        <div class="col-8  flex card mt-3 mb-3" style={{ height: '70vh', padding: 0, }}>
                            <div class="col-4" style={{ background: 'white', padding: 0, paddingTop: 20 }}>
                                {
                                    selectedTopicResourceTypes?.map((resource) => {
                                        return <div className='m-0' style={resource.label == this.state.selectedResource.label ? { backgroundColor: '#F8F9FA', padding: '4px', paddingLeft: 10 } : { paddingLeft: 5 }}
                                            onClick={() => this.getResourcesFromResourseType(resource)}>
                                            <p style={{ padding: 5 }} className={resource.label != this.state.selectedResource.label ? 'interSemibold cursor-pointer' : 'interbold cursor-pointer'}   >{resource.label}</p>
                                        </div>
                                    })
                                }
                                <div className='flex flex-row cursor-pointer mt-8 pl-2' onClick={() => this.setState({ isAddFormatDialogOpen: true })}>
                                    <p className='addformatText mr-2'>Add format </p>
                                    <PlusInBoxIcon />
                                </div>
                            </div>
                            <div class="col-8 ma-scroll-vr-smoke " style={{ background: '#F8F9FA', padding: 20 }}>
                                {
                                    this.state?.resources?.length ? this.state?.resources?.map((resource, index) => {
                                        return <div className='flex flex-row justify-content-between	pr-5 mb-3'>
                                            <div>
                                                <p className='resourceTitle'>{(index + 1) + '.   ' + resource?.contentName}</p>
                                            </div>
                                            <div className='flex flex-row '>
                                                <div className='cursor-pointer mr-2' onClick={() => this.setState({ contentData: resource, isShowPreview: true })}>
                                                    <EyeIconClasset />
                                                </div>
                                                <div className='cursor-pointer' onClick={() => this.setState({ isDeleteResourceOpen: true, contentData: resource })}>
                                                    <DeleteIconClasset width={"25"} height={"25"} color={'black'} />
                                                </div>
                                            </div>

                                        </div>
                                    }) : <p>Resources Not Found</p>
                                }
                            </div>
                        </div>}
                </div>}


                {
                    (this.state.selectedChapter?.chapterId || this.state?.selectedTopic?.topicId) &&
                    <div className='grid flex justify-content-end my-4'>
                        <div className='resourse-add '>
                            <p className='type-title'>Add Resources (Videos, Pdfs, Ppts)</p>
                            <div className='add-ico cursor-pointer' onClick={() => this.addClick('type')}>
                                <span  className='add-ic ma-pointer' >
                                    <i className="pi pi-plus"></i>
                                </span>
                                <span className='add-txt'>Add Resource </span>
                            </div>
                        </div>
                    </div>

                }




                {/* {
                    this.state.selectedResource?.label?.length && <>
                        <div className='flex flex-row grid mx-4 mt-4 '>
                            <p className='mr-4 uploadResourceText'>Upload Resource</p>
                            <div className='mr-4'>
                                <InputSwitch
                                    onChange={(e) => { this.setState({ assign3rdParty: !this.state.assign3rdParty }) }}
                                    checked={this.state.assign3rdParty} />
                            </div>
                            <p className='uploadResourceText'>Assign 3rd Party URL</p>
                        </div>
                        <div className='card mx-4 mt-3'>
                            <p className='sideHeading' style={{ textAlign: 'start' }}>Upload Video</p>
                            <div className='grid mt-3 border-1 border-black-alpha-40 mx-1	'>
                                <div className='col-3'>
                                    <Dropdown
                                        className='h-3rem w-full border-round-md pt-2 border-none text-lg pl-3 shadow-3'
                                        options={sampleOptions}
                                        placeholder="Priority(1-25)" />
                                </div>
                                <div className='card col-8 my-3 mx-1'>
                                    File Name
                                </div>
                            </div>
                            <div className='card mt-3'>
                                Resource Title
                            </div>
                            <div className='flex flex-row justify-content-between'>
                                <p className='addButtonClass' style={{ fontSize: 16 }}> <span style={{ color: '#FF0000' }}>Note :</span> Upload Resources Priority File Supported videos formats mp4,mpg,avi,wmv,mov and flv</p>
                                <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={() => { this.setState({ isDialogOpenForCreateBoard: true }) }}>
                                    <p className='addButtonClass'>Upload</p>
                                    <UploadBoxIcon />
                                </Button>
                            </div>
                        </div>
                        <div className='card mt-3 mx-4 flex flex-row justify-content-between'>
                            <p className='addButtonClass' style={{ fontSize: 16 }}>
                                For Bulk upload, use this option
                            </p>
                            <div className='flex flex-row justify-content-between'>
                                <div className='flex flex-row justify-content-between mr-8'>
                                    <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={() => { this.setState({ isDialogOpenForCreateBoard: true }) }}>
                                        <p className='addButtonClass'>Download Format</p>
                                        <DownloadBoxIcon />
                                    </Button>
                                </div>
                                <div className='flex flex-row justify-content-between'>
                                    <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={() => { this.setState({ isDialogOpenForCreateBoard: true }) }}>
                                        <p className='addButtonClass'>Bulk Upload</p>
                                        <UploadBoxIcon />
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </>
                } */}

                <Dialog
                    style={{ width: '90%', height: '100%' }}
                    modal
                    blockScroll={true}
                    footer={this.createRoleDialogFooter}
                    draggable={false}
                    header={null}
                    closeOnEscape={false}
                    dismissableMask={false}
                    className='add-a-resource-dialog'
                    visible={this.state.isShowAddDialog}
                    closable={false}
                    onHide={() => { this.setState({ contentParentId: null, selectedParentData: null, isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null }) }}>
                    <div className='add-a-resource' >
                        {
                            this.state.resourseOrigin == null ? <>
                                <p className='title'>Add a Resource</p>
                                <div className="grid col-12 ">
                                    <div className="col-12 p-md-6 line-v cursor-pointer">
                                        <div className='section section-1'>
                                            <div className='add-ico'>
                                                <span className='add-ic add-ic1' onClick={() => { this.setState({ resourseOrigin: 'repo' }) }} >
                                                    <i className="pi pi-plus"></i>
                                                </span>
                                            </div>
                                            <p className='section-text'>Add From Content Library</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 p-md-6">
                                        <div className='section section-2'>
                                            <div className='add-ico'>
                                                <span className='add-ic add-ic2' onClick={() => { this.setState({ resourseOrigin: 'upload' }) }} >
                                                    <i className="pi pi-upload"></i>
                                                </span>
                                            </div>
                                            <p className='section-text'>Upload Your Own Content</p>
                                        </div>
                                    </div> */}
                                </div>
                            </> : this.state.resourseOrigin == 'repo' ? <AddFromRepo
                                selectedTopicResourceTypes={(() => {
                                    let selectedTopicResourceTypes1 = cloneDeep(selectedTopicResourceTypes)
                                    selectedTopicResourceTypes1[this.state.selectedTopicResourceTypesIndex].isSelected = true;
                                    return selectedTopicResourceTypes1
                                })()}
                                contentInfo={this.state.content ? this.state.content.contentDetails : []}
                                resourseOrigin="upload" selectedType={this.state.selectedType}
                                onSelectContent={this.onSelectContent}
                            /> : <>
                                <AddFromLocal
                                    grades={{ boardId, classId, groupId, subjectId, chapterId, topicId }}
                                    contentInfo={this.state.content ? this.state.content.contentDetails : []}
                                    addContent={this.addOrUpdateContent}
                                    contentLanguagesList={[] || []}
                                    parentId={this.state.contentParentId}
                                />
                            </>}
                    </div>
                </Dialog>


                <Dialog
                    visible={this.state.isAddFormatDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className=''
                    style={{ height: "450px", width: '500px', }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="formHeadingInter">Add Format</h4>
                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button className='formSubmitButton px-4' onClick={() => this.setState({ isAddFormatDialogOpen: false })} >
                                <p className='formSubmitButtonText'>Create</p>
                            </Button>
                            <Button className='formCancelButton px-4' onClick={() => this.setState({ isAddFormatDialogOpen: false })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isAddFormatDialogOpen: false })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Select Resource Format<span className='ma-required'>*</span></p>
                        <div className=''>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>
                                <div className='ml-3' style={{ alignSelf: 'center', }}>
                                    <FormatsIcon />
                                </div>
                                <Dropdown
                                    className='h-3rem w-full border-round-md pt-2 border-none text-lg pl-3'
                                    options={[]}
                                    placeholder="Select Formats" />
                            </div>
                        </div>
                    </div>

                </Dialog>

                <Dialog
                    visible={this.state.isDeleteResourceOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className=''
                    style={{ height: "400px", width: '500px', }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='formHeadingInter' style={{}}>
                            <CrossInBox bgcolor={'#E96C00'} color={'black'} />

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button className='formSubmitButton px-6' style={{ backgroundColor: '#C25400', border: 0 }} onClick={() => this.setState({ isDeleteResourceOpen: false })} >
                                <p className='formSubmitButtonText'>Delete</p>
                            </Button>
                            <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isDeleteResourceOpen: false })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isDeleteResourceOpen: false })}
                >
                    <div>
                        <p className='sideHeading'>Delete Resource</p>
                        <p className='sideHeading'>Resource Type: Video</p>
                        <p className='sideHeading'>File Name: Classet Sample 4</p>
                        <p className='topicName mt-1' style={{ color: '#4E616B', textAlign: 'center' }}>Confirming this will Delete the resource and change the priority accordingly </p>

                    </div>

                </Dialog>



                <Dialog
                    visible={this.state.isEditResourceDailogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className=''
                    style={{}}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="formHeadingInter">Edit Resource</h4>
                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button className='formSubmitButton px-8' onClick={() => this.setState({ isEditResourceDailogOpen: false })} >
                                <ClassetEditIcon width={28} height={28} color={'white'} />
                                <p className='formSubmitButtonText'>Edit</p>
                            </Button>
                            <Button className='formCancelButton px-4' onClick={() => this.setState({ isEditResourceDailogOpen: false })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isEditResourceDailogOpen: false })}
                >
                    <div className='card mx-4 mt-3'>
                        <p className='sideHeading' style={{ textAlign: 'start' }}>Upload Video</p>
                        <div className='grid mt-3 border-1 border-black-alpha-40 mx-1	'>
                            <div className='col-3'>
                                <Dropdown
                                    className='h-3rem w-full border-round-md pt-2 border-none text-lg pl-3 shadow-3'
                                    options={sampleOptions}
                                    placeholder="Priority(1-25)" />
                            </div>
                            <div className='card col-8 my-3 mx-1'>
                                File Name
                            </div>
                        </div>
                        <div className='card mt-3'>
                            Resource Title
                        </div>
                        <div className='flex flex-row justify-content-between'>
                            <p className='addButtonClass' style={{ fontSize: 16, width: '75%', }}> <span style={{ color: '#FF0000' }}>Note :</span> Upload Resources Priority File Supported videos formats mp4,mpg,avi,wmv,mov and flv</p>
                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={() => { this.setState({ isDialogOpenForCreateBoard: true }) }}>
                                <p className='addButtonClass'>Upload</p>
                                <UploadBoxIcon />
                            </Button>
                        </div>
                    </div>

                </Dialog>
                {
                    this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                        this.setState({
                            isShowPreview: false
                        })
                    }} contentInfo={this.state.contentData} />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(ContentManagement)));