import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import uuidv4 from 'uuid/v4';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import Service from '../../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { trimObj } from '../../../../utile';
import { getFormFields, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../../utile/formHelper';
import stopFieldsJson from './stopFields.json'
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

export default class AddStop extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(stopFieldsJson, {})
        this.state = {
            stopField: this.props.editStopDetails ? this.props.editStopDetails : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            feeTypes: this.props.editStopDetails ? this.props.editStopDetails.feeTypes : [{
                id: uuidv4(),
                feeType: 'Bus Fee',
                amount: '',
                isItMandatory: null,
                installments: [],
                feeTypes: [],
            }],
        }

        this.service = new Service()
    }


    componentDidMount() {
        this.getFeeTypes()
    }

    getFeeTypes = () => {
        this.setState({
            isLoading: true
        })

        let url = `${baseUrlAdmin}/bus-fee-plans/bus-fee-types`

        this.service.get(url, true).then(data => {
            if (data.status) {
                if (data.res && data.res.data && data.res.data.length) {
                    this.setState({
                        feeTypes: data.res.data,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });

            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })
    }




    onSubmitClick = () => {

        let body = cloneDeep(this.state.stopField)
        const formStatus = isFormValid(stopFieldsJson, this.formFields.formValidations, trimObj(body));
        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            })
            isCompleteFormValid = false
        }

        if (isCompleteFormValid) {

            let body = this.state.stopField
            body.transportRoute = this.props.routeId._id;
            delete body.admissions;


            this.setState({
                isLoading: true
            })

            if (this.props.editStopDetails) {


                let url = `${baseUrlAdmin}/transport-route/stops/update`

                this.service.put(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate(true))
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            } else {

                let url = `${baseUrlAdmin}/transport-route/stops/add`

                this.service.post(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate())
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            }
        }

        if (isCompleteFormValid) {
            // let url = `${baseUrlAdmin}/`

            // this.service.post(url, true).then(res => {
            //     if (res?.status && res?.res?.status) {
            //         this.setState({
            //             isLoading: false,
            //             users: res?.res?.data
            //         })
            //     }else{
            //         this.setState({
            //             isLoading : false
            //         })
            //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            //     }
            // }).catch(err => {
            //     this.setState({
            //         isLoading: false
            //     })
            //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

            // })
        }
    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.editStopDetails ? 'Update' : 'Save'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onCloseAddStop} />
        </div>)
    }



    feeTypeRender = (rowData) => {
        return <InputText value={rowData.feeType}
            className='ma-w100p'
        />
    }
    onNumberChange = (id, value) => {

        if (isNaN(value) || parseInt(value) < 0) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Invalid amount', life: 3000 });
        } else {
            let a = this.state.feeTypes.map(a => {
                if (a.id == id) {
                    a.amount = value;
                    a.installments = [];
                }

                return a;
            })

                ;
            this.setState({
                feeTypes: a
            })
        }
    }


    amountRender = (rowData) => {
        return <InputText
            value={rowData.amount}
            onChange={(e) => { this.onNumberChange(rowData.id, e.target.value) }}
            className='p-inputtext-style1' />

    }

    onChoiceChange = (id, value) => {
        let a = this.state.feeTypes.map(a => {
            if (a.id == id) {
                a.isItMandatory = value
            }
            return a;
        })
        this.setState({
            feeTypes: a
        })
    }

    isMandateryRender = (rowData) => {
        return <>
            <label className='ma-label-s1' htmlFor='Yes'>Yes</label>
            <RadioButton className="radio-inline" value={true} inputid="Yes" name="isItMandatory"
                onChange={(e) => {
                    this.onChoiceChange(rowData.id, e.value)
                }}
                checked={rowData.isItMandatory}
            />
            <label className='ma-label-s1' htmlFor='No'>No</label>
            <RadioButton className="radio-inline ma-ml10" value={false} inputid="No" name="isItMandatory"
                onChange={(e) => {
                    this.onChoiceChange(rowData.id, e.value)
                }}
                checked={rowData.isItMandatory == false}
            />
        </>

    }

    installmentsRender = (rowData) => {

        return (
            <>
                <span> {rowData?.installments?.length ? rowData?.installments?.length : 0} installments </span>
                <Button label="+ Add" className='' onClick={() => this.showAddInstallPopup(rowData)} />
            </>
        )
    }

    showAddInstallPopup = (rowData) => {
        if (rowData.feeType == '' || rowData.amount == '' || rowData.isItMandatory == null) {
            return this.toast.show({ severity: 'error', summary: 'invalid', detail: 'Please select Fee type, amount, is madatory field to add installments.', life: 3000 });
        }



        this.setState({
            selectedFeeType: rowData,
            isShowInstallmentsPopup: true
        })

    }

    installmentNameRender = (rowData) => {
        return <Dropdown value={rowData.feeType}
            className='ma-w100p'
            options={this.state.allFeeTypes}
            optionLabel='feeType'
            optionValue='feeType'
            onChange={(e) => this.onChangeText(rowData.id, e.value)}
            placeholder="Select Board" />
    }

    render() {
        const { formValidations, stopField } = this.state
        console.log('addstops', this.props.routeId._id)
        return (
            <>
                <Dialog
                    visible={this.props.showAddStop}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    style={{ maxWidth: '75%', minWidth: '75%' }}
                    footer={this.footer}
                    header={`${this.props.editStopDetails ? 'Update' : 'Enter'} Stop Details`} headerClassName='text-center'
                >
                    <div className="grid subject-add-dialog">
                        <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Stop Number<span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w100p'
                                value={stopField.stopNumber}
                                onChange={(e) => onTextChange(e.target.value, 'stopNumber', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['stopNumber'].isValid && <p className="p-error">{formValidations.fields['stopNumber'].errorMsg}</p>}
                        </div>

                        <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Stop Name<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w100p'
                                value={stopField.stopName}
                                onChange={(e) => onTextChange(e.target.value, 'stopName', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['stopName'].isValid && <p className="p-error">{formValidations.fields['stopName'].errorMsg}</p>}
                        </div>

                        <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Location<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w100p'
                                value={stopField.location}
                                onChange={(e) => onTextChange(e.target.value, 'location', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['location'].isValid && <p className="p-error">{formValidations.fields['location'].errorMsg}</p>}

                        </div>

                        <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>PickUp Time<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <Calendar className='w-11 ma-w100p'
                                value={stopField.pickUpTime ? new Date(stopField.pickUpTime) : null}
                                timeOnly
                                onChange={(e) => onTextChange(e.value, 'pickUpTime', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['pickUpTime'].isValid && <p className="p-error">{formValidations.fields['pickUpTime'].errorMsg}</p>}
                        </div>
                        <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Drop Time<span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <Calendar className='w-11 ma-w100p'
                                value={stopField.dropTime ? new Date(stopField.dropTime) : null}
                                timeOnly
                                onChange={(e) => onTextChange(e.value, 'dropTime', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['dropTime'].isValid && <p className="p-error">{formValidations.fields['dropTime'].errorMsg}</p>}
                        </div>
                        <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Distance From School<span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w100p'
                                value={stopField.distanceFromSchool}
                                onChange={(e) => onNumberChange(e.target.value, 'distanceFromSchool', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['distanceFromSchool'].isValid && <p className="p-error">{formValidations.fields['distanceFromSchool'].errorMsg}</p>}
                        </div>

                        <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Fee Plans<span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <Dropdown className='w-11 ma-w100p'
                                options={this.state.feeTypes} optionLabel='name' optionValue='_id'
                                value={stopField.feeType}
                                onChange={(e) => onDropDownChange(e.value, 'feeType', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>}
                        </div>
                        {/* <div className="col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Fee<span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w100p'
                                value={stopField.fee}
                                onChange={(e) => onNumberChange(e.target.value, 'fee', this, stopFieldsJson, stopField, formValidations, 'stopField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['fee'].isValid && <p className="p-error">{formValidations.fields['fee'].errorMsg}</p>}

                        </div> */}
                    </div>


                </Dialog>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}
