import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import '../style.scss';
import { MailIcon, TickBgIcon, ArrowClockWise, InputIcon } from '../../svgIcons';
import { BasicLazyParams } from '../../../utile';
import { MultiSelect } from 'primereact/multiselect';
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const options = [
    { value: 1, label: 'Fee Type Based' },
    { value: 2, label: 'Term Based' }];




const feeTypeData = [
    {
        fee_type: "Monday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Tuesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Wednesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Thursday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Friday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Saturday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },


];
const searchOptions = [
    { value: 1, label: "Student Name" },
    { value: 2, label: "Enrollment Number" }
]

const paymentType = [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Cheque' },
    { value: 3, label: 'DD' },
    { value: 4, label: 'Online' },
    { value: 5, label: 'Credit Card' },
    { value: 6, label: 'Debit Card' },
    { value: 7, label: 'QR Code' },
]
export class RequestSubstitution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            feeTypeData: feeTypeData,
            paymentType: paymentType,
            openCreateSubstituionDialog: false,
            searchType: 1,
            searchInput: ""
        }
    }

    onHandleUnassign = () => {

        // console.log('Unassigned:', this.state.selectedFeeData, 'with remarks:', this.state.remarks);

        this.setState({
            openCreateSubstituionDialog: true,
            selectedFeeData: null,
            remarks: ""
        });
    }

    onHandleCancelPayFee = () => {
        this.setState({
            openCreateSubstituionDialog: false,
            paymentTypeOption: null,
            feeInfoToPayFee: {}

        })
    }

    openCreateSubstituion = () => {
        this.setState({
            openCreateSubstituionDialog: true,
        })
    }

    render() {
        return (
            <div className="m-2">




                <div className='grid m-2 mb-6'>
                    <div className="col-3">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Board</p>
                        <div className='flex w-10'>
                            <Dropdown
                                className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                options={sampleOptions}
                                // value={term.academicBoard}
                                // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center mt-1">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <span className='text-xl ml-4'>Select Board</span>
                                    </div>
                                )} />
                        </div>
                    </div>
                    <div className="col-3">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Grade</p>
                        <div className='flex w-10'>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                <Dropdown
                                    className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4'>Select Grade</span>
                                        </div>
                                    )} />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 ">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Section</p>
                        <div className='flex w-10'>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                <Dropdown
                                    className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4'>Select Section</span>
                                        </div>
                                    )} />
                            </div>
                        </div>
                    </div>

                </div>


                {
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.feeTypeData}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesTextt"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        resizableColumns
                        // selection={this.state.selectedProducts} 
                        // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    >

                        <Column field="fee_type" bodyClassName='text-center' header="Days / Periods" alignHeader={"center"} />
                        <Column field="approval" bodyClassName='text-center' header="Period 1" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="total_fee" bodyClassName='text-center' header="Period 2" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="concession" bodyClassName='text-center' header="Period 3" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="assigned_fee" bodyClassName='text-center' header="Period 4" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 5" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 6" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 7" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>
                        <Column field="paid_fee" bodyClassName='text-center' header="Period 8" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex align-content-end justify-content-end -mt-5 -mr-3">
                                    <span data-pr-tooltip="Edit User" data-pr-position="" className={''}
                                        onClick={() => { this.openCreateSubstituion(rowData) }}
                                    >
                                        <ArrowClockWise width={10} height={10} color={'#000000'} />

                                    </span>
                                </div>
                                <p>{rowData?.approval}</p>
                            </>
                            );
                        }} ></Column>




                    </DataTable>

                }

                <Dialog
                    visible={this.state.openCreateSubstituionDialog}
                    draggable={false}
                    closeOnEscape={false}
                    position={"right"}
                    className='ma-alert-box w-3 mr-0'
                    style={{ width: "300px", height: "600px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h4 className="text-center text-black-alpha-90 font-bold">Request Substitution </h4>

                        </div>)
                    }}
                    footer={() => (
                        <div className="mt-3" style={{ display: 'flex' }}>
                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.setState({ isDialogOpenForCreateConcession: true }) }}>
                                <p className='text-xl ml-2'>Request Substitution</p>
                            </Button>
                            <Button style={{ borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.setState({ openCreateSubstituionDialog: false }) }}>
                                <p className='text-xl'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ openCreateSubstituionDialog: false })}
                >
                    <div className='flex flex-column justify-content-center mx-3'>

                        <div className="">
                            <div className='mt-3'>
                                <p className='ma-label-s2 font-bold  text-black'>Reason for Substitution<span className='ma-required'>*</span></p>
                                <div className='flex'>
                                    <div className="p-inputgroup border-300 shadow-2 border-round-md" style={{ position: 'relative', height: '5rem', width: '100%', padding: 0 }}>
                                        <label
                                            style={{
                                                position: 'absolute',
                                                top: '0.5rem',
                                                left: '0.5rem',
                                                fontSize: '13px',
                                                color: '#aaa',
                                                pointerEvents: 'none'
                                            }}
                                        >
                                            Add Remarks
                                        </label>
                                        <InputText

                                            id="title"
                                            style={{
                                                // height: '100%',
                                                // width: '100%',
                                                // padding: '1.5rem 0.5rem 0.5rem 0.5rem',
                                                fontSize: '1rem',
                                                borderRadius: '0.5rem',
                                                border: 'none',
                                                boxShadow: 'none'
                                            }}
                                            value={this.state.remarks}
                                            onChange={(e) => this.setState({ remarks: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <p className='ma-label-s2 ml-2 font-bold' style={{ color: 'black' }}>Period Number <span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md pl-1" style={{backgroundColor:'#E1DEDE'}}>
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText style={{backgroundColor:'#E1DEDE'}} id="title" className='h-3rem  border-round-md  border-none  pl-3' onChange={(e) => { }} placeholder="Period Number" />

                                </div>
                            </div>
                            {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                        </div>


                        <div className='mt-3' >
                            <p className='ma-label-s2 font-bold' style={{ color: 'black' }}>Day of the week<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md pl-1" style={{backgroundColor:'#E1DEDE'}}>
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText style={{backgroundColor:'#E1DEDE'}} id="title" className='h-3rem  border-round-md  border-none  pl-3' onChange={(e) => { }} placeholder="Day of the week" />

                                </div>
                            </div>
                            {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                        </div>

                        <div className="mt-3">
                            <p className='ma-label-s2 ml-2 font-bold' style={{ color: 'black' }}>Date <span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md pl-1" style={{backgroundColor:'#E1DEDE'}}>
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText style={{backgroundColor:'#E1DEDE'}} id="title" className='h-3rem w-full border-round-md  border-none  pl-3' onChange={(e) => { }} placeholder="Date" />

                                </div>
                                {/* <i className="pi pi-info-circle  font-bold text-lg ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                        </div>

                        <div className='mt-3' >
                            <p className='ma-label-s2 font-bold' style={{ color: 'black' }}>Board<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md pl-1" style={{backgroundColor:'#E1DEDE'}}>
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText style={{backgroundColor:'#E1DEDE'}} id="title" className='h-3rem w-full border-round-md  border-none  pl-3' onChange={(e) => { }} placeholder="Concession Amount/Percentage" />

                                </div>
                                {/* <i className="pi pi-info-circle  font-bold text-lg ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                        </div>

                        <div className='mt-3' >
                            <p className='ma-label-s2 font-bold' style={{ color: 'black' }}>Grade<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md pl-1" style={{backgroundColor:'#E1DEDE'}}>
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText style={{backgroundColor:'#E1DEDE'}} id="title" className='h-3rem w-full border-round-md  border-none  pl-3' onChange={(e) => { }} placeholder="Grade" />

                                </div>
                                {/* <i className="pi pi-info-circle  font-bold text-lg ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                        </div>

                        <div className='mt-3' >
                            <p className='ma-label-s2 font-bold' style={{ color: 'black' }}>Section<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md pl-1" style={{backgroundColor:'#E1DEDE'}}>
                                    <span className="h-3rem pt-3 px-1"><i className="pi pi-envelope text-lg pl-3"></i></span>
                                    <InputText style={{backgroundColor:'#E1DEDE'}} id="title" className='h-3rem w-full border-round-md  border-none  pl-3' onChange={(e) => { }} placeholder="Section" />

                                </div>
                                {/* <i className="pi pi-info-circle  font-bold text-lg ml-1"></i> */}
                            </div>
                            {/* {formValidations && !formValidations.fields['rolename'].isValid && <p className="p-error">{formValidations.fields['rolename'].errorMsg}</p>} */}
                        </div>

                    </div>
                </Dialog>




            </div>
        )
    }
}






export default RequestSubstitution