import React, { Component } from "react"
import { connect } from 'react-redux';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import cloneDeep from 'lodash/cloneDeep';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import _ from 'lodash';
import { AddIcon2, DownloadIconBulk, GradesIcon, MailIcon, WarningIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask } from './../../../store/apiConstants';
import { BreadCrumb } from 'primereact/breadcrumb';
import CreateFeeType from "../../organizationManage/feeTypes/createFeeType";
import { MultiSelect } from "primereact/multiselect";
import withRouter from '../../lib/withRouter';
import { AnalyticsIcon2 } from '../../svgIcons';
import moment from 'moment';
import InputTextB from '../../customComponents/inputTextB';
import { userAssignedBoards } from './../../../store/selectors/userAssignedBoards';
import './styles.scss'
import BranchToSectionMultiDD from "../../BaseDropdownComponents/BranchToSectionMultiDD";
import {
    getBulkUploadTransactionDetails,
    getBulkUploadTransactions,
    saveBulkUploadUsers,
    clearBulkUserUploadData
} from './../../../store/actions';
import * as XLSX from 'xlsx';
import ClassetInputText from "../../../classetComponents/classetInputText";
import BranchToSectionDD from "../../BaseDropdownComponents/BranchToSectionDD";
import { getBoardsData, getBranchesLatest } from './../../../store/actions';

const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const SampleBulkUplodData = [];

class UserBulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openSnackBar: false,
            users: [],
            showMissedListTable: false,
            showUploadBtn: false,
            showLoader: false,
            transactionId: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            dropdownsData: {},
            isShowDetails: false,
            columns: [
                { columnName: 'Password', isVisible: false, id: 'password' }],
            refreshKey: 0
        };
        this.service = new Service();

    }

    refreshBranchToSectionDD = () => {

        this.setState(prevState => ({
            refreshKey: prevState.refreshKey + 1
        }));
    };

    getBulkUploadTransactionsData = () => {
        console.log(this.state.lazyParams,"this.state.lazyParams")
        this.props.getBulkUploadTransactions(
            { userId: this.props.userId },
            { ...this.state.lazyParams, globalSearch: this.state.globalSearch }
        )




    };

    componentDidMount = () => {
        this.getBulkUploadTransactionsData();
    };

    componentWillUnmount() {
        this.props.clearBulkUserUploadData();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Failed to upload bulk users data', life: 5000 });
            }
        }

        if (prevProps.bulkUserUploadSaveResponse !== this.props.bulkUserUploadSaveResponse) {
            if (this.props.bulkUserUploadSaveResponse) {
                debugger
                const transactionId = this.props.bulkUserUploadSaveResponse?.transaction_id ? this.props.bulkUserUploadSaveResponse?.transaction_id : '';
                this.toast.show({ severity: 'success', summary: 'Process is initiated', detail: `Bulk users upload process initiated, kindly refer the transaction ${transactionId} details in below table`, life: 10000 });

                this.getBulkUploadTransactionsData();



            }
        }
        // console.log(this.props.bulkUserUploadRequested,"this.props.bulkUserUploadRequested")
        // if(this.props.bulkUserUploadRequested===true){
        //     this.getBulkUploadTransactionsData();
        //    // this.setDropdownValues({dropdownsData:{}})
        // }



    }


    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            const users = newJsonSheet.map((record) => ({
                ...record,
                mobile: `+91${record.mobile}`
            }));

            this.setState({ users });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    uploadBulkUsers = () => {
        //this.getFeeCategoryAssignInfo(dropdownsData.branchId, dropdownsData.boardId, dropdownsData.classId, dropdownsData.sectionId) 



        let users = this.state.users.map(user => {
            return {
                ...user,
                board: this.state.dropdownsData?.selectedBoardName,
                boardId: this.state.dropdownsData?.boardId,
                grade: this.state.dropdownsData?.selectedClassName,
                gradeId: this.state.dropdownsData?.classId,
                sectionId: this.state.dropdownsData?.sectionId,
                sectionName: this.state.dropdownsData?.selectedsectionName,
                branchId: this.state.dropdownsData?.branchId,
                branchName: this.state.dropdownsData?.selectedbranchName
            };
        });

        console.log(this.state.dropdownsData, "dropdownsData.branchIddropdownsData.branchIddropdownsData.branchId", users)

        const payload = {
            user_id: this.props.userId,
            users: users,
            academicYear: localStorage && localStorage.getItem('userAcademicYear')

        };



        this.setState({
            users: [],
            fileSelected: null,
            fileSelected: '',
            dropdownsData: {}

        }, () => {

            this.props.saveBulkUploadUsers(payload);
            this.refreshBranchToSectionDD()

        });
    };

    getTransactionDetails = (transactionId) => {


        this.setState({
            isShowDetails: true,
            transactionId
        }, () => {

            const payload = {
                userId: this.props.userId,
                transactionId
            };
            this.props.getBulkUploadTransactionDetails(payload)
        });






    };

    onPage = (event) => {
        console.log(event,"eventtt")
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getBulkUploadTransactionsData);
    }

    

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getBulkUploadTransactionsData);
    }


    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData: dropdownsData
        }, () => { })
    }
    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };
    exportCSV = () => {
        this.dt.exportCSV();
    }
    exportCSV2 = () => {
        this.dt2.exportCSV();
    }



    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }
    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className='body-small-semibold'>Students List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>


            </div>
        );

        const header2 = (
            <div className="table-header ma-tbl-header ml-3 mr-3 mt -3 flex justify-content-between">
                <div className="">
                    <h4 >Sample Bulk Upload Sheet</h4>
                </div>
                <div className="">
                    <ul className="ma-tbl-li">
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV2}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        const headerTransaction = (
            <div className="table-header ma-tbl-header ml-3 mr-3 mt -3 flex justify-content-between">
                <div className="mt-4">
                    <h4 >Transactions Details - {this.state.transactionId}</h4>
                </div>
                <div className="">
                    <ul className="ma-tbl-li">
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />

                    </ul>
                </div>
            </div>
        );
        console.log(this.props.totalRecords,"this.props.totalRecords")
        return (
            <>
        
            <div >
                <BranchToSectionDD setValues={this.setDropdownValues} key={this.state.refreshKey} />

                <div class="formgrid grid mx-4 my-4">
                    <div class="mr-2">
                        <ClassetInputText
                            title='GradeName'
                            icon={<MailIcon />}
                            placeholder={'File Name '}
                            value={this.state.fileSelected} disabled
                        />
                    </div>
                    <div class="mr-2 mt-3">

                        <label htmlFor="file" className='selectFile'>Select File</label>
                        <input
                            type="file"
                            className="form-control"
                            id="file"
                            hidden={true}
                            accept={SheetJSFT}
                            onChange={this.handleChange}
                            disabled={this.props.bulkUserUploadRequested}
                        />
                    </div>
                    <div class="mr-2">
                        <Button className='formUploadButton' disabled={this.props.bulkUserUploadRequested}
                            onClick={() => {
                               
                                if (Object.keys(this.state.dropdownsData).length === 0) {
                                    this.toast.show({ severity: 'error', summary: `Branch,Borad,Grade Selection is required. Please Select the all Dropdown  `, detail: '', life: 3000 });
                                    return
                                } else if (!Object.keys(this.state.dropdownsData).length === 8) {
                                    this.toast.show({ severity: 'error', summary: `Please Select the all Dropdown  `, detail: '', life: 3000 });
                                    return
                                } else if (this.state.fileSelected === undefined || this.state.fileSelected === "") {

                                    this.toast.show({ severity: 'error', summary: `Please Select the File  `, detail: '', life: 3000 });
                                    return

                                } else if (this.state.users && this.state.users.length) {
                                    this.uploadBulkUsers();
                                }
                            }}>
                            <p className='formUploadButtonText'>Upload Student Data</p>
                        </Button>
                    </div>

                    <div class="mr-2">
                        <Button className='formBulkButton' disabled={this.props.bulkUserUploadRequested}
                            onClick={() => {
                                this.setState({
                                    isShowSampleFormat: true
                                })
                            }} >
                            <DownloadIconBulk /> <p className='formBulkButtonText'>Download Bulk Format</p>
                        </Button>
                    </div>
                </div>


                <div className="card datatable-crud-demo mt-5 mx-4">

                    <DataTable
                        //  lazy
                        value={this.props.bulkUserUploadTransactions}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesText"
                        showGridlines
                        columnResizeMode="expand"
                        resizableColumns
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.props.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.props.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}

                    >

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="transaction_id" field="transaction_id" header="Transaction Id" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="bulkStatus" field="bulkStatus" header="Status" />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="uploadedAt" field="uploadedAt" header="Date" />


                        <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Actions" body={(rowData, { rowIndex }) => {
                            return (
                                // <Button label="Show Details" onClick={() => { }} className='p-button-outlined' />

                                <Button className='formShowButton' disabled={this.props.bulkUserUploadRequested}
                                    // this.getTransactionDetails(rowData.transaction_id)
                                    onClick={() => this.getTransactionDetails(rowData.transaction_id)}
                                >
                                    <p className='formShowButtonText'>Show Details</p>
                                </Button>

                            )
                        }} ></Column>


                    </DataTable>




                    <Dialog
                        draggable={false}
                        closeOnEscape={true}
                        className='ma-alert-box w-90'
                        blockScroll={true}
                        dismissableMask={false}
                        closable={true}

                        style={{ minHeight: '90vh' }}
                        visible={this.state.isShowSampleFormat}
                        onHide={() => this.setState({ isShowSampleFormat: false })}>
                        <div>
                            <div className="card datatable-crud-demo ma-m30">
                                <DataTable ref={(el) => this.dt2 = el}
                                    value={SampleBulkUplodData}
                                    dataKey="id"
                                    header={header2}
                                    emptyMessage=""
                                    scrollable
                                    responsiveLayout="scroll"
                                    style={{ width: '100%' }}
                                >
                                    <Column field="mobile" header="mobile" />
                                    <Column field="username" header="username" />
                                    <Column field="given_name" header="given_name" />
                                    <Column field="email" header="email" />
                                    <Column field="password" header="password" />
                                    <Column field="grade" header="grade" />
                                    <Column field="board" header="board" />
                                    <Column field="gradeId" header="gradeId" />
                                    <Column field="boardId" header="boardId" />
                                    <Column field="sectionId" header="sectionId" />
                                    <Column field="sectionName" header="sectionName" />
                                    <Column field="gender" header="gender" />
                                    <Column field="admissionDate" header="Admission Date" />
                                    <Column field="aadharNo" header="aadhar No" />
                                    <Column field="dateOfBirth" header="Date Of Birth" />
                                    <Column field="age" header="age" />
                                    <Column field="fatherName" header="Father Name" />
                                    <Column field="fatherOccupation" header="Father Occupation" />
                                    <Column field="fatherQualification" header="Father Qualification" />
                                    <Column field="fatherMobileNum" header="Father Mobile Number" />
                                    <Column field="fatherEmailId" header="Father EmailId" />
                                    <Column field="motherName" header="Mother Name" />
                                    <Column field="motherOccupation" header="Mother Occupation" />
                                    <Column field="motherQualification" header="Mother Qualification" />
                                    <Column field="motherMobileNum" header="Mother Mobile Number" />
                                    <Column field="motherEmailId" header="Mother EmailId" />
                                    <Column field="guardianName" header="Guardian Name" />
                                    <Column field="guardianOccupation" header="Guardian Occupation" />
                                    <Column field="guardianQualification" header="Guardian Qualification" />
                                    <Column field="guardianMobileNum" header="Guardian Mobile Number" />
                                    <Column field="guardianEmailId" header="Guardian EmailId" />
                                    <Column field="houseNum" header="House Number" />
                                    <Column field="streetOrVillage" header="Street Or Village" />
                                    <Column field="mandal" header="mandal" />
                                    <Column field="cityOrDist" header="city Or Dist" />
                                    <Column field="state" header="state" />
                                    <Column field="pinCode" header="pinCode" />
                                    <Column field="siblingsName" header="Sibling Name" />
                                    <Column field="siblingsGender" header="Sibling Gender" />
                                    <Column field="siblingsClassStudying" header="Class Studying" />
                                    <Column field="siblingsSchoolStudying" header="School Studying" />
                                    <Column field="prevAcademicDetailsName" header="prevAcademicDetailsName" />
                                    <Column field="prevAcadamicDetailsClassStudied" header="prevAcadamicDetailsClassStudied" />
                                    <Column field="prevAcademicDetailsSchoolStudied" header="prevAcademicDetailsSchoolStudied" />
                                    <Column field="prevAcademicDetailsPercentageOrGpa" header="prevAcademicDetailsPercentageOrGpa" />

                                </DataTable>
                            </div>
                        </div>

                    </Dialog>


                    <Dialog
                        draggable={false}
                        closeOnEscape={true}

                        blockScroll={true}
                        dismissableMask={false}
                        closable={true}

                        style={{ minHeight: '90vh' }}
                        visible={this.state.isShowDetails}
                        onHide={() => this.setState({ isShowDetails: false })}
                        header={headerTransaction}

                    >
                        <div>
                            <div className="card datatable-crud-demo ma-m30">
                                <DataTable ref={(el) => this.dt = el}
                                    //  lazy
                                    className='ma-alert-box w-100'

                                    value={this.props.bulkUserUploadTransactionDetails}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    responsiveLayout='scroll'
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}

                                >
                                    <Column sortable field="username" header="User Name" />
                                    <Column sortable field="mobile" header="Phone Number" />
                                    {
                                        this.isColumnVisible('password') && <Column sortable field="password" header="Password" />
                                    }
                                    <Column sortable field="isCreated" header="User Creation Status" />
                                    <Column sortable field="isError" header="Error Info" />
                                    <Column sortable field="given_name" header="Given Name" />
                                    <Column sortable field="board" header="Board" />
                                    <Column sortable field="grade" header="Class" />
                                    <Column sortable field="group" header="Group" />
                                    <Column sortable field="sectionId" header="Section Id" />
                                </DataTable>
                            </div>
                        </div>

                    </Dialog>

                    {this.props.loading && <LoadingComponent />}
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />

                </div>

            </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({

    loading: state.createNewUserInfo && state.createNewUserInfo.loading,
    error: state.createNewUserInfo && state.createNewUserInfo.error,
    bulkUserUploadTransactions: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions.bulkTransaction,
    totalRecords: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions.totalRecords,
    bulkUserUploadTransactionDetails: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactionDetails && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0] && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0].output_json,
    bulkUserUploadSaveResponse: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadSaveResponse && state.createNewUserInfo.bulkUserUploadSaveResponse,
    bulkUserUploadRequested: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadRequested && state.createNewUserInfo.bulkUserUploadRequested,

    userId: localStorage.getItem('username'),

});


export default connect(mapStateToProps, {
    getBulkUploadTransactionDetails,
    getBulkUploadTransactions,
    saveBulkUploadUsers,
    clearBulkUserUploadData,
    getBoardsData, getBranchesLatest
})(Authentication(UserBulkUpload));