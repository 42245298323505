import React, { Component } from 'react'
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import {
    VideoIcon, PptIcon,
    PdfIcon,
    QuizIcon,
    QuizCountIcon
} from '../../svgIcons';


let totalAnalyticsTypes = [
    { field: 'mp4', title: 'VIDEOS', icon: <VideoIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'pdf', title: 'PDF', icon: <PdfIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'zip', title: 'PPT', icon: <PptIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quiz', title: 'QUIZS', icon: <QuizIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quizCount', title: 'QUIZ ATTEMPTS', icon: <QuizCountIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Attempts' }

]



export default class TotalAnalyticsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: '',
            columns: [
                { columnName: 'Total Time on VIDEOS', isVisible: true, id: 'mp4' },
                { columnName: 'Total Time on PDF', isVisible: true, id: 'pdf' },
                { columnName: 'Total Time on PPT', isVisible: true, id: 'zip' },
                { columnName: 'Total Time on QUIZ', isVisible: true, id: 'quiz' },
                { columnName: 'Total Time on QUIZ COUNT', isVisible: true, id: 'quizCount' }]
        }
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }
    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }




    render() {


        const { totalCount, finalRecords, totalUsageAnalytics } = this.props.totalUserRecord;
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h3 >Users List</h3>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6 list-none">
                        <Authorizer permId={PERMISSIONS.USAGEANALYTICS_SEARCH_TABLE} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalFilter: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.USAGEANALYTICS_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.USAGEANALYTICS_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w p-2'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div  onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (<>
            <div className='grid highlights-tabs'>
                {totalAnalyticsTypes.map((a) => {
                    return <div className=" sm:col-12 md:col-2 my-2 ">
                        <div className='paper-s1 highlights-tab lg:w-15rem md:w-8rem md:h-10rem'>
                            <p className='h-title'><span className='content-type'>{a.icon}</span> {a.title}</p>
                            <p className=''><span className='h-time-l'>{a.subTitle}:</span> <span className='h-time'>{totalUsageAnalytics[a.field]}</span></p>
                        </div>
                    </div>
                })}
            </div>

            <div className="card datatable-crud-demo ma-mt20">
                <DataTable ref={(el) => this.dt = el}
                    //  lazy
                    showGridlines
                    value={finalRecords || []}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"

                    paginatorLeft={() => {
                        return <>
                            <p className='avail-q'>Available Users:  <span>{finalRecords && finalRecords.length}</span> </p>
                        </>
                    }}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    responsiveLayout='scroll'
                    globalFilter={this.state.globalFilter}
                    header={header}>

                    <Column field="userName" header="User Name" />
                    {
                        this.isColumnVisible('mp4') && <Column field="mp4" header="Total Time on VIDEOS" />}
                    {
                        this.isColumnVisible('pdf') && <Column field="pdf" header="Total Time on PDF" />}
                    {
                        this.isColumnVisible('zip') && <Column field="zip" header="Total Time on PPT" />}
                    {
                        this.isColumnVisible('quiz') && <Column field="quiz" header="Total Time on QUIZ" />}
                    {
                        this.isColumnVisible('quizCount') && <Column field="quizCount" header="Total Attempts on QUIZ" />}
                    <Column header="Actions" body={(rowData) => {
                        return <>
                            <Authorizer permId={PERMISSIONS.USAGEANALYTICS_DETAILED_REPORT} >
                                <Button label='Detailed Report' onClick={() => { this.props.showUserAnalytics(rowData.userId, rowData.userName) }} />
                            </Authorizer>
                        </>
                    }} >
                    </Column>
                </DataTable>
            </div>

            <Toolbar className="total-stats border-none" right={() => {
                return (
                    <div>
                        <p className='total-time'><span>Total Time: </span>{totalCount}</p>
                        <p className='total-time'><span>Total Users: </span>{finalRecords.length}</p>
                    </div>
                )
            }}></Toolbar>


        </>)
    }
}
