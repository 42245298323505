import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './ClassetComponents.scss';  // Import the CSS file
import { Tooltip } from 'primereact/tooltip';
import LoadingComponent from '../components/loadingComponent';
import { ProgressSpinner } from 'primereact/progressspinner';

const ClassetDropdown = ({ icon, height, width, fromScreen, className, infoIcon, title, tooltipInfo, labelClassName, label, formHelperError, required, ...props }) => {
    const defaultHeight = height ? height : "48px"
    const defaultWidth = width ? width : "343px"
    const [focused, setFocused] = useState(false);
    const dropdownRef = React.useRef(null);

    const handleFocus = () => {
        if (!props.disabled) {
            setFocused(true);
        }
    };

    const handleBlur = () => {
        if (!props.disabled) {
            setFocused(false);
        }
    };

    return (
        <>
            <label htmlFor={title} className={`ma-label-s2 font-serif font-semibold text-900 text-lg  ${labelClassName}`}>{label}
                {required && <span className="ma-required">*</span>}
            </label>
            <div className='flex'>
                <div style={{ height: defaultHeight, width: defaultWidth }}
                    className={classNames('p-inputgroup', 'custom-input-group', `border-300 shadow-2 border-round-lg flex justify-content-center align-items-center ${formHelperError && 'border-red-400 border-2'}`, { 'custom-input-focused': focused })}
                    onClick={handleFocus}
                >
                    <span className={icon ? "p-inputgroup-addon pl-3 " : ""}>
                        {icon}
                    </span>
                    {fromScreen == "NavTopBar" ?
                        props?.options?.length && props?.value ?
                            <Dropdown id={title} className={`${className} no-border`} ref={dropdownRef} {...props} onFocus={handleFocus} onBlur={handleBlur} />
                            :
                            <ProgressSpinner style={{ width: '34px', height: '34px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />

                        :

                        <Dropdown id={title} className={`${className} no-border`} ref={dropdownRef} {...props} onFocus={handleFocus} onBlur={handleBlur} />
                    }

                </div>
                {(tooltipInfo != undefined || tooltipInfo != null) &&
                    <div className="">
                        <Tooltip target=".custom-target-icon ">
                            <div className='flex justify-content-center align-items-center'>
                                <i className="pi pi-info-circle  mr-3"></i>
                                {tooltipInfo}
                            </div>
                        </Tooltip>

                        <i className="custom-target-icon  pi pi-info-circle mt-2 font-bold text-xl ml-1"
                            data-pr-position="right"
                            data-pr-at="right+5 center"
                            data-pr-my="left center-2"
                            style={{ fontSize: '2rem', cursor: 'pointer' }}>
                        </i>
                    </div>}
            </div>
        </>
    );
};

export default ClassetDropdown;
