import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import InputTextB from './../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
import uuidv4 from 'uuid/v4';
import './styles.scss'
import { trimObj } from '../../../utile';
import { I18Next } from './../../../internationalization';

const formFields = [
    {
        "Type": "Text",
        "Label": "Board Name",
        "FieldName": "boardName",
        "Required": true,
        "MaxLength": 50
    },
    {
        "Type": "Text",
        "Label": "Board Code",
        "FieldName": "boardCode",
        "Required": true
    },
    {
        "Type": "Toggle",
        "Label": "Active Status",
        "FieldName": "boardActivity",
        "Required": false
    }

]

class CreateBoard extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editBoard || { boardId: uuidv4() });
        this.state = {
            board: this.formFields.data,
            formValidations: this.formFields.formValidations
        }
    }

    footer = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (<div>
            <Button label={I18Next('cancel', langType).COMMON_KEY} className='p-button-outlined' onClick={this.onCloseDialog} />
            <Button label={`${this.props.isAdd ? (I18Next('add', langType).COMMON_KEY) : (I18Next('update', langType).COMMON_KEY)}`} className='p-button-raised' onClick={this.onSubmitClick} />

        </div>)
    }

    onSubmitClick = () => {
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.board));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            this.props.addBoard(trimObj(this.state.board));
            // this.setState({
            //     formValidations: formStatus.formValidations,
            // });
        }
    }

    onCloseDialog = () => {
        this.props.hideCreateBoardDialog();

    }

    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.board));
        let formValidations = this.state.formValidations;
        data[field] = e.target.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            board: data,
            formValidations
        });
    }
    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.board));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            board: data,
            formValidations
        });
    }

    render() {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        const { board, formValidations } = this.state;
        return (
            <Dialog header={`${this.props.isAdd ? (I18Next('add', langType).COMMON_KEY) : (I18Next('edit', langType).COMMON_KEY)} ${I18Next('board', langType).COMMON_KEY}`}
                blockScroll={true}
                draggable={false}
                footer={this.footer}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                closable={false}
                style={{ width: "350px", height: "380px" }}
                onHide={() => this.setState({ showCreateDialog: false })}
                headerClassName="text-center"
            >
                <div className="grid mt-2">
                    <div className='col-12 grid'>
                        <div className="col-5">
                            <p className='text-sm'>{I18Next('boardName', langType).COMMON_KEY} <span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-7">
                            <InputTextB info={FIELDS_INFO.BOARD_NAME} id="topicn">
                                <InputText value={board.boardName} onChange={(e) => { this.onTextChange(e, 'boardName') }} />
                            </InputTextB>
                            {formValidations && !formValidations.fields['boardName'].isValid && <p className="p-error">{formValidations.fields['boardName'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className='col-12 grid'>
                        <div className="col-5">
                            <p className='text-sm' >{I18Next('boardCode', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-7">
                            <InputTextB info={FIELDS_INFO.BOARD_CODE} id="topicn">
                                <InputText value={board.boardCode} onChange={(e) => { this.onTextChange(e, 'boardCode') }} />
                            </InputTextB>
                            {formValidations && !formValidations.fields['boardCode'].isValid && <p className="p-error">{formValidations.fields['boardCode'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="col-12 grid">
                        <div className="col-5">
                            <p className='text-sm'>{I18Next('status', langType).COMMON_KEY}</p>
                        </div>
                        <div className="col-7">
                            <InputTextB info={FIELDS_INFO.BOARD_STATUS} id="topicn">
                                <InputSwitch checked={board.boardActivity} onChange={(e) => { this.onToggleChange(e, 'boardActivity') }} />
                            </InputTextB>
                        </div>
                    </div>
                </div>

            </Dialog >
        )
    }
}


const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,

});

export default connect(mapStatesToProps, {})(CreateBoard);
