import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import { RadioButton } from "primereact/radiobutton";
import './styles.scss'
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { MultiSelect } from 'primereact/multiselect';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { AddIconInButton, PlainCalenderIcon, EnvelopeIcon, MailIcon } from '../svgIcons';
import { BasicLazyParams, warningDailogInit } from '../../utile';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
const dateRangeOptions = [
    { value: 1, label: "Today" },
    { value: 2, label: "This Week" },
    { value: 3, label: "This Month" },
    { value: 4, label: "Academic Session" },
    { value: 5, label: "Custom" },
]

const checkStatusOptions = [
    { value: "Realised", label: "Realised" },
    { value: "Bounced", label: "Bounced" },
    { value: "Represented", label: "Represented" },
]

const searchOptions = [
    { value: 1, label: "Student Name" },
    { value: 2, label: "Enrollment Number" }
]
const records = [
    {
        enrollmentNo: 'ENR001',
        chequeNo: 'CHQ123456',
        bankName: 'Bank of America',
        chequeDate: '2024-01-15',
        depositDate: '2024-01-15',
        status: 'Represented'
    },
    {
        enrollmentNo: 'ENR002',
        chequeNo: 'CHQ123457',
        bankName: 'Wells Fargo',
        chequeDate: '2024-02-10',
        depositDate: '',
        status: 'Realised'
    },
    {
        enrollmentNo: 'ENR003',
        chequeNo: 'CHQ123458',
        bankName: 'Chase Bank',
        chequeDate: '2024-03-05',
        depositDate: '',
        status: 'Realised'
    },
    {
        enrollmentNo: 'ENR004',
        chequeNo: 'CHQ123459',
        bankName: 'Citibank',
        chequeDate: '2024-04-01',
        depositDate: '',
        status: 'Realised'
    },
    {
        enrollmentNo: 'ENR005',
        chequeNo: 'CHQ123460',
        bankName: 'HSBC',
        chequeDate: '2024-05-12',
        depositDate: '',
        status: 'Realised'
    }
];




const options = ['Fee Type Based', 'Term Based'];
class ChequeOrDDStatus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchType: 1,
            searchInput: "",
            chequeStatusData: records,
            datesRange: null,
            isLoading: false,
            isAssignBtnFeeCategory: true,
            isAssignSaveFeeCategory: false,
            isAssignViewFeeCategory: false,
            depositeDate: null,
            dateRangeType: 5,
            checkStatusType: 1,
            chequeFromDate: "",
            chequeToDate: "",
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCalenderDialogOpen: false,
            updateDateType: "",
            selectedProducts: null,
            updatedToRow: {},
            isCreateUser: false,
            isBranchView: false,
            isOpenDialogBoxToCreateGroup: false,
            groupName: "",
            branchName: "",
            groupHead: "",
            contactForGroup: "",
            descForGroup: "",
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,


        }

    }



    handleAssignFeeCatergory = () => {
        this.setState({ isAssignBtnFeeCategory: false, isAssignSaveFeeCategory: true, isAssignViewFeeCategory: false })
    }

    handleClickAssignFeeSave = () => {
        this.setState({ isAssignBtnFeeCategory: false, isAssignSaveFeeCategory: false, isAssignViewFeeCategory: true })
    }
    render() {
        return (
            <div className=" ml-4">



                <div className='grid m-2'>
                    <div className="col-3">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Cheque Status</p>
                        <div className='flex w-10'>
                            <Dropdown
                                className='fieldHeight w-full border-round-md shadow-2 pt-1 pl-2 body-medium'
                                options={checkStatusOptions}
                                value={this.state.checkStatusType}
                                onChange={(e) => { this.setState({ checkStatusType: e.value }) }}
                                // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center mt-1 -mt-1">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <span className='text-xl ml-4 body-medium'>Select Board</span>
                                    </div>
                                )} />
                        </div>
                    </div>
                    <div className="col-3">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Date Range Type</p>
                        <div className='flex w-10'>
                            <Dropdown
                                className='fieldHeight w-full border-round-md shadow-2 text-xl pt-1 pl-2 body-medium'
                                options={dateRangeOptions}
                                value={this.state.dateRangeType}
                                onChange={(e) => { this.setState({ dateRangeType: e.value }) }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center mt-1">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <span className='text-xl ml-4 body-medium'>Select Type</span>
                                    </div>
                                )} />
                        </div>
                    </div>
                    {this.state.dateRangeType == 5 &&
                        <>
                            <div className="col-3">
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>From Date</p>
                                <div className='flex w-10'>
                                    <Calendar
                                        className='fieldHeight w-full border-round-md shadow-2 text-lg body-medium'
                                        value={this.state.chequeFromDate}
                                        onChange={(e) => { this.setState({ chequeFromDate: e.value }) }}
                                        readOnlyInput
                                        showIcon
                                        placeholder="Select From Date"
                                        hideOnRangeSelection />
                                </div>
                            </div>
                            <div className="col-3">
                                <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>To Date</p>
                                <div className='flex w-10'>
                                    <Calendar
                                        className='fieldHeight w-full border-round-md shadow-2 text-lg body-medium'
                                        value={this.state.chequeToDate}
                                        onChange={(e) => { this.setState({ chequeToDate: e.value }) }}
                                        readOnlyInput
                                        showIcon
                                        placeholder="Select To Date"
                                        hideOnRangeSelection />
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className=" datatable-crud-demo mt-5 mx-4">

                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        value={this.state.chequeStatusData}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesText"
                        showGridlines
                        columnResizeMode="expand"
                        resizableColumns
                        // selection={this.state.selectedProducts} 
                        // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    // header={header}
                    >

                        <Column style={{ width: "15rem", height: "6rem" }} filterField="enrollmentNo" field="enrollmentNo" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Enrollment No" alignHeader={"center"} />
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="chequeNo" field="chequeNo" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Cheque No" alignHeader={"center"} />
                        <Column style={{ width: "17rem", height: "6rem" }} filterField="bankName" field="bankName" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Bank Name" alignHeader={"center"} />
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="chequeDate" field="chequeDate" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Cheque Date" alignHeader={"center"} />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Deposite Date" body={(rowData, { rowIndex }) => {
                            return (

                                rowData?.depositDate ?
                                <span>
                                    {rowData?.depositDate}
                                </span>
                                :
                                <div onClick={() => { this.setState({ isCalenderDialogOpen: true }) }}>
                                    <PlainCalenderIcon width={16} height={16} color={'black'} />
                                </div>

                            )
                        }} ></Column>
                        <Column style={{ width: "20rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Status" body={(rowData, { rowIndex }) => {
                            return (
                                <ClassetDropdown
                                optionLabel='label'
                                optionValue="value"
                                width={"175px"}
                                height={"48px"}
                                className='text-xl add-vehicle-field-input pl-2'
                                // value={rowData?.status}
                                options={checkStatusOptions}
                                // onChange={(e) => { onDropDownChange(e.target.value, 'driver', this, formFields, vehicle, formValidations, 'vehicle', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className='text-xl'>Status</span>
                                    </div>
                                )}
                            />
                            )
                        }} ></Column>


                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Actions" body={(rowData, { rowIndex }) => {
                            return (
                                <Button label="Save" className='tableSaveBtn' onClick={() => {
                                    this.setState({});
                                }} />
                            )
                        }} ></Column>


                    </DataTable>

                </div>
                <Dialog
                    blockScroll={true}
                    visible={this.state.isCalenderDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className='calenderBoxSize'
                    dismissableMask={false}
                    closable={false}
                    footer={() => (
                        <div className='flex justify-content-center mb-3'>
                            <Button label="Cancel" className='calenderCancelBtn mr-5 px-7' onClick={() => this.setState({ isCalenderDialogOpen: false })} />
                            <Button label='Apply' className='calenderApplyBtn   px-7' />
                        </div>
                    )}
                    onHide={() => {
                        this.setState({
                            isCalenderDialogOpen: false,
                        })
                    }}>
                    <div className="flex flex-column justify-content-center border-300 border-bottom-1 mt-1">
                        <Calendar
                            className="pb-5 body-medium"
                            value={this.state.depositeDate}
                            onChange={(e) => this.setState({ depositeDate: e.value })}
                            inline
                            showIcon={false}
                        />
                    </div>


                </Dialog>





            </div>
        )
    }
}





export default ChequeOrDDStatus