import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import SvgViewer from '../../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import './styles.scss'
// import UserInfo from './userInfo';
// import CreateUser from './createUser';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import IndividualUsageAnalytics from '../../analytics/usageAnalytics/individualUsageAnalytics';
import { AnalyticsIcon2, LocationMarker, WarningIcon } from '../../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../../internationalization';
import withRouter from '../../lib/withRouter';
import CreateRoute from './createRoute';

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];



const home = { icon: 'pi pi-home' }


class RouteManagement extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            users: [],
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            dataForAddRoutes: null,
            isShowCreateForm: false,
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],
            showingReports: false,
            series: [
                { name: 'Max marks', data: [44, 55, 57, 56, 61, 58] },
                { name: 'Sub.high score', data: [76, 85, 99, 98, 87, 105] },
                { name: 'Obtained marks', data: [35, 41, 36, 26, 45, 48] }
            ],
            options: {
                xaxis: {
                    categories: ['TELUGU', 'HINDI', 'ENGLISH', 'MATHS', 'SCIENCE', 'SOCIAL'],
                },
                yaxis: {
                    title: { text: 'Marks' }
                },
                chart: {
                    toolbar: {
                        show: false
                    }
                }
            },

        }

        this.service = new Service();
    }


    rightToolbarTemplate = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (
            <React.Fragment>
                {/* <h4 className='ma-tab-title'>User Management</h4> */}
                <h4 className='ma-tab-title'>{I18Next('VehicleManagement', langType).COMMON_KEY}</h4>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }


    componentDidMount() {

        this.getRoutesData()
    }

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }

    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }

    filterUsersData = () => {
        this.setState({
            isLoading: true,
            users: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        this.service.post(url, filterData, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    showUserInfo = (user) => {
        this.setState({
            selectedUser: user,
            isShowUserInfo: true
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.filterUsersData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }

        });
    }

    startGlobalFilter = () => {

        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isUserNameSearch, username, boardId, classId, groupId } = filterData;

            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }

            }



            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }


    onEditUserClick = (editUser) => {

        this.setState({
            editUser,
            isCreateUser: true
        });
    }

    showUserAnalytics = (selectedUser) => {

        this.setState({
            selectedUser,
            isShowAnalytics: true
        });
    }


    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value === true) {
            data.status = 'Active'
        } else {
            data.status = 'InActive'
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this User status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            // editUserData: data,
            editUser: data
        });

    }


    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        let user = this.state.editUser;

        this.setState({
            isLoading: true
        });

        const url = `${baseUrlAdmin}/user/changestatus?_id=${user._id}&status=${user.status}`;
        this.service.put(url, {}, true).then((res) => {
            if (res && res.status && res.res.success) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.filterUsersData();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }



    onCreateOrEditSuccess = () => {
        if (this.state.editUser) {

            this.setState({ isCreateUser: false, editUser: false }, () => {
                this.filterUsersData();
            })


        } else {
            this.setState({ isCreateUser: false, editUser: false })
        }


    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id === c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }


    editTemplate = (rowData) => {
        return (<>
            {/* <div className="p-d-flex ma-mlr10 user-edit-t"> */}
            <div className="">
                <Button label="Reset Password" className='p-button-outlined' onClick={() => { this.resetPassword(rowData) }} />
            </div>
            {/* </div> */}
        </>
        );
    }

    resetPassword = (data) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want reset the password, it can't be reversed once change the pasword`
            },
            // editUserData: data,
            editUser: data
        });

    }

    onConfirmReset = () => {



        this.setState({
            warningDialog2: warningDailogInit,
            isLoading: true
        });

        const url = `${baseUrlAdmin}/user/resetPassword?_id=${this.state.editUser._id}`;
        this.service.put(url, {}, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    isShowResetSuccess: true,
                    newPassword: res.res.password,
                    editUser: null
                })
                //   this.toast.show({ severity: 'success', summary: 'Success', detail: 'Password reset and sent to mail successfully.', life: 3000 });
            } else {
                this.setState({
                    isLoading: false,
                    editUser: null
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false,
                editUser: null
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }
    copyToClipboard = (txt) => {
        navigator.clipboard.writeText(txt);
        this.toast.show({ severity: 'success', summary: 'Copied', detail: 'Password copied to clipboard', life: 3000 });
    }

    reports = (rowData) => {
        this.setState({
            showingReports: true,
            reportsData: rowData
        })
    }


    onClickTm = () => {
        console.log("user-bulkupload")
        this.props.navigate('/user-bulkupload')
    }

    onCancel = () => {
        this.setState({
            // isShowCreateSuccess: false,
            // editUser: null,
            isShowCreateForm: false
        })
    }

    getRoutesData = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/transport-route/get-routes?academicYear=${academicYear}`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ users: res?.res?.data?.routeData, isLoading: false });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        }

    };

    addRoute = (rowData) => {

        this.setState({
            isShowCreateForm: true,
            dataForAddRoutes: rowData
        })
    }

    changeGpsStatus = async (e, rowData) => {
        this.setState({ isLoading: true })
        const payload = {
            "_id": rowData?.vehicleId
        }
        let url = `${baseUrlAdmin}/transport-vehicle/updategps`;
        try {
            const res = await this.service.put(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ isLoading: false })
                this.getRoutesData()
                this.toast.show({ severity: 'success', summary: 'Gps Update', detail: 'Gps Status Updated Successfully', life: 3000 });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        }
    }
    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>Route List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.USER_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.USER_VIEWCOLUMNS} >
                            <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.USER_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        const { selectedUser, isShowUserInfo, isShowAnalytics, editUser } = this.state
        return (
            <>
                {/* <div className="ask-a-doubt ma-main">
                            <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} />
                        </div> */}

                <div className="text-right lg:mt-4 md:mt-6 mb-2">

                    {/* <Authorizer permId={PERMISSIONS.USER_ADDNEWUSER} > */}
                    <Button
                        icon={'pi pi-plus-circle text-lg px-1 mt-1'}
                        className='add-vehicle-btn pl-4'
                        onClick={() => { this.setState({ isShowCreateForm: true, dataForAddRoutes: null }) }}>
                        Add Route
                    </Button>
                    {/* </Authorizer> */}
                </div>

                <div className="card datatable-crud-demo ma-m30  m-2">
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        className="dataTableValuesText"
                        columnResizeMode="expand"
                        value={this.state.users}
                        dataKey="id"
                        paginator
                        // lazy
                        responsiveLayout="scroll"
                        // showGridlines
                        // onPage={this.onPage}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={''}>

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="S.No." className="datatable-cell" body={(rowData, index) => {
                            return (
                                <>
                                    <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + index.rowIndex + 1}</p>
                                </>
                            )
                        }} />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="vehicleName" field="vehicleName" header="Vehicle Name" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="routeName" field="routeName" header="Route Name" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="totalStops" field="totalStops" header="Total Stops" />

                        {/* <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                            return (
                                <div className="">
                                    <InputSwitch onChange={(e) => { this.changeGpsStatus(e, rowData) }} checked={rowData.isGps} />
                                </div>
                            )
                        }} field="isGps" header="Gps" /> */}

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="startLocation" className='' field="startPoint" header="Start Location" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="endLocation" className='' field="endPoint" header="End Location" />
                        <Column className=' ' alignHeader={"center"} bodyClassName='text-center' header="Add Stops" body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex justify-content-center align-items-center">
                                    {/* <Authorizer permId={PERMISSIONS.USER_SHOWDETAILS} > */}
                                    <span style={{ cursor: 'pointer' }}
                                        onClick={() => this.addRoute(rowData)}
                                        data-pr-tooltip="Add Stops" data-pr-position="bottom" className={`yh${rowIndex}`} >
                                        <LocationMarker height={25} width={25} className='ma-icon-svg ma-pointer mr-1 ' />
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.yh${rowIndex}`} />
                                    {/* </Authorizer> */}
                                </div>
                            </>
                            );
                        }} ></Column>
                    </DataTable>
                </div>
                {/* {
                        isShowUserInfo && <UserInfo isShowUserInfo={isShowUserInfo} selectedUser={selectedUser} onHide={() => { this.setState({ isShowUserInfo: false }) }} />
                    } */}

                {
                    isShowAnalytics && <IndividualUsageAnalytics
                        userInfo={selectedUser}
                        onHide={() => { this.setState({ isShowAnalytics: false }) }} />
                }

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                <Dialog
                    header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={false}
                    visible={this.state.warningDialog.visible}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editUser: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editUser: null })}>
                    <div>
                        <span className="flex justify-content-center" >
                            <WarningIcon width={"30%"} height={"30%"} />
                        </span>
                    </div>
                    <p className='text-xl mt-2 text-900 font-bold flex justify-content-center'>{this.state.warningDialog.headerMsg}</p>
                    <p className='text-lg text-600 text-center font-bold white-space-normal'>{this.state.warningDialog.message}</p>
                </Dialog>

                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowCreateForm}
                    draggable={false}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={true}
                    style={{
                        width: "90%",
                        maxWidth: "1410px",
                        height: "auto",
                        maxHeight: "90%",
                        margin: "0 auto"
                    }}
                    header={() => {
                        return (

                            <h2 className='text-center text-5xl mb-4 font-bold'>{'Add'} Route</h2>
                        )
                    }}
                    onHide={() => {
                        this.setState({
                            isShowCreateForm: false,
                            newPassword: null
                        })
                    }}>
                    <div className=''>
                        <CreateRoute onCancel={this.onCancel} getRoutesData={this.getRoutesData} editData={this.state.dataForAddRoutes} dataForAddRoutes={this.state.dataForAddRoutes} toastMessage={this.toast} />
                    </div>
                </Dialog>



                {/* </Navbar> */}
            </>
        )
    }
}

export default connect(null, {

})(Authentication(withRouter(RouteManagement)));