import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';


const dispatchRecievedGetBoardsData = (data) => ({
    type: ActionTypes.REQUEST_BOARDS_DROPDOWN_DATA,
    data
})

// const dispatchCurrentYearUpdateAcademicYears = year => ({
//     type: ActionTypes.REQUEST_CURRENT_UPDATE_ACADEMIC_YEARS,
//     year
// });

const dispatchRecievedAcademicSessionsData= (data) => ({
    type: ActionTypes.REQUEST_ACADEMIC_SESSIONS_DATA,
   data
})



// export const getChangeCurrentAcademicYear = (year)=>dispatch=>{
//     dispatch(dispatchCurrentYearUpdateAcademicYears(year))
// }

export const getBoardsDropDowns = () => dispatch => {
    const AcademicYear = localStorage.getItem('userAcademicYear')
    axios({
        method: 'get',      
        url: `${baseUrlAdmin}/organization?academicYear=${AcademicYear}`,    
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }   
    }).then((res) => {
        // const etag = res.headers && res.headers.etag;
        // localStorage.setItem('etag', etag);
        const status = res && res.status;
        if(res.status == 200 && res.data?.Item){
            dispatch(dispatchRecievedGetBoardsData(res.data?.Item));
        }
        else{
              dispatch(dispatchRecievedGetBoardsData([]));
        }
      
    }).catch(e => {
        console.log(e)
    });
}

export const getAcademicSessionsDropDowns = () => dispatch => {
    axios({
        method: 'get',      
        url: `${baseUrlAdmin}/academic-years/`,    
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }   
    }).then((res) => {
        if(res.status == 200 && res.data?.status){
            dispatch(dispatchRecievedAcademicSessionsData(res.data?.data));
        }
        else{
            dispatch(dispatchRecievedAcademicSessionsData([]));
        }
        
    }).catch(e => {
        console.log(e)
    });
}