import React, { Component } from 'react'
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ClassetEditIcon, DeleteIcon } from '../../../svgIcons';
import { InputSwitch } from "primereact/inputswitch";
const ExamTemplates = [
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    }
]
export class ExamTemplate extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render() {
        return (
            <div className='px-5 py-3' >
                <DataTable ref={(el) => this.dt = el}
                    value={ExamTemplates}
                    dataKey="id"
                    paginator
                    rows={10}
                    // showGridlines
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    responsiveLayout="scroll"
                // header={header}

                >
                    <Column field="totalExams" header="Total Exams" className="datatable-cell" sortable />
                    <Column field="totalStudents" header="Total Students Appeared" className="datatable-cell" sortable />
                    <Column field="creationDate" header="Creation Date" className="datatable-cell" sortable />
                    <Column filed="status" header="Status" className="datatable-cell"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <InputSwitch />

                                </>
                            )
                        }}
                    ></Column>
                    <Column header="" body={(rowData, { rowIndex }) => {

                        return (
                            <>
                                <ClassetEditIcon width={32} height={32} className="mr-4" />
                                <DeleteIcon width={32} height={32} />
                            </>
                        )

                    }}>
                    </Column>



                </DataTable>
            </div>
        )
    }
}

export default ExamTemplate