import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../../session';
import { baseUrlAdmin, primaryColor } from '../../store/apiConstants';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';

import withRouter from '../lib/withRouter';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { CalendarIcon, ClassetEditIcon, MailIcon, PlainCalenderIcon, TickBgIcon, TickIcon, TickMarkIcon } from '../svgIcons';
import CustomCalendar from '../../classetComponents/classetCalender';
import CustomInputText from '../../classetComponents/classetInputText';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetCalendar from '../../classetComponents/classetCalender';
import ClassetTextArea from '../../classetComponents/classetTextArea';
import { range } from 'lodash';
import Service from '../../services';
import { Title } from 'chart.js';
import LoadingComponent from '../loadingComponent';


const academicSessions = [
    { id: 'One', title: 'Academic Session 1', year: '2023-2024', checked: 'academicYearOne' },
    { id: 'Two', title: 'Academic Session 2', year: '2022-2023', checked: 'academicYearTwo' },
    { id: 'Three', title: 'Academic Session 3', year: '2021-2022', checked: 'academicYearThree' },
    { id: 'Four', title: 'Academic Session 4', year: '2020-2021', checked: 'academicYearFour' },
    { id: 'Five', title: 'Academic Session 3', year: '2021-2022', checked: 'academicYearThree' },
    { id: 'six', title: 'Academic Session 4', year: '2020-2021', checked: 'academicYearFour' },
    { id: 'seven', title: 'Academic Session 3', year: '2021-2022', checked: 'academicYearThree' },
    { id: 'eight', title: 'Academic Session 4', year: '2020-2021', checked: 'academicYearFour' },
];


const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const tabs = [
    { label: 'Vehicle Management' },
    { label: 'Route Management' },
    { label: 'Assign Management' },
    { label: 'Transport Fee ' },
];


class AcademicSession extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            IsDailogBoxopenForYearChange: false,
            academicYearOne: false,
            academicYearTwo: false,
            academicYearThree: false,
            titleError: "",
            isLoading: false,
            rangeDateError: "",
            isDialogOpenForCreateAcademic: false,
            mode: "Create",
            academicYearsData: [],
            AcademicEditYear: {},
            title: '',
            rangeDate: [new Date(), new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)],
            description: '',


        };
        this.service = new Service();
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }





    getAPiAcademicYearsData = async () => {

        this.setState({ isLoading: true });

        let url = `${baseUrlAdmin}/academic-years/`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ academicYearsData: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "Error in Fetching Academic Years", life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "Network Error", life: 3000 });

        }

    };

    componentDidMount = () => {
        this.getAPiAcademicYearsData()

        console.log(this.props.year, "daff")
    }



    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Transport Management</h4>
            </React.Fragment>
        )
    }




    onChangeAcademicYear = (e, type) => {
        if (e.target.checked) {
            if (type == "One") {
                this.setState({ academicYearOne: true })
            }
            else if (type == "Two") {
                this.setState({ academicYearTwo: true })
            }
            else if (type == "Three") {
                this.setState({ academicYearThree: true })
            }

        }
        else {
            if (type == "One") {
                this.setState({ academicYearOne: false })
            }
            else if (type == "Two") {
                this.setState({ academicYearTwo: false })
            }
            else if (type == "Three") {
                this.setState({ academicYearThree: false })
            }

        }
        this.setState({ IsDailogBoxopenForYearChange: true })
    }

    // leftToolbarTemplate() {
    //     return (
    //         <React.Fragment>
    //             <BreadCrumb model={items} home={home} />
    //         </React.Fragment>
    //     )
    // }


    componentDidUpdate(prevProps, prevState) {
       
    }

    handleEditRequest = (session) => {
        this.setState({
            AcademicEditYear: session,
            isDialogOpenForCreateAcademic: true,
            title: session.academicCode,
            description: session?.description,
            rangeDate: [new Date(session.startDate), new Date(session.endDate)]
        });
    }

    onCreateAcademicYear = async () => {
        const { rangeDate, mode, AcademicEditYear, title, description } = this.state



        if (this.state.title == "" || this.state.rangeDate?.length == 0) {
            if (this.state.title == "") {
                this.setState({ titleError: "Title Is Required" })
            }
            if (this.state.rangeDate?.length == 0) {
                this.setState({ rangeDateError: "Academic Range Is Required" })
            }
        }
        else {
            if (!AcademicEditYear._id) {
                const payload = {

                    "startDate": rangeDate[0],
                    "endDate": rangeDate[1],
                    "academicCode": title,
                    "status": true,
                    "description": description,

                }
                let url = `${baseUrlAdmin}/academic-years/`;

                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({ isDialogOpenForCreateAcademic: false,AcademicEditYear:{}, rangeDate: [], title: "", description: "" })
                        this.getAPiAcademicYearsData()
                        this.toast.show({ severity: 'success', summary: 'success', detail: "Academic Year Created successfully", life: 3000 });
                    } else {
                        const error = 'Some error occurred';
                        this.toast.show({ severity: 'error', summary: 'error', detail: 'Error in Academic year create request', life: 3000 });

                    }
                } catch (e) {
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: "error", detail: 'Network Error', life: 3000 });
                }
            }
            else if (AcademicEditYear._id) {
                const payload = {
                    "_id": AcademicEditYear._id,
                    "startDate": rangeDate[0],
                    "endDate": rangeDate[1],
                    "academicCode": title,
                    "status": AcademicEditYear.status,
                    "description": description,

                }
                let url = `${baseUrlAdmin}/academic-years/`;
                console.log(payload, "payload edit")

                try {
                    const res = await this.service.put(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({ isDialogOpenForCreateAcademic: false, AcademicEditYear: {}, rangeDate: [], title: "", description: "" })
                        this.getAPiAcademicYearsData()
                        this.toast.show({ severity: 'success', summary: 'success', detail: "Academic Year Updated successfully", life: 3000 });
                    } else {

                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Error in Academic year update request', life: 3000 });

                    }
                } catch (e) {
                    console.log(e);
                    this.toast.show({ severity: 'error', summary: 'Error', detail: "Network Error", life: 3000 });
                }
            }

        }
    }


    render() {

        return (
            <div className='mt-2'>

                <div className="">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {/* {(this.state.isShowChapters || this.state.isShowTopics) && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        } */}
                    </div>
                    {/* <div className="-mt-3">
                        <Toolbar className="border-none" 
                         left={this.leftToolbarTemplate} 
                        right={this.rightToolbarTemplate}></Toolbar>
                    </div> */}
                </div>


                <div className="admin-management ml-5">
                    <div style={{ marginBottom: "110px" }}>

                        <div className=" grid h-4rem">
                            {/* {this.state.academicYearsData?.length ? */}
                            <div className="mt-3 md:col-12 lg:col-12 xl:col-12">
                                <div className="grid mb-6 mr-6">
                                    <div className='col-6 md:col-6 lg:col-12 xl:col-12 flex justify-content-end align-items-center'>
                                        <Button
                                            label="ADD"
                                            icon={'pi pi-plus-circle text-lg'}
                                            className='AddButton px-4 py-2'
                                            onClick={() => { this.setState({ isDialogOpenForCreateAcademic: true }) }}
                                        />
                                    </div>

                                </div>
                                {this.state.academicYearsData?.length ?
                                    <div className="grid doubts-tabs flex lg:justify-content-start xl:justify-content-start sm:justify-content-center align-items-center">
                                        {this.state?.academicYearsData.map((session, index) => (
                                            // <Authorizer key={session._id} permId={PERMISSIONS.SHOW_ROLES_LIST}>
                                            <div
                                                className="mx-6 card cardSize cursor-pointer col-12 md:col-6 lg:col-3 xl:col-2 doubts-tab-w"

                                            >
                                                <div className=" flex flex-column justify-content-center align-items-center">
                                                    <div className="mt-1 w-full flex justify-content-end align-items-center">
                                                        {/* <Checkbox
                                                                    className='mt-1 cardCheckBox'
                                                                    onChange={(e) => this.onChangeAcademicYear(e, session._id)}
                                                                    checked={this.state[`academicYear${session._id}`]}
                                                                /> */}
                                                    </div>
                                                    <div className="flex flex-column justify-content-center align-items-center mt-3 ml-3">
                                                        <h4 className="cardMainText mt-2">{session.academicCode}</h4>
                                                        <h5 className='cardPara'>{new Date(session.startDate).getFullYear()}-{new Date(session.endDate).getFullYear()}</h5>
                                                    </div>
                                                    <div className='-mt-1 mr-3 w-full flex justify-content-end align-items-center' onClick={() => { this.handleEditRequest(session) }}>
                                                        <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                        {/* <i
                                                            data-pr-tooltip="Search"
                                                            data-pr-position="bottom"
                                                            style={{ fontSize: "25px" }}
                                                            className="font-bold pi pi-pencil mt-1"
                                                            
                                                        ></i> */}
                                                    </div>
                                                </div>
                                            </div>
                                            // </Authorizer>
                                        ))}
                                    </div>
                                    :
                                    !this.state.isLoading &&
                                    <div className='mt-8 card h-20rem w-full  flex flex-column justify-content-center align-items-center'>
                                        <h3>No Academic Years Found</h3>
                                    </div>
                                   }

                            </div>
                        </div>
                    </div>


                </div>

                <Dialog
                    visible={this.state.isDialogOpenForCreateAcademic}
                    draggable={false}
                    closeOnEscape={false}
                    className=' w-5 mr-0'
                    style={{ width: "500px", height: "700px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-2'>
                            <h3 className="text-center font-serif font-bold text-800">{`${this.state.AcademicEditYear?._id ? "Update" :"Add"}`} Academic Session</h3>

                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center mb-4'>
                            <Button label='Accept' className='confirmDialogAcceptBtn px-7 mr-5' onClick={() => { this.onCreateAcademicYear() }} />
                            <Button style={{ color: "black", }} label="Cancel" className='confirmDialogCancelBtn px-7' onClick={() => this.setState({ isDialogOpenForCreateAcademic: false,AcademicEditYear:{}, title: "", date: "", description: "" })} />

                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForCreateAcademic: false,AcademicEditYear:{}, title: "", date: "", description: "" })}
                >
                    <div className='flex flex-column justify-content-center align-items-center mt-2'>
                        <div className="field mb-4">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                title="Title"
                                className='border-none text-lg pl-3 body-medium'
                                label={"Title"}
                                required={true}
                                tooltipInfo={<div>
                                    <p className='font-semibold text-sm'>Enter the Title of Academic Session</p>
                                    <p className="-mt-1">Title will be displayed throughout</p>
                                </div>}
                                value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })}
                                placeholder="Title" />
                            {this.state.title == "" && <p className='p-error font-semibold'>{this.state.titleError}</p>}
                        </div>
                        <div className="field mb-5 my-4">
                            <ClassetCalendar
                                icon={<PlainCalenderIcon width={24} height={24} color={'#808080'} />}
                                className='text-lg font-semibold text-color border-calendar'
                                width={"343px"}
                                title={"academicDate"}
                                inputClassName="text-lg font-semibold "
                                label={"Date"}
                                selectionMode={"range"}
                                readOnlyInput
                                required={true}
                                height={"48px"}
                                value={this.state.rangeDate}
                                tooltipInfo={
                                    <div>
                                        <p className='font-semibold text-sm'>Pick From and To Dates</p>
                                        <p className="-mt-1">Enter the start and end dates for the said academic session</p>
                                    </div>
                                }
                                onChange={(e) => this.setState({ rangeDate: e.value })}
                                placeholder="Select a date"
                            />
                            {this.state.rangeDate?.length == 0 && <p className='p-error font-semibold'>{this.state.rangeDateError}</p>}
                        </div>

                        <div className="field mt-4">
                            <ClassetTextArea
                                label="Description"
                                title="description"
                                width="356px"
                                height="119px"
                                labelClassName="mb-2"
                                className='text-lg'
                                tooltipInfo={
                                    <div>
                                        <p className='font-semibold text-sm'>Description</p>
                                        <p className="-mt-1">Write a brief to understand this session for others</p>
                                    </div>
                                }
                                value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })}
                                placeholder="Write A Description" rows={3} />
                        </div>
                    </div>
                </Dialog >

                <Dialog
                    blockScroll={true}
                    visible={this.state.IsDailogBoxopenForYearChange}
                    draggable={false}
                    closeOnEscape={false}
                    // className='confirmDialogSize'
                    style={{ width: "400px", height: "350px" }}
                    dismissableMask={false}
                    closable={false}
                    onHide={() => {
                        this.setState({ IsDailogBoxopenForYearChange: false });
                    }}
                    footer={() => {
                        return (
                            <div className='flex justify-content-center'>
                                <Button label="Accept" className='confirmDialogAcceptBtn mr-4  px-6' onClick={() => { }} />
                                <Button style={{ color: "black" }} label="Cancel" className='confirmDialogCancelBtn px-6' onClick={() => {
                                    this.setState({ IsDailogBoxopenForYearChange: false });
                                }} />

                            </div>
                        );
                    }}
                >
                    <div className='text-center'>
                        <TickBgIcon width={32} height={32} color="#268900" />
                        <h4 className="confirmDialogHeader">Confirm Academic Session</h4>
                        <p className="confirmDialogPara">
                            Confirming this Academic session will automatically deactivate the previously active AY
                        </p>
                    </div>
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {(this.state.isLoading) && <LoadingComponent />}

            </div >
        )
    }
}


const mapStateToProps = (state) => ({
    // // boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    // currentAcademicYear:state.academicYearsStateData.currentAcademicYear || "",
    // // isLoading: state.academicYearsStateData.isLoading,
    // // isUpdated: state.academicYearsStateData.isUpdated,
    academicYearsData: state,
    year: state
    // // updateError: state.academicYearsStateData.error,
    // // updateErrorMessage: state.academicYearsStateData.errorMessage,
});


export default connect(mapStateToProps, {
    // s,
    // updateAcademicYearsData,
    // updateCurrentAcademicYear
})(Authentication(withRouter(AcademicSession)));