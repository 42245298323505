import React, { Component } from 'react'
import { connect } from 'react-redux';

//import { withRouter } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid, onTextChange, isFieldValid, onNumberChange, onEmailChange, onDropDownChange } from '../../../utile/formHelper';
import _, { cloneDeep } from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import InputTextB from '../../customComponents/inputTextB';
import Service from './../../services';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import {

    getBoardsData,
    getBranchesLatest
} from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
import LoadingComponent from '../../loadingComponent';
import { FIELDS_INFO } from '../../../constants';
import { I18Next } from '../../../internationalization';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';
import { DeleteIconClasset, LocationMarker, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';

const createRouteFields = require('./createRoute.json');

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Add New User', className: 'ma-active-item' }
];


const home = { icon: 'pi pi-home' }


let formFields = createRouteFields;


const processEditUser = (editUser) => {

    editUser = _.cloneDeep(editUser);
    editUser.gradeId = editUser.classId;
    editUser.phone_number = editUser.phone_number.substring(3);

    editUser.grade = editUser['custom:grade'];
    editUser.group = editUser['custom:group'];
    editUser.board = editUser['custom:board'];
    editUser.board = editUser['custom:board'];
    return editUser;
}




class CreateRoute extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createRouteFields);
        this.state = {
            route: this.props?.editData?.id ? this.props?.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            stopsList: [],
            focused: false,
            inputFocused: false,
            boards: [],
            classes: [],
            academicYears: [],
            vehicleDropdowns: [],
            feeTypeDropDown: [],
            stops: [{ id: 1, name: '', lat: '', lng: '', pickUpTime: '', dropTime: '', transportFee: '' }],
            sections: [{ value: '21759c65-7c60-4745-bdbc-bcb47a65cf9c', label: 'Testing Section' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf11', label: 'Testing Section 1' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf22', label: 'Testing Section 2' },
            { value: '21759c65-7c60-4745-bdbc-bcb47a65cf33', label: 'Testing Section 3' }],
            isShowCreateSuccess: false,
            hideUpdateButton: false,
            sectionsData: {},

        }

        this.service = new Service();
    }

    componentDidMount() {

        this.getDropdownsData()

        if (this.props.dataForAddRoutes?._id) {
            this.setState({
                route: {
                    ...this.state.route,
                    vehicleId: this.props.dataForAddRoutes.vehicleId,
                    routeName: this.props.dataForAddRoutes.routeName,
                    registration_number: this.props.dataForAddRoutes.vehicleId,
                }
            });
        }
    }

    getDropdownsData = async () => {
        const academicYear = localStorage.getItem("userAcademicYear");
       
           let url = `${baseUrlAdmin}/transport-route/meta-data?academicYear=${academicYear}`

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const vehicles = res?.res?.data?.vehicles?.map(vehicles => ({
                    label: vehicles.vehicleName,
                    value: vehicles._id,
                    registrationNumber: vehicles.vehicleRegistrationNo
                }));
                const fee = res?.res?.data?.feePlans?.map(fee => ({
                    label: fee.feeName,
                    value: fee._id
                }));
                this.setState({ vehicleDropdowns: vehicles, feeTypeDropDown: fee });
            } else {
                this.props?.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.props?.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'some Error', life: 3000 });
        }

    };





    onCreateRoute = async () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        const fieldData = cloneDeep(this.state.route)
        if (this.props?.dataForAddRoutes?._id) {
            const stopData = this.state?.stops || []
            const stopsForApi = stopData?.map(stop => ({
                stopName: stop.name,
                pickuptime: stop.pickUpTime,
                droptime: stop.dropTime,
                transportFee: Number(stop.transportFee)
            }));
            const payloadData = {
                "routeId": this.props?.dataForAddRoutes?._id,
                "stop": stopsForApi || []
            }
            let url = `${baseUrlAdmin}/transport-route/stops/add`
            try {
                const res = await this.service.post(url, payloadData, true);
                if (res?.res?.status && res.status) {

                    this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                    this.props.onCancel()
                    this.props.getRoutesData()
                } else {
                    this.props.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                }
            } catch (e) {
                console.log(e);
                this.props.toastMessage.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
            }
        } else {
            let body = cloneDeep(this.state.route)
            const stopData = this.state?.stops || []
            const stopsForApi = stopData?.map(stop => ({
                stopName: stop.name,
                pickuptime: stop.pickUpTime,
                droptime: stop.dropTime,
                lat: stop.lat,
                lng: stop.lng,
                transportFee: Number(stop.transportFee)
            }));
            const formStatus = isFormValid(createRouteFields, this.formFields.formValidations, body);

            if (!formStatus.formValidations.isFormValid) {
                this.setState({ formValidations: formStatus.formValidations, });
            }
            else {
                const payload = {
                    "vehicleId": body?.vehicleId,
                    "routeName": body?.routeName,
                    "stops": stopsForApi || []
                }

                let url = `${baseUrlAdmin}/transport-route?academicYear=${academicYear}`
                try {
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {

                        this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel()
                        this.props.getRoutesData()
                    } else {
                        this.props.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });

                    }
                } catch (e) {
                    console.log(e);
                    this.props.toastMessage.show({ severity: 'error', summary: e, detail: 'Some error occured', life: 3000 });
                }
            }
        }
    }


    addStop = () => {
        const newStop = { id: this.state.stops.length + 1, name: '', lat: '', lng: '', pickUpTime: '', dropTime: '', transportFee: '' };
        this.setState(prevState => ({
            stops: [...prevState.stops, newStop]
        }));
    }

    removeStop = (index) => {
        if (this.state.stops.length > 1) {
            const stops = this.state.stops.filter((_, i) => i !== index);
            this.setState({ stops });
        }
    }

    handleInputChange = (index, field, value) => {
        const stops = [...this.state.stops];
        if (field === 'pickUpTime' || field === 'dropTime') {
            if (value instanceof Date && !isNaN(value)) {
                const hours = value.getHours().toString().padStart(2, '0');
                const minutes = value.getMinutes().toString().padStart(2, '0');
                value = `${hours}:${minutes}`;
            }
        }
        stops[index][field] = value;
        this.setState({ stops });
    };
    handleFocus = () => {
        this.setState({ focused: true })
    };

    handleBlur = () => {
        this.setState({ focused: false })
    }

    handleInputFocus = () => {
        this.setState({ inputFocused: true })
    };

    handleInputBlur = () => {
        this.setState({ inputFocused: false })
    }


    render() {
        const { route, formValidations, sectionsData, focused, inputFocused } = this.state;
        const { defaultLanguage, onCancel } = this.props
        const langType = defaultLanguage
        const isDisabled = true
        return (<>


            <div className=' mt-3'>
                <div className="grid  lg:col-12  px-2 ml-1" >
                    <div className="lg:col-4 md:col-8 md:item-center  md:ml-8 lg:ml-0" >
                        <label htmlFor={'title'} className={`ma-label-s2 font-serif font-semibold text-900 text-lg `}>{'Vehicle Name'}
                            {<span className="ma-required">*</span>}
                        </label>
                        <div className='flex'>
                            <div
                                className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`, { 'custom-input-focused': focused })}
                                onClick={this.handleFocus}
                            >
                                <span className={"p-inputgroup-addon pl-3 "}>
                                    <MailIcon width={24} height={24} color={'#667A85'} />
                                </span>
                                <Dropdown onBlur={this.handleBlur} className=' h-3rem w-full h-3rem no-border pt-1 ' placeholder='Select Vehicle Name' value={route.vehicleId}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    options={this.state.vehicleDropdowns} onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        onDropDownChange(selectedValue, 'vehicleId', this, formFields, route, formValidations, 'route', 'formValidations');
                                        const selectedVehicle = this.state.vehicleDropdowns.find(vehicle => vehicle.value === selectedValue);
                                        if (selectedVehicle) {
                                            route['registration_number'] = selectedVehicle.registrationNumber;
                                            this.setState({
                                                route: { ...route, vehicleId: selectedValue },
                                            });
                                        }
                                    }} />
                            </div>
                        </div>
                        {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['vehicleId'].isValid && <p className="p-error">{formValidations.fields['vehicleId'].errorMsg}</p>}
                    </div>
                    <div className="lg:col-4 md:col-8 md:item-center  md:ml-8 lg:ml-0">
                        <label htmlFor={"title"} className={`ma-label-s2 font-serif font-semibold text-900 text-lg `}>{"Route Name"}
                            {<span className="ma-required">*</span>}
                        </label>
                        <div className="flex">
                            <div
                                className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`, { 'custom-input-focused': inputFocused })}
                            >
                                <span className=" p-inputgroup-addon pl-3">
                                    <MailIcon width={24} height={24} color={"black"} />
                                </span>
                                <InputText onClick={this.handleInputFocus} onBlur={this.handleInputBlur} style={{ border: "none" }} disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    className='  h-3rem w-full'
                                    value={route.routeName}
                                    onChange={(e) => onTextChange(e.target.value, 'routeName', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Route Name" />
                            </div>
                        </div>
                        {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['routeName'].isValid && <p className="p-error">{formValidations.fields['routeName'].errorMsg}</p>}
                    </div>
                    <div className="lg:col-4 md:col-8 md:item-center  md:ml-8 lg:ml-0 ">
                        <label htmlFor={"title"} className={`ma-label-s2 font-serif font-semibold text-900 text-lg `}>{"Registration Number"}
                            {<span className="ma-required">*</span>}
                        </label>
                        <div className="flex">
                            <div
                            style={{backgroundColor:'#E6E8EA'}}
                                className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`)}
                            >
                                <span className=" p-inputgroup-addon pl-3">
                                    <MailIcon width={24} height={24} color={"black"} />
                                </span>
                                <InputText style={{ border: 'none',backgroundColor:'#E6E8EA' }} icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' border-round-md add-vehicle-field-input h-3rem  w-full'
                                    value={this.props?.dataForAddRoutes?._id ? this.props?.dataForAddRoutes?.vehicleRegistrationNo : route.registration_number}
                                    disabled={true}
                                    onChange={(e) => onTextChange(e.target.value, 'registration_number', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Registered Vehicle Number" />
                            </div>
                        </div>
                        {/* {formValidations && !formValidations.fields['registration_number'].isValid && <p className="p-error">{formValidations.fields['registration_number'].errorMsg}</p>} */}
                    </div>
                </div>
                <div style={{ height: "2px", backgroundColor: '#B1B1B1' }}></div>
                <div className="mt-6" >
                    {this.state.stops.map((stop, index) => (
                        <div className='flex '>
                            <div className='flex items-center' >
                                <Button style={{ width: "45px", height: "45px", color: '#076EFF' }} label={index + 1} className='border-circle bg-white text-2xl -mt-1' />
                                <span className='mx-2'><LocationMarker height={35} width={25} /></span>
                            </div>
                            <div className=" w-full grid gap-0  md:ml-5 md:gap-3 lg:gap-0 lg:ml-0 md:mb-5" key={index} >
                                <div className="lg:col-2">
                                    <div
                                        className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2` )}
                                    >
                                        <InputText className='w-full ' style={{ border: 'none', height: '37px' }}
                                            value={stop.name}
                                            onChange={(e) => this.handleInputChange(index, 'name', e.target.value)}
                                            placeholder="Stop Name" />
                                    </div>
                                </div>
                                <div className='lg:col-2' >
                                    <div
                                        className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`)}
                                    >
                                        <InputText
                                            className='w-full  h-rem'
                                            style={{ border: 'none', height: '37px' }}
                                            value={stop.lat}
                                            onChange={(e) => this.handleInputChange(index, 'lat', e.target.value)}
                                            placeholder="Latitude" />
                                    </div>
                                </div>
                                <div className='lg:col-2'>
                                    <div
                                        className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`)}
                                    >
                                        <InputText
                                            style={{ border: 'none', height: '37px' }}
                                            className='w-full  h-rem'
                                            value={stop.lng}
                                            onChange={(e) => this.handleInputChange(index, 'lng', e.target.value)}
                                            placeholder="Longitude" />
                                    </div>
                                </div>
                                <div className='lg:col-2'>
                                    <div
                                        className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`)}
                                    >
                                        <Calendar
                                            value={stop.pickUpTime ? new Date(`1970-01-01T${stop.pickUpTime}:00`) : null}
                                            className='w-full h-rem '
                                            style={{ height: '37px', border: 'none' }}
                                            inputStyle={{ boxShadow: 'none', border: 'none' }}
                                            onChange={(e) => this.handleInputChange(index, 'pickUpTime', e.value)}
                                            placeholder="Pick Up Time"
                                            timeOnly
                                        />
                                    </div>
                                </div>
                                <div className='lg:col-2'>
                                    <div
                                        className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`)}
                                    >
                                        <Calendar
                                            value={stop.dropTime ? new Date(`1970-01-01T${stop.dropTime}:00`) : null}
                                            inputStyle={{ boxShadow: 'none', border: 'none' }}
                                            className='w-full '
                                            style={{ height: '37px' }}
                                            onChange={(e) => this.handleInputChange(index, 'dropTime', e.value)}
                                            placeholder="Drop Time"
                                            timeOnly />
                                    </div>
                                </div>
                                <div className='lg:col-2'>
                                    <div
                                        className={classNames('p-inputgroup', 'custom-input-group', `border-300  border-round-lg flex justify-content-center align-items-center  border-2`)}
                                    >
                                        <InputText
                                            style={{ border: 'none', height: '37px' }}
                                            className='w-full  h-rem'
                                            value={stop.transportFee}
                                            onChange={(e) => this.handleInputChange(index, 'transportFee', e.target.value)}
                                            placeholder="Amount" />
                                    </div>
                                </div>
                            </div>
                            <div className=' items-center md:mt-5  lg:mt-2  ml-2 pt-1' onClick={() => this.removeStop(index)}>
                                <DeleteIconClasset height={"22"} />
                            </div>
                        </div>

                    ))}

                    <Button
                        label="Add Stop"
                        icon={'pi pi-plus-circle text-black text-lg mt-1'}
                        style={{ borderRadius: 12, backgroundColor: '#F4F5F6', border: 'none', color: 'black' }}
                        className=' p-3 font-semibold ml-4'
                        onClick={this.addStop}
                    />



                </div>

                <div className="text-center mt-8">
                    <Button label={this.props.editUser ? 'Update Vehicle' : 'Add Vehicle'} className='confirmDialogAcceptBtn px-6 mr-5 w-19rem' onClick={this.onCreateRoute} />
                    <Button label="Cancel" className='confirmDialogCancelBtn' onClick={onCancel} />
                </div>
            </div >



            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                (this.state.isLoading || this.props.isBranchLoading || this.props.isLoading) && <>
                    <LoadingComponent />
                </>
            }
        </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})((withRouter(CreateRoute)));
