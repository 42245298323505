import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import CreateAcademicYear from './createAcademicYear';
import LoadingComponent from '../../loadingComponent';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import SvgViewer from '../../customComponents/svgViewer';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import '../styles.scss'

const items = [
    { label: 'Organization' },
    { label: 'Academic Years', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

export default class AcademicYears extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateAcadYear: false,
            isLoading: false,
            academicYearsData: [],
            globalFilter: null,
            showSearch: false,
            columns: [{ columnName: '', isVisible: false, id: '' }]
        }
        this.service = new Service()
    }

    componentDidMount() {
        this.getAcademicYears()
    }

    getAcademicYears = () => {

        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/academic-years`;
        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {

                this.setState({
                    isLoading: false,
                    academicYearsData: res?.res?.data
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            console.log(err, 'err')
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Academic Years</h4>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    showEditRoleDialog = (editData) => {

        this.setState({
            editData,
            showCreateAcadYear: true,
        });
    }

    editTemplate = (rowData, { rowIndex }) => {
        return (<>
            <div className="p-d-flex ma-mlr10 ">
                <span data-pr-tooltip="Edit Role" data-pr-position="bottom" className={`ma-ml10 ar${rowIndex}`} onClick={() => this.showEditRoleDialog(rowData)} >
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                </span>
            </div>
        </>
        );
    }

    onClose = () => {
        this.setState({
            showCreateAcadYear: false, editData: null
        })
    }

    onSaveOrUpdate = (isUpdate) => {
        this.toast.show({ severity: 'success', summary: 'Saved', detail: `Admission Type ${isUpdate ? 'updated' : 'created'} successfuly`, life: 3000 });
        this.setState({
            showCreateAcadYear: false, editData: null
        }, this.getAcademicYears)
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    render() {

        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4>Academic Years</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}

                </div>

                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <i data-pr-tooltip="View Columns"
                            onClick={(event) => this.menu.toggle(event)}
                            data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" /> */}
                        <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4>View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (<>
            <div>
                <div className="-mt-4">
                    <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                </div>
            </div>
            <div className='ma-main'>
                <Button label="+ Add Academic Year" className='p-button-raised my-3' onClick={() => this.setState({ showCreateAcadYear: true })} />

                <div className='card datatable-crud-demo ma-m30'>
                    <DataTable ref={(el) => this.dt = el}

                        value={this.state.academicYearsData}
                        dataKey="id"
                        paginator
                        rows={10}
                        showGridlines
                        responsiveLayout="scroll"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={header}
                    >
                        <Column field="academicCode" header="Academic Code" />
                        <Column field="startDate" header="Start Date" body={(rowData) => {
                            return (
                                <>
                                    <p>{moment(rowData.startDate).format("DD-MM-YYYY")}</p>
                                </>
                            )
                        }} />
                        <Column field="endDate" header="End Date" body={(rowData) => {
                            return (
                                <>
                                    <p>{moment(rowData.endDate).format("DD-MM-YYYY")}</p>
                                </>
                            )
                        }} />
                        <Column header="Actions" body={this.editTemplate} ></Column>

                    </DataTable>
                    {this.state.showCreateAcadYear &&
                        <CreateAcademicYear
                            editData={this.state.editData}
                            academicYearsData={this.state.academicYearsData}
                            onClose={this.onClose}
                            onSaveOrUpdate={this.onSaveOrUpdate}

                        />
                    }
                </div>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            {this.state.isLoading && <LoadingComponent />}
        </>

        )
    }
}
