import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Component } from "react";
import { getFormFields, onDropDownChange, onTextChange } from "../../../utile/formHelper";

import './styles.scss';
import ClassetInputText from "../../../classetComponents/classetInputText";
import { CodeLinkIcon, MailIcon } from "../../svgIcons";
import ClassetTextArea from "../../../classetComponents/classetTextArea";
import Service from "../../../services";
import ClassetDropdown from "../../../classetComponents/classetDropDown";

const TeacherDairyFields = require('./teacherDairyFormat.json');


const fieldTypeoptions = [{ label: 'Numeric', value: 'Numeric' },
{ label: 'Alphanumeric', value: 'alphanumeric' },
{ label: 'Dates', value: 'dates' }
]

class TeacherDairyFormat extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(TeacherDairyFields);
        console.log(this.formFields.data,"dtatttttt")
        this.state = {
            teacherDairyData:this.formFields.data,
            formValidations: this.formFields.formValidations,
            teacher: [{ id: 1, title: '', description: '' }],
        }

        this.service = new Service();
    }

    addTeacher = () => {
        const newTeacher = { id: this.state.teacher.length + 1, title: '', description: '' };
        this.setState(prevState => ({
            teacher: [...prevState.teacher, newTeacher]
        }));
    }


    render() {
        const { formValidations,teacherDairyData } = this.state
        console.log(teacherDairyData,"teacherDairyData")
        return (
            <>
                <div className="p-5">
                    <div className="grid">
                        <div className="col-3 mr-2">
                            <label className='label'>Teacher Dairy Title<span className='ma-required'>*</span></label>
                            <div className='mt-2'>
                                <ClassetInputText
                                    icon={<MailIcon height={24} />}
                                    id="title"
                                    className='border-round-md   border-none '
                                    // value={bank.accNo}
                                    // onChange={(e) => { onTextChange(e.target.value, 'title', this, TeacherDairyFields, bank, formValidations, 'bank', 'formValidations') }}
                                    placeholder="Teacher Dairy Title" />
                            </div>
                            {/* {formValidations && !formValidations.fields['accNo'].isValid && <p className="p-error text-lg ">{formValidations.fields['accNo'].errorMsg}</p>} */}

                        </div>
                        <div className="col-6 ">
                            <label className='label'>Description<span className='ma-required'>*</span></label>
                            <div className='mt-2'>
                                <ClassetTextArea
                                    id="branchName"
                                    className='border-round-md   border-none '
                                    height={50}
                                    // value={bank.branchName}
                                    // onChange={(e) => { onTextChange(e.target.value, 'branchName', this, createBankFields, bank, formValidations, 'bank', 'formValidations') }}
                                    placeholder="Description" />

                            </div>
                            {/* {formValidations && !formValidations.fields['branchName'].isValid && <p className="p-error text-lg ">{formValidations.fields['branchName'].errorMsg}</p>} */}

                        </div>


                    </div>
                    <div className="">
                        <div className="flex justify-content-between ">
                            <h3 className="teacherDairyHead">Teacher Dairy Format</h3>

                            <Button className="addButton">
                                <p >Save Dairy Format</p>
                            </Button>
                        </div>

                    </div>
                    {this.state.teacher?.map((boards, index) => (
                        <div className="grid" key={index}>
                            <div className="col-3">
                                <label className='label'>Column {index + 1} Title<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetInputText
                                        icon={<MailIcon height={24} />}
                                        id="title"
                                        className='border-round-md   border-none '
                                        value={teacherDairyData?.title}
                                        onChange={(e) => {
                                        onTextChange(e.target.value, 'title', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations') }}
                                        placeholder={`Title of Column ${index + 1}`} />
                                </div>
                                {formValidations && !formValidations.fields['title']?.isValid && <p className="p-error text-lg ">{formValidations.fields['title']?.errorMsg}</p>}

                            </div>
                            <div className="col-3">
                                <label className='label'>Field Type<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetDropdown
                                        icon={<MailIcon height={24} />}
                                        id="fieldType"
                                        className='border-round-md   border-none '
                                        value={teacherDairyData?.fieldType}
                                        options={fieldTypeoptions}
                                        onChange={(e) => 
                                            {onDropDownChange(e.target.value, 'fieldType', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations') }}
                                        placeholder={
                                            <div>
                                                <span>
                                                    Field Type
                                                </span>
                                            </div>} />
                                </div>
                                {formValidations && !formValidations.fields['fieldType']?.isValid && <p className="p-error text-lg ">{formValidations.fields['fieldType']?.errorMsg}</p>}

                            </div>
                            <div className="col-3 ">
                                <label className='label'>Description<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetTextArea
                                        id="description"
                                        className='border-round-md   border-none '
                                        height={50}
                                        value={teacherDairyData?.description}
                                         onChange={(e) => { onTextChange(e.target.value, 'description', this, TeacherDairyFields, teacherDairyData, formValidations, 'teacherDairyData', 'formValidations') }}
                                        placeholder="Description" />

                                </div>
                                {formValidations && !formValidations.fields['description']?.isValid && <p className="p-error text-lg ">{formValidations.fields['description']?.errorMsg}</p>}

                            </div>
                        </div>

                    ))}
                    <Button style={{ borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', }} onClick={this.addTeacher} className='addColumnButton ml-4' 
                    // disabled={! teacherDairyData?.evary(each=>(each.title && each.fieldType && each.description))}
                    
                    >
                        <p >Add Column</p>
                    </Button>
                </div>

            </>
        )
    }

}
export default TeacherDairyFormat