import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
// import { Login } from './pages/Login';

import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import Login from './components/login/index';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgData, getOrgDataByCode } from './store/actions';
import Service from './components/services';
import { baseUrlAdmin } from './store/apiConstants';
import withRouter from './components/lib/withRouter';
import Authentication from './session'

let timer1
const AppWrapper = () => {
  let location = useLocation();
  const dispatch = useDispatch()
  const assignedModules = useSelector((state) => state.orgData.assignedModules)

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOrgData())
  }, [location]);

  useEffect(() => {
    //document.documentElement.setAttribute('data-theme', 'pink');
    //  document.documentElement.setAttribute('data-theme', 'green');
    //document.documentElement.setAttribute('data-theme', 'yellow');
    // localStorage.setItem('super_admin', 'eyJraWQiOiJrbmpETGpcL04yT3k5M0w3Z3hVUVlJaEVjNk9UYXhrMlpFMU5LTmF6SGxoMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0Q1YVRyaVl1UCIsImNvZ25pdG86dXNlcm5hbWUiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJvcmlnaW5fanRpIjoiZTAyNmFhZTUtOGNjNi00Y2NmLWJhM2UtMmI5OGZlZGY4Nzc2IiwiYXVkIjoiM3NtMWVsdXRiOW9vaWhiYmZyNXZ1ZGIzMm4iLCJldmVudF9pZCI6IjljNGFmNTYyLWFmZDItNGJlYi04Mjc3LTg4MmE4NzhkMDEwYiIsImN1c3RvbTpib2FyZElkIjoiICIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjQyMTcyMjY5LCJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIrOTE5OTk5OTk5OTk5IiwiY3VzdG9tOm9yZ0lkIjoiNjFhMGQ4ODcxZWMxOGFhNWYxOGFkODA2IiwiZXhwIjoxNjQyMTc1ODY5LCJjdXN0b206cm9sZSI6IjYxY2FlNWY2MWNmOWRiNzcyY2FhODZhZiIsImlhdCI6MTY0MjE3MjI2OSwianRpIjoiMDc0MjE3NTktMGIwOC00MmY1LWJmMmEtYTc3ZTQwMzdiODIyIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImN1c3RvbTppc0FkbWluIjoidHJ1ZSJ9.CfqhAyPE0V1wcaPwo4RT1aWikDrABBetbsGGWYAizWAUeYVZnlGL7NtQt4mfd5QpisZUPDFSFAQSljqzyhc_3VmygHOmf1jYkfQ79yRkCBBsVOxCMz6Ff-vZgsUxoulBUUFEWweiAIjTxghLA81WGFMCf8sBuM-HtH2LHqkgnSFHK_7UIy0c26IXf0IvxegrBdWJOhhKeuQyXZ2Ah2d2WuKNrne2Pb3wwnt1eJX1v3XYE3kC20hqRNgxVoZ63gsKNPCeW73l1Odnibj4NeQrBtLAxxpikAnVpJaLZrNslvOgtOEtMJiXe1wNXXa6kxpE7DXb-Z9gWYT-v-sHVTBClA')
    // localStorage.setItem('Bearer', 'eyJraWQiOiJrbmpETGpcL04yT3k5M0w3Z3hVUVlJaEVjNk9UYXhrMlpFMU5LTmF6SGxoMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0Q1YVRyaVl1UCIsImNvZ25pdG86dXNlcm5hbWUiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJvcmlnaW5fanRpIjoiZTAyNmFhZTUtOGNjNi00Y2NmLWJhM2UtMmI5OGZlZGY4Nzc2IiwiYXVkIjoiM3NtMWVsdXRiOW9vaWhiYmZyNXZ1ZGIzMm4iLCJldmVudF9pZCI6IjljNGFmNTYyLWFmZDItNGJlYi04Mjc3LTg4MmE4NzhkMDEwYiIsImN1c3RvbTpib2FyZElkIjoiICIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjQyMTcyMjY5LCJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIrOTE5OTk5OTk5OTk5IiwiY3VzdG9tOm9yZ0lkIjoiNjFhMGQ4ODcxZWMxOGFhNWYxOGFkODA2IiwiZXhwIjoxNjQyMTc1ODY5LCJjdXN0b206cm9sZSI6IjYxY2FlNWY2MWNmOWRiNzcyY2FhODZhZiIsImlhdCI6MTY0MjE3MjI2OSwianRpIjoiMDc0MjE3NTktMGIwOC00MmY1LWJmMmEtYTc3ZTQwMzdiODIyIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImN1c3RvbTppc0FkbWluIjoidHJ1ZSJ9.CfqhAyPE0V1wcaPwo4RT1aWikDrABBetbsGGWYAizWAUeYVZnlGL7NtQt4mfd5QpisZUPDFSFAQSljqzyhc_3VmygHOmf1jYkfQ79yRkCBBsVOxCMz6Ff-vZgsUxoulBUUFEWweiAIjTxghLA81WGFMCf8sBuM-HtH2LHqkgnSFHK_7UIy0c26IXf0IvxegrBdWJOhhKeuQyXZ2Ah2d2WuKNrne2Pb3wwnt1eJX1v3XYE3kC20hqRNgxVoZ63gsKNPCeW73l1Odnibj4NeQrBtLAxxpikAnVpJaLZrNslvOgtOEtMJiXe1wNXXa6kxpE7DXb-Z9gWYT-v-sHVTBClA')
    if (assignedModules) {
      let orgCode = new URLSearchParams(window.location.search).get("org");
      if (localStorage.getItem('orgCode')) {
        orgCode = localStorage.getItem('orgCode')
      }
      if (orgCode) {
        localStorage.setItem('orgCode', orgCode);
        dispatch(getOrgDataByCode(orgCode));
      } else {
        dispatch(getOrgData());
      }
    }
    document.documentElement.setAttribute('data-theme', localStorage.getItem('data-theme'));
    let service = new Service();
    let url = `${baseUrlAdmin}/user/server/time/forexam`;
    service.get(url, true).then((data) => {
      if (data && data.status) {
        localStorage.setItem('serverTime1', data.res.serverTime);
        var serverDate = new Date();
        serverDate.setTime(data.res.serverTime);

        timer1 = setInterval(() => {
          let time = Number(localStorage.getItem('serverTime1'));
          time += 1000;
          localStorage.setItem('serverTime1', time);
          //    console.log(new Date(time).toString(), 'a')
          //    console.log(new Date().toString(), 'b')
        }, 1000);
      }
    });
  }, [])

  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/error" element={<Error />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="/access" element={<Access />} />
      <Route path="*" element={<App />} />
    </Routes>
  );
};

export default Authentication(withRouter(AppWrapper));


