import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
// import './styles.scss'
import { Button } from 'primereact/button';
import { AddIcon2, DownloadIcon, MailIcon, TickBgIcon } from '../svgIcons';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BasicLazyParams } from '../../utile';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import './style.scss';
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

const options = [
    { value: 1, label: 'Fee Type Based' },
    { value: 2, label: 'Term Based' }];




const feeTypeData = [
    {
        fee_type: "Monday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Tuesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Wednesday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Thursday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Friday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },
    {
        fee_type: "Saturday",
        approval: 'Subject Teacher',
        total_fee: 'Subject Teacher',
        concession: 'Subject Teacher',
        assigned_fee: 'Subject Teacher',
        paid_fee: 'Subject Teacher',
        totalDue: 'Subject Teacher'
    },


];
const searchOptions = [
    { value: 1, label: "Student Name" },
    { value: 2, label: "Enrollment Number" }
]

const paymentType = [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Cheque' },
    { value: 3, label: 'DD' },
    { value: 4, label: 'Online' },
    { value: 5, label: 'Credit Card' },
    { value: 6, label: 'Debit Card' },
    { value: 7, label: 'QR Code' },
]
export class MyTimeTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            feeTypeData: feeTypeData,
            paymentType: paymentType,
            openCreateFeeDialog: false,
            searchType: 1,
            searchInput: ""
        }
    }

    onHandleUnassign = () => {

        // console.log('Unassigned:', this.state.selectedFeeData, 'with remarks:', this.state.remarks);

        this.setState({
            openCreateFeeDialog: true,
            selectedFeeData: null,
            remarks: ""
        });
    }

    onHandleCancelPayFee = () => {
        this.setState({
            openCreateFeeDialog: false,
            paymentTypeOption: null,
            feeInfoToPayFee: {}

        })
    }

    render() {
        return (
            <div className="m-2">

                


                <div className='grid m-2 mb-6'>
                    <div className="col-3">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Board</p>
                        <div className='flex w-10'>
                            <Dropdown
                                className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                options={sampleOptions}
                                // value={term.academicBoard}
                                // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center mt-1">
                                        <MailIcon width={20} height={16} color={'#667A85'} />
                                        <span className='text-xl ml-4'>Select Board</span>
                                    </div>
                                )} />
                        </div>
                    </div>
                    <div className="col-3">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Grade</p>
                        <div className='flex w-10'>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                <Dropdown
                                    className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4'>Select Grade</span>
                                        </div>
                                    )} />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 ">
                        <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Section</p>
                        <div className='flex w-10'>
                            <div className='p-inputgroup border-300 shadow-2 border-round-md'>

                                <Dropdown
                                    className='fieldHeight w-full border-round-md shadow-2 text-lg pl-2'
                                    options={sampleOptions}
                                    // value={term.academicBoard}
                                    // onChange={(e) => { onDropDownChange(e.target.value, 'academicBoard', this, createTermFields, term, formValidations, 'term', 'formValidations') }}
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4'>Select Section</span>
                                        </div>
                                    )} />
                            </div>
                        </div>
                    </div>
                    
                </div>


                {
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.feeTypeData}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesTextt"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        resizableColumns
                        // selection={this.state.selectedProducts} 
                        // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    >

                        <Column  field="fee_type"   bodyClassName='text-center' header="Days / Periods" alignHeader={"center"} />
                        <Column  field="approval"  bodyClassName='text-center' header="Period 1" alignHeader={"center"} />
                        <Column  field="total_fee"  bodyClassName='text-center' header="Period 2" alignHeader={"center"} />
                        <Column  field="concession"  bodyClassName='text-center' header="Period 3" alignHeader={"center"} />
                        <Column  field="assigned_fee"  bodyClassName='text-center' header="Period 4" alignHeader={"center"} />
                        <Column  field="paid_fee"  bodyClassName='text-center' header="Period 5" alignHeader={"center"} />
                        <Column  field="paid_fee"  bodyClassName='text-center' header="Period 6" alignHeader={"center"} />
                        <Column  field="paid_fee"  bodyClassName='text-center' header="Period 7" alignHeader={"center"} />
                        <Column  field="paid_fee"  bodyClassName='text-center' header="Period 8" alignHeader={"center"} />


                    </DataTable>

                }

                <Dialog
                    blockScroll={false}
                    visible={this.state.openCreateFeeDialog}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: "400px", height: "500px" }}
                    dismissableMask={false}
                    closable={false}
                    onHide={() => {
                        this.setState({ openCreateFeeDialog: false });
                    }}
                    footer={() => {
                        return (
                            <div className='flex justify-content-center'>
                                <Button label="Unassign" className='confirmDialogAcceptBtn mr-4  px-6' onClick={() => { }} />
                                <Button style={{ color: "black" }} label="Cancel" className='confirmDialogCancelBtn px-6' onClick={() => {
                                    this.setState({ openCreateFeeDialog: false });
                                }} />

                            </div>
                        );
                    }}
                >
                    <div className='text-center'>
                        <TickBgIcon width={32} height={32} color="#268900" />
                        <h4 className="confirmDialogHeader">Confirm Unassign Fee</h4>
                        <p className="text-xl">
                            Fee Type : Caution Deposit
                        </p>
                        <p className="text-xl">
                            Assigned Fee : 5,000
                        </p>


                    </div>
                    <div className="m-2">
                        <div className='mt-3'>
                            <p className='ma-label-s2 font-bold text-xl text-black'>Remarks</p>
                            <div className='flex'>
                                <div className="p-inputgroup border-300 shadow-2 border-round-md" style={{ position: 'relative', height: '8rem', width: '100%' }}>
                                    {this.state.remarks === '' && (
                                        <label
                                            style={{
                                                position: 'absolute',
                                                top: '0.5rem',
                                                left: '0.5rem',
                                                fontSize: '17px',
                                                color: '#aaa',
                                                pointerEvents: 'none'
                                            }}
                                        >
                                            Remarks
                                        </label>
                                    )}
                                    <InputText
                                    
                                        id="title"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            padding: '1.5rem 0.5rem 0.5rem 0.5rem',
                                            fontSize: '1rem',
                                            borderRadius: '0.5rem',
                                            border: 'none',
                                            boxShadow: 'none'
                                        }}
                                        value={this.state.remarks}
                                        onChange={(e) => this.setState({ remarks: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>




            </div>
        )
    }
}






export default MyTimeTable