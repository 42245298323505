import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react'
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { getFormFields, isFieldValid, isFormValid, onDropDownChange, onEmailChange, onNumberChange, onTextChange } from '../../utile/formHelper';
import LoadingComponent from '../loadingComponent';
import enquiryFields from './enquiryFields.json'
import registrationFields from './registrationFields.json'
import Registation from './registation';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    getBoardsData,
    getBranchesLatest
} from './../../store/actions';
import Authentication from './../session';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import FeeReciept from './feeReciept';
import FeeDetails from './feeDetails';
import FileUpload from '../discussionBoard/common/fileUpload';
import { StaticConstants } from '../../constants';
import { handleDownload } from '../../utile';
import { I18Next } from './../../internationalization';
import withRouter from '../lib/withRouter';



const transportOptions = [
    { label: "Required", value: "required" },
    { label: "Not Required", value: "notRequired" }
]

const admissionTypeOptions = [
    { label: "Day scholar ", value: "dayScholar" },
    { label: "Residential ", value: "residential " },
    { label: "Semi Residential", value: "semiResidential" }

]

const gender = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" }
]

const PointofContact = [
    { label: "Father", value: "father" },
    { label: "Mother ", value: "mother " },
    { label: "Guardian", value: "guardian" }
]


class CreateEnquiry extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(enquiryFields, this.props.applicationData || {});
        this.state = {
            enquiryField: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            enquieyForm: true,
            registrationForm: false,
            stage2Data: {
                applicationNumber: '',
                registrationPaymentMode: '',
                applicationForm: '',
                amount: '',
                applicationFee: 0,
                registrationFee: 0
            },
            feeDetails: null,
            showDownloadButton: false,
            uploadedFilesInfo: [],
            showUploadedImage: false,

            // isShowFeePrint: true,
        }

        this.service = new Service();
    }





    getAdmissionFees = (classId) => {


        if (!this.state.feeDetailsFetched) {


            const url = `${baseUrlAdmin}/application-fee/filter/${classId}`;
            this.service.get(url, true).then(res => {
                if (res && res.status) {

                    if (res.res.data) {
                        this.setState((prevState) => {
                            return {
                                stage2Data: {
                                    ...prevState.stage2Data,
                                    registrationFee: res.res?.data?.registrationFee,
                                    applicationFee: res.res?.data?.applicationFee,

                                },
                                feeDetailsFetched: true
                            }
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            feeDetailsFetched: true
                        })
                        this.toast.show({ severity: 'error', summary: 'No Plan is available', detail: 'No application fee is configured.', life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false,
                        feeDetailsFetched: true
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })
        }
    }

    getFeePlan = (admission) => {

        if (!this.state.isFeePlansFetched) {



            const url = `${baseUrlAdmin}/fee-plans/filter`;

            let filter = {
                academicYear: admission.academicYear,
                classId: admission.classId,
                sectionId: admission.sectionId,
                admissionType: admission.admissionType
            }

            this.service.post(url, filter, true).then(res => {
                if (res && res.status) {
                    if (res.res.data && res.res.data.length) {
                        this.setState({
                            feeDetails: res.res.data,
                            isLoading: false,
                            isFeePlansFetched: true
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                            isFeePlansFetched: true
                        })
                        this.toast.show({ severity: 'error', summary: 'No Plan is available', detail: 'No plan is created for the this curriculum.', life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false,
                        isFeePlansFetched: true
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false,
                    isFeePlansFetched: true
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })
        }
    }

    componentDidMount() {


        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {

            if (this.props.boards && this.props.boards.length && this.props.applicationData) {
                const _classes = [];
                const _groups = [];
                const selectedBoard =
                    this.props.boards &&
                    this.props.boards &&
                    this.props.boards.length > 0 &&
                    this.props.boards.find((board) => board.boardId === this.props.applicationData.boardId);
                if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                    selectedBoard.classes.map((item) => _classes.push({ label: item.className, value: item.classId }));
                }

                const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === this.props.applicationData.classId);
                if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                    selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                    _groups.find((g) => g.groupId == this.props.applicationData.groupId)

                    this.setState(
                        {
                            boardId: this.props.applicationData.boardId,
                            classId: this.props.applicationData.classId,
                            groupId: this.props.applicationData.groupId,
                            classes: _classes,
                            groups: _groups,
                            boardName: selectedBoard.boardName,
                            className: selectedClass.className,

                        });
                }
            }



        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


        if (this.props.applicationData && this.props.applicationData.stage == 'registration') {
            // this.getFeePlan(this.props.applicationData);
            this.getAdmissionCourses(this.props.applicationData?.classId)
        }
        if (this.props.applicationData && this.props.applicationData.stage == 'enquiry') {
            this.getAdmissionFees(this.props.applicationData?.classId)
        }




    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards && this.props.boards.length && this.props.applicationData) {
                const _classes = [];
                const _groups = [];
                const selectedBoard =
                    this.props.boards &&
                    this.props.boards &&
                    this.props.boards.length > 0 &&
                    this.props.boards.find((board) => board.boardId === this.props.applicationData.boardId);
                if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                    selectedBoard.classes.map((item) => _classes.push({ label: item.className, value: item.classId }));
                }

                const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === this.props.applicationData.classId);
                if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                    selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                    _groups.find((g) => g.groupId == this.props.applicationData.groupId)

                    this.setState(
                        {
                            boardId: this.props.applicationData.boardId,
                            classId: this.props.applicationData.classId,
                            groupId: this.props.applicationData.groupId,
                            classes: _classes,
                            groups: _groups,
                            boardName: selectedBoard.boardName,
                            className: selectedClass.className,

                        });
                }
            }
        }

        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


        if (prevProps.applicationData != this.props.applicationData) {
            this.setState((prevState) => {
                return {
                    enquiryField: {
                        ...prevState.enquiryField,
                        ...this.props.applicationData
                    }
                }
            })
        }

        if (this.props.applicationData && this.props.applicationData.stage == 'registration' && !this.state.feeDetails) {
            //this.getFeePlan(this.props.applicationData)
        }

        if (prevState.enquiryField && this.state.enquiryField.stage == 'enquiry' && this.state.enquiryField.stage != prevState.enquiryField.stage && !this.state.feeDetailsFetched) {
            this.getAdmissionFees(this.state.enquiryField?.classId)
        }
    }

    onChangeBoard = (boardId) => {
        this.setState({ classes: [], groups: [], classId: null, groupId: null });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState((prevState) => {
            return {
                classes: _classes,
                boardId,
                boardName: selectedBoard.boardName,
                stage2Data: {
                    ...prevState.stage2Data,
                    applicationFee: 0,
                    registrationFee: 0
                },
                feeDetailsFetched: false
            }
        });

    };


    getAdmissionCourses = (classId) => {
        const url = `${baseUrlAdmin}/admission-courses/filter/${classId}`;
        this.setState({
            isLoading: true,
            admissionCourses: []
        })

        this.service.get(url, true).then(res => {

            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.length) {
                    this.setState({
                        isLoading: false,
                        admissionCourses: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({ severity: 'error', summary: 'No Records', detail: 'No Courses available for the selected class', life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })

    }

    onChangeClass = (classId) => {
        this.setState({ groups: [], groupId: null });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            let selectedGroup = _groups[0];


            this.setState((prevState) => {
                return {
                    enquiryField: {
                        ...prevState.enquiryField,
                        course: null
                    },
                    groups: _groups,
                    classId,
                    className: selectedClass.className,
                    groupId: selectedGroup.value,
                    stage2Data: {
                        ...prevState.stage2Data,
                        applicationFee: 0,
                        registrationFee: 0
                    },
                    feeDetailsFetched: false
                }
            }, () => {


                this.getAdmissionCourses(classId)

                if (this.state.enquiryField.stage == 'enquiry') {
                    this.getAdmissionFees(classId);
                }

            });

        }
    };


    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }

        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        if (!data.selectedNode.children) {
            let stage2Data = {
                ...this.state.stage2Data,
                sectionId: data.selectedNode.key,
                section: data.selectedNode.name
            };
            this.setState((prevState) => {
                return {
                    stage2Data,
                    sectionsData: newSectionData
                };
            });
        } else {
            let stage2Data = {
                ...this.state.stage2Data,
                sectionId: '',
                section: ''
            };


            this.setState({
                sectionsData: newSectionData,
                stage2Data
            });
        }
    };

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }

    onSubmit = (data, isContinue) => {
        const url = `${baseUrlAdmin}/admissions`;

        if (!data.stage) {
            data.stage = 'enquiry';
        } else if (data.stage == 'enquiry') {
            data.stage = 'registration';
        } else if (data.stage == 'registration') {
            data.stage = 'admission';
        }





        if (this.props.applicationData) {
            this.setState({
                isLoading: true
            })
            this.service.put(url, data, true).then(res => {
                if (res && res.status) {
                    if (data.stage == 'enquiry') {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.onCreateOrEditSuccess(true);
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            isShowFeePrint: true,
                            reciept: res.res.data?.reciept,
                            isContinue: isContinue,
                            application: res.res.data?.application
                        })
                    }
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })

        } else {
            this.setState({
                isLoading: true
            });

            this.service.post(url, data, true).then(res => {
                if (res && res.status) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        if (isContinue) {
                            this.props.setApplicationData(res?.res?.data)
                        } else {
                            this.props.onCreateOrEditSuccess(true);
                        }
                    })

                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })
        }
    }

    onClickSaveAndExit = (isContinue) => {
        const { stage2Data } = this.state;
        let body = cloneDeep(this.state.enquiryField);

        body.boardId = this.state.boardId;
        body.classId = this.state.classId;
        body.groupId = this.state.groupId;
        body.boardName = this.state.boardName;
        body.className = this.state.className;


        const formStatus = isFormValid(enquiryFields, this.formFields.formValidations, body);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }




        if (body.stage == 'enquiry') {
            if (!stage2Data.applicationFee || !stage2Data.registrationFee) {
                isCompleteFormValid = false;
                return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Application and registration fee is required.', life: 3000 });

            }



            if (!stage2Data.applicationNumber || stage2Data.applicationNumber == '' ||
                !stage2Data.registrationPaymentMode || stage2Data.registrationPaymentMode == '' ||
                !stage2Data.sectionId || stage2Data.sectionId == ''
            ) {
                isCompleteFormValid = false;
                return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter application number, payment type and section', life: 3000 });
            } else {
                body.applicationNumber = stage2Data.applicationNumber;
                body.registrationPaymentMode = stage2Data.registrationPaymentMode;

                body.applicationForm = stage2Data.applicationForm;
                body.applicationFee = stage2Data.applicationFee;
                body.registrationFee = stage2Data.registrationFee;
                body.sectionId = stage2Data.sectionId;
                body.section = stage2Data.section;
            }
        }

        if (body.stage == 'registration') {

            if (this.state.feeDetails && this.state.feeDetails.length) {



                let totalFeeToBePaid = this.state.feeDetails?.reduce((total, fee) => {
                    return total + parseInt(fee.balance);
                }, 0);

                if (!stage2Data.amount || stage2Data.amount == '') {
                    isCompleteFormValid = false;
                    return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter amount', life: 3000 });
                } else if (parseInt(stage2Data.amount) > totalFeeToBePaid) {
                    isCompleteFormValid = false;
                    return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter amount less than the total amount', life: 3000 });
                } else {
                    body.amount = parseInt(stage2Data.amount);
                    body.admissionPaymentMode = stage2Data.registrationPaymentMode;
                    body.feeDetails = this.state.feeDetails;
                }
            } else {
                // isCompleteFormValid = false;
                // return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Fee Plan is not configured for the user heirarchy.', life: 3000 });

            }
        }


        if (isCompleteFormValid) {
            this.onSubmit(body, isContinue)
        }
    }


    onPaymentModeChange = (e) => this.setState((prevState) => {
        return {
            stage2Data: {
                ...prevState.stage2Data,
                registrationPaymentMode: e.value
            }
        }

    })


    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    _afterFilesUploaded = (files) => {

    }

    addAttachment = (fieldName, fileName, data) => {
        let enquiryField = {
            ...this.state.enquiryField,
            [fieldName]: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,

            }
        }

        // let formValidations = isFieldValid(enquiryFields.find(fm => fm.FieldName === fieldName), this.state.formValidations, enquiryField);


        this.setState((prevState) => {
            return {
                enquiryField,
                showDownloadButton: true,
                //  formValidations
            }
        });
    }

    _onUploadPdf = (fileName, data) => {

        this.addAttachment('applicationUrl', fileName, data);
        // return;
        // let org = {
        //     ...this.state.org,
        //     agreement: {
        //         fileName,
        //         storageBucket: data.Bucket,
        //         storageKey: data.Key,
        //         storageLocation: data.Location,

        //     }
        // }
        // let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'agreement'), this.state.formValidations, org);


        // this.setState((prevState) => {
        //     return {
        //         org,
        //         formValidations
        //     }
        // });


    }

    _onProgress = (filesInfo) => {

        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == filesInfo.name) {
                file = filesInfo;
            }
            return file;
        })

        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });
    }

    _onSuccess = (fileName, data) => {

        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == fileName) {
                file.s3Info = data;
                file.uri = data.Location;
            }
            return file;
        })
        if (files.length > 1) {
            files.shift()
        }
        this.setState((prevState) => {
            return { uploadedFilesInfo: files, showUploadedImage: true }
        });

    }

    _afterPhotoUploaded = (filess) => {

        let files = _.cloneDeep(Array.from(filess));
        let uploadedFilesInfo = this.state.uploadedFilesInfo;
        for (let i = 0; i < files.length; i++) {
            if (!uploadedFilesInfo.find(a => a.name == files[i].name)) {
                uploadedFilesInfo = [...uploadedFilesInfo, files[i]]
            }
        }
        this.setState((prevState, prevProps) => ({
            uploadedFilesInfo
        }));
    }







    render() {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        const { formValidations, enquiryField, stage2Data, stage3Data } = this.state;
        return (
            <>
                {this.state.enquieyForm &&
                    <>
                        <div className='flex justify-content-end my-3'>
                            <p className='back-arrow' onClick={() => this.props.onClick()}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </div>
                        <div className='card'>
                            <div className="grid">
                                <div className="col-12">
                                    <h4 className=''>{I18Next('enquiryForm', langType).COMMON_KEY}</h4>
                                </div>
                                <div className="col-4">
                                    <div className=''>
                                        <p className='mb-0'>{I18Next('academicYear', langType).COMMON_KEY}</p>
                                        <Dropdown
                                            options={this.props.academicYears}
                                            className='w-9'
                                            optionLabel='academicCode'
                                            optionValue='academicCode'
                                            value={enquiryField.academicYear}
                                            onChange={(e) => onTextChange(e.target.value, 'academicYear', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                        />
                                        {formValidations && !formValidations.fields['academicYear'].isValid && <p className="p-error">{formValidations.fields['academicYear'].errorMsg}</p>}
                                    </div>
                                </div>


                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('admissionType', langType).COMMON_KEY}</p>
                                    <Dropdown
                                        className='w-9'
                                        value={enquiryField.admissionType}
                                        onChange={(e) => onTextChange(e.target.value, 'admissionType', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                        options={this.props.admissionTypes}
                                        optionLabel='admissionType'
                                        optionValue='admissionCode'
                                    />
                                    {formValidations && !formValidations.fields['admissionType'].isValid && <p className="p-error">{formValidations.fields['admissionType'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('transport', langType).COMMON_KEY}</p>
                                    <Dropdown
                                        className='w-9'
                                        value={enquiryField.transport}
                                        onChange={(e) => onDropDownChange(e.target.value, 'transport', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                        options={transportOptions} optionLabel='label' optionValue='value'
                                    />
                                    {formValidations && !formValidations.fields['transport'].isValid && <p className="p-error">{formValidations.fields['transport'].errorMsg}</p>}
                                </div>

                                <div className='col-12'>
                                    <div>
                                        <p className='mb-0'>{I18Next('studentName', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className='grid'>
                                        <div className="col-2">
                                            <InputText
                                                placeholder='First Name'
                                                className='w-12'
                                                value={enquiryField.studentFirstName}
                                                onChange={(e) => onTextChange(e.target.value, 'studentFirstName', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['studentFirstName'].isValid && <p className="p-error">{formValidations.fields['studentFirstName'].errorMsg}</p>}
                                        </div>
                                        <div className="col-2">
                                            <InputText
                                                placeholder='Middle Name'
                                                className='w-12'
                                                value={enquiryField.studentMiddleName}
                                                onChange={(e) => onTextChange(e.target.value, 'studentMiddleName', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['studentMiddleName'].isValid && <p className="p-error">{formValidations.fields['studentMiddleName'].errorMsg}</p>}
                                        </div>
                                        <div className="col-2">
                                            <InputText
                                                placeholder='Last Name'
                                                className='w-12'
                                                value={enquiryField.studentLastName}
                                                onChange={(e) => onTextChange(e.target.value, 'studentLastName', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                            />
                                            {formValidations && !formValidations.fields['studentLastName'].isValid && <p className="p-error">{formValidations.fields['studentLastName'].errorMsg}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('gender', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <Dropdown
                                        className='w-9'
                                        value={enquiryField.gender}
                                        onChange={(e) => onTextChange(e.target.value, 'gender', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                        options={gender} optionLabel='label' optionValue='value'
                                    />
                                    {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error">{formValidations.fields['gender'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('dob', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <Calendar
                                        value={new Date(enquiryField.dateOfBirth)}
                                        id='tenureend'
                                        showIcon={true}
                                        showTime={false}
                                        hourFormat="12"
                                        maxDate={new Date()}
                                        onChange={(e) => onTextChange(e.target.value, 'dateOfBirth', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                        className='w-9'
                                    ></Calendar>
                                    {formValidations && !formValidations.fields['dateOfBirth'].isValid && <p className="p-error">{formValidations.fields['dateOfBirth'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('aadharNo', langType).COMMON_KEY}</p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.aadharNumber}
                                        onChange={(e) => onNumberChange(e.target.value, 'aadharNumber', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['aadharNumber'].isValid && <p className="p-error">{formValidations.fields['aadharNumber'].errorMsg}</p>}
                                </div>
                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('board', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <Dropdown value={this.state.boardId}
                                        className='w-9'
                                        options={this.props.boards}
                                        optionLabel="boardName"
                                        optionValue="boardId"
                                        onChange={(e) => this.onChangeBoard(e.value)}
                                        placeholder="--Select Board--" />
                                    {!this.state.boardId && <p className="p-error">{formValidations.fields['boardId'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('class', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <Dropdown value={this.state.classId}
                                        className='w-9'
                                        options={this.state.classes}
                                        onChange={(e) => this.onChangeClass(e.value)}
                                        placeholder="--Select Board--" />
                                    {!this.state.classId && <p className="p-error">{formValidations.fields['classId'].errorMsg}</p>}
                                </div>


                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('course', langType).COMMON_KEY}</p>
                                    <Dropdown value={enquiryField.course}
                                        className='w-9'
                                        options={this.state.admissionCourses}
                                        optionLabel='courseName'
                                        optionValue='_id'
                                        onChange={(e) => onTextChange(e.value, 'course', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                        placeholder="--Select Course--" />
                                    {formValidations && !formValidations.fields['course'].isValid && <p className="p-error">{formValidations.fields['course'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('fatherName', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.fatherName}
                                        onChange={(e) => onTextChange(e.target.value, 'fatherName', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['fatherName'].isValid && <p className="p-error">{formValidations.fields['fatherName'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('fatherNo', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.fatherMobileNo}
                                        onChange={(e) => onNumberChange(e.target.value, 'fatherMobileNo', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['fatherMobileNo'].isValid && <p className="p-error">{formValidations.fields['fatherMobileNo'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('emailId', langType).COMMON_KEY}</p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.fatherEmailId}
                                        onChange={(e) => onEmailChange(e.target.value, 'fatherEmailId', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['fatherEmailId'].isValid && <p className="p-error">{formValidations.fields['fatherEmailId'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('occupation', langType).COMMON_KEY}</p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.fatherOccupation}
                                        onChange={(e) => onTextChange(e.target.value, 'fatherOccupation', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['fatherOccupation'].isValid && <p className="p-error">{formValidations.fields['fatherOccupation'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('motherName', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.motherName}
                                        onChange={(e) => onTextChange(e.target.value, 'motherName', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['motherName'].isValid && <p className="p-error">{formValidations.fields['motherName'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('motherNo', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.motherMobileNo}
                                        onChange={(e) => onNumberChange(e.target.value, 'motherMobileNo', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['motherMobileNo'].isValid && <p className="p-error">{formValidations.fields['motherMobileNo'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('emailId', langType).COMMON_KEY}</p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.motherEmailId}
                                        onChange={(e) => onEmailChange(e.target.value, 'motherEmailId', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['motherEmailId'].isValid && <p className="p-error">{formValidations.fields['motherEmailId'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('occupation', langType).COMMON_KEY}</p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.motherOccupation}
                                        onChange={(e) => onTextChange(e.target.value, 'motherOccupation', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['motherOccupation'].isValid && <p className="p-error">{formValidations.fields['motherOccupation'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('guardianName', langType).COMMON_KEY}</p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.guardianName}
                                        onChange={(e) => onTextChange(e.target.value, 'guardianName', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['guardianName'].isValid && <p className="p-error">{formValidations.fields['guardianName'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('pointofContact', langType).COMMON_KEY}</p>
                                    <Dropdown
                                        className='w-9'
                                        value={enquiryField.pointofContact}
                                        onChange={(e) => onTextChange(e.target.value, 'pointofContact', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                        options={PointofContact} optionLabel='label' optionValue='value'
                                    />
                                    {formValidations && !formValidations.fields['pointofContact'].isValid && <p className="p-error">{formValidations.fields['pointofContact'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('address', langType).COMMON_KEY}</p>
                                    <InputTextarea
                                        className='w-9'
                                        value={enquiryField.address}
                                        onChange={(e) => onTextChange(e.target.value, 'address', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>}
                                </div>
                                <div className="col-4">
                                    <p className='mb-0'>{I18Next('previousClass', langType).COMMON_KEY}</p>
                                    <InputText
                                        className='w-9'
                                        value={enquiryField.previousClass}
                                        onChange={(e) => onTextChange(e.target.value, 'previousClass', this, enquiryFields, enquiryField, formValidations, 'enquiryField', 'formValidations')}
                                    />

                                    {formValidations && !formValidations.fields['previousClass'].isValid && <p className="p-error">{formValidations.fields['previousClass'].errorMsg}</p>}
                                </div>

                                <div className="col-12 mb-3">
                                    <div className='flex'>
                                        <p className='mb-0'>{I18Next('uploadPhoto', langType).COMMON_KEY} :</p>
                                        <div className='flex-row'>
                                            <FileUpload id={"file" + (new Date().getTime().toString().substring(5) + 30)}
                                                multiple={false}
                                                onProgress={this._onProgress}
                                                // ResetOnSelect={true}
                                                onSucess={this._onSuccess}
                                                maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                                onError={this._onFilsUploadError}
                                                accept={'images'}
                                                title={'add image'}
                                                afterFilesUploaded={this._afterPhotoUploaded}

                                            >
                                                {/* <Button label="Upload" className="p-button-outlined ml-3 mb-2" icon={"pi pi-upload mr-2"}/> */}
                                                <span className={`outlined-s1 border-primary ml-2`}><i className='pi pi-upload mr-2'></i>Upload</span>
                                            </FileUpload>
                                            {/* <span className={`outlined-s1`}><i className='pi pi-camera ma-mr10'></i>Capture</span> */}
                                        </div>
                                    </div>
                                    {this.state.showUploadedImage && <img className='uploaded-image ml-2 mb-2 mt-2' src={`${this.state.uploadedFilesInfo[0].s3Info.Location}`} />}
                                </div>

                            </div>

                            {
                                enquiryField.stage == 'enquiry' && <> <div className="grid">
                                    <div className="col-4">
                                        <p className='mb-0'>{I18Next('applicationNumber', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                        <InputText
                                            className='w-9'
                                            value={stage2Data.applicationNumber}
                                            onChange={(e) => {
                                                this.setState((prevState) => {
                                                    return {
                                                        stage2Data: {
                                                            ...prevState.stage2Data,
                                                            applicationNumber: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className="col-4 upload-pdf">
                                        <p className='mb-1'>{I18Next('uploadPdf', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                        <div className='flex-row'>
                                            <FileUpload id={"agreement"}
                                                multiple={false}
                                                // onProgress={this._onProgress}
                                                onSucess={this._onUploadPdf}
                                                // Reset={this.state.Reset}
                                                maxFileSize={5242880}
                                                onError={this._onFilsUploadError}
                                                accept={'pdfs'}
                                                title={'Pdfs'}
                                                afterFilesUploaded={this._afterFilesUploaded}
                                            >
                                                <span className={`outlined-s1 border-primary ma-pointer`}>Choose file</span>
                                            </FileUpload>
                                            {this.state.showDownloadButton && <i className='pi pi-download ma-ml10 ma-p10' onClick={() => handleDownload(this.state.enquiryField?.applicationUrl?.storageLocation, this.state.enquiryField?.applicationUrl?.fileName)}></i>}
                                        </div>

                                    </div>

                                    <div className="col-4">
                                        <p className='mb-0'>{I18Next('applicationFee', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                        <InputText
                                            className='w-9'
                                            value={stage2Data.applicationFee}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-4">
                                        <p className='mb-0'>{I18Next('registationFee', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                        <InputText
                                            className='w-9'
                                            value={stage2Data.registrationFee}
                                            disabled
                                        />
                                    </div>

                                </div>
                                    <p className='mb-0'>{I18Next('sectionInfo', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                    <div className="grid">
                                        {
                                            this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                                return <>
                                                    <div className="col-4">
                                                        <p>{level.levelName}<span className='ma-required'>*</span></p>
                                                        <Dropdown
                                                            value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                            className='w-9'
                                                            //    disabled={this.state.hideUpdateButton}
                                                            optionLabel={"name"}
                                                            optionValue={"key"}
                                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                                            onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                                            placeholder={`--${I18Next('select', langType).COMMON_KEY} ${level.levelName}--`} />
                                                        {!(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) && <p className="p-error">Required</p>}
                                                    </div>
                                                </>
                                            }) : null
                                        }
                                    </div>
                                </>}
                            {
                                enquiryField.stage == 'registration' && <> <div className="grid">
                                    {/* <FeeDetails feeDetails={this.state.feeDetails} /> */}
                                    <div className='col-4 mt-3'>
                                        <p className='mb-0 ma-label-s2 ma-mt10'>{I18Next('amount', langType).COMMON_KEY} <span className='ma-required'>*</span></p>
                                        <InputText
                                            placeholder='Enter amount paying'
                                            className='w-9'
                                            value={stage2Data.amount}
                                            onChange={(e) => {
                                                this.setState((prevState) => {
                                                    return {
                                                        stage2Data: {
                                                            ...prevState.stage2Data,
                                                            amount: e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                    </div>

                                </div>
                                </>}
                            {
                                (enquiryField.stage == 'enquiry' || enquiryField.stage == 'registration') && <>
                                    <div className="mt-3">
                                        <p>{I18Next('paymentMode', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                                        <div className="grid">
                                            <div className="col-2">
                                                <RadioButton
                                                    inputId="paymentMode1" name="paymentMode" value="cash"
                                                    onChange={this.onPaymentModeChange}
                                                    checked={stage2Data.registrationPaymentMode === 'cash'} />
                                                <label htmlFor="Cash" className="ml-2">{I18Next('cash', langType).COMMON_KEY}</label>
                                            </div>
                                            <div className="col-2">
                                                <RadioButton
                                                    inputId="paymentMode2" name="paymentMode" value="cheque"
                                                    onChange={this.onPaymentModeChange}
                                                    checked={stage2Data.registrationPaymentMode === 'cheque'} />
                                                <label htmlFor="Cheque" className="ml-2">{I18Next('cheque', langType).COMMON_KEY}</label>
                                            </div>
                                            <div className="col-2">
                                                <RadioButton
                                                    inputId="paymentMode3" name="paymentMode" value="credit/debit"
                                                    onChange={this.onPaymentModeChange}
                                                    checked={stage2Data.registrationPaymentMode === 'credit/debit'} />
                                                <label htmlFor="Credit/Debit" className="ml-2">{I18Next('creditDebit', langType).COMMON_KEY}</label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className='mt-3 ml-2'>
                                <Button label={I18Next('saveExit', langType).COMMON_KEY} onClick={() => { this.onClickSaveAndExit() }} className='p-button-raised'/>
                                {
                                    enquiryField.stage != 'registration' && <Button label={I18Next('saveContinueForRegister', langType).COMMON_KEY} className='ml-3 p-button-raised' onClick={() => { this.onClickSaveAndExit(true) }} />
                                }
                            </div>
                        </div>
                    </>
                }
                {
                    (this.state.isShowFeePrint || false) && <FeeReciept
                        reciept={this.state.reciept}
                        hideFeeReciept={() => {
                            if (this.state.isContinue) {
                                this.setState({
                                    isShowFeePrint: false,
                                    reciept: null
                                }, () => { this.props.setApplicationData(this.state.application) })

                            } else {
                                this.props.onCreateOrEditSuccess(true);
                            }

                        }}
                    />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.registrationForm && <Registation enquiryFormData={this.state.enquiryFormData} />}
                {this.state.isLoading && <LoadingComponent />}
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    defaultLanguage: state.languageReducer.language,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(CreateEnquiry)));

