import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { ExamTemplates } from '../constants';
import { Toast } from 'primereact/toast';
import Authorizer, { PERMISSIONS, isAuthorized, getPermIdOfAction } from '../../session/authorizer';
import Authentication from './../../session';
import { connect } from 'react-redux';
import AdhocTemplate from './adhoc';
import AdhocTemplate2 from './adhocT2';
import SubjectiveTemplate from './subjective';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import './styles.scss'
import withRouter from '../../lib/withRouter';
class CreateTest extends Component {
    constructor(props) {
        super(props);


        this.state = {
            examType: props.editExamType ? props.editExamType : '',
            testTypes: [],
        };
    }



    onCreateSuccess = (isEdit) => {
        this.setState({
            examType: null
        }, () => {
            if (isEdit) {
                this.props.onExamEditSuccess()
            }

        })
        this.toast.show({ severity: 'success', summary: 'Created', detail: `${isEdit ? 'Edited' : 'Created'} exam record successfully`, life: 3000 });
    }



    componentDidMount() {
        if (this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [];

                ExamTemplates.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-template'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.permissionIds !== this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [];

                ExamTemplates.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-template'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }

        if (prevProps.editExamId != this.props.editExamId) {
            this.setState({
                editExamId: this.props.editExamId
            })


        }
    }



    render() {

        //  console.log('exam-creation', this.state)
        return (<>
            <InputTextB info={FIELDS_INFO.TEST_TYPE} id="acd">
                <Dropdown value={this.state.examType}
                    className='w-15rem ma-w200'
                    disabled={this.props.editExamType}
                    options={this.state.testTypes}
                    onChange={(e) => this.setState({ examType: e.value })}
                    placeholder="Select Test Type" />
            </InputTextB>
            <div className='ma-mt20'>
                {
                    this.state.examType == 'AdhocMockTest' && <AdhocTemplate />
                }


                {
                    this.state.examType == 'SubjectiveTest' && <SubjectiveTemplate />
                }


                {
                    this.state.examType == 'AdhocMockTestT2' && <AdhocTemplate2 />
                }

            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}




const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});




export default connect(mapStatesToProps, {})(Authentication(withRouter(CreateTest)));