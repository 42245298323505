import React, { Component } from 'react'
import { Calendar } from 'primereact/calendar';
// import Navbar from '../../../navbar'
import { Toolbar } from 'primereact/toolbar';
import moment from 'moment';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../../session/authorizer';
import Authentication from '../../../session';
// import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    getBoardsData,
    getBranchesLatest
} from '../../../../store/actions';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import './styles.scss';
import TotalAnalyticsTable from './../totalAnalyticsTable';
import Service from '../../../../services';
import { baseUrlForLongRunningTask } from '../../../../store/apiConstants';
import SingleUserAnalytics from './../singleUserAnalytics'
import GraphicalView from './../graphicalView';
import LoadingComponent from '../../../loadingComponent';
import { AnalyticsIcon, BackArrowIcon, FilterIcon2 } from '../../../svgIcons';
import BranchWiseAccordian from './branchWiseAccordian';
import OverallUsageAnalytics from './overallUsageAnalytics';
import withRouter from '../../../lib/withRouter';




const items = [
    { label: 'Analytics' },
    { label: 'Usage Analytics' },
    { label: 'Branch Wise', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


let timesArray = {
    '6 am - 9 am': { from: 6, to: 9 },
    '9 am - 12 am': { from: 9, to: 12 },
    '12 am - 3 pm': { from: 12, to: 15 },
    '3 pm - 6 pm': { from: 15, to: 18 },

};

let timesArray2 = {

    '6 pm - 9 pm': { from: 18, to: 21 },
    '9 pm - 12 pm': { from: 21, to: 24 },
    '12 am - 3 am': { from: 0, to: 3 },
    '3 am - 6 am': { from: 3, to: 6 }
};



let ResourceTypes = [
    { field: null, title: 'All' },
    { field: 'mp4', title: 'Videos' },
    { field: 'pdf', title: 'PDF' },
    { field: 'quiz', title: 'QUIZ' },
    { field: 'zip', title: 'PPT' }

]


const InitialFilters = {
    timeRange: { from: 0, to: 24 },
    subjectId: null,
    contentType: null
};


const getText = (key) => {
    switch (key) {
        case 'mp4':
            return 'Videos'
        case 'pdf':
            return 'PDF'
        case 'zip':
            return 'PPT'
        case 'quiz':
            return 'Quiz'

        default:
            return ''
    }

}

const formMMHH = (value) => {

    let times = value.split(":");
    let hh = times[0];
    let mm = times[1];
    return parseFloat(hh + '.' + mm)
}


const filterGraphData = (data) => {
    let temp = [];

    Object.keys(data).forEach(key => {
        if (key == 'mp4' || key == 'pdf' || key == 'zip' || key == 'quiz') {
            temp.push({
                key: getText(key),
                [getText(key)]: formMMHH(data[key])
            })
        }
    });

    return temp;
}

const getPercentage = (data) => {
    let temp = [];

    Object.keys(data).forEach(key => {
        // if (key == 'mp4' || key == 'pdf' || key == 'zip' || key == 'quiz') {
        temp.push({
            id: getText(key),
            labFel: getText(key),
            value: data[key]
        })
        // }
    });

    return temp;
}

const getSubjectWiseGraph = (data) => {
    let temp = [];

    Object.keys(data).forEach(key => {
        // if (key == 'mp4' || key == 'pdf' || key == 'zip' || key == 'quiz') {
        temp.push({
            id: key,
            labFel: key,
            value: data[key]
        })
        // }
    });

    return temp;
}



class BranchWiseAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowSingleUser: false,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            topicId: null,
            fromDate: '',
            toDate: '',
            currentGradeSubjects: [],
            totalUsageAnalytics: {},
            totalUsageAnalyticsPerc: {},
            finalRecords: [],
            totalCount: '',
            userAnalytics: { 'mp4': 0, 'pdf': 0, 'zip': 0, 'quiz': 0 },
            totalHours: 0,
            isLoading: false,
            snackBarMsg: '',
            snackBarOpen: false,
            filters: InitialFilters,
            search: null,
            records: null,
            showFilterDialog: false,
            singleUserRecord: null,
            timeRangeKey: '',
            disableSearch: false,
            isShowGraphicalView: false,
            filterFor: 'all',
            isShowCompleteAnalysis: false,
            branchWiseAnalytics: []
        }

    }


    //totalUsage


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className='ma-tab-title'>Branch Wise Analytics</h4>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    componentDidMount() {


        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }


        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }





    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });

                this.setState({
                    boards: boards
                });
            }
        }

        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


    }

    onChangeBoard = (boardId) => {
        this.setState({ classes: [], groups: [], classId: null, groupId: null });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            filters: InitialFilters,
            totalUserRecord: null,
            currentGradeSubjects: [],
            totalUsageAnalytics: {},
            totalUsageAnalyticsPerc: {},
            finalRecords: [],
            totalCount: '',
            branchWiseAnalytics: []
        });

    };

    onChangeClass = (classId) => {
        this.setState({ groups: [], groupId: null });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            let selectedGroup = _groups[0];

            this.setState({
                groups: _groups,
                classId,
                groupId: selectedGroup.value,
                filters: InitialFilters,
                totalUserRecord: null,
                currentGradeSubjects: [],
                totalUsageAnalytics: {},
                totalUsageAnalyticsPerc: {},
                finalRecords: [],
                totalCount: '',
                branchWiseAnalytics: []
            });
        }
    };




    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }




    onSearchClick = () => {

        const { dateRange, isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionIds, startDate, endDate } = this.state;


        if (!boardId) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board, Class.', life: 3000 });
            return;
        } else if (boardId && (!classId || !groupId)) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Class', life: 3000 });
            return;
        } else if (!dateRange || !dateRange.length > 1) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        } else if (!dateRange[0] || !dateRange[1]) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        }
        // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
        //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
        //     this.props.snackBarOpen('Kindly select Course and Section.');
        //     return;
        // }



        this.getAthenaData({
            boardId,
            classId,
            groupId,
            sectionId: sectionIds && sectionIds.length > 0 ? sectionIds : null,
            dateRange: dateRange
        });

    };


    handleClearSearch = () => {


        let sectionsData = {};
        if (this.props.branchData && this.props.branchData.length) {
            sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
        }

        this.setState(
            {
                boardId: null,
                classId: null,
                groupId: null,
                branchId: null,
                courseId: [],
                sectionIds: [],
                username: '',
                dateRange: [],
                classes: [],
                sectionsData,
                totalUserRecord: null,
                currentGradeSubjects: null,
                totalUsageAnalytics: null,
                finalRecords: null,
                totalCount: null,
                currentGradeSubjects: null,
                filters: InitialFilters,
                timeRangeKey: '',
                branchWiseAnalytics: []

            }
        );
    };


    onResetFilters = () => {
        this.setState({ showFilterDialog: false, filters: InitialFilters, timeRangeKey: '' },
            () => {
                this.onSearchClick();
            }
        )
    }

    onMultiDateChange = (dateRange) => {
        this.setState({
            dateRange,
            filters: InitialFilters,
            totalUserRecord: null,
            currentGradeSubjects: [],
            totalUsageAnalytics: {},
            totalUsageAnalyticsPerc: {},
            finalRecords: [],
            totalCount: '',
            branchWiseAnalytics: []
        });
    }

    getSelectedUsersAnalytics = (userId, userName) => {
        let { boardId, classId, groupId, dateRange } = this.state;
        let search = {
            boardId,
            classId,
            groupId,
            userId,
            //   sectionId: sectionIds && sectionIds.length > 0 ? sectionIds : null,
            dateRange: dateRange
        }

        let reqBody = {
            ...search,
            ...InitialFilters
        };

        const service = new Service();
        let url = `${baseUrlForLongRunningTask}/analytics/getuseranalytics`;

        this.setState({
            filters: InitialFilters,
            timeRangeKey: '',
            disableSearch: true,
            isLoading: true,
            previousAllFilters: this.state.filters,
            previousAllTimeRangeKey: this.state.timeRangeKey,
            userId,
            userName
        });

        service.post(url, reqBody, false).then((res) => {

            if (res.status) {
                this.setState({
                    singleUserRecord: res.res,
                    currentGradeSubjects: res.res.currentGradeSubjects,
                    isLoading: false,
                    isShowSingleUser: true,
                    filterFor: 'single'
                });
            } else {
                this.setState({
                    errorMessage: 'Some error occured',
                    isLoading: false,
                    snackBarMsg: 'Some error occured',
                    snackBarOpen: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unkown error occured', life: 3000 });
            }
        }).catch(e => {

            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }


    setTimeRange = (value, key) => {
        this.setState((prevState) => ({
            filters: { ...prevState.filters, timeRange: value },
            timeRangeKey: key
        }));
    }

    handleChange = (type, value) => {

        if (type == "resource") {
            this.setState(prevState => ({
                filters: { ...prevState.filters, contentType: value }
            }));

        } else {

            this.setState(prevState => ({
                filters: { ...prevState.filters, subjectId: value }
            }));

        }
    }

    getAthenaData = (searchCriteria) => {
        if (!searchCriteria) return;

        let search = {
            boardId: searchCriteria.boardId,
            classId: searchCriteria.classId,
            groupId: searchCriteria.groupId,
            //  sectionIds: searchCriteria.sectionId,
            dateRange: searchCriteria.dateRange,
        }
        let reqBody = {
            ...InitialFilters,
            ...search

        };
        const service = new Service();
        let url = `${baseUrlForLongRunningTask}/analytics/gettotalanalytics2`;

        this.setState({
            isLoading: true,
            search,
            filters: InitialFilters,
            totalUserRecord: null,
            currentGradeSubjects: [],
            totalUsageAnalytics: {},
            totalUsageAnalyticsPerc: {},
            finalRecords: [],
            totalCount: '',
            branchWiseAnalytics: []
        });

        service.post(url, reqBody, true).then((res) => {
            
            if (res.status) {

                let {
                    currentGradeSubjects,
                    totalUsageAnalytics,
                    branchWiseAnalytics,
                    finalRecords,
                    totalCount
                } = res.res;

                this.setState({
                    totalUserRecord: res.res,
                    currentGradeSubjects,
                    totalUsageAnalytics,
                    branchWiseAnalytics,
                    // finalRecords,
                    // totalCount,
                    // currentGradeSubjects: res.res.currentGradeSubjects,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false,
                })
                this.toast.show({ severity: 'error', summary: 'No data', detail: res.message, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false,
            })
            
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    getAthenaDataByFilter = () => {
        const { dateRange, isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionIds, startDate, endDate, userId } = this.state;


        if (!boardId) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board, Class.', life: 3000 });
            return;
        } else if (boardId && (!classId || !groupId)) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Class', life: 3000 });
            return;
        } else if (!dateRange || !dateRange.length > 1) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        } else if (!dateRange[0] || !dateRange[1]) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        }

        if (this.state.filterFor == 'all') {
            let reqBody = {
                //...this.state.search,

                boardId,
                classId,
                groupId,
                //sectionId: sectionIds && sectionIds.length > 0 ? sectionIds : null,
                dateRange: dateRange,

                ...this.state.filters
            };
            const service = new Service();

            let url = `${baseUrlForLongRunningTask}/analytics/gettotalanalytics2`;

            this.setState({
                isLoading: true,
                showFilterDialog: false,
                allUserFilters: this.state.filters,
                totalUserRecord: null,
                currentGradeSubjects: [],
                totalUsageAnalytics: [],
                branchWiseAnalytics: [],

            });

            service.post(url, reqBody, true).then((res) => {
                
                if (res.status) {
                    let {
                        currentGradeSubjects,
                        totalUsageAnalytics,
                        branchWiseAnalytics,
                        finalRecords,
                        totalCount
                    } = res.res;

                    this.setState({
                        totalUserRecord: res.res,
                        currentGradeSubjects,
                        totalUsageAnalytics,
                        branchWiseAnalytics,
                        // finalRecords,
                        // totalCount,
                        // currentGradeSubjects: res.res.currentGradeSubjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        errorMessage: 'Some error occured',
                        isLoading: false,
                        snackBarMsg: 'Some error occured',
                        snackBarOpen: false
                    })
                }
            }).catch(e => {

                

            });
        } else {
            let reqBody = {
                boardId,
                classId,
                groupId,
                sectionId: sectionIds && sectionIds.length > 0 ? sectionIds : null,
                dateRange: dateRange,
                userId,
                // ...this.state.singleUserSearch,
                ...this.state.filters
            };

            const service = new Service();
            let url = `${baseUrlForLongRunningTask}/analytics/getuseranalytics`;

            this.setState({
                showFilterDialog: false,
                isLoading: true,
                singleUserFilters: this.state.search
            });

            service.post(url, reqBody, false).then((res) => {
                if (res.status) {
                    this.setState({
                        singleUserRecord: res.res,
                        currentGradeSubjects: res.res.currentGradeSubjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        errorMessage: 'Some error occured',
                        isLoading: false,
                        snackBarMsg: 'Some error occured',
                        snackBarOpen: false
                    })
                }
            });
        }

    }

    showGraphView = () => {

        let { filterFor, singleUserRecord, totalUserRecord } = this.state;


        if (filterFor == 'all') {
            this.setState({
                isShowGraphicalView: true,
                totalUsageAnalytics: totalUserRecord.totalUsageAnalytics,
                subjectWiseGraphData: getSubjectWiseGraph(totalUserRecord.subjectWiseAnalytics),
                contentWiseWiseGraphData: getPercentage(totalUserRecord.totalUserAnalyticsPerc)
            })
        } else {
            this.setState({
                isShowGraphicalView: true,
                pieData: getPercentage(singleUserRecord.userTotalAnalyticsPerc),
                graphAnalytics: singleUserRecord.userTotalAnalytics,
                barData: filterGraphData(singleUserRecord.userTotalAnalytics)
            })
        }





    }
    render() {

        //  console.log(this.state)

        const { isShowSingleUser, dateRange, totalUsageAnalytics, totalUsageAnalyticsPerc, finalRecords, totalCount } = this.state;
        return (<div className='usage-analytics' >
 
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="-mt-4">
                        <Toolbar className="p-breadcrumb" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>

                <div className="ask-a-doubt ma-main mt-4">
                    <Authorizer permId={PERMISSIONS.USAGEANALYTICS_BRANCHWISE_SEARCH} >
                        <p className='search-users-txt'>Search</p>
                        <div className=''>
                            <Dropdown value={this.state.boardId}
                                className='w-12rem mt-2'
                                options={this.state.boards}
                                disabled={this.state.filterFor != 'all'}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                placeholder="Select Board" />
                            <span className='ma-required-mark mr-4'>*</span>
                            <Dropdown value={this.state.classId}
                                options={this.state.classes}
                                disabled={this.state.filterFor != 'all'}
                                className='w-12rem  mr-2 ma-w200'
                                onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            <span className='ma-required-mark mr-4'>*</span>
                            <Calendar showIcon={true}
                                placeholder="Select Dates"
                                selectionMode="range"
                                readOnlyInput
                                className='w-12rem mt-2'
                                value={dateRange}
                                disabled={this.state.filterFor != 'all'}
                                onChange={(e) => this.onMultiDateChange(e.value)}
                            ></Calendar>
                            <span className='ma-required-mark '>*</span>
                        </div>
                        <div className='mt-4'>
                            <Button label="Search" className='' onClick={this.onSearchClick} />
                            <Button label="Clear" className='mx-2 p-button-outlined white-bg' onClick={this.handleClearSearch} />
                        </div>
                    </Authorizer>

                    {
                        this.state.currentGradeSubjects && this.state.currentGradeSubjects.length ? <Toolbar className="mt-4 border-none" right={() => {
                            return <div className='ma-p10'>
                                <Authorizer permId={PERMISSIONS.USAGEANALYTICS_BRANCHWISE_FILTERBY} >
                                    <span className='filt' data-pr-position="bottom" data-pr-tooltip="Filters" onClick={(event) => this.menu.toggle(event)}>
                                        <FilterIcon2 width={20} height={20} color="#878787"
                                        />
                                        {/* <i className='pi pi-filter ma-pointer ma-m10 filt' data-pr-tooltip="Filters" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" ></i> */}
                                        <Tooltip className="table-li-tooltip" target=".filt" />
                                    </span>
                                    <Menu
                                        className='analyitcs-filters'
                                        model={[
                                            {
                                                template: (<div className='table-filter'>
                                                    <h2 className="filter-title">Filter By</h2>
                                                    <p className='filter-sub'>Time</p>
                                                    <div className='grid'>
                                                        {
                                                            Object.keys(timesArray).map((key) => {
                                                                return <div key={key} className="sm:col-3 "> <Button className={`p-button-filter ${this.state.timeRangeKey == key ? '' : 'p-button-outlined '}`} onClick={() => { this.setTimeRange(timesArray[key], key) }}>
                                                                    {key}
                                                                </Button>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className='grid '>
                                                        {
                                                            Object.keys(timesArray2).map((key) => {
                                                                return <div key={key} className="sm:col-3"> <Button className={`p-button-filter ${this.state.timeRangeKey == key ? '' : 'p-button-outlined'}`} onClick={() => { this.setTimeRange(timesArray2[key], key) }}>
                                                                    {key}
                                                                </Button>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <p className='filter-sub'>Subject</p>

                                                    <div className='p-grid'>
                                                        {this.state.currentGradeSubjects.map((subject, index) => {
                                                            return <>
                                                                {index == 0 && <Button key={index} className={`p-button-filter m-2 ${this.state.filters.subjectId == null ? '' : 'p-button-outlined'}`} onClick={() => { this.handleChange('subject', null) }}>
                                                                    All
                                                                </Button>}
                                                                <Button key={index + 1} className={`p-button-filter m-2 ${this.state.filters.subjectId == subject.subjectId ? '' : 'p-button-outlined'}`} onClick={() => { this.handleChange('subject', subject.subjectId) }}>
                                                                    {subject.subjectName}
                                                                </Button>
                                                            </>
                                                        })}
                                                    </div>
                                                    <p className='filter-sub'>Resource Type</p>
                                                    <div className='p-grid'>
                                                        {
                                                            ResourceTypes.map((type) => {
                                                                return <Button key={type.title} className={`p-button-filter m-2 ${(this.state.filters.contentType == type.field) ? 'p-button-filter' : 'p-button-outlined p-button-filter'}`} onClick={() => { this.handleChange('resource', type.field) }}>
                                                                    {type.title}
                                                                </Button>

                                                            })
                                                        }
                                                    </div>
                                                    <Toolbar className="ma-toolbar mt-4 border-none bg-white" right={() => { return (<><Button label='Reset' className=' p-button-outlined' onClick={this.onResetFilters} /> <Button label={'Done'} className='mx-2' onClick={this.getAthenaDataByFilter} /></>) }}></Toolbar>
                                                </div>)
                                            }]
                                        }
                                        popup
                                        ref={el => this.menu = el} />
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.USAGEANALYTICS_BRANCHWISE_GRAPHICAL_VIEW} >
                                    <span data-pr-position="bottom" style={{ marginLeft: '10px' }} data-pr-tooltip="Graphical View" className='graph' onClick={() => {
                                        this.showGraphView()
                                    }}>
                                        <AnalyticsIcon width={20} height={20} color="var(--primary-color)" />
                                        {/* <img src="new-assets/plus.png" /> */}
                                    </span>

                                    <Tooltip className="table-li-tooltip border-none" target=".graph" />

                                </Authorizer>

                            </div>
                        }}

                            left={() => {
                                return <>
                                    <p className='overall-usage-txt'>Overall Usage Analytics</p>
                                </>
                            }}

                        ></Toolbar> :
                            <></>
                    }





                    {
                        this.state.totalUserRecord && <OverallUsageAnalytics
                            totalUserRecord={this.state.totalUserRecord}
                        />
                    }
                    <BranchWiseAccordian branchWiseAnalytics={this.state.branchWiseAnalytics} />


                    {
                        this.state.isShowGraphicalView && <GraphicalView
                            subjectWiseGraphData={this.state.subjectWiseGraphData || []}
                            contentWiseWiseGraphData={this.state.contentWiseWiseGraphData || []}
                            totalUsageAnalytics={this.state.totalUsageAnalytics || {}}
                            hideGraphicalView={() => {
                                this.setState({
                                    isShowGraphicalView: false
                                })
                            }} />
                    }

                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }
         
        </div >

        )
    }
}



const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});





export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(BranchWiseAnalytics)));