import React, { Component } from 'react'
import { QuestionTypeKeys } from '../constants';
import { getFormFields, isFormValid, isFieldValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl, onChipsChange } from '../../../utile/formHelper';
import CkEditorComponent from './ckEditor';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import uuidv4 from 'uuid/v4';
import MathJax from 'react-mathjax-preview';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import FileUploadLambda from '../../fileUpload/fileUploadLambda';
import {
  getImageUrl
} from '../../../store/actions';
import AttachPreviewDelete from './attachPreviewDelete';
import { InputTextarea } from 'primereact/inputtextarea';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import withRouter from '../../lib/withRouter';

class ParaQuestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: props.data && props.data.question ? props.data.question : '',
      attachmentUrl: props.data && props.data.attachmentUrl ? props.data.attachmentUrl : '',
      options: props.data && props.data.options ? props.data.options : [{
        id: uuidv4(),
        imageUrl: '',
        value: '',
      }, {
        id: uuidv4(),
        imageUrl: '',
        value: '',
      }, {
        id: uuidv4(),
        imageUrl: '',
        value: '',
      }, {
        id: uuidv4(),
        imageUrl: '',
        value: '',
      }],
      key: props.data && props.data.key ? props.data.key : (props.questionType == QuestionTypeKeys.PSGS ? '' : []),
      questionType: props.data && props.data.questionType ? props.data.questionType : (props.questionType == QuestionTypeKeys.PSGS ? 'PQS' : 'PQM'),
      explanation: props.data && props.data.explanation ? props.data.explanation : '',
      explainationAttachment: props.data && props.data.explainationAttachment ? props.data.explainationAttachment : '',
      hint: props.data && props.data.hint ? props.data.hint : '',
      hintAttachment: props.data && props.data.hintAttachment ? props.data.hintAttachment : '',
      isAssigned: props.assigned ? props.assigned : false
    }
  }


  _onFilsUploadError = (errMsg) => {
    this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
  }


  addAttachment = (fieldName, fileName, data) => {
    this.setState((prevState) => {
      return {
        [fieldName]: data.imageStorageKey
      }
    });
  }

  _onAttachSuccess = (fieldName) => (fileName, data) => {
    this.props.getImageUrl(data.imageStorageKey);
    this.addAttachment(fieldName, fileName, data);

  }

  _onOptionImageSuccess = (optionId) => (fileName, data) => {
    let options = this.state.options;
    options = options.map((o) => {
      if (o.id == optionId) {
        o.imageUrl = data.imageStorageKey;
      }
      return o;
    });
    this.props.getImageUrl(data.imageStorageKey);
    //  this.addAttachment('attachmentUrl', fileName, data);

    this.setState({
      options
    })

  }



  _onOptionImageSuccess = (optionId) => (fileName, data) => {
    let options = this.state.options;
    options = options.map((o) => {
      if (o.id == optionId) {
        o.imageUrl = data.imageStorageKey;
      }
      return o;
    });
    this.props.getImageUrl(data.imageStorageKey);
    //  this.addAttachment('attachmentUrl', fileName, data);

    this.setState({
      options
    })

  }


  onOptionChange = (optionId, value) => {

    let options = this.state.options;
    options = options.map((o) => {
      if (o.id == optionId) {
        o.value = value;
      }
      return o;
    });
    //this.props.getImageUrl(data.imageStorageKey);
    //  this.addAttachment('attachmentUrl', fileName, data);

    this.setState({
      options
    })
  }

  removeOptionsAttach = (optionId) => {
    let options = this.state.options;
    options = options.map((o) => {
      if (o.id == optionId) {
        o.imageUrl = '';
      }
      return o;
    });
    //this.props.getImageUrl(data.imageStorageKey);
    //  this.addAttachment('attachmentUrl', fileName, data);

    this.setState({
      options
    })
  }

  onRemoveOption = (optionId) => {
    let options = this.state.options;
    if (options.length > 1) {
      options = options.filter((o) => o.id != optionId);
      this.setState({
        options,
        key: ''

      })
    } else {
      this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Can not remove, atleast one options is required.', life: 3000 });

    }

  }

  onAddOption = () => {
    let options = this.state.options;
    if (options.length > 4) {

      this.toast.show({ severity: 'warn', summary: 'Can not add', detail: 'Can not be add more than 5 options', life: 3000 });

    } else {

      options = [
        ...options,
        {
          id: uuidv4(),
          imageUrl: '',
          value: '',
        }];
      this.setState({
        options,
        key: ''
      })
    }
  }


  isValid = () => {

    let { question, options, key } = this.state;


    let optionsValid = true;

    options.forEach((o, index) => {

      if (o.value == '' && o.imageUrl == '') {
        optionsValid = false;
      }
    });



    let isKeyValid = true;
    if (this.props.questionType == QuestionTypeKeys.PSGS) {
      if (key == '') {
        isKeyValid = false
      }
    } else {
      if (!key.length) {
        isKeyValid = false
      }

    }

    if (question != '' && isKeyValid && optionsValid) {
      return true
    } else {
      this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Please fill all * mark fields', life: 3000 });
      return false;
    }




  }

  assignCurriculum = () => {
    
    if (this.isValid()) {
      this.setState({
        isAssigned: true
      }, () => {
        this.props.onAssignClick(
          {
            id: this.props.id,
            data: {
              ...this.props.data,
              ...this.state
            },
            assigned: true
          });
      })
    }



  }

  onEditClick = () => {
    if (this.props.questions.every((s) => s.assigned === true)) {
      this.setState({
        isAssigned: false,
      }, () => {
        this.props.onAssignClick(
          {
            id: this.props.id,
            assigned: false
          });
      });
    } else {
      this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please add or remove which is in edit state.', life: 3000 });
    }

  }

  render() {
   console.log(this.props)
    const { question, attachmentUrl, options, key, explainationAttachment, explanation, hint, hintAttachment, isAssigned } = this.state;
    return (
      <>

        <p className='add-q-label eree ma-mt10 mt-3'>Question {this.props.index} <span className='ma-required'>*</span>
          {!isAssigned && <span className="ma-ml10 ma-pointer ma-right" onClick={() => { this.props.removeTempQuestion(this.props.id) }}>
            <i className="pi pi-times"></i>
          </span>}
        </p>
        <div className="grid ma-no-pm ma-p10" >
          <div className="col-6 p-md-12 p-lg-6">
            <div>
              {/* <CkEditorComponent
                value={question}
                onChange={(value) => {
                  this.setState({
                    question: value
                  })
                }}
                disabled={isAssigned}
              /> */}
              <InputTextB info={FIELDS_INFO.QUESTION} id="cx2">
                <InputTextarea rows={3} cols={30}
                  value={question}
                  onChange={(e) => {
                    this.setState({
                      question: e.target.value
                    })
                  }}
                  disabled={isAssigned}
                  className='latex-input ma-w100p w-12' />
              </InputTextB>
            </div>
            <div>
              <FileUploadLambda id={`${this.props.index}ed`}
                multiple={false}
                onProgress={() => { }}
                onSucess={(fileName, data) => this._onAttachSuccess('attachmentUrl')(fileName, data)}
                maxFileSize={307200}
                onError={this._onFilsUploadError}
                accept={'images'}
                title={'Images'}
                dirName=''
                disabled={isAssigned}
                afterFilesUploaded={() => { }}
              >
                <label className='attachment-label' htmlFor={`${this.props.index}ed`}>
                  Attachment <i className='pi pi-file' />
                </label>
              </FileUploadLambda>
              <div className='ma-clearFix'>

              </div>
              {this.props.getAllImagesUrlInfo[attachmentUrl] &&
                <AttachPreviewDelete
                  disableRemove={isAssigned}
                  imagePath={this.props.getAllImagesUrlInfo[attachmentUrl]}
                  removeAttach={() => { this.setState({ attachmentUrl: '' }) }}

                />}
            </div>
          </div>
          <div className="col-6  p-md-12 p-lg-6">
            <div className='preview-qo'>
              <MathJax math={question} />
              <div style={{ width: '100%', overflow: 'auto' }}>
                <img
                  src={this.props.getAllImagesUrlInfo[attachmentUrl]}
                  alt=""
                  className='question-img'
                />
              </div>
            </div>
          </div>
        </div>


        <p className='add-q-label'>Options</p>
        <div className='ma-p20'>
          {options && options.length ? <>
            {options.map((opt, index) => {
              return <div key={opt.id}>
                <p className='add-option-label'>Option {index + 1} <span className='ma-required'>*</span>

                  {!isAssigned && <span className='ma-right ma-pointer' onClick={() => { this.onRemoveOption(opt.id) }}><i className='pi pi-times'></i></span>}</p>
                <div className="grid ma-no-pm" >
                  <div className="col-6 p-md-12 p-lg-6">
                    <div>
                      {/* <CkEditorComponent
                        value={opt.value}
                        onChange={(value) => this.onOptionChange(opt.id, value)}
                        disabled={isAssigned}
                      /> */}
                      <InputTextB info={FIELDS_INFO.OPTION} id="cxWF2w">
                        <InputTextarea rows={3} cols={30}
                          value={opt.value}
                          onChange={(e) => this.onOptionChange(opt.id, e.target.value)}
                          disabled={isAssigned}
                          className='latex-input ma-w100p w-12' />
                      </InputTextB>
                    </div>
                    <div>
                      <FileUploadLambda id={`${this.props.index}opt${opt.id.substring(0, 5)}`}
                        multiple={false}
                        onProgress={() => { }}
                        onSucess={(filename, data) => this._onOptionImageSuccess(opt.id)(filename, data)}
                        maxFileSize={307200}
                        onError={this._onFilsUploadError}
                        accept={'images'}
                        title={'Images'}
                        dirName=''
                        afterFilesUploaded={() => { }}
                        disabled={isAssigned}
                      >
                        <label className='attachment-label' htmlFor={`${this.props.index}opt${opt.id.substring(0, 5)}`}>
                          Attachment <i className='pi pi-file' />
                        </label>
                      </FileUploadLambda>
                      <div className='ma-clearFix'>
                      </div>
                      {this.props.getAllImagesUrlInfo[opt.imageUrl] &&
                        <AttachPreviewDelete
                          disableRemove={isAssigned}
                          imagePath={this.props.getAllImagesUrlInfo[opt.imageUrl]}
                          removeAttach={() => { this.removeOptionsAttach(opt.id) }}
                        />}
                    </div>
                  </div>
                  <div className="col-6  p-md-12 p-lg-6">
                    <div className='preview-qo w-12'>
                      <MathJax math={opt.value} />
                      <div style={{ width: '100%', overflow: 'auto' }}>
                        <img
                          src={this.props.getAllImagesUrlInfo[opt.imageUrl]}
                          alt=""
                          className='question-img'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </> : <></>}
          <Button label="Add Option" disabled={isAssigned} className='ma-ml10 p-button-outlined' icon="pi pi-plus" iconPos='left' onClick={this.onAddOption} />
        </div>
        {options && options.length ? <>
          <div className="ma-mt20 key-selector">
            <span className='add-q-label'>Key </span><span className='ma-required ma-mr20'>*</span>
            {this.props.questionType == QuestionTypeKeys.PSGM ? <>
              <InputTextB info={FIELDS_INFO.KEYS} id="cx2w">
                <MultiSelect value={key}
                  className='ma-w200 ma-mr20 w-2'
                  options={options.map((o, index) => { return { label: `Option ${index + 1}`, value: `Option ${index + 1}` } })}
                  onChange={(e) => this.setState({
                    key: e.value
                  })}
                  disabled={isAssigned}
                  placeholder="Select Key" />
              </InputTextB>
            </> : <>
              <InputTextB info={FIELDS_INFO.KEY} id="cx2Dw">
                <Dropdown value={key}
                  className='ma-w200 ma-mr20 w-2'
                  options={options.map((o, index) => { return { label: `Option ${index + 1}`, value: `Option ${index + 1}` } })}
                  onChange={(e) => this.setState({
                    key: e.value
                  })}
                  disabled={isAssigned}
                  placeholder="Select Key" />
              </InputTextB>
            </>}
          </div>
        </> : <></>}
        <div className='mt-4'>
          <p className='add-q-label'>Explanation</p>
          <div className="grid" >
            <div className="col-6 p-md-12 p-lg-6">
              <div>
                {/* <CkEditorComponent
                  value={explanation}
                  onChange={(value) => {
                    this.setState({
                      explanation: value
                    })
                  }}
                  disabled={isAssigned}
                /> */}
                <InputTextB info={FIELDS_INFO.EXPLAINATION} id="Ocx2w">
                  <InputTextarea rows={3} cols={30}
                    value={explanation}
                    onChange={(e) => {
                      this.setState({
                        explanation: e.target.value
                      })
                    }}
                    disabled={isAssigned}
                    className='latex-input ma-w100p w-12' />
                </InputTextB>
              </div>
              <div>
                <FileUploadLambda id={`${this.props.index}att`}
                  multiple={false}
                  onProgress={() => { }}
                  onSucess={(fileName, data) => this._onAttachSuccess('explainationAttachment')(fileName, data)}
                  maxFileSize={307200}
                  onError={this._onFilsUploadError}
                  accept={'images'}
                  title={'Images'}
                  dirName=''
                  afterFilesUploaded={() => { }}
                  disabled={isAssigned}
                >
                  <label className='attachment-label' htmlFor={`${this.props.index}att`}>
                    Attachment <i className='pi pi-file' />
                  </label>
                </FileUploadLambda>
                <div className='ma-clearFix'>

                </div>
                {this.props.getAllImagesUrlInfo[explainationAttachment] &&
                  <AttachPreviewDelete
                    disableRemove={isAssigned}
                    imagePath={this.props.getAllImagesUrlInfo[explainationAttachment]}
                    removeAttach={() => { this.setState({ explainationAttachment: '' }) }}
                  />}
              </div>
            </div>
            <div className="col-6 p-md-12 p-lg-6">
              <div className='preview-qo w-12'>
                <MathJax math={explanation} />
                <div style={{ width: '100%', overflow: 'auto' }}>
                  <img
                    src={this.props.getAllImagesUrlInfo[explainationAttachment]}
                    alt=""
                    className='question-img'
                  />
                </div>
              </div>
            </div>
          </div>


          <p className='add-q-label'>Hint</p>
          <div className="grid" >
            <div className="col-6 p-md-12 p-lg-6">
              <div>
                {/* <CkEditorComponent
                  value={hint}
                  onChange={(value) => {
                    this.setState({
                      hint: value
                    })
                  }}
                  disabled={isAssigned}
                /> */}
                <InputTextB info={FIELDS_INFO.HINT} id="Ecx2R">
                  <InputTextarea rows={3} cols={30}
                    value={hint}
                    onChange={(e) => {
                      this.setState({
                        hint: e.target.value
                      })
                    }}
                    disabled={isAssigned}
                    className='latex-input ma-w100p w-12' />
                </InputTextB>
              </div>
              <div>
                <FileUploadLambda id={`${this.props.index}hint`}
                  multiple={false}
                  onProgress={() => { }}
                  onSucess={(fileName, data) => this._onAttachSuccess('hintAttachment')(fileName, data)}
                  maxFileSize={307200}
                  onError={this._onFilsUploadError}
                  accept={'images'}
                  title={'Images'}
                  dirName=''
                  afterFilesUploaded={() => { }}
                  disabled={isAssigned}
                >
                  <label className='attachment-label' htmlFor={`${this.props.index}hint`}>
                    Attachment <i className='pi pi-file' />
                  </label>
                </FileUploadLambda>
                <div className='ma-clearFix'>

                </div>
                {this.props.getAllImagesUrlInfo[hintAttachment] &&
                  <AttachPreviewDelete
                    disableRemove={isAssigned}
                    imagePath={this.props.getAllImagesUrlInfo[hintAttachment]}
                    removeAttach={() => { this.setState({ hintAttachment: '' }) }}

                  />}
              </div>
            </div>
            <div className="col-6 p-md-12 p-lg-6">
              <div className='preview-qo w-12'>
                <MathJax math={hint} />
                <div style={{ width: '100%', overflow: 'auto' }}>
                  <img
                    src={this.props.getAllImagesUrlInfo[hintAttachment]}
                    alt=""
                    className='question-img'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.isAssigned ? <Button
          label={`Edit Question`} className='mb-3'
          onClick={this.onEditClick}
        />

          : <Button className='mb-3'
            label={`Save Question`}
            onClick={this.assignCurriculum}
          />
        }
        <Toast ref={(el) => this.toast = el} position="bottom-right" />
        {/* </InputTextB> */}
        {/* <p style={{ color: 'var(--required)', fontSize: '14px' }}>{this.state.errorMsg}</p> */}
      </>
    )
  }
}



const mapStateToProps = (state) => ({
  getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},
  // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
  // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
  getImageUrl

})(withRouter(ParaQuestion));