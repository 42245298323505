import React from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import {
    getBoardsData,
} from '../../store/actions';
import InputTextB from './../customComponents/inputTextB';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import withRouter from '../lib/withRouter';

class AssignCurriculums extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            classData: [],
            boards: [],
            groups: [],
            classes: [],
            userBoard: props.data && props.data.boardId ? props.data.boardId : '',
            userClass: props.data && props.data.classId ? props.data.classId : '',
            userGroup: props.data && props.data.groupId ? props.data.groupId : '',
            boardName: props.data && props.data.boardName ? props.data.boardName : '',
            className: props.data && props.data.className ? props.data.className : '',
            groupName: props.data && props.data.groupName ? props.data.groupName : '',
            isAssigned: props.data ? true : false,
            errorMsg: '',
        }
    }



    getBoardName = (id) => {
        const _board = this.state.boards && this.state.boards.length && this.state.boards.find((b) => b.boardId === id);
        return (_board && _board.boardName) || ' ';
    };

    getClassName = (id) => {
        const _class = this.state.classes && this.state.classes.length && this.state.classes.find((c) => c.classId === id);
        return (_class && _class.className) || '';
    };

    getGroupName = (id) => {
        const _group = this.state.groups && this.state.groups.length > 0 && this.state.groups.find((g) => g.groupId === id);
        return (_group && _group.groupName) || ' ';
    };

    handleChangeBoard = (boardId) => {
        const boardName = this.getBoardName(boardId);
        this.setState({ classes: [], groups: [], userClass: '', userGroup: '' });
        const _classes = [];
        const selectedBoard =
            this.state.boards &&
            this.state.boards.length > 0 &&
            this.state.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            this.setState({
                classes: _classes,
                userBoard: boardId,
                userClass: '',
                userGroup: '',
                subjectId: '',
                groups: [],
                subjects: [],
                boardName,
                isAssigned: false,
            },
                () => {
                    this.props.onAssignClick(
                        {
                            id: this.props.id,
                            data: {

                            },
                            assigned: false
                        });
                });
        }
    };

    handleChangeClass = (classId) => {
        const className = this.getClassName(classId);
        this.setState({ groups: [], userGroup: '' });
        const boardId = this.state.userBoard;
        const _groups = [];
        const selectedBoard =
            this.state.boards &&
            this.state.boards.length > 0 &&
            this.state.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));

            const groupId = _groups[0].groupId
            const groupName = this.getGroupName(groupId);


            this.setState(
                {
                    groups: _groups,
                    userClass: classId,
                    className,
                    userGroup: '',
                    subjectId: '',
                    subjects: [],
                    chapters: [],
                    topics: [],
                    isAssigned: false,
                    userGroup: groupId,
                    groupName
                },
                () => {
                    const _groupId =
                        this.state.groups && this.state.groups.length && this.state.groups[0] && this.state.groups[0].groupId;
                    this.setState(
                        {
                            _groupId
                        }
                    );
                    this.props.onAssignClick(
                        {
                            id: this.props.id,
                            data: {

                            },
                            assigned: false
                        });
                }
            );
        }
    };



    assignCurriculum = () => {
        const { userBoard, userClass, userGroup, boardName, className,
            groupName
        } = this.state;


        if (userBoard != '' && userClass != '' && userGroup != '') {
            this.setState({
                isAssigned: true
            }, () => {
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        data: {
                            boardId: userBoard,
                            classId: userClass,
                            groupId: userGroup,
                            boardName,
                            className,
                            groupName
                        },
                        assigned: true
                    });
            })
        } else {
            this.setState({
                errorMsg: 'select class, group'
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 3000);
        }



    }




    checkIsAssignedInOthers = (classId) => {
        let isAssigned = false;
        this.props.assignedCurriculums.forEach(g => {
            if ((g.data && g.data.classId == classId) && g.id != this.props.id) {
                isAssigned = true;
            }
        });
        return isAssigned
    }

    onEditClick = () => {
        if (this.props.assignedCurriculums.every((s) => s.assigned === true)) {

            let propBoards = _.cloneDeep(this.props.boards);
            let boards = propBoards.filter(a => !(this.checkIsAssignedInOthers(a.classId)))
            // let selectedBoard = boards.find(b => b.boardId == this.state.userBoard)

            let classes = this.state.classes.filter(a => !(this.checkIsAssignedInOthers(a.classId)));


            this.setState({
                isAssigned: false,
                boards: boards,
                classes
            }, () => {
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        assigned: false
                    });
            });
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please add or remove which is in edit state.', life: 3000 });
        }

    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            let propBoards = _.cloneDeep(this.props.boards)
            let boards = []
            propBoards &&
                propBoards.map((board, index) => {
                    board.classes = board.classes.filter(a => !(this.checkIsAssignedInOthers(a.classId)))
                    boards.push(board);
                });

            boards = boards.filter(b => b.classes.length);
            this.setState({
                boards: boards
            }, () => {
                if (this.props.data && this.props.boards) {

                    const selectedBoard =
                        this.props.boards &&
                        this.props.boards.length > 0 &&
                        this.props.boards.find((board) => board.boardId === this.state.userBoard);
                    if (selectedBoard && selectedBoard.classes) {

                        this.setState({
                            classes: selectedBoard.classes
                        })
                    }


                }
            });



        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.boards !== this.props.boards) {
            let boards = []
            let propBoards = _.cloneDeep(this.props.boards)
            propBoards &&
                propBoards.map((board, index) => {
                    board.classes = board.classes.filter(a => !(this.checkIsAssignedInOthers(a.classId)))
                    boards.push(board);
                });

            boards = boards.filter(b => b.classes.length);
            this.setState(
                {
                    boards: boards
                }, () => {
                    if (this.props.data && this.props.boards) {

                        const selectedBoard =
                            this.props.boards &&
                            this.props.boards.length > 0 &&
                            this.props.boards.find((board) => board.boardId === this.state.userBoard);
                        if (selectedBoard && selectedBoard.classes) {

                            this.setState({
                                classes: selectedBoard.classes
                            })
                        }


                    }
                });
        }
    }




    render() {
        //   console.log(this.props, 'sssssss')
        return (
            <div className='grid' >
                <div className='col-12'>
                    <div className='grid' >
                        <InputTextB info='' id="cu10" isHide={true}>
                            <div className='col-8'>
                                <Dropdown value={this.state.userBoard}
                                    disabled={this.state.isAssigned}
                                    className=' w-10'
                                    options={this.state.boards.map(a => { return { label: a.boardName, value: a.boardId } })}
                                    onChange={(e) => this.handleChangeBoard(e.value)}
                                    placeholder="Select Board" />
                            </div>
                            <div className='col-8'>
                            <Dropdown value={this.state.userClass}
                                disabled={this.state.isAssigned}
                                className='w-10'
                                options={this.state.classes.map(a => { return { label: a.className, value: a.classId } })}
                                onChange={(e) => this.handleChangeClass(e.value)}
                                placeholder="Select Class" />
                            </div>
                            <div className='col-12'>
                            {
                                !(this.props.data && this.props.data.assigned) && <>
                                    {this.state.isAssigned ? <Button
                                        label={`Edit`}
                                        className='ml-0'
                                        onClick={this.onEditClick}
                                    />
                                        : <Button
                                            className='ml-0'
                                            label={`Assign`}
                                            onClick={this.assignCurriculum}
                                        />
                                    }
                                    {/* <span className="ma-ml10 ma-pointer" onClick={() => { this.props.removeTempCurriculum(this.props.id) }}>
                                        <i className="pi pi-times"></i>
                                    </span> */}
                                    <Button icon="pi pi-times" onClick={() => { this.props.removeTempCurriculum(this.props.id) }} className="p-button-rounded p-button-secondary p-button-text" />
                                </>
                            }
                            </div>

                            <p className='' style={{ margin: "10px 0px 10px 0px ", color: 'var(--required)', fontSize: '14px' }}>{this.state.errorMsg}</p>
                        </InputTextB>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
        isLoading: state.boardsData.loading,
    }
}

export default connect(mapStateToProps, {
    getBoardsData,
})(withRouter(AssignCurriculums));