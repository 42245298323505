import React, { Component } from 'react';
import { connect } from 'react-redux';
import SvgViewer from '../customComponents/svgViewer';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import Service from '../../services';
import { Toast } from 'primereact/toast';
import CreateTimeTable from './createTimetable';
import cloneDeep from 'lodash/cloneDeep';
import './styles.scss'
import { BasicLazyParams, warningDailogInit } from '../../utile';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import moment from 'moment';
import { baseUrlAdmin } from '../../store/apiConstants';
import {

    getBoardsData,


    //getYearlyTimeTableData,
} from '../../store/actions';
import Authentication from './../session';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import LoadingComponent from '../loadingComponent';
import { ApprovalIcon, DeleteIcon, DownloadIcon, EditIcon, PreviewIcon, WarningIcon } from '../svgIcons';
import withRouter from '../lib/withRouter';

const items = [
    { label: 'Academics' },
    { label: 'Yearly Planner', className: '' },
    { label: 'Timetable', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }



class TimeTableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            editTimetableData: null,
            globalFilter: null,
            isShowBoardCreate: false,
            warningDialog: warningDailogInit,
            warningDialogForDelete: warningDailogInit,
            columns: [],
            editData: {},
            isShowCreateTimeTable: false,
            timeTables: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            downloadTimeTableData: [],
            branchSections: [],
            isShowBranchPreview: false,
            branchPreviewData: null
        }
        this.service = new Service();
    }




    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className=''>Timetable</h4>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }



    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }
    onIndexTemplate = (data, props) => {
        return props.rowIndex + 1;
    }


    handleDelete = async (rowData) => {
        this.setState({
            warningDialogForDelete: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want to delete the ${rowData.title} time table.`
            },
            editTimetableData: rowData
        });
        // let { data } = await axios.delete(`${baseUrlAdmin}/yearlyplanner/${_id}`,
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
        //         }
        //     });
        // console.log("deleted data", data)
        // // this.props.getYearlyTimeTableData()
        // this.toast.show({ severity: 'success', summary: 'Delete Item', detail: "item deleted", life: 3000 });

    }

    deleteTimeTable = () => {
        // this.setState({

        // })
        let { editTimetableData } = this.state;
        const url = `${baseUrlAdmin}/timetable/${editTimetableData._id}`;

        this.setState({
            warningDialogForDelete: warningDailogInit,
            isLoading: true
        })
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.onTimeTableDeleteSuccess();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {


            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })



    }





    cancelCreate = () => {
        this.setState({
            isShowCreateTimeTable: false
        })
    }

    dummySave = () => {
        this.setState({
            isShowCreateTimeTable: false,
        }, () => this.getTimeTables());
        this.toast.show({ severity: 'success', summary: 'Saved', detail: 'Time table saved successfuly', life: 3000 });
    }



    getTimeTables = () => {
        this.setState({
            isLoading: true,
            timeTables: [],
            totalRecords: 0
        });
        let { lazyParams, globalSearch } = this.state;

        let url = `${baseUrlAdmin}/timetable/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder === 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.post(url, lazyParams.filters, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        timeTables: res.res.data.timeTables,
                        branchSections: res.res.data.branchSections,
                        totalRecords: res.res.data.totalRecords,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);
        })
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getTimeTables);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTimeTables);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTimeTables);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getTimeTables);
    }



    componentDidMount() {
        this.getTimeTables();
    }


    onTimeTableCreateorEditSuccess = (isEdit) => {
        this.setState({
            isShowCreateTimeTable: false,
            editTimetableData: null,
            lazyParams: BasicLazyParams,
            globalSearch: ''
        }, () => {
            this.getTimeTables()
        })
        this.toast.show({ severity: 'success', summary: 'Success', detail: `Time Table ${isEdit ? 'edited' : 'created'} Successfully`, life: 3000 });
    }

    onTimeTableDeleteSuccess = () => {
        this.setState({
            isShowCreateTimeTable: false,
            editTimetableData: null,
            lazyParams: BasicLazyParams,
            globalSearch: ''
        }, () => {
            this.getTimeTables()
        })
        this.toast.show({ severity: 'success', summary: 'Success', detail: `Time Table Deleted Successfully`, life: 3000 });
    }

    showBranchPreview = (rowData) => {

        let columns = rowData.sectionInfo[0].data;
        let data = [];
        rowData.sectionInfo.forEach(b => {
            let row = {};
            b.data.forEach(s => {
                row = {
                    ...row,
                    [s.levelName]: s.name
                };
            });
            data.push(row);
        });

        this.setState({
            isShowBranchPreview: true,
            branchPreviewData: {
                columns,
                data
            }
        })
    }


    render() {
        const { branchPreviewData } = this.state;
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="ma-tbl-left">
                    <h4 className=''>Timetable List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6  list-none">
                        <Authorizer permId={PERMISSIONS.TIMETABLE_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.TIMETABLE_FILTERS} >
                            <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.TIMETABLE_VIEW_COLUMNS} >
                            <li><i data-pr-tooltip="View Columns"
                                onClick={(event) => this.menu.toggle(event)}
                                data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.TIMETABLE_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        )
        return (<>
            <div className="-mt-6">
                <Toolbar className="border-none" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
            </div>
            <div className="p-grid ma-toolbar text-right">
                <div className="p-col-12 p-md-1 ma-no-p">
                    {(this.state.isShowCreateTimeTable) && <>
                        <p className='back-arrow' onClick={() => {
                            this.setState({
                                isShowCreateTimeTable: false,
                                editTimetableData: null
                            }, () => {
                                this.getTimeTables()
                            })
                        }}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                    </>
                    }
                </div>

            </div>
            <div className="ask-a-doubt ma-main question-bank">
                {!this.state.isShowCreateTimeTable ?
                    <>
                        <Toolbar className="border-none"


                            left={() => {
                                return <>
                                    <Authorizer permId={PERMISSIONS.TIMETABLE_ADD} >
                                        <div className='ma-mtb25'>
                                            <Button label="+ Add Timetable" className='ma-m-lr10'
                                                onClick={() => {
                                                    this.setState({
                                                        isShowCreateTimeTable: true,
                                                        editTimetableData: null
                                                    })
                                                }} />
                                        </div>
                                    </Authorizer>
                                </>
                            }}
                        ></Toolbar>

                        <div className="card datatable-crud-demo ma-m30 createPlanner-table">
                            <DataTable ref={(el) => this.dt = el}
                                let-i="rowIndex"
                                value={this.state.timeTables}
                                dataKey="_id"
                                paginator
                                lazy
                                showGridlines
                                onPage={this.onPage}
                                onSort={this.onSort}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                paginatorLeft={() => {
                                    return <>
                                        <p className='avail-q'> Available Timetable:  <span>{this.state.totalRecords}</span> </p>
                                    </>
                                }
                                }
                                rows={this.state.lazyParams.rows}
                                sortField={this.state.lazyParams.sortField}
                                sortOrder={this.state.lazyParams.sortOrder}
                                responsiveLayout="scroll"
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                header={header}
                            >
                                {/* <Column filterMatchMode='contains' sortable body={this.onIndexTemplate} field="Index" header="S No" /> */}
                                <Column filterMatchMode='contains' sortable field="title" header="Title" />

                                {/* {
                                        branchSections.filter(sd => sd.level != branchSections.length).map((s) => {
                                            return <Column field={s.key} header={s.levelName} />
                                        })
                                    } */}
                                {/* <Column filterMatchMode='contains' sortable field="boardName" header="Board" />
                                    <Column filterMatchMode='contains' sortable field="className" header="Grade" /> */}
                                <Column header="Branch" body={(rowData, { rowIndex }) => {
                                    return (
                                        <div className="ma-status">
                                            <span data-pr-tooltip="Preview" data-pr-position="bottom" className={`edd${rowIndex}`}
                                                onClick={() => {
                                                    this.showBranchPreview(rowData)
                                                }}>
                                                <PreviewIcon height={13} width={13} />
                                            </span>
                                            <Tooltip className="table-li-tooltip" autoHide={false} target={`.edd${rowIndex}`} />
                                        </div>
                                    )
                                }} />

                                <Column header="Actions" body={(rowData, { rowIndex }) => {
                                    return <>
                                        <div className="">
                                            <ul className="flex gap-2 p-0 list-none">
                                                <Authorizer permId={PERMISSIONS.TIMETABLE_EDIT} >
                                                    <span data-pr-tooltip="Edit" data-pr-position="bottom" className={`xyz${rowIndex} ma-mr20`}
                                                        onClick={() => {
                                                            this.setState({
                                                                isShowCreateTimeTable: true,
                                                                editTimetableData: rowData
                                                            })
                                                        }}>
                                                        <EditIcon height={13} width={13} />
                                                    </span>
                                                    <Tooltip className="table-li-tooltip" autoHide={false} target={`.xyz${rowIndex}`} />
                                                </Authorizer>

                                                {/* <Authorizer permId={PERMISSIONS.TIMETABLE_ASSIGN} >
                                                        <span data-pr-tooltip="Assign" data-pr-position="bottom" className={`ass${rowIndex} ma-mr20`} 
                                                        //onClick={() => { this.assignTimeTable(rowData) }}
                                                        >
                                                            <ApprovalIcon height={13} width={13} />
                                                        </span>
                                                        <Tooltip className="table-li-tooltip" target={`.ass${rowIndex}`} />
                                                    </Authorizer> */}
                                                <Authorizer permId={PERMISSIONS.TIMETABLE_DOWNLOAD_PLANNER} >
                                                    <span data-pr-tooltip="Download" data-pr-position="bottom" className={`dnd${rowIndex} ma-mr20`}
                                                    // onClick={() => { this.handleDownload(rowData) }}
                                                    >
                                                        <DownloadIcon height={13} width={13} />
                                                    </span>
                                                    <Tooltip className="table-li-tooltip" target={`.dnd${rowIndex}`} />
                                                </Authorizer>
                                                <Authorizer permId={PERMISSIONS.TIMETABLE_DELETE} >
                                                    <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`dlete${rowIndex} ma-mr20`}
                                                        onClick={() => { this.handleDelete(rowData) }}
                                                    >
                                                        <DeleteIcon height={13} width={13} />
                                                    </span>
                                                    <Tooltip className="table-li-tooltip" target={`.dlete${rowIndex}`} />
                                                </Authorizer>

                                            </ul>
                                        </div>
                                    </>
                                }} >
                                </Column>
                            </DataTable>

                        </div >
                    </> :

                    <CreateTimeTable
                        editTimetableData={this.state.editTimetableData}
                        cancelCreate={() => {
                            this.setState({
                                isShowCreateTimeTable: false,
                                editTimetableData: null,
                                lazyParams: BasicLazyParams,
                                globalSearch: ''
                            }, () => {
                                this.getTimeTables()
                            })
                        }}
                        dummySave={this.dummySave}

                        onTimeTableCreateorEditSuccess={
                            this.onTimeTableCreateorEditSuccess
                        }


                    />

                }




                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editTimetableData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editTimetableData: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialogForDelete.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, editTimetableData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.deleteTimeTable} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialogForDelete: warningDailogInit, editTimetableData: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialogForDelete.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialogForDelete.message}</p>
                </Dialog>

                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    // dismissableMask={true}
                    closable={true}
                    header="Sections Hierarchy"
                    headerClassName='text-center'
                    visible={this.state.isShowBranchPreview} style={{ maxWidth: '50%', minWidth: '50%' }}
                    // footer={() => {
                    //     return (<div>
                    //         <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, editTimetableData: null }) }} />
                    //         <Button label="Yes" className='ma-m-lr10' onClick={this.deleteTimeTable} />

                    //     </div>)
                    // }}
                    onHide={() => this.setState({ isShowBranchPreview: false, editTimetableData: null })}>
                    {/* <div className='section-hierarchy'>
                            {editTimetableData && editTimetableData.sectionIds.map((s, ind) => {
                                return <div className=''>

                                    <div className='p-grid section-h'>
                                       
                                        <div className='p-col-1 p-md-1'>
                                        <span>{ind + 1}</span>
                                        </div>
                                        {s.data.map(a => {
                                            return <div className='p-col-12 p-md-3 '>
                                                <p className='text-label2 ma-no-pm'> {a.levelName}</p>
                                                <p className='text-label2 ma-no-pm ma-tab-title'>{a.name}</p>
                                            </div>
                                        })}
                                    </div></div>
                            })}
                        </div> */}
                    {branchPreviewData && <>
                        <div className="card datatable-crud-demo ma-m30 fetch-q-tabled">
                            <DataTable ref={(el) => this.dt = el}
                                let-i="rowIndex"
                                scrollable
                                value={branchPreviewData.data}
                                dataKey="id"
                                responsiveLayout="scroll"
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            >
                                {branchPreviewData.columns.map(s => {
                                    return <Column headerClassName='difficulty' field={s.levelName} header={s.levelName}
                                    // body={(rowData, { rowIndex }) => {
                                    //     <div className='p-grid section-h'>
                                    //         <p>sfd</p>
                                    //         <div className='p-col-1 p-md-1'>
                                    //             <span>{rowIndex}</span>
                                    //         </div>
                                    //         {console.log(rowData)}
                                    //         {rowData.data.map(a => {
                                    //             return <div className='p-col-12 p-md-3 '>
                                    //                 <p className='text-label2 ma-no-pm'> {a.levelName}</p>
                                    //                 <p className='text-label2 ma-no-pm ma-tab-title'>{a.name}</p>
                                    //             </div>
                                    //         })}
                                    //     </div>
                                    // }} 
                                    />
                                })}
                            </DataTable>
                        </div >
                    </>}
                </Dialog>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <LoadingComponent />
            }

        </>
        )
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
});

export default connect(mapStateToProps, {
    getBoardsData,
})(Authentication(withRouter(TimeTableList)));